import { isEmpty } from "../../common";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

const questionnaireHasName = (questionName) => {
  return !isEmpty(questionName);
};

const allMessagesHaveEnglishVersion = (messages) => {
  let counter = 0;
  Object.entries(messages).forEach((message) => message[1].en === '' ? counter++ : counter);
  return counter === 0;
};

const hasAtLeastOneQuestion = (questions) => {
  return questions.length > 0;
};

const allQuestionsHaveEnglishVersion = (questionList) => {
  return questionList.filter((q) => isEmpty(q.question.en)).length === 0;
};

export const validateQuestionnaire = (input, addVisitorMessages) => {
  const _errorMessages = [];

  if (!questionnaireHasName(input.name)) {
    _errorMessages.push(i18n.t("Questions.QuestionnaireMustHaveName"));
  }

  if (!allMessagesHaveEnglishVersion(input.messages)) {
    _errorMessages.push(i18n.t("Questions.MessagesMustHaveEnglishVersion"));
  }

  if (
    addVisitorMessages &&
    !allMessagesHaveEnglishVersion(input.visitorMessages)
  ) {
    _errorMessages.push(i18n.t("Questions.VisitorMessagesMustHaveEnglishVersion"));
  }

  if (!hasAtLeastOneQuestion(input.questionnaire)) {
    _errorMessages.push(i18n.t("Questions.AtLeastOneQuestion"));
  }

  if (!allQuestionsHaveEnglishVersion(input.questionnaire)) {
    _errorMessages.push(i18n.t("Questions.QuestionsMustHaveEnglishVersion"));
  }

  return _errorMessages;
};

export default withTranslation()(validateQuestionnaire);
