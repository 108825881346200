import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { IconButton } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { isEmpty } from "../../common";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  grouped: {
    height: 40
  },
  iconButton: {
    marginLeft: theme.spacing(0.5)
  }
}));

export const ToggleScheduleDays = ({
  scheduleBitmapLength,
  scheduleBitmapIndex,
  scheduleBitmap,
  scheduleWeeks,
  onScheduleBitmapChange,
  onAddScheduleBitmap,
  onDeleteScheduleBitmap,
  customDays = [],
  disable = false
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [days, setDays] = useState([]);

  const DAYS = [
    {
      key: "monday",
      label: t("UserGroups.CommonMondayAbbrev")
    },
    {
      key: "tuesday",
      label: t("UserGroups.CommonTuesdayAbbrev")
    },
    {
      key: "wednesday",
      label: t("UserGroups.CommonWednesdayAbbrev")
    },
    {
      key: "thursday",
      label: t("UserGroups.CommonThursdayAbbrev")
    },
    {
      key: "friday",
      label: t("UserGroups.CommonFridayAbbrev")
    },
    {
      key: "saturday",
      label: t("UserGroups.CommonSaturdayAbbrev")
    },
    {
      key: "sunday",
      label: t("UserGroups.CommonSundayAbbrev")
    }
  ];

  useEffect(() => {
    let _days = bitmapStrToDays(scheduleBitmap);
    setDays(_days);
  }, [scheduleBitmapIndex, scheduleBitmap]);

  const bitmapStrToDays = (bitmapStr) => {
    let _days = [];
    for (var i = 0; i < bitmapStr.length; i++) {
      if (bitmapStr.charAt(i) === "1") {
        _days.push(i);
      }
    }
    return _days;
  };

  const daysToBitmapStr = (days) => {
    // horrible JS way of character processing
    let _bitmapStr = "0000000";
    let _bitmap = _bitmapStr.split("");
    days.map((day) => {
      _bitmap[day] = "1";
    });
    return _bitmap.join("");
  };

  return (
    <div className={classes.container}>
      <ToggleButtonGroup
        className={classes.grouped}
        size="medium"
        value={days}
        onChange={(event, value) => {
          setDays(value);
          onScheduleBitmapChange(scheduleBitmapIndex, daysToBitmapStr(value));
        }}
      >
        {(isEmpty(customDays) ? DAYS : customDays).map((day, index) => (
          <ToggleButton
            disabled={disable}
            key={day.key}
            value={index}
            aria-label={day.key}
          >
            {day.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {scheduleBitmapIndex == 0 && scheduleBitmapLength < scheduleWeeks && (
        <Tooltip title={t("UserGroups.AddSchedule")}>
          <div>
            <IconButton
              disabled={disable}
              onClick={() => onAddScheduleBitmap(scheduleBitmapIndex)}
              className={classes.iconButton}
            >
              <AddIcon />
            </IconButton>
          </div>
        </Tooltip>
      )}
      {scheduleBitmapIndex != 0 && (
        <Tooltip title={t("UserGroups.DeleteSchedule")}>
          <div>
            <IconButton
              disabled={disable}
              onClick={() => onDeleteScheduleBitmap(scheduleBitmapIndex)}
              className={classes.iconButton}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
