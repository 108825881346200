import * as L from 'leaflet';

export class MapImageProjection {
  constructor() {
    this.width = 0.0;
    this.height = 0.0;
    this.scale = 1.0;
    this.center = [0.0, 0.0];
    this.size = 20.0;
  }

  init(width, height) {
    this.width = width;
    this.height = height;
    let fh = 180.0;
    let fw = 360.0;
    let rw = fw / this.width;
    let rh = fh / this.height;
    this.scale = Math.min(rw, rh);
    this.center = [this.width/2.0, this.height/2.0];
    this.size = 20.0;
  }

  inside(x, y) {
    if ((0 <= x && x <= this.width) &&
        (0 <= y && y <= this.height))
      return true;
    return false;
  }
  
  // find image bounds in fake geographic lat,lon ranges
  bounds() {
    let lonMin = (0 - this.center[0]) * this.scale;
    let lonMax = (this.width - this.center[0]) * this.scale;
    let latMin = (0 - this.center[1]) * this.scale;
    let latMax = (this.height - this.center[1]) * this.scale;
    let southWest = L.latLng(latMin, lonMin);
    let northEast = L.latLng(latMax, lonMax);
    return L.latLngBounds(southWest, northEast);
  }

  // convert image position to fake geographic lat,lon
  transform(x, y) {
    // image y corrdinate is inverted, i.e. upper left is (0,0)
    let lon = (x - this.center[0]) * this.scale;
    let lat = ((this.height - y) - this.center[1]) * this.scale;
    return L.latLng(lat, lon);
  }

  // convert image position to fake geographic lat,lon
  transformPoint(point) {
    // image y corrdinate is inverted, i.e. upper left is (0,0)
    let lon = (point[0] - this.center[0]) * this.scale;
    let lat = ((this.height - point[1]) - this.center[1]) * this.scale;
    return L.latLng(lat, lon);
  }

  // inverse transform: convert fake geographic lat,lon to image position
  inverse(geoPos) {
    // image y corrdinate is inverted, i.e. upper left is (0,0)
    let x = (geoPos.lng / this.scale) + this.center[0];
    let y = -((geoPos.lat / this.scale)  + this.center[1] - this.height);
    return L.point(x, y);
  }
}

