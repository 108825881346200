import { isEmpty } from "../../common";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const resourceRequiredFieldsAreEmpty = (resource) => {
  return  isEmpty(resource.displayName[i18n.language]);
};

const appointmentTypeRequiredFieldsAreEmpty = (appointmentType) => {
  return  isEmpty(appointmentType.displayName[i18n.language]);
};

const appointmentLengthIsZero = (appointmentLength) => {
  return appointmentLength === 0;
};

const emailIsInvalid = (emailAddress) => {
  if(!isEmpty(emailAddress)){
    return !EMAIL_REGEX.test(String(emailAddress).toLowerCase());
  }
  return false;
};

// const resourceHasMultipleLocations = (resource) => {
//   return resource.attachedTo.length > 1;
// }

export const validateResource = (resource) => {
  const _errorMessages = [];

  if(resourceRequiredFieldsAreEmpty(resource)){
    _errorMessages.push(i18n.t("Resources.RequiredFields"));
  }

  if(emailIsInvalid(resource.externalEmail)){
    _errorMessages.push(i18n.t("Resources.InvalidEmail"));
  }

  // if(resourceHasMultipleLocations(resource)){
  //   _errorMessages.push(i18n.t("Resources.MoreThanOneLocation"));
  // }

  return _errorMessages;
};

export const validateAppointmentType = (appointmentType) => {
  const _errorMessages = [];

  if(appointmentTypeRequiredFieldsAreEmpty(appointmentType)){
    _errorMessages.push(i18n.t("Appointments.RequiredFields"));
  }

  if(appointmentLengthIsZero(appointmentType.appointmentLength)){
    _errorMessages.push(i18n.t("Appointments.AppointmentLengthInvalid"));
  }

  return _errorMessages;
};

export default withTranslation()(validateResource, validateAppointmentType);