import React from "react";
import { Route } from "react-router";
import { AppLayout } from "./AppLayout";
import { Redirect } from "react-router";
import { useLocalStorage } from "../hooks/useLocalStorage.js";

export const PublicRoute = ({ children, path, ...rest }) => {
  const storage = useLocalStorage();

  if (storage.getUserID() && path === "/signin") {
    return <Redirect to="/" />;
  } else if (storage.getUserID() && path === "/signup") {
    return <Redirect to="/" />;
  } else {
    return (
      <AppLayout>
        <Route {...rest}>{children}</Route>
      </AppLayout>
    );
  }
};
