import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAvailableLanguages } from "../../common";
import { makeStyles, Tooltip } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center"
  },
  selectedButton: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: `${theme.palette.primary.contrastText}`,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`
    }
  },
  unselectedButton: {
    fontWeight: "normal"
  },
  globeIcon: {
    color: "#767676",
    marginRight: "10px"
  }
}));

export const LanguageSelector = ({ controller }) => {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const [languageOptions, setLanguageOptions] = useState([]);
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    populateLanguageOptions();
    controller && controller.set(language);
  }, [language]);

  const handleToggleLanguage = (event, newLanguage) => {
    setLanguage(newLanguage);
  };

  const getButtonStyle = (value) => {
    return value === language ? "selectedButton" : "unselectedButton";
  };

  const populateLanguageOptions = () => {
    const _options = [];
    const availableLanguages = getAvailableLanguages();
    availableLanguages.map((lang) =>
      _options.push(
        <Tooltip title={lang.label} key={`${lang.value}Option`}>
          <ToggleButton
            value={lang.value}
            size="small"
            className={classes[getButtonStyle(lang.value)]}
          >
            {lang.value.toUpperCase()}
          </ToggleButton>
        </Tooltip>
      )
    );
    setLanguageOptions(_options);
  };

  return (
    <div className={classes.container}>
      <Tooltip title={t("MultiLanguage.MultiLanguageFormTooltip")}>
        <LanguageIcon className={classes.globeIcon} />
      </Tooltip>
      <ToggleButtonGroup
        value={language}
        orientation="horizontal"
        exclusive
        onChange={handleToggleLanguage}
        aria-label="text alignment"
      >
        {languageOptions}
      </ToggleButtonGroup>
    </div>
  );
};
