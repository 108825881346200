import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import typography from "../../theme/typography";
import { grey } from "@material-ui/core/colors";
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from "../../views/common/ErrorAlert";
import { LanguageSelector } from "../MultiLanguage/LanguageSelector";

const useStyles = makeStyles((theme) => ({
  alert: {
    margin: theme.spacing(1, 0)
  },
  title: {
    "& h2": {
      display: 'flex',
      justifyContent: 'space-between',
      ...typography.h3
    }
  },
  description: {
    marginBottom: "20px"
  },
  actions: {
    padding: theme.spacing(1, 3),
    margin: theme.spacing(2, 0)
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  content: {
    paddingBottom: theme.spacing(2)
  }
}));

function AddEditDialog({
  createMode,
  userName,
  title,
  enableLanguageSelector,
  errorMessage,
  setErrorMessage,
  errorMessages,
  setErrorMessages,
  description,
  children,
  onCancel,
  onSave = () => {},
  loading,
  disableSave,
  language,
  setLanguage,
  formId,
  overrideSaveButtonLabel,
  additionalButtons
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm" className={classes.root}>
      <DialogTitle className={classes.title}>
        {(createMode ? t("Commons.Add_") : t("Commons.Edit_")) + 
        (userName ? " " + userName : title)}
        {enableLanguageSelector && (
          <LanguageSelector controller={{ value: language, set: setLanguage}}/>
        )}
      </DialogTitle>
      {errorMessage && setErrorMessage && (
        <Alert
          className={classes.alert}
          severity="error"
          onClose={() => {
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </Alert>
      )}
      {errorMessages && errorMessages.length > 0 && setErrorMessages && 
        <div style={{ margin: '0 1rem' }}>
          <ErrorAlert errorMessages={errorMessages} onCloseAlert={setErrorMessages} />
        </div>
      }
      <DialogContent className={classes.content}>
        {createMode && description && <Typography variant="body2" className={classes.description}>
          {description}
        </Typography>}
        <Grid
          container
          direction="column"
          spacing={3}
          className={classes.container}
        >
          {children}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={() => onCancel(null)}>{t("Commons.Cancel")}</Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading || disableSave}
          {...(formId
            ? { type: 'submit', form: formId }
            : { onClick: onSave }
          )}
        >
          {overrideSaveButtonLabel ?? t("Commons.Save")}
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
        {additionalButtons}
      </DialogActions>
    </Dialog>
  );
}

AddEditDialog.defaultProps = {
  loading: false
};

export default AddEditDialog;