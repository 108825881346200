import React, { useState } from "react";
import {
  Button,
  makeStyles,
  Typography,
  TextField,
  Card,
  Tooltip
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import { CHECK_IN_REASONS_ACTIONS } from "../../../constants";
import { MultiLanguageInputField } from "../../../components/MultiLanguage/MultiLanguageInputField";
import { LanguageSelector } from "../../../components/MultiLanguage/LanguageSelector";
import UserGroupSelector from "../../../components/Selectors/UserGroupSelector";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
    gap: "1rem"
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  addButton: {
    padding: 5,
    minWidth: 0
  },
  card: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem"
  }
});

function CheckInReasons({ checkInReasons, dispatch, groupMap }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const addReason = () =>
    dispatch({ type: CHECK_IN_REASONS_ACTIONS.ADD_REASON });

  const onReasonIdChange = (value, index) =>
    dispatch({
      type: CHECK_IN_REASONS_ACTIONS.CHANGE_REASON_ID,
      payload: { value, index }
    });

  const onDisplayNameChange = (value, index, language) =>
    dispatch({
      type: CHECK_IN_REASONS_ACTIONS.CHANGE_DISPLAY_NAME,
      payload: { value, index, language }
    });
  
  const onVisiteeGroupsChange = (value, index) => 
    dispatch({
      type: CHECK_IN_REASONS_ACTIONS.CHANGE_VISITEE_GROUPS,
      payload: { value, index }
    });

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading}>
        <Typography variant="h5">{t("Gate.CheckInReasons")}</Typography>
        <div className={classes.controls}>
          <LanguageSelector
            controller={{
              value: language,
              set: setLanguage
            }}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.addButton}
            onClick={addReason}
          >
            <AddIcon />
          </Button>
        </div>
      </div>
      {checkInReasons.map((reason, index) => (
        <Card key={index} className={classes.card}>
          <Tooltip title={t("Gate.ReasonKeyTooltip")} placement="top" arrow>
            <TextField
              variant="outlined"
              label={t("Gate.ReasonKey")}
              value={reason.id}
              fullWidth
              required
              onChange={(event) => onReasonIdChange(event.target.value, index)}
            />
          </Tooltip>
          <MultiLanguageInputField
            label={t("Gate.DisplayName")}
            labelSize={90}
            controller={{
              value: reason.displayName,
              set: (event, language) =>
                onDisplayNameChange(event.target.value, index, language),
              lang: language
            }}
          />
          <Tooltip title={t("Gate.V2Feature")} placement="top" arrow>
            <div>
              <UserGroupSelector
                contentList={Object.keys(groupMap)}
                labelKey={t("Gate.VisiteeGroups")}
                controller={{
                  value: reason.visiteeGroups ? reason.visiteeGroups : [],
                  setter: (newValue) => onVisiteeGroupsChange(newValue, index)
                }}
              />
            </div>
          </Tooltip>
        </Card>
      ))}
    </div>
  );
}

export default CheckInReasons;
