import React from "react";
import PropTypes from "prop-types";
import { useCompanyService } from "../../services/useCompanyService";
import BaseSelector from "./BaseSelector";

const UserGroupSelector = ({ controller, labelKey, includeAccessPolicyGroup }) => {
  const companyService = useCompanyService();

  const optionLabelFormater = (userGroup) => {
    return userGroup.name;
  };

  const getFormattedUserGroups = async () => {
    let groups = await companyService.getUserGroups(includeAccessPolicyGroup);
    return groups;
  };

  return (
    <BaseSelector
      _controller={controller}
      _labelKey={labelKey}
      _ajaxMethod={getFormattedUserGroups}
      _optionLabelFormater={optionLabelFormater}
      _transform={{
        fromFireStore: (userGroup, list) => {
          const groups = list.find((g) => g.userGroupId === userGroup);
          return groups;
        },
        toFireStore: (userGroup) => {
          return userGroup.userGroupId;
        }
      }}
      _idFieldName={"name"}
    />
  );
};

UserGroupSelector.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default UserGroupSelector;