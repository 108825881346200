import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { getMultiLanguageContent } from "../../common";
import { validateAppointmentType } from "./ResourceFormValidator.js";
import { ErrorAlert } from "../common/ErrorAlert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TimeAmountPicker from "../../components/ResourceManagement/TimeAmountPicker";
import {
  Grid,
  Button,
  FormLabel,
  FormControl,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: theme.spacing(2)
  },
  fields: {
    marginBottom: theme.spacing(2)
  },
  appointmentLength: {
    paddingTop: "1px !important",
    paddingBottom: "23px !important"
  },
  startTimeLabel: {
    marginBottom: "6px"
  }
}));

export const AppointmentTypeEditor = ({
  appointmentTypeInfo,
  actionButtons,
  spinnerOn
}) => {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const [appointmentType, setAppointmentType] = useState(
    appointmentTypeInfo.data
  );
  const [errorMessages, setErrorMessages] = useState([]);

  const { cancel, save } = actionButtons;

  const onFieldChange = (field, value) => {
    let newState = { ...appointmentType };
    newState[field] = value;
    setAppointmentType(newState);
  };

  const onMultiLanguageFieldChange = (field, value) => {
    let newState = { ...appointmentType };
    newState[field][i18n.language] = value;
    setAppointmentType(newState);
  };

  const onAppointmentLengthChange = (value) => {
    const newValue = enforceTimeBoundaries(parseInt(value));
    onFieldChange("appointmentLength", newValue);
  };

  const enforceTimeBoundaries = (timeInMinutes) => {
    const MIN_PER_DAY = 24 * 60;
    // prettier-ignore
    return timeInMinutes < 0 ? 0 : timeInMinutes > MIN_PER_DAY ? MIN_PER_DAY : timeInMinutes;
  };

  const onSave = () => {
    const _errorMessages = validateAppointmentType(appointmentType);
    if (_errorMessages.length === 0) {
      return save(appointmentType);
    }
    setErrorMessages(_errorMessages);
  };

  const renderTitleSection = () => {
    if (appointmentTypeInfo.createMode) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h4">
              {t("Appointments.CreateNewAppointment")}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      appointmentType && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h4">
              {getMultiLanguageContent(appointmentType.displayName)}
            </Typography>
            <Typography variant="caption">{`id: ${appointmentType.appointmentTypeId}`}</Typography>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      )
    );
  };

  return (
    <Dialog
      open={appointmentTypeInfo.openForm}
      fullWidth={true}
      maxWidth="md"
      className={classes.root}
    >
      <DialogTitle>{renderTitleSection()}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={3} className={classes.fields}>
          {errorMessages.length > 0 && (
            <Grid item xs={12}>
              <ErrorAlert
                errorMessages={errorMessages}
                onCloseAlert={setErrorMessages}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <TextField
              fullWidth
              label={t("Appointments.DisplayName")}
              defaultValue={getMultiLanguageContent(
                appointmentType.displayName
              )}
              variant="outlined"
              onChange={(e) => {
                onMultiLanguageFieldChange("displayName", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label={t("Appointments.Description")}
              defaultValue={getMultiLanguageContent(
                appointmentType.description
              )}
              variant="outlined"
              onChange={(e) => {
                onMultiLanguageFieldChange("description", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <FormControl component="fieldset" variant="standard" fullWidth>
              <FormLabel component="legend" className={classes.startTimeLabel}>
                {t("Appointments.AvailableStartTimes")}
              </FormLabel>
              <Autocomplete
                multiple
                freeSolo
                limitTags={4}
                className={classes.autoComplete}
                disableClearable={true}
                options={[]}
                defaultValue={appointmentType.availableStartTimes}
                filterSelectedOptions
                onChange={(event, newValues) =>
                  onFieldChange("availableStartTimes", newValues)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t("Appointments.StartTime")}
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TimeAmountPicker
              titleKey={"Appointments.AppointmentLength"}
              display={{
                value: appointmentType.appointmentLength,
                set: onAppointmentLengthChange
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={cancel}>{t("Commons.Cancel")}</Button>
        <Button
          onClick={onSave}
          color="primary"
          variant="contained"
          disabled={spinnerOn}
        >
          {t("Commons.Save")}
        </Button>
        {spinnerOn && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </DialogActions>
    </Dialog>
  );
};
