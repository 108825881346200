import React, { useMemo } from "react";
import { Marker, Tooltip, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-easybutton";
import "leaflet-easybutton/src/easy-button.css";
import { insertIcon } from "./MapLeafletIcons";
import { LocationMarkerEditPopup } from "./LocationMarkerEditPopup";

export const MapLeafletInsertLocations = ({
  map,
  mapImageProjection,
  locationPrefix,
  locationSequence,
  setLocationSequence,
  addLocations,
  setAddLocations,
  insertMode
}) => {

  const padNumber = (num, size) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  };

  const isPointMoved = (oldPoint, newPoint, tol) => {
    if (
      Math.abs(oldPoint[0] - newPoint[0]) > tol ||
      Math.abs(oldPoint[1] - newPoint[1]) > tol
    ) {
      return true;
    }
    return false;
  };

  // this useMapEvents will capture the leaflet map events
  // for creating a new inserted location
  useMapEvents({
    click(e) {
      if (insertMode) {
        let seq = padNumber(parseInt(locationSequence), 3);
        let point = mapImageProjection.inverse(e.latlng);
        let _location = {
          displayName: `${locationPrefix}${seq}`,
          point: [point.x, point.y]
        };

        // Beware that addLocation (inserted) which is not a fully populated Location document
        // it only contains latlng (in geographic coordinate for display) and
        // location info for displayName and point (in image coordinate)
        let _addLocation = {
          latlng: e.latlng,
          location: _location
        };

        setLocationSequence(parseInt(locationSequence) + 1);
        setAddLocations([...addLocations, _addLocation]);
      }
    }
  });

  // after we inserted the location marker, this event handler
  // is taking over to handle click and drag events of the marker
  const addLocationsEventHandlers = useMemo((e) => ({
    click: (e) => {
      if (insertMode) {
        // console.log('click!');
      }
    },
    dragend: (e) => {
      let marker = e.target;
      let latlng = marker.getLatLng();
      if (marker.options.data) {
        let index = marker.options.data.index;
        if (index < addLocations.length) {
          let pos = mapImageProjection.inverse(latlng);
          let oldPoint = addLocations[index].location.point;
          let newPoint = [pos.x, pos.y];
          if (isPointMoved(oldPoint, newPoint, 1.0)) {
            addLocations[index].latlng = latlng;
            addLocations[index].location.point = newPoint;
          }
        }
      }
    }
  }));

  const saveAddLocation = async (seat) => {
    setAddLocations([...addLocations]);
  };

  const removeAddLocation = async (seat) => {
    map.closePopup();
    for (var i = 0; i < addLocations.length; i++) {
      if (addLocations[i].location.displayName === seat.displayName) {
        addLocations.splice(i, 1);
      }
    }
    setAddLocations([...addLocations]);
  };

  return (
    <>
      {addLocations.length > 0 &&
        addLocations.map((addLocation) => {
          return (
            <Marker
              key={addLocation.location.displayName}
              position={addLocation.latlng}
              draggable={true}
              eventHandlers={addLocationsEventHandlers}
              icon={insertIcon}
            >
              <Tooltip direction="top" offset={[0, -30]} opacity={0.8}>
                {addLocation.location.displayName}
              </Tooltip>
              <LocationMarkerEditPopup
                key={addLocation.location.displayName}
                item={addLocation.location}
                itemPoint={addLocation.location.point}
                saveEditedLocation={saveAddLocation}
                removeEditedLocation={removeAddLocation}
              />
            </Marker>
          );
        })}
    </>
  );
};
