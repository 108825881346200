import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useHiveApiService } from "../../services/useHiveApiService";
import { setErrorMessage } from "../../actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { isEmpty } from "../../common";
import { useCompanyService } from "../../services/useCompanyService";

import Grid from "@material-ui/core/Grid";
import InsertIconOn from "@material-ui/icons/AddLocation";
import InsertIconOff from "@material-ui/icons/AddLocationOutlined";
import ClearIcon from "@material-ui/icons/ClearAll";
import SaveIcon from "@material-ui/icons/Save";
import { Button, TextField, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: "primary",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  locationInsertGrid: {
    paddingTop: "5px",
    marginBottom: "10px",
    backgroundColor: "#ddd"
  },
  locationInsertItems: {
    margin: "6px"
  },
  leafletMouseMarker: {
    backgroundColor: "#fff",
    cursor: "crosshair"
  }
}));

export const LocationInsertMode = ({
  location,
  floorMap,
  insertMode,
  setInsertMode,
  locationPrefix,
  setLocationPrefix,
  locationSequence,
  setLocationSequence,
  addLocations,
  setAddLocations,
  seats,
  setSeats,
  reloadLocations
}) => {
  const hiveApi = useHiveApiService();
  const companyService = useCompanyService();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleInsertMode = () => {
    // toggle insert mode
    setInsertMode(!insertMode);
  };

  const handleClear = () => {
    setAddLocations([]);
  };

  const handleSave = async () => {
    let _addLocations = [];
    addLocations.map((addLocation) => {
      _addLocations.push(addLocation.location);
    });

    const requestBody = {
      data: {
        locationId: location.locationId,
        coordinateLevel: floorMap.floorMapLocation.locationType,
        addLocations: _addLocations
      }
    };

    setIsLoading(true);
    await hiveApi.saveInsertedLocations(requestBody).then((response) => {
      if (response.success) {
        if (!isEmpty(response.data)) {
          let _seats = [...seats, ...response.data.locations];
          setSeats(_seats);
        }
        setAddLocations([]);
        setIsLoading(false);

        // from Locations.jsx - which force reloading all children from the given parent location Id
        reloadLocations(location.locationId);
        updateLocationsDropdownList();
      } else {
        dispatch(setErrorMessage(t("Errors.General")));
        setIsLoading(false);
      }
    });
  };

  const updateLocationsDropdownList = () => {
    return companyService.updateLocationsDropdownList();
  };

  return (
    <Grid container className={classes.locationInsertGrid}>
      <Grid item xs={2}>
        <TextField
          value={locationPrefix}
          onChange={(event) => setLocationPrefix(event.target.value)}
          variant="outlined"
          size="small"
          placeholder={t("Locations.LocationPrefixPlaceHolder")}
          label={t("Locations.LocationPrefix")}
          className={classes.locationInsertItems}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          value={locationSequence}
          onChange={(event) => setLocationSequence(event.target.value)}
          variant="outlined"
          size="small"
          placeholder={t("Locations.LocationSequencePlaceHolder")}
          label={t("Locations.LocationSequence")}
          className={classes.locationInsertItems}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          value={addLocations.length}
          variant="outlined"
          size="small"
          label={t("Locations.LocationInserted")}
          InputProps={{ readOnly: true }}
          className={classes.locationInsertItems}
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container justify={"flex-end"}>
          <Button
            onClick={handleInsertMode}
            disabled={isLoading}
            variant="contained"
            color={insertMode ? "secondary" : "primary"}
            startIcon={
              insertMode ? <InsertIconOn color="error" /> : <InsertIconOff />
            }
            className={classes.locationInsertItems}
          >
            {insertMode
              ? t("Locations.LocationInsertModeOn")
              : t("Locations.LocationInsertModeOff")}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress}>
                {" "}
              </CircularProgress>
            )}
          </Button>
          <Button
            onClick={handleClear}
            disabled={isLoading || addLocations.length <= 0}
            variant="contained"
            color="primary"
            startIcon={<ClearIcon />}
            className={classes.locationInsertItems}
          >
            {t("Locations.LocationClear")}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress}>
                {" "}
              </CircularProgress>
            )}
          </Button>
          <Button
            onClick={handleSave}
            disabled={isLoading || addLocations.length <= 0}
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            className={classes.locationInsertItems}
          >
            {t("Locations.Save")}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress}>
                {" "}
              </CircularProgress>
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
