import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { colourDefaults } from "../CompanyInfo/VisualCustomization";
import { Cell, Tooltip, Legend, Pie, PieChart } from "recharts";
import { isEmpty, toPascalCase } from "../../common";
import { STYLE } from "../../constants";

const useStyles = makeStyles(() => ({
  donutChartCenterText: {
    fontSize: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: -9,
    position: "absolute",
    zIndex: 0
  },
  pieChart: {
    position: "absolute",
    zIndex: 0
  },
  chartContainer: {
    position: "relative",
    zIndex: 0
  }
}));

const DonutChartDisplay = React.memo(function DonutChartDisplay(props) {
  const defaultInnerRadius = 75;
  const defaultOuterRadius = 90;
  const { classes, data, dataKey, centerText, colors, emptyDataMessage, width, height, innerRadius, outerRadius, paddingAngle, cellKeyTemplate } = props;
  const defaultClasses = useStyles();
  const { t } = useTranslation();
  const validData = data.filter(value => value[dataKey] > 0);
  const validDataLength = validData.length;
  const chartColors = isEmpty(colors) ? [colourDefaults.primary, colourDefaults.secondary] : colors;

  if (validDataLength > 0) {
    return (
      <>
        <div className={defaultClasses.chartContainer}>
          {/* Center text must be placed before PieChart for Tooltip to work */}
          <Typography style={{width: width, height: height}} variant='h3' className={classes.donutChartCenterText || defaultClasses.donutChartCenterText}>
            {centerText}
          </Typography>
          <PieChart width={width} height={height} className={defaultClasses.pieChart}>
            <Pie
              data={validData}
              innerRadius={innerRadius || defaultInnerRadius}
              outerRadius={outerRadius || defaultOuterRadius}
              paddingAngle={validDataLength > 1 ? paddingAngle || 3 : 0}
              dataKey={dataKey}
            >
              {validData.map((entry, index) => (
                <Cell key={cellKeyTemplate + index} fill={chartColors[index % chartColors.length]} name={t('Commons.' + toPascalCase(entry.name))}/>
              ))}
            </Pie>
            <Tooltip wrapperStyle={{fontFamily: STYLE.fontFamily}}/>
            <Legend wrapperStyle={{fontFamily: STYLE.fontFamily}}/>
          </PieChart>
        </div>
      </>
    );
  } else {
    return (
      <Typography variant='h5'>
        {emptyDataMessage}
      </Typography>
    );
  }
});

export default DonutChartDisplay;