import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import MailingListEditor from "./MailingListEditor";
import UserSelectorPlusEmailInput from "../../components/Selectors/UserSelectorPlusEmailInput";

const HealthScreening = React.memo(
  ({
    className,
    company,
    companyService,
    setCompanyField,
    userGroups,
    usersList,
    ...rest
  }) => {
    const { t } = useTranslation();
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
      setSelectedUsers(
        company.healthScreeningMailingList
          ? company.healthScreeningMailingList
          : []
      );
    }, [company.healthScreeningMailingList]);

    const transformUser = {
      fromFireStore: (user, list) => {
        return list.find((u) => u.userId === user.userId) || user;
      },
      toFireStore: (users) => {
        return users.map(({ displayName = "", email, userId }) => {
          const displayNameSplit = displayName.split(" ");
          const firstName = displayNameSplit[0] || "";
          const lastName = displayNameSplit[1] || "";

          return {
            firstName,
            lastName,
            email,
            userId
          };
        });
      }
    };

    const onHealthScreeningMailingListChange = (newValue) => {
      setCompanyField("healthScreeningMailingList", newValue);
    };

    return (
      <Card className={className} {...rest}>
        <CardHeader
          title={t("CompanyInfo.HealthScreeningMailingList")}
          subheader={t("CompanyInfo.HealthScreeningMailingListSubHeader")}
        />
        <CardContent>
          <UserSelectorPlusEmailInput
            labelKey={"Commons.Users"}
            transform={transformUser}
            contentList={usersList}
            controller={{
              value: selectedUsers,
              set: onHealthScreeningMailingListChange
            }}
          />
          <MailingListEditor
            userGroups={userGroups}
            companyService={companyService}
          />
        </CardContent>
      </Card>
    );
  }
);

export default HealthScreening;
