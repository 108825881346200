import React, { useState, useRef } from "react";
import {
  Tooltip,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import EditIcon from "@material-ui/icons/Edit";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import ResetIcon from "@material-ui/icons/RotateLeft";
import VpnKey from "@material-ui/icons/VpnKey";
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "@material-ui/icons/FileCopyRounded";
import { setSuccessMessage } from "../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";

function ActionButtons({
  user,
  index,
  canManagePassword,
  setEditUser,
  setDeleteUserInfo,
  setEditUserSeats,
  setUserPassword,
  setResetPasswordInfo,
  isSuperUser
}) {
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const edit = () => setEditUser({ user, index });
  const deleteUser = () => setDeleteUserInfo({ user, index });
  const editSeats = () => setEditUserSeats({ user, index });
  const changeUserPassword = () => setUserPassword({ user, index });
  const resetPassword = () => setResetPasswordInfo({ user });

  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  const iconStyle = { padding: 0, margin: 8 };

  const ActionButton = ({ onClick, tooltip, icon }) => (
    <Tooltip title={tooltip}>
      <IconButton onClick={onClick} style={iconStyle}>
        {icon}
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <ActionButton
        onClick={edit}
        tooltip={t("Commons.Edit")}
        icon={<EditIcon />}
      />
      <ActionButton
        onClick={editSeats}
        tooltip={t("Users.ManageSeat")}
        icon={<EventSeatIcon />}
      />
      <Button ref={anchorRef} onClick={handleToggle}>
        {t("Users.More")}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open}>
                  {isSuperUser && (
                    <MenuItem>
                      <Tooltip title={t("Users.CopyUserId")}>
                        <IconButton>
                          <CopyToClipboard
                            onCopy={() => {
                              dispatch(
                                setSuccessMessage(t("Success.UserIDClipboard"))
                              );
                            }}
                            text={user.userId}
                          >
                            <CopyIcon />
                          </CopyToClipboard>
                        </IconButton>
                      </Tooltip>
                    </MenuItem>
                  )}
                  {canManagePassword(user) && (
                    <MenuItem>
                      <ActionButton
                        onClick={resetPassword}
                        tooltip={t("Users.ResetPassword")}
                        icon={<ResetIcon />}
                      />
                    </MenuItem>
                  )}
                  {(isSuperUser || canManagePassword(user)) && (
                    <MenuItem>
                      <ActionButton
                        onClick={changeUserPassword}
                        tooltip={t("Users.SetPassword")}
                        icon={<VpnKey />}
                      />
                    </MenuItem>
                  )}
                  {isSuperUser && (
                    <MenuItem>
                      <ActionButton
                        onClick={deleteUser}
                        tooltip={t("Users.DeleteUser")}
                        icon={<DeleteIcon />}
                      />
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default ActionButtons;
