import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getMultiLanguageContent } from "../../common";
import {
  makeStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Tooltip
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  adornment: {
    backgroundColor: `${theme.palette.primary.main}`,
    borderRadius: "4px",
    fontWeight: "bold",
    padding: "4px"
  }
}));

export const MultiLanguageInputField = ({
  controller,
  label,
  labelSize,
  // (Ilya) index is used for setting value
  // in AddQuestions component
  index = null,
  multiline = false
}) => {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    setLanguage(controller.lang);
  }, [controller.lang]);

  const calculateLabelWidth = (numberOfCharacters) => {
    return parseInt(numberOfCharacters / 0.15);
  };

  const labelWidth = !labelSize ? calculateLabelWidth(label.length) : labelSize;

  return (
    <div className={classes.container}>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="multi-language-field">{label}</InputLabel>
        <OutlinedInput
          id="multi-language-field"
          labelWidth={labelWidth}
          multiline={multiline}
          value={getMultiLanguageContent(controller.value, language)}
          onChange={(e) => controller.set(e, language, index)}
          startAdornment={
            <InputAdornment position="start">
              <Tooltip title={t("MultiLanguage.MultiLanguageFieldTooltip")}>
                <div className={classes.adornment}>
                  {language && language.toUpperCase()}
                </div>
              </Tooltip>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
};
