import MuiPaper from "./MuiPaper";
import MuiCardHeader from "./MuiCardHeader";
import MuiButton from "./MuiButton";
import MuiTableHead from "./MuiTableHead";
import MuiTableRow from "./MuiTableRow";
import MuiTableCell from "./MuiTableCell";
import MuiAlert from "./MuiAlert";
import MuiFormLabel from "./MuiFormLabel";

export default {
  MuiButton,
  MuiPaper,
  MuiCardHeader,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiAlert,
  MuiFormLabel
};
