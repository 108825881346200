import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyService } from "../../services/useCompanyService";
import QuickAddDialog from "../../components/QuickAddDialog/QuickAddDialog";
import { isEmpty } from "../../common";

const columns = [
  { id: 'name', label: 'Name', minWidth: 100, getter: (user) => user.displayName },
  { id: 'email', label: 'E-Mail', minWidth: 150, getter: (user) => user.email }
];

export const QuickAddUserGroup = ({ userGroup, close }) => {
  const companyService = useCompanyService();
  const { t } = useTranslation();

  const [usersList, setUsersList] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    const loadUsersList = async () => {
      const _usersList =
        await companyService.getUsersFromDropdownListCollection();
      setUsersList(_usersList);
    };
    usersList.length === 0 && loadUsersList();
  }, []);

  const add = async (user) => {
    const _user = await companyService.getUserById(user.userId);

    if (_user) {
      _user.groups.push(userGroup.userGroupId);
      _user.userId = user.userId;
      await companyService.updateUser(_user).then((result) => {
        if (result.data.success) {
          const _usersList = [...usersList];
          _usersList.find(item => item === user).groups.push(userGroup.userGroupId);
          setUsersList(_usersList);
          userGroup.users = isEmpty(userGroup.users) ? {} : userGroup.users;
          userGroup.users[user.email] = {email: user.email, name: user.displayName, userId: user.userId};
        } else {
          const _errorMessages = [...errorMessages, t("Errors.UpdateUserGroup")];
          setErrorMessages(_errorMessages);
        }
      });
    } else {
      const _errorMessages = [...errorMessages, t("Errors.UpdateUserGroup")];
      setErrorMessages(_errorMessages);
    }
  };

  const remove = async (user) => {
    const _user = await companyService.getUserById(user.userId);

    if (_user) {
      _user.groups = _user.groups.filter((group) => group !== userGroup.userGroupId);
      _user.userId = user.userId;
      await companyService.updateUser(_user).then((result) => {
        if (result.data.success) {
          const _usersList = [...usersList];
          _usersList.find(item => item === user).groups = [..._user.groups];
          setUsersList(_usersList);
          userGroup.users[user.email] && delete userGroup.users[user.email];
        } else {
          const _errorMessages = [...errorMessages, t("Errors.UpdateUserGroup")];
          setErrorMessages(_errorMessages);
        }
      });
    } else {
      const _errorMessages = [...errorMessages, t("Errors.UpdateUserGroup")];
      setErrorMessages(_errorMessages);
    }
  };

  const filterUsers = (user, search) => {
    let searchVal = search.toLowerCase();
    return user.email.toLowerCase().includes(searchVal) ||
    user.displayName.toLowerCase().includes(searchVal);
  };

  return (
    <div>
      {usersList.length > 0 && 
        <QuickAddDialog
          title={userGroup.name}
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
          optionsList={usersList}
          onAdd={add}
          onRemove={remove}
          onClose={() => close(userGroup)}
          innerText={t("UserGroups.Search")}
          optionsFilter={filterUsers}
          isOptionAdd={(user) => !user.groups.includes(userGroup.userGroupId)}
          columns={columns}
        />}
    </div>
  );
};