import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import { useCompanyService } from "../../services/useCompanyService";
import { useTranslation } from "react-i18next";
import { ToggleScheduleDays } from "./ToggleScheduleDays";
import { isEmpty } from "../../common";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import UserSelector from "../../components/Selectors/UserSelector";
import { ErrorAlert } from "../common/ErrorAlert";
import InfoIcon from "@material-ui/icons/Info";
import { setSuccessMessage, setErrorMessage } from "../../actions";
import { useDispatch } from "react-redux";

const formId = "edit-user-group";
const inputStyle = { margin: "0.5rem 0", marginBottom: "12px", marginTop: "12px" };

export const EditUserGroup = ({ createMode, userGroup, userGroups, close }) => {
  const SCHEDULE_WEEKS = 4;
  const SCHEDULE_DEFAULT_WEEKLY_BITMAP = "1111111";
  const companyService = useCompanyService();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [enableBookingApproval, setEnableBookingApproval] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState();
  const [editUserGroup, setEditUserGroup] = useState(
    !createMode
      ? userGroup
      : {
        name: "",
        admins: [],
        bookingApprovalsRequired: null,
        scheduleBitmap: []
      }
  );
  const [
    isInvalidBookingApprovalsRequired,
    setIsInvalidBookingApprovalsRequired
  ] = useState(false);

  useEffect(() => {
    const loadUsersList = async () => {
      const _userList =
        await companyService.getUsersFromDropdownListCollection();
      setUsersList(_userList);
    };
    isEmpty(usersList) && loadUsersList();
  }, []);

  useEffect(() => {
    if (isEmpty(editUserGroup.scheduleBitmap)) {
      let _userGroup = editUserGroup;
      _userGroup.scheduleBitmap = [SCHEDULE_DEFAULT_WEEKLY_BITMAP];
      setEditUserGroup(_userGroup);
    }

    const _selectedUser = getExistingAdmins();
    setSelectedUsers(_selectedUser);

    setIsInvalidBookingApprovalsRequired(
      parseInt(editUserGroup.bookingApprovalsRequired) > _selectedUser.length
    );
  }, [editUserGroup, usersList]);

  useEffect(() => {
    companyService
      .isBookingApprovalEnabled()
      .then((isBookingApprovalEnabled) => {
        setEnableBookingApproval(isBookingApprovalEnabled);
      });
  }, [companyService]);

  const onFieldChange = (field, event) => {
    let _editUserGroup = { ...editUserGroup };
    _editUserGroup[field] = event.target.value;
    setEditUserGroup(_editUserGroup);
  };

  const onScheduleBitmapChange = (index, scheduleBitmapStr) => {
    let _editUserGroup = { ...editUserGroup };
    _editUserGroup["scheduleBitmap"][index] = scheduleBitmapStr;
    setEditUserGroup(_editUserGroup);
  };

  const getExistingAdmins = () => {
    let admins = [];
    if (userGroup.admins && !isEmpty(usersList)) {
      userGroup.admins.forEach((admin) => {
        usersList.forEach((user) => {
          if (user.email.toLowerCase() === admin.toLowerCase()) {
            admins.push(user);
            return;
          }
        });
      });
    }
    return admins;
  };

  const onAddScheduleBitmap = (index) => {
    // clone the original object, force React to notice the change and re-render
    let _userGroup = { ...editUserGroup };
    _userGroup.scheduleBitmap.push(SCHEDULE_DEFAULT_WEEKLY_BITMAP);
    setEditUserGroup(_userGroup);
  };

  const onDeleteScheduleBitmap = (index) => {
    // clone the original object, force React to notice the change and re-render
    let _userGroup = { ...editUserGroup };
    _userGroup.scheduleBitmap.splice(index, 1);
    setEditUserGroup(_userGroup);
  };

  const transformUser = {
    fromFireStore: (user, list) => {
      return list.find((u) => u.email === user.email);
    },
    toFireStore: (user) => {
      return user;
    }
  };

  const checkNameForDuplicates = (name, isNewGroup) => {
    if (isNewGroup || name != userGroup.name) {
      return userGroups.find(userGroup => userGroup.name === name) ? true : false;
    }
    return false;
  };

  const save = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (createMode) {
      const newUserGroup = {
        name: editUserGroup.name,
        admins: selectedUsers ? selectedUsers.map((user) => user.email) : [],
        scheduleBitmap: editUserGroup.scheduleBitmap,
        users: {}
      };

      if (checkNameForDuplicates(newUserGroup.name, createMode)) {
        const _errorMessages = [];
        _errorMessages.push(t("UserGroups.UserGroupNameAlreadyExists"));
        setErrorMessages(_errorMessages);
        setLoading(false);
        return;
      }

      let bookingApprovalsRequired = parseInt(
        editUserGroup.bookingApprovalsRequired
      );
      newUserGroup.bookingApprovalsRequired =
        bookingApprovalsRequired && enableBookingApproval
          ? bookingApprovalsRequired
          : null;

      const response = await companyService.createUserGroup(newUserGroup);
      setLoading(false);
      if (response.data.success) {
        newUserGroup.userGroupId = response.data.userGroupId;
        dispatch(setSuccessMessage(t("Success.CreateUserGroup")));
        close(newUserGroup);
      } else {
        dispatch(setErrorMessage(t("Errors.CreateUserGroup")));
        close();
      }
    } else {
      if (selectedUsers) {
        editUserGroup.admins = selectedUsers.map((user) => user.email);
      }

      if (checkNameForDuplicates(editUserGroup.name, createMode)) {
        const _errorMessages = [];
        _errorMessages.push(t("UserGroups.UserGroupNameAlreadyExists"));
        setErrorMessages(_errorMessages);
        setLoading(false);
        return;
      }

      let bookingApprovalsRequired = parseInt(
        editUserGroup.bookingApprovalsRequired
      );
      editUserGroup.bookingApprovalsRequired =
        bookingApprovalsRequired && enableBookingApproval
          ? bookingApprovalsRequired
          : null;

      const response = await companyService.updateUserGroup(editUserGroup);
      setLoading(false);
      if (response.data.success) {
        dispatch(setSuccessMessage(t("Success.UpdateUserGroup")));
        close(editUserGroup);
      } else {
        dispatch(setErrorMessage(t("Errors.UpdateUserGroup")));
        close();
      }
    }
  };

  return (
    <AddEditDialog
      createMode={createMode}
      title={t("UserGroups.UserGroup")}
      formId={formId}
      disableSave={isInvalidBookingApprovalsRequired}
      onCancel={close}
      loading={loading}
    >
      {errorMessages.length > 0 && (
        <ErrorAlert
          errorMessages={errorMessages}
          onCloseAlert={setErrorMessages}
        />
      )}
      <form onSubmit={save} id={formId} style={{ margin: "0 0.5rem" }}>
        <TextField
          variant="outlined"
          label={t("Commons.Name")}
          value={editUserGroup.name}
          fullWidth
          onChange={(event) => {
            onFieldChange("name", event);
          }}
          required
          style={inputStyle}
        />
        <UserSelector
          contentList={usersList}
          transform={transformUser}
          labelKey={"UserGroups.AddAdmin"}
          controller={{
            value: selectedUsers,
            setter: setSelectedUsers
          }}
        />
        <TextField
          name="bookingApprovalsRequired"
          variant="outlined"
          InputLabelProps={{ style: { pointerEvents: "auto" } }}
          label={t("UserGroups.BookingApprovalsRequired")}
          type="number"
          value={editUserGroup.bookingApprovalsRequired}
          fullWidth
          disabled={!enableBookingApproval}
          helperText={
            isInvalidBookingApprovalsRequired ? (
              t("UserGroups.ApprovalsRequiredError")
            ) : (
              <p style={inputStyle}>
                <InfoIcon style={{ fontSize: 16 }} />{" "}
                <b>
                  {enableBookingApproval ? "" : t("Features.DisabledContent")}
                </b>{" "}
                {t("UserGroups.ApprovalsRequiredDescription")}
              </p>
            )
          }
          error={isInvalidBookingApprovalsRequired}
          onChange={(event) => {
            onFieldChange("bookingApprovalsRequired", event);
          }}
          style={inputStyle}
        />
        {editUserGroup &&
          editUserGroup.scheduleBitmap &&
          isEmpty(editUserGroup.isAccessPolicyGroup) &&
          editUserGroup.scheduleBitmap.map((bitmap, index) => {
            return (
              <>
                {editUserGroup.scheduleBitmap.length === 1 ? (
                  <Typography gutterBottom variant="h5">
                    {t("UserGroups.ScheduleDays")}
                  </Typography>
                ) : (
                  <Typography gutterBottom variant="h5">
                    {`${t("UserGroups.ScheduleDays")} ${index + 1}`}
                  </Typography>
                )}
                <ToggleScheduleDays
                  scheduleBitmapLength={editUserGroup.scheduleBitmap.length}
                  scheduleBitmapIndex={index}
                  scheduleBitmap={bitmap}
                  scheduleWeeks={SCHEDULE_WEEKS}
                  onScheduleBitmapChange={onScheduleBitmapChange}
                  onAddScheduleBitmap={onAddScheduleBitmap}
                  onDeleteScheduleBitmap={onDeleteScheduleBitmap}
                />
              </>
            );
          })}
      </form>
    </AddEditDialog>
  );
};
