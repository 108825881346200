import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  Grid
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { grey } from '@material-ui/core/colors';
import typography from '../../theme/typography';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  typography: {
    marginBottom: '1rem'
  },
  title: {
    marginBottom: theme.spacing(2),
    '& h2': {
      ...typography.h3
    }
  },
  actions: {
    padding: theme.spacing(1, 3),
    margin: theme.spacing(2, 0)
  },
  buttonProgress: {
    color: grey[700],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  warning: {
    marginTop: 24
  }
}));

function ConfirmDialog(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isConfirmAction, setIsConfirmAction] = useState(false);

  function confirmAction() {
    setIsConfirmAction(true);
    props.onConfirm();
  }

  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm">
      <DialogTitle>{props.title}</DialogTitle>
      {props.errorMessage && props.setErrorMessage && (
        <Alert
          className={classes.alert}
          severity="error"
          onClose={() => props.setErrorMessage('')}
        >
          {props.errorMessage}
        </Alert>
      )}
      <DialogContent>
        <Typography className={classes.typography}>{props.heading}</Typography>
        <Typography>{props.message}</Typography>
        {props.warning && (
          <Grid container spacing={1} className={classes.warning}>
            <Grid item xs={1}>
              <WarningIcon />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h5">{props.warning}</Typography>
              {props.warningInfo && (<Typography variant="caption">{props.warningInfo}</Typography>)}
            </Grid>
          </Grid>
        )}
        
        {props.children && props.children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={() => props.onCancel()}>{t('Commons.No')}</Button>
        <Button onClick={confirmAction} color="primary" variant="contained">
          {t('Commons.Yes')}{' '}
          {isConfirmAction && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
