import React, { useState, useEffect } from "react";
import { RESOURCE_STATES, TIME_UNIT_OPTIONS, WEEK_DAYS } from "../../constants";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import {
  isEmpty,
  isNullOrUndefined,
  getMultiLanguageContent
} from "../../common";
import DateTimeUtil from "../../utils/DateTimeUtil";
import { validateResource } from "./ResourceFormValidator.js";
import { ErrorAlert } from "../common/ErrorAlert";
import { hiveAppointmentUrl } from "../../firebase";
import DayHourRangePicker from "../../components/ResourceManagement/DayHourRangePicker";
import OperationTimeException from "../../components/ResourceManagement/OperationTimeException";
import {
  Grid,
  Link,
  Button,
  Divider,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress
} from "@material-ui/core";
import UserSelector from "../../components/Selectors/UserSelector";
import LocationSelector from "../../components/Selectors/LocationSelector";
import AppointmentTypeSelector from "../../components/Selectors/AppointmentTypeSelector";
import { MultiLanguageInputField } from "../../components/MultiLanguage/MultiLanguageInputField";
import { LanguageSelector } from "../../components/MultiLanguage/LanguageSelector";
import { useCompanyService } from "../../services/useCompanyService";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: theme.spacing(2)
  },
  fields: {
    marginBottom: theme.spacing(2)
  }
}));

export const ResourceEditor = ({
  resourceInfo,
  cancelFunc,
  saveFunc,
  spinnerOn,
  company
}) => {
  const classes = useStyles();
  const companyService = useCompanyService();
  const [t, i18n] = useTranslation();
  const [resource, setResource] = useState(resourceInfo.data);
  const [transformedOperationTimeTable, setTransformedOperationTimeTable] =
    useState([]);
  const [allTimeUnit, setAllTimeUnit] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [language, setLanguage] = useState(i18n.language);
  const [usersList, setUsersList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);

  useEffect(() => {
    isEmpty(transformedOperationTimeTable) && transformFromFireStoreToApp();
  }, [resource.unitOfTime, language]);

  useEffect(() => {
    isEmpty(allStates) && initSelectors();
    isEmpty(usersList) &&
      setUsersList(companyService.getUsersFromDropdownListCollection());
    isEmpty(locationsList) &&
      setLocationsList(companyService.getLocationsFromDropdownListCollection());
  }, []);

  const initSelectors = () => {
    setAllTimeUnit(wrapTimeUnitMenuItems());
    setAllStates(wrapStateMenuItems());
  };

  function wrapTimeUnitMenuItems() {
    return TIME_UNIT_OPTIONS.map((u) => {
      return (
        <MenuItem value={u} key={`timeUnitItem${u}`}>
          {DateTimeUtil.intMinToDisplayTime(u)}
        </MenuItem>
      );
    });
  }

  function wrapStateMenuItems() {
    return RESOURCE_STATES.map((s) => {
      return (
        <MenuItem value={s} key={`stateMenuItem${s}`}>
          {t(`Resources.${s}`)}
        </MenuItem>
      );
    });
  }

  const transformFromFireStoreToApp = () => {
    WEEK_DAYS.map((day) =>
      transformedOperationTimeTable.push(fireStoreToApp(day.name))
    );
  };

  const fireStoreToApp = (dayNane) => {
    let day = resource.operationTimeTable[dayNane];
    let start = 0,
      duration = 0;

    day.map((isInOperationTime) => {
      if (isInOperationTime) {
        ++duration;
      } else if (!isInOperationTime && duration === 0) {
        if (start === day.length && duration === 0) {
          start = 0;
        } else {
          ++start;
        }
      }
    });

    if (start === day.length && duration === 0) {
      return [];
    }

    return [start, start + duration];
  };

  const transformFromAppToFireStore = () => {
    WEEK_DAYS.map(
      (day, index) =>
        (resource.operationTimeTable[day.name] = appToFireStore(index))
    );
    resource.cutOffHours = parseInt(resource.cutOffHours);
    resource.capacity = parseInt(resource.capacity);
    resource.advanceAppointmentDays = parseInt(resource.advanceAppointmentDays);
  };

  const appToFireStore = (index) => {
    const MIN_PER_DAY = 24 * 60;
    let day = transformedOperationTimeTable[index];
    let arrayLength = MIN_PER_DAY / resource.unitOfTime;
    let timeTable = [];

    if (day[0] > 0 || day[1] > 0) {
      for (let i = 1; i <= arrayLength; i++) {
        if (i <= day[0] || i > day[1]) {
          timeTable.push(false);
        } else {
          timeTable.push(true);
        }
      }
    } else {
      for (let i = 1; i <= arrayLength; i++) {
        timeTable.push(false);
      }
    }

    return timeTable;
  };

  const onAppointmentTypeFieldChange = (newValue) => {
    onFieldChange("appointmentTypes", newValue);
  };

  const onAssociatedUsersFieldChange = (newValue) => {
    onFieldChange("associatedUsers", newValue);
  };

  const onAttachedToFieldChange = (newValue) => {
    onFieldChange("attachedTo", newValue);
  };

  const onFieldChange = (field, value) => {
    let newState = { ...resource };
    newState[field] = value;
    setResource(newState);
  };

  const onLatLngChange = (field, value) => {
    let newMapParam = !isNullOrUndefined(resource.mapParams)
      ? { ...resource.mapParams }
      : { lat: 0, lng: 0 };
    newMapParam[field] = parseFloat(value);
    onFieldChange("mapParams", newMapParam);
  };

  const onDescriptionChange = (e, language) => {
    onMultiLanguageFieldChange("description", e.target.value, language);
  };

  const onDisplayNameChange = (e, language) => {
    onMultiLanguageFieldChange("displayName", e.target.value, language);
  };

  const onMultiLanguageFieldChange = (field, value, language) => {
    let newState = { ...resource };
    newState[field][language] = value;
    setResource(newState);
  };

  const save = () => {
    const _errorMessages = validateResource(resource);
    if (_errorMessages.length === 0) {
      transformFromAppToFireStore();
      return saveFunc(resource);
    }
    setErrorMessages(_errorMessages);
  };

  const renderTitleSection = () => {
    if (resourceInfo.createMode) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <Typography variant="h4">
              {t("Resources.CreateNewReasource")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <LanguageSelector
              controller={{ value: language, set: setLanguage }}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <Typography variant="h4">
            {getMultiLanguageContent(resource.displayName)}
          </Typography>
          <Typography variant="caption">
            {"id: "}
            <Link
              target="_blank"
              href={`${hiveAppointmentUrl}/?company=${resource.companyId}&resource=${resource.resourceId}`}
            >
              {resource.resourceId}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <LanguageSelector
            controller={{ value: language, set: setLanguage }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={resourceInfo.openForm}
      fullWidth={true}
      maxWidth="md"
      className={classes.root}
    >
      <DialogTitle>{renderTitleSection()}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={3} className={classes.fields}>
          {errorMessages.length > 0 && (
            <Grid item xs={12}>
              <ErrorAlert
                errorMessages={errorMessages}
                onCloseAlert={setErrorMessages}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <MultiLanguageInputField
              label={t("Resources.DisplayName")}
              controller={{
                value: resource.displayName,
                set: onDisplayNameChange,
                lang: language
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t("Resources.ExternalEmail")}
              defaultValue={resource.externalEmail}
              variant="outlined"
              onChange={(e) => {
                onFieldChange("externalEmail", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="select-state-label">
                {t("Resources.State")}
              </InputLabel>
              <Select
                labelId="select-state-label"
                id="state-selector"
                label={t("Resources.State")}
                value={resource.state}
                width="100%"
                onChange={(e) => {
                  onFieldChange("state", e.target.value);
                }}
              >
                {allStates}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10}>
            <MultiLanguageInputField
              label={t("Resources.Description")}
              controller={{
                value: resource.description,
                set: onDescriptionChange,
                lang: language
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl
              disabled={!resourceInfo.createMode}
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="select-unitOfTime-label">
                {t("Resources.UnitOfTime")}
              </InputLabel>
              <Select
                labelId="select-unitOfTime-label"
                id="unitOfTime-selector"
                label={t("Resources.UnitOfTime")}
                value={resource.unitOfTime}
                width="100%"
                onChange={(e) => {
                  onFieldChange("unitOfTime", e.target.value);
                }}
              >
                {allTimeUnit}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <LocationSelector
              contentList={locationsList}
              company={company}
              labelKey={"Resources.AttachedTo"}
              controller={{
                value: resource.attachedTo,
                setter: onAttachedToFieldChange
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <UserSelector
              contentList={usersList}
              labelKey={"Appointments.NotifiedUsers"}
              controller={{
                value: resource.associatedUsers,
                setter: onAssociatedUsersFieldChange
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={t("Resources.AdvanceAppointmentDays")}
              defaultValue={resource.advanceAppointmentDays}
              variant="outlined"
              type="number"
              onChange={(e) => {
                onFieldChange("advanceAppointmentDays", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={t("Resources.CutOffHours")}
              defaultValue={resource.cutOffHours}
              variant="outlined"
              type="number"
              onChange={(e) => {
                onFieldChange("cutOffHours", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label={t("Resources.Capacity")}
              defaultValue={resource.capacity}
              variant="outlined"
              type="number"
              onChange={(e) => {
                onFieldChange("capacity", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <AppointmentTypeSelector
              labelKey={"Appointments.AppointmentTypes"}
              controller={{
                value: resource.appointmentTypes,
                setter: onAppointmentTypeFieldChange
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t("Resources.Address")}
              defaultValue={resource.address}
              variant="outlined"
              onChange={(e) => {
                onFieldChange("address", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={t("Resources.Latitude")}
              defaultValue={resource?.mapParams?.lat}
              variant="outlined"
              onChange={(e) => {
                onLatLngChange("lat", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={t("Resources.Longitude")}
              defaultValue={resource?.mapParams?.lng}
              variant="outlined"
              onChange={(e) => {
                onLatLngChange("lng", e.target.value);
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <Divider />
            <OperationTimeException data={resource.operationExceptionDates} />
          </Grid>
          {!isEmpty(resource.unitOfTime) && (
            <Grid item xs={12}>
              <Divider />
              <DayHourRangePicker
                titleKey={"Resources.OperationTime"}
                data={transformedOperationTimeTable}
                timeBaseInMinutes={resource.unitOfTime}
                setFunc={setTransformedOperationTimeTable}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={cancelFunc}>{t("Commons.Cancel")}</Button>
        <Button
          onClick={save}
          color="primary"
          variant="contained"
          disabled={spinnerOn}
        >
          {t("Commons.Save")}
        </Button>
        {spinnerOn && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </DialogActions>
    </Dialog>
  );
};
