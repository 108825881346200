import { firebase, database } from '../firebase';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useLocalStore } from "../hooks/useLocalStore";
import { startOfDay, endOfDay, format } from 'date-fns';
import { isEmpty } from '../common';
import { ROLES, BOOKING_STATES } from '../constants';
import CompanyUtil from '../utils/CompanyUtil';
import i18n from '../i18n';
import { findTimeZone, getZonedTime } from 'timezone-support';

export const useCompanyService = () => {
  const localStore = useLocalStore();
  const localStorage = useLocalStorage();
  const companyId = localStorage.getCompanyID();
  const companyRegion = localStorage.getHiveRegion();

  const getLocation = async (locationId) => {
    let locations = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Locations')
      .where('locationId', '==', locationId)
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => locations.push(doc.data()))
      );
    return locations[0];
  };

  const getLocations = async (parentLocation) => {
    let locations = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Locations')
      .where('locationParent', '==', parentLocation)
      .orderBy('displayName')
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => locations.push(doc.data()))
      );
    return locations;
  };

  const getAllLocations = async () => {
    let locations = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Locations')
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => locations.push(doc.data()))
      );
    return locations;
  };

  const getAllLocationsWithBottomLevelFilter = async (atBottomLevel = false) => {
    let locations = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Locations')
      .where('atBottomLevel', '==', atBottomLevel)
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => locations.push(doc.data()))
      );
    return locations;
  };

  const getBookableLocations = async () => {
    let locations = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Locations')
      .where('bookable', '==', true)
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => locations.push(doc.data()))
      );
    return locations;
  };

  const getBookableLocationsUnder = async (location) => {
    let locations = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Locations')
      .where(
        `coordinate.${location.locationType}.displayName`,
        '==',
        location.displayName
      )
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => {
          const loc = doc.data();
          if (loc.atBottomLevel) {
            locations.push(loc);
          }
        })
      );
    return locations;
  };

  const getBookableLocationsUnderAllLevels = async (location) => {
    let locations = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Locations')
      .where(
        `coordinate.${location.locationType}.displayName`,
        '==',
        location.displayName
      )
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => {
          const loc = doc.data();
          locations.push(loc);
        })
      );
    return locations;
  };

  const getLocationsUnderAllLevels = async (location, atBottomLevel) => {
    let locations = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Locations')
      .where(
        `atBottomLevel`,
        '==',
        atBottomLevel
      )
      .get()
      .then((snapshots) => {
        snapshots.forEach((doc) => {
          const loc = doc.data();
          if(loc.coordinate[location.locationType] && 
            loc.coordinate[location.locationType].locationId === location.locationId && 
            loc.locationId !== location.locationId) {
            locations.push(loc);
          }
        });
      });
    return locations;
  };

  const getMultiBookingLocationMap = async () => {
    let locationMap = {};

    const bookableLocs = await getBookableLocations();
    const multiBookableLocs = bookableLocs.filter(
      (loc) => loc.maxBookingCount > 1
    );

    for (let loc of multiBookableLocs) {
      locationMap[loc.locationId] = loc;
    }

    return locationMap;
  };

  const localDateToUtcStartOfDay = (localDate) => {
    return new Date(
      Date.UTC(
        localDate.getFullYear(),
        localDate.getMonth(),
        localDate.getDate()
      )
    );
  };

  const localDateToUtcEndOfDay = (localDate) => {
    return new Date(
      Date.UTC(
        localDate.getFullYear(),
        localDate.getMonth(),
        localDate.getDate(),
        23,
        59,
        59
      )
    );
  };

  const getEvents = async (startDate, endDate, locationId) => {
    const events = [];
    let query = database
      .collection('Companies')
      .doc(companyId)
      .collection('Meetings')
      .where('hiveEvent', '==', true);
    
    if (!isEmpty(startDate)) {
      const startDateUTC = localDateToUtcStartOfDay(startDate);
      query = query.where('date', '>=', startDateUTC);
    }

    if (!isEmpty(endDate)) {
      const endDateUTC = localDateToUtcEndOfDay(endDate);
      query = query.where('date', '<=', endDateUTC);
    }

    await query
      .orderBy('date')
      .get()
      .then((filteredEvents) => {
        filteredEvents.forEach((e) => {
          const eventData = _convertTimeStampToDateInEvent(e.data());
          if (!isEmpty(locationId)) {
            Object.values(eventData.coordinate).find((loc) => loc.locationId === locationId) && events.push(eventData);
          } else {
            events.push(eventData);
          }
        });
      });

    return events;
  };

  const getEvent = async (eventId) => {
    const event = await database
      .collection('Companies')
      .doc(companyId)
      .collection('Meetings')
      .doc(eventId)
      .get();
    
    return _convertTimeStampToDateInEvent(event.data());
  };

  const _convertTimeStampToDateInEvent = (eventData) => {
    eventData.date = eventData.date.toDate();
    eventData.startTime = eventData.startTime.toDate();
    eventData.endTime = eventData.endTime.toDate();
    eventData.reservationStartTime = eventData.reservationStartTime.toDate();
    eventData.reservationEndTime = eventData.reservationEndTime.toDate();

    return eventData;
  };

  const createEvent = async (eventData) => {
    return firebase.functions().httpsCallable('createEvent')({
      ...eventData,
      companyId: companyId
    });
  };

  const updateEvent = async (eventData) => {
    return firebase.functions().httpsCallable('updateEvent')({
      ...eventData,
      companyId: companyId
    });
  };

  const cancelEvent = async (eventId) => {
    return firebase.functions().httpsCallable('cancelEvent')({
      meetingId: eventId,
      companyId: companyId
    });
  };

  const getBookings = async (
    startingBookingDate,
    endingBookingDate,
    location,
    userId,
    status
  ) => {
    let bookings = [];
    let query = database
      .collection('Companies')
      .doc(companyId)
      .collection('Bookings');

    let companyData = await getCompany();

    const startDate = localDateToUtcStartOfDay(startingBookingDate);
    const startDateWithBuffer = new Date(startDate);
    startDateWithBuffer.setUTCHours(startDateWithBuffer.getUTCHours() - 12);
    const endDate = localDateToUtcEndOfDay(endingBookingDate);

    if (startingBookingDate) {
      query = query.where('date', '>=', startDateWithBuffer);
    }

    if (endingBookingDate) {
      query = query.where('date', '<=', endDate);
    }

    if (location) {
      query = query.where('ancestors', 'array-contains', location);
    }

    if (userId) {
      if (Array.isArray(userId)) {
        query = query.where('userId', 'in', userId.slice(0, 10));
      } else {
        query = query.where('userId', '==', userId);
      }
    }

    if (status && status !== 'ALL') {
      if (Array.isArray(status) && !location) {
        query = query.where('state', 'in', status);
      } else {
        query = query.where('state', '==', status);
      }
    }

    await query
      .orderBy('date')
      .get()
      .then((filteredBookings) =>
        filteredBookings.forEach((booking) => {
          const bookingData = booking.data();
          if (
            location &&
            Array.isArray(status) &&
            (!bookingData.state || !status.includes(bookingData.state))
          ) {
            return;
          }
          const tzRegion = bookingData.timeZone
            ? bookingData.timeZone.region
            : companyData.timeZone.region;
          const bookingDateInBookingTimeZone = getZonedTime(
            bookingData.date.toDate(),
            findTimeZone(tzRegion)
          );
          const bookingDateInUtc = new Date(
            Date.UTC(
              bookingDateInBookingTimeZone.year,
              bookingDateInBookingTimeZone.month - 1,
              bookingDateInBookingTimeZone.day
            )
          );
          if (bookingDateInUtc >= startDate && bookingDateInUtc <= endDate) {
            bookingData.bookingId = booking.id;
            bookings.push(bookingData);
          }
        })
      );
    return bookings;
  };

  const getBookingsForGroup = (bookings, groupId) => {
    return bookings.filter((b) =>
      b.groups ? b.groups.includes(groupId) : false
    );
  };

  const getMeetingsForAssociatedBookings = async (bookings) => {
    let meetings = [];
    let promises = [];
    bookings.forEach(async (booking) => {
      if (booking.meetingId) {
        promises.push(getMeeting(booking.meetingId).then(meeting => meetings.push(meeting)));
      }
    });
    await Promise.all(promises);
    return meetings;
  };

  const getMeeting = async (meetingId) => {
    let meetings = [];
    await database
      .collection("Companies")
      .doc(companyId)
      .collection("Meetings")
      .where("meetingId", "==", meetingId)
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => meetings.push(doc.data()))
      );
    return meetings[0];
  };

  const getAppointments = async (filters) => {
    const {
      startingAppointmentDate,
      endingAppointmentDate,
      location,
      resource,
      status
    } = filters;
    let appointments = [];
    let query = database
      .collection('Companies')
      .doc(companyId)
      .collection('Bookings');

    query = query.where('isAppointment', '==', true);

    if (startingAppointmentDate) {
      query = query.where('date', '>=', startOfDay(startingAppointmentDate));
    }

    if (endingAppointmentDate) {
      query = query.where('date', '<=', startOfDay(endingAppointmentDate));
    }

    if (location) {
      query = query.where('ancestors', 'array-contains', location);
    }

    if (resource) {
      query = query.where('resourceIds', 'array-contains', resource);
    }

    if (status && status !== 'ALL') {
      query = query.where('state', '==', status);
    }

    await query
      .orderBy('date')
      .get()
      .then((filteredBookings) =>
        filteredBookings.forEach((booking) => {
          const bookingData = booking.data();
          bookingData.bookingId = booking.id;

          appointments.push(bookingData);
        })
      );
    return appointments;
  };

  const getVisitorBookings = async (
    startingBookingDate,
    endingBookingDate,
    location,
    userId,
    status,
    visitor
  ) => {
    let bookings = [];
    let query = database
      .collection('Companies')
      .doc(companyId)
      .collection('Bookings');

    query = query.where('isAppointment', '==', false);

    if (startingBookingDate) {
      query = query.where('date', '>=', startOfDay(startingBookingDate));
    }

    if (endingBookingDate) {
      query = query.where('date', '<=', startOfDay(endingBookingDate));
    }

    if (location) {
      query = query.where('ancestors', 'array-contains', location);
    }

    if (userId) {
      query = query.where('userId', '==', userId);
    }

    if (status && status !== 'ALL') {
      query = query.where('state', '==', status);
    }

    await query
      .orderBy('date')
      .get()
      .then((filteredBookings) =>
        filteredBookings.forEach((booking) => {
          var data = booking.data();
          if (data.visitor !== null && data.visitor !== undefined) {
            var matched = true;
            if (visitor.firstName !== '') {
              if (
                !(
                  visitor.firstName.toUpperCase() ===
                  data.visitor.firstName.toUpperCase()
                )
              ) {
                matched = false;
              }
            }
            if (visitor.lastName !== '') {
              if (
                !(
                  visitor.lastName.toUpperCase() ===
                  data.visitor.lastName.toUpperCase()
                )
              ) {
                matched = false;
              }
            }
            if (matched) {
              const dataWithId = data;
              dataWithId.bookingId = booking.id;

              bookings.push(dataWithId);
            }
          }
        })
      );
    return bookings;
  };

  const getDevices = async () => {
    const devices = [];

    let deviceQuery = await database
      .collection('Companies')
      .doc(companyId)
      .collection('Devices')
      .get();

    for (let doc of deviceQuery.docs) {
      devices.push(doc.data());
    }

    return devices;
  };

  const getDevicesForProfile = async (profileId) => {
    const devices = [];

    let query = await database
      .collection('Companies')
      .doc(companyId)
      .collection('Devices')
      .where('profileId', '==', profileId)
      .get();

    for (let doc of query.docs) {
      devices.push(doc.data());
    }

    return devices;
  };

  const getAllLocationTags = async () => {
    let tags = [];
    const snapshot = await database
      .collection('Companies')
      .doc(companyId)
      .collection('Tags')
      .doc('LocationTags')
      .get();

    if (snapshot.data()) {
      tags.push(...snapshot.data().tags);
    }

    return tags;
  };

  const updateLocationTags = async (tags) => {
    const response = await firebase
      .functions()
      .httpsCallable('updateLocationTags')({ companyId, tags })
      .catch((err) => err);

    return response;
  };

  const getHealthScreeningRecords = async (
    startTime,
    endTime,
    userId,
    pass
  ) => {
    const healthScreeningRecords = [];

    let query = database
      .collection('Companies')
      .doc(companyId)
      .collection('HealthScreeningRecords');

    if (startTime) {
      // Include the entire day for startTime
      query = query.where('time', '>=', startOfDay(startTime));
    }

    if (endTime) {
      query = query.where('time', '<=', endTime);
    }

    if (userId) {
      query = query.where('userId', '==', userId);
    }

    if (pass === true || pass === false) {
      query = query.where('pass', '==', pass);
    }

    await query
      .orderBy('time')
      .get()
      .catch((e) => console.log(e))
      .then((snapshots) =>
        snapshots.forEach((doc) => {
          healthScreeningRecords.push(doc.data());
        })
      );

    return healthScreeningRecords;
  };

  const getHiveGateProfiles = async () => {
    const profileMap = {};

    let profileQuery = await database
      .collection('Companies')
      .doc(companyId)
      .collection('HiveGateProfiles')
      .get();

    for (let doc of profileQuery.docs) {
      profileMap[doc.id] = doc.data();
    }

    return profileMap;
  };

  const getUrl = async (urlName) => {
    const url = await database.collection('AppConfig').doc('urls').get();

    return url.data()[urlName];
  };

  const getCheckInQuestions = async () => {
    const questions = [];
    const query = await database
      .collection('Companies')
      .doc(companyId)
      .collection('CheckInQuestions')
      .get();
    query.forEach((q) => {
      const data = q.data();
      questions.push({
        ...data,
        id: q.id
      });
    });
    return questions;
  };

  const getFilteredReportedCases = async (
    startingDate,
    endingDate,
    userId,
    caseAdmin,
    status
  ) => {
    const _filteredReportedCases = [];
    let query = await database
      .collection('Companies')
      .doc(companyId)
      .collection('ReportedCases');

    if (!userId) {
      if (startingDate) {
        query = query.where('openingDate', '>=', startOfDay(startingDate));
      }

      if (endingDate) {
        query = query.where('openingDate', '<=', endOfDay(endingDate));
      }
    }

    if (userId) {
      query = query.where('user.id', '==', userId);
    }

    if (caseAdmin) {
      query = query.where('admins', 'array-contains', caseAdmin);
    }

    if (status && status !== 'ALL') {
      query = query.where('status', '==', status);
    }

    await query
      .orderBy('openingDate')
      .get()
      .then((cases) =>
        cases.forEach((c) => {
          const data = c.data();
          data.id = c.id;
          _filteredReportedCases.push(data);
        })
      );
    return _filteredReportedCases;
  };

  const getQuestionsForBooking = async (bookingId, userId) => {
    const response = await firebase
      .functions()
      .httpsCallable('getCheckinQuestionsForBooking')({
        companyId: companyId,
        bookingId: bookingId,
        userId: userId
      });

    if (response.data.success) {
      const questionSetId = response.data.data?.questionSetId;
      const isAnsweredAlready = response.data.data?.isAnsweredAlready ?? false;
      if (!isEmpty(questionSetId)) {
        let query = await database
          .collection('Companies')
          .doc(companyId)
          .collection('CheckInQuestions')
          .doc(questionSetId)
          .get();
        if (!isEmpty(query) && query.exists) {
          return {
            data: {
              success: true,
              id: query.id,
              questionnaire: query.data().questionnaire,
              isAnsweredAlready
            }
          };
        }
      }
    }
    return response;
  };

  const submitHealthScreening = async (
    booking,
    questionSetId,
    answers,
    isAppointment
  ) => {
    const generalData = {
      companyId: companyId,
      email: booking.email,
      questionSetId: questionSetId,
      answers: answers
    };

    const data = isAppointment
      ? generalData
      : {
        ...generalData,
        userId: booking.userId,
        firstName: booking.username.split(' ')[0],
        lastName: booking.username.split(' ')[1]
      };

    if (booking.visitor != null) {
      data.visitor = {
        firstName: booking.visitor.firstName,
        lastName: booking.visitor.lastName,
        email: booking.visitor.email,
        note: booking.visitor.note
      };
    }

    const response = await firebase
      .functions()
      .httpsCallable('submitHealthScreening')(data);
    return response.data;
  };

  const getUserBookingContacts = async (
    startingBookingDate,
    endingBookingDate,
    location,
    userId,
    visitorFirstName,
    visitorLastName,
    status
  ) => {
    let contacts = [];
    let locationPaths = [];

    var company = await getCompany();
    var coordinateSystem = company.coordinateSystem;

    let query = database
      .collection('Companies')
      .doc(companyId)
      .collection('Bookings');

    const isLocationPathEqual = (path1, path2) => {
      if (path1.length === path2.length) {
        for (var i = 0; i < path1.length; i++) {
          if (path1[i] !== path2[i]) {
            return false;
          }
        }
        return true;
      }
      return false;
    };

    const hasLocationPath = (path, pathList) => {
      for (var apath in pathList) {
        if (isLocationPathEqual(path, pathList[apath])) {
          return true;
        }
      }
      return false;
    };

    if (startingBookingDate) {
      query = query.where('date', '>=', startOfDay(startingBookingDate));
    }

    if (endingBookingDate) {
      query = query.where('date', '<=', startOfDay(endingBookingDate));
    }

    if (location) {
      query = query.where('ancestors', 'array-contains', location);
    }

    if (userId) {
      query = query.where('userId', '==', userId);
    }

    if (visitorFirstName) {
      query = query.where('visitor.firstName', '==', visitorFirstName);
    }

    if (visitorLastName) {
      query = query.where('visitor.lastName', '==', visitorLastName);
    }

    if (status && status !== 'ALL') {
      query = query.where('state', '==', status);
    }

    await query
      .orderBy('date')
      .get()
      .then((filteredBookings) =>
        filteredBookings.forEach((booking) => {
          const bookingData = booking.data();
          var locationPath = [];
          for (var dim in coordinateSystem) {
            if (bookingData.coordinate[coordinateSystem[dim]]) {
              locationPath.push(
                bookingData.coordinate[coordinateSystem[dim]].locationId
              );
            }
          }
          if (!hasLocationPath(locationPath, locationPaths)) {
            locationPaths.push(locationPath);
            let contact = {
              startingBookingDate: startingBookingDate,
              endingBookingDate: endingBookingDate,
              username: bookingData.username,
              email: bookingData.email,
              coordinate: bookingData.coordinate,
              visitor: bookingData.visitor
            };
            contacts.push(contact);
          }
        })
      );
    return contacts;
  };

  const getUserBookingContactTraces = async (
    startingBookingDate,
    endingBookingDate,
    location,
    userEmail,
    states
  ) => {
    let traces = [];
    let emailList = [];

    let query = database
      .collection('Companies')
      .doc(companyId)
      .collection('Bookings');

    const hasEmail = (email, emailList) => {
      for (var aemail in emailList) {
        if (emailList[aemail] === email) {
          return true;
        }
      }
      return false;
    };

    if (startingBookingDate) {
      query = query.where('date', '>=', startOfDay(startingBookingDate));
    }

    if (endingBookingDate) {
      query = query.where('date', '<=', startOfDay(endingBookingDate));
    }

    if (location) {
      query = query.where('ancestors', 'array-contains', location);
    }

    if (states && states !== 'ALL') {
      query = query.where('state', 'in', states);
    }

    // exclude the target contact user email
    // comment this out as per discussion on may 4, 2021. We better show a false positive than a false negative when tracing visitors.
    //emailList.push(userEmail);

    // only return unique emails
    await query
      .orderBy('date')
      .get()
      .then((filteredBookings) =>
        filteredBookings.forEach((booking) => {
          const bookingData = booking.data();
          var traceEmail = bookingData.email;
          var traceName = bookingData.username;
          const checkedInEvents = bookingData.events.filter(
            (event) =>
              event.event === 'STATE_CHANGE' &&
              event.new_state === BOOKING_STATES.CHECKED_IN
          );
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          };
          const checkInDate =
            !isEmpty(checkedInEvents) && !isEmpty(checkedInEvents[0].date)
              ? checkedInEvents[0].date
                .toDate()
                .toLocaleDateString('en-US', options)
              : '';

          if (bookingData.visitor) {
            traceEmail = bookingData.visitor.email;
            traceName = `${bookingData.visitor.firstName} ${bookingData.visitor.lastName}`;
          }

          if (!hasEmail(traceEmail, emailList)) {
            emailList.push(traceEmail);
            let trace = {
              date: checkInDate,
              username: traceName,
              email: traceEmail,
              coordinate: bookingData.coordinate,
              isVisitor: !!bookingData.visitor
            };
            traces.push(trace);
          }
        })
      );
    return traces;
  };

  const getCompany = async () => {
    return await database
      .collection('Companies')
      .doc(companyId)
      .get()
      .then((doc) => doc.data());
  };

  const getCompanyStream = (setCompany) => {
    return database
      .collection('Companies')
      .doc(companyId)
      .onSnapshot((doc) => {
        setCompany(doc.data());
      });
  };

  const updateLocationTreeCoordinateDisplayName = async (location) => {
    return firebase
      .functions()
      .httpsCallable('updateLocationTreeCoordinateDisplayName')({
        companyId: companyId,
        location: location
      });
  };

  const updateLocation = (location) => {
    return firebase.functions().httpsCallable('updateLocation')({
      companyId: companyId,
      location: location
    });
  };

  const createLocation = (location) => {
    return firebase.functions().httpsCallable('createLocation')({
      companyId: companyId,
      location: location
    });
  };

  const removeLocation = (location) => {
    return firebase.functions().httpsCallable('removeLocation')({
      companyId: companyId,
      location: location
    });
  };

  const companyExists = async (companyId) => {
    let snapshot = await database
      .collection('CompanyIDs')
      .where('companyId', '==', companyId)
      .get();
    if (snapshot.docs.length > 0) {
      return true;
    } else {
      snapshot = await database
        .collection('CompanyIDs')
        .where('aliases', 'array-contains', companyId)
        .get();
      return snapshot.docs.length > 0;
    }
  };

  const isCompanySsoEnabled = async (companyId) => {
    let snapshot = await database
      .collection('CompanyIDs')
      .where('companyId', '==', companyId)
      .get();
    if (snapshot.docs.length > 0) {
      return snapshot.docs[0].data().ssoEnabled;
    } else {
      snapshot = await database
        .collection('CompanyIDs')
        .where('aliases', 'array-contains', companyId)
        .get();
      return snapshot.docs.length > 0
        ? snapshot.docs[0].data().ssoEnabled
        : false;
    }
  };

  const updateCompany = async (company) => {
    return await firebase.functions().httpsCallable('updateCompany')({
      companyId: companyId,
      company: company
    }).then(async (result) => {
      result.data.success && await updateCompanyDataInReduxStore();
      return result;
    });
  };

  const createCompany = async (company) => {
    return await firebase.functions().httpsCallable('createCompany')(
      company
    ).then(async (result) => {
      result.success && updateCompanyDataInReduxStore(company);
      return result;
    });
  };

  const updateCompanyDataInReduxStore = async () => {
    const companyData = await getCompany();
    localStore.setCompany(companyData);
  };

  const getUsers = async () => {
    let users = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Users')
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => {
          let _userId = { userId: doc.id };
          let _userData = doc.data();
          let _userObj = { ..._userId, ..._userData };
          users.push(_userObj);
        })
      );
    users.forEach((u) => (u.state = isEmpty(u.state) ? 'ACTIVE' : u.state));
    return users;
  };

  const getUserById = async (userId) => {
    const _doc = await database
      .collection('Companies')
      .doc(companyId)
      .collection('Users')
      .doc(userId)
      .get();

    return _doc.exists ? _doc.data() : null;
  };

  const getResources = async () => {
    let resources = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Resources')
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => {
          let _resourceId = { resourceId: doc.id };
          let _resourceData = doc.data();
          let _resourceObj = { ..._resourceId, ..._resourceData };
          resources.push(_resourceObj);
        })
      );
    return resources;
  };

  const getAppointmentTypes = async () => {
    let appointmentTypes = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('AppointmentTypes')
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => {
          let _appointmentTypeId = { appointmentTypeId: doc.id };
          let _appointmentTypeData = doc.data();
          let _appointmentTypeObj = {
            ..._appointmentTypeId,
            ..._appointmentTypeData
          };
          appointmentTypes.push(_appointmentTypeObj);
        })
      );
    return appointmentTypes;
  };

  const getAdmins = async () => {
    let users = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Users')
      .where('roles', 'array-contains', ROLES.ADMIN)
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => {
          let _userId = { userId: doc.id };
          let _userData = doc.data();
          let _userObj = { ..._userId, ..._userData };
          users.push(_userObj);
        })
      );
    return users;
  };

  const getUserMap = async () => {
    let userMap = {};

    const users = await getUsers();

    for (let u of users) {
      userMap[u.userId] = u;
    }

    return userMap;
  };

  const getAdminEmailMap = async () => {
    let userMap = {};

    const users = await getAdmins();

    for (let u of users) {
      userMap[u.email] = u;
    }

    return userMap;
  };

  const getUsersWithIds = async (userIds) => {
    let names = [];
    for (let uid of userIds) {
      let user = await database
        .collection('Companies')
        .doc(companyId)
        .collection('Users')
        .doc(uid)
        .get();

      let data = user.data();
      let name = data.firstName + ' ' + data.lastName;
      names.push(name);
    }
    return names;
  };

  const signOutAllUsers = async () => {
    return firebase.functions().httpsCallable('signOutAllUsersForCompany')({
      companyId: companyId
    });
  };

  const getUserGroups = async (includeAccessPolicyGroup) => {
    let userGroups = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Groups')
      .get()
      .then((groups) =>
        groups.forEach((group) => userGroups.push(group.data()))
      );
    if (!includeAccessPolicyGroup) {
      userGroups = userGroups.filter((user) =>
        isEmpty(user.isAccessPolicyGroup)
      );
    }
    return userGroups;
  };

  const getUserGroupMap = async () => {
    let userGroupMap = {};
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Groups')
      .get()
      .then((groups) =>
        groups.forEach((group) => (userGroupMap[group.id] = group.data()))
      );
    return userGroupMap;
  };

  const getScheduledNotifications = async () => {
    let scheduledNotifications = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('ScheduledNotifications')
      .get()
      .then((notifications) =>
        notifications.forEach((notification) =>
          scheduledNotifications.push({
            ...notification.data(),
            scheduledNotificationId: notification.id
          })
        )
      );
    return scheduledNotifications;
  };

  const getLocationOptions = async (company) => {
    const locations = await getAllLocations();
    const notBottomLevelLocations = locations.filter(
      (location) => !location.atBottomLevel
    );
    notBottomLevelLocations.forEach((location) => {
      location.displayLabel = CompanyUtil.getStringForCoords(
        company,
        location.coordinate
      );
    });
    notBottomLevelLocations.sort((a, b) =>
      a.displayLabel.localeCompare(b.displayLabel)
    );
    return notBottomLevelLocations;
  };

  const getRequireApprovalGroups = async () => {
    let userGroups = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Groups')
      .where('isAccessPolicyGroup', '==', true)
      .get()
      .then((groups) =>
        groups.forEach((group) => userGroups.push(group.data()))
      );
    return userGroups;
  };

  const populateRequireApprovalGroup = async () => {
    return firebase.functions().httpsCallable('populateRequireApprovalGroup')({
      companyId: companyId
    });
  };

  const isBookingApprovalEnabled = async () => {
    let company = await getCompany();
    return company.preferences.enableBookingApproval;
  };

  const createUserGroup = async (userGroup) => {
    return firebase.functions().httpsCallable('createUserGroup')({
      companyId: companyId,
      userGroup: userGroup
    });
  };

  const updateUserGroup = (userGroup) => {
    return firebase.functions().httpsCallable('updateUserGroup')({
      companyId: companyId,
      userGroup: userGroup
    });
  };

  const removeUserGroup = (userGroupId) => {
    return firebase.functions().httpsCallable('removeUserGroup')({
      companyId: companyId,
      userGroupId: userGroupId
    });
  };

  const updateUser = (user) => {
    return firebase.functions().httpsCallable('updateUser')({
      companyId: companyId,
      user: user
    });
  };

  const createUser = (user) => {
    return firebase.functions().httpsCallable('createUser')(user);
  };

  const createUserByAdmin = (user) => {
    return firebase.functions().httpsCallable('createUserByAdmin')({
      companyId: companyId,
      user: user
    });
  };

  const deleteUserByAdmin = (user) => {
    return firebase.functions().httpsCallable('deleteUserByAdmin')({
      companyId: companyId,
      user: user
    });
  };

  const createResource = (data) => {
    return firebase.functions().httpsCallable('createResource')({
      companyId: companyId,
      resource: data
    });
  };

  const updateResource = (data) => {
    return firebase.functions().httpsCallable('updateResource')({
      companyId: companyId,
      resource: data
    });
  };

  const deleteResource = (data) => {
    return firebase.functions().httpsCallable('deleteResource')({
      companyId: companyId,
      resource: data
    });
  };

  const createAppointmentType = (data) => {
    return firebase.functions().httpsCallable('createAppointmentType')({
      companyId: companyId,
      appointmentType: data
    });
  };

  const updateAppointmentType = (data) => {
    return firebase.functions().httpsCallable('updateAppointmentType')({
      companyId: companyId,
      appointmentType: data
    });
  };

  const deleteAppointmentType = (data) => {
    return firebase.functions().httpsCallable('deleteAppointmentType')({
      companyId: companyId,
      appointmentType: data
    });
  };

  const uploadFloorPlan = (data) => {
    return firebase.functions().httpsCallable('uploadFloorPlan')({
      companyId: companyId,
      uploadData: data
    });
  };

  const uploadCompanyLogo = (data) => {
    return firebase.functions().httpsCallable('uploadCompanyLogo')({
      companyId: companyId,
      uploadData: data
    });
  };

  const uploadProviderConfigFile = (data) => {
    return firebase.functions().httpsCallable('uploadProviderConfigFile')({
      companyId: companyId,
      uploadData: data
    });
  };

  const createCase = (reportedCase) => {
    return firebase.functions().httpsCallable('createCase')({
      companyId: companyId,
      case: reportedCase
    });
  };

  const updateCase = (reportedCase) => {
    return firebase.functions().httpsCallable('updateCase')({
      companyId: companyId,
      case: reportedCase
    });
  };

  const sendNotificationNow = (notification) => {
    return firebase.functions().httpsCallable("sendNotificationNow")({
      companyId: companyId, notification: notification
    });
  };

  const createScheduledNotification = (scheduledNotification) => {
    return firebase.functions().httpsCallable('createScheduledNotification')({
      companyId: companyId,
      notification: scheduledNotification
    });
  };

  const updateScheduledNotification = (scheduledNotification) => {
    return firebase.functions().httpsCallable('updateScheduledNotification')({
      companyId: companyId,
      notification: scheduledNotification
    });
  };

  const deleteScheduledNotification = (scheduledNotificationId) => {
    return firebase.functions().httpsCallable('deleteScheduledNotification')({
      companyId: companyId,
      notificationId: scheduledNotificationId
    });
  };

  const uploadTaC = (data) => {
    return firebase.functions().httpsCallable('uploadTaC')({
      companyId: companyId,
      uploadData: data
    });
  };

  const getTermsAndConditions = (data) => {
    return firebase.functions().httpsCallable('getTermsAndConditions')({
      companyId: companyId,
      data: data
    });
  };

  const getProviderConfigFile = (data) => {
    return firebase.functions().httpsCallable('getProviderConfigFile')({
      companyId: companyId,
      data: data
    });
  };

  const getCompanyLogo = (data) => {
    return firebase.functions().httpsCallable('getCompanyLogo')({
      companyId: companyId,
      data: data
    });
  };

  const exportOccupantReport = async (
    startingBookingDate,
    endingBookingDate,
    email,
    coordinates,
    location,
    userId,
    status,
    groupId,
    includeAudit
  ) => {
    const startDate = startingBookingDate
      ? new Date(
        Date.UTC(
          startingBookingDate.getFullYear(),
          startingBookingDate.getMonth(),
          startingBookingDate.getDate()
        )
      )
      : null;
    const endDate = endingBookingDate
      ? new Date(
        Date.UTC(
          endingBookingDate.getFullYear(),
          endingBookingDate.getMonth(),
          endingBookingDate.getDate(),
          23,
          59,
          59
        )
      )
      : null;

    return firebase.functions().httpsCallable('sendReportEmail')({
      companyId: companyId,
      startingBookingDate: startDate,
      endingBookingDate: endDate,
      email: email,
      coordinateSystem: coordinates,
      managedLocation: location,
      userId: userId,
      status: status !== 'ALL' ? status : null,
      groupId: groupId ? groupId : '',
      includeAudit
    });
  };

  const exportAppointmentsReport = async (filters, email) => {
    const { startingAppointmentDate, endingAppointmentDate, location, status } =
      filters;
    return firebase.functions().httpsCallable('sendAppointmentReportEmail')({
      companyId: companyId,
      startingAppointmentDate: startingAppointmentDate
        ? startOfDay(startingAppointmentDate).toUTCString()
        : null,
      endingAppointmentDate: endingAppointmentDate
        ? startOfDay(endingAppointmentDate).toUTCString()
        : null,
      email: email,
      location: location,
      lang: i18n.language,
      status: status !== 'ALL' ? status : null
    });
  };

  const exportLocationReport = async (email, companyName, fileName, coordinateSystem, locationData) => {
    return firebase.functions().httpsCallable('sendLocationReport')({
      companyId: companyId,
      email: email,
      companyName: companyName,
      fileName: fileName,
      coordinateSystem: coordinateSystem,
      locationData: locationData
    });
  };

  const sendActiveUsersReportEmail = async (
    startingDate,
    endingDate,
    email,
    activeUsers,
    companyName
  ) => {
    const strStartingDate = format(startingDate, 'yyyy/MM/dd');
    const strEndingDate = format(endingDate, 'yyyy/MM/dd');

    return firebase.functions().httpsCallable('sendActiveUsersReportEmail')({
      startDate: strStartingDate,
      endDate: strEndingDate,
      email: email,
      activeUsersData: activeUsers,
      companyName: companyName
    });
  };

  const sendHealthNotificationAdmin = (booking, company, questionSetId) => {
    const data = {
      userId: booking.userId,
      companyId: company.companyId,
      bookingId: booking.bookingId,
      questionSetId: questionSetId,
      bookingLocationString: CompanyUtil.getStringForCoords(
        company,
        booking.coordinate
      )
    };

    return firebase.functions().httpsCallable('sendHealthNotificationAdmin')(
      data
    );
  };

  const deleteBooking = (bookingId) => {
    return firebase.functions().httpsCallable('deleteBooking')({
      companyId: companyId,
      bookingId: bookingId
    });
  };

  const cancelMeeting = (meetingId, meetingExternalId) => {
    return firebase.functions().httpsCallable('cancelMeeting')({
      companyId: companyId,
      meetingId: meetingId,
      meetingExternalId: meetingExternalId
    });
  };

  const checkIntoBooking = (
    bookingId,
    userEmail,
    questionSetId,
    isQuestionSetAnsweredHere
  ) => {
    return firebase.functions().httpsCallable('checkIntoBooking')({
      companyId: companyId,
      bookingId: bookingId,
      userEmail: userEmail,
      questionSetId: questionSetId,
      isQuestionSetAnsweredHere: isQuestionSetAnsweredHere,
      source: 'hive-admin-web'
    });
  };

  const checkOutOfBooking = (bookingId, userEmail) => {
    return firebase.functions().httpsCallable('checkOutOfBooking')({
      companyId: companyId,
      bookingId: bookingId,
      userEmail: userEmail,
      source: 'hive-admin-web'
    });
  };

  const createBooking = (bookingData) => {
    return firebase.functions().httpsCallable('createBooking')({
      companyId: companyId,
      ...bookingData
    });
  };

  const locationTimeBookingMigration = () => {
    return firebase
      .functions()
      .httpsCallable('updateLocationsWithTimeBookingFlag')({
        companyId: companyId
      });
  };

  const locationWithAssignmentsMigration = () => {
    return firebase
      .functions()
      .httpsCallable('updateLocationsWithAssignmentsV2')({
        companyId: companyId
      });
  };

  const updateCheckInQuestionsWithQuestionnaire = () => {
    return firebase
      .functions()
      .httpsCallable('updateCheckInQuestionsWithQuestionnaire')({
        companyId: companyId
      });
  };

  const deleteOldCheckInQuestionsFields = () => {
    return firebase
      .functions()
      .httpsCallable('deleteOldCheckInQuestionsFields')({
        companyId: companyId
      });
  };

  const createDevice = (device) => {
    return firebase.functions().httpsCallable('createDevice')({
      companyId: companyId,
      device: device
    });
  };

  const updateDevice = (deviceId, updates) => {
    return firebase.functions().httpsCallable('updateDevice')({
      companyId: companyId,
      deviceId: deviceId,
      updates: updates
    });
  };

  const deleteDevice = (deviceId) => {
    return firebase.functions().httpsCallable('deleteDevice')({
      companyId: companyId,
      deviceId: deviceId
    });
  };

  const createProfile = (profile) => {
    return firebase.functions().httpsCallable('createGateProfile')({
      companyId: companyId,
      profile: profile
    });
  };

  const updateProfile = (profileId, updates) => {
    return firebase.functions().httpsCallable('updateGateProfile')({
      companyId: companyId,
      profileId: profileId,
      updates: updates
    });
  };

  const deleteProfile = (profileId) => {
    return firebase.functions().httpsCallable('deleteGateProfile')({
      companyId: companyId,
      profileId: profileId
    });
  };

  const createCheckinQuestion = (questionSet) => {
    return firebase.functions().httpsCallable('createCheckinQuestion')(
      questionSet
    );
  };

  const updateCheckinQuestion = (question) => {
    return firebase.functions().httpsCallable('updateCheckinQuestion')(question);
  };

  const deleteCheckinQuestions = (questionIds) => {
    return firebase.functions().httpsCallable('deleteCheckinQuestions')({
      questionIds,
      companyId
    });
  };

  const getCompanyId = () => {
    return companyId;
  };

  //TODO: Remove after migration.
  const generateDropdownLists = async () => {
    const tasks = [
      firebase.functions().httpsCallable('updateUsersDropdownList')({
        companyId: companyId
      }),
      firebase.functions().httpsCallable('updateLocationsDropdownList')({
        companyId: companyId
      })
    ];

    return await Promise.all(tasks)
      .then((results) => {
        const badResults = results.filter((r) => r.data.success === false);
        return badResults.length > 0 ? badResults[0] : results[0];
      })
      .catch((error) => {
        console.log(error);
        return {
          data: {
            success: false,
            message: 'DATABASE_ERROR'
          }
        };
      });
  };

  const addAccessPolicyFieldToAllCompanies = () => {
    return firebase
      .functions()
      .httpsCallable('addAccessPolicyFieldToAllCompanies')({
        companyId: companyId
      });
  };

  const updateUsersDropdownList = () => {
    return firebase.functions().httpsCallable('updateUsersDropdownList')({
      companyId: companyId
    });
  };

  const updateLocationsDropdownList = () => {
    return firebase.functions().httpsCallable('updateLocationsDropdownList')({
      companyId: companyId
    });
  };

  const getUsersFromDropdownListCollection = async () => {
    return getFromDropdownCollection('usersList');
  };

  const getLocationsFromDropdownListCollection = async () => {
    return getFromDropdownCollection('locationsList');
  };

  const getFromDropdownCollection = async (listName) => {
    let list = [];
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('DropdownLists')
      .doc(listName)
      .get()
      .then((snapshot) => {
        snapshot.data() && list.push(...snapshot.data().list);
      });
    return list;
  };

  const copyScreeningMessagesToVisitorMessages = () => {
    return firebase
      .functions()
      .httpsCallable('copyScreeningMessagesToVisitorMessages')({
        companyId: companyId
      });
  };

  const convertHiveGateReasonsToCheckInReasons = () => {
    return firebase
      .functions()
      .httpsCallable('convertHiveGateReasonsToCheckInReasons')({
        companyId: companyId
      });
  };

  const addLocationDateToBookings = () => {
    return firebase.functions().httpsCallable('addLocationDateToBookings')({
      companyId: companyId
    });
  };

  const giveAllCompanyAdminsHRAdminRole = () => {
    return firebase.functions().httpsCallable('giveAllCompanyAdminsHRAdminRole')({
      companyId: companyId
    });
  };

  const getReservationByItem = (item) => {
    return firebase.functions().httpsCallable('getReservationByItem')({
      companyId: companyId,
      itemToBeReserved: item
    });
  };

  const updateReservation = (reservation) => {
    return firebase.functions().httpsCallable('updateReservation')({
      companyId: companyId,
      reservation: reservation,
      reservationId: reservation.reservationId
    });
  };

  const createReservation = (reservation) => {
    return firebase.functions().httpsCallable('createReservation')({
      companyId: companyId,
      reservation: reservation
    });
  };

  const deleteReservation = (reservation) => {
    return firebase.functions().httpsCallable('deleteReservation')({
      companyId: companyId,
      reservationId: reservation.reservationId
    });
  };

  const getAllReservationsByItemType = async (itemType) => {
    const map = {};
    await database
      .collection('Companies')
      .doc(companyId)
      .collection('Reservations')
      .where('itemToBeReserved.collection', '==', itemType)
      .get()
      .then((reservations) => {
        reservations.forEach((reservation) => {
          const res = reservation.data();
          if (res.itemToBeReserved.id in map) {
            map[res.itemToBeReserved.id].push(res);
          } else {
            map[res.itemToBeReserved.id] = [res];
          }
        });
      });
    
    return map;
  };

  return {
    getLocation,
    getLocations,
    getAllLocations,
    getAllLocationsWithBottomLevelFilter,
    getBookableLocations,
    getBookableLocationsUnder,
    getBookableLocationsUnderAllLevels,
    getLocationsUnderAllLevels,
    getMultiBookingLocationMap,
    getBookings,
    getBookingsForGroup,
    getMeetingsForAssociatedBookings,
    getAppointments,
    getDevices,
    getDevicesForProfile,
    createDevice,
    updateDevice,
    deleteDevice,
    getHealthScreeningRecords,
    getHiveGateProfiles,
    createProfile,
    updateProfile,
    deleteProfile,
    getVisitorBookings,
    getUrl,
    getQuestionsForBooking,
    getCheckInQuestions,
    getFilteredReportedCases,
    getCompany,
    getCompanyId,
    generateDropdownLists,
    getCompanyStream,
    getUsers,
    getUserById,
    getResources,
    getAppointmentTypes,
    getAdmins,
    getUserMap,
    getAdminEmailMap,
    getUsersWithIds,
    signOutAllUsers,
    updateLocation,
    createLocation,
    companyExists,
    isCompanySsoEnabled,
    updateCompany,
    createCompany,
    getEvents,
    getEvent,
    createEvent,
    updateEvent,
    cancelEvent,
    getUserGroups,
    getUserGroupMap,
    getScheduledNotifications,
    getLocationOptions,
    isBookingApprovalEnabled,
    createUserGroup,
    updateUserGroup,
    removeUserGroup,
    updateUser,
    createUser,
    createUserByAdmin,
    deleteUserByAdmin,
    createResource,
    updateResource,
    deleteResource,
    createAppointmentType,
    updateAppointmentType,
    deleteAppointmentType,
    updateLocationTreeCoordinateDisplayName,
    exportOccupantReport,
    exportAppointmentsReport,
    exportLocationReport,
    sendActiveUsersReportEmail,
    removeLocation,
    uploadFloorPlan,
    uploadCompanyLogo,
    uploadProviderConfigFile,
    updateCase,
    createCase,
    sendNotificationNow,
    createScheduledNotification,
    updateScheduledNotification,
    deleteScheduledNotification,
    uploadTaC,
    getTermsAndConditions,
    getProviderConfigFile,
    getCompanyLogo,
    sendHealthNotificationAdmin,
    deleteBooking,
    cancelMeeting,
    checkIntoBooking,
    checkOutOfBooking,
    createBooking,
    submitHealthScreening,
    getUserBookingContacts,
    getUserBookingContactTraces,
    locationTimeBookingMigration,
    locationWithAssignmentsMigration,
    updateCheckInQuestionsWithQuestionnaire,
    deleteOldCheckInQuestionsFields,
    createCheckinQuestion,
    updateCheckinQuestion,
    deleteCheckinQuestions,
    companyRegion,
    getRequireApprovalGroups,
    populateRequireApprovalGroup,
    updateUsersDropdownList,
    addAccessPolicyFieldToAllCompanies,
    updateLocationsDropdownList,
    getUsersFromDropdownListCollection,
    getLocationsFromDropdownListCollection,
    getAllLocationTags,
    updateLocationTags,
    copyScreeningMessagesToVisitorMessages,
    convertHiveGateReasonsToCheckInReasons,
    addLocationDateToBookings,
    giveAllCompanyAdminsHRAdminRole,
    getReservationByItem,
    updateReservation,
    createReservation,
    deleteReservation,
    getAllReservationsByItemType
  };
};
