import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { useCompanyService } from "../../services/useCompanyService";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../actions/index";
import { isEmpty } from "../../common";
import { BOOKING_STATES } from "../../constants";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";

const useStyles = makeStyles(() => ({
  selectButtonContainer: {
    margin: "20px 0",
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr",
    gridGap: "20px",
    "&>div": {
      display: "flex",
      "&>button": {
        marginRight: "13px"
      }
    },
    "&>div:last-of-type": {
      justifyContent: "flex-end",
      "&>button:last-of-type": {
        marginRight: "0"
      }
    }
  }
}));

function BookingsTableController({
  toggleAllSelected,
  tableEmpty,
  allBookingsSelected,
  setVerifyDelete,
  selectedBookingIds,
  checkingIn,
  callOnSelectedBookings,
  bookingMap,
  setCheckingIn,
  setQuestionnaire,
  setQuestionSetId,
  handleResponse,
  setShowQuestions,
  currentUser,
  loadingExport,
  setOpenEmailModal
}) {
  const classes = useStyles();
  const [t] = useTranslation();
  const noBookingsSelected = selectedBookingIds.length === 0;
  const companyService = useCompanyService();
  const dispatch = useDispatch();

  const [anySelectedBookingsArePast, setAnySelectedBookingsArePast] =
    useState(false);

  const checkOutBooking = callOnSelectedBookings(
    companyService.checkOutOfBooking,
    {
      success: t("Success.CheckedOutBookings"),
      error: t("Errors.CheckedOutBookings")
    }
  );

  const checkInBooking = async () => {
    // validation
    const errMsg = t("Errors.Selection");
    if (selectedBookingIds.length !== 1) {
      dispatch(setErrorMessage(errMsg));
      return;
    }

    const booking = bookingMap[selectedBookingIds[0]];

    if (isEmpty(booking)) {
      dispatch(setErrorMessage(errMsg));
      return;
    } else if (
      booking.state !== BOOKING_STATES.CONFIRMED &&
      booking.state !== BOOKING_STATES.INVITED
    ) {
      dispatch(setErrorMessage(t("Errors.CheckIn", { state: booking.state })));
      return;
    }

    setCheckingIn(true);

    // questions
    let _questionSet = await companyService.getQuestionsForBooking(
      booking.bookingId,
      booking.userId
    );

    if (
      _questionSet?.data?.success &&
      !isEmpty(_questionSet.data.questionnaire)
    ) {
      setQuestionnaire(_questionSet.data.questionnaire);
      setQuestionSetId(_questionSet.data.id);
      if (_questionSet.data.isAnsweredAlready) {
        const response = await companyService.checkIntoBooking(
          booking.bookingId,
          currentUser.email,
          _questionSet.data.id,
          false
        );
        handleResponse(response);
        return;
      } else {
        setShowQuestions(true);
        return;
      }
    } else {
      handleResponse(_questionSet);
      return;
    }
  };

  useEffect(() => {
    const selectedBookings = selectedBookingIds.map((id) => {
      return bookingMap[id];
    });

    setAnySelectedBookingsArePast(
      selectedBookings.some((booking) => {
        const now = new Date();
        if (!isEmpty(booking.endTime)) {
          return booking.endTime.toDate() < now;
        } else {
          const yesterday = new Date();
          yesterday.setDate(now.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);
          return booking.date.toDate() <= yesterday;
        }
      })
    );
  }, [bookingMap, selectedBookingIds]);

  return (
    <div className={classes.selectButtonContainer}>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleAllSelected}
          disabled={tableEmpty}
        >
          {allBookingsSelected && !tableEmpty
            ? t("Commons.UnselectAll")
            : t("Commons.SelectAll")}
        </Button>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setVerifyDelete(true)}
          disabled={
            tableEmpty || noBookingsSelected || anySelectedBookingsArePast
          }
        >
          {t("Commons.CancelSelected")}
        </Button>
        <Button
          disabled={
            selectedBookingIds.length > 1 ||
            checkingIn ||
            tableEmpty ||
            noBookingsSelected ||
            anySelectedBookingsArePast
          }
          variant="contained"
          color="primary"
          onClick={checkInBooking}
        >
          {t("Booking.CheckInSelected")}
          {checkingIn && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={checkOutBooking}
          disabled={tableEmpty || noBookingsSelected}
        >
          {t("Booking.CheckOutSelected")}
        </Button>
        {!tableEmpty && (
          <Tooltip title={t("Booking.ExportReport")}>
            <Button
              variant="contained"
              color="primary"
              disabled={loadingExport}
              onClick={() => setOpenEmailModal(true)}
              style={{ padding: "1rem" }}
            >
              {loadingExport ? (
                <CircularProgress size={24} />
              ) : (
                <SystemUpdateAltIcon />
              )}
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default BookingsTableController;
