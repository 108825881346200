import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography, Button } from "@material-ui/core";
import { useAuthService } from "../../services/useAuthService";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  wrapper: {
    maxWidth: "500px",
    textAlign: "center"
  },
  button: {
    marginTop: theme.spacing(5)
  }
}));

export const VerifyEmail = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const authService = useAuthService();
  const [verificationSent, setVerificationSent] = useState(false);

  const send = () => {
    authService
      .sendVerifyEmail()
      .then(() => {
        setVerificationSent(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const finish = () => {
    authService.logout();
  };

  return (
    <div className="content">
      <Container maxWidth="lg" className={classes.container}>
        {!verificationSent ? (
          <div className={classes.wrapper}>
            <Typography variant="h4">{t("VerifyEmail.VerifyEmail")}</Typography>
            <Button
              onClick={send}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t("VerifyEmail.SendVerificationEmail")}
            </Button>
          </div>
        ) : (
          <div className={classes.wrapper}>
            <Typography variant="h4" className={classes.title}>
              {t("VerifyEmail.VerificationEmailHasBeenSent")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={finish}
              className={classes.button}
            >
              {t("Commons.Ok")}
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
};
