import React from "react";
import {makeStyles} from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const GeneralInfo = React.memo(
  ({ className, company, setCompanyField, formikProps, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <Card className={className} {...rest}>
        <CardHeader title={t("CompanyInfo.GeneralInfo")} />
        <CardContent>
          <TextField
            className={classes.textField}
            error={
              !!(
                formikProps.errors.generalInfoUrl &&
                formikProps.touched.generalInfoUrl
              )
            }
            variant="outlined"
            label={t("CompanyInfo.GeneralInfoURL")}
            name="generalInfoUrl"
            fullWidth
            value={company.generalInfoUrl}
            onChange={(e) => setCompanyField("generalInfoUrl", e.target.value)}
            helperText={
              formikProps.touched.generalInfoUrl &&
              formikProps.errors.generalInfoUrl
            }
          />
          <Typography>{t("CompanyInfo.DisplayedInMobileAppUnder")}</Typography>
        </CardContent>
      </Card>
    );
  }
);

GeneralInfo.propTypes = {
  className: PropTypes.string
};

export default GeneralInfo;