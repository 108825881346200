import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  Card,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { setErrorMessage, setSuccessMessage } from "../../actions/index";

const useStyles = makeStyles(() => ({
  exportCard: {
    padding: "1rem 1rem 0 1rem",
    marginTop: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1rem"
  },
  cardChild: {
    flex: "2",
    alignSelf: "flex-start"
  },
  cardChildVertical: {
    display: "flex",
    flexDirection: "column",
    flex: "1"
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const EmailExport = ({
  email,
  setEmail,
  onExport,
  includeAuditInExport,
  setIncludeAuditInExport
}) => {
  const classes = useStyles();
  const [loadingExport, setLoadingExport] = useState(false);
  const [t] = useTranslation();
  const dispatch = useDispatch();


  return (
    <Card className={classes.exportCard}>
      <div className={classes.cardChild}>
        <TextField
          fullWidth
          variant="outlined"
          label={t("Commons.Email")}
          name="email"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
      </div>
      <div className={classes.cardChildVertical}>
        <Button
          variant="contained"
          color="primary"
          disabled={!email || loadingExport}
          onClick={() => {
            setLoadingExport(true);
            onExport().then((result) => {
              dispatch(
                result.data.success ? 
                  setSuccessMessage(t("Success.EmailReport", { email: email })) : 
                  setErrorMessage(t("Errors.Cloud.EMAIL_ERROR"))
              );
              setLoadingExport(false);
              setEmail("");
            }).catch((error) => {
              dispatch(
                setErrorMessage(t("Errors.Cloud.EMAIL_ERROR"))
              );
              console.log(error);
            });
          }}
        >
          {t("Reports.ExportReport")}
          {loadingExport && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
        <FormControlLabel
          style={{ marginTop: "-0.25rem" }}
          control={
            <Checkbox
              disabled={includeAuditInExport === undefined}
              checked={includeAuditInExport}
              color="primary"
              onChange={(event) => setIncludeAuditInExport(event.target.checked)}
            />
          }
          label="Include Audit Information"
        />
      </div>
    </Card>
  );
};

EmailExport.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  includeAuditInExport: PropTypes.bool,
  setIncludeAuditInExport: PropTypes.func
};

export default EmailExport;
