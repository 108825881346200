import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { MapImageProjection } from "./MapImageProjection";
import { GeoMarkerEditPopup } from "./GeoMarkerEditPopup";
import { isEmpty } from "../../common";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  LayerGroup,
  LayersControl
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-easybutton";
import "leaflet-easybutton/src/easy-button.css";
import L from "leaflet";
import { assignedIcon, selectedIcon } from "./MapLeafletIcons";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  mapContainer: {
    height: "600px"
  }
}));

export default function GeoMapLeaflet({
  stateKey,
  company,
  selectedSeat,
  setSelectedSeat,
  seats,
  setAutoSave,
  saveEditedLocation,
  removeEditedLocation
}) {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [mapImageProjection, setMapImageProjection] = useState(
    new MapImageProjection()
  );
  const [seatBounds, setSeatBounds] = useState(null);
  const [map, setMap] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (seats) {
      var _pointList = [];
      seats.map((item) => {
        if (!isEmpty(item.geometry) && Object.keys(item.geometry).length != 0) {
          _pointList.push(item.geometry.coordinates);
          return true;
        }
        return false;
      });

      if (_pointList.length > 1) {
        setSeatBounds(L.latLngBounds(_pointList));
      } else {
        setSeatBounds(L.latLngBounds([-90.0, -90.0], [90.0, 90.0]));
      }
    }
  }, [seats]);

  useEffect(() => {
    if (map) {
      L.easyButton(
        "fa-crosshairs fa-lg",
        function () {
          map.fitBounds(seatBounds);
        },
        "Zoom Fit"
      ).addTo(map);
    }
  }, [map]);

  useEffect(() => {
    if (map && seatBounds) {
      map.fitBounds(seatBounds);
    }
  }, [map, seatBounds]);

  const locationIcon = (item) => {
    if (selectedSeat.seat && item.locationId === selectedSeat.seat.locationId) {
      return selectedIcon;
    } else {
      return assignedIcon;
    }
  };

  const eventHandlers = useMemo(
    (e) => ({
      click: (e) => {
        let marker = e.target;
        if (marker.options.data) {
          setAutoSave(false);
          setSelectedSeat(marker.options.data);
        }
      },
      dragend: (e) => {}
    }),
    []
  );

  const handleSelectedChange = (editLocation, selectedMode) => {
    // TODO
  };

  return (
    <React.Fragment>
      {
        <Grid container>
          <Grid item xs={12}>
            {
              <MapContainer
                key={stateKey}
                className={classes.mapContainer}
                center={[43.64502, -79.39635]}
                zoom={11}
                scrollWheelZoom={false}
                whenCreated={setMap}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <LayersControl position="topright">
                  <LayersControl.Overlay checked name={"Geographic Locations"}>
                    <LayerGroup>
                      {company &&
                        seats &&
                        seats.map((item, index) => {
                          if (
                            !isEmpty(item.geometry) &&
                            Object.keys(item.geometry).length != 0
                          )
                            return (
                              <Marker
                                key={item.locationId}
                                data={{
                                  seat: item,
                                  index: index,
                                  editMode: false
                                }}
                                position={item.geometry.coordinates}
                                draggable={false}
                                eventHandlers={eventHandlers}
                                icon={locationIcon(item)}
                              >
                                <Tooltip
                                  direction="top"
                                  offset={[0, -30]}
                                  opacity={0.8}
                                >
                                  {item.displayName}
                                </Tooltip>

                                <GeoMarkerEditPopup
                                  company={company}
                                  item={item}
                                  itemPoint={item.geometry.coordinates}
                                  saveEditedLocation={saveEditedLocation}
                                  removeEditedLocation={removeEditedLocation}
                                  selectedMode={true}
                                  handleSelectedChange={handleSelectedChange}
                                />
                              </Marker>
                            );
                        })}
                    </LayerGroup>
                  </LayersControl.Overlay>
                </LayersControl>
              </MapContainer>
            }
          </Grid>
        </Grid>
      }
    </React.Fragment>
  );
}
