import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography, Card } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { useEffect } from "react";
import { useAuthService } from "../../services/useAuthService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  signInFormWrapper: {
    width: "100%",
    maxWidth: "900px",
    margin: "0 auto",
    textAlign: "center"
  },
  signInFormCard: {
    marginBottom: theme.spacing(5)
  },
  cardContentWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  cardImageWrapper: {
    width: "46%",
    backgroundImage: "url('../signin.jpg')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  cardFormWrapper: {
    width: "54%",
    padding: theme.spacing(5)
  },
  title: {
    textAlign: "center"
  },
  errorMessage: {
    minHeight: "30px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  form: {
    maxWidth: "400px",
    margin: "auto",
    marginBottom: "1.5em"
  },
  textField: {
    marginBottom: theme.spacing(3)
  },
  signUpLink: {
    color: "#1264a3"
  },
  button: {
    marginRight: theme.spacing(1)
  }
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const SignInSso = () => {
  const classes = useStyles();
  const history = useHistory();
  const authService = useAuthService();
  const { t } = useTranslation();

  let query = useQuery();

  const [errorMessage, setErrorMessage] = useState("");
  const [companyId, setCompanyId] = useState(() => {
    return query.get("company");
  });
  const [token, setToken] = useState(() => {
    return query.get("token");
  });

  useEffect(() => {
    signIn(companyId, token);
  }, []);

  const signIn = async (companyId, token) => {
    let verified;

    try {
      verified = await authService.signInWithCustomToken(companyId, token);
    } catch (error) {
      setErrorMessage(t("Errors.InvalidSSOCred"));
      return;
    }

    if (verified) {
      history.push("/");
    } else {
      setErrorMessage(t("Errors.SSOSignInProblem"));
      return;
    }
  };

  return (
    <div className="content">
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.signInFormWrapper}>
          <Card className={classes.signInFormCard}>
            <div className={classes.cardContentWrapper}>
              <div className={classes.cardImageWrapper}></div>
              <div className={classes.cardFormWrapper}>
                <img
                  src="../logo.jpg"
                  style={{ width: "300px", marginBottom: "24px" }}
                />
                <div className={classes.errorMessage}>
                  {errorMessage !== "" && (
                    <Alert
                      severity="error"
                      onClose={() => {
                        setErrorMessage("");
                      }}
                    >
                      {errorMessage}
                    </Alert>
                  )}
                </div>
                <Typography variant="h2" className={classes.title}>
                  {t("SignIn.SSOSignInTo")}
                  {companyId}
                </Typography>
              </div>
            </div>
          </Card>
        </div>
      </Container>
    </div>
  );
};
