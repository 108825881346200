import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { Locations } from "./views/locations/Locations";
import { LocationMap } from "./views/LocationMap/LocationMap";
import { LocationMapPlus } from "./views/LocationMapPlus/LocationMapPlus";
import { PublicRoute } from "./layouts/PublicRoute";
import Dashboard from "./views/Dashboard/Dashboard";
import CompanyInfo from "./views/CompanyInfo/CompanyInfo";
import CaseManagement from "./views/CaseManagement/CaseManagement";
import ResourceManagement from "./views/Resources/ResourceManagement";
import AppointmentManagement from "./views/Appointments/AppointmentManagement";
import SignUp from "./views/SignUp/SignUp";
import Users from "./views/Users/Users";
import Events from "./views/Events/Events";
import Reports from "./views/Reports/Reports";
import Visitors from "./views/Visitors/Visitors";
import Imports from "./views/Imports/Imports";
import Booking from "./views/Booking/Booking";
import { Gate } from "./views/Gate/Gate";
import { PrivateRoute } from "./layouts/PrivateRoute";
import { SignIn } from "./views/SignIn/SignIn";
import { ForgotPassword } from "./views/SignIn/ForgotPassword";
import { SignInSso } from "./views/SignInSso/SignInSso";
import { SignInOverride } from "./views/SignInOverride/SignInOverride";
import { VerifyEmail } from "./views/VerifyEmail/VerifyEmail";
import { Welcome } from "./views/Welcome/Welcome";
import { UserGroups } from "./views/UserGroups/UserGroups";
import { ScreeningQuestions } from "./views/Questions/ScreeningQuestions";
import { Notifications } from "./views/Notifications/Notifications";
import { ACTIONS_ROLES, PATHS } from "./constants";
import { Migration } from "./views/Migration/Migration";
import { Heartbeat } from "./views/Heartbeat/Heartbeat";
import EventDetails from "./views/Events/EventDetails";

export const Routes = () => {
  return (
    <Switch>
      <PublicRoute exact path={PATHS.SIGNUP}>
        <SignUp />
      </PublicRoute>
      <PublicRoute exact path={PATHS.SIGNIN}>
        <SignIn />
      </PublicRoute>
      <PublicRoute exact path={PATHS.SIGNIN_SSO}>
        <SignInSso />
      </PublicRoute>
      <PublicRoute exact path={PATHS.HEARTBEAT}>
        <Heartbeat />
      </PublicRoute>
      <PrivateRoute
        exact
        path={PATHS.SIGNIN_OVERRIDE}
        allowedRoles={ACTIONS_ROLES.ACCESS_SIGNIN_OVERRIDE}
      >
        <SignInOverride />
      </PrivateRoute>
      <PublicRoute exact path={PATHS.SIGNIN_FORGOT_PASSWORD}>
        <ForgotPassword />
      </PublicRoute>
      <PrivateRoute exact path={PATHS.VERIFY_EMAIL}>
        <VerifyEmail />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.ROOT}>
        <Welcome />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.DASHBOARD}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.COMPANY_INFO}
        allowedRoles={ACTIONS_ROLES.ACCESS_COMPANY_INFO}
      >
        <CompanyInfo />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.LOCATIONS}
        allowedRoles={ACTIONS_ROLES.ACCESS_LOCATIONS}
      >
        <Locations />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.CASE_MANAGEMENT}
        allowedRoles={ACTIONS_ROLES.ACCESS_CASE_MANAGEMENT}
      >
        <CaseManagement />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.GATE}
        allowedRoles={ACTIONS_ROLES.ACCESS_GATE}
      >
        <Gate />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.LOCATIONS_MAP}
        allowedRoles={ACTIONS_ROLES.ACCESS_LOCATION_MAP}
      >
        <LocationMap />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.LOCATIONS_MAP_PLUS}
        allowedRoles={ACTIONS_ROLES.ACCESS_LOCATION_MAP_PLUS}
      >
        <LocationMapPlus />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.MIGRATION}
        allowedRoles={ACTIONS_ROLES.ACCESS_MIGRATION}
      >
        <Migration />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.RESOURCES}
        allowedRoles={ACTIONS_ROLES.ACCESS_RESOURCES}
      >
        <ResourceManagement />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.APPOINTMENTS}
        allowedRoles={ACTIONS_ROLES.ACCESS_APPOINTMENTS}
      >
        <AppointmentManagement />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.USERS}
        allowedRoles={ACTIONS_ROLES.ACCESS_USERS}
      >
        <Users />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.USER_GROUPS}
        allowedRoles={ACTIONS_ROLES.ACCESS_USER_GROUPS}
      >
        <UserGroups />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.EVENTS}
        allowedRoles={ACTIONS_ROLES.ACCESS_EVENTS}
      >
        <Events />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.NEW_EVENT}
        allowedRoles={ACTIONS_ROLES.ACCESS_EVENTS}
      >
        <EventDetails createMode={true} />
      </PrivateRoute>
      <PrivateRoute
        path={PATHS.EVENT_DETAILS}
        allowedRoles={ACTIONS_ROLES.ACCESS_EVENTS}
      >
        <EventDetails />
      </PrivateRoute>

      <PrivateRoute
        exact
        path={PATHS.REPORTS}
        allowedRoles={ACTIONS_ROLES.ACCESS_REPORTS}
      >
        <Reports />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.BOOKING}
        allowedRoles={ACTIONS_ROLES.ACCESS_BOOKING}
      >
        <Booking />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.VISITORS}
        allowedRoles={ACTIONS_ROLES.ACCESS_VISITORS}
      >
        <Visitors />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.IMPORTS}
        allowedRoles={ACTIONS_ROLES.ACCESS_IMPORTS}
      >
        <Imports />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.QUESTIONS}
        allowedRoles={ACTIONS_ROLES.ACCESS_QUESTIONS}
      >
        <ScreeningQuestions />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PATHS.NOTIFICATIONS}
        allowedRoles={ACTIONS_ROLES.ACCESS_NOTIFICATIONS}
      >
        <Notifications />
      </PrivateRoute>
      <PublicRoute>
        <Redirect to={PATHS.ROOT} />
      </PublicRoute>
    </Switch>
  );
};
