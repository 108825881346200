import React, { useState, useEffect, useMemo } from "react";
import L from "leaflet";
import useImage from "use-image";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { MapLeafletInsertLocations } from "./MapLeafletInsertLocations";
import { LocationInsertMode } from "./LocationInsertMode";
import { TemplateMatching } from "./TemplateMatching";

import { LocationMarkerEditPopup } from "./LocationMarkerEditPopup";
import { isEmpty } from "../../common";
import { ROLES } from "../../constants";
import {
  MapContainer,
  LayersControl,
  LayerGroup,
  Marker,
  Tooltip,
  Polyline,
  CircleMarker,
  ImageOverlay,
  useMapEvents
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-easybutton";
import "leaflet-easybutton/src/easy-button.css";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import {
  assignedIcon,
  unassignedIcon,
  selectedIcon,
  suggestedLocationIcon
} from "./MapLeafletIcons";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  mapContainer: {
    height: "600px",
    cursor: "crosshair"
  },
  buttonProgress: {
    color: "primary",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  locationEditGrid: {
    paddingTop: "2px",
    marginBottom: "2px"
  },
  locationEditItem: {
    margin: "0px"
  },
  insertModeCursor: {
    backgroundColor: "#fff",
    cursor: "crosshair"
  }
}));

export default function LayeredMapLeaflet({
  stateKey,
  coordinates,
  coordinateIndex,
  location,
  floorMap,
  selectedSeat,
  setSelectedSeat,
  seats,
  setSeats,
  scale,
  handleSetScale,
  distance,
  map,
  setMap,
  mapImageProjection,
  setAutoSave,
  suggestedLocations,
  setSuggestedLocations,
  locationAdjacentLines,
  reloadLocations,
  forceRefreshLocation,
  saveEditedLocation,
  removeEditedLocation,
  setMaxOccupancy,
  saveMaxOccupancy
}) {
  const classes = useStyles();
  const [seatBounds, setSeatBounds] = useState({});
  const [radius, setRadius] = useState(1);
  const [locationPrefix, setLocationPrefix] = useState("");
  const [locationSequence, setLocationSequence] = useState(1);
  const [insertMode, setInsertMode] = useState(false);
  const [addLocations, setAddLocations] = useState([]);
  const [templateBound, setTemplateBound] = useState("");
  const [matchImageBound, setMatchImageBound] = useState("");
  const [clickBound, setClickBound] = useState("");
  const [projectedAdjLines, setProjectedAdjLines] = useState([]);

  const localStorage = useLocalStorage();
  const { t } = useTranslation();

  const [image] = useImage(floorMap.url);

  useEffect(() => {
    if (image) {
      mapImageProjection.init(image.width, image.height);

      if (seats) {
        var _pointList = [];
        seats.map((item) => {
          if (
            item.coordinate[floorMap.floorMapLocation.locationType].point &&
            item.coordinate[floorMap.floorMapLocation.locationType].point
              .length === 2
          ) {
            var _point = mapImageProjection.transform(
              item.coordinate[floorMap.floorMapLocation.locationType].point[0],
              item.coordinate[floorMap.floorMapLocation.locationType].point[1]
            );
            _pointList.push(_point);
            return true;
          }
          return false;
        });

        if (_pointList.length > 1) {
          setSeatBounds(L.latLngBounds(_pointList));
        } else {
          setSeatBounds(L.latLngBounds([-90.0, -90.0], [90.0, 90.0]));
        }
      }
    }
  }, [image, seats]);

  useEffect(() => {
    if (locationAdjacentLines.length > 0) {
      var _lines = [];
      locationAdjacentLines.map((item) => {
        var pt0 = item[0];
        var pt1 = item[1];
        var tpt0 = mapImageProjection.transform(pt0[0], pt0[1]);
        var tpt1 = mapImageProjection.transform(pt1[0], pt1[1]);
        _lines.push([tpt0, tpt1]);
      });
      setProjectedAdjLines(_lines);
    }
  }, [locationAdjacentLines]);

  // leaflet-darw setup
  useEffect(() => {
    if (map) {
      // create easy button for zoom fit function
      L.easyButton(
        "fa-crosshairs fa-lg",
        function () {
          map.fitBounds(seatBounds);
        },
        "Zoom Fit"
      ).addTo(map);

      // FeatureGroup is to store editable layers
      var featureLayers = new L.FeatureGroup();
      featureLayers.on("click", onFeatureGroupClick);
      map.addLayer(featureLayers);

      // modify the draw tooltip
      L.drawLocal.draw.toolbar.buttons = {
        polyline: "measure a meter",
        rectangle: "matching template"
      };
      L.drawLocal.draw.handlers.polyline = {
        error: "<strong>Error:</strong> shape edges cannot cross!",
        tooltip: {
          start: "drawing 1 meter.",
          cont: "continue drawing 1 meter.",
          end: "Click last point to finish measurement."
        }
      };
      L.drawLocal.draw.handlers.rectangle = {
        tooltip: {
          start: "drawing matching template."
        }
      };

      // settings to disable the distance measurement display (which is in km that we don't want)
      var drawControl = new L.Control.Draw({
        draw: {
          polygon: false,
          marker: false,
          circle: false,
          circlemarker: false,
          polyline: {
            shapeOptions: {
              clickable: true,
              showArea: true
            },
            showArea: true
          },
          rectangle: {
            shapeOptions: {
              clickable: true,
              showArea: false
            },
            showArea: false
          }
        },
        edit: {
          featureGroup: featureLayers,
          edit: true
        }
      });

      map.addControl(drawControl);

      map.on("draw:created", function (e) {
        var type = e.layerType;
        var layer = e.layer;
        if (type === "polyline") {
          console.log("draw polyline");
        } else if (type === "rectangle") {
          console.log("draw rectangle");
        }

        layer.properties = {
          type: e.layerType
        };
        featureLayers.addLayer(layer);
      });

      map.on("draw:deleted", function (e) {
        setTemplateBound("");
        setMatchImageBound("");
      });
    }
  }, [map]);

  useEffect(() => {
    handleRadiusChanged();
  }, [map, scale, distance]);

  useEffect(() => {
    if (!isEmpty(clickBound)) {
      if (isEmpty(templateBound)) {
        setTemplateBound(clickBound);
      } else {
        // assume matching another image boundary
        setMatchImageBound(clickBound);
      }
    }
  }, [clickBound]);

  // For computing the CircleMarker radius, it is specified in the current view pixels length.
  // Since this is NOT obvious, the algorithm is:
  // From the current view in map's pixel bounds, got the viewBoundingBox
  // From the current view of floor plan image, got the imageBoundingBox
  // From the distance and scale specification, we know pixelDistance (distance converted in pixels)
  // The pixelDistance needs to be scaled according to the viewBoundingBox/imageBoundingBox ratio
  // Finally, we got the current view radius in pixels
  //
  // In addition,
  // * we must listen to leaflet's zoom end event, to adjust current view of the radius.
  // * we must listen to map, distance and scale changes to recompute the radius
  //
  const handleRadiusChanged = () => {
    if (map) {
      let _mapBounds = map.getBounds();
      let sw = mapImageProjection.inverse(_mapBounds._southWest);
      let ne = mapImageProjection.inverse(_mapBounds._northEast);
      let imageBoundingBox = [
        Math.round(sw.x),
        Math.round(ne.y),
        Math.round(ne.x),
        Math.round(sw.y)
      ];
      let _viewBound = map.getPixelBounds();
      let viewBoundingBox = [
        _viewBound.min.x,
        _viewBound.min.y,
        _viewBound.max.x,
        _viewBound.max.y
      ];
      let _pixelDistance = distance / scale;
      let _zoomRatio =
        (viewBoundingBox[2] - viewBoundingBox[0]) /
        (imageBoundingBox[2] - imageBoundingBox[0]);
      // divided by 2 to show non-overlapping circle of distancing
      let _radius = (_pixelDistance * _zoomRatio) / 2;
      setRadius(_radius);
    }
  };

  // leaflet-draw objects click handler
  const onFeatureGroupClick = (e) => {
    var layer = e.layer;
    if (layer.properties.type === "rectangle") {
      let sw = mapImageProjection.inverse(layer._bounds._southWest);
      let ne = mapImageProjection.inverse(layer._bounds._northEast);
      let bound = [
        Math.round(sw.x),
        Math.round(ne.y),
        Math.round(ne.x),
        Math.round(sw.y)
      ];
      console.log(bound);
      setClickBound(bound);
    } else if (layer.properties.type === "polyline") {
      let sw = mapImageProjection.inverse(layer._bounds._southWest);
      let ne = mapImageProjection.inverse(layer._bounds._northEast);
      var a = sw.x - ne.x;
      var b = sw.y - ne.y;
      var dist = Math.sqrt(a * a + b * b);
      // keep 3 decimal place, scale in meters/pixel
      dist = Math.round((1.0 / dist) * 1000) / 1000;
      handleSetScale(dist);
    }
  };

  // const insideBoundingBox = (bound1, bound2) => {
  //   // remember bounding box [topleft.x, topleft.y, bottomright.x, bottomright.y]
  //   if (
  //     bound1[0] >= bound2[0] &&
  //     bound1[2] <= bound2[2] &&
  //     bound1[1] >= bound2[1] &&
  //     bound1[3] <= bound2[3]
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  const isPointMoved = (oldPoint, newPoint, tol) => {
    if (!isEmpty(oldPoint)) {
      // if there is old point, check the movement is greater than tolerance
      if (
        Math.abs(oldPoint[0] - newPoint[0]) > tol ||
        Math.abs(oldPoint[1] - newPoint[1]) > tol
      ) {
        return true;
      }
      return false;
    } else {
      // only has new point, we take it
      return true;
    }
  };

  const MapEventHandler = () => {
    const map = useMapEvents({
      zoomend: () => {
        handleRadiusChanged();
      }
    });
    return null;
  };

  // general marker handler
  const eventHandlers = useMemo(
    (e) => ({
      click: (e) => {
        let marker = e.target;
        if (marker.options.data) {
          setAutoSave(false);
          setSelectedSeat(marker.options.data);
          // marker.openPopup();
        }
      },
      dragend: (e) => {
        let marker = e.target;
        let latlng = marker.getLatLng();
        if (marker.options.data) {
          let index = marker.options.data.index;
          if (index < seats.length) {
            let pos = mapImageProjection.inverse(latlng);
            let oldPoint =
              seats[index].coordinate[floorMap.floorMapLocation.locationType]
                .point;
            let newPoint = [pos.x, pos.y];
            if (mapImageProjection.inside(pos.x, pos.y)) {
              // inside of the image boundary, update the digitzied point
              if (isPointMoved(oldPoint, newPoint, 1.0)) {
                seats[index].coordinate[
                  floorMap.floorMapLocation.locationType
                ].point = newPoint;
                setAutoSave(true);
                setSelectedSeat(marker.options.data);
              }
            } else {
              // outside of the image boundary, remove the digitized point
              delete seats[index].coordinate[
                floorMap.floorMapLocation.locationType
              ].point;
              setAutoSave(true);
              setSelectedSeat(marker.options.data);
            }
          }
          // it's out of sync, force a refresh
          else {
            forceRefreshLocation();
          }
        }
      }
    }),
    []
  );

  // general marker handler
  const suggestedLocationEventHandlers = useMemo(
    (e) => ({
      click: (e) => {
        // do nothing
      },
      dragend: (e) => {
        // do nothing
      }
    }),
    []
  );

  // const showLocationInsertWidget = (map) => {
  //   return (
  //     localStorage.isHiveApiEnabled() &&
  //     localStorage.getUserRoles().includes(ROLES.ROOT) &&
  //     !!map.url
  //   );
  // };

  const isSuggestedLocationId = (locationId) => {
    for (var x in suggestedLocations) {
      if (suggestedLocations[x].locationId === locationId) return true;
    }
    return false;
  };

  const getLocationPoint = (locationId) => {
    for (var x in seats) {
      if (seats[x].locationId === locationId)
        return seats[x].coordinate[floorMap.floorMapLocation.locationType]
          .point;
    }
    return false;
  };

  const locationIcon = (item) => {
    if (selectedSeat.seat && item.locationId === selectedSeat.seat.locationId) {
      return selectedIcon;
    } else if (
      suggestedLocations.length > 0 &&
      isSuggestedLocationId(item.locationId)
    ) {
      return suggestedLocationIcon;
    } else {
      return assignedIcon;
    }
  };

  const handleSuggestedLocationChange = (editLocation, selectedMode) => {
    let _suggestedLocations = [];
    if (!isEmpty(editLocation)) {
      if (selectedMode) {
        suggestedLocations.map((item) => {
          if (item.locationId !== editLocation.locationId)
            _suggestedLocations.push(item);
        });
      } else {
        let _newSuggestedLocation = {
          locationId: editLocation.locationId,
          displayName: editLocation.displayName
        };
        _suggestedLocations = [...suggestedLocations, _newSuggestedLocation];
      }
    } else {
      _suggestedLocations = [...suggestedLocations];
    }

    setSuggestedLocations(_suggestedLocations);
    setMaxOccupancy(_suggestedLocations.length);
  };

  const locationCenterDistance = (location1, location2) => {
    let dx = location1[0] - location2[0];
    let dy = location1[1] - location2[1];
    return Math.sqrt(dx * dx + dy * dy);
  };

  const isSuggestedLocationOverlap = (location) => {
    let locationPoint = getLocationPoint(location.locationId);
    for (var x in suggestedLocations) {
      if (suggestedLocations[x].locationId !== location.locationId) {
        let otherPoint = getLocationPoint(suggestedLocations[x].locationId);
        let dist = locationCenterDistance(locationPoint, otherPoint) * scale;
        if (dist < distance) {
          return true;
        }
      }
    }
    return false;
  };

  // this is necessary to define a wrapper component for drawing the circle markers
  // such that it can pick up any changes with the location position or selection.
  const SuggestedLocationCircle = ({ location, radius }) => {
    return (
      <CircleMarker
        center={mapImageProjection.transformPoint(
          getLocationPoint(location.locationId)
        )}
        radius={radius}
        color={isSuggestedLocationOverlap(location) ? "red" : "#3388ff"}
      />
    );
  };

  const showTemplateMatchingWidget = (map) => {
    return isHiveAPIEnabledAndHasMap(map) && hasAccessToHiveTemplateMatching();
  };

  const isHiveAPIEnabledAndHasMap = (map) => {
    return localStorage.isHiveApiEnabled() && !!map.url;
  };

  const hasAccessToHiveTemplateMatching = () => {
    return (
      localStorage.getUserRoles().includes(ROLES.ROOT) ||
      (localStorage.getUserRoles().includes(ROLES.ADMIN) &&
        localStorage.getTemplateMatchingEnabled())
    );
  };

  return (
    <React.Fragment>
      {floorMap.floorMapLocation && (
        <Grid container>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h3">
              {`${t("Locations.FloorMap_")} ${
                floorMap.floorMapLocation.displayName
              } ${floorMap.url ? "" : t("Locations.NotFound")}`}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {showTemplateMatchingWidget(floorMap) && (
              <LocationInsertMode
                location={location}
                floorMap={floorMap}
                locationPrefix={locationPrefix}
                setLocationPrefix={setLocationPrefix}
                locationSequence={locationSequence}
                setLocationSequence={setLocationSequence}
                addLocations={addLocations}
                setAddLocations={setAddLocations}
                insertMode={insertMode}
                setInsertMode={setInsertMode}
                seats={seats}
                setSeats={setSeats}
                reloadLocations={reloadLocations}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            {showTemplateMatchingWidget(floorMap) && (
              <TemplateMatching
                location={location}
                floorMap={floorMap}
                mapImageProjection={mapImageProjection}
                templateBound={templateBound}
                setTemplateBound={setTemplateBound}
                matchImageBound={matchImageBound}
                setMatchImageBound={setMatchImageBound}
                locationPrefix={locationPrefix}
                locationSequence={locationSequence}
                setLocationSequence={setLocationSequence}
                addLocations={addLocations}
                setAddLocations={setAddLocations}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            {image && (
              <MapContainer
                key={stateKey}
                className={classes.mapContainer}
                center={[0.0, 0.0]}
                zoom={1}
                scrollWheelZoom={false}
                whenCreated={setMap}
                crs={L.CRS.EPSG4326}
              >
                <MapEventHandler />

                <MapLeafletInsertLocations
                  map={map}
                  mapImageProjection={mapImageProjection}
                  locationPrefix={locationPrefix}
                  locationSequence={locationSequence}
                  setLocationSequence={setLocationSequence}
                  addLocations={addLocations}
                  setAddLocations={setAddLocations}
                  insertMode={insertMode}
                />

                <LayersControl position="topright">
                  <LayersControl.BaseLayer
                    checked
                    name={floorMap.floorMapLocation.displayName}
                  >
                    <ImageOverlay
                      url={floorMap.url}
                      bounds={mapImageProjection.bounds()}
                      attribution='&copy; <a href="https://hiveoffice.jonahgroup.com/">Hive Office</a>'
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.Overlay
                    checked={!saveMaxOccupancy}
                    name={coordinates[coordinateIndex]}
                  >
                    <LayerGroup>
                      {(seats || suggestedLocations.length > 0) &&
                        floorMap.floorMapLocation &&
                        seats.map((item, index) => {
                          if (
                            item.coordinate[
                              floorMap.floorMapLocation.locationType
                            ] &&
                            item.coordinate[
                              floorMap.floorMapLocation.locationType
                            ].point &&
                            item.coordinate[
                              floorMap.floorMapLocation.locationType
                            ].point.length === 2
                          )
                            return (
                              <Marker
                                key={item.locationId}
                                data={{
                                  seat: item,
                                  index: index,
                                  editMode: false
                                }}
                                position={mapImageProjection.transform(
                                  item.coordinate[
                                    floorMap.floorMapLocation.locationType
                                  ].point[0],
                                  item.coordinate[
                                    floorMap.floorMapLocation.locationType
                                  ].point[1]
                                )}
                                draggable={true}
                                eventHandlers={eventHandlers}
                                icon={locationIcon(item)}
                              >
                                <Tooltip
                                  direction="top"
                                  offset={[0, -30]}
                                  opacity={0.8}
                                >
                                  {item.displayName}
                                </Tooltip>

                                <LocationMarkerEditPopup
                                  item={item}
                                  itemPoint={
                                    item.coordinate[
                                      floorMap.floorMapLocation.locationType
                                    ].point
                                  }
                                  saveEditedLocation={saveEditedLocation}
                                  removeEditedLocation={removeEditedLocation}
                                  selectedMode={isSuggestedLocationId(
                                    item.locationId
                                  )}
                                  handleSelectedChange={
                                    handleSuggestedLocationChange
                                  }
                                />
                              </Marker>
                            );
                          else if (
                            selectedSeat.seat &&
                            item.locationId === selectedSeat.seat.locationId
                          )
                            // selected the unassigned seat, i.e. location has not point coordinate
                            return (
                              <Marker
                                key={item.locationId}
                                data={{
                                  seat: item,
                                  index: index,
                                  editMode: false
                                }}
                                position={map.getCenter()}
                                draggable={true}
                                eventHandlers={eventHandlers}
                                icon={unassignedIcon}
                              >
                                <Tooltip
                                  direction="top"
                                  offset={[0, -30]}
                                  opacity={0.8}
                                >
                                  {item.displayName}
                                </Tooltip>
                              </Marker>
                            );
                          return "";
                        })}
                    </LayerGroup>
                  </LayersControl.Overlay>

                  {projectedAdjLines.length > 0 && (
                    <LayersControl.Overlay
                      name={
                        t("Locations.Adjacent") +
                        " " +
                        coordinates[coordinateIndex]
                      }
                    >
                      <LayerGroup>
                        {projectedAdjLines.map((item, index) => (
                          <Polyline
                            key={`polyline${index}`}
                            pathOptions={{ color: "blue" }}
                            positions={item}
                          />
                        ))}
                      </LayerGroup>
                    </LayersControl.Overlay>
                  )}

                  {suggestedLocations.length > 0 && (
                    <LayersControl.Overlay
                      checked
                      name={t("Locations.SuggestedLocations")}
                    >
                      <LayerGroup>
                        {suggestedLocations.map((item, index) => (
                          <Marker
                            key={`marker${index}`}
                            position={mapImageProjection.transformPoint(
                              getLocationPoint(item.locationId)
                            )}
                            draggable={false}
                            eventHandlers={suggestedLocationEventHandlers}
                            icon={locationIcon(item)}
                          >
                            <Tooltip
                              direction="top"
                              offset={[0, -30]}
                              opacity={0.8}
                            >
                              {item.displayName}
                            </Tooltip>
                          </Marker>
                        ))}
                      </LayerGroup>
                    </LayersControl.Overlay>
                  )}

                  {suggestedLocations.length > 0 && (
                    <LayersControl.Overlay
                      checked
                      name={
                        t("Locations.SuggestedLocations") +
                        " " +
                        t("Locations.Radius")
                      }
                    >
                      <LayerGroup>
                        {suggestedLocations.map((item, index) => (
                          <SuggestedLocationCircle
                            key={`suggestedLocationCircle${index}`}
                            location={item}
                            radius={radius}
                          />
                        ))}
                      </LayerGroup>
                    </LayersControl.Overlay>
                  )}
                </LayersControl>
              </MapContainer>
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
