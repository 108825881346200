import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useLocalStore } from "../../hooks/useLocalStore";
import { setErrorMessage, setSuccessMessage } from "../../actions/index";
import { makeStyles } from "@material-ui/styles";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";

import { useCompanyService } from "../../services/useCompanyService";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Typography
} from "@material-ui/core";
import { isTrue } from "../../common";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  card: {
    width: "70%",
    marginTop: theme.spacing(5)
  },
  functionDescription: {
    marginBottom: theme.spacing(2)
  }
}));

const MigrationFunction = ({
  title,
  description,
  migrationFunction,
  disabled,
  openDialog
}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Card className={classes.card}>
      <CardHeader title={title} />
      <CardContent>
        <Typography className={classes.functionDescription}>
          {description}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            openDialog({
              open: true,
              title: title,
              description: description,
              function: migrationFunction
            })
          }
          disabled={disabled}
        >
          {t("Migration.RunMigration")}
        </Button>
      </CardContent>
    </Card>
  );
};

export const Migration = () => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const localStore = useLocalStore();
  const companyService = useCompanyService();
  const [functionRunning, setFunctionRunning] = useState(false);
  const [migrationDialog, setMigrationDialog] = useState({ open: false });

  const company = localStore.getCompany();

  const LOCATION_TIME_BOOKING_MIGRATION_DESCRIPTION = t(
    "Migration.AddTimeBookingField"
  );
  const UPLATE_LOCATIONS_WITH_ASSIGNMENTS_DESCRIPTION = t(
    "Migration.AddListOfUser"
  );
  const UPDATE_USER_COMPANIES_WITH_EMAILS = t(
    "Migration.AddEmailToUserCompanies"
  );
  const MIGRATE_QUESTIONNAIRE_DESCRIPTION = t(
    "Migration.MigrateQuestionnaireDescription"
  );
  const DELETE_OLD_CHECKINQUESTIONS_FIELDS_DESCRIPTION = t(
    "Migration.MigrateDeleteOldCheckInQuestionsFieldsDescription"
  );
  const POPULATE_DROPDOWN_COLLECTION = t(
    "Migration.PopulateDropdownListCollectionDescription"
  );
  const COPY_SCREENING_MESSAGES = t(
    "Migration.CopyScreeningMessagesToVisitorMessagesDescription"
  );

  // handlers
  const runCloudMigration = async (cloudFunction) => {
    setFunctionRunning(true);
    setMigrationDialog({ open: false });

    // do migration
    try {
      const result = await cloudFunction();

      if (result.data.success) {
        const path = "Success.Cloud." + result.data.message;
        const success = i18n.exists(path) ? t(path) : t("Success.General");
        dispatch(setSuccessMessage(success));
      } else {
        const path = "Errors.Cloud." + result.data.message;
        const error = i18n.exists(path)
          ? t(path)
          : t("Errors.DeleteQuestionSet");

        console.log(result.data.message);
        dispatch(setErrorMessage(error));
      }
    } catch (e) {
      console.log(e);
    }

    setFunctionRunning(false);
  };

  return (
    <div className="content">
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h3">
          {t("Migration.DataMigrationFunctions")}
          <i>{company && company.companyId}</i>{" "}
        </Typography>

        <MigrationFunction
          title={t("Migration.MigrateTimeBooking")}
          description={LOCATION_TIME_BOOKING_MIGRATION_DESCRIPTION}
          migrationFunction={companyService.locationTimeBookingMigration}
          disabled={functionRunning}
          openDialog={setMigrationDialog}
        />

        <MigrationFunction
          title={t("Migration.MigrateLocationWithAssignments")}
          description={UPLATE_LOCATIONS_WITH_ASSIGNMENTS_DESCRIPTION}
          migrationFunction={companyService.locationWithAssignmentsMigration}
          disabled={functionRunning}
          openDialog={setMigrationDialog}
        />

        <MigrationFunction
          title={t("Migration.MigrateEmailsToUserCompanies")}
          description={UPDATE_USER_COMPANIES_WITH_EMAILS}
          migrationFunction={companyService.userCompaniesWithEmailsMigration}
          disabled={functionRunning}
          openDialog={setMigrationDialog}
        />

        <MigrationFunction
          title={t("Migration.MigrateQuestionnaire")}
          description={MIGRATE_QUESTIONNAIRE_DESCRIPTION}
          migrationFunction={
            companyService.updateCheckInQuestionsWithQuestionnaire
          }
          disabled={functionRunning}
          openDialog={setMigrationDialog}
        />

        <MigrationFunction
          title={t("Migration.MigrateDeleteOldCheckInQuestionsFields")}
          description={DELETE_OLD_CHECKINQUESTIONS_FIELDS_DESCRIPTION}
          migrationFunction={companyService.deleteOldCheckInQuestionsFields}
          disabled={functionRunning}
          openDialog={setMigrationDialog}
        />

        <MigrationFunction
          title={t("Migration.PopulateDropdownListCollection")}
          description={POPULATE_DROPDOWN_COLLECTION}
          migrationFunction={companyService.generateDropdownLists}
          disabled={functionRunning}
          openDialog={setMigrationDialog}
        />

        <MigrationFunction
          title={"Give all companies the access policy field"}
          description={"Give all companies the access policy field"}
          migrationFunction={
            companyService.addAccessPolicyFieldToAllCompanies
          }
          disabled={functionRunning}
          openDialog={setMigrationDialog}
        />
        <MigrationFunction
          title={t("Migration.CopyScreeningMessagesToVisitorMessages")}
          description={COPY_SCREENING_MESSAGES}
          migrationFunction={
            companyService.copyScreeningMessagesToVisitorMessages
          }
          disabled={functionRunning}
          openDialog={setMigrationDialog}
        />
        <MigrationFunction
          title={t("Migration.AddLocationDateToBookings")}
          description={t("Migration.AddLocationDateToBookingsDescription")}
          migrationFunction={
            companyService.addLocationDateToBookings
          }
          disabled={functionRunning}
          openDialog={setMigrationDialog}
        />
        <MigrationFunction
          title="Convert Hive Gate reasons to checkInReasons"
          description="Converts the old reasons data structure into checkInReasons supporting multi-lang displayNames"
          migrationFunction={
            companyService.convertHiveGateReasonsToCheckInReasons
          }
          disabled={functionRunning}
          openDialog={setMigrationDialog}
        />
        <MigrationFunction
          title="Populate HR_ADMIN for all companies"
          description="Give to all company ADMINs the HR_ADMIN role. Beaware, this action will affect ALL companies. "
          migrationFunction={
            companyService.giveAllCompanyAdminsHRAdminRole
          }
          disabled={functionRunning}
          openDialog={setMigrationDialog}
        />
      </Container>
      {migrationDialog && isTrue(migrationDialog.open) && (
        <ConfirmDialog
          title={migrationDialog.title}
          heading={migrationDialog.description}
          message={t("Migration.RunMigration?")}
          onConfirm={() => runCloudMigration(migrationDialog.function)}
          onCancel={() => setMigrationDialog({ open: false })}
        />
      )}
    </div>
  );
};
