import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Typography, Grid } from "@material-ui/core";
import { isEmpty } from "../../common";

const useStyles = makeStyles(() => ({
  dashboardNumber: {
    fontSize: "64px",
    marginTop: "28px",
    marginBottom: "48px",
    textAlign: "center"
  },
  timeRangeButton: {
    width: "100%",
    margin: 2
  }
}));

const TimeRangeNumberDisplay = React.memo(function TimeRangeNumberDisplay(props) {
  const defaultButton = 0;
  // eslint-disable-next-line no-unused-vars
  const { classes, dataArray, rangeOptions, timeFilter, keyTemplate, t, ...rest } = props;
  const defaultClasses = useStyles();
  const [timeRange, setTimeRange] = useState(() => {
    return isEmpty(rangeOptions) ? rangeOptions[defaultButton] : 1;
  });
  const [selectedButton, setSelectedButton] = useState(defaultButton);
  
  return (
    <>
      <Typography variant='h2' className={classes.dashboardNumber || defaultClasses.dashboardNumber}>
        {dataArray.filter((data) => timeFilter(data, timeRange)).length}
      </Typography>
      <Grid container>
        {rangeOptions.map((range, index) => (
          <Grid container item xs={6} md={3} lg={6} spacing={0} key={`ButtonGridContainer-${index}`}>
            <Button
              key={keyTemplate + index}
              variant="contained"
              color={selectedButton === index ? "secondary" : "primary"}
              className={classes.timeRangeButton || defaultClasses.timeRangeButton}
              onClick={() => {
                setTimeRange(range);
                setSelectedButton(index);
              }}
            >
              {range + t('Dashboard.Days')}
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
});

export default TimeRangeNumberDisplay;