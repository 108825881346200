import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { FixedSizeList } from "react-window";
import { useCompanyService } from "../../services/useCompanyService";
import { useUserService } from "../../services/useUserService";
import { useTranslation } from 'react-i18next';
import { useLocalStore } from "../../hooks/useLocalStore";
import { EditLocation } from '../locations/EditLocation';
import { isNullOrUndefined } from "../../common";
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  IconButton,
  LinearProgress,
  Typography,
  Tooltip
} from '@material-ui/core';
import matchSorter from "match-sorter";
import { makeStyles } from "@material-ui/styles";
import { orange, blue, green } from '@material-ui/core/colors';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import {setErrorMessage, setSuccessMessage} from "../../actions";
import { isEmpty } from "../../common";
import { LocationMapSelect } from "./LocationMapSelect";
import { LocationMapLegend } from "./LocationMapLegend";
import MapLeaflet from "./MapLeaflet";
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  card: {
    maxWidth: "1000px",
    marginTop: theme.spacing(5),
    padding: "16px"
  },
  selectFileButtonWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    padding: "2px"
  },
  selectFileButtonOrigin: {
    width: "100%",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0
  },
  seatList: {
    margin: "auto"
  },
  selected: {
    color: orange[600]
  },
  unassigned: {
    color: blue[500]
  },
  assigned: {
    color: green[500]
  },
  seatListItemText: {
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  rightRail: {
    paddingTop:"10px"
  },
  mapControls: {
    marginTop:"16px",
    display:"grid",
    gridTemplateColumns:"2fr 1fr",
    gridColumnGap:"16px",
    "&>div:last-of-type": {
      display:"grid",
      justifySelf:"center",
      gridTemplateRows:"40px 1fr"
    }
  }
}));

export const LocationMapPlus = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const companyService = useCompanyService();
  const userService = useUserService();
  const localStore = useLocalStore();

  const [init, setInit] = useState(false);
  const [locations, setLocations] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [coordinateIndex, setCoordinateIndex] = useState(0);
  const [eligibleLocations, setEligibleLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [seats, setSeats] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState({});
  const [floorMap, setFloorMap] = useState({});
  const [floorMapUrlPrefix, setFloorMapUrlPrefix] = useState({});
  const [locationMaps, setLocationMaps] = useState({});
  const [company, setCompany] = useState();

  const [editLocation, setEditLocation] = useState(null);
  const [autoSave, setAutoSave] = useState(false);
  const [alert, setAlert] = useState(false);

  const preview = useRef();
  const seatListRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      const _company = localStore.getCompany();
      setCompany(_company);
      setLocationMaps(_company.locationMaps);
      setCoordinates(_company.coordinateSystem);
      setFloorMapUrlPrefix(`Companies/${_company.companyId}/`);
      const _locations = await companyService.getAllLocations();
      setLocations(_locations);
      setInit(true);
    };
    loadData();
  }, []);

  useEffect(() => {
    if (locations.length > 0 && coordinates.length > 0) {
      const _eligibleLocations = userService.getEligibleNoneBookableLocations(
        null, // pass null to don't check userGroups for locations
        locations,
        coordinates
      );
      setEligibleLocations(_eligibleLocations);
    }
  }, [locations, coordinates]);

  useEffect(() => {
    if (selectedLocation) {
      const _results = locations.filter(location => location.locationParent === selectedLocation.locationId && location.bookable);
      if(_results.length > 0){
        const _sortedSeats = _results.sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);
        setSeats(_sortedSeats);
      } else {
        setSeats(_results);
      }
      const _floorMapInformation= loadFloorMapInformation(selectedLocation);

      if (_floorMapInformation.floorMapPath) {
        userService
          .getFloorMapUrl(floorMapUrlPrefix + _floorMapInformation.floorMapPath)
          .then((url) => {
            setFloorMap({..._floorMapInformation, url: url});
          });
      } else {
        setFloorMap({floorMapLocation: selectedLocation, url: null});
      }

      let _index = 0;
      coordinates.forEach(function (item, index) {
        if (item === selectedLocation.locationType) {
          _index = index;
        }
      });
      setCoordinateIndex(_index+1);
    }
  }, [selectedLocation]);

  const loadLocations = async () => {
    setSelectedSeat({});
  };

  const updateEditedLocation = (editedLocation) => {
    let _index = -1;
    locations.forEach(function (item, index) {
      if (item.locationId === editedLocation.locationId) {
        _index = index;
      }
    });

    if (_index >= 0) {
      locations[_index] = {...editedLocation};
    }

  };

  const closeEditLocation = async (location) => {
    if (location) {
      let _seats = [...seats];

      if (editLocation.createMode) {
        let newLocation = await companyService.getLocation(location.locationId);
        let _locations = [...locations];
        _locations.push(newLocation);
        setLocations(_locations);
        forceRefreshLocation();
      } else {
        _seats[editLocation.index] = location;
        updateEditedLocation(location);
        forceRefreshLocation();
      }

      setSeats(_seats);
    }

    setEditLocation(null);
  };

  const loadFloorMapInformation = (location) =>{
    let _floorMapPath = locationMaps
      ? locationMaps[location.locationId]
      : null;
    let _floorMapLocation = location;
    let _floorMapInformation = {floorMapPath: _floorMapPath, floorMapLocation: _floorMapLocation};

    if(_floorMapPath == null){
      const _parentLocationId = location.locationParent;
      if(_parentLocationId && _parentLocationId.length > 0){
        const _parentLocation = locations.find(
          (currLocation) => currLocation.locationId === _parentLocationId
        );
        _floorMapInformation = loadFloorMapInformation(_parentLocation);
      }else
        _floorMapInformation = {floorMapPath: null, floorMapLocation: _floorMapLocation};
    }

    return _floorMapInformation;
  };


  const getLocationOptionName = (location) => {
    let _name = "";
    const locationType = location.locationType;
    for (let i = 0; i < coordinates.length; i++) {
      const _coordinateNode = coordinates[i];
      _name = _name.concat(location.coordinate[_coordinateNode].displayName);
      if (_coordinateNode === locationType) break;
      else _name = _name.concat(" -> ");
    }
    return _name;
  };

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, { keys: ["displayName"] });

  const handleLocationChange = (newLocation) => {
    setSelectedLocation(newLocation);
    setSelectedSeat({});
    setFloorMap({});
  };

  const isSeatInEditMode = (seat) => {
    return selectedSeat.seat &&
        seat.locationId === selectedSeat.seat.locationId;
  };

  const getSeatStyle = (seat) => {
    if(floorMap.floorMapLocation){
      const _isUnAssigned = !(seat.coordinate[floorMap.floorMapLocation.locationType].point
            && seat.coordinate[floorMap.floorMapLocation.locationType].point.length === 2);
      const _isSelected = selectedSeat.seat &&
          seat.locationId === selectedSeat.seat.locationId;
      if(!_isSelected)
        return _isUnAssigned ? classes.unassigned : classes.assigned;
      else{
        return _isSelected ? (selectedSeat.editMode ? classes.selected : classes.selected) : classes.default;
      }
    }else {
      return classes.default;
    }
  };

  const saveSeatCoordinate = async (seat) => {
    if(isSeatInEditMode(seat)) {
      let _selectedSeat = selectedSeat.seat;
      await companyService.updateLocation(_selectedSeat)
        .then((res) => {
          dispatch(res.data.success ? 
            setSuccessMessage(t("Success.UpdateCoordinates")) : 
            setErrorMessage(t('Errors.General'))
          );
        });
      setSelectedSeat({seat: _selectedSeat, editMode : false});
    }
  };

  useEffect(() => {

    if (!isEmpty(selectedSeat.index)) {
      seatListRef.current.scrollToItem(selectedSeat.index);
      if (autoSave) {
        saveSeatCoordinate(seats[selectedSeat.index]);
      }
    }

  },[selectedSeat, autoSave]);

  const handleSelectFileChange = useCallback((e, company, location) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const bytesPerMB = 1048576;
    if (!isEmpty(file) && file.size < 2 * bytesPerMB){
      setAlert(false);
      reader.onload = function() {
        // make sure the image data is encoded in base64 by btoa()
        const data = {
          name: file.name,
          type: file.type,
          companyId: company.companyId,
          blob: btoa(reader.result)
        };

        companyService.uploadFloorPlan(data)
          .then(async (data) =>{
            const filename = 'images/floorplans/' + file.name;
            company.locationMaps[location.locationId] = filename;
            await companyService.updateCompany(company);
            console.log(data);
            setLocationMaps(company.locationMaps);
            forceRefreshLocationMap(location);
          });
      };
      reader.readAsBinaryString(file);
    } else {
      setAlert(false);
    }
  }, []);


  const forceRefreshLocationMap = (location) => {
    handleLocationChange(null);
    // slight delay to force React to refresh all components
    setTimeout(function() {
      handleLocationChange(location);
    }, 100);
  };


  const forceRefreshLocation = () => {
    let _selected = selectedLocation;
    handleLocationChange(null);
    // slight delay to force React to refresh all components
    setTimeout(function() {
      handleLocationChange(_selected);
    }, 100);
  };

  const add = () => {
    setEditLocation({
      createMode: true,
      location: {
        locationParent: selectedLocation,
        locationType: coordinates[coordinateIndex],
        locationTrail: [],
        bookable: true,
        atBottomLevel: coordinateIndex === coordinates.length - 1,
        timeBookable: false
      }
    });
  };

  const edit = (location, index) => {
    const isMeetingRoom = location.isMeetingRoom;
    if (isNullOrUndefined(isMeetingRoom)){
      location.isMeetingRoom = false;
    }

    setEditLocation({
      createMode: false,
      location: location,
      index: index
    });
  };

  const SeatRow = ({ index, style }) => (
    <ListItem
      style={style}
      key={seats[index].locationId}
      button
      selected={
        selectedSeat.seat &&
        seats[index].locationId === selectedSeat.seat.locationId
      }
    >
      <IconButton onClick={() => setSelectedSeat({seat: seats[index], editMode : false})}>
        <EventSeatIcon fontSize="small"
          className={getSeatStyle(seats[index])} />
      </IconButton>
      <Tooltip title={seats[index].displayName}>
        <ListItemText className={classes.seatListItemText} 
          primary={seats[index].displayName} onClick={() => setSelectedSeat({seat: seats[index], editMode : false})}/>
      </Tooltip>
      <IconButton onClick={() => {
        setSelectedSeat({seat: seats[index], editMode : true});
        edit(seats[index], index);
      }}>
        <EditIcon fontSize="small"/>
      </IconButton>
      <IconButton disabled={!isSeatInEditMode(seats[index])} onClick={() => saveSeatCoordinate(seats[index])}>
        <SaveIcon fontSize="small" />
      </IconButton>
    </ListItem>
  );

  const EditFunctionsRow = () => {
    return <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
      <IconButton onClick={add}>
        <AddIcon/>{" "}
        <Typography component="span">
          {t("Commons.Add")} {coordinates[coordinateIndex]}
        </Typography>
      </IconButton>
      <IconButton onClick={forceRefreshLocation}>
        <RefreshIcon/>{" "}
        <Typography component="span">
          {t("Commons.Refresh")}
        </Typography>
      </IconButton>
    </ButtonGroup>;
  };

  if (init) {
    return (
      <div className="content">
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h3">{t("Locations.LocationMaps")}+</Typography>
          <Card className={classes.card } >
            {eligibleLocations && (
              <LocationMapSelect
                eligibleLocations={eligibleLocations}
                filterOptions={filterOptions}
                getLocationOptionName={getLocationOptionName}
                handleLocationChange={handleLocationChange}
              />
            )}

            <LocationMapLegend />

            <div className={classes.mapControls}>
              <div>
                {
                  Object.keys(floorMap).length < 1
                    ? ""
                    : floorMap.url
                      ? <MapLeaflet stateKey={0} floorMap={floorMap}
                        selectedSeat={selectedSeat} setSelectedSeat={setSelectedSeat}
                        seats={seats} setAutoSave={setAutoSave} editMode={true} />
                      : <div className={classes.fileSelector}>
                        <div className={classes.selectFileButtonWrapper}>
                          <Button variant="contained" color="primary">
                            {t("Locations.UploadFloorPlan")}
                          </Button>
                          <input
                            type="file"
                            className={classes.selectFileButtonOrigin}
                            onChange={(e) => handleSelectFileChange(e, company, selectedLocation)}
                            accept="image/png, image/jpeg"
                          ></input>
                        </div>
                        <img ref={preview} />
                      </div>

                }

              </div>
              <div className={classes.rightRail}>
                {
                  Object.keys(floorMap).length < 1
                    ? ""
                    : !floorMap.url
                      ? ""
                      :<div className={classes.fileSelector}>
                        <div className={classes.selectFileButtonWrapper}>
                          <Button variant="contained" color="primary">
                            {t("Locations.UpdateFloorPlan")}
                          </Button>
                          <input
                            type="file"
                            className={classes.selectFileButtonOrigin}
                            onChange={(e) => handleSelectFileChange(e, company, selectedLocation)}
                            accept="image/png, image/jpeg"
                          ></input>
                        </div>
                      </div>
                }
                {alert ? <Alert severity='error'>{t("Locations.AlertFloorMapSizeLimits")}</Alert> : <></> }
                <EditFunctionsRow />

                <FixedSizeList ref={seatListRef} className={classes.seatList} height={600} width={250} itemSize={46} itemCount={seats.length} aria-label="seats">
                  {SeatRow}
                </FixedSizeList>
              </div>
            </div>
          </Card>
        </Container>

        {editLocation && (
          <EditLocation
            createMode={editLocation.createMode}
            location={editLocation.location}
            users={[]}
            userGroups={[]}
            close={closeEditLocation}
            company={company}
            parentLocation={selectedLocation}
            reloadLocations={loadLocations}
          />
        )}
      </div>
    );
  } else {
    return <LinearProgress />;
  }
};
