import { tryGet, tryCompare } from "../common";
import { COMPANY_PREFERENCES } from "../constants";

export const CompanyPreferencesUtil = (companyPreferences) => {
  return {
    displayAccessPolicyField: () => {
      return tryGet(() => companyPreferences.hiveAccessPolicy.showAccessPolicyField, false);
    },

    hasAccessLevel: (accessLevel) => {
      return (tryGet( () => companyPreferences.hiveAccessPolicy.showAccessPolicyField, false) &&
        tryCompare(() => companyPreferences.hiveAccessPolicy.accessLevel, accessLevel));
    },

    getHiveSyncProviders: () => {
      return tryGet(() => companyPreferences.hiveSyncService.providers, null);
    },

    getHiveSyncMicrosoftProvider: () => {
      return tryGet(() => companyPreferences.hiveSyncService.providers.microsoft, null);
    },

    getMicrosoftTenantId: () => {
      return tryGet(() => companyPreferences.hiveSyncService.providers.microsoft.tenantId, "");
    },

    getHiveSyncGoogleProvider: () => {
      return tryGet(() => companyPreferences.hiveSyncService.providers.google, null);
    },

    getGoogleProviderEmail: () => {
      return tryGet(() => companyPreferences.hiveSyncService.providers.google.email, "");
    },

    getHiveAccessPolicyLevel: () => {
      return tryGet(() => companyPreferences.hiveAccessPolicy.accessLevel, "");
    },

    getHiveAccessPolicyMessage: () => {
      return tryGet(() => companyPreferences.hiveAccessPolicy.message, {en: "", fr: ""});
    },

    sendAccessNotification: () => {
      return tryGet(() => companyPreferences.hiveAccessPolicy.sendAccessNotification, false);
    },

    getUserGroupManagedPassword: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.userGroupManagedPassword], []);
    },

    getMaxAdvanceReservationDays: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.maxAdvanceReservationDays], 0);
    },

    getLocationCleanBuffer: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.locationCleanBuffer], 0);
    },

    getAutoCheckoutBuffer: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.autoCheckoutBuffer], 0);
    },

    getReleaseBuffer: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.autoReleaseBuffer], 0);
    },

    displayVaccinatedField: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.showVaccinatedField], false);
    },

    isCalendarSyncEnabled: () => {
      return tryGet(() => companyPreferences.hiveSyncService.enableCalendarSync, false);
    },

    isScheduledNotificationsEnabled: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.enableScheduledNotifications], false);
    },

    isCaseManagementEnabled: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.enableCaseManagement], false);
    },

    isResourceManagementEnabled: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.enableResourceManagement], false);
    },

    isCleaningEnabled: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.cleaningEnabled], false);
    },

    isDropInEnabled: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.enableDropIn], false);
    },

    isBookingsEnabled: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.bookingsEnabled], false);
    },

    isAssignedLocationExclusive: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.assignedLocationExclusive], false);
    },

    isAdjacentLocationBlocking: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.adjacentLocationBlocking], false);
    },

    isVisitorsEnabled: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.visitorsEnabled], false);
    },

    isMeetingsEnabled: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.meetingsEnabled], false);
    },

    isDirtySeatBookingAllowed: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.allowDirtySeatBooking], false);
    },

    isDirtySeatCheckInAllowed: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.allowDirtySeatCheckIn], false);
    },

    isStoreHealthScreeningAnswersEnabled: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.storeHealthScreeningAnswers], false);
    },

    isBookingApprovalEnabled: () => {
      return tryGet(() => companyPreferences[COMPANY_PREFERENCES.enableBookingApproval], false);
    }
  };
};
