export const SET_MESSAGE = "SET_MESSAGE";

export const setSuccessMessage = (message) => {
  return {
    type: SET_MESSAGE,
    payload: { severity: "success", message }
  };
};

export const setErrorMessage = (message) => {
  return {
    type: SET_MESSAGE,
    payload: { severity: "error", message }
  };
};

export const setWarningMessage = (message) => {
  return {
    type: SET_MESSAGE,
    payload: { severity: "warning", message }
  };
};
