import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  makeStyles
} from "@material-ui/core";
import { isEmpty } from "../../common";
import { grey } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import { ErrorAlert } from "./ErrorAlert";
import StyledRadio from "../../components/StyledRadio/StyledRadio";

export const MessageDialog = ({ title, message, close }) => {
  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  formDialog: {
    minWidth: "25em"
  },
  errorMsg: {
    margin: "20px"
  },
  question: {
    fontFamily: "inherit",
    wordBreak: "break-word",
    whiteSpace: "pre-line"
  },
  actions: {
    padding: theme.spacing(1, 3),
    margin: theme.spacing(2, 0)
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export const FormDialog = ({
  title,
  questions,
  questionSetId,
  handleCancel,
  handleSubmit
}) => {
  const [errMsgs, setErrMsgs] = useState([]);
  const [answers, setAnswers] = useState(Array(questions.length).fill(null));
  const [submitted, setSubmitted] = useState(false);
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  if (isEmpty(questions)) {
    handleSubmit(answers);
  }

  const _questions = useMemo(() => {
    var english = questions.map((q) => q.question["en"]);
    var userLanguage = questions.map((q) => q.question[i18n.language]);
    if (
      english.length === userLanguage.length &&
      !userLanguage.some((q) => isEmpty(q))
    ) {
      return userLanguage;
    } else {
      return english;
    }
  }, [questions, i18n]);

  const submit = () => {
    if (answers.some((a) => a === null)) {
      setErrMsgs([t("Questions.AnswerAllQuestions")]);
      return;
    }
    setSubmitted(true);
    handleSubmit(answers, questionSetId);
  };

  const setAnswer = (event, index) => {
    const _answers = answers;
    _answers[index] = event.target.value === "true";

    const result = [];
    for (let elem of _answers) {
      result.push(elem);
    }
    setAnswers(result);
  };

  useEffect(() => {}, [answers, errMsgs]);

  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
      </DialogTitle>
      {errMsgs.length > 0 && (
        <ErrorAlert
          errorMessages={errMsgs}
          onCloseAlert={setErrMsgs}
          styles={classes.errorMsg}
        />
      )}
      <DialogContent className={classes.formDialog}>
        {_questions.map((q, index) => (
          <>
            <Typography variant="body1" gutterBottom>
              <pre className={classes.question}>{q}</pre>
            </Typography>
            <RadioGroup
              value={answers[index]}
              onChange={(e) => setAnswer(e, index)}
            >
              <FormControlLabel
                value={true}
                control={<StyledRadio />}
                label={t("Commons.Yes")}
              />
              <FormControlLabel
                value={false}
                control={<StyledRadio />}
                label={t("Commons.No")}
              />
            </RadioGroup>
          </>
        ))}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleCancel} disabled={submitted}>
          {t("Commons.Cancel")}
        </Button>
        <Button
          onClick={submit}
          variant="contained"
          color="primary"
          disabled={submitted}
        >
          {t("Commons.Submit")}
          {submitted && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};