import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import LayeredMapLeaflet from "./LayeredMapLeaflet";
import GeoMapLeaflet from "./GeoMapLeaflet";
import { MaxOccupancy } from './MaxOccupancy';
import { useDispatch } from "react-redux";
import { useCompanyService } from "../../services/useCompanyService";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useLocalStore } from "../../hooks/useLocalStore";
import { useUserService } from "../../services/useUserService";
import { useHiveApiService } from "../../services/useHiveApiService";
import { useTranslation } from 'react-i18next';
import { EditLocation } from '../locations/EditLocation';
import { isEmpty, isTrue } from "../../common";
import { ROLES } from "../../constants";
import {
  Button,
  ButtonGroup,
  Card,
  colors,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

import RefreshIcon from "@material-ui/icons/Refresh";
// import DeleteIcon from '@material-ui/icons/Delete';
import { setErrorMessage, setSuccessMessage } from "../../actions";
import { LocationMapLegend } from "./LocationMapLegend";
import { LocationMapList } from "./LocationMapList";
import { MapImageProjection } from './MapImageProjection';
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  card: {
    marginTop: theme.spacing(5),
    padding: "16px"
  },
  selectFileButtonWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    padding: "2px"
  },
  selectFileButtonOrigin: {
    width: "100%",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0
  },
  panelHeader: {
    backgroundColor: colors.grey[50]
  },
  resetButton: {
    paddingLeft: "22px"
  },
  resetLabel: {
    paddingLeft: "10px"
  },
  spacer: {
    marginBottom: "10px"
  }
}));

export const LocationMapEditor = ({ targetLocation, locationTrail, seats, setSeats, reloadLocations, users }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const companyService = useCompanyService();
  const userService = useUserService();
  const hiveApi = useHiveApiService();
  const localStorage = useLocalStorage();
  const localStore = useLocalStore();

  const [init, setInit] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [coordinateIndex, setCoordinateIndex] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [suggestedLocations, setSuggestedLocations] = useState([]);
  const [locationAdjacentLines, setLocationAdjacentLines] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState({});
  const [floorMap, setFloorMap] = useState({});
  const [floorMapUrlPrefix, setFloorMapUrlPrefix] = useState({});
  const [locationMaps, setLocationMaps] = useState({});
  const [company, setCompany] = useState();

  const [editLocation, setEditLocation] = useState(null);
  const [userGroups, setUserGroups] = useState(null);
  const [autoSave, setAutoSave] = useState(false);
  const [maxOccupancy, setMaxOccupancy] = useState(0);
  const [saveMaxOccupancy, setSaveMaxOccupancy] = useState(false);

  const [scale, setScale] = useState("");
  const [distance, setDistance] = useState(2.0);
  const [map, setMap] = useState(null);
  const [deleteAllDialog, setDeleteAllDialog] = useState({open: false});
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [mapImageProjection, setMapImageProjection] = useState(new MapImageProjection());

  const dispatch = useDispatch();

  useEffect(() => {
    // Defining loadData function inside the useEffect to avoid function dependency
    const loadData = async () => {
      const _company = localStore.getCompany();
      setCompany(_company);
      setLocationMaps(_company.locationMaps);
      setCoordinates(_company.coordinateSystem);
      setFloorMapUrlPrefix(`Companies/${_company.companyId}/`);
      handleLocationChange(targetLocation);
      const _userGroups = await companyService.getUserGroups();
      setUserGroups(_userGroups);
      setInit(true);
    };
    loadData();
  }, [targetLocation]);

  useEffect(() => {
    if (selectedLocation) {
      const _floorMapInformation = loadFloorMapInformation(selectedLocation);

      if (_floorMapInformation.floorMapPath) {
        userService
          .getFloorMapUrl(floorMapUrlPrefix + _floorMapInformation.floorMapPath)
          .then(async (url) => {
            setFloorMap({ ..._floorMapInformation, url: url });

            // setup floor map meta data if not existed, create the map
            // remember the scale is stored with the floor map location's level, not the selected location
            let _floorMapLocation = _floorMapInformation.floorMapLocation;
            if (isEmpty(company.locationMapMetas)) {
              let _locationMapMetas = {};
              _locationMapMetas[_floorMapLocation.locationId] = {
                scale: 0.02
              };
              company.locationMapMetas = _locationMapMetas;
              await companyService.updateCompany(company);
            }
            else {
              let _locationMapMetas = company.locationMapMetas;
              let _locationMapMeta = _locationMapMetas ? _locationMapMetas[_floorMapLocation.locationId] : null;
              // setup selected floor map meta data if not existed
              if (isEmpty(_locationMapMeta)) {
                _locationMapMetas[_floorMapLocation.locationId] = {
                  scale: 0.02
                };
                await companyService.updateCompany(company);
              }
              else {
                setScale(_locationMapMeta.scale);
              }
            }

          });
      } else {
        setFloorMap({ floorMapLocation: selectedLocation, url: null });
      }

      let _index = 0;
      coordinates.forEach(function (item, index) {
        if (item === selectedLocation.locationType) {
          _index = index;
        }
      });
      // set coordinate index to the next level
      setCoordinateIndex(_index + 1);
    }
  }, [selectedLocation]);

  const loadLocations = async () => {
    // do nothing at this level
  };

  const closeEditLocation = async (location) => {
    if (location) {
      let _seats = [...seats];

      if (!editLocation.createMode) {
        _seats[editLocation.index] = location;
      }

      setSeats(_seats);
    }

    setEditLocation(null);
  };

  const loadFloorMapInformation = (location) => {
    let _floorMapPath = locationMaps
      ? locationMaps[location.locationId]
      : null;
    let _floorMapLocation = location;
    let _floorMapInformation = { floorMapPath: _floorMapPath, floorMapLocation: _floorMapLocation };

    if (_floorMapPath == null) {
      const _parentLocationId = location.locationParent;
      if (_parentLocationId && _parentLocationId.length > 0) {
        const _parentLocation = locationTrail.find(
          (currLocation) => currLocation.locationId === _parentLocationId
        );
        _floorMapInformation = loadFloorMapInformation(_parentLocation);
      } else
        _floorMapInformation = { floorMapPath: null, floorMapLocation: _floorMapLocation };
    }

    return _floorMapInformation;
  };

  const isSeatInEditMode = (seat) => {
    return selectedSeat.seat &&
      seat.locationId === selectedSeat.seat.locationId;
  };

  const saveSeatCoordinate = async (seat) => {
    if (isSeatInEditMode(seat)) {
      let _selectedSeat = selectedSeat.seat;
      await companyService.updateLocation(_selectedSeat).then((res) => {
        dispatch(res.data.success ? setSuccessMessage(t("Success.UpdateCoordinates"))
          : setErrorMessage(t('Errors.General')));
      }
      );
      setSelectedSeat({ seat: _selectedSeat, editMode: false });
    }
  };

  const saveEditedLocation = async (seat) => {
    await companyService.updateLocation(seat).then((res) => {
      dispatch(res.data.success ? setSuccessMessage(t("Success.UpdateLocation"))
        : setErrorMessage(t('Errors.General')));
    }
    );
    setSelectedSeat({ seat: seat, editMode: false });
  };

  const updateLocationsDropdownList = () => {
    return companyService.updateLocationsDropdownList();
  };

  const removeEditedLocation = async (seat) => {
    await companyService.removeLocation(seat).then((res) => {
      dispatch(res.data.success ? setSuccessMessage(t("Success.DeleteLocation"))
        : setErrorMessage(t('Errors.General')));

      // from Locations.jsx - which force reloading all children from the given parent location Id
      reloadLocations(selectedLocation.locationId);
      updateLocationsDropdownList();
    });
  };

  useEffect(() => {

    if (!isEmpty(selectedSeat.index)) {
      if (autoSave) {
        saveSeatCoordinate(seats[selectedSeat.index]);
      }
    }

  }, [selectedSeat, autoSave]);

  const handleSelectFileChange = useCallback((e, company, location) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const bytesPerMB = 1048576;
    if (file && file.size < 2 * bytesPerMB){
      setAlert(false);
      reader.onload = function () {
        const data = {
          name: file.name,
          type: file.type,
          companyId: company.companyId,
          blob: btoa(reader.result)
        };
        companyService.uploadFloorPlan(data)
          .then(async (data) => {
            const filename = 'images/floorplans/' + file.name;
            company.locationMaps[location.locationId] = filename;
            await companyService.updateCompany(company);
            console.log(data);
            setLocationMaps(company.locationMaps);
            setSelectedLocation(null);
            handleLocationChange(location);
          });
      };
      reader.readAsBinaryString(file);
    } else {
      setAlert(true);
    }

  }, []);

  const handleLocationChange = (newLocation) => {
    setSelectedLocation(newLocation);
    setSelectedSeat({});
    setFloorMap({});
  };

  const forceRefreshLocation = () => {
    let _selected = selectedLocation;
    handleLocationChange(null);
    setTimeout(function () {
      handleLocationChange(_selected);
    }, 1000);
  };

  // const confirmDeleteAllLocations = () => {
  //   setDeleteAllDialog({open: true});
  // };

  const handleDeleteAllLocations = async () => {
    const requestBody = {
      data: {
        locationId: targetLocation.locationId
      }
    };

    setIsLoading(true);
    await hiveApi.deleteAllChildLocations(requestBody).then(response => {
      if (response.success) {
        setIsLoading(false);
        // from Locations.jsx - which force reloading all children from the given parent location Id
        reloadLocations(selectedLocation.locationId);
        updateLocationsDropdownList();
      } else {
        dispatch(setErrorMessage(t('Errors.General')));
        setIsLoading(false);
      }
      setDeleteAllDialog({open: false});
    });
  };

  const handleSetScale = async (scaleValue) => {
    if (Number(scaleValue) > 0) {
      let _floorMapLocation = floorMap.floorMapLocation;
      let _locationMapMetas = company.locationMapMetas;
      let _locationMapMeta = _locationMapMetas ? _locationMapMetas[_floorMapLocation.locationId] : null;
      if (isEmpty(_locationMapMeta)) {
        _locationMapMetas[_floorMapLocation.locationId] = {
          scale: Number(scaleValue)
        };
        await companyService.updateCompany(company);
      }
      else {
        if (Number(scaleValue) != _locationMapMeta.scale) {
          _locationMapMetas[_floorMapLocation.locationId].scale = Number(scaleValue);
          await companyService.updateCompany(company);
        }
      }
    }
    setScale(scaleValue);
  };


  const prepareOutputMaxOccupancyData = (saveMaxOccupancyData) => {
    // the suggested location may have been updated, populated with the latest
    let _locationSelected = [];
    suggestedLocations.map((item) => {
      _locationSelected.push(item.locationId);
    });
    saveMaxOccupancyData.data.locationSelected = _locationSelected;

    // the locations position may have been updated, populated with the latest
    let _locationPoints = {};
    for (var x in seats) {
      _locationPoints[seats[x].locationId] = seats[x].coordinate[floorMap.floorMapLocation.locationType].point;
    }
    saveMaxOccupancyData.data.locationPoints = _locationPoints;
    return saveMaxOccupancyData;
  };


  const showMaxOccupancyWidget = (map) => {
    return (isHiveAPIEnabledAndHasMap(map) && hasAccessToHiveMaxOccupancy());
  };

  // TODO: This is usefull for deleting many locations at once but it's not done correctly since hivaAPI is not checking for existing bookings. We should re-evaluae this.
  // const showDeleteAllLocations = (map) => {
  //   let seatAtBottomLevel = seats && seats.length > 0 && seats[0].atBottomLevel;
  //   return (isHiveAPIEnabledAndHasMap(map) && seatAtBottomLevel);
  // };

  const isHiveAPIEnabledAndHasMap = (map) => {
    return localStorage.isHiveApiEnabled() && !!map.url;
  };

  const hasAccessToHiveMaxOccupancy = () => {
    return localStorage.getUserRoles().includes(ROLES.ROOT) || (
      localStorage.getUserRoles().includes(ROLES.ADMIN) && 
      localStorage.getMaximumOccupancyEnabled()
    );
  };

  const isGeographicMapAllowed = () => {
    if (localStorage.getUserRoles().includes(ROLES.ROOT)) {
      let isGeographic = false;
      // found at least 1 location is geographic location
      for (var x in seats) {
        if (! isEmpty(seats[x].geometry) && Object.keys(seats[x].geometry).length != 0) {
          isGeographic = true;
        }
      }
      return isGeographic;
    }
    return false;
  };

  const isFloorMapInitialized = () => {
    return Object.keys(floorMap).length > 0;
  };

  const displayLayeredMapLeaflet = () => {
    return (
      <LayeredMapLeaflet
        stateKey={0}
        coordinates={coordinates}
        coordinateIndex={coordinateIndex}
        location={selectedLocation}
        floorMap={floorMap}
        selectedSeat={selectedSeat}
        setSelectedSeat={setSelectedSeat}
        seats={seats}
        setSeats={setSeats}
        setAutoSave={setAutoSave}
        reloadLocations={reloadLocations}
        forceRefreshLocation={forceRefreshLocation}
        scale={scale}
        handleSetScale={handleSetScale}
        distance={distance}
        map={map}
        setMap={setMap}
        mapImageProjection={mapImageProjection}
        suggestedLocations={suggestedLocations}
        setSuggestedLocations={setSuggestedLocations}
        locationAdjacentLines={locationAdjacentLines}
        saveEditedLocation={saveEditedLocation}
        removeEditedLocation={removeEditedLocation}
        setMaxOccupancy={setMaxOccupancy}
        saveMaxOccupancy={saveMaxOccupancy}
        setSaveMaxOccupancy={setSaveMaxOccupancy}
        editMode={true}
      />
    );
  };

  const displayGeoMapLeaflet = () => {
    return (
      <GeoMapLeaflet
        stateKey={0}
        company={company}
        selectedSeat={selectedSeat}
        setSelectedSeat={setSelectedSeat}
        seats={seats}
        setSeats={setSeats}
        setAutoSave={setAutoSave}
        saveEditedLocation={saveEditedLocation}
        removeEditedLocation={removeEditedLocation}
      />
    );
  };

  const displayUploadFloorPlanButton = () => {
    return (
      <Grid container align="center" className={classes.selectFileButtonWrapper}>
        <Button variant="contained" color="primary" disabled={!selectedLocation}>
          {t("Locations.UploadFloorPlan")}
          <input
            type="file"
            className={classes.selectFileButtonOrigin}
            onChange={(e) => handleSelectFileChange(e, company, selectedLocation)}
            accept="image/png, image/jpeg" />
        </Button>
      </Grid>
    );
  };

  if (init) {
    return (
      <React.Fragment>
        <Card className={classes.card} >
          <Grid container alignItems="center" justify="space-between" className={classes.panelHeader} >
            <Grid item xs={9}>
              {
                isFloorMapInitialized() && floorMap.url ? displayLayeredMapLeaflet() :
                  isGeographicMapAllowed() ? displayGeoMapLeaflet() : displayUploadFloorPlanButton()
              }
            </Grid>
            <Grid item xs={3}>
              {isFloorMapInitialized() && (
                floorMap.url &&
                <div className={classes.selectFileButtonWrapper}>
                  <Button variant="contained" color="primary">
                    {t("Locations.UpdateFloorPlan")}
                  </Button>
                  <input
                    type="file"
                    className={classes.selectFileButtonOrigin}
                    onChange={(e) => handleSelectFileChange(e, company, selectedLocation)}
                    accept="image/png, image/jpeg"
                  ></input>
                </div>
              )}
              <LocationMapList
                floorMap={floorMap}
                selectedSeat={selectedSeat}
                setSelectedSeat={setSelectedSeat}
                seats={seats}
              />
            </Grid>
            <Grid item xs={9}>
              {showMaxOccupancyWidget(floorMap) &&
                <MaxOccupancy
                  map={map}
                  mapImageProjection={mapImageProjection}
                  location={selectedLocation}
                  floorMap={floorMap}
                  scale={scale}
                  handleSetScale={handleSetScale}
                  prepareOutputMaxOccupancyData={prepareOutputMaxOccupancyData}
                  distance={distance}
                  setDistance={setDistance}
                  suggestedLocations={suggestedLocations}
                  setSuggestedLocations={setSuggestedLocations}
                  setLocationAdjacentLines={setLocationAdjacentLines}
                  maxOccupancy={maxOccupancy}
                  setMaxOccupancy={setMaxOccupancy}
                  saveMaxOccupancy={saveMaxOccupancy}
                  setSaveMaxOccupancy={setSaveMaxOccupancy}
                />}
              {alert ? <Alert severity='error'>{t("Locations.AlertFloorMapSizeLimits")}</Alert> : <></> }
            </Grid>
            <Grid item xs={3}>
              <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                <IconButton
                  disabled={isLoading}
                  onClick={forceRefreshLocation}
                  className={classes.resetButton}
                >
                  <RefreshIcon />
                  <Typography component="span" className={classes.resetLabel}>
                    {t("Commons.Refresh")}
                  </Typography>
                </IconButton>
                {/* {showDeleteAllLocations(floorMap) &&
                  <IconButton
                    disabled={isLoading}
                    onClick={confirmDeleteAllLocations}
                    className={classes.resetButton}
                  >
                    <DeleteIcon />
                    <Typography component="span" className={classes.resetLabel}>
                      {t("Commons.DeleteAll")}
                    </Typography>
                  </IconButton>
                } */}
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.spacer}/>
              <LocationMapLegend />
            </Grid>
          </Grid>
        </Card>
        {editLocation && (
          <EditLocation
            createMode={editLocation.createMode}
            location={editLocation.location}
            users={users}
            userGroups={userGroups}
            close={closeEditLocation}
            company={company}
            parentLocation={selectedLocation}
            reloadLocations={loadLocations}
          />
        )}
        {deleteAllDialog && isTrue(deleteAllDialog.open) && (
          <ConfirmDialog
            title={t("Locations.DeleteAllLocationsDialogTitle")}
            heading={t("Locations.DeleteAllLocationsDialogHeading")}
            message={t("Locations.DeleteAllLocationsDialogMessage")}
            onConfirm={() => handleDeleteAllLocations()}
            onCancel={() => setDeleteAllDialog({open: false})}
          />
        )}
      </React.Fragment>
    );
  } else {
    return <LinearProgress />;
  }
};
