import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import P from "prop-types";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import CompanyUtil from "../../utils/CompanyUtil";
import { useCompanyService } from "../../services/useCompanyService";
import Alert from "@material-ui/lab/Alert";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export const EditQuestionLocations = ({
  onClose,
  onSaveCallback,
  company,
  allLocations,
  checkInQuestion
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [t, i18n] = useTranslation();
  const companyService = useCompanyService();
  const localStorage = useLocalStorage();
  const [questionLocations, setQuestionLocations] = useState(() => {
    return allLocations.filter((l) =>
      checkInQuestion.locationIds.includes(l.locationId)
    );
  });
  const [errorMessage, setErrorMessage] = useState("");

  const onSave = async () => {
    setIsLoading(true);
    const locationIds = questionLocations.map((l) => l.locationId);
    const response = await companyService.updateCheckinQuestion({
      ...checkInQuestion,
      locationIds,
      companyId: localStorage.getCompanyID()
    });
    setIsLoading(false);
    if (!response.data.success) {
      const path = "Errors.Cloud." + response.data.message;
      const error = i18n.exists(path) ? t(path) : t("Errors.General");
      setErrorMessage(error);
    } else {
      onSaveCallback();
      onClose();
    }
  };

  return (
    <AddEditDialog
      createMode={false}
      title={t("Questions.Locations")}
      onSave={onSave}
      onCancel={onClose}
      loading={isLoading}
    >
      {errorMessage && (
        <Grid item>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Grid>
      )}
      <Grid item>
        <Autocomplete
          multiple
          options={allLocations}
          value={questionLocations}
          getOptionLabel={(option) =>
            CompanyUtil.getStringForCoords(company, option.coordinate)
          }
          onChange={(e, value) => setQuestionLocations(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Questions.TypeAssignLocation")}
              variant="outlined"
            />
          )}
        />
      </Grid>
    </AddEditDialog>
  );
};

EditQuestionLocations.propTypes = {
  onClose: P.func,
  onSaveCallback: P.func,
  allLocations: P.array,
  checkInQuestion: P.object
};

EditQuestionLocations.defaultProps = {
  onClose: () => {},
  onSaveCallback: () => {}
};