import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";
import { Grid, TextField } from "@material-ui/core";
import { useCompanyService } from "../../services/useCompanyService";
import { useUserService } from "../../services/useUserService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import matchSorter from "match-sorter";
import { isNullOrUndefined } from "../../common";

export const EditUserSeats = ({ user, locations, coordinates, close }) => {
  const userService = useUserService();
  const companyService = useCompanyService();
  const dispatch = useDispatch();

  const [t, i18n] = useTranslation();
  const [eligibleSeats, setEligibleSeats] = useState([]);
  const [selectedAssignedSeats, setSelectedAssignedSeats] = useState([]);
  const [selectedPreferredSeats, setSelectedPreferredSeats] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let _eligibleSeats = userService.getUserEligibleLocations(
      user,
      locations,
      coordinates
    );
    setEligibleSeats(_eligibleSeats);

    let _selectedAssignedSeats = getExistingUserSeats(user.assignedSeats);
    setSelectedAssignedSeats(_selectedAssignedSeats);

    let _selectedPreferredSeats = getExistingUserSeats(user.preferredSeats);
    setSelectedPreferredSeats(_selectedPreferredSeats);
  }, []);

  const getExistingUserSeats = (seatIds) => {
    let seats = [];
    if (seatIds) {
      seatIds.forEach((seatId) => {
        let seat = locations.find((location) => location.locationId === seatId);
        !isNullOrUndefined(seat) && seats.push(seat);
      });
    }
    return seats;
  };

  const filterOptions = (options, { inputValue }) => {

    let _filtered = new Set();

    for (let level of coordinates) {

      let locsAtLevel = options.filter((location) => !isNullOrUndefined(location.coordinate[level]));
      let key = "coordinate." + level + ".displayName";

      let _matchesAtLevel = matchSorter(locsAtLevel, inputValue, { keys: [key] });
      _matchesAtLevel.forEach((loc) => _filtered.add(loc));
    }

    return Array.from(_filtered);
  };

  const getSeatOptionName = (location) => {
    let _name = "";
    coordinates.forEach((level, index) => {

      if (!isNullOrUndefined(location.coordinate[level])) {
        _name = _name.concat(
          location.coordinate[level].displayName +
            (index !== coordinates.length - 1 && !isNullOrUndefined(location.coordinate[coordinates[index+1]]) ? " -> " : "")
        );
      }
    });
    return _name;
  };

  const save = async () => {
    setLoading(true);
    user.assignedSeats = selectedAssignedSeats.map((seat) => seat.locationId);
    user.preferredSeats = selectedPreferredSeats.map((seat) => seat.locationId);
    let response = await companyService.updateUser(user);
    let result = response.data;
    if (result.success) {
      dispatch(setSuccessMessage(t("Success.UpdateUser")));
    } else {
      const path = "Errors.Cloud."+result.message;
      const error = i18n.exists(path) ? t(path) : t("Errors.UpdateUser");
      dispatch(setErrorMessage(error));
    }
    setLoading(false);
    close(user);
  };

  return (
    <AddEditDialog
      createMode={false}
      title={t("Users.UserSeats")}
      userName={user.firstName + " " + user.lastName}
      onSave={save}
      onCancel={close}
      loading={loading}
    >
      {eligibleSeats && (
        <Grid item>
          <Autocomplete
            multiple
            id="selected_assignedSeats"
            defaultValue={selectedAssignedSeats}
            options={eligibleSeats}
            value={selectedAssignedSeats}
            getOptionLabel={(option) => getSeatOptionName(option)}
            onChange={(event, newValue) => {
              setSelectedAssignedSeats(newValue);
            }}
            filterOptions={filterOptions}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Users.AssignSeat")}
                margin="normal"
              />
            )}
          />
        </Grid>
      )}
      {eligibleSeats && (
        <Grid item>
          <Autocomplete
            multiple
            id="selected_preferredSeats"
            defaultValue={selectedPreferredSeats}
            options={eligibleSeats}
            value={selectedPreferredSeats}
            getOptionLabel={(option) => getSeatOptionName(option)}
            onChange={(event, newValue) => {
              setSelectedPreferredSeats(newValue);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Users.AddPreferredSeats")}
                margin="normal"
              />
            )}
          />
        </Grid>
      )}
    </AddEditDialog>
  );
};
