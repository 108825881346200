import React, { useEffect, useState } from "react";
import { useCompanyService } from "../../services/useCompanyService";
import { useUserService } from "../../services/useUserService";
import { isTrue, isEmpty } from "../../common";
import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useLocalStore } from "../../hooks/useLocalStore";
import { LocationMapSelect } from "./LocationMapSelect";
import { LocationMapList } from "./LocationMapList";
import matchSorter from "match-sorter";
import MapLeaflet from "./MapLeaflet";

const useStyles = makeStyles(() => ({
  rightRail: {
    paddingTop: "10px"
  },
  mapControls: {
    marginTop: "16px",
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gridColumnGap: "16px",
    "&>div:last-of-type": {
      display: "grid",
      justifySelf: "center",
      gridTemplateRows: "40px 1fr"
    }
  }
}));

export const LocationMapListSelector = ({ updateSeat }) => {
  const classes = useStyles();
  const companyService = useCompanyService();
  const userService = useUserService();
  const localStore = useLocalStore();

  const [init, setInit] = useState(false);
  const [locations, setLocations] = useState([]);
  const [eligibleLocations, setEligibleLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [seats, setSeats] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState({});
  const [floorMap, setFloorMap] = useState({});
  const [locationMaps, setLocationMaps] = useState({});
  const [autoSave, setAutoSave] = useState(false);

  const company = localStore.getCompany();
  const coordinates = company.coordinateSystem;
  const floorMapUrlPrefix = `Companies/${company.companyId}/`;

  useEffect(() => {
    // Defining loadData function inside the useEffect to avoid function dependency
    const loadData = async () => {
      const _locations = await companyService.getAllLocations();
      setLocations(_locations);
      setLocationMaps(company.locationMaps);
      setInit(true);
    };
    loadData();
  }, []);

  useEffect(() => {
    if (locations.length > 0 && coordinates.length > 0) {
      const _eligibleLocations = userService.getEligibleNoneBookableLocations(
        null, // pass null to don't check userGroups for locations
        locations,
        coordinates
      );
      setEligibleLocations(_eligibleLocations);
    }
  }, [locations, coordinates]);

  useEffect(() => {
    if (selectedLocation) {
      const _results = locations.filter(
        (location) =>
          location.locationParent === selectedLocation.locationId &&
          location.bookable
      );
      if (_results.length > 0) {
        const _sortedSeats = _results.sort((a, b) =>
          a.displayName > b.displayName ? 1 : -1
        );
        setSeats(_sortedSeats);
      } else {
        setSeats(_results);
      }

      const _floorMapInformation = loadFloorMapInformation(selectedLocation);
      if (_floorMapInformation.floorMapPath) {
        userService
          .getFloorMapUrl(floorMapUrlPrefix + _floorMapInformation.floorMapPath)
          .then((url) => {
            setFloorMap({ ..._floorMapInformation, url: url });
          });
      } else {
        setFloorMap({ floorMapLocation: selectedLocation, url: null });
      }
    }
  }, [selectedLocation]);

  const loadFloorMapInformation = (location) => {
    let _floorMapPath = locationMaps ? locationMaps[location.locationId] : null;
    let _floorMapLocation = location;
    let _floorMapInformation = {
      floorMapPath: _floorMapPath,
      floorMapLocation: _floorMapLocation
    };

    if (_floorMapPath == null) {
      const _parentLocationId = location.locationParent;
      if (_parentLocationId && _parentLocationId.length > 0) {
        const _parentLocation = locations.find(
          (currLocation) => currLocation.locationId === _parentLocationId
        );
        _floorMapInformation = loadFloorMapInformation(_parentLocation);
      } else
        _floorMapInformation = {
          floorMapPath: null,
          floorMapLocation: _floorMapLocation
        };
    }

    return _floorMapInformation;
  };

  const getLocationOptionName = (location) => {
    let _name = "";
    const locationType = location.locationType;
    for (let i = 0; i < coordinates.length; i++) {
      const _coordinateNode = coordinates[i];
      _name = _name.concat(location.coordinate[_coordinateNode].displayName);
      if (_coordinateNode === locationType) break;
      else _name = _name.concat(" -> ");
    }
    return _name;
  };

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, { keys: ["displayName"] });

  const handleLocationChange = (newLocation) => {
    setSelectedLocation(newLocation);
    if (!isEmpty(newLocation) && isTrue(newLocation.bookable)) {
      setSelectedSeat(newLocation);
    } else {
      setSelectedSeat({});
    }
  };

  function normalizeSeat(seat) {
    return seat.seat ? seat.seat : seat;
  }

  useEffect(() => {
    updateSeat(normalizeSeat(selectedSeat));
  }, [selectedSeat]);

  if (init) {
    return (
      <>
        {eligibleLocations && (
          <LocationMapSelect
            eligibleLocations={eligibleLocations}
            filterOptions={filterOptions}
            getLocationOptionName={getLocationOptionName}
            handleLocationChange={handleLocationChange}
          />
        )}

        <div className={classes.mapControls}>
          <div>
            {Object.keys(floorMap).length < 1 ? (
              ""
            ) : floorMap.url ? (
              <MapLeaflet
                stateKey={0}
                floorMap={floorMap}
                selectedSeat={selectedSeat}
                setSelectedSeat={setSelectedSeat}
                seats={seats}
                setAutoSave={setAutoSave}
                editMode={false}
              />
            ) : (
              ""
            )}
          </div>
          <div className={classes.rightRail}>
            <LocationMapList
              floorMap={floorMap}
              selectedSeat={selectedSeat}
              setSelectedSeat={setSelectedSeat}
              seats={seats}
            />
          </div>
        </div>
      </>
    );
  } else {
    return <LinearProgress />;
  }
};
