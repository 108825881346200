import React from "react";
import { Card, CardContent, Container, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

export const FeatureNotEnabled = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Card>
        <CardContent>
          <Typography variant="h3">{t("Features.DisabledHeader")}</Typography>
          <Typography>{t("Features.DisabledContent")}</Typography>
        </CardContent>
      </Card>
    </Container>
  );
};