import { useState } from "react";

export default function useMailingListEditorConfirmDialog(
  removeMailingList,
  onRemoveMailingListFromFirestore
) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [listToRemove, setListToRemove] = useState(null);
  const [userGroupSelected, setUserGroupSelected] = useState(false);

  const onClickRemove = (index, _userGroupSelected) => {
    setOpenConfirm(true);
    setListToRemove(index);
    setUserGroupSelected(_userGroupSelected);
  };

  const onConfirmRemove = () => {
    removeMailingList(listToRemove);
    if (userGroupSelected) onRemoveMailingListFromFirestore(listToRemove);
    setOpenConfirm(false);
  };

  const onCancelRemove = () => {
    setListToRemove(null);
    setUserGroupSelected(false);
    setOpenConfirm(false);
  };

  return { openConfirm, onClickRemove, onConfirmRemove, onCancelRemove };
}
