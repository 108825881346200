import React, { useState, useEffect } from "react";
import { Stage, Layer, Image, Circle } from "react-konva";
import useImage from "use-image";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  }
}));

const MapImage = ({ url, width, setHeight, height, setAspectRatio }) => {
  const [image] = useImage(url);
  useEffect(() => {
    if (image) {
      const aspactRatio = image.height / image.width;
      setHeight(width * aspactRatio);
      setAspectRatio(image.width / width);
    }
  }, [image, width, setHeight]);

  return <Image image={image} width={width} height={height} />;
};

export default function MapCanvas({
  width,
  floorMap,
  selectedSeat,
  setSelectedSeat,
  pointX,
  setPointX,
  pointY,
  setPointY,
  seats,
  aspectRatio,
  setAspectRatio
}) {
  const classes = useStyles();
  const [height, setHeight] = useState(0);
  const { t } = useTranslation();

  const handleMouseDown = (e) => {
    if (floorMap.url) {
      const pos = e.target.getStage().getPointerPosition();
      setPointX(pos.x);
      setPointY(pos.y);
    }
  };

  return (
    <div>
      {floorMap.floorMapLocation && (
        <div className={classes.container}>
          <Grid container item xs={12}>
            <Typography gutterBottom variant="h3">
              {`${t("Locations.FloorMap_")} ${
                floorMap.floorMapLocation.displayName
              } ${floorMap.url ? "" : t("Locations.NotFound")}`}
            </Typography>
          </Grid>
          {floorMap.url && (
            <Stage width={width} height={height} onMouseDown={handleMouseDown}>
              <Layer>
                <MapImage
                  url={floorMap.url}
                  width={width}
                  height={height}
                  setHeight={setHeight}
                  setAspectRatio={setAspectRatio}
                />
              </Layer>
              <Layer>
                <Circle
                  x={pointX}
                  y={pointY}
                  width={10}
                  height={10}
                  fill="red"
                  shadowBlur={5}
                />
              </Layer>
              {seats && floorMap.floorMapLocation && (
                <Layer>
                  {seats.map((item, index) => {
                    if (
                      item.coordinate[floorMap.floorMapLocation.locationType]
                        .point &&
                      item.coordinate[floorMap.floorMapLocation.locationType]
                        .point.length === 2
                    )
                      return (
                        <Circle
                          x={
                            item.coordinate[
                              floorMap.floorMapLocation.locationType
                            ].point[0] / aspectRatio
                          }
                          y={
                            item.coordinate[
                              floorMap.floorMapLocation.locationType
                            ].point[1] / aspectRatio
                          }
                          radius={10}
                          strokeWidth={2}
                          stroke={
                            selectedSeat.seat &&
                            item.locationId === selectedSeat.seat.locationId
                              ? "#FB8C00"
                              : "#4CAF50"
                          }
                          shadowBlur={5}
                          onClick={() =>
                            setSelectedSeat({
                              seat: item,
                              index: index,
                              editMode: false
                            })
                          }
                          key={item.locationId}
                        />
                      );
                  })}
                </Layer>
              )}
            </Stage>
          )}
        </div>
      )}
    </div>
  );
}
