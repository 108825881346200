import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useHiveApiService } from "../../services/useHiveApiService";
import { setErrorMessage, setSuccessMessage } from "../../actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import ComputeIcon from "@material-ui/icons/MyLocation";
import ClearIcon from "@material-ui/icons/ClearAll";
import SaveIcon from "@material-ui/icons/Save";
import ExportImageIcon from "@material-ui/icons/Image";
import {
  Button,
  TextField,
  Typography,
  CircularProgress
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: "primary",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  maximumOccupancyGrid: {
    paddingTop: "5px",
    marginBottom: "10px",
    backgroundColor: "#ddd"
  },
  maximumOccupancyItems: {
    margin: "6px"
  },
  maximumOccupancyExportImage: {
    width: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat"
  }
}));

export const MaxOccupancy = ({
  location,
  floorMap,
  scale,
  handleSetScale,
  prepareOutputMaxOccupancyData,
  distance,
  setDistance,
  map,
  mapImageProjection,
  suggestedLocations,
  setSuggestedLocations,
  setLocationAdjacentLines,
  maxOccupancy,
  setMaxOccupancy,
  saveMaxOccupancy,
  setSaveMaxOccupancy
}) => {
  const hiveApi = useHiveApiService();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState();
  const [saveMaxOccupancyData, setSaveMaxOccupancyData] = useState(null);
  // hive-admin-546 coverCounts is set to 1 seat and is NOT set by UI
  //   we keep this because the API call needs it as a parameter.
  // eslint-disable-next-line no-unused-vars
  const [coverCounts, setCoverCounts] = useState("1");

  const transformLocationSelected = (data) => {
    const _locationsSelected = [];
    data.locationSelected.map((locId) => {
      const newLocation = {
        locationId: locId,
        displayName: data.locationDisplayNames[locId]
      };
      return _locationsSelected.push(newLocation);
    });
    return _locationsSelected;
  };

  const transformLocationNeighbors = (data) => {
    const _locationAdjacentLines = [];
    for (var locId in data.locationNeighborIds) {
      let adjIdList = data.locationNeighborIds[locId];
      let point0 = data.locationPoints[locId];
      adjIdList.forEach((adjId) => {
        let point1 = data.locationPoints[adjId];
        return _locationAdjacentLines.push([point0, point1]);
      });
    }
    return _locationAdjacentLines;
  };

  const handleCompute = async () => {
    let _locationSelected = [];
    suggestedLocations.map((item) => {
      _locationSelected.push(item.locationId);
    });

    let _coverCounts = [];
    let _coverCountsList = coverCounts.split(",");
    _coverCountsList.map((item) => {
      _coverCounts.push(parseInt(item));
    });

    const requestBody = {
      data: {
        locationId: location.locationId,
        coordinateLevel: floorMap.floorMapLocation.locationType,
        coordinateLevelName: floorMap.floorMapLocation.displayName,
        scaleInMeterPerPixel: Number(scale),
        distanceInMeter: Number(distance),
        locationSelected: _locationSelected,
        coverCounts: _coverCounts,
        numSamples: 0
      }
    };

    setIsLoading(true);
    await hiveApi.computeMaxOccupancy(requestBody).then((response) => {
      if (response.success) {
        setSuggestedLocations(transformLocationSelected(response.data));
        setLocationAdjacentLines(transformLocationNeighbors(response.data));
        setMaxOccupancy(response.data.locationSelected.length);
        prepareSaveMaxOccupancyData(requestBody, response.data);
        setIsLoading(false);
      } else {
        dispatch(setErrorMessage(t("Errors.General")));
        setIsLoading(false);
      }
    });
  };

  const prepareSaveMaxOccupancyData = (originRequest, maxOccupancyData) => {
    const requestBody = {
      data: { ...originRequest.data, ...maxOccupancyData }
    };
    setSaveMaxOccupancyData(requestBody);
    setSaveMaxOccupancy(true);
  };

  const handleClear = () => {
    setSuggestedLocations([]);
    setMaxOccupancy(0);
    setSaveMaxOccupancy(false);
  };

  const handleSaveMaxOccupancy = async () => {
    setIsLoading(true);
    let outputMaxOccupancyData =
      prepareOutputMaxOccupancyData(saveMaxOccupancyData);

    await hiveApi.saveMaxOccupancy(outputMaxOccupancyData).then((response) => {
      if (response.success) {
        setIsLoading(false);
        setSaveMaxOccupancy(false);
        dispatch(setSuccessMessage(t("Success.General")));
      } else {
        dispatch(setErrorMessage(t("Errors.General")));
        setIsLoading(false);
      }
    });
  };

  const handleExportMaxOccupancy = async () => {
    setIsLoading(true);
    let outputMaxOccupancyData =
      prepareOutputMaxOccupancyData(saveMaxOccupancyData);

    let _plotMaxOccupancyData = outputMaxOccupancyData;

    // modify the plot options
    let _mapBounds = map.getBounds();
    let sw = mapImageProjection.inverse(_mapBounds._southWest);
    let ne = mapImageProjection.inverse(_mapBounds._northEast);
    let imageBoundingBox = [
      Math.round(sw.x),
      Math.round(ne.y),
      Math.round(ne.x),
      Math.round(sw.y)
    ];
    _plotMaxOccupancyData.data.imageBoundingBox = imageBoundingBox;
    _plotMaxOccupancyData.data.voronoiPolygons = false;
    _plotMaxOccupancyData.data.adjacentLines = false;
    _plotMaxOccupancyData.data.adjacentLines = false;
    _plotMaxOccupancyData.data.displayRadius = false;
    _plotMaxOccupancyData.data.displayLabels = false;

    await hiveApi.exportMaxOccupancy(_plotMaxOccupancyData).then((response) => {
      if (response.success) {
        setImageData(response.data.imageData);
        setIsLoading(false);
        dispatch(setSuccessMessage(t("Success.General")));
      } else {
        dispatch(setErrorMessage(t("Errors.General")));
        setIsLoading(false);
      }
    });
  };

  return (
    <Grid container className={classes.maximumOccupancyGrid}>
      <Grid item xs={2}>
        <TextField
          value={scale}
          onChange={(event) => handleSetScale(event.target.value)}
          variant="outlined"
          size="small"
          placeholder={t("Locations.ScalePlaceHolder")}
          label={t("Locations.Scale")}
          className={classes.maximumOccupancyItems}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          value={distance}
          onChange={(event) => setDistance(event.target.value)}
          variant="outlined"
          size="small"
          placeholder={t("Locations.DistancePlaceHolder")}
          label={t("Locations.Distance")}
          className={classes.maximumOccupancyItems}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          value={maxOccupancy}
          disabled
          variant="outlined"
          size="small"
          label={t("Locations.MaxOccupancy")}
          InputProps={{ readOnly: true }}
          className={classes.maximumOccupancyItems}
        />
      </Grid>
      <Grid item xs={7}>
        <Grid container justify={"flex-end"}>
          <Button
            onClick={handleExportMaxOccupancy}
            disabled={!saveMaxOccupancy || isLoading}
            variant="contained"
            color="primary"
            startIcon={<ExportImageIcon />}
            className={classes.maximumOccupancyItems}
          >
            {t("Locations.ExportImage")}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress}>
                {" "}
              </CircularProgress>
            )}
          </Button>
          <Button
            onClick={handleCompute}
            disabled={isLoading || !scale || !distance}
            variant="contained"
            color="primary"
            startIcon={<ComputeIcon />}
            className={classes.maximumOccupancyItems}
          >
            {t("Locations.Compute")}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress}>
                {" "}
              </CircularProgress>
            )}
          </Button>
          <Button
            onClick={handleClear}
            disabled={isLoading || suggestedLocations.length <= 0}
            variant="contained"
            color="primary"
            startIcon={<ClearIcon />}
            className={classes.maximumOccupancyItems}
          >
            {t("Locations.LocationClear")}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress}>
                {" "}
              </CircularProgress>
            )}
          </Button>
          <Button
            onClick={handleSaveMaxOccupancy}
            disabled={!saveMaxOccupancy || isLoading}
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            className={classes.maximumOccupancyItems}
          >
            {t("Locations.Save")}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress}>
                {" "}
              </CircularProgress>
            )}
          </Button>
        </Grid>
      </Grid>
      {imageData && (
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4">
            {t("Locations.ExportImageResult")}
          </Typography>
          <img
            className={classes.maximumOccupancyExportImage}
            src={`data:image/jpeg;base64,${imageData}`}
          />
        </Grid>
      )}
    </Grid>
  );
};
