import React, { useEffect, useState } from "react";
import { Grid, TextField, FormControlLabel, Checkbox, Collapse, Card, CardHeader, Avatar, Accordion, AccordionSummary, AccordionDetails, Typography, CardActions, Button, FormGroup, Container, CircularProgress  } from "@material-ui/core";
import { isEmpty } from "../../common";
import { useCompanyService } from "../../services/useCompanyService";
import { makeStyles } from "@material-ui/styles";
import { red, blue, yellow, grey } from '@material-ui/core/colors';
import { ErrorAlert } from "../common/ErrorAlert";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SimpleSelector from "../../components/SimpleSelector/SimpleSelector";
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker, TimePicker } from "@material-ui/pickers";
import * as Parser from "cron-parser";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import NotificationsIcon from '@material-ui/icons/Notifications';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { RECIPIENT_CONDITIONS } from "../../constants";
import { useDispatch } from "react-redux";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";
import i18next from "i18next";
import { findTimeZone, getUTCOffset } from "timezone-support";
import UserSelector from "../../components/Selectors/UserSelector";
import UserGroupSelector from "../../components/Selectors/UserGroupSelector";

const useStyles = makeStyles(() => ({
  card: {
    width: "400px",
    minHeight: "100px"
  },
  red: {
    backgroundColor: red[500]
  },
  blue: {
    backgroundColor: blue[500]
  },
  yellow: {
    backgroundColor: yellow[500]
  },
  formGroup: {
    paddingLeft: '12px'
  },
  scheduleHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: 0
  },
  flexColumnContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "1rem",
    width: "100%",
    padding: 0
  },
  flexRowContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    padding: 0
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export const EditNotification = ({ createMode, scheduledNotification, users, close }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyService = useCompanyService();
  const daysOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const daysOfTheWeekFr = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
  const [timeZone, setTimeZone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editScheduledNotification, setEditScheduledNotification] = useState(() => {
    if (!createMode) {
      const loadedNotification = {...scheduledNotification};
      return loadedNotification;
    } else {
      return {
        notification: {
          title: "",
          body: "",
          type: "INFO",
          severity: "LOW",
          webLink: {
            label: "",
            url: ""
          }
        },
        recipients: [],
        exceptRecipients: false,
        groups: [],
        recipientCondition: RECIPIENT_CONDITIONS.MANUAL_SELECT,
        sendPushNotification: false,
        sendEmailNotification: false,
        startDate: new Date(),
        endDate: new Date(),
        cronSchedule: "0 8 * * *",
        isSendNow: false
      };
    }
  });
  const [noEndDate, setNoEndDate] = useState(() => {
    return createMode || scheduledNotification.endDate == null;
  });
  const [scheduledTime, setScheduledTime] = useState(() => {
    if (!createMode && !scheduledNotification.isSendNow) {
      const interval = Parser.parseExpression(scheduledNotification.cronSchedule);
      const fields = JSON.parse(JSON.stringify(interval.fields));
      const date = new Date();
      date.setHours(fields.hour);
      date.setMinutes(fields.minute);
      return date;
    } else {
      const time = new Date();
      time.setHours(8);
      time.setMinutes(0);
      time.setSeconds(0);
      time.setMilliseconds(0);
      return time;
    }
  });
  const [scheduledDays, setScheduledDays] = useState(() => {
    if (!createMode && !scheduledNotification.isSendNow) {
      const interval = Parser.parseExpression(scheduledNotification.cronSchedule);
      const fields = JSON.parse(JSON.stringify(interval.fields));
      return fields.dayOfWeek;
    } else {
      return [];
    }
  });
  const [scheduledDates, setScheduledDates] = useState(() => {
    if (!createMode && !scheduledNotification.isSendNow) {
      const interval = Parser.parseExpression(scheduledNotification.cronSchedule);
      const fields = JSON.parse(JSON.stringify(interval.fields));
      return fields.dayOfMonth;
    } else {
      return [];
    }
  });
  const [dayChoosingType, setDayChoosingType] = useState(() => {
    if (!createMode && !scheduledNotification.isSendNow) {
      const interval = Parser.parseExpression(scheduledNotification.cronSchedule);
      const fields = JSON.parse(JSON.stringify(interval.fields));
      if (fields.dayOfWeek.length >= 8 && fields.dayOfMonth.length >= 31) {
        return "day";
      } else if (fields.dayOfWeek.length < 8) {
        return "week";
      } else {
        return "month";
      }
    } else {
      return "day";
    }
  });
  const [manualSelectChoosingType, setManualSelectChoosingType] = useState(() => {
    return !createMode
      && isEmpty(scheduledNotification.recipients)
      && !scheduledNotification.exceptRecipients ? "group" : "user";
  });
  const [isSendNow, setIsSendNow] = useState(scheduledNotification.isSendNow ?? false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    companyService.getCompany().then((companyData) => {
      const abbr = getTimeZoneAbbreviation(companyData.timeZone.region);
      setTimeZone(abbr);
    });
  }, [companyService]);

  const getTimeZoneAbbreviation = (region) => {
    const tz = findTimeZone(region);
    const now = new Date();
    const tzOffset = getUTCOffset(now, tz);
    return tzOffset.abbreviation;
  };
  
  const [selectedUsers, setSelectedUsers] = useState(() => scheduledNotification.recipients || []);
  const [selectedGroups, setSelectedGroups] = useState(() => scheduledNotification.groups || []);

  const save = async () => {
    const _errorMessages = runFormValidation();
    if (_errorMessages.length === 0) {

      if (noEndDate) {
        editScheduledNotification.endDate = null;
      }
      editScheduledNotification.groups = selectedGroups && manualSelectChoosingType === 'group' ? selectedGroups : [];
      editScheduledNotification.recipients = selectedUsers && manualSelectChoosingType === 'user' ? selectedUsers : [];
      if (manualSelectChoosingType === 'group') {
        editScheduledNotification.exceptRecipients = false;
      }
      editScheduledNotification.cronSchedule = isSendNow ? null : constructCronSchedule();
      editScheduledNotification.isSendNow = isSendNow;

      const savingScheduledNotification = {...editScheduledNotification};
      editScheduledNotification.startDate.setHours(0, 0, 0);
      savingScheduledNotification.startDate = editScheduledNotification.startDate.toISOString();
      if (!noEndDate) {
        editScheduledNotification.endDate.setHours(23, 59, 59);
        savingScheduledNotification.endDate = editScheduledNotification.endDate.toISOString();
      }

      try {
        const response = createMode ? await companyService.createScheduledNotification(savingScheduledNotification) : await companyService.updateScheduledNotification(savingScheduledNotification);
        if (response.data.success) {
          dispatch(setSuccessMessage(t("Success.CreateScheduledNotification")));
          if (createMode) {
            editScheduledNotification.scheduledNotificationId = response.data.data.scheduledNotificationId;
          }
          close(editScheduledNotification);
        } else {
          let details = i18next.exists("Errors.Cloud." + response.data.message) ? t("Errors.Cloud." + response.data.message) : "";
          dispatch(setErrorMessage(t("Errors.CreateScheduledNotification") + " " + details));
        }
      } catch (error) {
        dispatch(setErrorMessage(t("Errors.CreateScheduledNotification")));
        console.log(error);
      }
    } else {
      setErrorMessages(_errorMessages);
    }
  };

  const isManualSelect = () => {
    return (isEmpty(editScheduledNotification.recipientCondition) || editScheduledNotification.recipientCondition === RECIPIENT_CONDITIONS.MANUAL_SELECT);
  };

  const runFormValidation = () => {
    const _errorMessages = [];
    // eslint-disable-next-line no-useless-escape
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    if (!editScheduledNotification.notification.title) {
      _errorMessages.push(t("ScheduledNotifications.TitleRequired"));
    }
    if (!editScheduledNotification.notification.body) {
      _errorMessages.push(t("ScheduledNotifications.BodyRequired"));
    }
    if (!editScheduledNotification.notification.type) {
      _errorMessages.push(t("ScheduledNotifications.TypeRequired"));
    }
    if (!editScheduledNotification.notification.severity) {
      _errorMessages.push(t("ScheduledNotifications.SeverityRequired"));
    }
    if (!!editScheduledNotification.notification.webLink.label && !editScheduledNotification.notification.webLink.url) {
      _errorMessages.push(t("ScheduledNotifications.WebLinkUrlRequired"));
    }
    if (!isEmpty(editScheduledNotification.notification.webLink.url)
      && !editScheduledNotification.notification.webLink.url.match(urlRegex)) {
      _errorMessages.push(t("ScheduledNotifications.WebLinkUrlNotValidUrl"));
    }
    if (isManualSelect() && manualSelectChoosingType === 'user' && !editScheduledNotification.exceptRecipients && (!selectedUsers || selectedUsers.length === 0)) {
      _errorMessages.push(t("ScheduledNotifications.RecipientsRequired"));
    }
    if (isManualSelect() && manualSelectChoosingType === 'group' && (!selectedGroups || selectedGroups.length === 0)) {
      _errorMessages.push(t("ScheduledNotifications.RecipientGroupsRequired"));
    }
    if (editScheduledNotification.exceptRecipients && !isEmpty(selectedUsers) && selectedUsers.length > 10) {
      _errorMessages.push(t("ScheduledNotifications.MaxExcludedUsersExceeded"));
    }
    if (!editScheduledNotification.startDate && !isSendNow) {
      _errorMessages.push(t("ScheduledNotifications.StartDateRequired"));
    }
    if (!noEndDate && !editScheduledNotification.endDate && !isSendNow) {
      _errorMessages.push(t("ScheduledNotifications.EndDateRequired"));
    }
    if (!scheduledTime && !isSendNow) {
      _errorMessages.push(t("ScheduledNotifications.ScheduledTimeRequired"));
    }
    if (dayChoosingType === "week" && (!scheduledDays || scheduledDays.length === 0) && !isSendNow) {
      _errorMessages.push(t("ScheduledNotifications.AtLeastOneDayOfTheWeek"));
    }
    if (dayChoosingType === "month" && (!scheduledDates || scheduledDates.length === 0) && !isSendNow) {
      _errorMessages.push(t("ScheduledNotifications.AtLeastOneDayOfTheMonth"));
    }

    return _errorMessages;
  };

  const constructCronSchedule = () => {
    const interval = Parser.parseExpression(editScheduledNotification.cronSchedule ? editScheduledNotification.cronSchedule : "0 8 * * *");
    const fields = JSON.parse(JSON.stringify(interval.fields));
    fields.hour = [scheduledTime.getHours()];
    fields.minute = [scheduledTime.getMinutes()];
    if (dayChoosingType === "day") {
      fields.dayOfWeek = [0,1,2,3,4,5,6,7];
    } else if (dayChoosingType === "week") {
      fields.dayOfWeek = scheduledDays.filter(value => value !== 7);
      fields.dayOfMonth = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
    } else if (dayChoosingType === "month") {
      fields.dayOfWeek = [0,1,2,3,4,5,6,7];
      fields.dayOfMonth = scheduledDates;
    }
    const modifiedInterval = Parser.fieldsToExpression(fields);
    return modifiedInterval.stringify();
  };

  const onNotificationFieldChange = (field, event) => {
    let _editScheduledNotification = { ...editScheduledNotification };
    _editScheduledNotification.notification[field] = event.target.value;
    setEditScheduledNotification(_editScheduledNotification);
  };

  const onWebLinkFieldChange = (field, event) => {
    let _editScheduledNotification = { ...editScheduledNotification };
    _editScheduledNotification.notification.webLink[field] = event.target.value;
    setEditScheduledNotification(_editScheduledNotification);
  };

  const onDateFieldChange = (field, event) => {
    let _editScheduledNotification = { ...editScheduledNotification };
    _editScheduledNotification[field] = event;
    setEditScheduledNotification(_editScheduledNotification);
  };

  const onCheckboxFieldChange = (field, event) => {
    let _editScheduledNotification = { ...editScheduledNotification };
    _editScheduledNotification[field] = event.target.checked;
    setEditScheduledNotification(_editScheduledNotification);
  };

  const onSelectFieldChange = (filter, value) => {
    let _editScheduledNotification = { ...editScheduledNotification };
    _editScheduledNotification.notification[filter] = value.id;
    setEditScheduledNotification(_editScheduledNotification);
  };

  const colorForSeverity = () => {
    if (editScheduledNotification.notification.severity === "LOW") {
      return classes.blue;
    } else if (editScheduledNotification.notification.severity === "MEDIUM") {
      return classes.yellow;
    } else if (editScheduledNotification.notification.severity === "HIGH") {
      return classes.red;
    }
    return null;
  };

  const sendNow = async () => {
    const _errorMessages = runFormValidation();
    if (!isSendNow) {
      _errorMessages.push("Cannot send now when schedule is enabled!");
    }
    if (_errorMessages.length === 0) {
      const savingScheduledNotification = {...editScheduledNotification};
      savingScheduledNotification.endDate = null;
      savingScheduledNotification.groups = selectedGroups && manualSelectChoosingType === 'group' ? selectedGroups : [];
      savingScheduledNotification.recipients = selectedUsers && manualSelectChoosingType === 'user' ? selectedUsers : [];
      if (manualSelectChoosingType === 'group') {
        savingScheduledNotification.exceptRecipients = false;
      }
      savingScheduledNotification.cronSchedule = null;
      savingScheduledNotification.startDate = null;

      try {
        const response = await companyService.sendNotificationNow(savingScheduledNotification);
        if (response.data.success) {
          dispatch(setSuccessMessage("Notifications Sent"));
          close(editScheduledNotification);
        } else {
          let details = i18next.exists("Errors.Cloud." + response.data.message) ? t("Errors.Cloud." + response.data.message) : "";
          dispatch(setErrorMessage("Failed to send notifications." + " " + details));
        }
      } catch (error) {
        dispatch(setErrorMessage("Failed to send notifications."));
        console.log(error);
      }
    } else {
      setErrorMessages(_errorMessages);
    }
  };

  const sendNowButton = () => {
    return <Button
      color="primary"
      variant="contained"
      disabled={loading}
      onClick={async () => {
        setLoading(true);
        await sendNow();
        setLoading(false);
      }}
    >
      {t("ScheduledNotifications.CloseAndSendNow") + (createMode ? "" : t("ScheduledNotifications.CloseAndSendNowExt"))}
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>;
  };

  return (
    <AddEditDialog
      createMode={createMode}
      title={t("ScheduledNotifications.ScheduledNotification")}
      onSave={async () => {
        setLoading(true);
        if (isSendNow) {
          await sendNow();
        }
        await save();
        setLoading(false);
      }}
      onCancel={close}
      loading={loading}
      overrideSaveButtonLabel={isSendNow ? t("ScheduledNotifications.SaveAndSendNow") : null}
      additionalButtons={isSendNow && sendNowButton()}
    >
      {errorMessages.length > 0 && <Grid item>
        <ErrorAlert errorMessages={errorMessages} onCloseAlert={setErrorMessages}/>
      </Grid>}
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox 
              checked={editScheduledNotification.sendPushNotification}
              onChange={(event) => {
                onCheckboxFieldChange("sendPushNotification", event);
              }}
              color="primary"
            />}
          label={t("ScheduledNotifications.SendPushNotification")} 
        />
        <FormControlLabel
          control={
            <Checkbox 
              checked={editScheduledNotification.sendEmailNotification}
              onChange={(event) => {
                onCheckboxFieldChange("sendEmailNotification", event);
              }}
              color="primary"
            />}
          label={t("ScheduledNotifications.SendEmailNotification")}
        />
      </FormGroup>
      
      <Grid item>
        <Typography variant="h4">{"Content"}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label={t("ScheduledNotifications.Title")}
          value={editScheduledNotification.notification.title}
          fullWidth
          required
          onChange={(event) => {
            onNotificationFieldChange("title", event);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label={t("ScheduledNotifications.Body")}
          value={editScheduledNotification.notification.body}
          fullWidth
          required
          multiline
          rows={3}
          onChange={(event) => {
            onNotificationFieldChange("body", event);
          }}
        />
      </Grid>
      <Grid
        item
        container
        direction="row"
        spacing={3}
        xs={12}>
        <Grid item xs={5}>
          <SimpleSelector
            disable={false}
            value={{id: editScheduledNotification.notification.type}}
            onChangeFunc={onSelectFieldChange}
            options={[
              { id: "INFO", displayKey: t("ScheduledNotifications.Info") },
              { id: "ALERT", displayKey: t("ScheduledNotifications.Alert") },
              { id: "SCHEDULING", displayKey: t("ScheduledNotifications.Scheduling") }
            ]}
            label={t("ScheduledNotifications.Type")}
            fieldId="type"
          />
        </Grid>
        <Grid item xs={5}>
          <SimpleSelector
            disable={false}
            value={{id: editScheduledNotification.notification.severity}}
            onChangeFunc={onSelectFieldChange}
            options={[
              { id: "LOW", displayKey: t("ScheduledNotifications.Low") },
              { id: "MEDIUM", displayKey: t("ScheduledNotifications.Medium") },
              { id: "HIGH", displayKey: t("ScheduledNotifications.High") }
            ]}
            label={t("ScheduledNotifications.Severity")}
            fieldId="severity"
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        spacing={3}
        xs={12}>
        <Grid item xs={5}>
          <TextField
            variant="outlined"
            label={t("ScheduledNotifications.WebLinkLabel")}
            value={editScheduledNotification.notification.webLink.label}
            fullWidth
            onChange={(event) => {
              onWebLinkFieldChange("label", event);
            }}
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            variant="outlined"
            label={t("ScheduledNotifications.WebLinkUrl")}
            value={editScheduledNotification.notification.webLink.url}
            fullWidth
            onChange={(event) => {
              onWebLinkFieldChange("url", event);
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("ScheduledNotifications.PreviewNotification")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar className={colorForSeverity()}>
                    {editScheduledNotification.notification.type === "ALERT" && <NotificationsIcon/>}
                    {editScheduledNotification.notification.type === "INFO" && <InfoIcon/>}
                    {editScheduledNotification.notification.type === "SCHEDULING" && <CalendarTodayIcon/>}
                  </Avatar>
                }
                title={editScheduledNotification.notification.title}
                subheader={editScheduledNotification.notification.body}
              />
              {!isEmpty(editScheduledNotification.notification.webLink.url) && <CardActions>
                <Button color='primary'>
                  <OpenInNewIcon color='primary'/>{editScheduledNotification.notification.webLink.label}
                </Button>
              </CardActions>}
            </Card>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item>
        <Typography variant="h4">{"Recipients"}</Typography>
      </Grid>
      <Grid item>
        <ToggleButtonGroup
          value={isEmpty(editScheduledNotification.recipientCondition) ? RECIPIENT_CONDITIONS.MANUAL_SELECT : editScheduledNotification.recipientCondition}
          exclusive
          onChange={(event, value) => {
            onDateFieldChange('recipientCondition', value);
          }}>
          <ToggleButton value={RECIPIENT_CONDITIONS.MANUAL_SELECT}>{t("ScheduledNotifications.ManualSelect")}</ToggleButton>
          <ToggleButton value={RECIPIENT_CONDITIONS.ANY_STATE_TODAY}>{t("ScheduledNotifications.AnyStateToday")}</ToggleButton>
          <ToggleButton value={RECIPIENT_CONDITIONS.CHECKED_IN_TODAY}>{t("ScheduledNotifications.CheckedInToday")}</ToggleButton>
          <ToggleButton value={RECIPIENT_CONDITIONS.CONFIRMED_TODAY}>{t("ScheduledNotifications.ConfirmedToday")}</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        <Collapse in={editScheduledNotification.recipientCondition === RECIPIENT_CONDITIONS.ANY_STATE_TODAY}>
          <Typography>{t("ScheduledNotifications.AnyStateTodayVerbose")}</Typography>
        </Collapse>
        <Collapse in={editScheduledNotification.recipientCondition === RECIPIENT_CONDITIONS.CHECKED_IN_TODAY}>
          <Typography>{t("ScheduledNotifications.CheckedInTodayVerbose")}</Typography>
        </Collapse>
        <Collapse in={editScheduledNotification.recipientCondition === RECIPIENT_CONDITIONS.CONFIRMED_TODAY}>
          <Typography>{t("ScheduledNotifications.ConfirmedTodayVerbose")}</Typography>
        </Collapse>
        <Collapse in={editScheduledNotification.recipientCondition === RECIPIENT_CONDITIONS.MANUAL_SELECT || isEmpty(editScheduledNotification.recipientCondition)}>
          <Typography>{t("ScheduledNotifications.ManualSelectVerbose")}</Typography>
          <br></br>
          <div>
            <ToggleButtonGroup
              value={manualSelectChoosingType}
              exclusive
              onChange={(e, newValue) => {
                setManualSelectChoosingType(newValue);
              }}>
              <ToggleButton value="user">{t("ScheduledNotifications.ByUser")}</ToggleButton>
              <ToggleButton value="group">{t("ScheduledNotifications.ByUserGroup")}</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <Collapse in={manualSelectChoosingType === 'user'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={editScheduledNotification.exceptRecipients}
                  onChange={(event) => {
                    onCheckboxFieldChange("exceptRecipients", event);
                  }}
                  color="primary"
                />}
              label={t("ScheduledNotifications.AllUsersExcept")}
            />
            <UserSelector
              contentList={users}
              labelKey={editScheduledNotification.exceptRecipients ? t("ScheduledNotifications.ExcludedUsers") : t("ScheduledNotifications.IncludedUsers")}
              controller={{
                value: selectedUsers,
                setter: setSelectedUsers
              }}
            />
          </Collapse>
          <Collapse in={manualSelectChoosingType === 'group'}>
            <br></br>
            <UserGroupSelector 
              labelKey={t("Commons.IncludedUserGroups")}
              controller={{
                value: selectedGroups ? selectedGroups : [], 
                setter: setSelectedGroups
              }} 
            />
          </Collapse>
        </Collapse>
      </Grid>
      <Grid item>
        <Container className={classes.scheduleHeader}>
          <Typography variant="h4">{t("ScheduledNotifications.Schedule")}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isSendNow}
                onChange={(e)=>{
                  setIsSendNow(e.target.checked);
                }}
                color="primary"
              />}
            label={t("ScheduledNotifications.SendNow")}
          />
        </Container>
      </Grid>
      <Grid item>
        <Collapse in={!isSendNow}>
          <Container className={classes.flexColumnContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={noEndDate}
                  onChange={() => {
                    setNoEndDate(!noEndDate);
                  }}
                  color="primary"
                />}
              label={t("ScheduledNotifications.NoEndDate")}
            />
            <Container className={classes.flexRowContainer}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="MM/dd/yyyy"
                label={t("ScheduledNotifications.StartDate")}
                value={editScheduledNotification.startDate}
                onChange={(event) => {
                  onDateFieldChange("startDate", event);
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                disabled={noEndDate}
                inputVariant="outlined"
                format="MM/dd/yyyy"
                label={t("ScheduledNotifications.EndDate")}
                value={editScheduledNotification.endDate == null ? null : editScheduledNotification.endDate}
                onChange={(event) => {
                  onDateFieldChange("endDate", event);
                }}
              />
            </Container>
            <ToggleButtonGroup
              value={dayChoosingType}
              exclusive
              onChange={(e, newValue) => {
                setDayChoosingType(newValue);
              }}>
              <ToggleButton value="day">{t("ScheduledNotifications.Daily")}</ToggleButton>
              <ToggleButton value="week">{t("ScheduledNotifications.Weekly")}</ToggleButton>
              <ToggleButton value="month">{t("ScheduledNotifications.Monthly")}</ToggleButton>
            </ToggleButtonGroup>
            <TimePicker
              label={t("ScheduledNotifications.ScheduledTime") + " (" + timeZone + ")"}
              placeholder="8:00 AM"
              minutesStep={30}
              value={scheduledTime}
              onChange={(newValue) => {
                setScheduledTime(newValue);
              }}
            />
            <Collapse in={dayChoosingType === "week"}>
              {(i18n.language === 'fr' ? daysOfTheWeekFr : daysOfTheWeek).map((day, index) => (
                <FormControlLabel
                  key={day + "-" + index}
                  control={
                    <Checkbox
                      checked={scheduledDays.includes(index)}
                      onChange={(e)=>{
                        if (e.target.checked) {
                          setScheduledDays(scheduledDays.concat([index]).sort());
                        } else {
                          setScheduledDays(scheduledDays.filter(day => day !== index));
                        }
                      }}
                      color="primary"
                    />}
                  label={day}
                />
              ))}
            </Collapse>
            <Collapse in={dayChoosingType === "month"}>
              <Autocomplete
                multiple
                id="selected_dates"
                defaultValue={scheduledDates}
                options={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]}
                value={scheduledDates}
                getOptionLabel={(option) => option.toString()}
                onChange={(event, newValue) => {
                  setScheduledDates(newValue);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("ScheduledNotifications.DaysOfTheMonth")}
                    variant="outlined"
                    required
                  />
                )}
              />
            </Collapse>
          </Container>
        </Collapse>
      </Grid>
    </AddEditDialog>
  );
};