import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Chip,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CheckCircle, ClearOutlined } from "@material-ui/icons";
import DateTimeUtil from "../../utils/DateTimeUtil";
import useExportATtendeesTable from "./useExportAttendeesTable";
import { ExportReactCSV } from "../common/ExportReactCSV";
import AddAttendee from "./AddAttendee";

const useStyles = makeStyles((theme) => ({
  eventAttendees: {
    display: "flex",
    flexDirection: "column"
  },
  buttons: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem"
  },
  paper: {
    width: "100%",
    overflow: "hidden"
  },
  tableContainer: {
    maxHeight: 600,
    minHeight: 600
  },
  noAttendees: {
    margin: "0 auto",
    background: "Red"
  },
  INVITED: {
    backgroundColor: theme.palette.grey[300]
  },
  CONFIRMED: {
    backgroundColor: theme.palette.primary.main
  },
  DECLINED: {
    backgroundColor: theme.palette.error.light
  },
  externalGuest: {
    color: theme.palette.success.main
  },
  iconButton: {
    cursor: "pointer"
  }
}));

export default function EventDetailsAttendeesTab({
  eventTitle,
  usersList,
  attendees,
  canAddRemove,
  onAddAttendee,
  onRemoveAttendee
}) {
  const classes = useStyles();
  const [t] = useTranslation();
  const { exportCsvHeaders, convertToExportable } = useExportATtendeesTable();

  const [showAddAttendee, setShowAddAttendee] = useState(false);

  const TABLE_COLUMNS = [
    { id: 1, label: t("Commons.Name"), align: "left", restricted: false },
    { id: 2, label: t("Commons.Email"), align: "left", restricted: false },
    { id: 3, label: t("Commons.Status"), align: "left", restricted: false },
    {
      id: 4,
      label: t("Events.ExternalGuest"),
      align: "center",
      restricted: false
    },
    { id: 5, label: t("Commons.Actions"), align: "center", restricted: true }
  ];

  const exportCsvFilename = `${eventTitle} ${t(
    "Events.Attendies"
  )} (${DateTimeUtil.formatDateAndTime(new Date())}).csv`;

  const convertableData = convertToExportable(attendees);

  return (
    <div className={classes.eventAttendees}>
      {showAddAttendee && (
        <AddAttendee
          onClose={() => setShowAddAttendee(false)}
          selectedAttendees={attendees}
          usersList={usersList}
          onAddAttendee={onAddAttendee}
        />
      )}

      <div className={classes.buttons}>
        {canAddRemove && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowAddAttendee(true)}
          >
            {t("Events.AddAttendee")}
          </Button>
        )}

        <ExportReactCSV
          csvData={convertableData}
          fileName={exportCsvFilename}
          headers={exportCsvHeaders}
          disabled={0 === attendees.length}
        />
      </div>
      <Paper className={classes.paper}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {TABLE_COLUMNS.map((column) => {
                  if (!column.restricted || (column.restricted && canAddRemove))
                    return (
                      <TableCell
                        key={column.id}
                        variant="head"
                        align={column.align}
                      >
                        {column.label}
                      </TableCell>
                    );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {attendees.map((item, index) => (
                <TableRow hover key={item.bookingId}>
                  <TableCell>
                    <div>{`${item.firstName} ${item.lastName}`}</div>
                  </TableCell>
                  <TableCell>
                    <div>{item.email}</div>
                  </TableCell>
                  <TableCell>
                    <div>
                      <Chip
                        label={t(`Events.${item.status}`).toUpperCase()}
                        className={classes[item.status]}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      {!item.userId && (
                        <CheckCircle className={classes.externalGuest} />
                      )}
                    </div>
                  </TableCell>
                  {canAddRemove && (
                    <TableCell align="center">
                      <Tooltip
                        title={t("Events.RemoveAttendee")}
                        key={t("Events.RemoveAttendee")}
                      >
                        <div>
                          <ClearOutlined
                            className={classes.iconButton}
                            onClick={() => onRemoveAttendee(index)}
                          />
                        </div>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

EventDetailsAttendeesTab.propTypes = {
  eventTitle: PropTypes.string.isRequired,
  usersList: PropTypes.arrayOf(PropTypes.object).isRequired,
  attendees: PropTypes.arrayOf(PropTypes.object).isRequired,
  canAddRemove: PropTypes.bool.isRequired,
  onAddAttendee: PropTypes.func.isRequired,
  onRemoveAttendee: PropTypes.func.isRequired
};
