import { combineReducers } from "redux";
import messageReducer from "./messageReducer";
import userInfoReducer from "./userInfoReducer";
import companyInfoReducer from "./companyInfoReducer";

const rootReducer = combineReducers({
  message: messageReducer,
  currentUser: userInfoReducer,
  company: companyInfoReducer
});

export default rootReducer;
