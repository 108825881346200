import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  TextField,
  FormControlLabel,
  Grid,
  Typography,
  FormHelperText,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useLocalStore } from "../../hooks/useLocalStore";
import { ROLES, COMPANY_PREFERENCES } from "../../constants";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { isEmpty, tryGet } from "../../common";
import { MultiLanguageInputField } from "../../components/MultiLanguage/MultiLanguageInputField";
import { LanguageSelector } from "../../components/MultiLanguage/LanguageSelector";
import UserGroupSelector from "../../components/Selectors/UserGroupSelector";

const useStyles = makeStyles((theme) => ({
  formLabelWithTip: {
    marginRight: theme.spacing(0)
  },
  infoIcon: {
    fontSize: "inherit",
    verticalAlign: "text-bottom",
    display: "inline",
    marginLeft: theme.spacing(0.5)
  },
  textField: {
    marginTop: theme.spacing(2),
    width: theme.spacing(37)
  },
  languageSelector: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "2rem",
    paddingTop: "1rem"
  },
  accessLevelToggles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  helperText: {
    width: theme.spacing(37)
  }
}));

const Preferences = React.memo(
  ({ className, company, setCompanyField, ...rest }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const localStorage = useLocalStorage();
    const localStore = useLocalStore();
    const [language, setLanguage] = useState(i18n.language);
    const [preferencesUtil, setPrefencesUtil] = useState(null);

    const userRoles = localStorage.getUserRoles();

    useEffect(() => {
      setPrefencesUtil(localStore.getCompanyPreferencesUtil());
    }, []);

    const onUserGroupChange = (newValue) => {
      let newPrefs = { ...company.preferences };
      newPrefs[COMPANY_PREFERENCES.userGroupManagedPassword] = newValue;
      setCompanyField("preferences", newPrefs);
    };

    function companyHasAtLeastOneProvider() {
      const providers = tryGet(() => company.preferences.hiveSyncService.providers, {});
      return Object.keys(providers).length > 0;
    }

    const onDisplayNameChange = (e, language) => {
      onMultiLanguageFieldChange(e.target.value, language);
    };

    const onMultiLanguageFieldChange = (value, language) => {
      const newPrefs = { ...company.preferences };
      newPrefs.hiveAccessPolicy.message[language] = value;
      setCompanyField("preferences", newPrefs);
    };

    const getPref = (pref, fallbackValue = false) => {
      return tryGet(() => company.preferences[pref], fallbackValue);
    };

    const hasHiveAccessLevel = () => {
      return tryGet(() => company.preferences.hiveAccessPolicy.showAccessPolicyField, false);
    };

    const isCalendarSyncEnabled = () => {
      return tryGet(() => company.preferences.hiveSyncService.enableCalendarSync, false);
    };

    return (preferencesUtil &&
      <Card className={className} {...rest}>
        <Grid container item className={classes.languageSelector}>
          <CardHeader title={t("CompanyInfo.Preferences")} />
          {
            preferencesUtil.hasAccessLevel("denyBooking") && (
              <LanguageSelector
                controller={{ value: language, set: setLanguage }}
              />
            )
          }
        </Grid>
        <CardContent>
          <Grid container item direction="column">
            <div>
              <FormControlLabel
                className={classes.formLabelWithTip}
                control={
                  <Checkbox
                    checked={getPref(COMPANY_PREFERENCES.enableDropIn)}
                    onChange={(e) => {
                      const newPrefs = { ...company.preferences };
                      newPrefs[COMPANY_PREFERENCES.enableDropIn] = e.target.checked;
                      setCompanyField("preferences", newPrefs);
                    }}
                    color="primary"
                  />
                }
                label={t("CompanyInfo.EnableDropIn")}
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={getPref(COMPANY_PREFERENCES.bookingsEnabled)}
                  onChange={(e) => {
                    const newPrefs = { ...company.preferences };
                    newPrefs[COMPANY_PREFERENCES.bookingsEnabled] = e.target.checked;
                    setCompanyField("preferences", newPrefs);
                  }}
                  color="primary"
                />
              }
              label={t("CompanyInfo.BookingsEnabled")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getPref(COMPANY_PREFERENCES.enableCaseManagement)}
                  onChange={(e) => {
                    const newPrefs = { ...company.preferences };
                    newPrefs[COMPANY_PREFERENCES.enableCaseManagement] = e.target.checked;
                    setCompanyField("preferences", newPrefs);
                  }}
                  color="primary"
                />
              }
              label={t("CompanyInfo.CaseManagementEnabled")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getPref(COMPANY_PREFERENCES.assignedLocationExclusive)}
                  onChange={(e) => {
                    const newPrefs = { ...company.preferences };
                    newPrefs[COMPANY_PREFERENCES.assignedLocationExclusive] = e.target.checked;
                    setCompanyField("preferences", newPrefs);
                  }}
                  color="primary"
                />
              }
              label={t("CompanyInfo.ExclusiveSeat")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getPref(COMPANY_PREFERENCES.adjacentLocationBlocking)}
                  onChange={(e) => {
                    const newPrefs = { ...company.preferences };
                    newPrefs[COMPANY_PREFERENCES.adjacentLocationBlocking] = e.target.checked;
                    setCompanyField("preferences", newPrefs);
                  }}
                  color="primary"
                />
              }
              label={t("CompanyInfo.AdjacentLocationOccupied")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getPref(COMPANY_PREFERENCES.visitorsEnabled)}
                  onChange={(e) => {
                    const newPrefs = { ...company.preferences };
                    newPrefs[COMPANY_PREFERENCES.visitorsEnabled] = e.target.checked;
                    setCompanyField("preferences", newPrefs);
                  }}
                  color="primary"
                />
              }
              label={t("CompanyInfo.EnableVisitorBookings")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getPref(COMPANY_PREFERENCES.meetingsEnabled)}
                  onChange={(e) => {
                    const newPrefs = { ...company.preferences };
                    newPrefs[COMPANY_PREFERENCES.meetingsEnabled] = e.target.checked;
                    setCompanyField("preferences", newPrefs);
                  }}
                  color="primary"
                />
              }
              label={t("CompanyInfo.EnableMeetingRoomBookings")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getPref(COMPANY_PREFERENCES.enableCustomShifts)}
                  onChange={(e) => {
                    const newPrefs = { ...company.preferences };
                    newPrefs[COMPANY_PREFERENCES.enableCustomShifts] = e.target.checked;
                    setCompanyField("preferences", newPrefs);
                  }}
                  color="primary"
                />
              }
              label={t("CompanyInfo.EnableCustomShifts")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getPref(COMPANY_PREFERENCES.enableSocial)}
                  onChange={(e) => {
                    const newPrefs = { ...company.preferences };
                    newPrefs[COMPANY_PREFERENCES.enableSocial] = e.target.checked;
                    setCompanyField("preferences", newPrefs);
                  }}
                  color="primary"
                />
              }
              label={t("CompanyInfo.EnableSocial")}
            />
            <Grid container item direction="column">
              <br></br>
              <Typography color="textPrimary" gutterBottom>
                {t("CompanyInfo.OtherPreferences")}
              </Typography>
              <TextField
                className={classes.textField}
                variant="outlined"
                type="number"
                label={t("CompanyInfo.MaxAdvanceReservationDays")}
                value={getPref(COMPANY_PREFERENCES.maxAdvanceReservationDays, 0)}
                onChange={(e) => {
                  const newPrefs = { ...company.preferences };
                  newPrefs[COMPANY_PREFERENCES.maxAdvanceReservationDays] = parseInt(e.target.value);
                  setCompanyField("preferences", newPrefs);
                }}
              />

              <br></br>
              {
                hasHiveAccessLevel() && ( 
                  <Grid item>
                    <Typography color="textPrimary" gutterBottom>
                      {t("CompanyInfo.AccessLevelLabel")}
                    </Typography>
                    <div className={classes.accessLevelToggles}>
                      <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={company.preferences.hiveAccessPolicy.accessLevel}
                        onChange={(e, newAccessLevel) => {
                          if (!isEmpty(newAccessLevel)) {
                            const newPrefs = { ...company.preferences };
                            newPrefs.hiveAccessPolicy.accessLevel =
                              newAccessLevel ? newAccessLevel : null;
                            setCompanyField("preferences", newPrefs);
                          }
                        }}
                      >
                        <ToggleButton value="allowAll">
                          {t("CompanyInfo.AccessLevelAllowAll")}
                        </ToggleButton>
                        <ToggleButton value="denyBooking">
                          {t("CompanyInfo.AccessLevelDenyBooking")}
                        </ToggleButton>
                        <ToggleButton value="requireApproval">
                          {t("CompanyInfo.AccessLevelRequireApproval")}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                    <Tooltip title={t("CompanyInfo.SendAccessNotificationTooltip")} arrow>
                      <FormControlLabel 
                        label={t("CompanyInfo.SendAccessNotification")}
                        control={
                          <Checkbox
                            checked={company.preferences.hiveAccessPolicy.sendAccessNotification} 
                            onChange={(e) => {
                              const newPrefs = { ...company.preferences };
                              newPrefs.hiveAccessPolicy.sendAccessNotification = e.target.checked;
                              setCompanyField("preferences", newPrefs);
                            }}
                            color="primary"
                          />
                        }
                      />
                    </Tooltip>
                    {preferencesUtil.hasAccessLevel("denyBooking") && (
                      <MultiLanguageInputField
                        className={{ width: "100px" }}
                        label={t("CompanyInfo.AccessPolicyMessage")}
                        controller={{
                          value: company.preferences.hiveAccessPolicy.message,
                          set: onDisplayNameChange,
                          lang: language
                        }}
                      />
                    )}
                  </Grid>
                )
              }
            </Grid>
            {companyHasAtLeastOneProvider() && (
              <Grid container item direction="column">
                <br></br>
                <Typography color="textPrimary" gutterBottom>
                  {t("CompanyInfo.IntegrationPreferences")}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCalendarSyncEnabled()}
                      onChange={(e) => {
                        const newPrefs = { ...company.preferences };
                        if (!newPrefs.hiveSyncService) {
                          newPrefs.hiveSyncService = {};
                        }
                        newPrefs.hiveSyncService.enableCalendarSync =
                          e.target.checked;
                        setCompanyField("preferences", newPrefs);
                      }}
                      color="primary"
                    />
                  }
                  label={t("CompanyInfo.CalendarIntegrationEnabled")}
                />
                <FormHelperText>
                  {t("CompanyInfo.CalendarIntegrationHelperText")}
                </FormHelperText>
              </Grid>
            )}
            {userRoles.includes(ROLES.ROOT) && (
              <div>
                <Grid container item direction="column">
                  <br></br>
                  <Typography color="textPrimary" gutterBottom>
                    {t("CompanyInfo.RootPreferences")}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getPref(COMPANY_PREFERENCES.cleaningEnabled)}
                        onChange={(e) => {
                          const newPrefs = { ...company.preferences };
                          newPrefs[COMPANY_PREFERENCES.cleaningEnabled] = e.target.checked;
                          setCompanyField("preferences", newPrefs);
                        }}
                        color="primary"
                      />
                    }
                    label={t("CompanyInfo.CleaningEnabled")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getPref(COMPANY_PREFERENCES.allowDirtySeatBooking)}
                        onChange={(e) => {
                          const newPrefs = { ...company.preferences };
                          newPrefs[COMPANY_PREFERENCES.allowDirtySeatBooking] = e.target.checked;
                          setCompanyField("preferences", newPrefs);
                        }}
                        color="primary"
                      />
                    }
                    label={t("CompanyInfo.AllowDirtySeatBooking")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getPref(COMPANY_PREFERENCES.allowDirtySeatCheckIn)}
                        onChange={(e) => {
                          const newPrefs = { ...company.preferences };
                          newPrefs[COMPANY_PREFERENCES.allowDirtySeatCheckIn] = e.target.checked;
                          setCompanyField("preferences", newPrefs);
                        }}
                        color="primary"
                      />
                    }
                    label={t("CompanyInfo.AllowDirtySeatCheckIn")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getPref(COMPANY_PREFERENCES.storeHealthScreeningAnswers)}
                        onChange={(e) => {
                          const newPrefs = { ...company.preferences };
                          newPrefs[COMPANY_PREFERENCES.storeHealthScreeningAnswers] =
                            e.target.checked;
                          setCompanyField("preferences", newPrefs);
                        }}
                        color="primary"
                      />
                    }
                    label={t("CompanyInfo.StoreHealthScreeningAnswers")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getPref(COMPANY_PREFERENCES.showVaccinatedField)}
                        onChange={(e) => {
                          const newPrefs = { ...company.preferences };
                          newPrefs[COMPANY_PREFERENCES.showVaccinatedField] = e.target.checked;
                          setCompanyField("preferences", newPrefs);
                        }}
                        color="primary"
                      />
                    }
                    label={t("CompanyInfo.ShowVaccinatedField")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasHiveAccessLevel()}
                        onChange={(e) => {
                          const newPrefs = { ...company.preferences };
                          newPrefs.hiveAccessPolicy.showAccessPolicyField =
                            e.target.checked;
                          setCompanyField("preferences", newPrefs);
                        }}
                        color="primary"
                      />
                    }
                    label={t("CompanyInfo.ShowAccessPolicyField")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getPref(COMPANY_PREFERENCES.enableScheduledNotifications)}
                        onChange={(e) => {
                          const newPrefs = { ...company.preferences };
                          newPrefs[COMPANY_PREFERENCES.enableScheduledNotifications] =
                            e.target.checked;
                          setCompanyField("preferences", newPrefs);
                        }}
                        color="primary"
                      />
                    }
                    label={t("CompanyInfo.EnableScheduledNotifications")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getPref(COMPANY_PREFERENCES.enableBookingApproval)}
                        onChange={(e) => {
                          const newPrefs = { ...company.preferences };
                          newPrefs[COMPANY_PREFERENCES.enableBookingApproval] = e.target.checked;
                          setCompanyField("preferences", newPrefs);
                        }}
                        color="primary"
                      />
                    }
                    label={t("CompanyInfo.EnableBookingApproval")}
                  />
                </Grid>
                <Grid container item direction="column">
                  <br></br>
                  <Typography color="textPrimary" gutterBottom>
                    {t("CompanyInfo.OtherRootPreferences")}
                  </Typography>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    type="number"
                    label={t("CompanyInfo.LocationCleanBuffer")}
                    value={getPref(COMPANY_PREFERENCES.locationCleanBuffer, 0)}
                    onChange={(e) => {
                      const newPrefs = { ...company.preferences };
                      newPrefs[COMPANY_PREFERENCES.locationCleanBuffer] = parseInt(e.target.value);
                      setCompanyField("preferences", newPrefs);
                    }}
                  />
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    type="number"
                    label={t("CompanyInfo.AutoCheckoutBuffer")}
                    value={getPref(COMPANY_PREFERENCES.autoCheckoutBuffer, 0)}
                    onChange={(e) => {
                      const newPrefs = { ...company.preferences };
                      newPrefs[COMPANY_PREFERENCES.autoCheckoutBuffer] = parseInt(e.target.value);
                      setCompanyField("preferences", newPrefs);
                    }}
                  />
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    type="number"
                    label={t("CompanyInfo.AutoReleaseBuffer")}
                    value={getPref(COMPANY_PREFERENCES.autoReleaseBuffer, 0)}
                    onChange={(e) => {
                      const newPrefs = { ...company.preferences };
                      newPrefs[COMPANY_PREFERENCES.autoReleaseBuffer] = parseInt(e.target.value);
                      setCompanyField("preferences", newPrefs);
                    }}
                  />
                  <div className={classes.textField}>
                    <UserGroupSelector
                      labelKey={"CompanyInfo.UserGroupManagedPassword"}
                      controller={{
                        value: getPref(COMPANY_PREFERENCES.userGroupManagedPassword, []),
                        setter: onUserGroupChange
                      }}
                    />
                  </div>
                  <FormHelperText className={classes.helperText}>
                    {t("CompanyInfo.UserGroupManagePasswordsHelperText")}
                  </FormHelperText>
                </Grid>
              </div>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }
);

Preferences.propTypes = {
  className: PropTypes.string
};

export default Preferences;
