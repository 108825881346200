import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const MailingListEditorUserGroupSelect = ({
  lists,
  list,
  index,
  userGroups,
  onUserGroupChange,
  t
}) => (
  <FormControl fullWidth>
    <InputLabel id="userGroup-select-label" variant="outlined">
      {t("CompanyInfo.SelectUserGroup")}
    </InputLabel>
    <Select
      labelId="userGroup-select-label"
      value={list.userGroupId}
      label={t("CompanyInfo.SelectUserGroup")}
      onChange={(e) => onUserGroupChange(index, e.target.value)}
      disabled={list.userGroupId !== ""}
      variant="outlined"
    >
      {userGroups.map(({ userGroupId, name }) => (
        <MenuItem
          key={userGroupId}
          value={userGroupId}
          disabled={lists.map((ml) => ml.userGroupId).includes(userGroupId)}
        >
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default MailingListEditorUserGroupSelect;
