import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { isEmpty, isNullOrUndefined } from "../../common";
import isArray from "lodash/isArray";
import {
  Typography,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Checkbox,
  Chip
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  table: {
    width: "100%"
  },
  card: {
    maxHeight: "70vh"
  },
  inner: {
    height: "60vh"
  },
  recordsFoundContainer: {
    position: "relative"
  },
  recordsFoundText: {
    top: "50%",
    transform: "translate(0, -50%)"
  },
  iconButton: {
    padding: "9px",
    marginRight: "20px"
  },
  tableCell: {
    padding: "5px"
  },
  tableActionsCell: {
    padding: "5px",
    minWidth: "200px"
  },
  tableHeader: {
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingLeft: "5px"
  },
  tableActionHeader: {
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingLeft: "5px",
    minWidth: "200px"
  },
  selectColumnHeader: {
    paddingTop: "16px",
    paddingBottom: "16px",
    width: "27px"
  },
  selectColumn: {
    paddingLeft: "12px"
  },
  firstColumn: {
    paddingLeft: "20px"
  },
  plainChip: {
    margin: "2px"
  }
}));

export function cellWithChips(data, attribute) {
  const chipStyle = { margin: "2px" };
  let content = "";
  if (data && attribute) {
    content = Array.isArray(data[attribute]) ? (
      data[attribute].map((item, index) => (
        <Chip key={`key${item}${index}`} label={item} style={chipStyle} />
      ))
    ) : (
      <Chip
        key={`key${data[attribute]}`}
        label={data[attribute]}
        style={chipStyle}
      />
    );
  }

  return <TableCell>{content}</TableCell>;
}
const DataTable = ({
  tableStructure,
  tableContent,
  actions,
  renderActions,
  selectedRows,
  customSpacer = "50px",
  displayListSize = true,
  customCellStyle = {},
  fixHeight = false,
  emptyText = ""
}) => {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    selectedRows && setSelectedItems(selectedRows.value);
  }, [selectedRows && selectedRows.value]);

  const populateActionButtons = (data, index) => {
    const actionButtons = [];
    actions.map((action, i) =>
      actionButtons.push(
        <Tooltip title={t(action.toolTipKey)} key={`button${i}`}>
          <IconButton
            disabled={action.disabled && action.disabled(data)}
            onClick={() => action.func && action.func(data, index)}
            className={classes.iconButton}
          >
            {action.render ? action.render(data) : action.icon}
          </IconButton>
        </Tooltip>
      )
    );

    return actionButtons;
  };

  const onToggleCheck = (index) => {
    let pos = selectedItems.indexOf(index);
    if (pos < 0) {
      let newList = [...selectedItems];
      newList.push(index);
      selectedRows.setter(newList);
    } else {
      let newList = [...selectedItems];
      newList.splice(pos, 1);
      selectedRows.setter(newList);
    }
  };

  const createTableHeader = () => {
    const columns = [];
    selectedRows &&
      columns.push(
        <TableCell
          className={classes.selectColumnHeader}
          key={"checkBox1"}
        ></TableCell>
      );
    tableStructure.map((item, i) => {
      const shouldRender = item.shouldRender ? item.shouldRender() : true;
      shouldRender &&
        columns.push(
          <TableCell
            className={`${classes.tableHeader} ${
              !selectedRows && i === 0 && classes.firstColumn
            }`}
            key={`col${i}`}
          >
            {i18n.exists(item.columnNamekey) ? t(item.columnNamekey) : item.columnNamekey}
          </TableCell>
        );
    });
    if (!isEmpty(actions) || renderActions) {
      columns.push(
        <TableCell
          align="center"
          className={classes.tableActionHeader}
          key={"actions1"}
        >
          {t("Commons.Actions")}
        </TableCell>
      );
    }
    return (
      <TableHead>
        <TableRow>{columns}</TableRow>
      </TableHead>
    );
  };

  const getMultiLanguageContent = (field) => {
    return isEmpty(field[i18n.language]) ? field.en : field[i18n.language];
  };

  const getValue = (item, data) => {
    const {
      fieldName,
      customContentFormatter,
      fieldNameIsKey = false,
      multiLanguage = false
    } = item;
    const { [fieldName]: field } = data;

    if (fieldNameIsKey) return t(`DataTables.${field}`);
    if (multiLanguage) return getMultiLanguageContent(field);

    return !isNullOrUndefined(customContentFormatter)
      ? customContentFormatter(data)
      : field;
  };

  const isChecked = (index) => {
    return isAllSelected() || selectedItems.includes(index);
  };

  const isAllSelected = () => {
    return (
      selectedRows &&
      tableContent.length > 0 &&
      tableContent.length === selectedRows.value.length
    );
  };

  const createRow = (data, index) => {
    const cells = [];
    tableStructure.map((item, i) => {
      const shouldRender = item.shouldRender ? item.shouldRender() : true;
      if (shouldRender) {
        const cellValue = getValue(item, data);
        const { customCellRenderer } = item;
        const CellContent = ({ text }) => text;

        return cells.push(
          (customCellRenderer && customCellRenderer(data)) || (
            <TableCell
              className={`${classes.tableCell} ${
                !selectedRows && i === 0 && classes.firstColumn
              }`}
              key={`cell${i}`}
              style={customCellStyle}
            >
              {isArray(cellValue) ? (
                cellValue.map((item, index) => (
                  <CellContent key={index} text={item} />
                ))
              ) : (
                <CellContent text={cellValue} />
              )}
            </TableCell>
          )
        );
      }
    });
    !isEmpty(actions) &&
      cells.push(
        <TableCell
          align="center"
          className={classes.tableCell}
          key={"actionButtons"}
        >
          {populateActionButtons(data, index)}
        </TableCell>
      );
    renderActions &&
      cells.push(
        <TableCell
          align="center"
          className={classes.tableCell}
          key={"actionButtons"}
        >
          {renderActions(data, index)}
        </TableCell>
      );
    return (
      <TableRow hover key={`row${index}`}>
        {selectedRows && (
          <TableCell className={[classes.tableCell, classes.selectColumn]}>
            <Checkbox
              checked={isChecked(index)}
              color="primary"
              onChange={() => onToggleCheck(index)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </TableCell>
        )}
        {cells}
      </TableRow>
    );
  };

  const createEmptyRow = () => {
    return (
      <TableRow>
        <TableCell align={"center"} colSpan={8}>
          {i18n.exists(emptyText) ? t(emptyText) : emptyText}
        </TableCell>
      </TableRow>
    );
  };

  const populateTable = () => {
    const newSelectedRows = [];
    if (isAllSelected()) {
      tableContent.map((r, index) => newSelectedRows.push(index));
    }

    const rows = [];

    if (isEmpty(tableContent)) {
      rows.push(createEmptyRow());
    } else {
      tableContent.map((singleRowData, index) =>
        rows.push(createRow(singleRowData, index))
      );
    }

    return <TableBody>{rows}</TableBody>;
  };

  return (
    <>
      <div className={classes.table} style={{ marginBottom: customSpacer }}>
        {displayListSize && (
          <div className={classes.recordsFoundContainer}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
              className={classes.recordsFoundText}
            >
              {tableContent.length} {t("Commons.RecordsFound")}
            </Typography>
          </div>
        )}
        <Card className={classes.card}>
          <TableContainer className={fixHeight && classes.inner} style={{ maxHeight: "60vh"}}>
            <Table stickyHeader aria-label="sticky table">
              {createTableHeader()}
              {populateTable()}
            </Table>
          </TableContainer>
        </Card>
      </div>
    </>
  );
};

DataTable.propTypes = {
  tableStructure: PropTypes.array.isRequired,
  tableContent: PropTypes.array.isRequired,
  actions: PropTypes.array,
  selectedRows: PropTypes.func
};

export default DataTable;
