import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography } from "@material-ui/core";
import { useCompanyService } from "../../services/useCompanyService";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  wrapper: {
    textAlign: "center"
  },
  illustration: {
    width: "300px",
    marginBottom: theme.spacing(5)
  }
}));

export const Welcome = () => {
  const classes = useStyles();
  const companyService = useCompanyService();
  const [companyName, setCompanyName] = useState(null);
  const {t} = useTranslation();

  useEffect(() => {
    companyService.getCompany().then((company) => {
      setCompanyName(company.companyName);
    });
  }, [companyService]);

  return (
    <div className="content">
      <Container maxWidth="lg" className={classes.container}>
        {companyName ? (
          <div className={classes.wrapper}>
            <img
              src="/welcome.svg"
              alt={t("Commons.Welcome")}
              className={classes.illustration}
            />
            <Typography variant="h1">{t("Commons.WelcomeTo")} {companyName}</Typography>
          </div>
        ) : (
          ""
        )}
      </Container>
    </div>
  );
};
