import { colors } from "@material-ui/core";

const white = "#FFFFFF";

export default {
  primary: {
    contrastText: "#263238",
    // dark: colors.indigo[900],
    // main: colors.indigo[500],
    // light: colors.indigo[100]
    //teal
    main: "#f5ce49"
  },
  secondary: {
    contrastText: "#263238",
    // dark: colors.blue[900],
    // main: colors.blue.A700,
    // light: colors.blue.A400
    //orange
    main: "#f4f6f8"
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: "#263238",
    secondary: "#546e7a",
    label: "#0b6dd4",
    link: "#0b6dd4"
  },
  link: "#1264a3",
  icon: {
    green: '#4caf50',
    main: colors.blueGrey[600]
  },
  background: {
    default: "#f4f6f8",
    paper: white
  },
  divider: colors.grey[200]
};
