import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { setSuccessMessage } from "../../actions/index";
import { setErrorMessage } from "../../actions/messageActions";
import { Grid, TextField, Typography } from "@material-ui/core";
import { ErrorAlert } from "../common/ErrorAlert";
import { useHiveIamService } from "../../services/useHiveIamService";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import { isEmpty } from "../../common";

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  description: {
    marginBottom: theme.spacing(3)
  }
}));

export const SetUserPassword = ({ user, close }) => {
  const hiveIamService = useHiveIamService();
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles();

  const HAS_WHITE_SPACES_REGEX = /\s/g;

  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  const save = async () => {
    const _errorMessages = runFormValidadtion();
    if (_errorMessages.length === 0) {
      setLoading(true);
      try {
        const requestBody = {
          data: {
            email: user.email,
            password: password
          }
        };

        await hiveIamService.saveNewUserPassword(requestBody).then((response) => {
          response.success ? dispatch(setSuccessMessage(t("Success.UpdateUser"))) : dispatch(setErrorMessage(response.message));
        });
        setLoading(false);
        close(user);
      } catch (e) {
        if (e instanceof TypeError) {
          setErrorMessages([e.message]);
        } else {
          setErrorMessages([t("Errors.Credentials")]);
        }
        setLoading(false);
        return;
      }
    }
    setErrorMessages(_errorMessages);
  };

  const runFormValidadtion = () => {
    const _errorMessages = [];

    if (isEmpty(password)) {
      _errorMessages.push(t("SignIn.MissingPassword"));
    }

    if (password.length < 6) {
      _errorMessages.push(t("SignUp.MoreThanSixCharacters"));
    }

    if (HAS_WHITE_SPACES_REGEX.test(password)) {
      _errorMessages.push(t("SignUp.PasswordContainSpaces"));
    }

    return _errorMessages;
  };

  return (
    <AddEditDialog
      createMode={false}
      title="User Password"
      userName={user.firstName + " " + user.lastName}
      onSave={save}
      onCancel={close}
      loading={loading}
    >
      {errorMessages.length > 0 && (
        <ErrorAlert
          errorMessages={errorMessages}
          onCloseAlert={setErrorMessages}
          styles={classes.errorMsg}
        />
      )}
      <Grid item sm={12}>
        <Typography gutterBottom variant="subtitle2">
          {user.email}
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <TextField
          required
          fullWidth
          variant="outlined"
          label={t("Commons.Password")}
          type="password"
          value={password}
          helperText="* Minimum 6 characters and no spaces"
          autoComplete="new-password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
    </AddEditDialog>
  );
};
