import { findTimeZone, setTimeZone, convertTimeToDate, getZonedTime } from "timezone-support";

export const HH_MM_AMPM = "HH_MM_AMPM";
export default class DateTimeUtil {

  static now() {
    return this.dateOnly(new Date());
  }

  static dateOnly(date) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();

    return new Date(year, month, day);
  }

  static startOfDayInRegion(date, region) {
    const timezone = findTimeZone(region);

    const time = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    const regionTime = setTimeZone(time, timezone);
    const regionDate = convertTimeToDate(regionTime, timezone);

    return regionDate;
  }

  static formateDate(date) {
    const dateFormat = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", dateFormat);
  }

  static formatDateAndTime(date) {
    const dateFormat = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return date.toLocaleDateString("en-US", dateFormat);
  }

  static intMinToDisplayTime(intMin, includeAmPm = false){
    const MIN_PER_HOUR = 60;
    const MIN_PER_DAY = MIN_PER_HOUR * 24;
      
    if(intMin > (MIN_PER_DAY)){
      this.intMinToDisplayTime(intMin%MIN_PER_DAY, includeAmPm);
    }
      
    let mod = intMin % MIN_PER_HOUR;
    let h = (intMin - mod) / MIN_PER_HOUR;
      
    let period = "am";
    if(h >= 12 && includeAmPm){
      h = h > 12 ? h - 12 : 12;
      period = "pm";
    }
      
    let min;
    min = mod > 9 ? `${mod}` : `0${mod}`;
      
    let hour;
    hour = h > 9 ? `${h}` : `0${h}`;
        
    return includeAmPm ? `${hour}:${min} ${period}` : `${hour}:${min}`;
  }

  static getFormatedZonedTime(timeStamp, region, pattern) {
    const tz = findTimeZone(region);
    const zonedTime = getZonedTime(timeStamp.toDate(), tz);
    let result = "";
    switch (pattern) {
      case HH_MM_AMPM:
        result = this.formateZonedTimeIntoHoursMinutesAmPm(zonedTime);
        break;
      default:
        break;
    }
    return result;
  }

  static formateZonedTimeIntoHoursMinutesAmPm(zonedTime) {
    let h = zonedTime.hours;
    let m = zonedTime.minutes;
    let p = "am";

    if (h >= 12) {
      h = h > 12 ? h - 12 : 12;
      p = "pm";
    }

    if (m < 10) {
      m = `0${m}`;
    }

    return `${h}:${m} ${p}`;
  }
}
