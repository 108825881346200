import React, { useState } from "react";
import PropTypes from "prop-types";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setErrorMessage, setSuccessMessage } from "../../actions";
import { Grid, TextField } from "@material-ui/core";
import UserSelector from "../../components/Selectors/UserSelector";
import { ATTENDEE_STATES } from "../../constants";

export default function AddAttendee({ usersList, onClose, onAddAttendee }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [selectedUser, setSelectedUser] = useState();

  const [loading, setLoading] = useState(false);

  const transformUser = {
    fromFireStore: (user, list) => {
      return list.find((u) => u.userId === user.userId);
    },
    toFireStore: (user) => {
      return {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        userId: user.userId,
        status: ATTENDEE_STATES.INVITED
      };
    }
  };

  const addAttendee = async () => {
    try {
      setLoading(true);
      onAddAttendee(selectedUser);
      dispatch(setSuccessMessage(t("Success.AddAttendee")));
      setSelectedUser(null);
      onClose();
    } catch (error) {
      console.log("Error during adding attendee", error);
      dispatch(setErrorMessage(t("Errors.General")));
    } finally {
      setLoading(false);
    }
  };

  return (
    <AddEditDialog
      createMode={true}
      title={t("Events.Attendies")}
      onCancel={onClose}
      loading={loading}
      onSave={addAttendee}
      overrideSaveButtonLabel={t("Commons.Add")}
    >
      <Grid item xs={12}>
        <UserSelector
          contentList={usersList}
          labelKey={"Commons.User"}
          transform={transformUser}
          controller={{
            value: selectedUser,
            setter: setSelectedUser
          }}
        />
      </Grid>
      <Grid container spacing={3} style={{ padding: "12px" }}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            value={selectedUser?.firstName || ""}
            variant="outlined"
            label={t("Commons.FirstName")}
            onChange={(e) =>
              setSelectedUser({
                ...selectedUser,
                userId: null,
                firstName: e.target.value,
                status: ATTENDEE_STATES.INVITED
              })
            }
            disabled={selectedUser?.userId ? true : false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            value={selectedUser?.lastName || ""}
            variant="outlined"
            label={t("Commons.LastName")}
            onChange={(e) =>
              setSelectedUser({
                ...selectedUser,
                userId: null,
                lastName: e.target.value,
                status: ATTENDEE_STATES.INVITED
              })
            }
            disabled={selectedUser?.userId ? true : false}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          value={selectedUser?.email || ""}
          variant="outlined"
          label={t("Commons.Email")}
          onChange={(e) =>
            setSelectedUser({
              ...selectedUser,
              userId: null,
              email: e.target.value,
              status: ATTENDEE_STATES.INVITED
            })
          }
          disabled={selectedUser?.userId ? true : false}
        />
      </Grid>
    </AddEditDialog>
  );
}

AddAttendee.propTypes = {
  usersList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  onAddAttendee: PropTypes.func.isRequired
};
