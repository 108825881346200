import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  Card,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { blueGrey } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

const StyledRadio = withStyles({
  root: {
    color: blueGrey[400],
    "&$checked": {
      color: blueGrey[600]
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);

const QuestionText = ({ questionText }) => (
  <Typography variant="body1" gutterBottom>
    <pre
      style={{
        fontFamily: "inherit",
        wordBreak: "break-word",
        whiteSpace: "pre-line"
      }}
    >
      {questionText}
    </pre>
  </Typography>
);

const StyledCard = ({ children }) => (
  <Card
    style={{
      padding: "1rem",
      margin: "1rem"
    }}
  >
    {children}
  </Card>
);

function HealthScreeningForm({
  formId,
  questionnaire,
  questionSetId,
  handleSubmit,
  setSubmitted
}) {
  const { t, i18n } = useTranslation();

  const initialValues = questionnaire.reduce(
    (obj, question) => ({
      ...obj,
      [question.question[i18n.language]]:
        question.expectedAnswer === "CHECKED" ||
        question.expectedAnswer === "UNCHECKED"
          ? false
          : null
    }),
    {}
  );
  const [answers, setAnswers] = useState(initialValues);

  const checkboxOnChange = (event, questionText) =>
    setAnswers({
      ...answers,
      [questionText]: event.target.checked
    });

  const radioOnChange = (event, questionText) =>
    setAnswers({
      ...answers,
      [questionText]: event.target.value === "true"
    });

  const onSubmit = (event) => {
    event.preventDefault();
    const boolSheet = Object.values(answers);

    setSubmitted(true);
    handleSubmit(boolSheet, questionSetId);
  };

  return (
    <form onSubmit={onSubmit} id={formId}>
      {questionnaire.map((questionObject, index) => {
        const { question, checkboxText, expectedAnswer, required } =
          questionObject;
        const questionText = question[i18n.language];
        const questionLabel = `${questionText} ${required ? "(required)" : ""}`;

        switch (expectedAnswer) {
          case "CHECKED":
          case "UNCHECKED":
            return (
              <StyledCard key={index}>
                <FormControl>
                  <QuestionText questionText={questionLabel} />
                  <FormControlLabel
                    label={checkboxText ? checkboxText[i18n.language] : ""}
                    control={
                      <Checkbox
                        name={questionText}
                        checked={answers[questionText]}
                        onChange={(event) =>
                          checkboxOnChange(event, questionText)
                        }
                        required={required}
                      />
                    }
                  />
                </FormControl>
              </StyledCard>
            );
          case "YES":
          case "NO":
            return (
              <StyledCard key={index}>
                <QuestionText questionText={questionLabel} />
                <RadioGroup
                  value={answers[questionText]}
                  onChange={(event) => radioOnChange(event, questionText)}
                >
                  <FormControlLabel
                    value={true}
                    control={<StyledRadio required={required} />}
                    label={t("Commons.Yes")}
                  />
                  <FormControlLabel
                    value={false}
                    control={<StyledRadio required={required} />}
                    label={t("Commons.No")}
                  />
                </RadioGroup>
              </StyledCard>
            );
          default:
            return null;
        }
      })}
    </form>
  );
}

export default HealthScreeningForm;
