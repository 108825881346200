import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

// Material UI
import { makeStyles, Typography, ListItem } from "@material-ui/core";
import { FixedSizeList } from "react-window";
import ListItemText from "@material-ui/core/ListItemText";

// Project Components
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { useCompanyService } from "../../../services/useCompanyService";
import { isEmpty } from "../../../common";

const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: "bold",
    marginTop: theme.spacing(1)
  },
  list: {
    marginTop: theme.spacing(1)
  },
  typographyTop: {
    marginTop: "1rem"
  }
}));

export const DeleteProfile = ({ deleteProfile: profile, close }) => {
  const companyService = useCompanyService();
  const classes = useStyles();
  const [t, i18n] = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");
  const [assignedDevices, setAssignedDevices] = useState();

  useEffect(() => {
    loadAssignedDevices();
  }, []);

  const loadAssignedDevices = async () => {
    const _assignedDevices = await companyService.getDevicesForProfile(
      profile.profileId
    );
    setAssignedDevices(_assignedDevices);
  };

  const confirmDelete = async () => {
    // delete profile
    const result = await companyService.deleteProfile(profile.profileId);

    if (result.data.success) {
      close(profile);
    } else {
      const path = "Errors.Cloud." + result.data.message;
      const error = i18n.exists(path) ? t(path) : t("Errors.DeleteProfile");
      setErrorMessage(error);
    }
  };

  function renderRow({ index, style, data }) {
    return (
      <ListItem style={style} key={index}>
        <ListItemText>{data.devices[index].name}</ListItemText>
      </ListItem>
    );
  }

  return (
    <ConfirmDialog
      title={t("Gate.DeleteProfile")}
      heading={profile.displayName}
      message={t("Gate.ConfirmDeleteProfile")}
      onConfirm={confirmDelete}
      onCancel={close}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      {!isEmpty(assignedDevices) && (
        <>
          <Typography className={classes.description}>
            It is currently assigned to:
          </Typography>
          <FixedSizeList
            height={75}
            width={400}
            itemSize={25}
            className={classes.list}
            itemCount={assignedDevices.length}
            itemData={{ devices: assignedDevices }}
          >
            {renderRow}
          </FixedSizeList>
          <Typography className={classes.typographyTop}>
            {t("Gate.DevicesWillBeUpdated")}
          </Typography>
        </>
      )}
    </ConfirmDialog>
  );
};