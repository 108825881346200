import { useState, useEffect } from "react";
import { useLocalStore } from "../hooks/useLocalStore";
import { LeftNavMenu } from "./leftNavMenu";
import { isEmpty } from "../common";


export default function useLeftNavMenuState() {
  const localStore = useLocalStore();
  const [menuLinks, setMenuLinks] = useState(LeftNavMenu);

  const currentUser = localStore.getCurrentUser();
  const preferencesUtil = localStore.getCompanyPreferencesUtil();

  useEffect(() => {
    updateLinkMenuState();
  }, [window.location.pathname]);

  const checkCompanyPreferences = (key) => {
    let checked = true;
    // prettier-ignore
    switch (key) {
      case "NavBar.Notifications":
        checked = preferencesUtil.isScheduledNotificationsEnabled();
        break;
      case "NavBar.CaseManagement":
        checked = preferencesUtil.isCaseManagementEnabled();
        break;
      case "NavBar.Resources":
        checked = preferencesUtil.isResourceManagementEnabled();
        break;
      case "NavBar.Appointments":
        checked = preferencesUtil.isResourceManagementEnabled();
        break;
      default:
        break;
    }
    return checked;
  };

  const populateSubMenuRoles = (subMenu) => {
    const allowedRoles = [];
    Object.keys(subMenu).map(key => {
      allowedRoles.push(...subMenu[key].allowedRoles);
    });
    return [...new Set(allowedRoles)];
  };

  const hasActiveLinkOrChildOpen = (menu) => {
    const menuKeys = Object.keys(menu);
    let response = false;
    let i = 0;
    do {
      if(!isEmpty(menu[menuKeys[i]].subMenuLinks)) {
        return menu[menuKeys[i]].isSubMenuOpen === true || 
          hasActiveLinkOrChildOpen(menu[menuKeys[i]].subMenuLinks);
      }
      response = menu[menuKeys[i]].active === true || menu[menuKeys[i]].isSubMenuOpen === true;
      i++;
    } while (i < menuKeys.length && !response);
    return response;
  };

  const getAllFoldableMenus = (menuItems) => {
    const foldableMenus = [];
    const content = isRootMenu(menuItems) ? menuItems : menuItems.subMenuLinks;
    Object.keys(content).map(key => {
      if(!isEmpty(content[key].subMenuLinks)){
        foldableMenus.push(...getAllFoldableMenus(content[key]));
      }
    });
    !isRootMenu(menuItems) && foldableMenus.push(menuItems);
    return foldableMenus;
  };

  const isRootMenu = (menu) => {
    const menuKeys = Object.keys(menu);
    const rootMenuKeys = Object.keys(menuLinks);
    return JSON.stringify(menuKeys) === JSON.stringify(rootMenuKeys);
  };

  const updateLinkMenuState = (menuItems) => {
    const newMenuState = menuItems ? {...menuItems} : {...menuLinks};
    executeActionOnEachMenuItem(newMenuState, [highlightActiveMenu, defineSubMenuOpenState]);
    !menuItems && setMenuLinks(newMenuState);
  };

  const executeActionOnEachMenuItem = (newMenuState = {...menuLinks}, actions) => {
    actions.forEach(action => {
      Object.keys(newMenuState).map(key => action(newMenuState[key]));
    });
    return newMenuState;
  };

  const highlightActiveMenu = (menuItem) => {
    const currentPath = window.location.pathname;
    if(isEmpty(menuItem.link)){
      updateLinkMenuState(menuItem.subMenuLinks);
    } else {
      menuItem.active = currentPath.startsWith(menuItem.link) ? true : false;
    }
  };

  const defineSubMenuOpenState = (menuItem) => {
    if(!isEmpty(menuItem.subMenuLinks)){
      const subMenu = menuItem.subMenuLinks;
      const subMenuKeys = Object.keys(subMenu);
      let subMenuOpenState = false;

      let i = 0;
      do {
        if(!isEmpty(subMenu[subMenuKeys[i]].subMenuLinks)){
          defineSubMenuOpenState(subMenu[subMenuKeys[i]]);
        } else {
          subMenuOpenState = subMenu[subMenuKeys[i]].active;
        }
        i++;
      } while (i < subMenuKeys.length && !subMenuOpenState);
      
      menuItem.isSubMenuOpen = subMenuOpenState;
    }
  };

  const getAuthorizedMenuLinks = (menuItems = menuLinks) => {
    const menuKeys = Object.keys(menuItems);
    const filteredMenuLinks = [];
    menuKeys.forEach((key) => {
      if(!isEmpty(menuItems[key].subMenuLinks)) {
        menuItems[key].allowedRoles.push(...populateSubMenuRoles(menuItems[key].subMenuLinks));
      }
      const userHasValidRole = currentUser.roles.some((role) => menuItems[key].allowedRoles.includes(role));
      const companyHasFeature = checkCompanyPreferences(menuItems[key].textKey);
      if(userHasValidRole && companyHasFeature) {
        filteredMenuLinks.push({...menuItems[key]});
      }
    });
    return filteredMenuLinks;
  };

  const updateFoldableMenuState = (menuKey) => {
    const newMenuState = {...menuLinks};
    const allFoldableMenus = getAllFoldableMenus(newMenuState);
    const actualMenu = allFoldableMenus.find(menu => menu.key === menuKey);
    actualMenu.isSubMenuOpen = hasActiveLinkOrChildOpen(actualMenu.subMenuLinks) && true || !actualMenu.isSubMenuOpen;
    allFoldableMenus.map(menu => {
      if(menu.key != menuKey) {
        menu.isSubMenuOpen = hasActiveLinkOrChildOpen(menu.subMenuLinks) && true || false;
      }
    });
    setMenuLinks(newMenuState);
  };

  return { menuLinks, getAuthorizedMenuLinks, updateFoldableMenuState };
}