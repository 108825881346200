import { useState } from 'react';

export default function useRemoveTagConfirmDialog(tags, removeTag) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [tagToRemove, setTagToRemove] = useState(null);

  const onClickRemove = (index) => {
    setOpenConfirm(true);
    setTagToRemove(tags[index]);
  };

  const onConfirmRemove = () => {
    removeTag(
      tags.findIndex(
        (tag) =>
          tag.displayName === tagToRemove.displayName &&
          tag.color === tagToRemove.color
      )
    );
    setTagToRemove(null);
    setOpenConfirm(false);
  };

  const onCancelRemove = () => {
    setTagToRemove(null);
    setOpenConfirm(false);
  };

  return {
    onClickRemove,
    openConfirm,
    tagToRemove,
    onConfirmRemove,
    onCancelRemove
  };
}
