import React from "react";
import { colourDefaults } from "../CompanyInfo/VisualCustomization";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { isEmpty, toPascalCase } from "../../common";
import { STYLE } from "../../constants";

const BarChartDisplay = (props) => {
  const chartXLabelMargin = 60;
  const chartYLabelMargin = 80;
  const {
    data,
    dataKey,
    customXAxis,
    XAxisLabel,
    YAxisLabel,
    customTooltip,
    states,
    colors,
    width,
    height,
    translationContext,
    keyTemplate,
    t
  } = props;
  const chartColors = isEmpty(colors)
    ? [colourDefaults.primary, colourDefaults.secondary]
    : colors;

  // width and height must be specified or BarChart must be in a ResponsiveContainer in the return or component does not render
  return (
    <BarChart
      width={width}
      height={height}
      data={data}
      margin={{
        right: 10
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      {customXAxis ? (
        customXAxis
      ) : (
        <XAxis
          height={chartXLabelMargin}
          dataKey={dataKey}
          label={XAxisLabel}
        />
      )}
      <YAxis
        tickCount={4}
        allowDecimals={false}
        width={chartYLabelMargin}
        label={
          <text dy={"38%"} dx={"1%"} style={{ fontFamily: STYLE.fontFamily }}>
            {YAxisLabel}
          </text>
        }
        style={{ fontFamily: STYLE.fontFamily }}
      />
      <Tooltip
        content={customTooltip}
        wrapperStyle={{ fontFamily: STYLE.fontFamily }}
      />
      <Legend wrapperStyle={{ fontFamily: STYLE.fontFamily }} />
      {states.map((state, index) => {
        const dataKey = state[0] || state;
        return (
          <Bar
            dataKey={dataKey}
            stackId="stack1"
            fill={chartColors[index % chartColors.length]}
            name={t(translationContext + toPascalCase(dataKey))}
            key={keyTemplate + index}
          />
        );
      })}
    </BarChart>
  );
};

export default BarChartDisplay;