import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import differenceInDays from "date-fns/differenceInDays";
import { APPOINTMENT_STATES } from "../../constants";
import { grey } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { isNullOrUndefined } from "../../common";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import LocationSelector from "../../components/Selectors/LocationSelector";
import StatusSelector from "../../components/Selectors/StatusSelector";
import ResourceSelector from "../../components/Selectors/ResourceSelector";

const useStyles = makeStyles(() => ({
  box: {
    paddingBottom: "0px"
  },
  fields: {
    marginBottom: "30px"
  },
  searchButton: {
    height: "100%"
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const FilterSection = ({
  filterSet,
  searchFunc,
  loading,
  company,
  locationsList
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [appointmentLocation, setAppointmentLocation] = useState(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [startingAppointmentDate, setStartingAppointmentDate] = useState(
    new Date()
  );
  const [endingAppointmentDate, setEndingAppointmentDate] = useState(
    addDays(new Date(), 7)
  );

  useEffect(() => {
    if (!isNullOrUndefined(filterSet.values.resource)) {
      setSelectedResource(filterSet.values.resource);
    }
  }, [filterSet.values.resource]);

  useEffect(() => {
    filterSet.setter({
      location: appointmentLocation,
      resource: selectedResource,
      status: selectedStatus,
      startingAppointmentDate: startingAppointmentDate,
      endingAppointmentDate: endingAppointmentDate
    });
  }, [
    appointmentLocation,
    selectedResource,
    selectedStatus,
    startingAppointmentDate,
    endingAppointmentDate
  ]);

  function updateStartDate(date) {
    setStartingAppointmentDate(date);
    const difference = differenceInDays(endingAppointmentDate, date);
    if (difference > 62) {
      const baseDate = new Date(date.getTime());
      setEndingAppointmentDate(addDays(baseDate, 62));
    }
  }

  function updateEndDate(date) {
    setEndingAppointmentDate(date);
    const difference = differenceInDays(date, startingAppointmentDate);
    if (difference > 62) {
      const baseDate = new Date(date.getTime());
      setStartingAppointmentDate(subDays(baseDate, 62));
    }
  }

  return (
    <Grid container spacing={3} className={classes.fields}>
      <Grid item xs={3}>
        <KeyboardDatePicker
          fullWidth
          disableToolbar
          inputVariant="outlined"
          format="MM/dd/yyyy"
          label={t("Commons.StartDate")}
          name="startingBookingDate"
          maxDate={endingAppointmentDate}
          value={startingAppointmentDate}
          onChange={updateStartDate}
        />
      </Grid>
      <Grid item xs={3}>
        <KeyboardDatePicker
          fullWidth
          disableToolbar
          inputVariant="outlined"
          format="MM/dd/yyyy"
          label={t("Commons.EndDate")}
          name="endingBookingDate"
          minDate={startingAppointmentDate}
          value={endingAppointmentDate}
          onChange={updateEndDate}
        />
      </Grid>
      <Grid item xs={6}>
        <LocationSelector
          contentList={locationsList}
          disabled={selectedResource != null}
          company={company}
          controller={{
            value: appointmentLocation,
            setter: setAppointmentLocation
          }}
          labelKey={"Commons.Location"}
        />
      </Grid>
      <Grid item xs={3}>
        <ResourceSelector
          labelKey={"Resources.Resource"}
          disabled={appointmentLocation != null}
          controller={{
            value: selectedResource,
            setter: setSelectedResource
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <StatusSelector
          controller={{
            value: selectedStatus, 
            setter: setSelectedStatus
          }}
          statusObj={APPOINTMENT_STATES}
          includeAll={true}
          remove={["PENDING_APPROVAL", "DENIED_APPROVAL"]}
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.searchButton}
          disabled={loading}
          onClick={searchFunc}
        >
          {t("Appointments.ViewAppointments")}
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

FilterSection.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default FilterSection;
