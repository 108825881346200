import { ACTIONS_ROLES, PATHS } from "../constants";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BusinessIcon from "@material-ui/icons/Business";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import HiveGate from "@material-ui/icons/AccountBalance";
import PersonIcon from "@material-ui/icons/Person";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import GroupIcon from "@material-ui/icons/Group";
import BookingIcon from "@material-ui/icons/ImportContacts";
import ReportIcon from "@material-ui/icons/Equalizer";
import VisitorIcon from "@material-ui/icons/CardTravel";
import MigrationIcon from "@material-ui/icons/PermDataSetting";
import EventIcon from "@material-ui/icons/Event";
import CalendarToday from "@material-ui/icons/CalendarToday";
import AssignmentIcon from "@material-ui/icons/Assignment";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ComputerIcon from "@material-ui/icons/Computer";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";

/*
 * Every menu link must be unique.
 * Every foldable menu must have a subMenuLinks attribute.
 */
export const LeftNavMenu = Object.freeze({
  dashboard: {
    key: "dashboard",
    textKey: "NavBar.Dashboard",
    icon: DashboardIcon,
    link: PATHS.DASHBOARD,
    active: false,
    allowedRoles: ACTIONS_ROLES.ACCESS_DASHBOARD
  },
  users: {
    key: "users",
    textKey: "NavBar.Users",
    icon: PersonIcon,
    link: PATHS.USERS,
    active: false,
    allowedRoles: ACTIONS_ROLES.ACCESS_USERS
  },
  events: {
    key: "events",
    textKey: "NavBar.Events",
    icon: CalendarToday,
    link: PATHS.EVENTS,
    active: false,
    allowedRoles: ACTIONS_ROLES.ACCESS_EVENTS
  },
  reports: {
    key: "reports",
    textKey: "NavBar.Reports",
    icon: ReportIcon,
    link: PATHS.REPORTS,
    active: false,
    allowedRoles: ACTIONS_ROLES.ACCESS_REPORTS
  },
  bookings: {
    key: "bookings",
    textKey: "NavBar.Bookings",
    icon: BookingIcon,
    link: PATHS.BOOKING,
    active: false,
    allowedRoles: ACTIONS_ROLES.ACCESS_BOOKING
  },
  caseManagement: {
    key: "caseManagement",
    textKey: "NavBar.CaseManagement",
    icon: AssignmentLateIcon,
    link: PATHS.CASE_MANAGEMENT,
    active: false,
    allowedRoles: ACTIONS_ROLES.ACCESS_CASE_MANAGEMENT
  },
  visitors: {
    key: "visitors",
    textKey: "NavBar.Visitors",
    icon: VisitorIcon,
    link: PATHS.VISITORS,
    active: false,
    allowedRoles: ACTIONS_ROLES.ACCESS_VISITORS
  },
  configurations: {
    key: "configurations",
    textKey: "NavBar.Configurations",
    icon: ComputerIcon,
    allowedRoles: [],
    isSubMenuOpen: false,
    subMenuLinks: {
      companyInformation: {
        key: "companyInformation",
        textKey: "NavBar.CompanyInformation",
        icon: BusinessIcon,
        link: PATHS.COMPANY_INFO,
        active: false,
        allowedRoles: ACTIONS_ROLES.ACCESS_COMPANY_INFO
      },
      locations: {
        key: "locations",
        textKey: "NavBar.Locations",
        icon: EditLocationIcon,
        link: PATHS.LOCATIONS,
        active: false,
        allowedRoles: ACTIONS_ROLES.ACCESS_LOCATIONS
      },
      userGroups: {
        key: "userGroups",
        textKey: "NavBar.UserGroups",
        icon: GroupIcon,
        link: PATHS.USER_GROUPS,
        active: false,
        allowedRoles: ACTIONS_ROLES.ACCESS_USER_GROUPS
      },
      screeningQuestions: {
        key: "screeningQuestions",
        textKey: "NavBar.ScreeningQuestions",
        icon: AssignmentIcon,
        link: PATHS.QUESTIONS,
        active: false,
        allowedRoles: ACTIONS_ROLES.ACCESS_QUESTIONS
      },
      hiveGate: {
        key: "hiveGate",
        textKey: "NavBar.HIVEGate",
        icon: HiveGate,
        link: PATHS.GATE,
        active: false,
        allowedRoles: ACTIONS_ROLES.ACCESS_GATE
      },
      notifications: {
        key: "notifications",
        textKey: "NavBar.Notifications",
        icon: NotificationsIcon,
        link: PATHS.NOTIFICATIONS,
        active: false,
        allowedRoles: ACTIONS_ROLES.ACCESS_NOTIFICATIONS
      }
    }
  },
  hiveAppointment: {
    key: "hiveAppointment",
    textKey: "NavBar.HIVEAppointments",
    icon: CollectionsBookmarkIcon,
    allowedRoles: [],
    isSubMenuOpen: false,
    subMenuLinks: {
      resources: {
        key: "resources",
        textKey: "NavBar.Resources",
        icon: AssignmentInd,
        link: PATHS.RESOURCES,
        active: false,
        allowedRoles: ACTIONS_ROLES.ACCESS_RESOURCES
      },
      appointments: {
        key: "appointments",
        textKey: "NavBar.Appointments",
        icon: EventIcon,
        link: PATHS.APPOINTMENTS,
        active: false,
        allowedRoles: ACTIONS_ROLES.ACCESS_APPOINTMENTS
      }
    }
  },
  migration: {
    key: "migration",
    textKey: "NavBar.Migration",
    icon: MigrationIcon,
    link: PATHS.MIGRATION,
    active: false,
    allowedRoles: ACTIONS_ROLES.ACCESS_MIGRATION
  }
});
