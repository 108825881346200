import { isAfter } from "date-fns";
import { isEmpty } from "../../common";
import i18n from "../../i18n";

const eventRequiredFieldsAreEmpty = (event) => {
  return (
    isEmpty(event.displayName) ||
    isEmpty(event.locationId) ||
    isEmpty(event.date) ||
    isEmpty(event.startTime) ||
    isEmpty(event.endTime) ||
    isEmpty(event.reservationStartTime) ||
    isEmpty(event.reservationEndTime) ||
    isEmpty(event.owner) ||
    isEmpty(event.organizer) ||
    isEmpty(event.details)
  );
};

const startTimeIsAfterEndTime = (event) =>
  isAfter(new Date(event.startsAt), new Date(event.endsAt));

const reservationStartTimeIsAfterEndTime = (event) =>
  isAfter(
    new Date(event.reservationStartsAt),
    new Date(event.reservationEndsAt)
  );

const validateEvent = (event) => {
  const _errorMessages = [];

  if (eventRequiredFieldsAreEmpty(event)) {
    _errorMessages.push(i18n.t("Events.RequiredFields"));
  }

  if (startTimeIsAfterEndTime(event)) {
    _errorMessages.push(i18n.t("Events.EventStartTimeError"));
  }

  if (reservationStartTimeIsAfterEndTime(event)) {
    _errorMessages.push(i18n.t("Events.EventReservationStartTimeError"));
  }

  return _errorMessages;
};

export default validateEvent;
