import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  TextField,
  Typography,
  Button,
  Card,
  Link,
  Tooltip
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useAuthService } from "../../services/useAuthService";
import { Formik } from "formik";
import { useCompanyService } from "../../services/useCompanyService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  signUpFormWrapper: {
    maxWidth: "900px",
    width: "100%",
    margin: "0 auto",
    textAlign: "center"
  },
  signUpFormCard: {
    marginBottom: theme.spacing(5)
  },
  registeringFormCard: {
    width: "900px"
  },
  cardContentWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  cardImageWrapper: {
    width: "45%",
    backgroundImage: "url('./signin.jpg')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  cardFormWrapper: {
    width: "55%",
    padding: theme.spacing(5)
  },
  title: {
    textAlign: "center"
  },
  errorMessage: {
    minHeight: "30px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  textField: {
    marginBottom: theme.spacing(3)
  },
  signInLink: {
    color: "#1264a3"
  },
  verificationEmailWrapper: {
    margin: "auto",
    height: "100%",
    maxWidth: "900px",
    textAlign: "center",
    display: "flex",
    alignItems: "center"
  },
  confirmButton: {
    marginTop: theme.spacing(1.5)
  }
}));

export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();
  const [emailSent, setEmailSent] = useState(false);
  const [creatingUpEmail, setCreatingEmail] = useState(false);
  const authService = useAuthService();
  const companyService = useCompanyService();
  const [tooltipIndex, setTooltipIndex] = useState(-1);
  const [errorMessage, setErrorMessage] = useState("");
  const timer = useRef();
  const minShowTime = 2000;
  const { t } = useTranslation();

  const createAccount = async (email, password, companyId, companyName) => {
    try {
      timer.current = new Date().getTime();
      setCreatingEmail(true);
      await authService.createUser(email, password, companyId, companyName);
      const currentTime = new Date().getTime();
      if (timer.current < currentTime + minShowTime) {
        const waitTime = minShowTime - (currentTime - timer.current);
        setTimeout(() => setEmailSent(true), waitTime);
      } else {
        setEmailSent(true);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const finish = () => {
    authService.logout().then(() => history.push("/signin"));
  };

  const validate = async (values) => {
    const errors = {};
    // Validate Email
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = t("SignUp.InvalidEmail");
    }
    // Validate Password
    if (!values.password) {
      errors.password = t("SignUp.Required");
    } else if (values.password.length < 6) {
      errors.password = t("SignUp.MoreThanSixCharacters");
    }
    // Validate Company Name
    if (!values.companyName) {
      errors.companyName = t("SignUp.Required");
    }

    // If user enters a same company in the company list, throw out an error
    if (!values.companyId) {
      errors.companyId = t("SignUp.Required");
    } else if (!/^[a-z0-9]*$/.test(values.companyId)) {
      errors.companyId = t("SignUp.CompanyIdCanotBeChanged");
    } else {
      const companyExists = await companyService.companyExists(
        values.companyId
      );
      if (companyExists) {
        errors.companyId = t("SignUp.CompanyAlreadyExists");
      }
    }

    return errors;
  };

  return (
    <div className="content">
      <Container maxWidth="lg" className={classes.container}>
        {!emailSent ? (
          !creatingUpEmail ? (
            <div className={classes.signUpFormWrapper}>
              <Card className={classes.signUpFormCard}>
                <div className={classes.cardContentWrapper}>
                  <div className={classes.cardImageWrapper}></div>
                  <div className={classes.cardFormWrapper}>
                    <img
                      alt={t("SignUp.SignUpLink")}
                      src="./logo.jpg"
                      style={{ width: "300px", marginBottom: "24px" }}
                    />
                    <Typography variant="h2" className={classes.title}>
                      {t("SignUp.SignUp")}
                    </Typography>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                        companyName: "",
                        companyId: ""
                      }}
                      validate={validate}
                      validateOnChange={false}
                      validateOnBlur={false}
                      onSubmit={(values) => {
                        createAccount(
                          values.email,
                          values.password,
                          values.companyId,
                          values.companyName
                        );
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit
                      }) => (
                        <>
                          <form
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                          >
                            <div className={classes.errorMessage}>
                              {errorMessage !== "" && (
                                <Alert
                                  severity="error"
                                  onClose={() => {
                                    setErrorMessage("");
                                  }}
                                >
                                  {errorMessage}
                                </Alert>
                              )}
                            </div>
                            <Tooltip
                              title={t("SignUp.EmailVerification")}
                              open={tooltipIndex === 0}
                              placement="right"
                              arrow
                            >
                              <TextField
                                className={classes.textField}
                                error={
                                  errors.email && touched.email ? true : false
                                }
                                required
                                fullWidth
                                variant="outlined"
                                label={t("Commons.Email")}
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setTooltipIndex(-1);
                                }}
                                onFocus={() => {
                                  setTooltipIndex(0);
                                }}
                                helperText={touched.email && errors.email}
                              />
                            </Tooltip>
                            <Tooltip
                              title={t("SignUp.EmailVerification")}
                              open={tooltipIndex === 1}
                              placement="right"
                              arrow
                            >
                              <TextField
                                className={classes.textField}
                                error={
                                  errors.password && touched.password
                                    ? true
                                    : false
                                }
                                required
                                fullWidth
                                variant="outlined"
                                label={t("Commons.Password")}
                                name="password"
                                type="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setTooltipIndex(-1);
                                }}
                                onFocus={() => {
                                  setTooltipIndex(1);
                                }}
                                helperText={touched.password && errors.password}
                              />
                            </Tooltip>
                            <Tooltip
                              title={t("SignUp.CompanyNameDisplayOnly")}
                              open={tooltipIndex === 2}
                              placement="right"
                              arrow
                            >
                              <TextField
                                className={classes.textField}
                                error={
                                  errors.companyName && touched.companyName
                                    ? true
                                    : false
                                }
                                required
                                fullWidth
                                variant="outlined"
                                label={t("SignUp.CompanyName")}
                                name="companyName"
                                value={values.companyName}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setTooltipIndex(-1);
                                }}
                                onFocus={() => {
                                  setTooltipIndex(2);
                                }}
                                helperText={
                                  touched.companyName && errors.companyName
                                }
                              />
                            </Tooltip>
                            <Tooltip
                              title={t("SignUp.CompanyIdCanotBeChanged")}
                              open={tooltipIndex === 3}
                              placement="right"
                              arrow
                            >
                              <TextField
                                className={classes.textField}
                                error={
                                  errors.companyId && touched.companyId
                                    ? true
                                    : false
                                }
                                required
                                fullWidth
                                variant="outlined"
                                label={t("SignUp.CompanyID")}
                                name="companyId"
                                value={values.companyId}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setTooltipIndex(-1);
                                }}
                                onFocus={() => {
                                  setTooltipIndex(3);
                                }}
                                helperText={
                                  touched.companyId && errors.companyId
                                }
                              />
                            </Tooltip>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              {t("SignUp.CreateAccount")}
                            </Button>
                          </form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </Card>
              <Typography variant="body1" component="span">
                {t("SignUp.AlreadyHaveAccount")}
                <Link
                  component={RouterLink}
                  to="/signin"
                  className={classes.signInLink}
                >
                  {t("SignUp.SignInHere")}
                </Link>
              </Typography>
            </div>
          ) : (
            <div className={classes.verificationEmailWrapper}>
              <Card
                className={
                  classes.signUpFormCard + " " + classes.registeringFormCard
                }
              >
                <div className={classes.cardContentWrapper}>
                  <div className={classes.cardImageWrapper}></div>
                  <div className={classes.cardFormWrapper}>
                    <Typography variant="h4" className={classes.title}>
                      {t("SignUp.RegisteringCompany")}
                      <br />
                      {t("SignUp.PleaseBePatient")}
                    </Typography>
                  </div>
                </div>
              </Card>
            </div>
          )
        ) : (
          <div className={classes.verificationEmailWrapper}>
            <Card className={classes.signUpFormCard}>
              <div className={classes.cardContentWrapper}>
                <div className={classes.cardImageWrapper}></div>
                <div className={classes.cardFormWrapper}>
                  <Typography variant="h4" className={classes.title}>
                    {t("SignUp.VerifyYourEmail")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={finish}
                    className={classes.confirmButton}
                  >
                    {t("SignUp.Finish")}
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        )}
      </Container>
    </div>
  );
}
