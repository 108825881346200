import i18n from "./i18n";
// import _isEmpty from "lodash/isEmpty"
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";

export const isEmpty = (value) => {
  return isNullOrUndefined(value) || value.length === 0;
};

export const isNullOrUndefined = (value) => {
  return isNull(value) || isUndefined(value);
};

export const isTrue = (value) => {
  return value === true;
};

export const isFalse = (value) => {
  return value === false;
};

export const stringToBoolean = (value) => {
  return value === "true";
};

export const isURLFormat = (value) => {
  const result = value.match(
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return result !== null;
};

// use function literal to keep the flexibility of 'this'
export function debounce(fn, delay) {
  return (args) => {
    clearTimeout(fn.id);
    fn.id = setTimeout(() => {
      fn.call(this, args);
    }, delay);
  };
}

export function allMatching(mainObjectArray, comparisonArray, innerAttribute){
  const response = [];
  mainObjectArray.map(a => comparisonArray.map(b => {
    if(a[innerAttribute].includes(b)){
      response.push(a);
    }
    return false;
  }));
  return response;

}

export const toPascalCase = (str) => {
  let substrings = str.split('_');
  substrings = substrings.map((substring) => (
    substring[0].toUpperCase() + substring.slice(1).toLowerCase()
  ));
  const pascalCased = substrings.join('');

  return pascalCased;
};

export const getMultiLanguageContent = (field, lang = i18n.language) => {
  return isEmpty(field[lang]) ? field.en : field[lang];
};

export const getAvailableLanguages = () => {
  return Object.entries(i18n.options.menu).map(([code, language]) => {
    return {label: language, value: code};
  });
};

export const hasFrenchContent = (multiLanguageField) => {
  return multiLanguageField.fr && multiLanguageField.fr.length > 0;
};

export const isValidEmail = (email) => {
  const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
  return re.test(String(email).toLowerCase());
};

export const tryGet = (func, fallbackValue) => {
  try {
    const value = func();
    return (value === null || value === undefined) ? fallbackValue : value;
  } catch (error) {
    return fallbackValue;
  }
};

export const tryCompare = (func, value) => {
  const possibleValue = tryGet(func, "");
  return possibleValue === value;
};

export const delay = async (ms) => {return new Promise(resolve => setTimeout(resolve, ms));};