import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import DateTimeUtil from "../../utils/DateTimeUtil";
import { WEEK_DAYS } from "../../constants";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import {
  Box,
  Slider,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  box: {
    marginTop: "15px"
  },
  daysGroup: {
    marginTop: "15px"
  }
}));

const DayHourRangePicker = ({ titleKey, data, timeBaseInMinutes, setFunc }) => {
  const MAX_STEPS = 1440 / timeBaseInMinutes;

  const classes = useStyles();
  const [t] = useTranslation();
  const [operationTimeTable, setOperationTimeTable] = useState(data);
  const [dayOnFocus, setDayOnFocus] = useState({ content: [] });

  const updateOperationTimeSchedule = (day) => {
    let _operationTimeTable = [...operationTimeTable];
    _operationTimeTable[day.index] = day.content;
    setOperationTimeTable(_operationTimeTable);
    setFunc(_operationTimeTable);
  };

  const isCurrentDaySet = (content) => {
    return content && content.length === 2 && content[0] >= 0 && content[1] > 0;
  };

  const getMarkers = () => {
    let _markers = [];
    _markers.push({
      value: dayOnFocus.content[0] || (MAX_STEPS / 24) * 8,
      label: DateTimeUtil.intMinToDisplayTime(
        dayOnFocus.content[0] * timeBaseInMinutes,
        true
      )
    });
    _markers.push({
      value: (MAX_STEPS / 24) * 12,
      label: DateTimeUtil.intMinToDisplayTime(720, true)
    });
    _markers.push({
      value: dayOnFocus.content[1] || (MAX_STEPS / 24) * 18,
      label: DateTimeUtil.intMinToDisplayTime(
        dayOnFocus.content[1] * timeBaseInMinutes,
        true
      )
    });
    return _markers;
  };

  const toggleWeekDayState = (i) => {
    if (dayOnFocus.index === i) {
      if (isCurrentDaySet(operationTimeTable[i])) {
        dayOnFocus.content = [0, 0];
        updateOperationTimeSchedule(dayOnFocus);
      } else {
        let _markers = getMarkers();
        dayOnFocus.content = [_markers[0].value, _markers[2].value];
        updateOperationTimeSchedule(dayOnFocus);
      }
    } else {
      setDayOnFocus({ index: i, content: operationTimeTable[i] });
    }
  };

  const handleTimeChange = (event, newValue) => {
    dayOnFocus.content = newValue;
    updateOperationTimeSchedule(dayOnFocus);
  };

  const instantiateCheckBox = (day, index) => {
    return (
      <FormControlLabel
        key={`dayCheckbox${index}`}
        label={dayOnFocus.index === index ? <b>{t(day)}</b> : t(day)}
        labelPlacement={"top"}
        control={
          <Checkbox
            color="primary"
            size={"small"}
            checked={isCurrentDaySet(operationTimeTable[index])}
            icon={<BookmarkBorderIcon />}
            checkedIcon={<BookmarkIcon />}
            onChange={() => toggleWeekDayState(index)}
          />
        }
      />
    );
  };

  const populateWeekDays = () => {
    const checkboxList = [];
    WEEK_DAYS.map((day, index) =>
      checkboxList.push(instantiateCheckBox(day.key, index))
    );
    return checkboxList;
  };

  return (
    <Box className={classes.box}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">{t(titleKey)}</FormLabel>
        <FormGroup row={true} className={classes.daysGroup}>
          {populateWeekDays()}
        </FormGroup>
        <Slider
          value={dayOnFocus.content}
          marks={isCurrentDaySet(dayOnFocus.content) ? getMarkers() : []}
          onChange={handleTimeChange}
          disabled={!isCurrentDaySet(dayOnFocus.content)}
          step={1}
          min={0}
          max={MAX_STEPS}
        />
      </FormControl>
    </Box>
  );
};

DayHourRangePicker.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default DayHourRangePicker;
