import React from "react";
import { useLocalStore } from "../../hooks/useLocalStore";
import { makeStyles } from "@material-ui/styles";
import { ROLES } from "../../constants";
import { isTrue } from "../../common";
import { Chip, Typography, TableCell } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  "state--ACTIVE": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  "state--LOCKED": {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  "state--INACTIVE": {
    backgroundColor: theme.chip
  },
  nonBookableMark: {
    marginLeft: "5px"
  },
  plainChip: {
    margin: "2px"
  }
}));

export default function useUserDataTable(company, userGroups, showExternalIds) {
  const classes = useStyles();
  const localStore = useLocalStore();

  const preferencesUtil = localStore.getCompanyPreferencesUtil();

  const getSelectedUserGroups = (user) => {
    let _selectedUserGroups = [];
    if (userGroups && user.groups) {
      user.groups.forEach((group) => {
        userGroups.forEach((userGroup) => {
          if (userGroup.userGroupId === group) {
            _selectedUserGroups.push(userGroup);
          }
        });
      });
    }
    return _selectedUserGroups;
  };

  const renderDisplayName = (user) => {
    return `${user.firstName} ${user.lastName}`;
  };

  const renderGroups = (user) => {
    return getSelectedUserGroups(user).map((group, index) => (
      <Chip
        label={group.name}
        key={`key${group.name}${index}`}
        className={classes.plainChip}
      />
    ));
  };

  const renderUserState = (user) => {
    return (
      user.state && (
        <Chip label={user.state} className={classes[`state--${user.state}`]} />
      )
    );
  };

  const renderRoles = (user) => {
    // prettier-ignore
    return (
      <TableCell>
        {user.roles
          .filter((role) => preferencesUtil.isCaseManagementEnabled() ? 
            true : role !== ROLES.CASE_ADMIN)
          .map((role, index) => (
            <Chip
              className={classes.plainChip}
              label={role.replace(/_/g, " ")}
              key={role + "_" + index}
            />
          ))}
      </TableCell>
    );
  };

  const renderUserVaccinated = (user) => {
    const vaxStatus =
      user.vaccination && isTrue(user.vaccination.isVaccinated) ? (
        <CheckIcon />
      ) : (
        <Typography className={classes.nonBookableMark}>---</Typography>
      );

    return <TableCell align="center">{vaxStatus}</TableCell>;
  };

  const renderUserAccess = (user) => {
    const accessState =
      user.access && isTrue(user.access.hasAccess) ? (
        <CheckIcon />
      ) : (
        <Typography className={classes.nonBookableMark}>---</Typography>
      );
    return <TableCell align="center">{accessState}</TableCell>;
  };

  const tableStructure = [
    {
      columnNamekey: "Commons.Name",
      fieldName: "firstName",
      customContentFormatter: renderDisplayName
    },
    {
      columnNamekey: "Commons.Email",
      fieldName: "email"
    },
    {
      columnNamekey: "Commons.ExternalId",
      fieldName: "externalId",
      shouldRender: () => showExternalIds
    },
    {
      columnNamekey: "Users.Groups",
      fieldName: "groups",
      customContentFormatter: renderGroups
    },
    {
      columnNamekey: "Users.Roles",
      fieldName: "roles",
      customCellRenderer: renderRoles
    },
    {
      columnNamekey: "Users.State",
      fieldName: "state",
      customContentFormatter: renderUserState
    },
    {
      columnNamekey: "Users.Vaccinated",
      fieldName: "vaccination",
      customCellRenderer: renderUserVaccinated,
      shouldRender: () => preferencesUtil.displayVaccinatedField()
    },
    {
      columnNamekey: "Users.AccessGranted",
      fieldName: "access",
      customCellRenderer: renderUserAccess,
      shouldRender: () => preferencesUtil.displayAccessPolicyField()
    }
  ];

  return tableStructure;
}
