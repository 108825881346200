import React, { useState, useEffect } from "react";
import { useLocalStore } from "../../hooks/useLocalStore";
import { useCompanyService } from "../../services/useCompanyService";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography } from "@material-ui/core";
import { isEmpty, isTrue, allMatching } from "../../common";
import { ROLES } from "../../constants";
import CurrentBookings from "./CurrentBookings";
import CreateBooking from "./CreateBooking";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  tabContainer: {
    margin: "1em 0",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "3em",
    gridGap: "1em",
    borderBottom: "solid 2px #444",
    "align-items": "end",
    "& button, & button:focus": {
      backgroundColor: theme.palette.primary.main,
      outline: "none",
      opacity: ".7",
      border: "none",
      height: "90%",
      "&.active": {
        opacity: "1",
        border: "solid 2px #444",
        "border-bottom": "none",
        height: "100%"
      }
    }
  }
}));

export default function Booking() {
  const { t } = useTranslation();
  const classes = useStyles();
  const localStore = useLocalStore();
  const companyService = useCompanyService();
  const [value, setValue] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);

  const company = localStore.getCompany();

  useEffect(() => {
    isEmpty(usersList) && loadUsersList();
    isEmpty(locationsList) && loadLocationsList();
  }, []);

  const loadLocationsList = async () => {
    const _locations = await companyService.getLocationsFromDropdownListCollection();
    setLocationsList(_locations);
  };

  const loadUsersList = async () => {
    const _usersList =
      await companyService.getUsersFromDropdownListCollection();
    const currentUser = localStore.getCurrentUser();
    const isGroupAdminOnly = isTrue(
      currentUser.roles.some((role) => role === ROLES.USER_GROUP_ADMIN) &&
      currentUser.roles.length === 1
    );

    if (isGroupAdminOnly) {
      const userGroups = await companyService.getUserGroups();
      const managedGroups = userGroups
        .filter((g) => g.admins.includes(currentUser.email))
        .map((g) => g.userGroupId);
      const filteredUserList = allMatching(_usersList, managedGroups, "groups");
      setUsersList(filteredUserList);
    } else {
      setUsersList(_usersList);
    }
  };

  return (
    <div className="content">
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h3">{t("Booking.BookingManagement")}</Typography>
        <div className={classes.tabContainer}>
          <button
            onClick={() => setValue(0)}
            className={value === 0 ? "active" : ""}
          >
            <Typography variant="h4">{t("Booking.CurrentBookings")}</Typography>
          </button>
          <button
            onClick={() => setValue(1)}
            className={value === 1 ? "active" : ""}
          >
            <Typography variant="h4">{t("Booking.NewBooking")}</Typography>
          </button>
        </div>
        {value !== 0 ? "" : <CurrentBookings company={company} usersList={usersList} locationsList={locationsList}/>}
        {value !== 1 ? "" : <CreateBooking company={company} usersList={usersList}/>}
      </Container>
    </div>
  );
}
