import React, { useState } from "react";
import { firebase } from '../../firebase';
import { makeStyles } from "@material-ui/styles";
import { Button, Card, Container, Typography, Select, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  formWrapper: {
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto",
    textAlign: "center"
  },
  formCard: {
    width: "auto",
    marginBottom: theme.spacing(5)
  },
  cardContentWrapper: {
    padding: theme.spacing(5)
  },
  title: {
    textAlign: "center"
  },
  selector: {
    margin: theme.spacing(3)
  }, 
  button: {
    margin: theme.spacing(2)
  }
}));

export const Heartbeat = () => {
  const classes = useStyles();

  const [time, setTime] = useState(0);
  const [isPaused, setIsPaused] = useState(true);
  const [logTime, setLogTime] = useState(false);
  const [numPings, setNumPings] = useState(1);
  const [results, setResults] = useState([]);

  React.useEffect(() => {
    let interval = null;

    if (!isPaused) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }

    if (logTime) {
      const _results = [...results];
      _results.push(time);
      setResults(_results);
      setLogTime(false);
    }

    return () => clearInterval(interval);
  }, [logTime, isPaused]);

  const makeRequest = async () => {
    clearResults();
    const heartbeat = firebase.functions().httpsCallable('heartbeat');

    for (let i = 0; i < numPings; i++) {
      timerStart();
      await heartbeat().then(result => {
        if (result.data.success) {
          timerPause();
          timerLog();
          timerReset();
        } else {
          const _failed = [...results];
          _failed.push("FAILED");
          setResults(_failed);
          timerReset();
        }
      }).catch((error) => {
        console.log(error);
        const _failed = [...results];
        _failed.push("FAILED");
        setResults(_failed);
        timerReset();
      });
    }
  };
  
  const timerStart = () => {
    setIsPaused(false);
  };

  const timerPause = () => {
    setIsPaused(true);
  };

  const timerReset = () => {
    setIsPaused(true);
    setTime(0);
  };

  const timerLog = () => {
    setLogTime(true);
  };

  const clearResults = () => {
    setResults([]);
  };

  const handleChange = (event) => {
    setNumPings(event.target.value);
  };

  const Timer = ({ time, variant }) => {

    return time === "FAILED" ? (
      <div>
        <Typography variant={variant}>{time}</Typography>
      </div>
    ) : (
      <div>
        <Typography variant={variant}>
          {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:{("0" + Math.floor((time / 1000) % 60)).slice(-2)}.{("0" + ((time / 10) % 100)).slice(-2)}
        </Typography>
      </div>
    );
  };

  return (
    <div className="content">
      <Container className={classes.container}>
        <div className={classes.formWrapper}>
          <Card className={classes.formCard}>
            <div className={classes.cardContentWrapper}>
              <img
                src="./logo.jpg"
                style={{ width: "300px", marginBottom: "24px" }}
              />
              <Typography variant="h2" className={classes.title}>
                Heartbeat
              </Typography>
              <div className={classes.selector}>
                <Typography>Select the number of pings to send</Typography>
                <Select
                  value={numPings}
                  onChange={handleChange}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </div>
              <Timer time={time} variant="h3"></Timer>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={makeRequest}
              >
                Ping!
              </Button>
              {results.map((result, index) => (
                <div key={index}>
                  <Timer time={result}></Timer>
                </div>
              ))}
            </div>
          </Card>
        </div>
      </Container>
    </div>
  );
};