import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import { grey } from "@material-ui/core/colors";
import { useCompanyService } from "../../services/useCompanyService";
import { LOCATION_STATES } from "../../constants";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  wrapper: {
    margin: "20px 0"
  },
  card: {
    maxHeight: "70vh"
  },

  table: {
    paddingTop: 0,
    paddingBottom: 0
  },

  requestParams: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1.5fr",
    columnGap: "20px",
    rowGap: "20px",
    alignItems: "center"
  },
  "@media print": {
    requestParams: {
      display: "none"
    }
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  row: {
    display: "contents",
    "&>p": {
      color: "#263238",
      display: "table-cell",
      padding: "16px",
      "font-size": "14px",
      "text-align": "left",
      "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
      "font-weight": "400",
      "line-height": "1.5rem",
      "border-bottom": "1px solid #eeeeee",
      "letter-spacing": "-0.05px",
      "vertical-align": "inherit"
    }
  },
  header: {
    "&>p": {
      backgroundColor: "#fafafa",
      color: "#263238",
      display: "table-cell",
      padding: "16px",
      "font-size": "14px",
      "text-align": "left",
      "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
      "font-weight": "500",
      "line-height": "21px",
      "border-bottom": "1px solid #eeeeee",
      "letter-spacing": "-0.05px",
      "vertical-align": "inherit"
    }
  }
}));

export default function Cleaned({ locationOptions, company }) {
  const classes = useStyles();
  const companyService = useCompanyService();
  const { t } = useTranslation();

  const [locations, setLocations] = useState([]);
  const [cleanedState, setCleanedState] = useState([]);

  const [loadingView, setLoadingView] = useState(false);

  const [coordinateSystem, setCoordinateSystem] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [reportDate, setReportDate] = useState(new Date());
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    setCoordinateSystem([...company.coordinateSystem]);
    setCompanyName(company.companyName);
  }, []);

  const getBookings = async (state) => {
    setLoadingView(true);
    setCleanedState(state);

    const bottonLevelLocations = selectedLocation
      ? await companyService.getBookableLocationsUnderAllLevels(
          selectedLocation
        )
      : await companyService.getAllLocations();
    setLocations(bottonLevelLocations);

    setLoadingView(false);
  };

  const dateString = reportDate.toISOString().split("T")[0];

  return (
    <>
      <Typography variant="h3">{companyName}</Typography>
      <Typography variant="h4">
        {t("Reports.CleaningReports")} [{dateString}]
      </Typography>
      <div
        className={classnames({
          [classes.requestParams]: true,
          [classes.wrapper]: true
        })}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={loadingView}
          onClick={() => {
            getBookings(LOCATION_STATES.NOT_CLEANED);
          }}
        >
          {t("Reports.NotCleanedReport")}
          {loadingView && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={loadingView}
          onClick={() => {
            getBookings(LOCATION_STATES.CLEANED);
          }}
        >
          {t("Reports.CleanedReport")}
          {loadingView && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>

        <Autocomplete
          id="bookingLocation"
          defaultValue={selectedLocation}
          options={locationOptions}
          value={selectedLocation}
          getOptionLabel={(option) => option.displayLabel}
          onChange={(event, newValue) => {
            setSelectedLocation(newValue);
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Commons.Location")}
              variant="outlined"
            />
          )}
        />
      </div>

      {locations && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="Bookings">
            <TableHead>
              <TableRow>
                {coordinateSystem.map((value, index) => {
                  return <TableCell key={index}>{value}</TableCell>;
                })}
                <TableCell>{t("Reports.State")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations
                .filter((location) => location.state === cleanedState)
                .map((location) => {
                  return (
                    <div className={classes.row}>
                      <TableRow key={location.locationId}>
                        {coordinateSystem.map((value, index) => {
                          if (location.coordinate[value]) {
                            return (
                              <TableCell>
                                {location.coordinate[value].displayName}
                              </TableCell>
                            );
                          } else {
                            return <TableCell></TableCell>;
                          }
                        })}
                        <TableCell>{location.state}</TableCell>
                      </TableRow>
                    </div>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
