import React, { useState, useEffect } from "react";
import { RESOURCE_STATES } from "../../constants";
import { useCompanyService } from "../../services/useCompanyService";
import { useLocalStore } from "../../hooks/useLocalStore";
import { useHistory } from "react-router-dom";
import { debounce, isEmpty } from "../../common";
import { useDispatch } from "react-redux";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { ResourceEditor } from "./ResourceEditor";
import { Container, Typography, Button } from "@material-ui/core";
import DataTable from "../../components/DataTable/DataTable";
import SearchBar from "../../components/SearchBar/SearchBar";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import MultipleSelector from "../../components/MultipleSelector/MultipleSelector";
import addDays from "date-fns/addDays";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CalendarToday from "@material-ui/icons/CalendarToday";

const resourcesTableStructure = [
  {
    columnNamekey: "Resources.Name",
    fieldName: "displayName",
    multiLanguage: true
  },
  {
    columnNamekey: "Resources.Description",
    fieldName: "description",
    multiLanguage: true
  },
  {
    columnNamekey: "Resources.State",
    fieldName: "state",
    fieldNameIsKey: true
  }
];

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  fullWidth: {
    width: "100%"
  },
  toolbar: {
    display: "flex",
    alignItems: "start",
    marginTop: theme.spacing(5),
    gap: "15px",
    "justify-content": "space-between",
    "&>*": {
      width: "30%"
    }
  },
  addResourceButton: {
    margin: theme.spacing(3, 0)
  }
}));

export default function Resource() {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const classes = useStyles();
  const companyService = useCompanyService();
  const localStore = useLocalStore();
  const history = useHistory();
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [resourceInfo, setResourceInfo] = useState(null);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  const [shouldReload, setShouldReload] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [allStates, setAllStates] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const company = localStore.getCompany();

  function wrapState(state) {
    return { id: state, name: t(`Resources.${state}`) };
  }

  useEffect(() => {
    let localFilteredResources = filterResourceListBySearchString();
    if (selectedStates.length > 0) {
      localFilteredResources = resources.filter((r) =>
        selectedStates.some((s) => r.state === s)
      );
    }
    setFilteredResources(localFilteredResources);
  }, [selectedStates, searchValue]);

  useEffect(() => {
    loadData();
  }, [shouldReload]);

  const filterResourceListBySearchString = () => {
    const localFilteredUserList = resources.filter((resource) =>
      resource.displayName[i18n.language]
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );
    return localFilteredUserList;
  };

  const loadData = async () => {
    const _allStates = RESOURCE_STATES.map((s) => wrapState(s));
    setAllStates(_allStates);

    const resourcesData = await companyService.getResources();
    setResources(resourcesData);
    setFilteredResources(
      isEmpty(selectedStates)
        ? resourcesData
        : resourcesData.map((r) => selectedStates.includes(r.state))
    );
  };

  const handleStateChange = (value) => {
    const states = value.map((s) => s.id);
    setSelectedStates(states);
  };

  const addResource = () => {
    setResourceInfo(null);
    setResourceInfo({
      openForm: true,
      createMode: true,
      data: {
        state: "ACTIVE",
        capacity: 0,
        unitOfTime: 15,
        cutOffHours: 0,
        advanceAppointmentDays: 0,
        attachedTo: "",
        appointmentTypes: [],
        associatedUsers: [],
        displayName: { [i18n.language]: "" },
        description: { [i18n.language]: "" },
        operationTimeTable: {
          Sunday: [],
          Monday: [],
          Tuesday: [],
          Wednesday: [],
          Thursday: [],
          Friday: [],
          Saturday: []
        },
        operationExceptionDates: []
      }
    });
  };

  const openResourceEditor = (_resource, _index) => {
    const _data = { ..._resource };
    _data.companyId = company.companyId;

    setResourceInfo(null);
    setResourceInfo({
      openForm: true,
      createMode: false,
      index: _index,
      data: _data
    });
  };

  const saveResource = (_resource) => {
    setSpinnerOn(true);
    if (resourceInfo.createMode) {
      createResource(_resource);
    } else {
      updateResource(_resource);
    }
    setSpinnerOn(false);
    setResourceInfo(null);
  };

  const createResource = async (data) => {
    await companyService
      .createResource(data)
      .then((response) => {
        if (!response.data.success) {
          dispatch(setErrorMessage(t("Errors.CreateResource")));
        } else {
          dispatch(setSuccessMessage(t("Success.CreateResource")));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage(t("Errors.CreateResource")));
        console.log("error occurred trying to update resource:", error);
      })
      .finally(() => {
        setShouldReload(!shouldReload);
      });
  };

  const updateResource = async (data) => {
    await companyService
      .updateResource(data)
      .then((response) => {
        if (!response.data.success) {
          dispatch(setErrorMessage(t("Errors.UpdateResource")));
        } else {
          dispatch(setSuccessMessage(t("Success.UpdateResource")));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage(t("Errors.UpdateResource")));
        console.log("error occurred trying to update resource:", error);
      })
      .finally(() => {
        setShouldReload(!shouldReload);
      });
  };

  const cancelEditResource = () => {
    let _info = { ...resourceInfo };
    _info.openForm = false;
    setResourceInfo(null);
  };

  const deleteResource = async () => {
    await companyService
      .deleteResource(deleteCandidate.resource)
      .then((response) => {
        if (!response.data.success) {
          dispatch(setErrorMessage(t("Errors.DeleteResource")));
        } else {
          const index = deleteCandidate.index;
          const _resources = [...filteredResources];
          _resources.splice(index, 1);
          setFilteredResources(_resources);
          setDeleteCandidate(null);
          dispatch(setSuccessMessage(t("Success.DeleteResource")));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage(t("Errors.DeleteResource")));
        console.log("error occurred trying to update resource:", error);
      })
      .finally(() => {
        setShouldReload(!shouldReload);
      });
  };

  const confirmDeleteResource = (_resource, _index) => {
    setDeleteCandidate({
      resource: _resource,
      index: _index
    });
  };

  const jumpToAppointmentManagement = async (_resource) => {
    history.push("/appointments", {
      filters: {
        resource: _resource.resourceId,
        status: "ALL",
        startingAppointmentDate: new Date(),
        endingAppointmentDate: addDays(new Date(), 7)
      }
    });
  };

  const onTextSearch = (value) => {
    setSearchValue(value);
  };
  const debounceTextSearch = debounce(onTextSearch, 600);

  const actionButtons = [
    {
      toolTipKey: "Commons.Edit",
      icon: <EditIcon />,
      func: openResourceEditor
    },
    {
      toolTipKey: "Resources.DeleteResource",
      icon: <DeleteIcon />,
      func: confirmDeleteResource
    },
    {
      toolTipKey: "Resources.JumpToAppointmentManager",
      icon: <CalendarToday />,
      func: jumpToAppointmentManagement
    }
  ];

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h3">{t("NavBar.Resources")}</Typography>
        <div className={classes.toolbar}>
          <SearchBar onSearch={debounceTextSearch} />
          <MultipleSelector
            options={allStates}
            onChange={(event) => {
              handleStateChange(event);
            }}
            label={t("Resources.ResourceState")}
            placeHolder={t("Resources.State")}
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.addResourceButton}
            onClick={addResource}
          >
            {t("Resources.AddResource")}
          </Button>
        </div>
        <DataTable
          tableStructure={resourcesTableStructure}
          tableContent={[...filteredResources]}
          selectedRows={{ value: selectedRows, setter: setSelectedRows }}
          actions={actionButtons}
        />
      </Container>
      {resourceInfo && (
        <ResourceEditor
          resourceInfo={resourceInfo}
          cancelFunc={cancelEditResource}
          saveFunc={saveResource}
          spinnerOn={spinnerOn}
          company={company}
        />
      )}
      {deleteCandidate && (
        <ConfirmDialog
          title={t("Resources.DeleteResource")}
          message={t("Resources.DeleteResourceConfirmation", {
            resource: `${deleteCandidate.resource.displayName}`
          })}
          warning={t("Resources.DeleteResourceInstruction")}
          onConfirm={deleteResource}
          onCancel={() => {
            setDeleteCandidate(null);
          }}
        />
      )}
    </>
  );
}
