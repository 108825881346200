import React, { useEffect, useState } from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchBar from "../../../components/SearchBar/SearchBar";
import DataTable from "../../../components/DataTable/DataTable";
import { isEmpty } from "../../../common";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "start",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    gap: "15px",
    "justify-content": "space-between",
    "&>*": {
      width: "30%"
    }
  },
  addButton: {
    margin: theme.spacing(2, 0)
  }
}));

export const Devices = ({
  devices,
  setEditDevice,
  setDeleteDevice,
  profileMap
}) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [filteredDevices, setFilteredDevices] = useState(devices);
  const { t } = useTranslation();

  useEffect(() => {
    setFilteredDevices(devices);
  }, [devices]);

  useEffect(() => {
    if (searchValue) {
      let _searchValue = searchValue.toLowerCase();
      let _filteredDevices = devices.filter(
        (d) =>
          d.name.toLowerCase().includes(_searchValue) ||
          (d.profileId &&
            profileMap[d.profileId] &&
            profileMap[d.profileId].displayName
              .toLowerCase()
              .includes(_searchValue)) ||
          d.uuid.includes(_searchValue)
      );
      setFilteredDevices(_filteredDevices);
    } else {
      setFilteredDevices(devices);
    }
  }, [searchValue]);

  const renderProfileName = (profile) => {
    const profileId = profile.profileId;
    // prettier-ignore
    return isEmpty(profileId) ? 
      t("Gate.NoProfileAssociated") : 
      isEmpty(profileMap[profileId]) ? "" : profileMap[profileId].displayName;
  };

  const devicesTableStructure = [
    {
      columnNamekey: "Gate.DeviceName",
      fieldName: "name"
    },
    {
      columnNamekey: "Gate.AssignedProfile",
      fieldName: "profileId",
      customContentFormatter: renderProfileName
    },
    {
      columnNamekey: "Gate.UUID",
      fieldName: "uuid"
    }
  ];

  const actionButtons = [
    {
      toolTipKey: "Commons.Edit",
      icon: <EditIcon />,
      func: (device) => setEditDevice({ createMode: false, device: device })
    },
    {
      toolTipKey: "Commons.Delete",
      icon: <DeleteIcon />,
      func: (device) => setDeleteDevice(device)
    }
  ];

  return (
    <Container>
      <Typography variant="h3">{t("Gate.Devices")}</Typography>
      <div className={classes.toolbar}>
        <SearchBar onSearch={setSearchValue} />
      </div>
      <DataTable
        tableStructure={devicesTableStructure}
        tableContent={[...filteredDevices]}
        actions={actionButtons}
      />
    </Container>
  );
};
