import * as L from "leaflet";
import "leaflet.awesome-markers";

export const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});


export const assignedIcon = L.AwesomeMarkers.icon({
  icon: 'arrow-circle-down',
  markerColor: 'green',
  prefix: 'fa',
  className: 'awesome-marker awesome-marker-square'
});


export const unassignedIcon = L.AwesomeMarkers.icon({
  icon: 'arrow-circle-down',
  markerColor: 'blue',
  prefix: 'fa',
  className: 'awesome-marker awesome-marker-square'
});


export const selectedIcon = L.AwesomeMarkers.icon({
  icon: 'arrow-circle-down',
  markerColor: 'orange',
  prefix: 'fa',
  className: 'awesome-marker awesome-marker-square'
});


export const insertIcon = L.AwesomeMarkers.icon({
  icon: 'plus-circle',
  markerColor: 'red',
  prefix: 'fa',
  className: 'awesome-marker awesome-marker-square'
});

export const suggestedLocationIcon = L.AwesomeMarkers.icon({
  icon: 'chevron-down',
  markerColor: 'darkred',
  prefix: 'fa',
  className: 'awesome-marker awesome-marker-square'
});

export const selectedSuggestedLocationIcon = L.AwesomeMarkers.icon({
  icon: 'chevron-down',
  markerColor: 'orange',
  prefix: 'fa',
  className: 'awesome-marker awesome-marker-square'
});
