import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "../../common";
import { useTranslation } from "react-i18next";
import { FormControl, Select, MenuItem, InputLabel, Chip, Checkbox, ListItemText } from "@material-ui/core";

const StatusSelector = ({
  controller,
  statusObj,
  remove,
  includeAll,
  includeNone,
  style,
  enable = true
}) => {
  const isMultiple = Array.isArray(controller.value);
  const [t] = useTranslation();
  const [allStatus, setAllStatus] = useState([]);

  useEffect(() => {
    isEmpty(allStatus) && loadStatuses();
  }, []);

  const loadStatuses = () => {
    const list = Object.getOwnPropertyNames(statusObj);
    remove.map((s) => delete list[s]);
    setAllStatus(list);
  };

  const createItemList = () => {
    const _itemList = [];

    if (includeAll && !isMultiple) {
      _itemList.push(
        <MenuItem value="ALL" key={`menuItemALL`}>
          {t("Status.AllStatus")}
        </MenuItem>
      );
    }

    if (!isEmpty(allStatus)) {
      allStatus.map((s) => {
        if (!remove.includes(s)) {
          _itemList.push(
            <MenuItem value={s} key={`menuItem${s}`}>
              <Checkbox
                color="primary"
                checked={controller.value.indexOf(s) > -1}
              />
              <ListItemText primary={t(`Status.${s}`)} />
            </MenuItem>
          );
        }
      });
    }

    if (includeNone) {
      _itemList.push(
        <MenuItem value="NONE" key={`menuItemNONE`}>
          {t("Status.NoneStatus")}
        </MenuItem>
      );
    }

    return _itemList;
  };

  const handleOnSelectValue = (e) => {
    controller.setter(e.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      style={{
        width: "100%"
      }}
    >
      <InputLabel id="status-label">
        {t("Commons.Status")}
      </InputLabel>
      <Select
        disabled={!enable}
        style={style}
        labelId="status-label"
        multiple={isMultiple}
        value={controller.value}
        onChange={handleOnSelectValue}
        label="status"
        displayEmpty
        renderValue={(selected) => {
          return (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.5rem"
              }}
            >
              {isMultiple && selected.map((value) => (
                <Chip key={value} label={value} />
              )) || <Chip key={selected} label={selected} />}
            </div>
          );
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
      >
        {createItemList()}
      </Select>
    </FormControl>
  );
};

StatusSelector.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default StatusSelector;
