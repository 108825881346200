import { useReducer } from 'react';
import {
  LOCATION_TAGS_ACTIONS as ACTIONS,
  EMPTY_LOCATION_TAG
} from '../../constants';

const tagNameReducer = (tags, action) =>
  tags.map((tag, index) => {
    const { index: payloadIndex, displayName, language } = action.payload;
    return index === payloadIndex
      ? {
        ...tag,
        displayName: {
          ...tags[index].displayName,
          [language]: displayName
        }
      }
      : tag;
  });

const tagColorReducer = (tags, action) =>
  tags.map((tag, index) => {
    const { index: payloadIndex, color } = action.payload;
    return index === payloadIndex ? { ...tag, color } : tag;
  });

const removeTagReducer = (tags, action) =>
  tags.filter((_, index) => index !== action.payload.index);

export default function useLocationTagsEditorFormState(language, locationTags) {
  const reducer = (tags, action) => {
    switch (action.type) {
      case ACTIONS.ADD_TAG:
        return [...tags, EMPTY_LOCATION_TAG];
      case ACTIONS.CHANGE_TAG_NAME:
        return tagNameReducer(tags, action);
      case ACTIONS.CHANGE_TAG_COLOR:
        return tagColorReducer(tags, action);
      case ACTIONS.REMOVE_TAG:
        return removeTagReducer(tags, action);
      default:
        return tags;
    }
  };

  const [tags, dispatch] = useReducer(reducer, locationTags);

  const addTag = () => dispatch({ type: ACTIONS.ADD_TAG });

  const changeTagName = (index, displayName) =>
    dispatch({
      type: ACTIONS.CHANGE_TAG_NAME,
      payload: { index, displayName, language }
    });

  const changeTagColor = (index, color) =>
    dispatch({
      type: ACTIONS.CHANGE_TAG_COLOR,
      payload: { index, color }
    });

  const removeTag = (index) =>
    dispatch({ type: ACTIONS.REMOVE_TAG, payload: { index } });

  return { tags, addTag, changeTagName, removeTag, changeTagColor };
}
