import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore } from "../../hooks/useLocalStore";

export default function useExportUserDataTable(userGroups, locations) {
  const [t] = useTranslation();
  const [exportCsvHeaders, setExportCsvHeaders] = useState(null);
  const localStore = useLocalStore();

  const preferencesUtil = localStore.getCompanyPreferencesUtil();

  useEffect(() => {
    initExportCsvHeaders();
  }, []);

  const initExportCsvHeaders = () => {
    const headers = [
      { label: t("Commons.FirstName"), key: "firstName" },
      { label: t("Commons.LastName"), key: "lastName" },
      { label: t("Commons.Email"), key: "email" },
      { label: t("Commons.ExternalId"), key: "externalId" },
      { label: t("Imports.Groups"), key: "groups" },
      { label: t("Imports.Roles"), key: "roles" },
      { label: t("Imports.State"), key: "state" },
      { label: t("Imports.Assigned"), key: "assigned" },
      { label: t("Imports.Preferred"), key: "preferred" }
    ];
    if (preferencesUtil.displayVaccinatedField()) {
      headers.push({ label: t("Users.Vaccinated"), key: "isVaccinated" });
    }
    if (preferencesUtil.displayAccessPolicyField()) {
      headers.push({ label: t("Users.AccessGranted"), key: "hasAccess" });
    }
    setExportCsvHeaders(headers);
  };

  const convertToExportable = (filteredUsers) => {
    return filteredUsers.map((user) => {
      const exportable = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        externalId: user.externalId,
        groups: getSelectedUserGroups(user).map((group) => group.name),
        roles: user.roles,
        state: user.state,
        assigned: user.assignedSeats.map((seat) => getLocationName(seat)),
        preferred: user.preferredSeats.map((seat) => getLocationName(seat))
      };
      if (preferencesUtil.displayVaccinatedField()) {
        exportable.isVaccinated =
          (user.vaccination && user.vaccination.isVaccinated.toString()) ||
          "N/A";
      }
      if (preferencesUtil.displayAccessPolicyField()) {
        exportable.hasAccess =
          (user.access && user.access.hasAccess && user.access.hasAccess.toString()) || "N/A";
      }
      return exportable;
    });
  };

  const getSelectedUserGroups = (user) => {
    let _selectedUserGroups = [];
    if (userGroups && user.groups) {
      user.groups.forEach((group) => {
        userGroups.forEach((userGroup) => {
          if (userGroup.userGroupId === group) {
            _selectedUserGroups.push(userGroup);
          }
        });
      });
    }
    return _selectedUserGroups;
  };

  const getLocationName = (locationId) => {
    const assignedLocation = locations && locations.find((location) => location.locationId === locationId) || {};
    return assignedLocation.displayName || "Location not found.";
  };

  return { exportCsvHeaders, convertToExportable };
}
