import React from "react";
import PropTypes from "prop-types";
import { useCompanyService } from "../../services/useCompanyService";
import { useTranslation } from "react-i18next";
import BaseSelector from "./BaseSelector";

const ResourceSelector = ({ controller, disabled, labelKey }) => {
  /* eslint-disable no-unused-vars */
  const [t, i18n] = useTranslation();
  const companyService = useCompanyService();
  const resourceLabelFormatter = (resource) => {
    return resource.displayName[i18n.language];
  };

  return (
    <BaseSelector
      _controller={controller}
      _labelKey={labelKey}
      _disabled={disabled}
      _ajaxMethod={companyService.getResources}
      _optionLabelFormater={resourceLabelFormatter}
      _idFieldName={"resourceId"}
    />
  );
};

ResourceSelector.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default ResourceSelector;
