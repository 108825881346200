import React, { useState, useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import UserSelector from "../../components/Selectors/UserSelector";
import SimpleSelector from "../../components/SimpleSelector/SimpleSelector";
import { useDispatch } from "react-redux";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";
import { useLocalStore } from "../../hooks/useLocalStore";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useCompanyService } from "../../services/useCompanyService";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { ROLES, CASE_STATUS, CASE_TYPE } from "../../constants";
import { grey } from "@material-ui/core/colors";
import { EditCase } from "./EditCase";
import { isEmpty } from "../../common";
import {
  CircularProgress,
  Container,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Tooltip,
  Button,
  IconButton,
  Chip,
  Card,
  Grid
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  fullWidth: {
    width: "100%"
  },
  toolbar: {
    display: "flex",
    alignItems: "start",
    marginBottom: theme.spacing(5)
  },
  table: {
    width: "100%",
    "& th:nth-of-type(4)": {
      minWidth: "250px"
    }
  },
  addCaseButton: {
    margin: theme.spacing(3, 0)
  },
  searchButton: {
    padding: "13px"
  },
  chipsWrapper: {
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    gap: "5px"
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function CaseManagement() {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const classes = useStyles();
  const localStore = useLocalStore();
  const companyService = useCompanyService();
  const [loadingCases, setLoadingCases] = useState(false);
  const [savingCases, setSavingCases] = useState(false);
  const [editCase, setEditCase] = useState(null);
  const [reportedCases, setReportedCases] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [filters, setFilters] = useState({
    selectedUser: null,
    selectedAdminUser: null,
    selectedStatus: "",
    highOpeningCaseDate: new Date(),
    lowOpeningCaseDate: new Date()
  });

  const adminRoles = [ROLES.ADMIN, ROLES.CASE_ADMIN, ROLES.USER_GROUP_ADMIN];
  const currentUser = localStore.getCurrentUser();
  const company = localStore.getCompany();

  useEffect(() => {
    loadData();
  }, []);

  function formateDate(date) {
    const dateFormat = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", dateFormat);
  }

  function wrapParameter(statusCode) {
    return { id: statusCode, displayKey: `CaseManagement.${statusCode}` };
  }

  const loadData = async () => {
    if (!currentUser.roles.includes(ROLES.ADMIN)) {
      filters.selectedAdminUser = currentUser;
    }

    const _allStatus = Object.values(CASE_STATUS).map((s) => wrapParameter(s));
    _allStatus.push(wrapParameter("ALL"));
    setAllStatus(_allStatus);

    if (isEmpty(usersList)) {
      const _users = await companyService.getUsersFromDropdownListCollection();
      setUsersList(_users);
    }
      
    if (isEmpty(locationsList)) {
      const _locations = await companyService.getLocationsFromDropdownListCollection();
      setLocationsList(_locations);
    }

    await getReportedCases();
  };

  const getReportedCases = async () => {
    setLoadingCases(true);
    const _reportedCases = await companyService.getFilteredReportedCases(
      filters.lowOpeningCaseDate,
      filters.highOpeningCaseDate,
      filters.selectedUser,
      filters.selectedAdminUser,
      filters.selectedStatus.id
    );
    setLoadingCases(false);
    setReportedCases(_reportedCases);
  };

  const onFilterChange = (filter, value) => {
    let _filters = { ...filters };
    _filters[filter] = value;
    setFilters(_filters);
  };

  const onUserChange = (value) => {
    onFilterChange("selectedUser", value);
  };

  const onAdminUserChange = (value) => {
    onFilterChange("selectedAdminUser", value);
  };

  const transformAdminUser = {
    fromFireStore: (adminUser, list) => {
      return list.find((u) => u.userId === adminUser.id);
    },
    toFireStore: (user) => {
      return { displayName: user.displayName, id: user.userId };
    }
  };

  const add = () => {
    setEditCase(null);
    setEditCase({
      openForm: true,
      createMode: true,
      reportedCase: {
        status: wrapParameter(CASE_STATUS.OPEN),
        type: wrapParameter(CASE_TYPE.MANUALLY_OPENED),
        changeUserState: false,
        openedBy: {
          displayName: currentUser.displayName,
          id: currentUser.userId
        },
        admins: [],
        notes: []
      }
    });
  };

  const edit = (_case) => {
    let _reportedCase = { ..._case };
    _reportedCase.status = wrapParameter(_case.status);
    _reportedCase.type = wrapParameter(_case.type);
    setEditCase({
      openForm: true,
      createMode: false,
      reportedCase: _reportedCase
    });
  };

  const saveEditCase = async (reportedCase) => {
    if (reportedCase) {
      reportedCase.status = reportedCase.status.id;
      reportedCase.type = reportedCase.type.id;
      if (editCase.createMode) {
        await createCaseService(reportedCase);
      } else {
        await updateCaseService(reportedCase);
        const _users = await companyService.getUsersFromDropdownListCollection();
        setUsersList(_users);
      }
      await getReportedCases();
    }
    setEditCase(null);
  };

  const cancelEditCase = () => {
    let _editCase = { ...editCase };
    _editCase.openForm = false;
    setEditCase(null);
  };

  const updateCaseService = async (data) => {
    setSavingCases(true);
    await companyService
      .updateCase(data)
      .then((response) => {
        if (!response.data.success) {
          dispatch(setErrorMessage(t("Errors.UpdateCase")));
        } else {
          dispatch(setSuccessMessage(t("Success.UpdateCase")));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage(t("Errors.UpdateCase")));
        console.log("error occurred trying to update case:", error);
      })
      .finally(() => {
        setSavingCases(false);
      });
  };

  const createCaseService = async (data) => {
    setSavingCases(true);
    await companyService
      .createCase(data)
      .then((response) => {
        if (!response.data.success) {
          dispatch(setErrorMessage(t("Errors.CreateCase")));
        } else {
          dispatch(setSuccessMessage(t("Success.CreateCase")));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage(t("Errors.CreateCase")));
        console.log("error occurred trying to update case:", error);
      })
      .finally(() => {
        setSavingCases(false);
      });
  };

  return (
    <div className="content">
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h3">{t("NavBar.CaseManagement")}</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.addCaseButton}
            onClick={add}
          >
            {t("CaseManagement.CreateNewCase")}
          </Button>
        </div>
        <div className={classes.toolbar}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <UserSelector
                contentList={usersList}
                labelKey={"Commons.User"}
                controller={{
                  value: filters.selectedUser,
                  setter: onUserChange
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <UserSelector
                contentList={usersList}
                labelKey={"CaseManagement.CaseAdmin"}
                transform={transformAdminUser}
                filterRoles={adminRoles}
                controller={{
                  value: filters.selectedAdminUser,
                  setter: onAdminUserChange
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <KeyboardDatePicker
                disableToolbar
                fullWidth
                inputVariant="outlined"
                format="MM/dd/yyyy"
                label={t("Commons.StartDate")}
                name="highCaseOpeningDate"
                maxDate={filters.highOpeningCaseDate}
                value={filters.lowOpeningCaseDate}
                onChange={(value) =>
                  onFilterChange("lowOpeningCaseDate", value)
                }
              />
            </Grid>
            <Grid item xs={3}>
              <KeyboardDatePicker
                disableToolbar
                fullWidth
                inputVariant="outlined"
                format="MM/dd/yyyy"
                label={t("Commons.EndDate")}
                name="lowCaseOpeningDate"
                minDate={filters.lowOpeningCaseDate}
                maxDate={new Date()}
                value={filters.highOpeningCaseDate}
                onChange={(value) =>
                  onFilterChange("highOpeningCaseDate", value)
                }
              />
            </Grid>
            <Grid item xs={3}>
              <SimpleSelector
                disable={false}
                value={filters.selectedStatus}
                onChangeFunc={onFilterChange}
                options={allStatus}
                label={t("Commons.Status")}
                fieldId="selectedStatus"
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={loadingCases}
                className={classes.searchButton}
                onClick={getReportedCases}
              >
                {t("CaseManagement.SearchCases")}
                {loadingCases && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.table}>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {reportedCases ? reportedCases.length : 0}{" "}
            {t("CaseManagement.SearchCases")}
          </Typography>
          <Card className={classes.card}>
            <TableContainer className={classes.inner}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("CaseManagement.OpeningDate")}</TableCell>
                    <TableCell>{t("CaseManagement.LastChangeDate")}</TableCell>
                    <TableCell>{t("Commons.User")}</TableCell>
                    <TableCell>{t("CaseManagement.CaseAdmin")}</TableCell>
                    <TableCell>{t("Commons.Location")}</TableCell>
                    <TableCell>{t("Commons.Status")}</TableCell>
                    <TableCell>{t("CaseManagement.Actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportedCases.map((c, index) => (
                    <TableRow hover key={index}>
                      <TableCell>
                        <div>{formateDate(c.openingDate.toDate())}</div>
                      </TableCell>
                      <TableCell>
                        <div>{formateDate(c.lastChange.toDate())}</div>
                      </TableCell>
                      <TableCell>
                        <div>{c.user.displayName}</div>
                      </TableCell>
                      <TableCell>
                        <ul className={classes.chipsWrapper}>
                          {c.admins.map((admin) => (
                            <li key={admin.id}>
                              <Chip label={admin.displayName} />
                            </li>
                          ))}
                        </ul>
                      </TableCell>
                      <TableCell>
                        <div>{c.location.displayName}</div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <Chip
                            label={c.status}
                            className={classes[`state--${c.status}`]}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div>
                          <Tooltip title={t("Commons.Edit")}>
                            <IconButton
                              onClick={() => edit(c, index)}
                              className={classes.iconButton}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </Container>
      {editCase && (
        <EditCase
          usersList={usersList}
          locationsList={locationsList}
          openForm={editCase.openForm}
          company={company}
          createMode={editCase.createMode}
          currentUser={currentUser}
          _case={editCase.reportedCase}
          cancelFunc={cancelEditCase}
          saveFunc={saveEditCase}
          spinnerOn={savingCases}
        />
      )}
    </div>
  );
}
