import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

function Message() {
  const messageState = useSelector((state) => state.message);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = useCallback(
    (key) => (
      <>
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
          style={{ color: "#fff" }}
        >
          <CloseIcon />
        </IconButton>
      </>
    ),
    [closeSnackbar]
  );

  useEffect(() => {
    if (messageState.message !== "") {
      enqueueSnackbar(messageState.message, {
        autoHideDuration:
          messageState.severity === "error" ||
          messageState.severity === "warning"
            ? 10000
            : 5000,
        variant: messageState.severity,
        action
      });
    }
  }, [messageState, action, enqueueSnackbar]);

  return <div></div>;
}

export default Message;
