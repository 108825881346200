import React, { useState } from "react";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import { useTranslation } from "react-i18next";
import { makeStyles, Button, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import useLocationTagsEditorFormState from "./useLocationTagsEditorFormState";
import Tag from "./Tag";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import useRemoveTagConfirmDialog from "./useRemoveTagConfirmDialog";
import useSubmitLocationTagsForm from "./useSubmitLocationTagsForm";

const formId = "location-tags-editor";

const useStyles = makeStyles({
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  addButton: {
    padding: 5,
    minWidth: 0
  },
  contentWrapper: {
    margin: "1rem"
  }
});

function LocationTagsEditor({
  closeDialog,
  locationTags = [],
  loadLocationTags
}) {
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const { tags, addTag, changeTagName, removeTag, changeTagColor } =
    useLocationTagsEditorFormState(language, locationTags);

  const {
    onClickRemove,
    openConfirm,
    tagToRemove,
    onConfirmRemove,
    onCancelRemove
  } = useRemoveTagConfirmDialog(tags, removeTag);

  const { loading, onSubmit, validationErrors, setValidationErrors } =
    useSubmitLocationTagsForm(tags, loadLocationTags, closeDialog);

  return (
    <>
      <AddEditDialog
        title={t("Locations.LocationTags")}
        onCancel={closeDialog}
        enableLanguageSelector
        language={language}
        setLanguage={setLanguage}
        formId={formId}
        errorMessages={validationErrors}
        setErrorMessages={setValidationErrors}
        loading={loading}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.addButtonContainer}>
            <Tooltip title={t("Locations.AddTagTooltip")} placement="left">
              <Button
                variant="contained"
                color="primary"
                className={classes.addButton}
                onClick={addTag}
                disabled={tags.length > 9}
              >
                <AddIcon />
              </Button>
            </Tooltip>
          </div>
          <form onSubmit={onSubmit} id={formId}>
            {tags.map((tag, index) => (
              <Tag
                tag={tag}
                index={index}
                onRemove={() => onClickRemove(index)}
                changeTagName={changeTagName}
                language={language}
                key={index}
                changeTagColor={changeTagColor}
              />
            ))}
          </form>
        </div>
      </AddEditDialog>
      {openConfirm && (
        <ConfirmDialog
          title={t("Locations.ConfirmTagRemoval")}
          heading={`${t("Locations.TagRemovalHeading")} "${
            tagToRemove.displayName[language]
          }"?`}
          message={t("Locations.TagRemovalMessage")}
          onConfirm={onConfirmRemove}
          onCancel={onCancelRemove}
        />
      )}
    </>
  );
}

export default LocationTagsEditor;
