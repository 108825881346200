import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from "@material-ui/core";
import { ErrorAlert } from "./ErrorAlert";
import { grey } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import HealthScreeningForm from "./HealthScreeningForm";

const useStyles = makeStyles((theme) => ({
  formDialog: {
    minWidth: "25em"
  },
  errorMsg: {
    margin: "20px"
  },
  actions: {
    padding: theme.spacing(1, 3),
    margin: theme.spacing(2, 0)
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const formId = "health-screening-form";

function HealthScreeningModal({
  title,
  questionnaire,
  questionSetId,
  handleCancel,
  handleSubmit
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [errMsgs, setErrMsgs] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
      </DialogTitle>
      {errMsgs.length > 0 && (
        <ErrorAlert
          errorMessages={errMsgs}
          onCloseAlert={setErrMsgs}
          styles={classes.errorMsg}
        />
      )}
      <DialogContent className={classes.formDialog}>
        <HealthScreeningForm
          formId={formId}
          questionnaire={questionnaire}
          questionSetId={questionSetId}
          handleSubmit={handleSubmit}
          setSubmitted={setSubmitted}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleCancel} disabled={submitted}>
          {t("Commons.Cancel")}
        </Button>
        <Button
          type="submit"
          form={formId}
          variant="contained"
          color="primary"
          disabled={submitted}
        >
          {t("Commons.Submit")}
          {submitted && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HealthScreeningModal;
