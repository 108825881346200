import React from "react";
import PropTypes from "prop-types";
import BaseSelector from "./BaseSelector";
import { USER_STATUS } from "../../constants";

const UserSelector = ({
  controller,
  labelKey,
  filterRoles,
  filterGroups,
  transform,
  disabled,
  excludeInactiveUsers = true,
  contentList
}) => {
  const optionLabelFormater = (user) => {
    return user.displayName;
  };

  const optionFilterContent = (users) => {
    if (filterRoles && filterGroups) {
      return users.filter(
        (user) =>
          user.roles.some((r) => filterRoles.includes(r)) &&
          user.groups.some((g) => filterGroups.includes(g))
      );
    }

    if (filterRoles) {
      return users.filter((user) =>
        user.roles.some((r) => filterRoles.includes(r))
      );
    }

    if (filterGroups) {
      return users.filter((user) =>
        user.groups.some((r) => filterGroups.includes(r))
      );
    }

    if (excludeInactiveUsers) {
      return users.filter((user) => 
        user.state != USER_STATUS.INACTIVE
      );
    }

    return users;
  };

  const optionTooltipContent = (user) => {
    return user.email;
  };

  return (
    <BaseSelector
      _controller={controller}
      _disabled={disabled}
      _labelKey={labelKey}
      _ajaxMethod={() => contentList}
      _tooltip={optionTooltipContent}
      _filter={optionFilterContent}
      _optionLabelFormater={optionLabelFormater}
      _idFieldName={"userId"}
      _transform={transform}
    />
  );
};

UserSelector.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default UserSelector;
