import { SET_MESSAGE } from "../actions/index";

const initialState = {
  severity: "success",
  message: ""
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE: {
      return { ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export default messageReducer;
