import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Grid, TextField } from "@material-ui/core";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import { useCompanyService } from "../../services/useCompanyService";
import { setErrorMessage, setSuccessMessage } from "../../actions";
import { isNullOrUndefined } from "../../common";

export const EditLocationUser = ({ location, locationTrail, users, close }) => {
  const companyService = useCompanyService();
  const dispatch = useDispatch();

  const [t, i18n] = useTranslation();
  const [eligibleUsers, setEligibleUsers] = useState([]);
  const [selectedAssignedUsers, setSelectedAssignedUsers] = useState([]);
  const [selectedPreferredUsers, setSelectedPreferredUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let parentGroups = locationTrail.map((location) => location.groups);
    parentGroups.push(location.groups);
    parentGroups = parentGroups.flat();

    let _eligibleUsers = users.filter((user) =>
      parentGroups.every((group) => user.groups.includes(group))
    );
    setEligibleUsers(_eligibleUsers);

    let _selectedAssignedUsers = getExistingUsersByLocation(
      location.assignedLocationFor,
      users
    );
    let _selectedPreferredUsers = getExistingUsersByLocation(
      location.preferredLocationFor,
      users
    );
    setSelectedAssignedUsers(_selectedAssignedUsers);
    setSelectedPreferredUsers(_selectedPreferredUsers);
  }, []);

  const getExistingUsersByLocation = (userIds, users) => {
    if (userIds.length === 0) {
      return [];
    } else {
      const existingUsers = userIds.map((userId) => {
        return users.find((user) => user.userId == userId);
      });

      return existingUsers.filter((user) => !isNullOrUndefined(user));
    }
  };

  const getUserName = (user) => {
    return user.firstName.concat(" ", user.lastName);
  };

  const save = async () => {
    setLoading(true);

    let beforeChangeAssigned = getExistingUsersByLocation(
      location.assignedLocationFor,
      users
    );
    let afterChangeAssigned = selectedAssignedUsers;
    let addedAssigned = afterChangeAssigned.filter(
      (user) => !beforeChangeAssigned.includes(user)
    );
    let removedAssigned = beforeChangeAssigned.filter(
      (user) => !afterChangeAssigned.includes(user)
    );

    let beforeChangePreferred = getExistingUsersByLocation(
      location.preferredLocationFor,
      users
    );
    let afterChangePreferred = selectedPreferredUsers;
    let addedPreferred = afterChangePreferred.filter(
      (user) => !beforeChangePreferred.includes(user)
    );
    let removedPreferred = beforeChangePreferred.filter(
      (user) => !afterChangePreferred.includes(user)
    );

    addedAssigned.forEach((user) =>
      user.assignedSeats.push(location.locationId)
    );
    addedPreferred.forEach((user) =>
      user.preferredSeats.push(location.locationId)
    );
    removedAssigned.forEach((user) => {
      let index = user.assignedSeats.indexOf(location.locationId);
      if (index != -1) {
        user.assignedSeats.splice(index, 1);
      }
    });
    removedPreferred.forEach((user) => {
      let index = user.preferredSeats.indexOf(location.locationId);
      if (index != -1) {
        user.preferredSeats.splice(index, 1);
      }
    });

    location.assignedLocationFor = selectedAssignedUsers.map(
      (user) => user.userId
    );
    location.preferredLocationFor = selectedPreferredUsers.map(
      (user) => user.userId
    );

    let changedUsers = new Set([
      ...addedAssigned,
      ...addedPreferred,
      ...removedAssigned,
      ...removedPreferred
    ]);

    const promises = [];
    changedUsers.forEach((user) => {
      let response = companyService.updateUser(user);
      promises.push(response);
    });

    const errors = [];
    await Promise.all(promises).then((values) => {
      values.forEach((value) => {
        if (!value.data.success) {
          const path = "Errors.Cloud." + value.data.message;
          const error = i18n.exists(path) ? t(path) : t("Errors.UpdateLocation");
          errors.push(error);
        }
      });
    });

    errors.length > 0 ? dispatch(setErrorMessage(t("Errors.UpdateLocationUser"))) 
      : 
      dispatch(setSuccessMessage(t("Success.UpdateLocationUser")));

    setLoading(false);
    close(location);
  };

  return (
    <AddEditDialog
      createMode={false}
      title={t("Locations.Locations")}
      userName={location.displayName}
      onSave={save}
      onCancel={close}
      loading={loading}
    >
      {eligibleUsers && (
        <Grid item>
          <Autocomplete
            multiple
            id="selected_assignedUsers"
            defaultValue={selectedAssignedUsers}
            options={eligibleUsers}
            value={selectedAssignedUsers}
            getOptionLabel={(option) => getUserName(option)}
            onChange={(event, newValue) => {
              setSelectedAssignedUsers(newValue);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Locations.AssignUser")}
                margin="normal"
              />
            )}
          />
        </Grid>
      )}
      {eligibleUsers && (
        <Grid item>
          <Autocomplete
            multiple
            id="selected_preferredUsers"
            defaultValue={selectedPreferredUsers}
            options={eligibleUsers}
            value={selectedPreferredUsers}
            getOptionLabel={(option) => getUserName(option)}
            onChange={(event, newValue) => {
              setSelectedPreferredUsers(newValue);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Locations.PreferUser")}
                margin="normal"
              />
            )}
          />
        </Grid>
      )}
    </AddEditDialog>
  );
};
