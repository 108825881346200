import { tryGet } from "../common";
import { ROLES } from "../constants";

export const UserRolesUtil = (user) => {
  return {
    hasRoles: (givenRoles) => {
      return tryGet(() => user.roles, []).some(r => givenRoles.includes(r));
    },

    hasRole: (role) => {
      return tryGet(() => user.roles, []).some(r => r === role);
    },

    isHRAdminOnly: () => {
      return tryGet(() => user.roles, []).length === 1 && user.roles.includes(ROLES.HR_ADMIN);
    },

    isRootUser: () => {
      return tryGet(() => user.roles, []).some(r => r === ROLES.ROOT);
    },

    isAdminUser: () => {
      return tryGet(() => user.roles, []).some(r => r === ROLES.ADMIN);
    }
  };
};