import React, { useState } from "react";
import {
  Typography,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useHiveApiService } from "../../services/useHiveApiService";
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import classnames from "classnames";
import { grey } from "@material-ui/core/colors";
import addDays from 'date-fns/addDays';
import { useCompanyService } from "../../services/useCompanyService";
import { useDispatch } from "react-redux";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  wrapper: {
    margin: "20px 0"
  },
  card: {
    maxHeight: "70vh",
    display: "grid",
    "grid-template-rows": "1fr 95px"
  },
  panelContent: {
    maxHeight: "70vh",
    overflow: "auto"
  },
  table: {
    paddingTop: 0,
    paddingBottom: 0
  },
  requestParams: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1.5fr",
    columnGap: "20px",
    rowGap: "20px",
    alignItems: "center"
  },
  exportParams: {
    position: "sticky",
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gridColumnGap: "20px",
    alignItems: "center",
    padding: "20px"
  },
  "@media print": {
    requestParams: {
      display: "none"
    },
    exportParams: {
      display: "none"
    }
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  selectButtonContainer: {
    maxWidth: "960px",
    margin: "20px 0",
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr",
    gridGap: "20px",
    "&>div": {
      display: "flex",
      "&>button": {
        marginRight: "13px"
      }
    },
    "&>div:last-of-type": {
      justifyContent: "flex-end",
      "&>button:last-of-type": {
        marginRight: "0"
      }
    }
  },
  selected: {
    backgroundColor: "#efe8d0"
  },
  nowrap: {
    whiteSpace: "nowrap"
  }
}));

export default function ActiveUsers({ company }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const hiveApi = useHiveApiService();
  const companyService = useCompanyService();
  const dispatch = useDispatch();

  const [activeUsers, setActiveUsers] = useState([]);
  const [startingDate, setStartingDate] = useState(addDays(new Date(), -7)); //Default: 1 Week ago
  const [endingDate, setEndingDate] = useState(new Date());
  const [email, setEmail] = useState("");
  const [maxActiveUsers, setMaxActiveUsers] = useState(0);
  const [loadingView, setLoadingView] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const handleRetrieveActiveUsers = async () => {
    const startDate = moment(startingDate).format("YYYY/MM/DD");
    const endDate = moment(endingDate).format("YYYY/MM/DD");

    const requestBody = {
      data: {
        startDate: startDate,
        endDate: endDate
      }
    };
    setLoadingView(true);
    await hiveApi.getActiveUsers(requestBody).then((response) => {
      if (response.success) {
        setActiveUsers(response.data.eventData);
      } else {
        dispatch(setErrorMessage(t("Errors.General")));
      }
    });
    setLoadingView(false);
  };

  return (
    <>
      <Typography variant="h3">{company.companyName}</Typography>
      <Typography variant="h4">{t("Reports.ActiveUsersReport")}</Typography>
      <div
        className={classnames({
          [classes.requestParams]: true,
          [classes.wrapper]: true
        })}
      >
        <KeyboardDatePicker
          disableToolbar
          inputVariant="outlined"
          format="MM/dd/yyyy"
          label={t("Commons.StartDate")}
          name="startingDate"
          maxDate={endingDate}
          value={startingDate}
          onChange={(date) => {
            setStartingDate(date);
          }}
        />
        <KeyboardDatePicker
          disableToolbar
          inputVariant="outlined"
          format="MM/dd/yyyy"
          label={t("Commons.EndDate")}
          name="endingDate"
          minDate={startingDate}
          value={endingDate}
          onChange={(date) => {
            setEndingDate(date);
          }}
        />
        <Button
          onClick={() => {
            handleRetrieveActiveUsers();
          }}
          disabled={loadingView}
          variant="contained"
          color="primary"
        >
          {t("Reports.ViewReport")}
          {loadingView && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </div>
      <Typography variant="h5">
        {t("Reports.MaxActiveUsers")} {maxActiveUsers}{" "}
      </Typography>
      <Card className={classes.card}>
        <div className={classes.panelContent}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label={t("Reports.ActiveUsersReport")}
            >
              <TableHead>
                <TableRow>
                  <TableCell>{t("Commons.Date")}</TableCell>
                  <TableCell>{t("Commons.ActiveUsers")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeUsers.length > 0 ? (
                  activeUsers.map((events, index) => (
                    <TableRow key={events[0]}>
                      {events[1]["activeUsers"] > maxActiveUsers &&
                        setMaxActiveUsers(events[1]["activeUsers"])}
                      <TableCell className={classes.nowrap}>
                        {" "}
                        {events[1]["countDate"]}{" "}
                      </TableCell>
                      <TableCell className={classes.nowrap}>
                        {" "}
                        {events[1]["activeUsers"]}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align={"center"} colSpan={5}>
                      {t("Reports.NoActiveUsersFound")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {activeUsers.length > 0 && (
          <div className={classes.exportParams}>
            <TextField
              className={classes.textField}
              variant="outlined"
              label={t("Commons.Email")}
              name="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={!email || loadingExport}
              onClick={() => {
                setLoadingExport(true);
                companyService
                  .sendActiveUsersReportEmail(
                    startingDate,
                    endingDate,
                    email,
                    activeUsers,
                    company.companyName
                  )
                  .then(() => {
                    dispatch(
                      setSuccessMessage(
                        t("Success.EmailReport", { email: email })
                      )
                    );
                    setLoadingExport(false);
                    setEmail("");
                  });
              }}
            >
              {t("Reports.ExportReport")}
              {loadingExport && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </div>
        )}
      </Card>
    </>
  );
}
