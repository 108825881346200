import { MAILING_LIST_ACTIONS as ACTIONS } from "../../constants";
import useMailingListEditorReducer from "./useMailingListEditorReducer";

export default function useMailingListEditorFormState(mailingLists) {
  const { lists, dispatch } = useMailingListEditorReducer(mailingLists);

  const addMailingList = () => dispatch({ type: ACTIONS.ADD_MAILING_LIST });

  const removeMailingList = (index) =>
    dispatch({ type: ACTIONS.REMOVE_MAILING_LIST, payload: { index } });

  const onUserGroupChange = (index, userGroupId) =>
    dispatch({
      type: ACTIONS.CHANGE_USER_GROUP,
      payload: { index, userGroupId }
    });

  const onUsersByUserGroupChange = (newValue, index) =>
    dispatch({
      type: ACTIONS.CHANGE_USERS_BY_USER_GROUP,
      payload: { index, newValue }
    });

  return {
    addMailingList,
    removeMailingList,
    onUserGroupChange,
    onUsersByUserGroupChange,
    lists
  };
}
