import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, makeStyles, Tooltip, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
    gap: "1rem"
  },
  header: {
    display: "flex",
    gap: "1rem",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  field: {
    display: "flex",
    flexDirection: "column",
    width: "50%"
  },
  checkBoxes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: "1.5rem"
  }
});

const OptionalFields = ({ optionalFields, onFieldChange }) => {

  const classes = useStyles();
  const { t } = useTranslation();

  const handleOptionalFieldChange = (fieldName, optionName, value) => {
    const _optionalFields = {...optionalFields};
    _optionalFields[fieldName][optionName] = value;
    if (optionName === "display" && !value) {
      _optionalFields[fieldName]["required"] = false;
    }
    onFieldChange("optionalFields", _optionalFields);
  };

  const renderCheckBoxes = (values, fieldName) => {
    return (
      <div className={classes.checkBoxes}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={values.display}
              onChange={(event) => handleOptionalFieldChange(fieldName, "display", event.target.checked)}
            />
          }
          label={t("Gate.Display")}
          className={classes.checkBox}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={values.required}
              disabled={!values.display}
              onChange={(event) => handleOptionalFieldChange(fieldName, "required", event.target.checked)}
            />
          }
          label={t("Gate.Required")}
          className={classes.checkBox}
        />
      </div>
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant="h5">{t("Gate.OptionalFields")}</Typography>
        <Tooltip title={t("Gate.V2Feature")} placement="top" arrow>
          <InfoIcon color="disabled"/>
        </Tooltip>
      </div>
      <div className={classes.body}>
        <div className={classes.field}>
          <Typography>{t("Gate.Phone_")}</Typography>
          {renderCheckBoxes(optionalFields.phone, "phone")}
        </div>
        <div className={classes.field}>
          <Typography>{t("Gate.Company_")}</Typography>
          {renderCheckBoxes(optionalFields.company, "company")}
        </div>
      </div>
    </div>
  );
};

export default OptionalFields;