import { useReducer } from "react";
import {
  MAILING_LIST_ACTIONS as ACTIONS,
  EMPTY_MAILING_LIST
} from "../../constants";

export default function useMailingListEditorReducer(mailingLists) {
  const removeMailingListReducer = (lists, action) =>
    lists.filter((_, index) => index !== action.payload.index);

  const changeUserGroupReducer = (lists, action) =>
    lists.map((list, index) => {
      const { index: payloadIndex, userGroupId } = action.payload;
      return index === payloadIndex
        ? { ...list, mailingListEmails: [], userGroupId }
        : list;
    });

  const changeUsersByUserGroupReducer = (lists, action) =>
    lists.map((list, index) => {
      const { index: payloadIndex, newValue } = action.payload;
      return index === payloadIndex
        ? { ...list, mailingListEmails: newValue }
        : list;
    });

  const reducer = (lists, action) => {
    switch (action.type) {
      case ACTIONS.ADD_MAILING_LIST:
        return [...lists, EMPTY_MAILING_LIST];
      case ACTIONS.REMOVE_MAILING_LIST:
        return removeMailingListReducer(lists, action);
      case ACTIONS.CHANGE_USER_GROUP:
        return changeUserGroupReducer(lists, action);
      case ACTIONS.CHANGE_USERS_BY_USER_GROUP:
        return changeUsersByUserGroupReducer(lists, action);
    }
  };

  const [lists, dispatch] = useReducer(reducer, mailingLists);

  return { lists, dispatch };
}
