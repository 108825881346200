// React & external
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

// Material UI
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Project components
import AddEditDialog from "../../../components/AddEditDialog/AddEditDialog";
import { useCompanyService } from "../../../services/useCompanyService";
import { isEmpty } from "../../../common";


export const EditDevice = ({
  editDevice: device,
  profileMap,
  close,
  createMode
}) => {
  const companyService = useCompanyService();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editDevice, setEditDevice] = useState(device);
  const [t, i18n] = useTranslation();

  const save = async () => {
    setLoading(true);
    const required = ["name", "uuid"];

    // validate fields
    for (let field of required) {
      if (isEmpty(editDevice[field]?.trim())) {
        setErrorMessage(t('Errors.RequiredFields'));
        setLoading(false);
        return;
      }
    }

    let result;
    if (createMode) {
      // create device
      result = await companyService.createDevice(editDevice);
    } else {
      // update device
      const updates = {
        name: editDevice["name"],
        profileId: editDevice["profileId"]
      };

      result = await companyService.updateDevice(editDevice.deviceId, updates);
    }

    if (result.data.success) {
      if (createMode) {
        editDevice.deviceId = result.data.message;
      }
      close(editDevice, createMode);
    } else {
      const path = "Errors.Cloud." + result.data.message;
      const error = i18n.exists(path) ? t(path) : "";

      setErrorMessage(
        (createMode ? t("Errors.UpdateDevice") : t("Errors.CreateDevice")) + (error ? " " + error : "")
      );
    }
    setLoading(false);
  };

  const onFieldChange = (field, value) => {
    let _editDevice = { ...editDevice };
    _editDevice[field] = value;
    setEditDevice(_editDevice);
  };

  return (
    <AddEditDialog
      createMode={createMode}
      title={t("Gate.Device")}
      onSave={save}
      onCancel={close}
      loading={loading}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <Grid item sm={12}>
        <TextField
          variant="outlined"
          label={t("Gate.DeviceName")}
          value={editDevice.name}
          fullWidth
          required
          onChange={(event) => onFieldChange("name", event.target.value)}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          disabled={!createMode}
          variant="outlined"
          label={t("Gate.UUID")}
          value={editDevice.uuid}
          fullWidth
          required
          onChange={(event) => {
            const value = event.target.value;
            const uuid = value ? value.toLowerCase().trim() : value;
            onFieldChange("uuid", uuid);
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <Autocomplete
          id="profileId"
          filterSelectedOptions
          defaultValue={
            profileMap[
              isEmpty(editDevice.profileId) ? "" : editDevice.profileId
            ]
          }
          options={Object.values(profileMap)}
          getOptionLabel={(option) => option.displayName}
          onChange={(event, newValue) => {
            onFieldChange("profileId", newValue ? newValue.profileId : "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Gate.TypeAddProfile")}
              variant="outlined"
            />
          )}
        />
      </Grid>
    </AddEditDialog>
  );
};
