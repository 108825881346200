import React, { useState, useEffect } from "react";
import DateTimeUtil, { HH_MM_AMPM } from "../../utils/DateTimeUtil";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setSuccessMessage, setErrorMessage, setWarningMessage } from "../../actions/index";
import { Container, Typography } from "@material-ui/core";
import { BOOKING_STATES } from "../../constants";
import { isEmpty, isNullOrUndefined } from "../../common";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useCompanyService } from "../../services/useCompanyService";
import { useLocalStore } from "../../hooks/useLocalStore";
import DataTable from "../../components/DataTable/DataTable";
import FilterSection from "./FilterSection";
import ActionButtons from "./ActionButtons";
import { CollectEmail } from "./CollectEmail";
import HealthScreeningModal from '../common/HealthScreeningModal';

function formatAsDateTime(appointment) {
  const time = appointment.time;
  if (!isNullOrUndefined(time.startTime) && !isNullOrUndefined(time.timeZone)) {
    const startTime = DateTimeUtil.getFormatedZonedTime(
      time.startTime,
      time.timeZone.region,
      HH_MM_AMPM
    );
    if (!isNullOrUndefined(time.endTime)) {
      const endTime = DateTimeUtil.getFormatedZonedTime(
        time.endTime,
        time.timeZone.region,
        HH_MM_AMPM
      );
      return `From ${startTime} to ${endTime}`;
    }
    return startTime;
  }
  return "---";
}

const appointmentsTableStructure = [
  {
    columnNamekey: "Appointments.ResourceName",
    fieldName: "displayName",
    multiLanguage: true
  },
  {
    columnNamekey: "Appointments.AppointmentDate",
    fieldName: "date"
  },
  {
    columnNamekey: "Appointments.TimeLabel",
    fieldName: "time",
    customContentFormatter: formatAsDateTime
  },
  {
    columnNamekey: "Appointments.AppointmentTypes",
    fieldName: "type",
    multiLanguage: true
  },
  {
    columnNamekey: "Appointments.VisitorName",
    fieldName: "visitorName"
  },
  {
    columnNamekey: "Appointments.AppointmentStatus",
    fieldName: "state",
    fieldNameIsKey: true
  }
];

const useStyles = makeStyles(() => ({
  container: {
    height: "100%"
  },
  title: {
    paddingBottom: "45px"
  },
  filterSection: {
    marginBottom: "45px"
  }
}));

export default function AppointmentManagement() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const localStore = useLocalStore();
  const companyService = useCompanyService();
  const [filters, setFilters] = useState({});
  const [loadingView, setLoadingView] = useState(false);
  const [queryResults, setQueryResults] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkingIn, setCheckingIn] = useState(false);
  const [selectedBookingIds, setSelectedBookingIds] = useState([]);
  const [showQuestions, setShowQuestions] = useState(false);

  const [questionnaire, setQuestionnaire] = useState([]);
  const [questionSetId, setQuestionSetId] = useState("");

  const [visitorSearchValue, setVisitorSearchValue] = useState("");
  const [displayEmailCollector, setDisplayEmailCollector] = useState(false);
  const [anySelectedAppointmentsArePast, setAnySelectedAppointmentsArePast] = useState(false);
  const [locationsList, setLocationsList] = useState([]);

  const currentUser = localStore.getCurrentUser();
  const company = localStore.getCompany();

  useEffect(() => {
    if (!isNullOrUndefined(location.state?.filters)) {
      const _filters = {...location.state.filters};
      setFilters(_filters);
      delete location.state.filters;
      getAppointments(_filters);
    }
    
    isEmpty(locationsList) &&
    setLocationsList(companyService.getLocationsFromDropdownListCollection());
  }, []);

  useEffect(() => {
    const newList = selectedRows.map((r) => appointments[r].bookingId);
    setSelectedBookingIds(newList);
  }, [selectedRows]);

  useEffect(() => {
    const newAppointmentList = queryResults.filter(a => a.visitorName.toLowerCase().includes(visitorSearchValue.toLowerCase()));
    setAppointments(newAppointmentList);
    setSelectedRows([]);
  }, [filters, visitorSearchValue]);
  
  useEffect(() => {
    const selectedAppointments = selectedBookingIds.map((id) => {
      return appointments.find((appt) => appt.bookingId === id);
    });

    setAnySelectedAppointmentsArePast(selectedAppointments.some((booking) => {
      return booking.time.endTime.toDate() < new Date();
    }));
  }, [appointments, selectedBookingIds]);

  const hasAnyValidFilter = (obj) => {
    if(isNullOrUndefined(obj)){
      return false;
    }

    const filterList = ["resource", "status", "startingAppointmentDate", "endingAppointmentDate"];
    return Object.keys(obj).some(attribute => filterList.includes(attribute));
  };

  const getAppointments = async (input) => {
    setLoadingView(true);
    const _filters = hasAnyValidFilter(input || {}) ? input : filters;
    const appointmentData = await companyService.getAppointments(_filters);
    const _appointments = [];
    appointmentData.map((data) =>
      _appointments.push({
        displayName: getAttributeFromResource(data.appointmentDetails.resources, "displayName"),
        description: getAttributeFromResource(data.appointmentDetails.resources, "description"),
        visitorName: getVisitorName(data),
        date: data.date.toDate().toDateString(),
        bookingId: data.bookingId,
        state: data.state,
        userId: data.userId,
        username: data.username,
        time: {
          startTime: data.startTime,
          endTime: data.endTime,
          timeZone: data.timeZone
        },
        type: data.appointmentDetails.appointmentType.displayName
      })
    );
    setQueryResults(_appointments);
    setAppointments(_appointments);
    setLoadingView(false);
  };

  const getVisitorName = (booking) => {
    const firstName = booking?.visitor?.firstName;
    const lastName = booking?.visitor?.lastName;

    if(!isEmpty(firstName)){
      if(!isEmpty(lastName)){
        return `${firstName} ${lastName}`;
      }
      return firstName;
    } else if(!isEmpty(lastName)){
      return lastName;
    }

    return "-- // --";
  };

  const getAttributeFromResource = (resource, attribute) => {
    const resourceIds = Object.keys(resource);
    return resource[resourceIds[0]][attribute];
  };

  const refreshContent = () => {
    setSelectedRows([]);
    getAppointments();
  };

  const getAppointmentFromLocal = (bookingId) => {
    return appointments.filter(a => a.bookingId === bookingId)[0];
  };

  const openEmailCollector = () => {
    setDisplayEmailCollector(true);
  };

  const exportTableContent = (email) => {
    setLoadingView(true);
    companyService.exportAppointmentsReport(filters, email)
      .then(() => {
        dispatch(
          setSuccessMessage(t('Success.EmailReport', { email: email }))
        );
        setLoadingView(false);
      });
  };

  const toggleAllSelected = () => {
    const newSelectedRows = [];
    if (!isAllSelected()) {
      appointments.map((a, index) => newSelectedRows.push(index));
    }
    setSelectedRows(newSelectedRows);
  };

  const cancelMultipleRows = callOnSelectedAppointments(companyService.deleteBooking, {
    success: t("Success.DeleteAppointment"),
    error: t("Errors.DeleteAppointment")
  }
  );

  const checkOutMultipleRows = callOnSelectedAppointments(companyService.checkOutOfBooking, {
    success: t("Success.CheckedOutAppointments"),
    error: t("Errors.CheckedOutAppointments")
  }
  );

  const checkInAppointment = async () => {
    // validation
    const errMsg = t("Errors.Selection");
    if (selectedBookingIds.length !== 1) {
      dispatch(setErrorMessage(errMsg));
      return;
    }

    const appointment = getAppointmentFromLocal(selectedBookingIds[0]);

    if (isEmpty(appointment)) {
      dispatch(setErrorMessage(errMsg));
      return;
    } else if (
      appointment.state !== BOOKING_STATES.CONFIRMED &&
      appointment.state !== BOOKING_STATES.INVITED
    ) {
      dispatch(
        setErrorMessage(t("Errors.CheckIn", { state: appointment.state }))
      );
      return;
    }

    setCheckingIn(true);

    // questions
    let _questionSet = await companyService.getQuestionsForBooking(
      appointment.bookingId,
      appointment.userId
    );
    if (_questionSet?.data?.success && !isEmpty(_questionSet.data.questionnaire)) {
      setQuestionnaire(_questionSet.data.questionnaire);
      setQuestionSetId(_questionSet.data.id);

      if (_questionSet.data.isAnsweredAlready) {
        const response = await companyService.checkIntoBooking(
          appointment.bookingId,
          currentUser.email,
          _questionSet.data.id,
          false
        );
        handleResponse(response);
        return;
      } else {
        setShowQuestions(true);
        return;
      }
    } else {
      handleResponse(_questionSet);
      return;
    }
  };

  const handleResponse = (response) => {
    if (response.data.success) {
      const path = "Success.Cloud." + response.data.message;
      const success = i18n.exists(path) ? t(path) : t("Success.General");
      dispatch(setSuccessMessage(success));
      refreshContent();
    } else {
      const path = "Errors.Cloud." + response.data.message;
      const error = i18n.exists(path) ? t(path) : t("Errors.General");
      dispatch(setErrorMessage(error));
      console.log("error", response.data.message);
    }
  };

  function callOnSelectedAppointments(ajaxMethod, messages) {
    return () => {
      const cancels = selectedBookingIds.map((bookingId) =>
        ajaxMethod(bookingId)
      );
      Promise.all(cancels)
        .then((response) => {
          if (response[0].data.success) {
            dispatch(setSuccessMessage(messages.success));
            refreshContent();
          } else {
            dispatch(setErrorMessage(messages.error));
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(setErrorMessage(messages.error));
        });
    };
  }

  const isAllSelected = () => {
    return (
      appointments.length > 0 && appointments.length === selectedRows.length
    );
  };

  const handleQuestionSubmit = async (answers, questionSetId) => {
    const appointment = getAppointmentFromLocal(selectedBookingIds[0]);

    const isAppointment = true;
    const healthScreeningResponse = await companyService.submitHealthScreening(
      appointment,
      questionSetId,
      answers,
      isAppointment
    );

    if (!healthScreeningResponse.data.pass) {
      const response = await companyService.sendHealthNotificationAdmin(
        appointment,
        company,
        questionSetId
      );
      handleQuestionnaireFailureResponse(response);
    } else {
      const response = await companyService.checkIntoBooking(
        selectedBookingIds[0],
        currentUser.email,
        questionSetId,
        true
      );
      handleResponse(response);
    }
    setShowQuestions(false);
  };

  const handleQuestionnaireFailureResponse = (response) => {
    if (response.data.success) {
      dispatch(setWarningMessage(t("Questionnaire.Notified")));
      refreshContent();
    } else {
      const path = "Errors.Cloud." + response.data.message;
      const error = i18n.exists(path)
        ? t(path)
        : t("Errors.SubmittingQuestionnaire");
      dispatch(setErrorMessage(error));
      console.log("error", response.data.message);
    }
  };

  return (
    <div className="content">
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h3" className={classes.title}>
          {t("Appointments.AppointmentManagement")}
        </Typography>
        <FilterSection
          company={company}
          locationsList={locationsList}
          filterSet = {{values: filters, setter: setFilters}}
          filterSetter={setFilters}
          searchFunc={getAppointments}
          loading={loadingView}
        />
        <ActionButtons
          loading={loadingView}
          checkingIn={checkingIn}
          tableContent={{ rows: appointments, selectedRows: selectedRows }}
          searchString={{ value: visitorSearchValue, setter: setVisitorSearchValue }}
          functions={{
            selectAllFunc: toggleAllSelected,
            cancelFunc: cancelMultipleRows,
            checkInFunc: checkInAppointment,
            checkOutFunc: checkOutMultipleRows,
            exportFunc: openEmailCollector
          }}
          disableOptions={{
            disableCancel: anySelectedAppointmentsArePast,
            disableCheckIn: anySelectedAppointmentsArePast
          }}
        />
        <DataTable
          tableStructure={appointmentsTableStructure}
          tableContent={[...appointments]}
          selectedRows={{ value: selectedRows, setter: setSelectedRows }}
        />
      </Container>
      {showQuestions && !isEmpty(questionnaire) && (
        <HealthScreeningModal
          title={t("Booking.HealthScreening")}
          questionnaire={questionnaire}
          questionSetId={questionSetId}
          handleCancel={() => {
            setShowQuestions(false);
          }}
          handleSubmit={handleQuestionSubmit}
        />
      )}
      <CollectEmail display={displayEmailCollector} close={setDisplayEmailCollector} save={exportTableContent} />
    </div>
  );
}
