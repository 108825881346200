export const ROLES = Object.freeze({
  ROOT: "ROOT",
  ADMIN: "ADMIN",
  IT_ADMIN: "IT_ADMIN",
  LOCATION_ADMIN: "LOCATION_ADMIN",
  USER_GROUP_ADMIN: "USER_GROUP_ADMIN",
  VISITOR_ADMIN: "VISITOR_ADMIN",
  CASE_ADMIN: "CASE_ADMIN",
  CLEANER: "CLEANER",
  APPOINTMENT_ADMIN: "APPOINTMENT_ADMIN",
  HR_ADMIN: "HR_ADMIN",
  EVENT_ADMIN: "EVENT_ADMIN",
  EVENT_TEAM: "EVENT_TEAM"
});

export const ACTIONS_ROLES = Object.freeze({
  ACCESS_SIGNIN_OVERRIDE: [ROLES.ROOT],
  ACCESS_DASHBOARD: [ROLES.ADMIN],
  ACCESS_COMPANY_INFO: [ROLES.ADMIN, ROLES.IT_ADMIN],
  ACCESS_LOCATIONS: [ROLES.ADMIN, ROLES.LOCATION_ADMIN],
  ACCESS_CASE_MANAGEMENT: [
    ROLES.ROOT,
    ROLES.ADMIN,
    ROLES.CASE_ADMIN,
    ROLES.USER_GROUP_ADMIN
  ],
  ACCESS_GATE: [ROLES.ROOT, ROLES.ADMIN],
  ACCESS_LOCATION_MAP: [ROLES.ROOT],
  ACCESS_LOCATION_MAP_PLUS: [ROLES.ROOT],
  ACCESS_MIGRATION: [ROLES.ROOT],
  ACCESS_RESOURCES: [ROLES.ROOT, ROLES.ADMIN],
  ACCESS_APPOINTMENTS: [ROLES.ROOT, ROLES.ADMIN, ROLES.APPOINTMENT_ADMIN],
  ACCESS_USERS: [ROLES.ADMIN, ROLES.USER_GROUP_ADMIN, ROLES.HR_ADMIN],
  ACCESS_USER_GROUPS: [ROLES.ADMIN, ROLES.USER_GROUP_ADMIN],
  ACCESS_REPORTS: [
    ROLES.ADMIN,
    ROLES.LOCATION_ADMIN,
    ROLES.USER_GROUP_ADMIN,
    ROLES.HR_ADMIN
  ],
  ACCESS_BOOKING: [ROLES.ADMIN, ROLES.LOCATION_ADMIN, ROLES.USER_GROUP_ADMIN],
  ACCESS_VISITORS: [ROLES.ADMIN, ROLES.VISITOR_ADMIN],
  ACCESS_IMPORTS: [ROLES.ROOT],
  ACCESS_QUESTIONS: [ROLES.ADMIN],
  ACCESS_NOTIFICATIONS: [ROLES.ROOT, ROLES.ADMIN],
  ACCESS_EVENTS: [ROLES.ROOT, ROLES.EVENT_ADMIN],
  ACCESS_FACILITY_MANAGEMENT: [ROLES.ROOT, ROLES.EVENT_ADMIN],
  SIGNIN_OVERRIDE: [
    ROLES.ADMIN,
    ROLES.IT_ADMIN,
    ROLES.LOCATION_ADMIN,
    ROLES.USER_GROUP_ADMIN,
    ROLES.VISITOR_ADMIN,
    ROLES.CASE_ADMIN,
    ROLES.APPOINTMENT_ADMIN,
    ROLES.HR_ADMIN
  ],
  EDIT_EVENT: [ROLES.ROOT, ROLES.EVENT_ADMIN]
});

export const PATHS = Object.freeze({
  ROOT: "/",
  SIGNUP: "/signup",
  SIGNIN: "/signin",
  SIGNIN_SSO: "/signin/sso",
  SIGNIN_OVERRIDE: "/signin-override",
  SIGNIN_FORGOT_PASSWORD: "/signin/forgot-password",
  VERIFY_EMAIL: "/verify-email",
  DASHBOARD: "/dashboard",
  COMPANY_INFO: "/company-info",
  LOCATIONS: "/locations",
  CASE_MANAGEMENT: "/case-management",
  GATE: "/gate",
  LOCATIONS_MAP: "/locationMap",
  LOCATIONS_MAP_PLUS: "/locationMapPlus",
  MIGRATION: "/migration",
  RESOURCES: "/resources",
  APPOINTMENTS: "/appointments",
  USERS: "/users",
  USER_GROUPS: "/user-groups",
  EVENTS: "/events",
  EVENT_DETAILS: "/events/:id",
  NEW_EVENT: "/events/new",
  REPORTS: "/reports",
  BOOKING: "/booking",
  VISITORS: "/visitors",
  IMPORTS: "/imports",
  QUESTIONS: "/questions",
  NOTIFICATIONS: "/notifications",
  FACILITY_MANAGER: "/facility-manager",
  HEARTBEAT: "/heartbeat"
});

export const USER_STATUS = Object.freeze({
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  LOCKED: "LOCKED"
});

export const RESOURCE_STATES = Object.freeze(["ACTIVE", "INACTIVE"]);

export const RESOURCE_BASE_TIME = Object.freeze(15);

export const TIME_UNIT_OPTIONS = Object.freeze([
  RESOURCE_BASE_TIME,
  RESOURCE_BASE_TIME * 2,
  RESOURCE_BASE_TIME * 3,
  RESOURCE_BASE_TIME * 4
]);

export const WEEK_DAYS = Object.freeze([
  { name: "Sunday", key: "WeekDays.SUN" },
  { name: "Monday", key: "WeekDays.MON" },
  { name: "Tuesday", key: "WeekDays.TUE" },
  { name: "Wednesday", key: "WeekDays.WED" },
  { name: "Thursday", key: "WeekDays.THU" },
  { name: "Friday", key: "WeekDays.FRI" },
  { name: "Saturday", key: "WeekDays.SAT" }
]);

export const CASE_STATUS = Object.freeze({
  OPEN: "OPEN",
  IN_PROGRESS: "IN_PROGRESS",
  DISMISSED: "DISMISSED",
  CLOSED: "CLOSED"
});

export const CASE_TYPE = Object.freeze({
  MANUALLY_OPENED: "MANUALLY_OPENED",
  FAILED_HEALTH_SCREENING: "FAILED_HEALTH_SCREENING"
});

export const BOOKING_STATES = Object.freeze({
  PENDING_APPROVAL: "PENDING_APPROVAL",
  DENIED_APPROVAL: "DENIED_APPROVAL",
  INVITED: "INVITED",
  CONFIRMED: "CONFIRMED",
  CHECKED_IN: "CHECKED_IN",
  CHECKED_OUT: "CHECKED_OUT",
  CANCELED: "CANCELED"
});

export const APPOINTMENT_STATES = Object.freeze({
  PENDING_APPROVAL: "PENDING_APPROVAL",
  DENIED_APPROVAL: "DENIED_APPROVAL",
  INVITED: "INVITED",
  CONFIRMED: "CONFIRMED",
  CHECKED_IN: "CHECKED_IN",
  CHECKED_OUT: "CHECKED_OUT"
});

export const ATTENDEE_STATES = Object.freeze({
  INVITED: "INVITED",
  CONFIRMED: "CONFIRMED",
  DECLINED: "DECLINED",
  PENDING: "PENDING"
});

export const BOOKING_EVENTS = Object.freeze({
  CREATION: "CREATION",
  STATE_CHANGE: "STATE_CHANGE"
});

export const LOCATION_STATES = Object.freeze({
  CLEANED: "CLEANED",
  NOT_CLEANED: "NOT_CLEANED"
});

export const HEALTH_SCREENING_STATES = Object.freeze({
  PASSED: true,
  FAILED: false
});

export const URL_NAMES = Object.freeze({
  hiveIdentity: "hiveIdentity",
  profileUrl: "profileUrl",
  profileUrlV2: "profileUrlV2"
});

export const HIVE_GATE_VERSIONS = Object.freeze({
  V1: "1",
  V2: "2"
});

export const COMPANY_PREFERENCES = Object.freeze({
  enableDropIn: "enableDropIn",
  cleaningEnabled: "cleaningEnabled",
  enableCustomShifts: "enableCustomShifts",
  showOccupancy: "showOccupancy",
  bookingsEnabled: "bookingsEnabled",
  assignedLocationExclusive: "assignedLocationExclusive",
  adjacentLocationBlocking: "adjacentLocationBlocking",
  visitorsEnabled: "visitorsEnabled",
  meetingsEnabled: "meetingsEnabled",
  hiveGateService: "hiveGateService",
  userGroupManagedPassword: "userGroupManagedPassword",
  enableCaseManagement: "enableCaseManagement",
  enableScheduledNotifications: "enableScheduledNotifications",
  showVaccinatedField: "showVaccinatedField",
  showAccessPolicyField: "showAccessPolicyField",
  enableResourceManagement: "enableResourceManagement",
  maxAdvanceReservationDays: "maxAdvanceReservationDays",
  allowDirtySeatBooking: "allowDirtySeatBooking",
  allowDirtySeatCheckIn: "allowDirtySeatCheckIn",
  storeHealthScreeningAnswers: "storeHealthScreeningAnswers",
  enableBookingApproval: "enableBookingApproval",
  locationCleanBuffer: "locationCleanBuffer",
  autoCheckoutBuffer: "autoCheckoutBuffer",
  autoReleaseBuffer: "autoReleaseBuffer",
  enableSocial: "enableSocial"
});

export const RECIPIENT_CONDITIONS = Object.freeze({
  MANUAL_SELECT: "MANUAL_SELECT",
  CHECKED_IN_TODAY: "CHECKED_IN_TODAY",
  CONFIRMED_TODAY: "CONFIRMED_TODAY",
  ANY_STATE_TODAY: "ANY_STATE_TODAY"
});

export const FIRESTORE = Object.freeze({
  WHERE_IN_ARRAY_LIMIT: 10
});

export const TIME = Object.freeze({
  millisecondsPerDay: 1000 * 60 * 60 * 24
});

export const STYLE = Object.freeze({
  fontFamily: "Roboto, sans-serif"
});

export const HIVE_REGIONS = Object.freeze({
  US: "us-east1",
  CANADA: "northamerica-northeast1"
});

export const LOCATION_TAGS_ACTIONS = Object.freeze({
  ADD_TAG: "add-tag",
  REMOVE_TAG: "remove-tag",
  CHANGE_TAG_NAME: "change-tag-name",
  CHANGE_TAG_COLOR: "change-tag-color"
});

export const TAG_COLORS = Object.freeze([
  "#b4b4b8",
  "#ee7a70",
  "#f6bb5c",
  "#fce564",
  "#91fa87",
  "#5ba4f8",
  "#ca8af8"
]);

export const EMPTY_LOCATION_TAG = Object.freeze({
  displayName: { en: "", fr: "" },
  color: TAG_COLORS[0]
});

export const EMPTY_MAILING_LIST = Object.freeze({
  userGroupId: "",
  userObjectsByEmail: []
});

export const MAILING_LIST_ACTIONS = Object.freeze({
  ADD_MAILING_LIST: "add-mailing-list",
  REMOVE_MAILING_LIST: "remove-mailing-list",
  CHANGE_USER_GROUP: "change-user-group",
  CHANGE_USERS_BY_USER_GROUP: "change-users-by-user-group"
});

export const CHECK_IN_REASONS_ACTIONS = Object.freeze({
  ADD_REASON: "add-reason",
  CHANGE_REASON_ID: "change-reason-id",
  CHANGE_DISPLAY_NAME: "change-display-name",
  CHANGE_VISITEE_GROUPS: "change-visitee-groups"
});

export const EMPTY_CHECK_IN_REASON = Object.freeze({
  id: "",
  displayName: { en: "", fr: "" }
});
