import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useHiveApiService } from "../../services/useHiveApiService";
import { setErrorMessage, setSuccessMessage } from "../../actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import MatchIcon from "@material-ui/icons/Filter";
import { Button, TextField, CircularProgress } from "@material-ui/core";
import { isEmpty } from "../../common";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: "primary",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  templateMatchingGrid: {
    paddingTop: "5px",
    marginBottom: "10px",
    backgroundColor: "#ddd"
  },
  templateMatchingItems: {
    margin: "6px"
  }
}));

export const TemplateMatching = ({
  location,
  floorMap,
  mapImageProjection,
  templateBound,
  matchImageBound,
  locationPrefix,
  locationSequence,
  setLocationSequence,
  addLocations,
  setAddLocations
}) => {
  const hiveApi = useHiveApiService();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [matchThreshold, setMatchThreshold] = useState(0.65);
  const [matchAngle, setMatchAngle] = useState(30);
  const [isLoading, setIsLoading] = useState(false);

  const padNumber = (num, size) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  };

  const transformMatchedBoundingBox = (data) => {
    const _addLocations = [];
    let _seq = parseInt(locationSequence);
    data.matchedBoundingBoxes.forEach((bbox) => {
      let cx = (bbox[2] - bbox[0]) / 2 + bbox[0];
      let cy = (bbox[3] - bbox[1]) / 2 + bbox[1];
      let latlng = mapImageProjection.transform(cx, cy);
      let seq = padNumber(_seq, 3);
      let _location = {
        displayName: `${locationPrefix}${seq}`,
        point: [cx, cy]
      };

      // Beware that addLocation (inserted) which is not a fully populated Location document
      // it only contains latlng (in geographic coordinate for display) and
      // location info for displayName and point (in image coordinate)
      let _addLocation = {
        latlng: latlng,
        location: _location
      };

      _addLocations.push(_addLocation);
      _seq = _seq + 1;
    });

    if (_addLocations.length > 0) {
      setLocationSequence("" + _seq);
      setAddLocations([...addLocations, ..._addLocations]);
    }
  };

  const handleMatch = async () => {
    const requestBody = {
      data: {
        locationId: location.locationId,
        coordinateLevel: floorMap.floorMapLocation.locationType,
        coordinateLevelName: floorMap.floorMapLocation.displayName,
        matchAngle: Math.round(Number(matchAngle)),
        threshold: Number(matchThreshold),
        templateBoundingBox: templateBound,
        saveMatched: 0
      }
    };

    // if matching image bound is selected, use it as restriction
    if (!isEmpty(matchImageBound)) {
      requestBody.data.imageBoundingBox = matchImageBound;
    }

    setIsLoading(true);
    await hiveApi.templateMatching(requestBody).then((response) => {
      if (response.success) {
        transformMatchedBoundingBox(response.data);
        dispatch(setSuccessMessage(t("Success.General")));
        setIsLoading(false);
      } else {
        dispatch(setErrorMessage(t("Errors.General")));
        setIsLoading(false);
      }
    });
  };

  const displayBound = (bound) => {
    if (!isEmpty(bound)) {
      return `${bound[0]},${bound[1]} ${bound[2]},${bound[3]}`;
    } else {
      return "";
    }
  };

  return (
    <Grid container className={classes.templateMatchingGrid}>
      <Grid item xs={2}>
        <TextField
          value={matchAngle}
          onChange={(event) => setMatchAngle(event.target.value)}
          variant="outlined"
          size="small"
          placeholder={t("Locations.MatchAnglePlaceHolder")}
          label={t("Locations.MatchAngle")}
          className={classes.templateMatchingItems}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          value={matchThreshold}
          onChange={(event) => setMatchThreshold(event.target.value)}
          variant="outlined"
          size="small"
          placeholder={t("Locations.MatchThresholdPlaceHolder")}
          label={t("Locations.MatchThreshold")}
          className={classes.templateMatchingItems}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          value={displayBound(templateBound)}
          disabled
          variant="outlined"
          size="small"
          label={t("Locations.TemplateBound")}
          InputProps={{ readOnly: true }}
          className={classes.templateMatchingItems}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          value={displayBound(matchImageBound)}
          disabled
          variant="outlined"
          size="small"
          label={t("Locations.MatchImageBound")}
          InputProps={{ readOnly: true }}
          className={classes.templateMatchingItems}
        />
      </Grid>
      <Grid item xs={2}>
        <Grid container justify={"flex-end"}>
          <Button
            onClick={handleMatch}
            disabled={isLoading || !templateBound}
            variant="contained"
            color="primary"
            startIcon={<MatchIcon />}
            className={classes.templateMatchingItems}
          >
            {t("Locations.Match")}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress}>
                {" "}
              </CircularProgress>
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
