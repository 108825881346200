import {
  Button,
  Grid,
  Link,
  makeStyles,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { KeyboardDatePicker, TimePicker } from "@material-ui/pickers";
import { format, formatISO } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import LocationSelector from "../../components/Selectors/LocationSelector";
import UserSelector from "../../components/Selectors/UserSelector";
import { useHistory } from "react-router-dom";
import { NotificationsActive } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  eventDetails: {
    padding: "1rem 0",
    display: "flex"
  },
  formItem: {
    width: "100%"
  },
  notifications: { display: "flex", flexDirection: "column" },
  notificationsTitle: { display: "flex", alignItems: "center", gap: "0.5rem" },
  notificationsIcon: { color: theme.palette.primary.main },
  notificationsList: { marginTop: "0.5rem" },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1rem",
    gap: "1rem"
  }
}));

export default function EventDetailsSummaryTab({
  event,
  setEvent,
  editMode,
  createMode,
  allowedToEdit,
  company,
  locationsList,
  usersList,
  onDateChange,
  onSave
}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const transformUser = {
    fromFireStore: (user, list) => {
      return list.find((u) => u.userId === user.userId);
    },
    toFireStore: (user) => {
      return {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        userId: user.userId
      };
    }
  };

  const clearNotification = (index) => {
    const notifications = Array.from(event.notifications);
    notifications.splice(index, 1);
    /* TODO: Implement clear notification function that removes item from db */
    setEvent({ ...event, notifications });
  };

  const renderNotifications = () => {
    return (
      <div className={classes.notificationsList}>
        {event.notifications.length === 0 ? (
          <Typography>{t("Events.EventNoNotificationsMessage")}</Typography>
        ) : (
          event.notifications.map((notification, index) => {
            return allowedToEdit === true ? (
              <Tooltip
                key={index}
                title={t("Events.RemoveNotification")}
                placement="bottom"
              >
                <Typography>
                  <Link
                    color="inherit"
                    underline="hover"
                    style={{ cursor: "pointer" }}
                    href="#"
                    onClick={() => clearNotification(index)}
                  >
                    {notification.message}
                  </Link>
                </Typography>
              </Tooltip>
            ) : (
              <Typography>{notification.message}</Typography>
            );
          })
        )}
      </div>
    );
  };

  return (
    <>
      <div className={classes.eventDetails}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label={t("Events.DisplayName")}
              value={event.displayName}
              onChange={(e) =>
                setEvent({ ...event, displayName: e.target.value })
              }
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={4}>
            <LocationSelector
              contentList={locationsList}
              company={company}
              labelKey={"Events.LocationField"}
              controller={{
                value: event.locationId,
                setter: (val) => setEvent({ ...event, locationId: val })
              }}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={4}>
            <KeyboardDatePicker
              className={classes.formItem}
              disableToolbar
              required
              inputVariant="outlined"
              format="MM/dd/yyyy"
              label={t("Events.Date")}
              name="eventDate"
              value={format(new Date(event.date), "P")}
              onChange={onDateChange}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.eventDetails}>
        <Grid container alignItems="center" justify="center" spacing={3}>
          <Grid item xs={3}>
            <TimePicker
              className={classes.formItem}
              inputVariant="outlined"
              required
              format="hh:mm a"
              label={t("Events.StartsAt")}
              value={new Date(event.startTime)}
              ampm
              onChange={(date) => {
                setEvent({
                  ...event,
                  startTime: formatISO(date)
                });
              }}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={3}>
            <TimePicker
              required
              className={classes.formItem}
              inputVariant="outlined"
              format="hh:mm a"
              label={t("Events.EndsAt")}
              value={new Date(event.endTime)}
              ampm
              onChange={(date) => {
                setEvent({
                  ...event,
                  endTime: formatISO(date)
                });
              }}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={3}>
            <TimePicker
              className={classes.formItem}
              required
              inputVariant="outlined"
              format="hh:mm a"
              label={t("Events.ReservationStartsAt")}
              value={new Date(event.reservationStartTime)}
              ampm
              onChange={(date) => {
                setEvent({
                  ...event,
                  reservationStartTime: formatISO(date)
                });
              }}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={3}>
            <TimePicker
              className={classes.formItem}
              required
              inputVariant="outlined"
              format="hh:mm a"
              label={t("Events.ReservationEndsAt")}
              value={new Date(event.reservationEndTime)}
              ampm
              onChange={(date) => {
                setEvent({
                  ...event,
                  reservationEndTime: formatISO(date)
                });
              }}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.eventDetails}>
        <Grid container alignItems="center" justify="center" spacing={3}>
          <Grid item xs={6}>
            <UserSelector
              className={classes.formItem}
              labelKey={"Events.EventOwnerField"}
              contentList={usersList}
              controller={{
                value: event.owner,
                setter: (val) => setEvent({ ...event, owner: val })
              }}
              transform={transformUser}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={6}>
            <UserSelector
              className={classes.formItem}
              labelKey={"Events.EventOrganizerField"}
              contentList={usersList}
              controller={{
                value: event.organizer,
                setter: (val) => setEvent({ ...event, organizer: val })
              }}
              transform={transformUser}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.eventDetails}>
        <Grid container alignItems="flex-start" justify="center" spacing={3}>
          <Grid item xs={createMode ? 12 : 6}>
            <TextField
              multiline
              fullWidth
              required
              value={event.details}
              onChange={(e) => setEvent({ ...event, details: e.target.value })}
              variant="outlined"
              label={t("Events.Details")}
              rows={3}
              rowsMax={3}
              disabled={!editMode}
            />
          </Grid>
          {!createMode && (
            <Grid item xs={6}>
              <div className={classes.notifications}>
                <div className={classes.notificationsTitle}>
                  <NotificationsActive className={classes.notificationsIcon} />
                  <Typography variant="h5">
                    {t("Events.Notifications")}
                  </Typography>
                </div>

                {renderNotifications()}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.goBack()}
        >
          {t("Commons.Back")}
        </Button>
        {allowedToEdit && (
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={!editMode}
          >
            {t("Commons.Save")}
          </Button>
        )}
      </div>
    </>
  );
}

EventDetailsSummaryTab.propTypes = {
  event: PropTypes.object.isRequired,
  setEvent: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  createMode: PropTypes.bool.isRequired,
  allowedToEdit: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired,
  locationsList: PropTypes.array.isRequired,
  usersList: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};
