import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  Drawer,
  List,
  Button,
  AppBar
} from "@material-ui/core";
import useLeftNavMenuState from "./useLeftNavMenuState";
import { isEmpty } from "../common";
import LeftNavMenuItem from "./LeftNavMenuItem";
import LeftNavMenuItemFoldable from "./LeftNavMenuItemFoldable";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  "@media print": {
    drawer: {
      display: "none"
    }
  },
  drawerLogo: {
    position: "relative"
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.paper,
    backgroundImage: "url(/nav-background.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflow: "auto"
  },
  drawerHeader: {
    width: drawerWidth,
    borderStyle: "none",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 2px 4px -4px rgba(0,0,0,0.7)",
    marginRight: `calc(100% - 259px)`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2,1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  drawerSpacer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2, 1),
    marginBottom: theme.spacing(3.5),
    ...theme.mixins.toolbar
  }
}));

export default function LeftNav({
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const domain = `${window.location.protocol}//${window.location.host}`;
  const { getAuthorizedMenuLinks, updateFoldableMenuState } = useLeftNavMenuState();

  const renderMenuItem = (menuLink, index) => {
    if(isEmpty(menuLink.link)){
      const innerLinks = getAuthorizedMenuLinks(menuLink.subMenuLinks);
      return (
        <LeftNavMenuItemFoldable
          item={menuLink} 
          index={index}
          key={`LeftNavMenuItemFoldable${index}`}
          onClickItem={updateFoldableMenuState}
        >
          {innerLinks.map((menuLink, index) => renderMenuItem(menuLink, index))}
        </LeftNavMenuItemFoldable>
      );
    }
    return (
      <LeftNavMenuItem 
        item={menuLink} 
        index={index}
        key={`LeftNavMenuItem${index}`}
      />
    );
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{ paper: classes.drawerPaper }}
      {...restProps}
    >
      <AppBar elevation={0} className={classes.drawerHeader}>
        <Button component={RouterLink} to="/" className={classes.drawerLogo}>
          <img
            alt={t("NavBar.TheJonahGroupHomepage")}
            src={`${domain}/logo.jpg`}
            style={{ height: "46px" }}
          />
        </Button>
      </AppBar>
      <div className={classes.drawerSpacer}></div>
      <List>
        { 
          getAuthorizedMenuLinks().map((menuLink, index) => 
            renderMenuItem(menuLink, index))
        }
      </List>
    </Drawer>
  );
}
