import { useHttpService } from './useHttpService';
import { useLocalStorage } from "../hooks/useLocalStorage";
import { hiveSyncUrl } from "../firebase";

export const useHiveSyncService = () => {
  const BASE_URL = hiveSyncUrl;
  const GET_TEST_ADMIN_CONSENT_URL = "/microsoft/graph/adminconsent/link/test";
  const GET_ADMIN_CONSENT_URL = "/microsoft/graph/adminconsent/link";

  const localStorage = useLocalStorage();
  const companyId = localStorage.getCompanyID();
  const hiveSyncServiceKey = localStorage.getHiveSyncServiceKey();
  const httpService = hiveSyncServiceKey ? new useHttpService(BASE_URL, { Authorization: `Bearer ${hiveSyncServiceKey}` }) : null;

  const getAdminConsentLink = async (requestBody) => {
    let response = { success: false, message: "" };
    if (httpService) {
      try {
        const results = await httpService.doPost(`${GET_ADMIN_CONSENT_URL}?company=${companyId}`, requestBody);
        response.success = true;
        response.data = results.data;
      } catch (error) {
        throw new Error(`getAdminConsentLink has failed. ${error}`);
      }
    } else {
      throw new Error("Company has no access to Hive Sync Service.");
    }
    return response;
  };

  const getTestAdminConsentLink = async () => {
    let response = { success: false, message: "" };
    if (httpService) {
      try {
        const results = await httpService.doGet(`${GET_TEST_ADMIN_CONSENT_URL}?company=${companyId}`);
        response.success = true;
        response.data = results.data;
      } catch (error) {
        response.success = false;
      }
    }
    return response;
  };

  return {
    getAdminConsentLink: getAdminConsentLink,
    getTestAdminConsentLink: getTestAdminConsentLink
  };
};
