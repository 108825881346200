import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme/index";
import { Routes } from "./Routes";
import "./assets/scss/index.scss";
import { SnackbarProvider } from "notistack";
import Message from "./components/Message/Message";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider maxSnack={7}>
          <Router>
            <Routes />
          </Router>
          <Message />
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
