import React from "react";
import { useTranslation } from "react-i18next";
import { MultiLanguageInputField } from "../../components/MultiLanguage/MultiLanguageInputField";
import StyledRadio from "../../components/StyledRadio/StyledRadio";
import QuestionLabel from "./QuestionLabel";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import RenderAdditionalField from "./RenderAdditionalField";
import {
  Button,
  makeStyles,
  Tooltip,
  Card,
  RadioGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";

const useStyles = makeStyles({
  questionLabel: {
    display: "flex",
    justifyContent: "space-between"
  },
  removeButton: {
    padding: 5,
    minWidth: 0,
    height: "24px",
    width: "24px"
  },
  questionCard: {
    padding: "0.5rem",
    margin: "0.5rem"
  },
  inputContainer: {
    margin: "1rem 0"
  }
});

const Question = ({ questions, index, functions, language }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const {
    removeQuestion,
    questionTypeOnChange,
    checkboxTextOnChange,
    expectedAnswerOnChange,
    expectedCheckboxAnswerOnChange,
    questionOnChange,
    requiredOnChange
  } = { ...functions };

  return (
    <>
      <div key={`question_${index}`}>
        <div className={classes.questionLabel}>
          <QuestionLabel text={`Question ${index + 1}`} heading />
          <Tooltip title={t("Questions.DeleteQuestionTooltip")}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.removeButton}
              onClick={() => removeQuestion(index)}
            >
              <DeleteOutline className={classes.removeIcon} />
            </Button>
          </Tooltip>
        </div>
        <Card className={classes.questionCard}>
          <QuestionLabel text={t("Questions.QuestionType")} />
          <RadioGroup
            value={questions[index].questionType}
            onChange={(event) => questionTypeOnChange(event, index)}
            row
          >
            <FormControlLabel
              value="RADIO"
              control={<StyledRadio />}
              label={t("Questions.Radio")}
            />
            <FormControlLabel
              value="CHECKBOX"
              control={<StyledRadio />}
              label={t("Questions.Checkbox")}
            />
          </RadioGroup>
          <RenderAdditionalField
            index={index}
            language={language}
            questions={questions}
            checkboxTextOnChange={checkboxTextOnChange}
            expectedAnswerOnChange={expectedAnswerOnChange}
            expectedCheckboxAnswerOnChange={expectedCheckboxAnswerOnChange}
          />
          <div className={classes.inputContainer}>
            <MultiLanguageInputField
              label={t("Questions.QuestionText")}
              multiline={true}
              controller={{
                value: questions[index].question,
                set: questionOnChange,
                lang: language
              }}
              index={index}
            />
          </div>
          <div className={classes.inputContainer}>
            <FormControlLabel
              label={t("Questions.Required")}
              control={
                <Checkbox
                  color="primary"
                  checked={questions[index].required}
                  onChange={(event) => requiredOnChange(event, index)}
                />
              }
            />
          </div>
        </Card>
      </div>
    </>
  );
};
export default Question;
