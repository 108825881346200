import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Button,
  Chip,
  Card,
  CardHeader,
  CardContent,
  Typography
} from "@material-ui/core";
import classnames from "classnames";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AddIcon from "@material-ui/icons/Add";
import Alert from "../../components/Alert/Alert";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  form: {
    // padding: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  companyStructureWrapper: {
    marginBottom: theme.spacing(2)
  },
  companyStructureTextFieldWrapper: {
    display: "flex",
    alignItems: "center"
  },
  companyStructureTextField: {
    flexGrow: 1
  },
  chipWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    listStyle: "none",
    margin: 0
  },
  chip: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75)
  }
}));

const BasicInfo = React.memo(
  ({
    className,
    company,
    setCompanyField,
    doLocationsExist,
    ...rest
  }) => {
    const classes = useStyles();
    const [locationName, setLocationName] = useState("");
    const { t } = useTranslation();

    const addLocation = () => {
      const coordinateSystem = [...company.coordinateSystem];
      coordinateSystem.push(locationName);
      setCompanyField("coordinateSystem", coordinateSystem);
      setLocationName("");
    };

    const deleteLocation = (index) => {
      const coordinateSystem = [...company.coordinateSystem];
      coordinateSystem.splice(index, 1);
      setCompanyField("coordinateSystem", coordinateSystem);
    };

    return (
      <Card className={className} {...rest}>
        <CardHeader title={t("CompanyInfo.CompanyInfo")} />
        <CardContent>
          <TextField
            className={classes.textField}
            required
            fullWidth
            variant="outlined"
            label={t("CompanyInfo.CompanyName")}
            name="companyName"
            value={company.companyName}
            onChange={(e) => setCompanyField("companyName", e.target.value)}
          />
          <TextField
            className={classes.textField}
            disabled
            fullWidth
            variant="outlined"
            label={t("CompanyInfo.CompanyId")}
            name="companyId"
            value={company.companyId}
            onChange={(e) => setCompanyField("companyId", e.target.value)}
          />
          {!doLocationsExist && (
            <Alert
              className={classes.alert}
              message={t("CompanyInfo.CantChangeCompanyStructure")}
            />
          )}
          <div className={classes.companyStructureWrapper}>
            <Typography variant="h6" gutterBottom>
              {t("CompanyInfo.CompanyLocationStructure")}
            </Typography>
            {!doLocationsExist && (
              <div className={classes.companyStructureTextFieldWrapper}>
                {" "}
                <TextField
                  className={classnames({
                    [classes.textField]: true,
                    [classes.companyStructureTextField]: true
                  })}
                  variant="outlined"
                  label={t("CompanyInfo.LocationName")}
                  value={locationName}
                  onChange={(e) => setLocationName(e.target.value)}
                />
                <Button
                  onClick={addLocation}
                  disabled={!locationName}
                  startIcon={<AddIcon />}
                  style={{ marginLeft: "8px" }}
                >
                  {t("Commons.Add")}
                </Button>
              </div>
            )}

            <ul className={classes.chipWrapper}>
              {company.coordinateSystem.map((data, index) => {
                return (
                  <li key={index} className={classes.chipWrapper}>
                    {!doLocationsExist ? (
                      <Chip
                        label={data}
                        onDelete={() => deleteLocation(index)}
                        className={classes.chip}
                        color="primary"
                      />
                    ) : (
                      <Chip
                        label={data}
                        className={classes.chip}
                        color="primary"
                      />
                    )}{" "}
                    {index !== company.coordinateSystem.length - 1 && (
                      <ChevronRightIcon />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </CardContent>
      </Card>
    );
  }
);

BasicInfo.propTypes = {
  className: PropTypes.string
};

export default BasicInfo;
