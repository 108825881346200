import React, { useState } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useCompanyService } from "../../services/useCompanyService";
import DateTimeUtil from "../../utils/DateTimeUtil";
import classnames from "classnames";
import { grey } from "@material-ui/core/colors";
import CompanyUtil from "../../utils/CompanyUtil";
import ReportFilters from "./ReportFilters";
import { useEffect } from "react";
import { useCallback } from "react";
import { isEmpty } from "../../common";
import DataTable from "../../components/DataTable/DataTable";
import EmailExport from "./EmailExport";

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: "20px 0"
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function LocationReport({ company }) {
  const classes = useStyles();
  const companyService = useCompanyService();
  const [t] = useTranslation();

  const [locations, setLocations] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [onlyBookable, setOnlyBookable] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const [locationsList, setLocationsList] = useState([]);
  const [email, setEmail] = useState("");

  const [selectedLocation, setSelectedLocation] = useState(null);

  const locationController = {
    value: selectedLocation,
    setter: setSelectedLocation
  };

  useEffect(() => {
    loadData();
  });

  const loadData = useCallback(async () => {
    const _locationsList =
      await companyService.getLocationsFromDropdownListCollection();
    isEmpty(locationsList) && setLocationsList(_locationsList);
    const _usersList =
      await companyService.getUsersFromDropdownListCollection();
    isEmpty(usersList) && setUsersList(_usersList);
  }, [locationsList, usersList]);

  const getLocations = async (location) => {
    setLoadingView(true);

    let _locations = [];

    const locationData = await companyService.getLocation(location);

    _locations = location
      ? await companyService.getLocationsUnderAllLevels(
        locationData,
        onlyBookable
      )
      : await companyService.getAllLocationsWithBottomLevelFilter(onlyBookable);

    const mappedLocations = _locations.map((location) => {
      return {
        ...location,
        coordinateAsString: CompanyUtil.getStringForCoords(
          company,
          location.coordinate
        ),
        assignedUsers: location.assignedLocationFor
          .map((userId) => getUserEmailById(userId))
          .join(", "),
        preferredUsers: location.preferredLocationFor
          .map((userId) => getUserEmailById(userId))
          .join(", ")
      };
    });

    mappedLocations.sort((a, b) =>
      a.coordinateAsString.localeCompare(b.coordinateAsString)
    );

    setLocations(mappedLocations);

    setLoadingView(false);
  };

  const getUserEmailById = (userId) => {
    return usersList.find((user) => user.userId == userId).email;
  };

  const getCoordinateSystemHeaders = () => {
    return company.coordinateSystem.map((cs) => {
      return {
        columnNamekey: cs,
        fieldName: `coordinate.${cs}.displayName`,
        customContentFormatter: (data) => renderCoordinateCell(data, cs)
      };
    });
  };

  const renderTableCell = (data, fieldName) => {
    const { [fieldName]: field } = data;

    return field ? field : "";
  };

  const renderCoordinateCell = (data, fieldName) => {
    const field = data["coordinate"][fieldName];

    return field ? field.displayName : "";
  };

  const tableStructure = [
    {
      columnNamekey: "Reports.LocationType",
      fieldName: "locationType",
      customContentFormatter: (data) => renderTableCell(data, "locationType")
    },
    ...getCoordinateSystemHeaders(),
    {
      columnNamekey: "Reports.Coordinate",
      fieldName: "coordinateAsString",
      customContentFormatter: (data) =>
        renderTableCell(data, "coordinateAsString")
    },
    {
      columnNamekey: "Reports.MaxBookingCount",
      fieldName: "maxBookingCount",
      customContentFormatter: (data) =>
        renderTableCell(data, "maxBookingCount")
    },
    {
      columnNamekey: "Reports.AssignedUsers",
      fieldName: "assignedUsers",
      customContentFormatter: (data) => renderTableCell(data, "assignedUsers")
    },
    {
      columnNamekey: "Reports.PreferredUsers",
      fieldName: "preferredUsers",
      customContentFormatter: (data) => renderTableCell(data, "preferredUsers")
    }
  ];

  const reportFilename = `Location Report (${DateTimeUtil.formatDateAndTime(
    new Date()
  )}).csv`;

  return (
    <>
      <Typography variant="h3">{t("Reports.LocationReport")}</Typography>
      <ReportFilters
        enableLocationFilter={true}
        locationsList={locationsList}
        locationController={locationController}
        company={company}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={onlyBookable}
            onChange={(event) => {
              setOnlyBookable(event.target.checked);
            }}
          />
        }
        label={t("Reports.BookableLocationsOnly")}
      />
      <div className={classnames({ [classes.wrapper]: true })}>
        <Button
          variant="contained"
          color="primary"
          disabled={loadingView}
          onClick={() => getLocations(selectedLocation)}
        >
          {t("Reports.ViewReport")}
          {loadingView && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </div>
      <DataTable
        tableStructure={tableStructure}
        tableContent={[...locations]}
        displayListSize={true}
        customCellStyle={{ padding: "10px" }}
        customSpacer="0px"
        fixHeight={false}
        emptyText="Reports.NoLocationsFound"
      />
      {locations.length > 0 && (
        <EmailExport
          email={email}
          setEmail={setEmail}
          onExport={() =>
            companyService.exportLocationReport(
              email,
              company.companyName,
              reportFilename,
              company.coordinateSystem,
              locations
            )
          }
        />
      )}
    </>
  );
}
