import { useState } from "react";

export default function useAddQuestionsFormState(
  language,
  controller,
  createMode
) {
  const [questions, setQuestions] = useState(
    !createMode ? controller.data.questionnaire : []
  );

  const [name, setName] = useState(!createMode ? controller.data.name : "");
  const nameOnChange = (event) => setName(event.target.value);

  const [welcome, setWelcome] = useState(
    !createMode ? controller.data.messages.welcome : { en: "", fr: "" }
  );
  const onWelcomeChange = (event) =>
    setWelcome({
      ...welcome,
      [language]: event.target.value
    });
  const welcomeController = {
    value: welcome,
    set: onWelcomeChange,
    lang: language
  };

  const [accessGranted, setAccessGranted] = useState(
    !createMode ? controller.data.messages.accessGranted : { en: "", fr: "" }
  );
  const onAccessGrantedChange = (event) =>
    setAccessGranted({
      ...accessGranted,
      [language]: event.target.value
    });
  const accessGrantedController = {
    value: accessGranted,
    set: onAccessGrantedChange,
    lang: language
  };

  const [accessDenied, setAccessDenied] = useState(
    !createMode ? controller.data.messages.accessDenied : { en: "", fr: "" }
  );
  const onAccessDeniedChange = (event) =>
    setAccessDenied({
      ...accessDenied,
      [language]: event.target.value
    });
  const accessDeniedController = {
    value: accessDenied,
    set: onAccessDeniedChange,
    lang: language
  };

  const [visitorWelcome, setVisitorWelcome] = useState(
    !createMode ? controller.data.visitorMessages.welcome : { en: "", fr: "" }
  );
  const onVisitorWelcomeChange = (event) =>
    setVisitorWelcome({
      ...visitorWelcome,
      [language]: event.target.value
    });
  const visitorWelcomeController = {
    value: visitorWelcome,
    set: onVisitorWelcomeChange,
    lang: language
  };

  const [visitorAccessGranted, setVisitorAccessGranted] = useState(
    !createMode
      ? controller.data.visitorMessages.accessGranted
      : { en: "", fr: "" }
  );
  const onVisitorAccessGrantedChange = (event) =>
    setVisitorAccessGranted({
      ...visitorAccessGranted,
      [language]: event.target.value
    });
  const visitorAccessGrantedController = {
    value: visitorAccessGranted,
    set: onVisitorAccessGrantedChange,
    lang: language
  };

  const [visitorAccessDenied, setVisitorAccessDenied] = useState(
    !createMode
      ? controller.data.visitorMessages.accessDenied
      : { en: "", fr: "" }
  );
  const onVisitorAccessDeniedChange = (event) =>
    setVisitorAccessDenied({
      ...visitorAccessDenied,
      [language]: event.target.value
    });
  const visitorAccessDeniedController = {
    value: visitorAccessDenied,
    set: onVisitorAccessDeniedChange,
    lang: language
  };

  const copyFromRegularMessages = () => {
    setVisitorWelcome(welcome);
    setVisitorAccessGranted(accessGranted);
    setVisitorAccessDenied(accessDenied);
  };

  const updateQuestionnaireOnQuestionSet = (questions) => {
    const _questionSet = { ...controller.data };
    _questionSet.questionnaire = questions;
    controller.set(_questionSet);
  };

  const addQuestion = () => {
    const _questions = [...questions];
    _questions.push({
      questionType: "",
      question: { en: "", fr: "" },
      checkboxText: { en: "", fr: "" },
      required: true,
      expectedAnswer: ""
    });
    setQuestions(_questions);
    updateQuestionnaireOnQuestionSet(_questions);
  };

  const removeQuestion = (index) => {
    const _questions = [...questions];
    _questions.splice(index, 1);
    setQuestions(_questions);
    updateQuestionnaireOnQuestionSet(_questions);
  };

  const questionTypeOnChange = (event, index) => {
    const _questions = [...questions];
    _questions[index].questionType = event.target.value;
    if (event.target.value === "CHECKBOX") {
      _questions[index].checkboxText = { en: "", fr: "" };
    }
    setQuestions(_questions);
    updateQuestionnaireOnQuestionSet(_questions);
  };

  const checkboxTextOnChange = (event, lang, index) => {
    const _questions = [...questions];
    _questions[index].checkboxText[lang] = event.target.value;
    setQuestions(_questions);
    updateQuestionnaireOnQuestionSet(_questions);
  };

  const expectedAnswerOnChange = (event, index) => {
    const _questions = [...questions];
    _questions[index].expectedAnswer = event.target.value;
    setQuestions(_questions);
    updateQuestionnaireOnQuestionSet(_questions);
  };

  const expectedCheckboxAnswerOnChange = (event, index) => {
    const _questions = [...questions];
    _questions[index].expectedAnswer = event.target.checked
      ? "CHECKED"
      : "UNCHECKED";
    setQuestions(_questions);
    updateQuestionnaireOnQuestionSet(_questions);
  };

  const questionOnChange = (event, lang, index) => {
    const _questions = [...questions];
    _questions[index].question[lang] = event.target.value;
    setQuestions(_questions);
    updateQuestionnaireOnQuestionSet(_questions);
  };

  const requiredOnChange = (event, index) => {
    const _questions = [...questions];
    _questions[index].required = event.target.checked;
    setQuestions(_questions);
    updateQuestionnaireOnQuestionSet(_questions);
  };

  return {
    controllers: {
      welcomeController,
      accessGrantedController,
      accessDeniedController,
      visitorWelcomeController,
      visitorAccessGrantedController,
      visitorAccessDeniedController
    },
    methods: {
      nameOnChange,
      questionTypeOnChange,
      checkboxTextOnChange,
      expectedAnswerOnChange,
      expectedCheckboxAnswerOnChange,
      questionOnChange,
      requiredOnChange,
      addQuestion,
      removeQuestion,
      copyFromRegularMessages
    },
    values: {
      name,
      welcome,
      accessGranted,
      accessDenied,
      visitorWelcome,
      visitorAccessGranted,
      visitorAccessDenied,
      questions
    }
  };
}
