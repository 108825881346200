import React, { useState, useEffect } from "react";
import PropType from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useCompanyService } from "../../services/useCompanyService";
import { useUserService } from "../../services/useUserService";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../common";
import { ROLES, USER_STATUS } from "../../constants";
import UserFuzzySearch from "../../components/Selectors/UserFuzzySearch";
import MultipleSelector from "../../components/MultipleSelector/MultipleSelector";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "start",
    marginTop: theme.spacing(5),
    gap: "15px",
    "justify-content": "space-between",
    "&>*": {
      width: "30%"
    }
  }
}));

const cleanFilters = {
  mainFilter: "",
  fuzzySearchString: "",
  roles: [],
  groups: [],
  state: []
};

const filterKeyMap = {
  groups: "userGroupId",
  roles: "roleId",
  state: "name"
};

const FilterSection = ({
  usersList,
  filteredUsersList,
  tableOwnership,
  message
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const companyService = useCompanyService();
  const userService = useUserService();
  const [userGroups, setUserGroups] = useState([]);
  const [fuzzyResults, setFuzzyResults] = useState([]);
  const [fuzzyString, setFuzzyString] = useState("");
  const [filters, setFilters] = useState(cleanFilters);
  const [activeUsersAmount, setActiveUsersAmount] = useState(0);
  const [newSearch, setNewSearch] = useState(false);

  const stateOptions = React.useMemo(() =>
    Object.values(USER_STATUS).map((s) => ({ name: s }))
  );

  const rolesOptions = React.useMemo(() =>
    Object.values(ROLES)
      .filter((role) => role !== "ROOT")
      .map((role) => {
        return {
          name: role.replace(/_/g, " "),
          roleId: role
        };
      })
  );

  useEffect(() => {
    if (!tableOwnership.amITheOwner) {
      setFilters(cleanFilters);
      setFuzzyString("");
      message.set(t("Users.ActiveUsersMessage", { amount: activeUsersAmount }));
    }
  }, [tableOwnership.amITheOwner, activeUsersAmount]);

  useEffect(() => {
    const loadUserGroups = async () => {
      const requireApprovalGroup =
        await companyService.getRequireApprovalGroups();
      const userGroups = await companyService.getUserGroups();
      const allUserGroups = requireApprovalGroup.concat(userGroups);
      setUserGroups(allUserGroups);
    };
    isEmpty(userGroups) && loadUserGroups();
  }, []);

  useEffect(() => {
    const loadUsers = async () => {
      if (tableOwnership.amITheOwner) {
        let _users = await getUsers();
        const validFilters = getNonEmptyFilters();
        if (!isEmpty(validFilters)) {
          var _filteredUsers = [..._users];
          validFilters.map((filter) => {
            _filteredUsers = applyFilter(filter, _filteredUsers);
          });
          message.set(
            t("Users.RecordsFoundForResults", {
              amount: _filteredUsers.length
            })
          );
          filteredUsersList.set(_filteredUsers);
        }
      }
    };
    loadUsers();
  }, [filters]);

  const applyFilter = (filter, list) => {
    /* eslint-disable */
    switch (filter) {
      case "fuzzySearchString":
        return filterByFuzzySearch(list);
      case "state":
        return filterByState(list);
      case "roles":
        return filterByRoles(list);
      case "groups":
        return filterByGroupa(list);
      default:
        return list;
    }
    /* eslint-enable */
  };

  const filterByFuzzySearch = (list) => {
    if (filters.mainFilter != "fuzzySearchString") {
      return list.filter(
        (user) =>
          user.email.toLowerCase().includes(filters.fuzzySearchString) ||
          `${user.displayName}`
            .toLowerCase()
            .includes(filters.fuzzySearchString)
      );
    }
    return list;
  };

  const filterByState = (list) => {
    return list.filter((user) => filters.state.includes(user.state));
  };

  const filterByRoles = (list) => {
    filters.roles.map((f) => {
      list = list.filter((user) => {
        return user.roles.some((uf) => uf === f);
      });
    });
    return list;
  };

  const filterByGroupa = (list) => {
    filters.groups.map((f) => {
      list = list.filter((user) => {
        return user.groups.some((uf) => uf === f);
      });
    });
    return list;
  };

  const getUsers = async () => {
    const loadUsersByIds = async () => {
      const _userIds = fuzzyResults.map((u) => u.userId);
      const list = await userService.getUsersByIds(_userIds);
      usersList.set(list);
      return list;
    };

    const loadUsersByFilter = async (filter) => {
      const list = await userService.getUsersByAttribute(
        filter,
        filters[filter],
        filter === "state" ? false : true
      );
      usersList.set(list);
      return list;
    };

    if (newSearch) {
      return filters.mainFilter === "fuzzySearchString"
        ? await loadUsersByIds()
        : await loadUsersByFilter(filters.mainFilter);
    }
    return usersList.get();
  };

  const getNonEmptyFilters = (_filters = null) => {
    _filters = !_filters ? { ...filters } : _filters;
    delete _filters.mainFilter;
    const remainFilters = Object.keys(_filters);
    return remainFilters.filter((f) => !isEmpty(_filters[f]));
  };

  const handleFilterChange = (filter, newValue) => {
    setNewSearch(hasNoFilters(filters));
    let _filters = { ...filters };
    _filters[filter] = findData(filter, newValue);

    if (hasNoFilters(_filters)) {
      setFilters(cleanFilters);
      setNewSearch(true);
      tableOwnership.takeOwnership(false);
      return false;
    }

    _filters.mainFilter = determineMainFilter(_filters, filter);
    _filters = stateAsMainFilterShouldBeSingle(_filters);

    setFilters(_filters);
    tableOwnership.takeOwnership(true);
  };

  const stateAsMainFilterShouldBeSingle = (_filters) => {
    const validFilters = getNonEmptyFilters({ ..._filters });
    if (
      validFilters.length === 1 &&
      validFilters[0] === "state" &&
      _filters.state.length > 1
    ) {
      _filters.state = [_filters.state[1]];
    }
    return _filters;
  };

  const determineMainFilter = (_filters, filter) => {
    let candidate = isEmpty(_filters.mainFilter) ? filter : _filters.mainFilter;
    if (candidate === filter && isEmpty(_filters[filter])) {
      const nonEmptyFilters = getNonEmptyFilters();
      const validFilters = nonEmptyFilters.filter(
        (filter) => filter != _filters.mainFilter
      );
      candidate = validFilters[0];
    }
    return candidate;
  };

  const findData = (filter, newValue) => {
    if (filter === "fuzzySearchString") {
      isEmpty(filters.mainFilter) && setFuzzyString(newValue);
      !isEmpty(newValue) &&
        message.set(t("Users.SearchingFor", { queryParam: newValue }));
      return newValue;
    } else {
      return newValue.map((v) => v[filterKeyMap[filter]]);
    }
  };

  const hasNoFilters = (_filters) => {
    return (
      isEmpty(_filters.fuzzySearchString) &&
      isEmpty(_filters.roles) &&
      isEmpty(_filters.groups) &&
      isEmpty(_filters.state)
    );
  };

  const handleFuzzyInput = (inputString) => {
    handleFilterChange("fuzzySearchString", inputString);
  };

  return (
    filters && (
      <div className={classes.toolbar}>
        <UserFuzzySearch
          listSize={setActiveUsersAmount}
          fuzzyResults={{ get: () => fuzzyResults, set: setFuzzyResults }}
          queryString={{ get: () => fuzzyString, set: handleFuzzyInput }}
        />
        <MultipleSelector
          label={t("Users.UserRoles")}
          placeHolder={t("Users.Roles")}
          options={rolesOptions}
          onChange={(newValue) => handleFilterChange("roles", newValue)}
          value={rolesOptions.filter((o) => filters.roles.includes(o.roleId))}
        />
        <MultipleSelector
          label={t("Users.UserGroups")}
          placeHolder={t("Users.Groups")}
          options={userGroups}
          onChange={(newValue) => handleFilterChange("groups", newValue)}
          value={userGroups.filter((g) =>
            filters.groups.includes(g.userGroupId)
          )}
        />
        <MultipleSelector
          label={t("Users.UserState")}
          placeHolder={t("Users.State")}
          options={stateOptions}
          onChange={(newValue) => handleFilterChange("state", newValue)}
          value={stateOptions.filter((s) => filters.state.includes(s.name))}
        />
      </div>
    )
  );
};

FilterSection.proptype = {
  usersList: PropType.array.isRequired,
  filteredUsersList: PropType.array.isRequired,
  tableOwnership: PropType.object.isRequired,
  message: PropType.object.isRequired
};

export default FilterSection;
