import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import { ErrorAlert } from "../common/ErrorAlert";
import { useCompanyService } from "../../services/useCompanyService";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { MultiLanguageInputField } from "../../components/MultiLanguage/MultiLanguageInputField";
import { validateQuestionnaire } from "./QuestionsFormValidator.js";
import omit from "lodash/omit";
import useAddQuestionsFormState from "./useAddQuestionsFormState";
import Question from "./Question";
import MessagesHeading from "./MessagesHeading";

const useStyles = makeStyles({
  button: {
    padding: 5,
    minWidth: 0
  },
  checkInContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  checkInContainerInner: {
    display: "flex"
  },
  infoIcon: {
    marginLeft: "0.5rem"
  },
  questions: {
    margin: "1rem 0"
  },
  inputContainer: {
    margin: "1rem 0"
  }
});

const formId = "create-questionnaire-form";

export const QuestionnaireEditor = ({
  onClose,
  onSaveCallback,
  createMode,
  checkInQuestion,
  controller
}) => {
  const classes = useStyles();

  const [t, i18n] = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [errorMessages, setErrorMessages] = useState([]);

  const companyService = useCompanyService();
  const localStorage = useLocalStorage();

  const {
    controllers: {
      welcomeController,
      accessGrantedController,
      accessDeniedController,
      visitorWelcomeController,
      visitorAccessGrantedController,
      visitorAccessDeniedController
    },
    methods: {
      nameOnChange,
      questionTypeOnChange,
      checkboxTextOnChange,
      expectedAnswerOnChange,
      expectedCheckboxAnswerOnChange,
      questionOnChange,
      requiredOnChange,
      addQuestion,
      removeQuestion,
      copyFromRegularMessages
    },
    values: {
      name,
      welcome,
      accessGranted,
      accessDenied,
      visitorWelcome,
      visitorAccessGranted,
      visitorAccessDenied,
      questions
    }
  } = useAddQuestionsFormState(language, controller, createMode);

  const [addVisitorMessages, setAddvisitorMessages] = useState(false);

  const preparePayload = (questionnaire) => {
    const companyId = localStorage.getCompanyID();
    const messages = { welcome, accessGranted, accessDenied };
    /* eslint-disable */
    const visitorMessages = addVisitorMessages
      ? {
          welcome: visitorWelcome,
          accessGranted: visitorAccessGranted,
          accessDenied: visitorAccessDenied
        }
      : messages;
    /* eslint-enable */

    const payload = {
      messages,
      visitorMessages,
      name,
      questionnaire,
      companyId
    };

    if (!createMode) {
      payload.id = checkInQuestion.id;
      payload.locationIds = checkInQuestion.locationIds;
    }

    return payload;
  };

  const prepareQuestionnaire = () => {
    return Object.values(questions).map((question) => {
      if (question.questionType === "CHECKBOX") {
        return question;
      }
      return {
        ...omit(question, ["checkboxText"])
      };
    });
  };

  const onClickSaveCloudFunction = (payload) => {
    return createMode
      ? companyService.createCheckinQuestion(payload)
      : companyService.updateCheckinQuestion(payload);
  };

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);

      const questionnaire = prepareQuestionnaire();
      const payload = preparePayload(questionnaire, addVisitorMessages);
      const _errorMessages = validateQuestionnaire(payload, addVisitorMessages);

      if (_errorMessages.length === 0) {
        const response = await onClickSaveCloudFunction(payload);

        if (!response.data.success) {
          const path = "Errors.Cloud." + response.data.message;
          const error = i18n.exists(path) ? t(path) : t("Errors.General");
          setErrorMessages([error]);
        } else {
          onSaveCallback();
          setLoading(false);
          onClose();
        }
      }
      setErrorMessages(_errorMessages);
      setLoading(false);
    },
    [
      questions,
      welcome,
      accessGranted,
      accessDenied,
      localStorage,
      name,
      visitorWelcome,
      visitorAccessGranted,
      visitorAccessDenied,
      addVisitorMessages
    ]
  );

  return (
    <AddEditDialog
      createMode={createMode}
      title={t("Questions.Questionnaire")}
      onCancel={onClose}
      enableLanguageSelector
      language={language}
      setLanguage={setLanguage}
      formId={formId}
      loading={loading}
      disableSave={loading}
    >
      {errorMessages.length > 0 && (
        <ErrorAlert
          errorMessages={errorMessages}
          onCloseAlert={setErrorMessages}
        />
      )}
      <form onSubmit={onSubmit} id={formId}>
        <div className={classes.inputContainer}>
          <TextField
            variant="outlined"
            placeholder={t("Questions.QuestionnaireName")}
            value={name}
            fullWidth
            onChange={nameOnChange}
          />
        </div>
        <MessagesHeading heading={t("Questions.Messages")} />
        <div className={classes.inputContainer}>
          <MultiLanguageInputField
            label={t("MultiLanguage.WelcomeMessage")}
            labelSize={120}
            multiline={true}
            controller={welcomeController}
          />
        </div>
        <div className={classes.inputContainer}>
          <MultiLanguageInputField
            label={t("MultiLanguage.AccessGrantedMessage")}
            labelSize={160}
            multiline={true}
            controller={accessGrantedController}
          />
        </div>
        <div className={classes.inputContainer}>
          <MultiLanguageInputField
            label={t("MultiLanguage.AccessDeniedMessage")}
            labelSize={155}
            multiline={true}
            controller={accessDeniedController}
          />
        </div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={addVisitorMessages}
                onChange={(event) =>
                  setAddvisitorMessages(event.target.checked)
                }
                color="primary"
              />
            }
            label={t("Questions.AddVisitorMessages")}
          />
        </FormGroup>
        {addVisitorMessages && (
          <>
            <MessagesHeading
              visitorMessages
              copyFromRegularMessages={copyFromRegularMessages}
              heading={t("Questions.VisitorMessages")}
            />
            <div className={classes.inputContainer}>
              <MultiLanguageInputField
                label={t("MultiLanguage.WelcomeMessage")}
                labelSize={120}
                multiline={true}
                controller={visitorWelcomeController}
              />
            </div>
            <div className={classes.inputContainer}>
              <MultiLanguageInputField
                label={t("MultiLanguage.AccessGrantedMessage")}
                labelSize={120}
                multiline={true}
                controller={visitorAccessGrantedController}
              />
            </div>
            <div className={classes.inputContainer}>
              <MultiLanguageInputField
                label={t("MultiLanguage.AccessDeniedMessage")}
                labelSize={120}
                multiline={true}
                controller={visitorAccessDeniedController}
              />
            </div>
          </>
        )}
        <div className={classes.checkInContainer}>
          <div className={classes.checkInContainerInner}>
            <Typography variant="h6">
              {t("Questions.SelfCheckInQuestions")}
            </Typography>
            <Tooltip
              arrow
              placement="top"
              title={t("Questions.AnswerShouldBeNo")}
            >
              <InfoIcon fontSize="small" className={classes.infoIcon} />
            </Tooltip>
          </div>
          <Tooltip title={t("Questions.NewQuestionTooltip")}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={addQuestion}
            >
              <AddIcon />
            </Button>
          </Tooltip>
        </div>
        <div className={classes.questions}>
          {questions &&
            questions.map((q, index) => (
              <Question
                key={index}
                questions={questions}
                index={index}
                language={language}
                functions={{
                  removeQuestion,
                  requiredOnChange,
                  questionOnChange,
                  questionTypeOnChange,
                  checkboxTextOnChange,
                  expectedAnswerOnChange,
                  expectedCheckboxAnswerOnChange
                }}
              />
            ))}
        </div>
      </form>
    </AddEditDialog>
  );
};
