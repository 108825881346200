import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import DateTimeUtil from "../../utils/DateTimeUtil";
import { RESOURCE_BASE_TIME } from "../../constants";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  FormControl,
  FormLabel,
  ButtonGroup,
  Button,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  label: {
    // position: "relative",
    // marginButton: "10px",
    // color: "black"
  },
  buttonGroup: {
    marginTop: "8px"
  },
  button: {
    padding: "5px"
  },
  display: {
    padding: "5px"
  }
}));

const TimeAmountPicker = ({ display, titleKey }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const increment = () => {
    display.set(display.value + RESOURCE_BASE_TIME);
  };

  const decrement = () => {
    display.set(display.value - RESOURCE_BASE_TIME);
  };

  return (
    <FormControl component="fieldset" variant="standard" fullWidth>
      <FormLabel component="legend" className={classes.label}>
        {t(titleKey)}
      </FormLabel>
      <ButtonGroup
        className={classes.buttonGroup}
        color="primary"
        aria-label="small outlined primary button group"
      >
        <Button
          className={classes.button}
          variant="contained"
          onClick={decrement}
        >
          <RemoveIcon />
        </Button>
        <Button disabled className={classes.display}>
          <Typography variant="button" className={classes.caption}>
            {DateTimeUtil.intMinToDisplayTime(display.value)}
          </Typography>
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={increment}
        >
          <AddIcon />
        </Button>
      </ButtonGroup>
    </FormControl>
  );
};

TimeAmountPicker.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default TimeAmountPicker;
