import { database } from "../firebase";

export const useAppService = () => {

  const getFaqs = async () => {
    let faqs = [];
    await database
      .collection('WebAppData')
      .doc('faqs')
      .get()
      .then(doc => doc.data().questions.forEach(question => faqs.push(question)));
    return faqs;
  };

  const getReleaseNotes = async () => {
    /* In case we want to get release notes for the last several days
        const daysAgo = 30;
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const startTime = new Date();
        startTime.setTime(startTime.getTime() - daysAgo * millisecondsPerDay);
        */
    const documentLimit = 4;
    const releaseNotes = [];
        
    const snapshot = await database
      .collection('ReleaseNotes')
      .orderBy('date', 'desc')
      .limit(documentLimit)
      .get();
        
    snapshot.forEach((doc) => {
      const data = doc.data();
      releaseNotes.push({title: data.title, changelog: data.changelog});
    });

    return releaseNotes;
  };

  return {
    getFaqs: getFaqs,
    getReleaseNotes: getReleaseNotes
  };
};
