import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Paper, Button, Input } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import classnames from "classnames";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  search: {
    flexGrow: 1,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center"
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon.main
  },
  searchInput: {
    flexGrow: 1
  },
  searchButton: {
    backgroundColor: theme.palette.common.white,
    marginLeft: theme.spacing(2)
  }
}));

function SearchBar({ className, onSearch, onReset, innerText, ...restProps }) {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <div
      className={classnames(className, { [classes.root]: true })}
      {...restProps}
    >
      <Paper className={classes.search} elevation={1}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          className={classes.searchInput}
          disableUnderline
          placeholder={(innerText && innerText) || t("Commons.Search")}
          onChange={(e) => {
            handleChange(e);
            if (onSearch) {
              onSearch(e.target.value);
            }
          }}
          value={value}
        />
      </Paper>
      {onReset && (
        <Button
          className={classes.searchButton}
          onClick={() => {
            onReset();
            setValue("");
          }}
          size="large"
          variant="contained"
        >
          {t("Commons.Reset")}
        </Button>
      )}
    </div>
  );
}

SearchBar.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  onReset: PropTypes.func
};
export default SearchBar;
