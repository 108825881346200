import React from "react";
import { Grid, TextField } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";

export const LocationMapSelect = ({
  eligibleLocations,
  filterOptions,
  getLocationOptionName,
  handleLocationChange
}) => {
  const [t] = useTranslation();

  return (
    <Grid item xs={12}>
      <Autocomplete
        id="selected_location"
        options={eligibleLocations}
        getOptionLabel={(option) => getLocationOptionName(option)}
        onChange={(event, newValue) => {
          handleLocationChange(newValue);
        }}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Locations.FindLocationMap")}
            margin="normal"
          />
        )}
      />
    </Grid>
  );
};
