import React from 'react';
import { CSVLink } from 'react-csv';
import { Button, makeStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    "& a": {
      color: theme.palette.text.primary
    }
  }
}));

// hive-admin-653: CSVLink property "uFEFF" default is true, causing problems with
// unwanted BOM (Byte Order Mark) characters in the start of CSV output,
// we need to explicitly set uFEFF value to false.
//
export const ExportReactCSV = ({ csvData, fileName, headers, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.root}
      disabled={disabled}
    >
      <CSVLink data={csvData} filename={fileName} headers={headers} uFEFF={false}>
        {t("Reports.DownloadData")}
      </CSVLink>
    </Button>
  );
};