import React, { useState, useEffect } from "react";
import PropType from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useCompanyService } from "../../services/useCompanyService";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../common";
import { TextField, Tooltip } from "@material-ui/core";

const UserFuzzySearch = ({ fuzzyResults, queryString, listSize }) => {
  const [t] = useTranslation();
  const companyService = useCompanyService();
  const [usersList, setUsersList] = useState([]);
  const [fuzzySerachString, setFuzzySerachString] = useState("");

  useEffect(() => {
    const loadUsers = async () => {
      const _usersList =
        await companyService.getUsersFromDropdownListCollection();
      setUsersList(_usersList);
      listSize(_usersList.length);
    };

    if (isEmpty(usersList)) {
      loadUsers();
    }
  }, []);

  useEffect(() => {
    listSize(usersList.length);
  }, [usersList.length]);

  useEffect(() => {
    setFuzzySerachString(queryString.get());
  }, [queryString.get()]);

  const inputFieldFormater = (user) => {
    if (!isEmpty(user.displayName)) {
      return (
        <Tooltip title={user.email}>
          <span>{user.displayName}</span>
        </Tooltip>
      );
    }
    return fuzzySerachString;
  };

  const optionLabelFormater = (user) => {
    return !isEmpty(user.displayName) ? user.displayName : fuzzySerachString;
  };

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        label={t("Commons.Search")}
        variant="outlined"
        onKeyDown={handleOnSelectValue}
      />
    );
  };

  const handleOnSelectValue = (event, newValue) => {
    let value = "";
    let results = [];
    if (event.keyCode == 13 && event.target.value) {
      value = event.target.value.toLowerCase();
      results = usersList.filter(
        (user) =>
          user.email.toLowerCase().includes(value) ||
          `${user.firstName} ${user.lastName}`.toLowerCase().includes(value)
      );
    } else if (newValue) {
      value = newValue.displayName;
      results = [newValue];
    }
    fuzzyResults.set(results);
    queryString.set(value);
  };

  return (
    <Autocomplete
      id={`select${t("Commons.Search")}Widget`}
      freeSolo
      fullWidth
      size="small"
      options={usersList}
      renderInput={renderInput}
      renderOption={inputFieldFormater}
      getOptionLabel={optionLabelFormater}
      onChange={handleOnSelectValue}
      value={fuzzySerachString}
      filterSelectedOptions
    />
  );
};

UserFuzzySearch.proptype = {
  fuzzyResults: PropType.object.isRequired,
  queryString: PropType.object.isRequired,
  listSize: PropType.func.isRequired
};

export default UserFuzzySearch;
