import React, { useEffect, useRef } from "react";
import { FixedSizeList } from "react-window";
import { isEmpty } from "../../common";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { red, orange, blue, green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EventSeatIcon from "@material-ui/icons/EventSeat";

const useStyles = makeStyles(() => ({
  seatList: {
    margin: "auto"
  },
  edit: {
    color: red[500]
  },
  selected: {
    color: orange[600]
  },
  unassigned: {
    color: blue[500]
  },
  assigned: {
    color: green[500]
  },
  seatListItemText: {
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  seatToolTip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export const LocationMapList = ({
  floorMap,
  selectedSeat,
  setSelectedSeat,
  seats
}) => {
  const classes = useStyles();
  const seatListRef = useRef();

  useEffect(() => {
    if (!isEmpty(selectedSeat.index)) {
      seatListRef.current.scrollToItem(selectedSeat.index);
    }
  }, [selectedSeat]);

  const getSeatStyle = (seat) => {
    if (floorMap.floorMapLocation) {
      const _isUnAssigned = !(
        seat.coordinate[floorMap.floorMapLocation.locationType].point &&
        seat.coordinate[floorMap.floorMapLocation.locationType].point.length ===
          2
      );
      const _isSelected =
        selectedSeat.seat && seat.locationId === selectedSeat.seat.locationId;
      if (!_isSelected)
        return _isUnAssigned ? classes.unassigned : classes.assigned;
      else {
        return _isSelected
          ? selectedSeat.editMode
            ? classes.selected
            : classes.selected
          : classes.default;
      }
    } else {
      return classes.default;
    }
  };

  const SeatRow = ({ index, style }) => (
    <ListItem
      button
      style={style}
      key={seats[index].locationId}
      selected={
        selectedSeat.seat &&
        seats[index].locationId === selectedSeat.seat.locationId
      }
    >
      <IconButton
        onClick={() => setSelectedSeat({ seat: seats[index], editMode: false })}
      >
        <EventSeatIcon
          fontSize="small"
          className={getSeatStyle(seats[index])}
        />
      </IconButton>
      <Tooltip title={seats[index].displayName}>
        <ListItemText
          className={classes.seatListItemText}
          primary={seats[index].displayName}
          onClick={() =>
            setSelectedSeat({ seat: seats[index], editMode: false })
          }
        />
      </Tooltip>
    </ListItem>
  );

  return (
    <div>
      <FixedSizeList
        ref={seatListRef}
        className={classes.seatList}
        height={600}
        width={250}
        itemSize={46}
        itemCount={seats.length}
        aria-label="seats"
      >
        {SeatRow}
      </FixedSizeList>
    </div>
  );
};
