import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import { useTranslation } from 'react-i18next';

export default function useLocationTagsValidation(tags) {
  const { t } = useTranslation();

  const tagIndexesLongerThan25 = keys(
    pickBy(
      tags,
      (tag) => tag.displayName.en.length > 25 || tag.displayName.fr.length > 25
    )
  );

  const tagIndexesWithNoEnName = keys(
    pickBy(tags, (tag) => tag.displayName.en === '')
  );

  const formErrors = [];

  const longerThan25Error = t('Locations.TagLongerThan25');
  const enNameMissingError = t('Locations.EnglishNameMissingError');

  if (
    tagIndexesLongerThan25.length > 0 &&
    !formErrors.includes(longerThan25Error)
  )
    formErrors.push(longerThan25Error);

  if (
    tagIndexesWithNoEnName.length > 0 &&
    !formErrors.includes(enNameMissingError)
  )
    formErrors.push(enNameMissingError);

  return { formErrors };
}
