export default class FirebaseUtil {
  static async makeBatchCall(list, callback, batchSize = 10) {
    let items = [];
    while (list.length) {
      let subSet = list.splice(0, batchSize);
      let results = await callback(subSet);
      items.push(...results);
    }
    return items;
  }
}
