import { useHttpService } from './useHttpService';
import { useLocalStorage } from "../hooks/useLocalStorage";
import { hiveApiUrl } from "../firebase";

export const useHiveApiService = () => {
  const BASE_URL = hiveApiUrl;
  const HEALTHCHECK_URL = "/api/map/health";
  const MAX_OCCUPANCY_URL = "/api/map/location/occupancy";
  const SAVE_MAX_OCCUPANCY_URL = "/api/map/location/occupancy/save";
  const EXPORT_MAX_OCCUPANCY_URL = "/api/map/location/occupancy/plot";
  const SAVE_INSERTED_LOCATION_URL = "/api/map/location/save";
  const DELETE_ALL_CHILD_LOCATION_URL = "/api/map/location/child/delete";
  const TEMPLATE_MATCHING_URL = "/api/map/location/matching";
  const ACTIVE_USERS_URL = "/api/user/active_users";

  const localStorage = useLocalStorage();
  const companyId = localStorage.getCompanyID();
  const hiveApiServiceKey = localStorage.getHiveApiServiceKey();
  const httpService = hiveApiServiceKey ? new useHttpService(BASE_URL, { Authorization: `Bearer ${hiveApiServiceKey}` }) : null;

  const healthCheck = async () => {
    let response = { success: false, message: "" };
    if (httpService) {
      try {
        const results = await httpService.doGet(`${HEALTHCHECK_URL}?company=${companyId}`);
        response.success = true;
        response.data = results.data;
      } catch (error) {
        response.message = `healthCheck has failed. ${error}`;
      }
      return response;

    }
    response.message = "Company has no access to Hive API Service.";
    return response;
  };

  const computeMaxOccupancy = async (requestBody) => {
    let response = { success: false, message: "" };
    if (httpService) {
      try {
        const results = await httpService.doPost(`${MAX_OCCUPANCY_URL}?company=${companyId}`, requestBody);
        response.success = true;
        response.data = results.data;
      } catch (error) {
        response.message = `computeMaxOccupancy has failed. ${error}`;
      }
      return response;

    }
    response.message = "Company has no access to Hive API Service.";
    return response;
  };

  const saveMaxOccupancy = async (requestBody) => {
    let response = { success: false, message: "" };
    if (httpService) {
      try {
        const results = await httpService.doPost(`${SAVE_MAX_OCCUPANCY_URL}?company=${companyId}`, requestBody);
        response.success = true;
        response.data = results.data;
      } catch (error) {
        response.message = `saveMaxOccupancy has failed. ${error}`;
      }
      return response;

    }
    response.message = "Company has no access to Hive API Service.";
    return response;
  };

  const exportMaxOccupancy = async (requestBody) => {
    let response = { success: false, message: "" };
    if (httpService) {
      try {
        const results = await httpService.doPost(`${EXPORT_MAX_OCCUPANCY_URL}?company=${companyId}`, requestBody);
        response.success = true;
        response.data = results.data;
      } catch (error) {
        response.message = `exportMaxOccupancy has failed. ${error}`;
      }
      return response;

    }
    response.message = "Company has no access to Hive API Service.";
    return response;
  };

  const saveInsertedLocations = async (requestBody) => {
    let response = { success: false, message: "" };
    if (httpService) {
      try {
        const results = await httpService.doPost(`${SAVE_INSERTED_LOCATION_URL}?company=${companyId}`, requestBody);
        response.success = true;
        response.data = results.blob();
        console.log(response.data);
      } catch (error) {
        response.message = `saveInsertedLocations has failed. ${error}`;
      }
      return response;

    }
    response.message = "Company has no access to Hive API Service.";
    return response;
  };

  const deleteAllChildLocations = async (requestBody) => {
    let response = { success: false, message: "" };
    if (httpService) {
      try {
        const results = await httpService.doPost(`${DELETE_ALL_CHILD_LOCATION_URL}?company=${companyId}`, requestBody);
        response.success = true;
        response.data = results.blob();
        console.log(response.data);
      } catch (error) {
        response.message = `deleteAllChildLocations has failed. ${error}`;
      }
      return response;
    }
    response.message = "Company has no access to Hive API Service.";
    return response;
  };

  const templateMatching = async (requestBody) => {
    let response = { success: false, message: "" };
    if (httpService) {
      try {
        const results = await httpService.doPost(`${TEMPLATE_MATCHING_URL}?company=${companyId}`, requestBody);
        response.success = true;
        response.data = results.data;
      } catch (error) {
        response.message = `templateMatching has failed. ${error}`;
      }
      return response;
    }
    response.message = "Company has no access to Hive API Service.";
    return response;
  };

  const getActiveUsers = async (requestBody) => {
    let response = { success: false, message: "" };
    if (httpService) {
      try {
        const results = await httpService.doPost(`${ACTIVE_USERS_URL}?company=${companyId}`, requestBody);
        response.success = true;
        response.data = results.data;
      } catch (error) {
        response.message = `getActiveUsers has failed. ${error}`;
      }
      return response;
    }
    response.message = "Company has no access to Hive API Service.";
    return response;
  };

  return {
    healthCheck: healthCheck,
    computeMaxOccupancy: computeMaxOccupancy,
    saveMaxOccupancy: saveMaxOccupancy,
    exportMaxOccupancy: exportMaxOccupancy,
    saveInsertedLocations: saveInsertedLocations,
    deleteAllChildLocations: deleteAllChildLocations,
    templateMatching: templateMatching,
    getActiveUsers: getActiveUsers
  };

};
