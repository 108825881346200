import React from "react";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { useTranslation } from 'react-i18next';

export const DeleteNotification = ({ notification, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      title={t("ScheduledNotifications.DeleteNotification")}
      heading={notification.title}
      message={t("ScheduledNotifications.DeleteNotificationConfirmation")}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};