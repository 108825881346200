import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import { debounce } from "../../common";
import { grey } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  CircularProgress,
  InputBase,
  Tooltip,
  Paper
} from "@material-ui/core";
import { isEmpty } from "lodash";

const useStyles = makeStyles(() => ({
  box: {
    paddingBottom: "0px"
  },
  fields: {
    marginBottom: "15px"
  },
  searchFieldRoot: {
    marginTop: "1px",
    display: "flex"
  },
  inputSearch: {
    marginLeft: "6px"
  },
  searchIcon: {
    margin: "6px"
  },
  actionButtons: {
    marginLeft: "8px"
  },
  exportButton: {
    marginLeft: "8px",
    padding: "6px"
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const ActionButtons = ({
  loading,
  functions,
  disableOptions,
  tableContent,
  searchString,
  checkingIn
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");

  const { selectAllFunc, cancelFunc, checkInFunc, checkOutFunc, exportFunc } =
    functions;
  const {
    disableSelectAll = false,
    disableCancel = false,
    disableCheckIn = false,
    disableCheckOut = false,
    disableExport = false
  } = disableOptions;

  useEffect(() => {}, []);

  const debounceOnChange = React.useCallback(
    debounce((value) => {
      searchString.setter(value);
    }, 400),
    []
  );

  const onSearchVisitor = async (value) => {
    setInputValue(value);
    debounceOnChange(value);
  };

  const isAllSelected = () => {
    return (
      tableContent.rows.length > 0 &&
      tableContent.rows.length === tableContent.selectedRows.length
    );
  };

  const isActive = () => {
    return tableContent.selectedRows.length > 0;
  };

  const shouldDisableSelectAll = () => {
    return loading || tableContent.rows.length === 0 || disableSelectAll;
  };

  const shouldDisableSearchVisitor = () => {
    return isEmpty(inputValue) && (loading || tableContent.rows.length === 0);
  };

  const shouldDisableCheckIn = () => {
    return tableContent.selectedRows.length !== 1 || disableCheckIn;
  };

  const shouldDisableCancel = () => {
    return disableCancel;
  };

  const shouldDisableCheckOut = () => {
    return disableCheckOut;
  };

  const shouldDisableExport = () => {
    return disableExport;
  };

  const tableHasContent = () => {
    return tableContent.rows.length > 0;
  };

  return (
    <Grid container spacing={1} className={classes.fields}>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={shouldDisableSelectAll()}
          onClick={selectAllFunc}
        >
          {isAllSelected()
            ? t("Appointments.UnselectAll")
            : t("Appointments.SelectAll")}
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Paper component="form" className={classes.searchFieldRoot}>
          <InputBase
            className={classes.inputSearch}
            placeholder={t("Appointments.FilterByVisitorName")}
            value={inputValue}
            fullWidth
            disabled={shouldDisableSearchVisitor()}
            onChange={(event) => onSearchVisitor(event.target.value)}
          />
          <SearchIcon
            className={classes.searchIcon}
            style={
              shouldDisableSearchVisitor()
                ? { color: "rgba(0, 0, 0, 0.26)" }
                : {}
            }
          />
        </Paper>
      </Grid>
      <Grid item xs={7} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || !isActive() || shouldDisableCancel()}
          onClick={cancelFunc}
          className={classes.actionButtons}
        >
          {t("Appointments.CancelSelected")}
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || shouldDisableCheckIn()}
          onClick={checkInFunc}
          className={classes.actionButtons}
        >
          {t("Appointments.CheckInSelected")}
          {(loading || checkingIn) && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || !isActive() || shouldDisableCheckOut()}
          onClick={checkOutFunc}
          className={classes.actionButtons}
        >
          {t("Appointments.CheckOutSelected")}
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
        <Tooltip
          title={t("Appointments.ExportAppointmentsToolTip")}
          placement="top-start"
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!tableHasContent() || shouldDisableExport()}
            onClick={exportFunc}
            className={classes.exportButton}
          >
            <SystemUpdateAltIcon />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

ActionButtons.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default ActionButtons;
