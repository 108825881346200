import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Chip,
  Tooltip
} from "@material-ui/core";
import { useCompanyService } from "../../services/useCompanyService";
import { useLocalStore } from "../../hooks/useLocalStore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker } from "@material-ui/pickers";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import { ErrorAlert } from "../common/ErrorAlert";
import { makeStyles } from "@material-ui/styles";
import { useAuthService } from "../../services/useAuthService";
import {
  ROLES,
  USER_STATUS,
  FIRESTORE
} from "../../constants";
import { useDispatch } from "react-redux";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";
import { isEmpty, isNullOrUndefined } from "../../common";
import addDays from "date-fns/addDays";
import differenceInDays from "date-fns/differenceInDays";

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  description: {
    marginBottom: theme.spacing(3)
  }
}));

export const EditUser = ({ createMode, user, userGroups, close }) => {
  const dispatch = useDispatch();
  const companyService = useCompanyService();
  const localStore = useLocalStore();
  const authService = useAuthService();
  const classes = useStyles();

  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isUserLocked = user.state && user.state === USER_STATUS.LOCKED;

  const [editUser, setEditUser] = useState(formatUser(user));
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [startingBookingDateVaccine, setStartingBookingDateVaccine] = useState(
    new Date()
  );
  const [endingBookingDateVaccine, setEndingBookingDateVaccine] = useState(
    new Date()
  );
  const [t, i18next] = useTranslation();
  const isSelf = user.userId === authService.getUserId();
  var closeListener = useRef(() => {});
  const fixedOptions = userGroups.filter(
    (group) => !isEmpty(group.isAccessPolicyGroup)
  );

  const preferencesUtil = localStore.getCompanyPreferencesUtil();
  const userRolesUtil = localStore.getUserRolesUtil();

  const isHRAdminOnly = userRolesUtil.isHRAdminOnly();

  useEffect(() => {
    closeListener.current = companyService.getCompanyStream(setCompany);

    // will be called when component unmounts
    return closeListener.current;
  }, []);

  function formatVaccinationDate(_user) {
    if (_user.vaccination && _user.vaccination.lastVaccinationDate != null) {
      if (typeof _user.vaccination.lastVaccinationDate === "string") {
        _user.vaccination.lastVaccinationDate = new Date(
          _user.vaccination.lastVaccinationDate
        );
      } else if (!(_user.vaccination.lastVaccinationDate instanceof Date)) {
        _user.vaccination.lastVaccinationDate =
          _user.vaccination.lastVaccinationDate.toDate();
      }
    }
  }

  function formatUser(user) {
    let _user = { ...user };

    _user.isCompanyAdmin = _user.roles && _user.roles.includes(ROLES.ADMIN);
    _user.isVisitorAdmin =
      _user.roles && _user.roles.includes(ROLES.VISITOR_ADMIN);
    _user.isAppointmentAdmin =
      _user.roles && _user.roles.includes(ROLES.APPOINTMENT_ADMIN);
    _user.isLocationAdmin =
      _user.roles && _user.roles.includes(ROLES.LOCATION_ADMIN);
    _user.isCleaner = _user.roles && _user.roles.includes(ROLES.CLEANER);
    _user.isCaseAdmin = _user.roles && _user.roles.includes(ROLES.CASE_ADMIN);
    _user.isITAdmin = _user.roles && _user.roles.includes(ROLES.IT_ADMIN);
    _user.isHRAdmin = _user.roles && _user.roles.includes(ROLES.HR_ADMIN);
    _user.state = isEmpty(user.state) ? USER_STATUS.ACTIVE : user.state;
    if (isEmpty(_user.access)) {
      _user.access = { hasAccess: false };
    }
    if (createMode) {
      _user.vaccination = { isVaccinated: false, lastVaccinationDate: null };
      _user.access = { hasAccess: false };
    } else {
      formatVaccinationDate(_user);
    }
    return _user;
  }

  const onFieldChange = (field, value) => {
    let _editUser = { ...editUser };
    _editUser[field] = value;
    setEditUser(_editUser);
  };

  const getExistingUserGroups = () => {
    let adminUserGroups = [];
    if (editUser.groups) {
      editUser.groups.forEach((group) => {
        userGroups.forEach((userGroup) => {
          if (userGroup.userGroupId === group) {
            adminUserGroups.push(userGroup);
            return;
          }
        });
      });
    }
    return adminUserGroups;
  };

  const [selectedUserGroups, setSelectedUserGroups] = useState(() =>
    getExistingUserGroups()
  );

  function addRole(adminType, role, roles) {
    let localRoles = [...roles];
    if (adminType) {
      if (!roles.includes(role)) {
        localRoles = [...roles, role];
      }
    } else {
      localRoles = localRoles.filter((r) => r !== role);
    }
    return localRoles;
  }

  const trimmer = (str) => {
    return str && str.trim();
  };

  const save = async () => {
    const _errorMessages = runFormValidadtion();
    if (_errorMessages.length === 0) {
      setLoading(true);
      if (createMode) {
        const newUser = {
          firstName: trimmer(editUser.firstName),
          lastName: trimmer(editUser.lastName),
          email: trimmer(editUser.email),
          externalId: trimmer(editUser.externalId),
          groups: selectedUserGroups
            ? selectedUserGroups.map((userGroup) => userGroup.userGroupId)
            : [],
          state: editUser.state,
          vaccination: editUser.vaccination ? editUser.vaccination : null,
          access: editUser.access ? editUser.access : null,
          roles: editUser.isCompanyAdmin ? [ROLES.ADMIN] : [],
          assignedSeats: editUser.assignedSeats ? editUser.assignedSeats : [],
          preferredSeats: editUser.preferredSeats ? editUser.preferredSeats : []
        };

        if (editUser.isVisitorAdmin) {
          newUser.roles.push(ROLES.VISITOR_ADMIN);
        }

        if (editUser.isAppointmentAdmin) {
          newUser.roles.push(ROLES.APPOINTMENT_ADMIN);
        }

        if (editUser.isCaseAdmin) {
          newUser.roles.push(ROLES.CASE_ADMIN);
        }

        if (editUser.isITAdmin) {
          newUser.roles.push(ROLES.IT_ADMIN);
        }

        if (editUser.isCleaner) {
          newUser.roles.push(ROLES.CLEANER);
        }

        if (editUser.isHRAdmin) {
          newUser.roles.push(ROLES.HR_ADMIN);
        }

        const response = await companyService.createUserByAdmin(newUser);
        if (response.data.success) {
          newUser.userId = response.data.userId;
          // add user to requireApproval group after creation
          if (
            preferencesUtil.hasAccessLevel("requireApproval") &&
            !newUser.access.hasAccess
          ) {
            let requireApprovalGroups =
              await companyService.getRequireApprovalGroups();
            let requireApprovalGroup = requireApprovalGroups[0];
            newUser.groups.push(requireApprovalGroup.userGroupId);
            await companyService.updateUser(newUser);
          }
          const companySsoEnabled = await companyService.isCompanySsoEnabled(
            company.companyId
          );
          if (!companySsoEnabled) {
            await authService.sendResetPasswordEmail(editUser.email.trim());
          }

          setLoading(false);
          close(newUser);
        } else {
          let details = i18next.exists("Errors.Cloud." + response.data.message)
            ? t("Errors.Cloud." + response.data.message)
            : "";
          _errorMessages.push(`${t("Errors.CreateUser")} ${details}`);
          setLoading(false);
        }
      } else {
        let localEditUser = { ...editUser };

        if (isNullOrUndefined(localEditUser.vaccination)) {
          localEditUser.vaccination = null;
        }

        //Reset vaccination date if not vaccinated
        if (
          localEditUser.vaccination &&
          !localEditUser.vaccination.isVaccinated
        ) {
          localEditUser.vaccination.lastVaccinationDate = null;
        }

        if (
          localEditUser.vaccination &&
          localEditUser.vaccination.lastVaccinationDate != null
        ) {
          const strLastVaccinationDate =
            localEditUser.vaccination.lastVaccinationDate.toISOString();
          localEditUser.vaccination.lastVaccinationDate =
            strLastVaccinationDate;
        }

        if (isNullOrUndefined(localEditUser.access)) {
          localEditUser.access = { hasAccess: false };
        }

        localEditUser.groups = selectedUserGroups
          ? selectedUserGroups.map((userGroup) => userGroup.userGroupId)
          : [];

        localEditUser.roles = addRole(
          localEditUser.isCompanyAdmin,
          ROLES.ADMIN,
          localEditUser.roles
        );
        localEditUser.roles = addRole(
          localEditUser.isVisitorAdmin,
          ROLES.VISITOR_ADMIN,
          localEditUser.roles
        );
        localEditUser.roles = addRole(
          localEditUser.isAppointmentAdmin,
          ROLES.APPOINTMENT_ADMIN,
          localEditUser.roles
        );
        localEditUser.roles = addRole(
          localEditUser.isCaseAdmin,
          ROLES.CASE_ADMIN,
          localEditUser.roles
        );
        localEditUser.roles = addRole(
          localEditUser.isITAdmin,
          ROLES.IT_ADMIN,
          localEditUser.roles
        );
        localEditUser.roles = addRole(
          localEditUser.isCleaner,
          ROLES.CLEANER,
          localEditUser.roles
        );
        localEditUser.roles = addRole(
          localEditUser.isHRAdmin,
          ROLES.HR_ADMIN,
          localEditUser.roles
        );

        delete localEditUser.isCompanyAdmin;
        delete localEditUser.isVisitorAdmin;
        delete localEditUser.isAppointmentAdmin;
        delete localEditUser.isCaseAdmin;
        delete localEditUser.isITAdmin;
        delete localEditUser.isCleaner;
        delete localEditUser.isHRAdmin;

        await companyService
          .updateUser(localEditUser)
          .then((response) => {
            if (!response.data.success) {
              dispatch(setErrorMessage(t("Errors.UpdateUser")));
              localEditUser = { ...user };
            } else {
              dispatch(setSuccessMessage(t("Success.UpdateUser")));
            }
          })
          .catch((error) => {
            dispatch(setErrorMessage(t("Errors.UpdateUser")));
            localEditUser = { ...user };
            console.log("error occurred trying to update user:", error);
          })
          .finally(() => {
            setLoading(false);
            close(localEditUser);
          });
      }
    }
    setErrorMessages(_errorMessages);
  };

  const runFormValidadtion = () => {
    const _errorMessages = [];

    if (!userHasFirstName()) {
      _errorMessages.push(t("Users.UsersMustHaveAFirstName"));
    }

    if (!userHasLastName()) {
      _errorMessages.push(t("Users.UsersMustHaveALastName"));
    }

    if (!userHasEmail()) {
      _errorMessages.push(t("Users.UsersMustHaveAnEmail"));
    } else if (!emailIsValid()) {
      _errorMessages.push(t("Users.EmailIsInvalid"));
    }

    if (!userIsUnderGroupLimit()) {
      _errorMessages.push(t("Users.UserIsOverGroupLimit"));
    }

    return _errorMessages;
  };

  const userHasFirstName = () => {
    return !!editUser.firstName;
  };

  const userHasLastName = () => {
    return !!editUser.lastName;
  };

  const userHasEmail = () => {
    return !!editUser.email;
  };

  const emailIsValid = () => {
    return EMAIL_REGEX.test(String(editUser.email).toLowerCase());
  };

  const userIsUnderGroupLimit = () => {
    return selectedUserGroups
      ? selectedUserGroups.length <= FIRESTORE.WHERE_IN_ARRAY_LIMIT
      : true;
  };

  const handleAccessGrantedChange = async (event) => {
    const _access = { ...editUser.access };
    _access["hasAccess"] = event.target.checked;
    onFieldChange("access", _access);
    const filteredGroups = selectedUserGroups.filter((group) =>
      isEmpty(group.isAccessPolicyGroup)
    );
    // remove requireApprovalGroup if hasAccess
    if (
      preferencesUtil.hasAccessLevel("requireApproval") &&
      _access.hasAccess
    ) {
      setSelectedUserGroups(filteredGroups);
    } else {
      setSelectedUserGroups([
        ...fixedOptions,
        ...filteredGroups.filter((option) => !fixedOptions.includes(option))
      ]);
    }
  };

  function updateStartDateVaccine(date) {
    setStartingBookingDateVaccine(date);
    const difference = differenceInDays(endingBookingDateVaccine, date);
    if (difference > 62) {
      const baseDate = new Date(date.getTime());
      setEndingBookingDateVaccine(addDays(baseDate, 62));
    }
  }

  const stateSelectDisabled =
  preferencesUtil.isCaseManagementEnabled() && isUserLocked;

  return (
    <AddEditDialog
      createMode={createMode}
      description={
        createMode ? t("Users.AddUserNotApplyDomainRestrictions") : null
      }
      title={t("Commons.User")}
      userName={
        createMode ? null : editUser.firstName + " " + editUser.lastName
      }
      onSave={save}
      onCancel={close}
      loading={loading}
    >
      {errorMessages.length > 0 && (
        <ErrorAlert
          errorMessages={errorMessages}
          onCloseAlert={setErrorMessages}
        />
      )}
      {createMode && (
        <Typography
          variant="body2"
          className={classes.description}
        ></Typography>
      )}
      <Grid item sm={12}>
        <TextField
          variant="outlined"
          label={t("Commons.FirstName")}
          value={editUser.firstName}
          fullWidth
          required
          onChange={(event) => {
            onFieldChange("firstName", event.target.value);
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          variant="outlined"
          label={t("Commons.LastName")}
          value={editUser.lastName}
          fullWidth
          required
          onChange={(event) => {
            onFieldChange("lastName", event.target.value);
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          variant="outlined"
          label={t("Commons.Email")}
          value={editUser.email}
          fullWidth
          disabled={!createMode}
          required
          onChange={(event) => {
            onFieldChange("email", event.target.value);
          }}
        />
      </Grid>
      {userGroups && (
        <Grid item sm={12}>
          <Autocomplete
            multiple
            defaultValue={editUser.groups}
            options={userGroups.filter((group) =>
              isEmpty(group.isAccessPolicyGroup)
            )}
            value={selectedUserGroups}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              let needRequire = editUser.access.hasAccess ? [] : fixedOptions;
              setSelectedUserGroups([
                ...needRequire,
                ...newValue.filter((option) => !fixedOptions.includes(option))
              ]);
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((group, index) => (
                <Chip
                  key={`chip_${index}`}
                  label={group.name}
                  {...getTagProps({ index })}
                  disabled={fixedOptions.includes(group)}
                />
              ))
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Users.AddUserGroup")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      )}
      <Grid item sm={12}>
        <TextField
          variant="outlined"
          label={t("Commons.ExternalId")}
          value={editUser.externalId}
          fullWidth
          onChange={(event) => {
            onFieldChange("externalId", event.target.value);
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <Typography variant="h6" gutterBottom>
          {t("Users.UserState")}
        </Typography>
        <Tooltip
          title={stateSelectDisabled ? t("Users.StateSelectTooltip") : ""}
          arrow
        >
          <FormControl>
            <Select
              id="user-state-select"
              defaultValue={USER_STATUS.ACTIVE}
              value={editUser.state}
              onChange={(event) => {
                onFieldChange("state", event.target.value);
              }}
              disabled={stateSelectDisabled}
            >
              <MenuItem value={USER_STATUS.ACTIVE}>
                {t("Users.Active")}
              </MenuItem>
              <MenuItem value={USER_STATUS.INACTIVE}>
                {t("Users.Inactive")}
              </MenuItem>
              <MenuItem value={USER_STATUS.LOCKED}>
                {t("Users.Locked")}
              </MenuItem>
            </Select>
          </FormControl>
        </Tooltip>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {t("Users.UserRoles")}
            </Typography>
          </Grid>
          <Grid container direction="row">
            <Grid xs={5} item>
              <Grid item>
                <FormControlLabel
                  label="Company Admin"
                  control={
                    <Checkbox
                      disabled={isSelf || isHRAdminOnly}
                      color="primary"
                      checked={editUser.isCompanyAdmin}
                      onChange={(event) => {
                        onFieldChange("isCompanyAdmin", event.target.checked);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label={t("Users.LocationAdmin")}
                  control={
                    <Checkbox
                      disabled
                      color="primary"
                      checked={editUser.isLocationAdmin}
                    />
                  }
                />
              </Grid>
              {preferencesUtil.isCaseManagementEnabled() && (
                <Grid item>
                  <FormControlLabel
                    label={t("Users.CaseAdmin")}
                    control={
                      <Checkbox
                        color="primary"
                        disabled={isHRAdminOnly}
                        checked={editUser.isCaseAdmin}
                        onChange={(event) => {
                          onFieldChange("isCaseAdmin", event.target.checked);
                        }}
                      />
                    }
                  />
                </Grid>
              )}
              {preferencesUtil.isCalendarSyncEnabled() && (
                <Grid item>
                  <FormControlLabel
                    label={t("Users.ITAdmin")}
                    control={
                      <Checkbox
                        color="primary"
                        disabled={isHRAdminOnly}
                        checked={editUser.isITAdmin}
                        onChange={(event) => {
                          onFieldChange("isITAdmin", event.target.checked);
                        }}
                      />
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid xs={5} item>
              <Grid item>
                <FormControlLabel
                  label={t("Users.VisitorAdmin")}
                  control={
                    <Checkbox
                      color="primary"
                      disabled={isHRAdminOnly}
                      checked={editUser.isVisitorAdmin}
                      onChange={(event) => {
                        onFieldChange("isVisitorAdmin", event.target.checked);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label={t("Users.HRAdmin")}
                  control={
                    <Checkbox
                      color="primary"
                      disabled={isHRAdminOnly}
                      checked={editUser.isHRAdmin}
                      onChange={(event) => {
                        onFieldChange("isHRAdmin", event.target.checked);
                      }}
                    />
                  }
                />
              </Grid>
              {preferencesUtil.isResourceManagementEnabled() && (
                <Grid item>
                  <FormControlLabel
                    label={t("Users.AppointmentAdmin")}
                    control={
                      <Checkbox
                        color="primary"
                        disabled={isHRAdminOnly}
                        checked={editUser.isAppointmentAdmin}
                        onChange={(event) => {
                          onFieldChange(
                            "isAppointmentAdmin",
                            event.target.checked
                          );
                        }}
                      />
                    }
                  />
                </Grid>
              )}
              {preferencesUtil.isCleaningEnabled() && (
                <Grid item>
                  <FormControlLabel
                    label={t("Users.Cleaner")}
                    control={
                      <Checkbox
                        color="primary"
                        disabled={isHRAdminOnly}
                        checked={editUser.isCleaner}
                        onChange={(event) => {
                          onFieldChange("isCleaner", event.target.checked);
                        }}
                      />
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {preferencesUtil.displayVaccinatedField() && (
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("Users.Vaccination")}
              </Typography>
            </Grid>
            <Grid container direction="row">
              <Grid xs={4} item>
                <Grid item>
                  <FormControlLabel
                    label={t("Users.Vaccinated")}
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          editUser && editUser.vaccination
                            ? editUser.vaccination.isVaccinated
                            : false
                        }
                        onChange={(event) => {
                          const _vaccination = { ...editUser.vaccination };
                          _vaccination["isVaccinated"] = event.target.checked;
                          onFieldChange("vaccination", _vaccination);
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              {editUser.vaccination && editUser.vaccination.isVaccinated && (
                <Grid xs={5} item>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          label={t("Users.VaccinationDate")}
                          name="startingBookingDateVaccine"
                          maxDate={endingBookingDateVaccine}
                          value={
                            editUser.vaccination.lastVaccinationDate != null
                              ? editUser.vaccination.lastVaccinationDate
                              : startingBookingDateVaccine
                          }
                          onChange={(value) => {
                            updateStartDateVaccine(value);
                            const _vaccination = { ...editUser.vaccination };
                            _vaccination["lastVaccinationDate"] = value;
                            onFieldChange("vaccination", _vaccination);
                          }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* Access Policy */}
      {preferencesUtil.displayAccessPolicyField() && (
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {t("Users.AccessPolicy")}
              </Typography>
            </Grid>
            <Grid container direction="row">
              <Grid xs={4} item>
                <Grid item>
                  <FormControlLabel
                    label={t("Users.AccessGranted")}
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          editUser && editUser.access
                            ? editUser.access.hasAccess
                            : false
                        }
                        onChange={(event) => {
                          handleAccessGrantedChange(event);
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </AddEditDialog>
  );
};
