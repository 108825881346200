import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import { register } from "./serviceWorker";
import { PersistGate } from "redux-persist/lib/integration/react";
import "./i18n";

const config = {
  onUpdate: (registration) => {
    const registrationWaiting = registration.waiting;
    if (registrationWaiting) {
      registrationWaiting.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          const reload = window.confirm(
            "New version available, please reload the page."
          );
          if (reload) {
            window.location.reload();
          }
        }
      });
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
    }
  }
};
register(config);
const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Suspense fallback={<div>Loading ...</div>}>
        <App />
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
