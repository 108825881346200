import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { LinearProgress } from "@material-ui/core";
import { isEmpty } from "../../common";
import { Devices } from "./Device/Devices";
import { Profiles } from "./Profile/Profiles";
import { EditDevice } from "./Device/EditDevice";
import { DeleteDevice } from "./Device/DeleteDevice";
import { EditProfile } from "./Profile/EditProfile";
import { DeleteProfile } from "./Profile/DeleteProfile";
import { useCompanyService } from "../../services/useCompanyService";
import { useLocalStore } from "../../hooks/useLocalStore";
import { setSuccessMessage } from "../../actions/messageActions";
import { COMPANY_PREFERENCES } from "../../constants";
import { FeatureNotEnabled } from "../common/FeatureNotEnabled";

export const Gate = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const localStore = useLocalStore();
  const companyService = useCompanyService();
  const [loading, setLoading] = useState(true);
  const [userGroupMap, setUserGroupMap] = useState(null);
  const [pageEnabled, setPageEnabled] = useState(true);
  const [devices, setDevices] = useState([]);
  const [editDevice, setEditDevice] = useState();
  const [deleteDevice, setDeleteDevice] = useState();
  const [profileMap, setProfileMap] = useState({});
  const [editProfile, setEditProfile] = useState();
  const [deleteProfile, setDeleteProfile] = useState();
  const [usersList, setUsersList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);

  const company = localStore.getCompany();

  useEffect(() => {
    const loadData = async () => {
      if(isEmpty(usersList)){
        setLoading(true);

        const asyncCalls = [
          companyService.getLocationsFromDropdownListCollection(),
          companyService.getUsersFromDropdownListCollection()
        ];

        try {
          const [_locationsList, _usersList] = await Promise.all(
            asyncCalls
          );
          setLocationsList(_locationsList);
          setUsersList(_usersList);
        } catch (error) {
          console.log(error);
        }

        setLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadHiveProfileData = async () => {
      const hiveGatePref =
        company.preferences[COMPANY_PREFERENCES.hiveGateService];
      if (hiveGatePref && hiveGatePref["apiKey"]) {
        const asyncCalls = [
          companyService.getDevices(), 
          companyService.getHiveGateProfiles(), 
          companyService.getUserGroupMap()
        ];

        try {
          Promise.all(asyncCalls).then(response => {
            setDevices(response[0]);
            setProfileMap(response[1]);
            setUserGroupMap(response[2]);
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        setPageEnabled(false);
      }
    };
    company && loadHiveProfileData();
  }, [company]);

  const closeEditDevice = (device, createMode) => {
    let message;
    let _devices = [...devices];

    if (device && createMode) {
      _devices.push(device);
      message = t("Success.CreateDevice");
    } else if (device) {
      const index = _devices.map((d) => d.deviceId).indexOf(device.deviceId);
      _devices[index] = device;
      message = t("Success.UpdateDevice");
    }

    if (device) {
      setDevices(_devices);
      dispatch(setSuccessMessage(message));
    }

    setEditDevice();
  };

  const closeDeleteDevice = (device) => {
    if (device) {
      let _devices = devices.filter((d) => d.deviceId !== device.deviceId);
      setDevices(_devices);
      dispatch(setSuccessMessage(t("Success.DeleteDevice")));
    }

    setDeleteDevice();
  };

  const closeEditProfile = (profile, createMode, reasons) => {
    let message;
    let _profileMap = { ...profileMap };

    if (profile && createMode) {
      message = t("Success.CreateProfile");
    } else if (profile) {
      message = t("Success.UpdateProfile");
    }

    if (profile) {
      if (reasons) {
        profile.reasons = reasons;
      }
      _profileMap[profile.profileId] = profile;
      setProfileMap(_profileMap);
      dispatch(setSuccessMessage(message));
    }

    setEditProfile();
  };

  const closeDeleteProfile = (profile) => {
    if (profile) {
      let _profileMap = { ...profileMap };
      delete _profileMap[profile.profileId];
      let _devices = [...devices];
      for (let _d of _devices) {
        if (_d.profileId === profile.profileId) {
          _d.profileId = "";
        }
      }

      setProfileMap(_profileMap);
      setDevices(_devices);
    }

    setDeleteProfile();
  };

  if (loading) {
    return <LinearProgress />;
  }

  if (!pageEnabled) {
    return <FeatureNotEnabled />;
  }

  return (
    <div className="content">
      <Devices
        devices={devices}
        setEditDevice={setEditDevice}
        setDeleteDevice={setDeleteDevice}
        profileMap={profileMap}
      />
      {locationsList && (
        <Profiles
          profiles={Object.values(profileMap)}
          setEditProfile={setEditProfile}
          setDeleteProfile={setDeleteProfile}
          company={company}
          usersList={usersList}
          locationsList={locationsList}
        />
      )}
      {editDevice && (
        <EditDevice
          editDevice={editDevice.device}
          createMode={editDevice.createMode}
          close={closeEditDevice}
          profileMap={profileMap}
        />
      )}
      {editProfile && (
        <EditProfile
          profile={editProfile.profile}
          groupMap={userGroupMap}
          createMode={editProfile.createMode}
          close={closeEditProfile}
          company={company}
          usersList={usersList}
          locationsList={locationsList}
          setProfileMap={setProfileMap}
        />
      )}
      {deleteDevice && (
        <DeleteDevice deleteDevice={deleteDevice} close={closeDeleteDevice} />
      )}
      {deleteProfile && (
        <DeleteProfile
          deleteProfile={deleteProfile}
          close={closeDeleteProfile}
        />
      )}
    </div>
  );
};
