import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  LinearProgress,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useCompanyService } from "../../services/useCompanyService";
import { useUserService } from "../../services/useUserService";
import { EditUser } from "./EditUser.jsx";
import { isTrue, isEmpty } from "../../common";
import { EditUserSeats } from "./EditUserSeats.jsx";
import { SetUserPassword } from "./SetUserPassword.jsx";
import { grey } from "@material-ui/core/colors";
import { useAuthService } from "../../services/useAuthService";
import { useDispatch } from "react-redux";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";
import { MessageDialog } from "../common/MessageDialog";
import { useLocalStorage } from "../../hooks/useLocalStorage.js";
import { useLocalStore } from "../../hooks/useLocalStore";
import { ExportReactCSV } from "../common/ExportReactCSV";
import { ROLES, USER_STATUS } from "../../constants";
import DateTimeUtil from "../../utils/DateTimeUtil";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import ActionButtons from "./ActionButtons";
import useUserDataTable from "./useUserDataTable";
import useExportUserDataTable from "./useExportUserDataTable";
import TableNavigator from "../../components/DataTable/TableNavigator";
import DataTable from "../../components/DataTable/DataTable";
import FilterSection from "./FilterSection";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  addUserButton: {
    margin: theme.spacing(3, 0)
  },
  table: {
    width: "100%"
  },
  signOutUserGroup: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2)
  },
  signOutUsersButton: {
    margin: theme.spacing(3, 0)
  },
  signOutUserText: {
    marginLeft: theme.spacing(1)
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  exportButtonContainer: {
    display: "grid",
    gridTemplateColumns: "8fr 8fr 3fr",
    margin: "5px 0px"
  },
  recordsFoundContainer: {
    position: "relative"
  },
  recordsFoundText: {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)"
  }
}));

export default function Users() {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const classes = useStyles();
  const companyService = useCompanyService();
  const userService = useUserService();
  const authService = useAuthService();
  const localStore = useLocalStore();
  const localStorage = useLocalStorage();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [queryResultsMessage, setQueryResultsMessage] = useState("");
  const [init, setInit] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [editUserSeats, setEditUserSeats] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [locations, setLocations] = useState([]);
  const [resetPasswordInfo, setResetPasswordInfo] = useState(null);
  const [deleteUserInfo, setDeleteUserInfo] = useState(null);
  const [logoutUsersDialog, setLogoutUsersDialog] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessageGeneral] = useState("");
  const [isSso, setIsSso] = useState(false);
  const [exportableFilteredUsers, setExportableFilteredUsers] = useState([]);
  const [showExternalIds, setShowExternalIds] = useState(false);
  const [tabIndex, setTabIndex] = useState(null);
  const [hideTabIndex, setHideTabIndex] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const [filtersOwnsTable, setFiltersOwnsTable] = useState(false);
  const [forceReload, setForceReload] = useState(false);
  const [lastItemFromPreviousPage, setLastItemFromPreviousPage] = useState(null);

  const LOGOUT_USERS_EXPLANATION = t("Users.LogoutAllUsersMessage");
  const LOGOUT_USERS_CONFIRMATION = t("Users.LogoutAllUsersConfirmation");

  const company = localStore.getCompany();
  const preferencesUtil = localStore.getCompanyPreferencesUtil();

  const tableStructure = useUserDataTable(company, userGroups, showExternalIds);
  const { exportCsvHeaders, convertToExportable } = useExportUserDataTable(
    userGroups,
    locations
  );

  const isSuperUser = isTrue(
    localStorage.getUserRoles().some((role) => role === ROLES.ROOT)
  );
  const isCompanyOrGroupAdmin = isTrue(
    localStorage
      .getUserRoles()
      .some((role) => role === ROLES.USER_GROUP_ADMIN || role === ROLES.ADMIN)
  );
  const exportCsvFilename = `Users Report (${DateTimeUtil.formatDateAndTime(
    new Date()
  )}).csv`;

  useEffect(() => {
    const loadCompanyData = async () => {
      const ssoEnabled = await companyService.isCompanySsoEnabled(company.companyId);
      setIsSso(ssoEnabled);
    };

    const loadUserGroups = async () => {
      const requireApprovalGroup =
        await companyService.getRequireApprovalGroups();
      const userGroups = await companyService.getUserGroups();
      const allUserGroups = requireApprovalGroup.concat(userGroups);
      setUserGroups(allUserGroups);
    };

    const loadLocations = async () => {
      const locations = await companyService.getAllLocations();
      setLocations(locations);
    };

    if (!init) {
      loadCompanyData();
      loadUserGroups();
      loadLocations();
      setInit(true);
    }
  }, [queryResultsMessage]);

  useEffect(() => {
    const loadUsers = async () => {
      const { previous, next } = getPageParameters();
      const { results, hasNextPage } = await userService.getPaginatedUsers(
        tabIndex,
        previous,
        next
      );
      setUsers(results);
      setNextPage(hasNextPage);
      setFilteredUsers(results);
    };

    const loadLocations = async () => {
      const locations = await companyService.getAllLocations();
      setLocations(locations);
    };

    (!filtersOwnsTable && loadUsers()) || setHideTabIndex(true);
    loadLocations();
  }, [tabIndex, pageNumber, filtersOwnsTable, forceReload]);

  useEffect(() => {
    setPageNumber(1);
  }, [tabIndex]);

  useEffect(() => {
    company && updateExportableUsers(filteredUsers);
  }, [filteredUsers && filteredUsers.length]);

  useEffect(() => {
    if (hideTabIndex) {
      setTabIndex(null);
    } else {
      setTabIndex("A");
    }
    setPageNumber(1);
  }, [hideTabIndex]);

  const updateExportableUsers = (users) => {
    const exportableUsers = convertToExportable(users);
    setExportableFilteredUsers(exportableUsers);
  };

  const getPageParameters = () => {
    let previous = null;
    let next = null;
    if (pageNumber != lastPage) {
      if (pageNumber > lastPage) {
        next = isEmpty(users) ? null : users[users.length - 1];
        setLastItemFromPreviousPage(next);
      } else {
        previous = isEmpty(users) ? null : users[0];
        setLastItemFromPreviousPage(null);
      }
      setLastPage(pageNumber);
    } else {
      next = lastItemFromPreviousPage ? lastItemFromPreviousPage : null;
    }
    return { previous, next };
  };

  const add = () => {
    setEditUser({
      createMode: true,
      user: { state: USER_STATUS.ACTIVE }
    });
  };

  const closeEditUser = (user) => {
    if (user) {
      let _users = [...users];
      if (editUser.createMode) {
        _users.push(user);
      } else {
        const index = _users.findIndex((user) => {
          return user.userId === editUser.user.userId;
        });
        _users[index] = user;
      }
      setUsers(_users);
    }
    setEditUser(null);
    setForceReload(!forceReload);
  };

  const closeEditUserSeats = (user) => {
    if (user) {
      let _users = [...users];
      const index = _users.findIndex((u) => {
        return u.userId === user.userId;
      });
      _users[index] = user;
      setUsers(_users);
    }
    setEditUserSeats(null);
    setForceReload(!forceReload);
  };

  const closeSetUserPassword = (user) => {
    if (user) {
      let _users = [...users];
      const index = _users.findIndex((u) => {
        return u.userId === user.userId;
      });
      _users[index] = user;
      setUsers(_users);
    }
    setUserPassword(null);
    setForceReload(!forceReload);
  };

  const closeDeleteUser = (user) => {
    if (user) {
      let _users = [];
      users.map((user) => {
        if (user.userId !== deleteUserInfo.user.userId) _users.push(user);
      });
      setUsers(_users);
    }
    setDeleteUserInfo(null);
    setForceReload(!forceReload);
  };

  const confirmDeleteUser = async () => {
    const user = deleteUserInfo.user;
    try {
      if (preferencesUtil.hasAccessLevel("requireApproval")) {
        user.groups = [];
        await companyService.updateUser(user);
      }
      const result = await companyService.deleteUserByAdmin(user);
      closeDeleteUser(user);
      result.data.success ? dispatch(setSuccessMessage(t("Success.DeleteUser"))) : dispatch(setErrorMessage(t("Errors.DeleteUser")));
    } catch (error) {
      setDeleteUserInfo(null);
      console.log(error);
      setErrorMessageGeneral(t("Errors.General") + error.message);
      return;
    }
  };

  const confirmResetPassword = async () => {
    const email = resetPasswordInfo.user.email;
    setResetPasswordInfo(null);
    try {
      await authService.sendResetPasswordEmail(email);
      dispatch(
        setSuccessMessage(t("Success.EmailResetPassword", { email: email }))
      );
    } catch (error) {
      console.log(error);
      setErrorMessageGeneral(t("Errors.General") + error.message);
      return;
    }
  };

  const handleLogoutAllUsers = async () => {
    setButtonLoading(true);
    setLogoutUsersDialog(false);

    const result = await companyService.signOutAllUsers();

    if (result.data.success) {
      dispatch(setSuccessMessage(t("Success.General")));
    } else {
      setErrorMessageGeneral(t("Errors.General"));
    }
    setButtonLoading(false);
    setFiltersOwnsTable(false);
  };

  const handleClose = () => {
    setLogoutUsersDialog(false);
  };

  const handleClearFilters = () => {
    setFiltersOwnsTable(false);
    setQueryResultsMessage("");
  };

  const canManagePassword = (user) => {
    let hasUserGroupManagedPassword = false;

    if (userGroups.length > 0 && user.groups.length > 0) {
      const userGroupManagedPassword = preferencesUtil.getUserGroupManagedPassword();
      hasUserGroupManagedPassword = user.groups.some((g) => userGroupManagedPassword.includes(g)) || false;
    }

    return isCompanyOrGroupAdmin && (!isSso || hasUserGroupManagedPassword);
  };

  const renderActionsComponent = (_user, _index) => {
    return (
      <ActionButtons
        user={_user}
        index={_index}
        canManagePassword={canManagePassword}
        setEditUser={setEditUser}
        setDeleteUserInfo={setDeleteUserInfo}
        setEditUserSeats={setEditUserSeats}
        setUserPassword={setUserPassword}
        setResetPasswordInfo={setResetPasswordInfo}
        isSuperUser={isSuperUser}
      />
    );
  };

  if (init) {
    return (
      <div className="content">
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h3">{t("Users.Users")}</Typography>
          <FilterSection
            usersList={{ get: () => users, set: setUsers }}
            filteredUsersList={{
              get: () => filteredUsers,
              set: setFilteredUsers
            }}
            tableOwnership={{
              amITheOwner: filtersOwnsTable,
              takeOwnership: (value) => {
                setFiltersOwnsTable(value);
              }
            }}
            message={{
              get: () => queryResultsMessage,
              set: setQueryResultsMessage
            }}
          />
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.addUserButton}
              onClick={add}
            >
              {t("Users.AddUser")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "1rem" }}
              onClick={handleClearFilters}
            >
              {t("Users.ClearFilters")}
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: "1rem" }}
              onClick={() => setShowExternalIds(!showExternalIds)}
            >
              {showExternalIds
                ? t("Users.HideExternalIds")
                : t("Users.ShowExternalIds")}
            </Button>
          </div>
          <div className={classes.table}>
            <div className={classes.exportButtonContainer}>
              <div className={classes.recordsFoundContainer}>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                  className={classes.recordsFoundText}
                >
                  {queryResultsMessage}
                </Typography>
              </div>
              <div></div>
              <ExportReactCSV
                csvData={exportableFilteredUsers}
                fileName={exportCsvFilename}
                headers={exportCsvHeaders}
                disabled={0 === filteredUsers.length}
              />
            </div>
            <div style={{ marginTop: "24px" }}>
              <TableNavigator
                disableNavigator={filtersOwnsTable}
                disableIndex={false}
                tabIndex={{ value: tabIndex, set: setTabIndex }}
                hideTabIndex={{ value: hideTabIndex, set: setHideTabIndex }}
                page={{
                  set: setPageNumber,
                  value: pageNumber,
                  hasMore: nextPage
                }}
              >
                <DataTable
                  tableStructure={tableStructure}
                  tableContent={[...filteredUsers]}
                  renderActions={renderActionsComponent}
                  displayListSize={false}
                  customSpacer="0px"
                />
              </TableNavigator>
            </div>
          </div>
          <div className={classes.signOutUserGroup}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.signOutUsersButton}
              onClick={() => setLogoutUsersDialog(true)}
              disabled={buttonLoading}
            >
              {t("Users.LogoutAllUsers")}
              {buttonLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <Typography variant="body2" className={classes.signOutUserText}>
              {LOGOUT_USERS_EXPLANATION}
            </Typography>
          </div>
        </Container>
        {editUser && (
          <EditUser
            createMode={editUser.createMode}
            user={editUser.user}
            userGroups={userGroups}
            close={closeEditUser}
          />
        )}
        {editUserSeats && (
          <EditUserSeats
            user={editUserSeats.user}
            locations={locations}
            coordinates={company.coordinateSystem}
            close={closeEditUserSeats}
          />
        )}
        {userPassword && (
          <SetUserPassword
            user={userPassword.user}
            close={closeSetUserPassword}
          />
        )}
        {resetPasswordInfo && (
          <ConfirmDialog
            title={t("Users.ResetPassword")}
            message={t("Users.ResetPasswordConfirmation", {
              user: `${resetPasswordInfo.user.firstName} ${resetPasswordInfo.user.lastName}`
            })}
            warning={t("Users.ResetPasswordInstruction")}
            onConfirm={confirmResetPassword}
            onCancel={() => {
              setResetPasswordInfo(null);
            }}
          />
        )}
        {deleteUserInfo && (
          <ConfirmDialog
            title={t("Users.DeleteUser")}
            message={t("Users.DeleteUserConfirmation", {
              user: `${deleteUserInfo.user.firstName} ${deleteUserInfo.user.lastName}`
            })}
            warning={t("Users.DeleteUserInstruction")}
            onConfirm={confirmDeleteUser}
            onCancel={() => {
              setDeleteUserInfo(null);
            }}
          />
        )}
        {logoutUsersDialog && (
          <ConfirmDialog
            title={t("Users.LogoutAllUsers")}
            heading={LOGOUT_USERS_EXPLANATION}
            message={LOGOUT_USERS_CONFIRMATION}
            onConfirm={handleLogoutAllUsers}
            onCancel={handleClose}
          />
        )}
        {errorMessage && (
          <MessageDialog
            title={t("Errors.GenericError")}
            message={errorMessage}
            close={() => {
              setErrorMessage("");
            }}
          />
        )}
      </div>
    );
  } else {
    return <LinearProgress />;
  }
}
