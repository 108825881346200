import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isNullOrUndefined } from "../../common";
import { useTranslation } from "react-i18next";
import { TextField, Tooltip } from "@material-ui/core";

const BaseSelector = ({
  _controller,
  _labelKey,
  _ajaxMethod,
  _optionLabelFormater,
  _idFieldName,
  _tooltip,
  _filter,
  _transform,
  _disabled
}) => {
  const [t] = useTranslation();
  const [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const isMultiple = Array.isArray(_controller.value);
  const attrib = isMultiple ? selectedItems : selectedItem;
  const setAttrib = isMultiple ? setSelectedItems : setSelectedItem;

  useEffect(() => {
    loadData();
  }, [_ajaxMethod]);

  useEffect(() => {
    if(_controller.value) {
      setAttrib(fromFireStore(_controller.value));
    } else {
      isMultiple ? setAttrib([]) : setAttrib(null);
    }
  }, [list]);

  const loadData = async () => {
    const _data = await _ajaxMethod();
    const _filteredData = _filter ? _filter(_data) : _data;
    setList(_filteredData);
  };

  const handleOnSelectValue = (newValue) => {
    setAttrib(newValue);
    _controller.setter(toFireStore(newValue));
  };

  const toFireStore = (obj) => {
    let value = isMultiple ? [] : null;
    if (!isNullOrUndefined(obj)) {
      value = isMultiple ? obj.map((o) => transformTo(o)) : transformTo(obj);
    }
    return value;
  };

  const transformTo = (singleObj) => {
    if (_transform && _transform.toFireStore) {
      return _transform.toFireStore(singleObj);
    }
    return singleObj[_idFieldName];
  };

  const fromFireStore = (obj) => {
    if (isMultiple) {
      const response = [];
      obj.map((id) => {
        const singleObj = transformFrom(id);
        !isNullOrUndefined(singleObj) && response.push(singleObj);
      });
      return response;
    }
    return transformFrom(obj);
  };

  const transformFrom = (singleObjId) => {
    if (_transform && _transform.fromFireStore) {
      return _transform.fromFireStore(singleObjId, list);
    }
    return list.find((o) => o[_idFieldName] === singleObjId);
  };

  return (
    <Autocomplete
      fullWidth
      disabled={_disabled}
      multiple={isMultiple}
      id={`select${t(_labelKey)}Widget`}
      defaultValue={attrib}
      options={list}
      value={attrib}
      getOptionLabel={(item) =>
        item && _optionLabelFormater && _optionLabelFormater(item)
      }
      onChange={(event, newValue) => {
        handleOnSelectValue(newValue);
      }}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={t(_labelKey)} variant="outlined" />
      )}
      renderOption={(option) => {
        return _tooltip ? (
          <Tooltip title={_tooltip(option)}>
            <span>{_optionLabelFormater(option)}</span>
          </Tooltip>
        ) : (
          _optionLabelFormater(option)
        );
      }}
    />
  );
};

BaseSelector.propTypes = {
  _disabled: PropTypes.bool,
  _transform: PropTypes.object,
  _controller: PropTypes.object.isRequired,
  _filter: PropTypes.func,
  _ajaxMethod: PropTypes.func,
  _optionLabelFormater: PropTypes.func,
  _tooltip: PropTypes.string,
  _labelKey: PropTypes.string.isRequired,
  _idFieldName: PropTypes.string.isRequired
};

export default BaseSelector;
