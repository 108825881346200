import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent
} from "@material-ui/core";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  colorSchemeWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  colorPickersWrapper: {
    display: "flex",
    marginTop: theme.spacing(3)
  },
  colorPickerWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "5px"
  },
  colourPickerGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "5px"
  },
  colourPicker: {
    display: "grid",
    gridTemplateColumns: "1.5fr 1fr",
    padding: "5px",
    border: "solid 1px #444"
  },
  pickerSpan: {
    gridColumn: "2/3",
    "& > div": {
      height: "75px",
      width: "100%",
      display: "grid",
      justifyContent: "center",
      alignContent: "center"
    },
    gridRow: "1/3"
  }
}));

export const colourDefaults = {
  primary: "#f7b51b",
  onPrimary: "#000000",
  secondary: "#494551",
  onSecondary: "#ffffff",
  background: "#fef8e2",
  onBackground: "#000000",
  surface: "#FFFFFF",
  onSurface: "#000000"
};

const VisualCustomization = React.memo(function VisualCustomization(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { className, company, ...restProps } = props;

  const [colours, setColours] = useState(colourDefaults);

  function updateColours(key) {
    return (value) => {
      const newColours = { ...colours };
      newColours[key] = value;
      company.themeData = newColours;
      setColours(newColours);
    };
  }

  function resetColours() {
    setColours({ ...colourDefaults });
    company.themeData = { ...colourDefaults };
  }

  useEffect(() => {
    if (company.themeData) {
      setColours(company.themeData);
    } else {
      company.themeData = { ...colourDefaults };
    }
  }, []);

  return (
    <Card className={className} {...restProps}>
      <CardHeader
        title={t("CompanyInfo.CompanyVisualIdentityCustomization")}
        subheader={t("CompanyInfo.CreateColorTheme")}
      />
      <CardContent>
        <div className={classes.colorSchemeWrapper}>
          <Typography variant="h6" gutterBottom>
            {t("CompanyInfo.ColorScheme")}
          </Typography>
          <div className={classes.colourPickerGrid}>
            <div className={classes.colourPicker}>
              <div className={classes.colorPickerWrapper}>
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("CompanyInfo.PrimaryColor")}
                </Typography>
                <ColorPicker
                  colorValue={colours.primary}
                  onChange={updateColours("primary")}
                />
              </div>
              <div className={classes.colorPickerWrapper}>
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("CompanyInfo.TextPrimaryColor")}
                </Typography>
                <ColorPicker
                  colorValue={colours.onPrimary}
                  onChange={updateColours("onPrimary")}
                />
              </div>
              <div className={classes.pickerSpan}>
                <div style={{ backgroundColor: colours.primary }}>
                  <Typography
                    variant="subtitle2"
                    style={{ marginRight: "8px", color: colours.onPrimary }}
                  >
                    {t("CompanyInfo.ExampleText")}
                  </Typography>
                </div>
              </div>
            </div>

            <div className={classes.colourPicker}>
              <div className={classes.colorPickerWrapper}>
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("CompanyInfo.SecondaryColor")}
                </Typography>
                <ColorPicker
                  colorValue={colours.secondary}
                  onChange={updateColours("secondary")}
                />
              </div>
              <div className={classes.colorPickerWrapper}>
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("CompanyInfo.TextSecondaryColor")}
                </Typography>
                <ColorPicker
                  colorValue={colours.onSecondary}
                  onChange={updateColours("onSecondary")}
                />
              </div>
              <div className={classes.pickerSpan}>
                <div style={{ backgroundColor: colours.secondary }}>
                  <Typography
                    variant="subtitle2"
                    style={{ marginRight: "8px", color: colours.onSecondary }}
                  >
                    {t("CompanyInfo.ExampleText")}
                  </Typography>
                </div>
              </div>
            </div>

            <div className={classes.colourPicker}>
              <div className={classes.colorPickerWrapper}>
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("CompanyInfo.BackgroundColor")}
                </Typography>
                <ColorPicker
                  colorValue={colours.background}
                  onChange={updateColours("background")}
                />
              </div>
              <div className={classes.colorPickerWrapper}>
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("CompanyInfo.TextBackgroundColor")}
                </Typography>
                <ColorPicker
                  colorValue={colours.onBackground}
                  onChange={updateColours("onBackground")}
                />
              </div>
              <div className={classes.pickerSpan}>
                <div style={{ backgroundColor: colours.background }}>
                  <Typography
                    variant="subtitle2"
                    style={{ marginRight: "8px", color: colours.onBackground }}
                  >
                    {t("CompanyInfo.ExampleText")}
                  </Typography>
                </div>
              </div>
            </div>

            <div className={classes.colourPicker}>
              <div className={classes.colorPickerWrapper}>
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("CompanyInfo.SurfaceColor")}
                </Typography>
                <ColorPicker
                  colorValue={colours.surface}
                  onChange={updateColours("surface")}
                />
              </div>
              <div className={classes.colorPickerWrapper}>
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("CompanyInfo.TextSurfaceColor")}
                </Typography>
                <ColorPicker
                  colorValue={colours.onSurface}
                  onChange={updateColours("onSurface")}
                />
              </div>
              <div className={classes.pickerSpan}>
                <div style={{ backgroundColor: colours.surface }}>
                  <Typography
                    variant="subtitle2"
                    style={{ marginRight: "8px", color: colours.onSurface }}
                  >
                    {t("CompanyInfo.ExampleText")}
                  </Typography>
                </div>
              </div>
            </div>

            <div className={classes.colourPickerSaveButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={resetColours}
              >
                {t("CompanyInfo.ResetColours")}
              </Button>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
});

VisualCustomization.propTypes = {
  className: PropTypes.string
  //   colorScheme: PropTypes.array.isRequired,
};

export default VisualCustomization;
