import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import ExpandMore from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(() => ({
  menuItem: {
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "24px",
    paddingTop: "14px",
    paddingBottom: "14px"
  },
  menuItemWrapper: {
    display: "flex",
    width: "100%"
  },
  menuText: {
    marginTop: 0,
    marginBottom: 0,
    "& span": {
      fontWeight: "bold"
    }
  },
  menuExpandIcon: {
    float: "right"
  }
}));

const LeftNavMenuItemFoldable = ({ item, index, onClickItem, children }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const Icon = item.icon;

  const onClickMenuItem = () => {
    onClickItem(item.key);
  };

  return (
    <ListItem button key={index} className={classes.menuItem}>
      <div className={classes.menuItemWrapper}  onClick={onClickMenuItem}>
        <ListItemIcon >
          <Icon />
        </ListItemIcon>
        <ListItemText className={classes.menuText} primary={t(item.textKey)}/>
        {item.isSubMenuOpen ? <ExpandMore className={classes.menuExpandIcon}/> : <NavigateNextIcon className={classes.menuExpandIcon}/>}
      </div>
      <Collapse component="li" in={item.isSubMenuOpen} timeout="auto" unmountOnExit>
        <List>
          {children}
        </List>
      </Collapse>
    </ListItem>
  );
};

LeftNavMenuItemFoldable.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClickItem: PropTypes.func.isRequired
};

export default LeftNavMenuItemFoldable;