import _firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/firebase-functions";
import "firebase/storage";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyA5nyK0eVb04ZWlTFMm7MyWxc5gKpJQtno",
  authDomain: "jonah-group-hiveoffice.firebaseapp.com",
  databaseURL: "https://jonah-group-hiveoffice.firebaseio.com",
  projectId: "jonah-group-hiveoffice",
  storageBucket: "jonah-group-hiveoffice.appspot.com",
  messagingSenderId: "584994211580",
  appId: "1:584994211580:web:2a1edcaf728914e08e3979",
  measurementId: "G-1MPH6J8GGQ"
};
// Initialize Firebase
_firebase.initializeApp(firebaseConfig);

const _storage = _firebase.storage();
// Export auth and firestore references
export const firebase = _firebase;
export const storage = _storage;
export const auth = _firebase.auth();
export const database = _firebase.firestore();

export const hiveIamUrl = "https://hiveidentity.web.app";
export const hiveSyncUrl = "https://hivesync.web.app";
export const hiveApiUrl = "https://hiveapi-v1.web.app";
export const hiveAppointmentUrl = "https://hive-appointment.web.app"
export const ssoUrl = `${hiveIamUrl}/oauth/login?source=hive-admin-web&company=`;
