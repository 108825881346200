import React from "react";
import { Route, Redirect } from "react-router";
import { isNullOrUndefined, isTrue } from "../common.js";
import { AppLayout } from "./AppLayout";
import { useLocalStorage } from "../hooks/useLocalStorage.js";

export const PrivateRoute = ({ path, allowedRoles, children, ...rest }) => {
  const localStorage = useLocalStorage();

  if (isNullOrUndefined(localStorage.getUserID())) {
    return <Redirect to="/signin" />;
  } else if (path !== "/verify-email" && !isTrue(localStorage.getEmailVerified())) {
    return <Redirect to="/verify-email" />;
  } else if (path !== "/company-info" && localStorage.getCreateCompanyName()) {
    return <Redirect to="/company-info" />;
  } else if (allowedRoles && !isTrue(localStorage.getUserRoles().some(role => allowedRoles.includes(role)))) {
    return <Redirect to="/" />;
  } else {
    return (
      <AppLayout>
        <Route path={path} {...rest}>{children}</Route>
      </AppLayout>
    );
  }
};
