import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Typography,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel, CircularProgress
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';
import { useLocalStore } from "../../hooks/useLocalStore";
import { useDispatch } from "react-redux";
import { setSuccessMessage, setErrorMessage, setWarningMessage } from "../../actions/index";
import { useCompanyService } from "../../services/useCompanyService";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { BOOKING_STATES } from "../../constants";
import { isEmpty } from "../../common";
import { grey } from "@material-ui/core/colors";
import CompanyUtil from "../../utils/CompanyUtil";
import HealthScreeningModal from '../common/HealthScreeningModal';
import LocationSelector from "../../components/Selectors/LocationSelector";
import UserSelector from "../../components/Selectors/UserSelector";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%"
  },
  wrapper: {
    margin: "20px 0"
  },
  card: {
    background: grey[100],
    maxWidth: "960px",
    maxHeight: "70vh"
  },
  panelContent: {
    maxHeight: "60vh",
    overflow: "auto"
  },
  table: {
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiTableRow-root": {
      cursor: "pointer"
    }
  },
  requestParams: {
    maxWidth: "960px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1.5fr",
    columnGap: "20px",
    rowGap: "20px"
  },
  exportParams: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gridColumnGap: "20px",
    alignItems: "center",
    padding: "20px"
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  selectButtonContainer: {
    maxWidth: "960px",
    margin: "20px 0",
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr",
    gridGap: "20px",
    "&>div": {
      display: "flex",
      "&>button": {
        marginRight: "13px"
      }
    },
    "&>div:last-of-type": {
      justifyContent: "flex-end",
      "&>button:last-of-type": {
        marginRight: "0"
      }
    }

  },
  selected: {
    backgroundColor:"#efe8d0"
  }
}));


export default function Reports() {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyService = useCompanyService();
  const localStore = useLocalStore();

  const [locationOptions, setLocationOptions] = useState([]);
  const [startingBookingDate, setStartingBookingDate] = useState(new Date());
  const [endingBookingDate, setEndingBookingDate] = useState(new Date());
  const [bookingLocation, setBookingLocation] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [bookingMap, setBookingMap] = useState({});
  const [showQuestions, setShowQuestions] = useState(false);

  const [questionnaire, setQuestionnaire] = useState([]);
  const [questionSetId, setQuestionSetId] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("ALL");

  const [loadingView, setLoadingView] = useState(false);
  const [selectedBookingIds, setSelectedBookingIds] = useState([]);
  const [checkingIn, setCheckingIn] = useState(false);
  const [anySelectedBookingsArePast, setAnySelectedBookingsArePast] = useState(false);

  const company = localStore.getCompany();
  const currentUser = localStore.getCurrentUser();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      const users = await companyService.getUsersFromDropdownListCollection();
      setUsers(users);
    };
    getUsers();
  }, []);

  useEffect(() => {
    const selectedBookings = selectedBookingIds.map((id) => {
      return bookingMap[id];
    });

    setAnySelectedBookingsArePast(selectedBookings.some((booking) => {
      const now = new Date();
      if (!isEmpty(booking.endTime)) {
        return booking.endTime.toDate() < now;
      } else {
        const yesterday = new Date();
        yesterday.setDate(now.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);
        return booking.date.toDate() <= yesterday;
      }
    }));
  }, [bookingMap, selectedBookingIds]);

  const loadData = async () => {
    if (company.coordinateSystem) {
      const notBottomLevelLocations = await companyService.getLocationOptions(company);
      setLocationOptions(notBottomLevelLocations);
    }

    await getVisitorBookings(
      startingBookingDate,
      endingBookingDate,
      bookingLocation ? bookingLocation.locationId : null,
      selectedUser ? selectedUser.userId : null,
      selectedStatus,
      {firstName: firstName, lastName: lastName}  
    );
  };

  const getVisitorBookings = async (
    startingBookingDate,
    endingBookingDate,
    location,
    userId,
    status,
    visitor
  ) => {
    setLoadingView(true);
    const _bookings = await companyService.getVisitorBookings(
      startingBookingDate,
      endingBookingDate,
      location,
      userId,
      status,
      visitor
    );
    setLoadingView(false);
    setBookings(_bookings);
    const _bookingMap = {};
    for (let b of _bookings) {
      _bookingMap[b.bookingId] = b;
    }
    setBookingMap(_bookingMap);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  function filterBookingItem(filterBool) {
    return (itemsList, bookingId) => {
      return itemsList.filter(item =>{
        let flag = filterBool;
        if (item === bookingId) {
          flag = !flag;
        }
        return flag;
      });
    };
  }

  const filterForBookingItem = filterBookingItem(false);
  const filterOutBookingItem = filterBookingItem(true);

  function checkForMatchingBooking(bookingId) {
    const list = filterForBookingItem(selectedBookingIds, bookingId);
    return selectedBookingIds.length !== 0 ? list.length > 0 : false;
  }

  function toggleBooking(bookingId) {
    if (checkForMatchingBooking(bookingId)) {
      const filteredBookings = filterOutBookingItem(selectedBookingIds,bookingId);
      setSelectedBookingIds([...filteredBookings]);
    } else {
      setSelectedBookingIds([...selectedBookingIds, bookingId]);
    }
  }

  function selectAllBookings() {
    setSelectedBookingIds(bookings.map(booking => booking.bookingId));
  }

  function deSelectAllBookings() {
    setSelectedBookingIds([]);
  }

  function toggleAllSelected(){
    if (selectedBookingIds.length === bookings.length) {
      deSelectAllBookings();
    } else {
      selectAllBookings();
    }
  }

  function doRefresh() {
    deSelectAllBookings();
    getVisitorBookings(
      startingBookingDate,
      endingBookingDate,
      bookingLocation ? bookingLocation.locationId : null,
      selectedUser ? selectedUser.userId : null,
      selectedStatus,
      {firstName: firstName, lastName: lastName}  
    );
  }

  function callOnSelectedBookings(ajaxMethod, messages) {
    return ()=>{

      const cancels = selectedBookingIds.map(bookingId => ajaxMethod(bookingId));

      Promise.all(cancels)
        .then(response => {
          if (response[0].data.success) {
            dispatch(setSuccessMessage(messages.success));
            doRefresh();
          } else {
            dispatch(setErrorMessage(messages.error));
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(setErrorMessage(messages.error));
        });
    };

  }

  const signOutBooking = callOnSelectedBookings(companyService.checkOutOfBooking,{
    success: t("Success.UpdateBooking"),
    error: t("Errors.UpdateBooking")
  });

  const checkInBooking = async () => {
    
    // validation
    const errMsg = t("Errors.Selection");
    if (selectedBookingIds.length !== 1) {
      dispatch(setErrorMessage(errMsg));
      return;
    }
    
    const booking = bookingMap[selectedBookingIds[0]];

    if (isEmpty(booking)) {
      dispatch(setErrorMessage(errMsg));
      return;
    } else if (booking.state !== BOOKING_STATES.CONFIRMED && booking.state !== BOOKING_STATES.INVITED) {
      dispatch(setErrorMessage(t('Errors.CheckIn', {state: booking.state})));
      return;
    }

    setCheckingIn(true);
    
    // questions
    let _questionSet = await companyService.getQuestionsForBooking(booking.bookingId, booking.userId);

    if (_questionSet?.data?.success && !isEmpty(_questionSet.data.questionnaire)) {
      setQuestionnaire(_questionSet.data.questionnaire);
      setQuestionSetId(_questionSet.data.id);
      setShowQuestions(true);
      return;
    } else {
      handleResponse(_questionSet);
      return;
    }
  };

  const handleQuestionSubmit = async (answers, questionSetId) => {

    const booking = bookingMap[selectedBookingIds[0]];

    const isAppointment = false;
    const healthScreeningResponse = await companyService.submitHealthScreening(
      booking, 
      questionSetId, 
      answers,
      isAppointment
    );

    if (!healthScreeningResponse.data.pass) {
      const response = await companyService.sendHealthNotificationAdmin(
        bookingMap[selectedBookingIds[0]],
        company,
        questionSetId
      );
      handleQuestionnaireFailureResponse(response);
    } else {
      const response = await companyService.checkIntoBooking(selectedBookingIds[0], currentUser.email, questionSetId, true);
      handleResponse(response);
    }
    setShowQuestions(false);
  };

  const handleQuestionnaireFailureResponse = (response) => {
    setCheckingIn(false);
    if (response.data.success) {
      dispatch(setWarningMessage(t('Questionnaire.Notified')));
      doRefresh();
    } else {
      const path = "Errors.Cloud."+response.data.message;
      const error = i18n.exists(path) ? t(path) : t('Errors.General');
      dispatch(setErrorMessage(error));
    }
  };

  const handleResponse = (response) => {
    setCheckingIn(false);
    if (response.data.success) {
      const path = "Success.Cloud."+response.data.message;
      const success = i18n.exists(path) ? t(path) : t('Success.General');
      dispatch(setSuccessMessage(success));
      doRefresh();
    } else {
      const path = "Errors.Cloud."+response.data.message;
      const error = i18n.exists(path) ? t(path) : t('Errors.General');
      dispatch(setErrorMessage(error));
    }
  };

  const handleCancel = () => {
    setShowQuestions(false);
    setCheckingIn(false);
  };

  return (
    <div className="content">
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h3">{t("Visitors.VisitorsManagement")}</Typography>
        <Accordion className={classes.card}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{t("Visitors.AdvancedVisitorSearch")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              className={classnames({
                [classes.requestParams]: true,
                [classes.wrapper]: true
              })}
            >
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="MM/dd/yyyy"
                label={t("Commons.StartDate")}
                name="startingBookingDate"
                maxDate={endingBookingDate}
                value={startingBookingDate}
                onChange={(date) => {
                  setStartingBookingDate(date);
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="MM/dd/yyyy"
                label={t("Commons.EndDate")}
                name="endingBookingDate"
                minDate={startingBookingDate}
                value={endingBookingDate}
                onChange={(date) => {
                  setEndingBookingDate(date);
                }}
              />
              <LocationSelector 
                contentList={locationOptions}
                company={company}
                controller={{
                  value: bookingLocation, 
                  setter: setBookingLocation}} 
                labelKey={"Commons.Location"}
              />
              <UserSelector
                contentList={users}
                labelKey={t("Commons.User")}
                controller={{
                  value: selectedUser,
                  setter: setSelectedUser
                }}
              />
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="status-label">{t("Commons.Status")}</InputLabel>
                <Select
                  labelId="status-label"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  label="status"
                  className={classes.filter}
                  autoWidth={true}
                >
                  <MenuItem value="ALL">{t("Commons.AllStatus")}</MenuItem>
                  <MenuItem value={BOOKING_STATES.CONFIRMED}>{t("Commons.Confirmed")}</MenuItem>
                  <MenuItem value={BOOKING_STATES.CHECKED_IN}>{t("Commons.CheckedIn")}</MenuItem>
                  <MenuItem value={BOOKING_STATES.CHECKED_OUT}>{t("Commons.CheckedOut")}</MenuItem>
                  <MenuItem value={BOOKING_STATES.INVITED}>{t("Visitors.Invited")}</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                disabled={loadingView}
                onClick={() => {
                  getVisitorBookings(
                    startingBookingDate,
                    endingBookingDate,
                    bookingLocation ? bookingLocation : null,
                    selectedUser ? selectedUser : null,
                    selectedStatus,
                    { firstName: firstName, lastName: lastName }
                  );
                }}
              >
                {t("Visitors.ViewVisitors")}
                {loadingView && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>

        <div
          className={classnames({
            [classes.requestParams]: true,
            [classes.wrapper]: true
          })}
        >
          <TextField
            className={classes.textField}
            variant="outlined"
            label={t("Visitors.VisitorFirstName")}
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            label={t("Visitors.VisitorLastName")}
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={loadingView}
            onClick={() => {
              getVisitorBookings(
                startingBookingDate,
                endingBookingDate,
                bookingLocation ? bookingLocation.locationId : null,
                selectedUser ? selectedUser.userId : null,
                selectedStatus,
                { firstName: firstName, lastName: lastName }
              );
            }}
          >
            {t("Visitors.SearchVisitorName")}
            {loadingView && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </div>

        <div className={classes.selectButtonContainer}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleAllSelected}
            >{t("Commons.SelectAll")}
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={checkInBooking}
              disabled={selectedBookingIds.length > 1 || checkingIn || anySelectedBookingsArePast}
            >
              {t("Visitors.CheckInBookings")}
              {checkingIn && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={signOutBooking}
            >{t("Visitors.CheckOutBookings")}
            </Button>
          </div>
        </div>

        {company && bookings && (
          <Card className={classes.card}>
            <div className={classes.panelContent}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label={t("Visitors.Bookings")}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Visitors.Select")}</TableCell>
                      <TableCell>{t("Commons.Visitor")}</TableCell>
                      <TableCell>{t("Commons.Date")}</TableCell>
                      <TableCell>{t("Commons.User")}</TableCell>
                      <TableCell>{t("Commons.Location")}</TableCell>
                      <TableCell>{t("Commons.Status")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookings.length > 0 ? (
                      bookings.map((booking, index) => (
                        <TableRow
                          key={index}
                          className={checkForMatchingBooking(booking.bookingId) ? classes.selected : ""}
                          onClick={() => toggleBooking(booking.bookingId)}
                          hover={true}
                        >
                          <TableCell>
                            <Checkbox
                              checked={checkForMatchingBooking(booking.bookingId)}
                              value={booking.locationId}
                              onClick={() => toggleBooking(booking.bookingId)}
                              color="primary"
                            ></Checkbox>
                          </TableCell>
                          <TableCell>
                            {booking.visitor.firstName}{" "}
                            {booking.visitor.lastName}
                          </TableCell>
                          <TableCell>
                            {booking.date.toDate().toDateString()}
                          </TableCell>
                          <TableCell>{booking.username}</TableCell>
                          <TableCell>
                            {CompanyUtil.getStringForCoords(company, booking.coordinate)}
                          </TableCell>
                          <TableCell>{booking.state}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align={"center"} colSpan={5}>
                          {t("Visitors.NoBookingsFound")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Card>
        )}
      </Container>
      {showQuestions && !isEmpty(questionnaire) && (
        <HealthScreeningModal 
          title={t("Visitors.HealthScreening")} 
          questionnaire={questionnaire}
          questionSetId={questionSetId}
          handleCancel={handleCancel}
          handleSubmit={handleQuestionSubmit}
        />
      )}
    </div>
  );
}
