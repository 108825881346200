import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useCompanyService } from "../../services/useCompanyService";
import { setErrorMessage } from "../../actions/index";
import { firebase } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  fileSelectorContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    "&>div:first-of-type": {
      "align-self": "self-start",
      "margin-top": "0"
    },
    "&>div:last-of-type": {
      display: "grid",
      gridTemplateRows: "1fr 300px"
    },
    "& textarea": {
      height: "300px"
    }
  },
  fileSelector: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2)
  },
  selectFileButtonWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    padding: "2px"
  },
  selectFileButtonOrigin: {
    width: "100%",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0
  },
  fileName: {
    marginLeft: theme.spacing(1)
  }
}));

const TermsAndConditions = React.memo(
  ({ className, company, ...rest }) => {
    const classes = useStyles();
    const companyService = useCompanyService();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [termsAndConditions, setTermsAndConditions] = useState();

    const handleSelectFileChange = useCallback((e, company, location) => {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = function (fileData) {
        const data = {
          name: file.name,
          type: file.type,
          companyId: company.companyId,
          blob: reader.result
        };

        companyService
          .uploadTaC(data)
          .then(async (data) => {
            company.tacFileName = file.name;
            company.tacVersion = firebase.firestore.Timestamp.fromDate(
              new Date()
            );
            await companyService.updateCompany(company);
            setTermsAndConditions("");
          })
          .catch((error) => {
            console.log("Error updating the TAC", error);
            dispatch(setErrorMessage(t("Errors.General")));
          });
      };
      reader.readAsText(file);
    }, []);

    useEffect(() => {
      if (!termsAndConditions && company.tacFileName) {
        companyService
          .getTermsAndConditions({
            companyId: company.companyId,
            fileName: company.tacFileName
          })
          .then((results) => {
            setTermsAndConditions(results.data.message);
          })
          .catch((error) => {
            console.log("Error getting TAC.", error);
            dispatch(setErrorMessage(t("Errors.RetrieveTermsAndConditions")));
          });
      }
    });

    return (
      <Card className={className} {...rest}>
        <CardHeader title={t("CompanyInfo.TermsConditions")} />
        <CardContent>
          <div className={classes.fileSelectorContainer}>
            <div className={classes.fileSelector}>
              <div className={classes.selectFileButtonWrapper}>
                <Button variant="contained" color="primary">
                  {t("CompanyInfo.UploadTermsConditions")}
                </Button>
                <input
                  type="file"
                  className={classes.selectFileButtonOrigin}
                  onChange={(e) => handleSelectFileChange(e, company)}
                  accept="text/plain, .md"
                ></input>
              </div>
            </div>
            <div>
              <Typography variant="h3">{company.tacFileName}</Typography>
              <textarea value={termsAndConditions} disabled></textarea>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
);

TermsAndConditions.propTypes = {
  className: PropTypes.string
};

export default TermsAndConditions;
