import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { useCompanyService } from "../../services/useCompanyService";
import { grey } from "@material-ui/core/colors";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { setSuccessMessage, setErrorMessage, setWarningMessage } from "../../actions/index";
import { LocationMapListSelector } from "../LocationMapPlus/LocationMapListSelector";
import {
  Card,
  Typography,
  TextField,
  Button,
  CircularProgress
} from "@material-ui/core";
import classnames from "classnames";
import UserSelector from "../../components/Selectors/UserSelector";
import CompanyUtil from "../../utils/CompanyUtil";
import DateTimeUtil from "../../utils/DateTimeUtil";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%"
  },
  wrapper: {
    margin: "20px 0"
  },
  card: {
    margin: "1em 0",
    padding: "1em"
  },
  bookingContainer: {
    display: "grid",
    gridTemplateColumns: "150px 1fr",
    "&>div:nth-of-type(odd)": {
      display: "grid",
      alignContent: "center"
    }
  },
  dateAndUserContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1em"
  },
  commentContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "1em"
  },
  makeBooking: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "1em",
    "&>div:nth-of-type(1n+5)": {
      border: "solid 2px #f5ce49",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "auto",
      padding: "1rem"
    },
    "&>div:last-of-type": {
      display: "grid",
      justifyContent: "end",
      border: "none",
      "&>button": {
        width: "20em"
      }
    }
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  mapContainer: {
    overflowY: "auto"
  }
}));

export default function CreateBooking({ company, usersList }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const companyService = useCompanyService();
  const [bookingDate, setBookingDate] = useState(DateTimeUtil.now());
  const [chosenSeat, setChosenSeat] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [comment, setComment] = useState("");
  const [loadingView, setLoadingView] = useState(false);
  const [t, i18next] = useTranslation();

  async function createBooking() {
    setLoadingView(true);

    const startOfDayInRegion = DateTimeUtil.startOfDayInRegion(
      bookingDate,
      chosenSeat.timeZone.region
    );

    const _user = await companyService.getUserById(selectedUser.id);

    const data = {
      locationId: chosenSeat.locationId,
      date: startOfDayInRegion.toISOString(),
      username: _user.firstName + " " + _user.lastName,
      email: _user.email,
      userId: selectedUser.id,
      userExternalId: _user.externalId || null,
      coordinate: chosenSeat.coordinate,
      locationType: chosenSeat.locationType,
      adjacentLocation: chosenSeat.adjacentLocation,
      comment,
      source: "hive-admin-web"
    };

    companyService
      .createBooking(data)
      .then((response) => {
        if (response.data.success) {
          dispatch(setSuccessMessage(t("Success.CreateBooking")));
          setBookingDate(DateTimeUtil.now());
          setSelectedUser(null);
          setChosenSeat(null);
        } else {
          const details = i18next.exists(
            "Errors.Cloud." + response.data.message
          )
            ? t("Errors.Cloud." + response.data.message)
            : "";
          dispatch(setWarningMessage(`${t("Errors.CreateBooking")} ${details}`));
        }
        console.log(response);
      })
      .catch((error) => {
        dispatch(setErrorMessage(t("Errors.CreateBooking")));
        console.log(error);
      })
      .finally(() => setLoadingView(false));
  }

  const transformUser = {
    fromFireStore: (user, list) => {
      return list.find((u) => u.userId === user.id);
    },
    toFireStore: (user) => {
      return { displayName: user.displayName, id: user.userId };
    }
  };

  return (
    <div className={classes.bookingContainer}>
      <div>
        <Typography variant="h4">{t("Booking.StepOne")}</Typography>
      </div>
      <div>
        <Typography variant="h5">{t("Booking.PickDateUser")}</Typography>
        <Card className={classes.card}>
          <div
            className={classnames({
              [classes.dateAndUserContainer]: true,
              [classes.wrapper]: true
            })}
          >
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              format="MM/dd/yyyy"
              label={t("Commons.StartDate")}
              name="startingBookingDate"
              value={bookingDate}
              onChange={(value) => setBookingDate(DateTimeUtil.dateOnly(value))}
              helperText={t("Booking.DateOfBookingAndLocation")}
            />
            <UserSelector
              contentList={usersList}
              labelKey={"Commons.User"}
              transform={transformUser}
              controller={{
                value: selectedUser,
                setter: setSelectedUser
              }}
            />
          </div>
        </Card>
      </div>
      <div>
        <Typography variant="h4">{t("Booking.StepTwo")}</Typography>
      </div>
      <div>
        <Typography variant="h5">{t("Booking.PickSeat")}</Typography>
        <Card className={classnames([classes.mapContainer, classes.card])}>
          <div>
            <LocationMapListSelector updateSeat={setChosenSeat} />
          </div>
        </Card>
      </div>
      <div>
        <Typography variant="h4">{t("Booking.StepThree")}</Typography>
      </div>
      <div>
        <Typography variant="h5">{t("Commons.Comment")}</Typography>
        <Card className={classes.card}>
          <TextField
            className={classes.commentContainer}
            variant="outlined"
            label={t("Booking.LeaveComment")}
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Card>
      </div>
      <div>
        <Typography variant="h4">{t("Booking.StepFour")}</Typography>
      </div>
      <div>
        <Typography variant="h5">{t("Booking.CurrentBookingInfo")}</Typography>
        <Card className={classes.card}>
          <div
            className={classnames({
              [classes.makeBooking]: true,
              [classes.wrapper]: true
            })}
          >
            <div>
              <Typography variant="h5">{t("Commons.Date")}</Typography>
            </div>
            <div>
              <Typography variant="h5">{t("Commons.UserName")}</Typography>
            </div>
            <div>
              <Typography variant="h5">{t("Booking.SeatLocation")}</Typography>
            </div>
            <div></div>
            <div>
              <Typography variant="body1">
                {bookingDate.toDateString()}
              </Typography>
            </div>
            <div>
              <Typography variant="body1">
                {selectedUser ? `${selectedUser.displayName}` : ""}
              </Typography>
            </div>
            <div>
              <Typography variant="body1">
                {
                  // prettier-ignore
                  company && chosenSeat && chosenSeat.coordinate ? 
                    CompanyUtil.getStringForCoords(company, chosenSeat.coordinate) : ""
                }
              </Typography>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  loadingView ||
                  !(
                    bookingDate &&
                    selectedUser &&
                    chosenSeat &&
                    chosenSeat.coordinate
                  )
                }
                onClick={createBooking}
              >
                {t("Booking.CreateBooking")}
                {loadingView && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

CreateBooking.propTypes = {
  company: PropTypes.object.isRequired,
  usersList: PropTypes.array.isRequired
};
