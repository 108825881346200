import { isEmpty } from "../common";

export default class CompanyUtil {
  static getStringForCoords(company, coordinates) {
    return CompanyUtil.getStringForBookingCoords(
      company.coordinateSystem,
      coordinates
    );
  }

  static getStringForBookingCoords(companyCoordinates, coordinates) {
    const locationLevels = [];

    for (let level of companyCoordinates) {
      if (!isEmpty(coordinates[level])) {
        locationLevels.push(coordinates[level]["displayName"]);
      }
    }

    return isEmpty(locationLevels) ? "" : locationLevels.join(" | ");
  }
}
