import React, { useEffect, useState } from "react";
import { 
  Accordion, 
  AccordionDetails, 
  AccordionSummary, 
  Card, 
  Typography, 
  Button, 
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Delete from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ValidatedTimePicker from "../../components/Selectors/ValidatedTimePicker";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(2),
    width: "25%"
  },
  time: {
    margin: theme.spacing(10),
    width: "20%"
  },
  defaultCheckBox: {
    margin: theme.spacing(2),
    width: "15%"
  },
  deleteButton: {
    margin: theme.spacing(2)
  },
  shiftsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "flex-start"
  },
  shiftCard: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between"
  },
  accordionSummary: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around"
  },
  title: {
    margin: theme.spacing(0.3)
  }
}));

const CustomBookingShifts = ({ className, company, setCompanyField, ...rest }) => {

  const [t, i18n] = useTranslation();
  const classes = useStyles();

  const [currentDefault, setCurrentDefault] = useState(null);

  useEffect(() => {
    company.bookingShifts ? 
      company.bookingShifts.forEach((shift, index) => shift.isDefault && setCurrentDefault(index)) 
      : 
      setCompanyField("bookingShifts", []);
  });

  const add = () => {
    const _bookingShifts = [...company.bookingShifts];
    _bookingShifts.push({ name: { en: "", fr: "" }, startTime: "00:00", endTime: "23:30", isDefault: false});
    setCompanyField("bookingShifts", _bookingShifts);
  };

  const remove = (index) => {
    const _bookingShifts = [...company.bookingShifts];
    _bookingShifts.splice(index, 1);
    setCompanyField("bookingShifts", _bookingShifts);
  };

  const checkStartTimeValid = (value, endTime) => {
    const endTimeDate = stringToDate(endTime);
    return endTimeDate ? value < endTimeDate : true;
  };

  const setStartTime = (value, index) => {
    const _bookingShifts = [...company.bookingShifts];
    if (value) {
      _bookingShifts[index].startTime = `${value.getHours().toString()}:${formatMinutes(value.getMinutes())}`;
    } else {
      _bookingShifts[index].startTime = "00:00";
    }
    setCompanyField("bookingShifts", _bookingShifts);
  };

  const checkEndTimeValid = (value, startTime) => {
    const startTimeDate = stringToDate(startTime);
    return startTimeDate ? value > startTimeDate : true;
  };

  const setEndTime = (value, index) => {
    const _bookingShifts = [...company.bookingShifts];
    if (value) {
      _bookingShifts[index].endTime = `${value.getHours().toString()}:${formatMinutes(value.getMinutes())}`;
    } else {
      _bookingShifts[index].endTime = "23:30";
    }
    setCompanyField("bookingShifts", _bookingShifts);
  };

  const setDefault = (value, index) => {
    const _bookingShifts = [...company.bookingShifts];
    if (currentDefault === null) {
      setCurrentDefault(index);
      _bookingShifts[index].isDefault = true;
    } else if (index === currentDefault) {
      setCurrentDefault(null);
      _bookingShifts[index].isDefault = false;
    } else {
      _bookingShifts[currentDefault].isDefault = false;
      setCurrentDefault(index);
      _bookingShifts[index].isDefault = true;
    }
    setCompanyField("bookingShifts", _bookingShifts);
  };

  const setName = (value, index) => {
    const _bookingShifts = [...company.bookingShifts];
    _bookingShifts[index].name[i18n.language] = value;
    setCompanyField("bookingShifts", _bookingShifts);
  };

  const stringToDate = (dateStr) => {
    if (dateStr) {
      const splitDate = dateStr.split(":");
      return new Date(0, 0, 0, splitDate[0], splitDate[1]);
    } else {
      return new Date(0, 0, 0, 0, 0);
    }
    
  };

  const formatMinutes = (minutes) => {
    return minutes < 10 ? "0" + minutes.toString() : minutes.toString(); 
  };

  const getMultiLanguageName = (name) => {
    return name[i18n.language] ? name[i18n.language] : name.en;
  };

  const renderShift = (shift, index) => {
    return (
      <Card className={classes.shiftCard}>
        <TextField
          required 
          label={t("CompanyInfo.BookingShiftName")} 
          variant="outlined" 
          className={classes.textField} 
          value={getMultiLanguageName(shift.name)}
          onChange={(event) => setName(event.target.value, index)}
        />
        <ValidatedTimePicker
          label={t("CompanyInfo.BookingShiftStartTime")}
          value={stringToDate(shift.startTime)}
          className={classes.time}
          checkValid={(value) => checkStartTimeValid(value, shift.endTime)}
          setValue={(value) => setStartTime(value, index)}
          errorMessage={t("CompanyInfo.BookingShiftStartTimeError")}
          minuteStep={30}
        />
        <ValidatedTimePicker
          label={t("CompanyInfo.BookingShiftEndTime")}
          value={stringToDate(shift.endTime)}
          className={classes.time}
          checkValid={(value) => checkEndTimeValid(value, shift.startTime)}
          setValue={(value) => setEndTime(value, index)}
          errorMessage={t("CompanyInfo.BookingShiftEndTimeError")}
          minuteStep={30}
        />
        <FormControlLabel 
          label={t("CompanyInfo.BookingShiftDefault")}
          className={classes.defaultCheckBox} 
          control={<Checkbox checked={shift.isDefault} onChange={() => setDefault(shift, index)} color="primary"/>}
        />
        <IconButton 
          onClick={() => remove(index)} 
          className={classes.deleteButton}
        >
          <Delete />
        </IconButton>
      </Card>
    );
  };

  return (
    <Card className={className} {...rest}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className={classes.accordionSummary}>
            <Typography variant="h5" className={classes.title}>{t("CompanyInfo.BookingShifts")}</Typography>
            <Typography variant="body2"className={classes.title}>{t("CompanyInfo.BookingShiftSubtitle")}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.shiftsContainer}>
            {company.bookingShifts && company.bookingShifts.map((shift, index) => renderShift(shift, index))}
            <Button 
              variant="contained" 
              color="primary" 
              onClick={add}
            >
              {t("CompanyInfo.BookingShiftAddShift")}
              <AddIcon />
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default CustomBookingShifts;