import React, { useState, useEffect } from "react";
import { useCompanyService } from "../../services/useCompanyService";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { debounce } from "../../common";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";
import { AppointmentTypeEditor } from "./AppointmentTypeEditor";
import DataTable from "../../components/DataTable/DataTable";
import SearchBar from "../../components/SearchBar/SearchBar";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Container, Typography, Button } from "@material-ui/core";

const appointmentTableStructure = [
  {
    columnNamekey: "Appointments.Name",
    fieldName: "displayName",
    multiLanguage: true
  },
  {
    columnNamekey: "Appointments.Description",
    fieldName: "description",
    multiLanguage: true
  }
];

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  fullWidth: {
    width: "100%"
  },
  toolbar: {
    display: "flex",
    alignItems: "start",
    marginTop: theme.spacing(5),
    gap: "15px",
    "justify-content": "space-between",
    "&>*": {
      width: "30%"
    }
  },
  addResourceButton: {
    margin: theme.spacing(3, 0)
  }
}));

export default function AppointmentType() {
  const classes = useStyles();
  const companyService = useCompanyService();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [filteredAppointmentTypes, setFilteredAppointmentTypes] = useState([]);
  const [appointmentTypeInfo, setAppointmentTypeInfo] = useState(null);
  const [deleteCandidate, setDeleteCandidate] = useState(null);

  useEffect(() => {
    let localFilteredAppointmentTypes = appointmentTypes.filter((a) =>
      a.displayName[i18n.language]
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );
    setFilteredAppointmentTypes(localFilteredAppointmentTypes);
  }, [searchValue]);

  useEffect(() => {
    loadData();
  }, [shouldReload]);

  const loadData = async () => {
    const appointmentTypesData = await companyService.getAppointmentTypes();
    setAppointmentTypes(appointmentTypesData);
    setFilteredAppointmentTypes(appointmentTypesData);
  };

  const cancelEditAppointmentType = () => {
    let _info = { ...appointmentTypeInfo };
    _info.openForm = false;
    setAppointmentTypeInfo(null);
  };

  const saveAppointmentType = (_appointmentType) => {
    setSpinnerOn(true);
    if (appointmentTypeInfo.createMode) {
      createAppointmentType(_appointmentType);
    } else {
      updateAppointmentType(_appointmentType);
    }
    setSpinnerOn(false);
    setAppointmentTypeInfo(null);
  };

  const createAppointmentType = async (data) => {
    await companyService
      .createAppointmentType(data)
      .then((response) => {
        if (!response.data.success) {
          dispatch(setErrorMessage(t("Errors.CreateAppointmentType")));
        } else {
          dispatch(setSuccessMessage(t("Success.CreateAppointmentType")));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage(t("Errors.CreateAppointmentType")));
        console.log("error occurred trying to update appointmentType:", error);
      })
      .finally(() => {
        setShouldReload(!shouldReload);
      });
  };

  const updateAppointmentType = async (data) => {
    await companyService
      .updateAppointmentType(data)
      .then((response) => {
        if (!response.data.success) {
          dispatch(setErrorMessage(t("Errors.CreateAppointmentType")));
        } else {
          dispatch(setSuccessMessage(t("Success.CreateAppointmentType")));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage(t("Errors.CreateAppointmentType")));
        console.log("error occurred trying to update appointment type:", error);
      })
      .finally(() => {
        setShouldReload(!shouldReload);
      });
  };

  const deleteAppointmentType = async () => {
    await companyService
      .deleteAppointmentType(deleteCandidate.appointmentType)
      .then((response) => {
        if (!response.data.success) {
          dispatch(setErrorMessage(t("Errors.DeleteAppointmentType")));
        } else {
          const index = deleteCandidate.index;
          const _appointmentTypes = [...filteredAppointmentTypes];
          _appointmentTypes.splice(index, 1);
          setFilteredAppointmentTypes(_appointmentTypes);
          setDeleteCandidate(null);
          dispatch(setSuccessMessage(t("Success.DeleteAppointmentType")));
        }
      })
      .catch((error) => {
        dispatch(setErrorMessage(t("Errors.DeleteAppointmentType")));
        console.log("error occurred trying to update appointment type:", error);
      })
      .finally(() => {
        setShouldReload(!shouldReload);
      });
  };

  const addAppointmentType = () => {
    setAppointmentTypeInfo(null);
    setAppointmentTypeInfo({
      openForm: true,
      createMode: true,
      data: {
        appointmentLength: 0,
        availableStartTimes: [],
        displayName: { en: "", fr: "" },
        description: { en: "", fr: "" }
      }
    });
  };

  const editAppointmentType = (_appointmentType, _index) => {
    setAppointmentTypeInfo(null);
    setAppointmentTypeInfo({
      openForm: true,
      createMode: false,
      index: _index,
      data: { ..._appointmentType }
    });
  };

  const confirmDeleteAppointmentType = (_appointmentType, _index) => {
    setDeleteCandidate({
      appointmentType: _appointmentType,
      index: _index
    });
  };

  const onTextSearch = (value) => {
    setSearchValue(value);
  };
  const debounceTextSearch = debounce(onTextSearch, 600);

  const actionButtons = [
    {
      toolTipKey: "Commons.Edit",
      icon: <EditIcon />,
      func: editAppointmentType
    },
    {
      toolTipKey: "Appointments.DeleteAppointmentType",
      icon: <DeleteIcon />,
      func: confirmDeleteAppointmentType
    }
  ];

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h3">
          {t("Appointments.AppointmentTypes")}
        </Typography>
        <div className={classes.toolbar}>
          <SearchBar onSearch={debounceTextSearch} />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.addResourceButton}
            onClick={addAppointmentType}
          >
            {t("Appointments.AddAppointmentType")}
          </Button>
        </div>
        <DataTable
          tableStructure={appointmentTableStructure}
          tableContent={filteredAppointmentTypes}
          selectedRows={{ value: selectedRows, setter: setSelectedRows }}
          actions={actionButtons}
        />
      </Container>
      {appointmentTypeInfo && (
        <AppointmentTypeEditor
          appointmentTypeInfo={appointmentTypeInfo}
          spinnerOn={spinnerOn}
          actionButtons={{
            cancel: cancelEditAppointmentType,
            save: saveAppointmentType
          }}
        />
      )}
      {deleteCandidate && (
        <ConfirmDialog
          title={t("Appointments.DeleteAppointmentType")}
          message={t("Appointments.DeleteAppointmentTypeConfirmation", {
            resource: `${deleteCandidate.appointmentType.displayName}`
          })}
          warning={t("Appointments.DeleteAppointmentTypeInstruction")}
          onConfirm={deleteAppointmentType}
          onCancel={() => {
            setDeleteCandidate(null);
          }}
        />
      )}
    </>
  );
}
