import React, { useEffect, useState } from "react";
import { useCompanyService } from "../../services/useCompanyService";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Typography,
  LinearProgress,
  Button
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Delete from "@material-ui/icons/Delete";
import { EditUserGroup } from "./EditUserGroup";
import { DeleteUserGroup } from "./DeleteUserGroup";
import { useTranslation } from "react-i18next";
import DataTable, { cellWithChips } from "../../components/DataTable/DataTable";
import { setSuccessMessage, setErrorMessage } from "../../actions";
import { useDispatch } from "react-redux";
import { QuickAddUserGroup } from "./QuickAddUserGroup";

const userGroupsTableStructure = [
  {
    columnNamekey: "UserGroups.GroupName",
    fieldName: "name"
  },
  {
    columnNamekey: "UserGroups.Admins",
    fieldName: "admins",
    customCellRenderer: renderAdmin
  }
];

function renderAdmin(group) {
  return cellWithChips(group, "admins");
}

const addButtonStyle = {
  margin: "1.5rem 0"
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  card: {
    maxWidth: "960px",
    height: "70vh",
    marginTop: theme.spacing(3)
  },
  panelHeader: {
    backgroundColor: "#fafafa"
  },
  panelContent: {
    height: "calc(70vh - 49px)",
    overflow: "auto"
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export const UserGroups = () => {
  const classes = useStyles();
  const companyService = useCompanyService();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [userGroups, setUserGroups] = useState(null);
  const [editUserGroupInfo, setEditUserGroupInfo] = useState(null);
  const [quickAddUserGroupInfo, setQuickAddUserGroupInfo] = useState(null);
  const [deleteUserGroupInfo, setDeleteUserGroupInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [requireApprovalGroupId, setRequireApprovalGroupId] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    companyService.getUserGroups().then((userGroups) => {
      setUserGroups(userGroups);
    });
    loadData();
    setIsLoading(false);
  }, []);

  const loadData = async () => {
    const approvalGroups = await companyService.getRequireApprovalGroups();
    if (approvalGroups.length > 0) {
      setRequireApprovalGroupId(approvalGroups[0].userGroupId);
    }

    const otherGroups = await companyService.getUserGroups();
    const newList = [...approvalGroups, ...otherGroups];
    setUserGroups(newList);
  };

  const add = () => {
    setEditUserGroupInfo({
      createMode: true,
      userGroup: {}
    });
  };

  const edit = (userGroup, index) => {
    setEditUserGroupInfo({
      createMode: false,
      userGroup: userGroup,
      index: index
    });
  };

  const quickAdd = (userGroup, index) => {
    setQuickAddUserGroupInfo({
      userGroup: userGroup,
      index: index
    });
  };

  const remove = (userGroup, index) => {
    setDeleteUserGroupInfo({
      userGroup: userGroup,
      index: index
    });
  };

  const isDisabled = (userGroup) => {
    if (requireApprovalGroupId && userGroup) {
      return userGroup.userGroupId === requireApprovalGroupId;
    }
    return false;
  };

  const actions = [
    {
      toolTipKey: "Commons.Edit",
      icon: <EditIcon />,
      func: edit
    },
    {
      toolTipKey: "Commons.Add",
      icon: <GroupAddIcon />,
      func: quickAdd
    },
    {
      toolTipKey: "Commons.Delete",
      disabled: isDisabled,
      icon: <Delete />,
      func: remove
    }
  ];

  const closeEditUserGroup = (userGroup) => {
    if (userGroup) {
      let _userGroups = [...userGroups];

      if (editUserGroupInfo.createMode) {
        _userGroups.push(userGroup);
      } else {
        _userGroups[editUserGroupInfo.index] = userGroup;
      }

      setUserGroups(_userGroups);
    }
    setEditUserGroupInfo(null);
  };

  const closeQuickAddUserGroup = (userGroup) => {
    if (userGroup) {
      let _userGroups = [...userGroups];

      _userGroups[quickAddUserGroupInfo.index] = userGroup;
      setUserGroups(_userGroups);
    }
    setQuickAddUserGroupInfo(null);
  };

  const confirmDeleteUserGroup = async () => {
    const response = await companyService.removeUserGroup(
      deleteUserGroupInfo.userGroup.userGroupId
    );

    if (!response.data.success) {
      dispatch(setErrorMessage(t("Errors.DeleteUserGroup")));
    } else {
      let _userGroups = [...userGroups];
      _userGroups.splice(deleteUserGroupInfo.index, 1);
      setUserGroups(_userGroups);
      dispatch(setSuccessMessage(t("Success.DeleteUserGroup")));
    }
    setDeleteUserGroupInfo(null);
  };

  if (!isLoading) {
    return (
      <div className="content">
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h2">{t("UserGroups.UserGroups")}</Typography>
          {userGroups && (
            <>
              <Button
                variant="contained"
                color="primary"
                style={addButtonStyle}
                onClick={add}
              >
                {t("UserGroups.Add")}
              </Button>
              <DataTable
                tableStructure={userGroupsTableStructure}
                tableContent={userGroups}
                actions={actions}
              />
            </>
          )}
        </Container>
        {editUserGroupInfo && (
          <EditUserGroup
            createMode={editUserGroupInfo.createMode}
            userGroup={editUserGroupInfo.userGroup}
            userGroups={userGroups}
            close={closeEditUserGroup}
          />
        )}
        {quickAddUserGroupInfo && (
          <QuickAddUserGroup
            userGroup={quickAddUserGroupInfo.userGroup}
            close={closeQuickAddUserGroup}
          />
        )}
        {deleteUserGroupInfo && (
          <DeleteUserGroup
            userGroup={deleteUserGroupInfo.userGroup}
            onConfirm={confirmDeleteUserGroup}
            onCancel={() => {
              setDeleteUserGroupInfo(null);
            }}
          />
        )}
      </div>
    );
  } else {
    return <LinearProgress />;
  }
};
