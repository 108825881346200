import React from "react";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { useTranslation } from 'react-i18next';

export const DeleteUserGroup = ({ userGroup, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      title={t("UserGroups.DeleteUserGroup")}
      heading={userGroup.name}
      message={t("UserGroups.DeleteUserGroupConfirmation")}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};