import React, { useState, useEffect } from 'react';
import { TimePicker } from '@material-ui/pickers';

const ValidatedTimePicker = ({ label, value, checkValid, setValue, errorMessage, minuteStep = 1 }) => {

  const [unvalidatedValue, setUnvalidatedValue] = useState(value);

  useEffect(() => {
    checkValid(unvalidatedValue) ? setValue(unvalidatedValue) : setValue(null);
  }, [unvalidatedValue]);

  return (
    <TimePicker
      label={label}
      value={value}
      error={unvalidatedValue ? !checkValid(unvalidatedValue) : false}
      helperText={checkValid(unvalidatedValue) ? "" : errorMessage}
      onChange={(newValue) => setUnvalidatedValue(newValue)}
      minutesStep={minuteStep}
    />
  );
};

export default ValidatedTimePicker;