import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FixedSizeList } from "react-window";
import { useCompanyService } from "../../services/useCompanyService";
import { useUserService } from "../../services/useUserService";
import { useLocalStore } from "../../hooks/useLocalStore";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Tooltip
} from "@material-ui/core";
import matchSorter from "match-sorter";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import { red, orange, blue, green } from "@material-ui/core/colors";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import MapCanvas from "./MapCanvas";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { setErrorMessage, setSuccessMessage } from "../../actions";
import { isEmpty } from "../../common";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  card: {
    maxWidth: "1000px",
    marginTop: theme.spacing(5),
    padding: "16px"
  },
  selectFileButtonWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    padding: "2px"
  },
  selectFileButtonOrigin: {
    width: "100%",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0
  },
  seatList: {
    margin: "auto"
  },
  edit: {
    color: red[500]
  },
  selected: {
    color: orange[600]
  },
  unassigned: {
    color: blue[500]
  },
  assigned: {
    color: green[500]
  },
  seatListItemText: {
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  seatToolTip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  rightRail: {
    paddingTop: "10px"
  },
  mapControls: {
    marginTop: "16px",
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gridColumnGap: "16px",
    "&>div:last-of-type": {
      display: "grid",
      justifySelf: "center",
      gridTemplateRows: "40px 1fr"
    }
  }
}));

export const LocationMap = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const companyService = useCompanyService();
  const userService = useUserService();
  const localStore = useLocalStore();

  const [init, setInit] = useState(false);
  const [locations, setLocations] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [eligibleLocations, setEligibleLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [seats, setSeats] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState({});
  const [floorMap, setFloorMap] = useState({});
  const [floorMapUrlPrefix, setFloorMapUrlPrefix] = useState({});
  const [locationMaps, setLocationMaps] = useState({});
  const [company, setCompany] = useState();

  const [pointX, setPointX] = useState(10);
  const [pointY, setPointY] = useState(10);
  const [aspectRatio, setAspectRatio] = useState(0);
  const preview = useRef();
  const seatListRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      const _company = localStore.getCompany();
      setCompany(_company);
      setLocationMaps(_company.locationMaps);
      setCoordinates(_company.coordinateSystem);
      setFloorMapUrlPrefix(`Companies/${_company.companyId}/`);
      const _locations = await companyService.getAllLocations();
      setLocations(_locations);
      setInit(true);
    };
    loadData();
  }, []);

  useEffect(() => {
    if (locations.length > 0 && coordinates.length > 0) {
      const _eligibleLocations = userService.getEligibleNoneBookableLocations(
        null, // pass null to don't check userGroups for locations
        locations,
        coordinates
      );
      setEligibleLocations(_eligibleLocations);
    }
  }, [locations, coordinates]);

  useEffect(() => {
    if (selectedLocation) {
      const _results = locations.filter(
        (location) =>
          location.locationParent === selectedLocation.locationId &&
          location.bookable
      );
      if (_results.length > 0) {
        const _sortedSeats = _results.sort((a, b) =>
          a.displayName > b.displayName ? 1 : -1
        );
        setSeats(_sortedSeats);
      } else {
        setSeats(_results);
      }
      const _floorMapInformation = loadFloorMapInformation(selectedLocation);

      if (_floorMapInformation.floorMapPath) {
        userService
          .getFloorMapUrl(floorMapUrlPrefix + _floorMapInformation.floorMapPath)
          .then((url) => {
            setFloorMap({ ..._floorMapInformation, url: url });
          });
      } else {
        setFloorMap({ floorMapLocation: selectedLocation, url: null });
      }
    }
  }, [selectedLocation]);

  const loadFloorMapInformation = (location) => {
    let _floorMapPath = locationMaps ? locationMaps[location.locationId] : null;
    let _floorMapLocation = location;
    let _floorMapInformation = {
      floorMapPath: _floorMapPath,
      floorMapLocation: _floorMapLocation
    };

    if (_floorMapPath == null) {
      const _parentLocationId = location.locationParent;
      if (_parentLocationId && _parentLocationId.length > 0) {
        const _parentLocation = locations.find(
          (currLocation) => currLocation.locationId === _parentLocationId
        );
        _floorMapInformation = loadFloorMapInformation(_parentLocation);
      } else
        _floorMapInformation = {
          floorMapPath: null,
          floorMapLocation: _floorMapLocation
        };
    }

    return _floorMapInformation;
  };

  const getLocationOptionName = (location) => {
    let _name = "";
    const locationType = location.locationType;
    for (let i = 0; i < coordinates.length; i++) {
      const _coordinateNode = coordinates[i];
      _name = _name.concat(location.coordinate[_coordinateNode].displayName);
      if (_coordinateNode === locationType) break;
      else _name = _name.concat(" -> ");
    }
    return _name;
  };

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, { keys: ["displayName"] });

  const handleLocationChange = (newLocation) => {
    setSelectedLocation(newLocation);
    setSelectedSeat({});
    setPointX(10);
    setPointY(10);
  };

  const isSeatInEditMode = (seat) => {
    return (
      selectedSeat.seat &&
      seat.locationId === selectedSeat.seat.locationId &&
      selectedSeat.editMode
    );
  };

  const getSeatStyle = (seat) => {
    if (floorMap.floorMapLocation) {
      const _isUnAssigned = !(
        seat.coordinate[floorMap.floorMapLocation.locationType].point &&
        seat.coordinate[floorMap.floorMapLocation.locationType].point.length ===
          2
      );
      const _isSelected =
        selectedSeat.seat && seat.locationId === selectedSeat.seat.locationId;
      if (!_isSelected)
        return _isUnAssigned ? classes.unassigned : classes.assigned;
      else {
        return _isSelected
          ? selectedSeat.editMode
            ? classes.edit
            : classes.selected
          : classes.default;
      }
    } else {
      return classes.default;
    }
  };

  const saveSeatCoordinate = async (seat) => {
    if (isSeatInEditMode(seat)) {
      const _floorMapLocation = floorMap.floorMapLocation;
      let _selectedSeat = selectedSeat.seat;
      _selectedSeat.coordinate[_floorMapLocation.locationType].point = [
        pointX * aspectRatio,
        pointY * aspectRatio
      ];
      await companyService.updateLocation(_selectedSeat).then((res) => {
        dispatch(
          res.data.success
            ? setSuccessMessage(t("Success.UpdateCoordinates"))
            : setErrorMessage(t("Errors.General"))
        );
      });
      setSelectedSeat({ seat: _selectedSeat, editMode: false });
    }
  };

  const SeatRow = ({ index, style }) => (
    <ListItem
      style={style}
      key={seats[index].locationId}
      button
      selected={
        selectedSeat.seat &&
        seats[index].locationId === selectedSeat.seat.locationId
      }
    >
      <IconButton
        onClick={() => setSelectedSeat({ seat: seats[index], editMode: false })}
      >
        <EventSeatIcon
          fontSize="small"
          className={getSeatStyle(seats[index])}
        />
      </IconButton>
      <Tooltip title={seats[index].displayName}>
        <ListItemText
          className={classes.seatListItemText}
          primary={seats[index].displayName}
          onClick={() =>
            setSelectedSeat({ seat: seats[index], editMode: false })
          }
        />
      </Tooltip>
      <IconButton
        onClick={() => setSelectedSeat({ seat: seats[index], editMode: true })}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton
        disabled={!isSeatInEditMode(seats[index])}
        onClick={() => saveSeatCoordinate(seats[index])}
      >
        <SaveIcon fontSize="small" />
      </IconButton>
    </ListItem>
  );

  useEffect(() => {
    if (!isEmpty(selectedSeat.index)) {
      seatListRef.current.scrollToItem(selectedSeat.index);
    }
  }, [selectedSeat]);

  const handleSelectFileChange = useCallback((e, company, location) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function () {
      // make sure the image data is encoded in base64 by btoa()
      const data = {
        name: file.name,
        type: file.type,
        companyId: company.companyId,
        blob: btoa(reader.result)
      };

      companyService.uploadFloorPlan(data).then(async () => {
        const filename = "images/floorplans/" + file.name;
        company.locationMaps[location.locationId] = filename;
        await companyService.updateCompany(company);
        setLocationMaps(company.locationMaps);
        setSelectedLocation(null);
        handleLocationChange(location);
      });
    };
    reader.readAsBinaryString(file);
  }, []);

  if (init) {
    return (
      <div className="content">
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h3">{t("Locations.LocationMaps")}</Typography>
          <Card className={classes.card}>
            {eligibleLocations && (
              <Grid item xs={12}>
                <Autocomplete
                  id="selected_location"
                  options={eligibleLocations}
                  getOptionLabel={(option) => getLocationOptionName(option)}
                  onChange={(event, newValue) => {
                    handleLocationChange(newValue);
                  }}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Locations.FindLocationMap")}
                      margin="normal"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid container spacing={2} alignItems="center" direction="row">
              <Grid item xs={2} className={classes.seatToolTip}>
                <RadioButtonUncheckedIcon
                  fontSize="small"
                  className={classes.assigned}
                />
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("Locations.Assigned")}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.seatToolTip}>
                <RadioButtonUncheckedIcon
                  fontSize="small"
                  className={classes.selected}
                />
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("Locations.Selected")}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.seatToolTip}>
                <EventSeatIcon
                  fontSize="small"
                  className={classes.unassigned}
                />
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("Locations.Unassigned")}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.seatToolTip}>
                <EventSeatIcon fontSize="small" className={classes.assigned} />
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("Locations.Assigned")}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.seatToolTip}>
                <EventSeatIcon fontSize="small" className={classes.selected} />
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("Locations.Selected")}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.seatToolTip}>
                <EventSeatIcon fontSize="small" className={classes.edit} />
                <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
                  {t("Commons.Edit")}
                </Typography>
              </Grid>
            </Grid>
            <div className={classes.mapControls}>
              <div>
                {Object.keys(floorMap).length < 1 ? (
                  ""
                ) : floorMap.url ? (
                  <MapCanvas
                    width={650}
                    floorMap={floorMap}
                    selectedSeat={selectedSeat}
                    setSelectedSeat={setSelectedSeat}
                    pointX={pointX}
                    setPointX={setPointX}
                    pointY={pointY}
                    setPointY={setPointY}
                    seats={seats}
                    aspectRatio={aspectRatio}
                    setAspectRatio={setAspectRatio}
                  />
                ) : (
                  <div className={classes.fileSelector}>
                    <div className={classes.selectFileButtonWrapper}>
                      <Button variant="contained" color="primary">
                        {t("Locations.UploadFloorPlan")}
                      </Button>
                      <input
                        type="file"
                        className={classes.selectFileButtonOrigin}
                        onChange={(e) =>
                          handleSelectFileChange(e, company, selectedLocation)
                        }
                        accept="image/png, image/jpeg"
                      ></input>
                    </div>
                    <img ref={preview} />
                  </div>
                )}
              </div>
              <div className={classes.rightRail}>
                {Object.keys(floorMap).length < 1 ? (
                  ""
                ) : !floorMap.url ? (
                  ""
                ) : (
                  <div className={classes.fileSelector}>
                    <div className={classes.selectFileButtonWrapper}>
                      <Button variant="contained" color="primary">
                        {t("Locations.UpdateFloorPlan")}
                      </Button>
                      <input
                        type="file"
                        className={classes.selectFileButtonOrigin}
                        onChange={(e) =>
                          handleSelectFileChange(e, company, selectedLocation)
                        }
                        accept="image/png, image/jpeg"
                      ></input>
                    </div>
                  </div>
                )}
                <FixedSizeList
                  ref={seatListRef}
                  className={classes.seatList}
                  height={1000}
                  width={250}
                  itemSize={46}
                  itemCount={seats.length}
                  aria-label="seats"
                >
                  {SeatRow}
                </FixedSizeList>
              </div>
            </div>
          </Card>
        </Container>
      </div>
    );
  } else {
    return <LinearProgress />;
  }
};
