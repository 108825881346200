import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from 'react-i18next';
import { useCompanyService } from "../../../services/useCompanyService";
import { makeStyles } from "@material-ui/styles";
import { isNullOrUndefined } from "../../../common";
import { setSuccessMessage } from "../../../actions/messageActions";
import { HIVE_GATE_VERSIONS, URL_NAMES } from "../../../constants";
import {
  Button,
  Container,
  Typography,
  ListItem,
  ListItemText 
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "@material-ui/icons/FileCopyRounded";
import QRCodeIcon from '../../common/QRCodeIcon.jsx';
import QRCodePopup from './QRCodePopup.jsx';
import SearchBar from "../../../components/SearchBar/SearchBar";
import CompanyUtil from "../../../utils/CompanyUtil";
import DataTable from "../../../components/DataTable/DataTable";

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5)
  },
  toolbar: {
    display: "flex",
    alignItems: "start",
    marginTop: theme.spacing(5),
    gap: "15px",
    "justify-content": "space-between",
    "&>*": {
      width: "30%"
    }
  },
  addButton: {
    margin: theme.spacing(2, 0)
  }
}));

export const Profiles = ({
  profiles,
  setEditProfile,
  setDeleteProfile,
  company,
  usersList,
  locationsList
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyService = useCompanyService();
  const [searchValue, setSearchValue] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState(profiles);
  const [profileUrls, setprofileUrls] = useState(null);
  const [profileBaseUrl, setProfileBaseUrl] = useState(null);
  const [locationMap, setLocationMap] = useState(null);
  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = (url) => {
    setUrl(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFilteredProfiles(profiles);
  }, [profiles]);

  useEffect(() => {
    if (searchValue) {
      let _searchValue = searchValue.toLowerCase();

      let _filteredProfiles = profiles.filter((p) => {
        const locationsConcat = getConcatenatedLocationString(
          p.visitorCheckInLocations
        );

        return (
          getProfileOwnerName(p.profileOwner)
            .toLowerCase()
            .includes(_searchValue) ||
          p.displayName.toLowerCase().includes(_searchValue) ||
          locationsConcat.toLowerCase().includes(_searchValue)
        );
      });

      setFilteredProfiles(_filteredProfiles);
    } else {
      setFilteredProfiles(profiles);
    }
  }, [searchValue]);

  useEffect(() => {
    loadData();
  }, [profiles, locationsList]);

  const loadData = async () => {
    if (isNullOrUndefined(profileBaseUrl)) {
      let urlVersion = "";
      company.preferences.hiveGateService.version === HIVE_GATE_VERSIONS.V2 ? 
        urlVersion = URL_NAMES.profileUrlV2 :
        urlVersion = URL_NAMES.profileUrl;

      await companyService.getUrl(urlVersion).then((baseUrl) => {
        mapProfileUrls(baseUrl);
        setProfileBaseUrl(baseUrl);
      });
    } else {
      mapProfileUrls();
    }

    if (locationsList) {
      const _locationMap = {};
      locationsList.map((l) => {
        _locationMap[l.locationId] = l;
      });
      setLocationMap(_locationMap);
    }
  };

  const mapProfileUrls = (baseUrl = profileBaseUrl) => {
    const _profileUrls = {};
    for (let profile of profiles) {
      let _profileUrl = baseUrl.replace("{companyId}", company.companyId);
      _profileUrl = _profileUrl.replace("{profileId}", profile.profileId);
      _profileUrl = _profileUrl.replace("{mode}", "visitor");
      _profileUrls[profile.profileId] = _profileUrl;
    }
    setprofileUrls(_profileUrls);
  };

  const getConcatenatedLocationString = (locations) => {
    let locationsConcat = "";

    for (let loc of locations) {
      let locString = CompanyUtil.getStringForCoords(
        company,
        locationMap[loc.locationId].coordinate
      );
      locationsConcat += " " + locString;
    }

    return locationsConcat;
  };

  const copyProfileUrl = (profile) => {
    return (
      profileUrls && (
        <CopyToClipboard
          onCopy={() => {
            dispatch(
              setSuccessMessage(
                `${profile.displayName} profile URL copied to clipboard.`
              )
            );
          }}
          text={profileUrls[profile.profileId]}
        >
          <CopyIcon />
        </CopyToClipboard>
      )
    );
  };

  const getProfileOwnerName = (email) => {
    const user = usersList.find((u) => u.email === email);
    return isNullOrUndefined(user) ? "" : user.displayName;
  };

  const renderProfileOwnerName = (profile) => {
    const name = getProfileOwnerName(profile.profileOwner);
    return name === "" ? t("Gate.NoOwnerAssigned") : name;
  };

  const renderLocationsNames = (profile) => {
    const locations = profile.visitorCheckInLocations;
    const rows = [];
    locationMap &&
      locations.map((location) => {
        rows.push(
          <ListItem
            key={`item-${location.locationId}`}
            style={
              locations.length === 1 ? { padding: 0 } : { padding: "0 1em 0 0" }
            }
          >
            <ListItemText align="left">
              {location &&
                CompanyUtil.getStringForCoords(
                  company,
                  locationMap[location.locationId].coordinate
                )}
            </ListItemText>
          </ListItem>
        );
      });
    return rows;
  };

  const profileTableStructure = [
    {
      columnNamekey: "Gate.DisplayName",
      fieldName: "displayName"
    },
    {
      columnNamekey: "Gate.ProfileOwner",
      fieldName: "profileOwner",
      customContentFormatter: renderProfileOwnerName
    },
    {
      columnNamekey: "Gate.Locations",
      fieldName: "visitorCheckInLocations",
      customContentFormatter: renderLocationsNames
    }
  ];

  const actionButtons = [
    {
      toolTipKey: "Commons.Edit",
      icon: <EditIcon />,
      func: (profile) => setEditProfile({ createMode: false, profile: profile })
    },
    {
      toolTipKey: "Commons.Delete",
      icon: <DeleteIcon />,
      func: (profile) => setDeleteProfile(profile)
    },
    {
      toolTipKey: "Gate.CopyProfileUrl",
      render: copyProfileUrl
    },
    {
      toolTipKey: "Commons.QrCode",
      icon: <QRCodeIcon />,
      func: (profile) => handleClickOpen(profileUrls[profile.profileId])
    }
  ];

  return (
    <Container className={classes.section}>
      <Typography variant="h3">{t("Gate.Profiles")}</Typography>
      <div className={classes.toolbar}>
        <SearchBar onSearch={setSearchValue} />
      </div>
      <Button
        variant="contained"
        color="primary"
        className={classes.addButton}
        onClick={() => setEditProfile({ createMode: true, profile: {} })}
      >
        {t("Gate.AddProfile")}
      </Button>
      <DataTable
        tableStructure={profileTableStructure}
        tableContent={[...filteredProfiles]}
        actions={actionButtons}
      />
      <QRCodePopup
        selectedValue={url}
        open={open}
        onClose={handleClose}
        title={t("Commons.QrCode")}
      />
    </Container>
  );
};
