import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { SketchPicker } from "react-color";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer"
  },
  popover: {
    position: "absolute",
    zIndex: "2"
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  }
}));
function ColorPicker(props) {
  const classes = useStyles();
  const { colorValue } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(colorValue);
  const handleClick = () => {
    setDisplayColorPicker((prevState) => !prevState);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  const handleChange = (color) => {
    setColor(color.hex);
    props.onChange(color.hex);
  };

  useEffect(() => {
    setColor(colorValue);
  });

  return (
    <div>
      <div className={classes.swatch} onClick={handleClick}>
        <div
          style={{
            width: "36px",
            height: "14px",
            borderRadius: "2px",
            background: `${color}`
          }}
        />
      </div>
      {displayColorPicker ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
}

ColorPicker.propTypes = {
  colorValue: PropTypes.string.isRequired
};

export default ColorPicker;
