import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Container,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography
} from "@material-ui/core";
import Papa from "papaparse";
import Alert from "@material-ui/lab/Alert";
import { apply, compose, lift, splitAt, zipObj } from "ramda";
import { makeStyles } from "@material-ui/styles";
import { useCompanyService } from "../../services/useCompanyService";
import { grey } from "@material-ui/core/colors";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  toolbar: {
    display: "flex",
    marginTop: theme.spacing(5),
    gap: "15px",
    "justify-content": "space-between",
    "&>*": {
      width: "30%"
    }
  },
  table: {
    width: "100%",
    marginTop: theme.spacing(5)
  },
  inner: {
    height: "70vh"
  },
  card: {
    maxHeight: "70vh",
    display: "grid",
    "grid-template-rows": "1fr 95px"
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  exportParams: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gridColumnGap: "20px",
    alignItems: "center",
    padding: "20px"
  },
  fileSelector: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr 1fr",
    marginTop: theme.spacing(2),
    "&>div": {
      whiteSpace: "no-break",
      "align-self": "center",
      "justify-self": "center"
    }
  },
  selectFileButtonWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    padding: "2px"
  },
  selectFileButtonOrigin: {
    width: "100%",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0
  }
}));

export default function Imports() {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyService = useCompanyService();
  const [importFile, setImportFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMsg] = useState("");
  const [userData, setUserData] = useState([]);

  function handleCsvFile(event) {
    const file = event.target.files[0];
    const keys = [
      "firstName",
      "lastName",
      "email",
      "roles",
      "groups",
      "assignedSeats",
      "preferredSeats",
      "state",
      "verified",
      "externalId"
    ];

    setErrorMsg("");
    if (file && 0 === file.length) {
      setErrorMsg(t("Imports.ChooseFile"));
      setUserData([]);
      return;
    } else {
      setErrorMsg("");
      setImportFile(file.name);
    }

    Papa.parse(file, {
      skipEmptyLines: true,
      error: function (err, file, inputElem, reason) {
        // onError({ err, file, inputElem, reason })
      },
      complete: function (results) {
        const data = results.data;

        // remove display headers
        data.shift();

        // add api headers
        data.unshift(keys);

        // convert arrays to objects
        const formattedResult = compose(apply(lift(zipObj)), splitAt(1))(data);

        setUserData(formattedResult);
      }
    });
  }

  // split the comma seperated items string into a list of items
  // each item is trimmed with prefix or suffix whitespaces.
  const createListFromString = (itemsString) => {
    var list = [];
    var itemsList = itemsString.split(",");

    for (let item of itemsList) {
      list.push(item.trim());
    }
    return list;
  };

  const importUsers = async (users) => {
    userData.forEach(async (user) => {
      setLoading(true);
      await saveUser(user);
      setLoading(false);
    });
  };

  const saveUser = async (user) => {
    var groups = user.groups;
    var roles = user.roles;
    var assignedSeats = user.assignedSeats;
    var preferredSeats = user.preferredSeats;

    // fixing all the messy import arrays
    if (!groups || 0 === groups.length) {
      user.groups = [];
    } else if (typeof groups === "string") {
      user.groups = createListFromString(groups);
    }

    if (!roles || 0 === roles.length) {
      user.roles = [];
    } else if (typeof roles === "string") {
      user.roles = createListFromString(roles);
    }

    if (!assignedSeats || 0 === assignedSeats.length) {
      user.assignedSeats = [];
    } else if (typeof assignedSeats === "string") {
      user.assignedSeats = createListFromString(assignedSeats);
    }

    if (!preferredSeats || 0 === preferredSeats.length) {
      user.preferredSeats = [];
    } else if (typeof preferredSeats === "string") {
      user.preferredSeats = createListFromString(preferredSeats);
    }

    const response = await companyService.createUserByAdmin(user);
    if (response.data.success) {
      user.userId = response.data.userId;
      // We decided to ignore the verified flag during import JONA1298-321
      // if (user.verified && user.verified !== "TRUE")
      //   await authService.sendResetPasswordEmail(user.email);
      dispatch(setSuccessMessage(t("Success.ImportUser", { user: user })));
    } else {
      const path = "Errors.Cloud." + response.data.message;
      const error = i18n.exists(path) ? t(path) : t("Errors.CreateUser");
      console.log(error);
      setErrorMsg(error);
      dispatch(setErrorMessage(error));
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h3">{t("Imports.ImportUsers")}</Typography>

      <div className={classes.toolbar}>
        <div className={classes.fileSelector}>
          <div className={classes.selectFileButtonWrapper}>
            <Button variant="contained" color="primary">
              <Typography variant="h6">{t("Imports.SelectFile")}</Typography>
            </Button>
            <input
              type="file"
              className={classes.selectFileButtonOrigin}
              onChange={(e) => {
                handleCsvFile(e);
              }}
              accept="text/csv"
            ></input>
          </div>
        </div>
      </div>
      {userData && userData.length > 0 && (
        <div className={classes.exportParams}>
          <TextField
            className={classes.textField}
            disabled={true}
            variant="outlined"
            label={t("Imports.CSVFile")}
            name="importFile"
            value={importFile}
            onChange={(event) => {
              setImportFile(event.target.value);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!importFile || loading}
            onClick={async () => {
              await importUsers(importFile);
            }}
          >
            {t("Imports.ImportUsers")}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </div>
      )}

      <div className={classes.errorMessage}>
        {errorMessage && (
          <Alert
            severity="error"
            onClose={() => {
              setErrorMsg("");
            }}
          >
            {errorMessage}
          </Alert>
        )}
      </div>

      <div className={classes.table}>
        <Typography variant="h4">{t("Imports.ImportUsersView")}</Typography>
        <Card className={classes.card}>
          <TableContainer className={classes.inner}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{t("Commons.FirstName")}</TableCell>
                  <TableCell>{t("Commons.LastName")}</TableCell>
                  <TableCell>{t("Commons.Email")}</TableCell>
                  <TableCell>{t("Imports.Roles")}</TableCell>
                  <TableCell>{t("Imports.Groups")}</TableCell>
                  <TableCell>{t("Imports.Assigned")}</TableCell>
                  <TableCell>{t("Imports.Preferred")}</TableCell>
                  <TableCell>{t("Imports.State")}</TableCell>
                  <TableCell>{t("Commons.ExternalId")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData && userData.length > 0 ? (
                  userData.map((user, index) => (
                    <TableRow hover key={index}>
                      <TableCell>
                        <div>{index + 1}</div>
                      </TableCell>
                      <TableCell>
                        <div>{user.firstName}</div>
                      </TableCell>
                      <TableCell>
                        <div>{user.lastName}</div>
                      </TableCell>
                      <TableCell>
                        <div>{user.email}</div>
                      </TableCell>
                      <TableCell>
                        <div>{user.roles}</div>
                      </TableCell>
                      <TableCell>
                        <div>{user.groups}</div>
                      </TableCell>
                      <TableCell>
                        <div>{user.assignedSeats}</div>
                      </TableCell>
                      <TableCell>
                        <div>{user.preferredSeats}</div>
                      </TableCell>
                      <TableCell>
                        <div>{user.state}</div>
                      </TableCell>
                      <TableCell>
                        <div>{user.externalId}</div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align={"center"} colSpan={10}>
                      {t("Imports.NoImportUser")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </Container>
  );
}
