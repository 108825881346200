import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Box, FormControl, FormLabel, TextField } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  box: {
    marginTop: "15px"
  },
  autoComplete: {
    marginTop: "15px"
  }
}));

const OperationTimeException = ({ data }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [operationTimeException, setOperationTimeException] = useState(data);

  const updateTimeExceptions = (newValues) => {
    operationTimeException.splice(0, operationTimeException.length);
    operationTimeException.push(...newValues);
  };

  return (
    <Box className={classes.box}>
      <FormControl component="fieldset" variant="standard" fullWidth>
        <FormLabel component="legend">
          {t("Resources.OperationTimeException")}
        </FormLabel>
        <Autocomplete
          multiple
          freeSolo
          limitTags={4}
          className={classes.autoComplete}
          disableClearable={true}
          options={[]}
          defaultValue={operationTimeException}
          filterSelectedOptions
          onChange={(event, newValues) => updateTimeExceptions(newValues)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t("Resources.ExceptionDate")}
              variant="outlined"
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

OperationTimeException.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default OperationTimeException;
