import React from "react";
import { makeStyles, Card, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { MultiLanguageInputField } from "../../components/MultiLanguage/MultiLanguageInputField";
import ColorSelector from "./ColorSelector";

const useStyles = makeStyles({
  tagCard: {
    padding: "0.5rem",
    margin: "1rem 0"
  },
  removeButtonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  removeButton: {
    padding: 5,
    minWidth: 0,
    height: "24px",
    width: "24px",
    marginBottom: "0.5rem"
  }
});

function Tag({
  tag,
  index,
  onRemove,
  changeTagName,
  language,
  changeTagColor
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const nameController = {
    value: tag.displayName,
    set: (e) => changeTagName(index, e.target.value, language),
    lang: language
  };

  return (
    <Card className={classes.tagCard}>
      <div className={classes.removeButtonContainer}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classes.removeButton}
          onClick={onRemove}
        >
          <DeleteOutline />
        </Button>
      </div>
      <MultiLanguageInputField
        label={t("Locations.TagName")}
        labelSize={65}
        controller={nameController}
      />
      <ColorSelector
        value={tag.color}
        onChange={changeTagColor}
        index={index}
      />
    </Card>
  );
}

export default Tag;
