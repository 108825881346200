import React, { useMemo, useState } from "react";
import { Card, Button, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { isEmpty } from "../../common";
import { useTranslation } from "react-i18next";
import useMailingListEditorFormState from "./useMailingListEditorFormState";
import MailingListEditorUserGroupSelect from "./MailingListEditorUserGroupSelect";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import useMailingListEditorConfirmDialog from "./useMailingListEditorConfirmDialog";
import { useDispatch } from "react-redux";
import { setSuccessMessage, setErrorMessage } from "../../actions";
import UserSelectorPlusEmailInput from "../../components/Selectors/UserSelectorPlusEmailInput";

const useStyles = makeStyles({
  mailingListAddButton: { margin: "1rem 0" },
  mailingListContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem"
  },
  mailingListCard: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1rem"
  },
  mailingListCardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  mailingListsController: { display: "flex", gap: "1rem" },
  mailingListExplanationText: { display: "block", marginBottom: "1rem" },
  mailingListsSaveButton: { alignSelf: "flex-end" }
});

function MailingListEditor({ userGroups, companyService }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const mailingLists = useMemo(() => {
    const groupsWithMailingLists = userGroups.filter(
      (group) => group.mailingListEmails && !isEmpty(group.mailingListEmails)
    );

    if (!isEmpty(groupsWithMailingLists)) {
      return groupsWithMailingLists.map(
        ({ userGroupId, mailingListEmails }) => {
          return { userGroupId, mailingListEmails };
        }
      );
    }
    return [];
  }, [userGroups]);

  const {
    addMailingList,
    removeMailingList,
    onUserGroupChange,
    onUsersByUserGroupChange,
    lists
  } = useMailingListEditorFormState(mailingLists);

  const getUsersByUserGroupContentList = (index) => {
    const usersByUserGroup = userGroups.find(
      (group) => group.userGroupId === lists[index].userGroupId
    );

    if (isEmpty(usersByUserGroup?.users)) return [];

    const usersList = Object.entries(usersByUserGroup.users).map((user) => ({
      userId: user[1].userId,
      displayName: user[1].name,
      email: user[1].email
    }));

    return usersList;
  };

  const transformUsersForUserGroup = {
    fromFireStore: (userEmail, list) => {
      return list.find((u) => u.email === userEmail) || userEmail;
    },
    toFireStore: (users) =>
      users.map((obj) => {
        return obj.email || obj;
      })
  };

  const userGroupSelected = (index) => lists[index]?.userGroupId !== "";

  const userSelectorProps = (index) => ({
    labelKey: "Commons.Users",
    contentList: userGroupSelected(index)
      ? getUsersByUserGroupContentList(index)
      : [],
    transform: transformUsersForUserGroup,
    controller: userGroupSelected(index)
      ? {
        value: lists[index].mailingListEmails,
        set: (newValue) => onUsersByUserGroupChange(newValue, index)
      }
      : {
        value: [],
        set: () => {}
      },
    disabled: !userGroupSelected(index),
    labelFormater: (obj) => { return obj.displayName || obj;},
    wrapEmail: false
  });

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(null);

  const onRemoveMailingListFromFirestore = async (index) => {
    const { userGroupId } = lists[index];
    const userGroupToUpdate = userGroups.find(
      (userGroup) => userGroup.userGroupId === userGroupId
    );

    const payload = { ...userGroupToUpdate, mailingListEmails: [] };
    const response = await companyService.updateUserGroup(payload);

    if (response.data.success) {
      dispatch(setSuccessMessage(t("Success.RemoveMailingList")));
      setLoading(null);
      return;
    }

    setLoading(null);
    dispatch(setErrorMessage(t("Errors.RemoveMailingList")));
  };

  const onSaveMailingList = async (index) => {
    setLoading(index);

    const { mailingListEmails, userGroupId } = lists[index];
    const userGroupToUpdate = userGroups.find(
      (userGroup) => userGroup.userGroupId === userGroupId
    );

    const payload = { ...userGroupToUpdate, mailingListEmails };
    console.log(payload);
    const response = await companyService.updateUserGroup(payload);

    if (response.data.success) {
      dispatch(setSuccessMessage(t("Success.UdpateMailingList")));
      setLoading(null);
      return;
    }

    setLoading(null);
    dispatch(setErrorMessage(t("Errors.UdpateMailingList")));
  };

  const { openConfirm, onClickRemove, onConfirmRemove, onCancelRemove } =
    useMailingListEditorConfirmDialog(
      removeMailingList,
      onRemoveMailingListFromFirestore
    );

  return (
    <>
      <Button
        onClick={addMailingList}
        variant="contained"
        color="primary"
        className={classes.mailingListAddButton}
      >
        {t("CompanyInfo.AddMailingListForUserGroup")}
        <AddIcon />
      </Button>
      <Typography
        variant="caption"
        className={classes.mailingListExplanationText}
      >
        {t("CompanyInfo.MailingListExplanationText")}
      </Typography>
      <div className={classes.mailingListContainer}>
        {lists.map((list, index) => (
          <Card key={index} className={classes.mailingListCard}>
            <div className={classes.mailingListCardContent}>
              <Typography variant="h6">
                {t("CompanyInfo.ManageMailingList")}
              </Typography>
              <IconButton
                onClick={() => onClickRemove(index, userGroupSelected(index))}
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </div>
            <div className={classes.mailingListsController}>
              <MailingListEditorUserGroupSelect
                lists={lists}
                list={list}
                index={index}
                userGroups={userGroups}
                onUserGroupChange={onUserGroupChange}
                t={t}
              />
              <UserSelectorPlusEmailInput {...userSelectorProps(index)} />
            </div>
            <Button
              disabled={!userGroupSelected(index)}
              variant="contained"
              color="primary"
              onClick={() => onSaveMailingList(index)}
              loading={loading === index}
              className={classes.mailingListsSaveButton}
            >
              {t("Commons.Save")}
            </Button>
          </Card>
        ))}
      </div>
      {openConfirm && (
        <ConfirmDialog
          title={t("CompanyInfo.ConfirmMailingListRemoval")}
          heading={t("CompanyInfo.MailingListRemovalHeading")}
          message={t("CompanyInfo.MailingListRemovalMessage")}
          onConfirm={onConfirmRemove}
          onCancel={onCancelRemove}
        />
      )}
    </>
  );
}

export default MailingListEditor;
