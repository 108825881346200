import React, { useState, useEffect, useMemo } from "react";
import useImage from "use-image";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { MapImageProjection } from "./MapImageProjection";
import { MapContainer, Marker, Tooltip, ImageOverlay } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-easybutton";
import "leaflet-easybutton/src/easy-button.css";
import L from "leaflet";
import { assignedIcon, unassignedIcon, selectedIcon } from "./MapLeafletIcons";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  mapContainer: {
    height: "600px"
  }
}));

export default function MapLeaflet({
  stateKey,
  floorMap,
  selectedSeat,
  setSelectedSeat,
  seats,
  setAutoSave,
  editMode
}) {
  const classes = useStyles();
  const [mapImageProjection, setMapImageProjection] = useState(
    new MapImageProjection()
  );
  const [seatBounds, setSeatBounds] = useState({});
  const [map, setMap] = useState(null);
  const { t } = useTranslation();

  const [image] = useImage(floorMap.url);

  useEffect(() => {
    if (image) {
      mapImageProjection.init(image.width, image.height);

      if (seats) {
        var _pointList = [];

        seats.map((item) => {
          if (
            item.coordinate[floorMap.floorMapLocation.locationType].point &&
            item.coordinate[floorMap.floorMapLocation.locationType].point
              .length === 2
          )
            _pointList.push(
              mapImageProjection.transform(
                item.coordinate[floorMap.floorMapLocation.locationType]
                  .point[0],
                item.coordinate[floorMap.floorMapLocation.locationType].point[1]
              )
            );
        });

        // if there are at least 2 points, set the seat bounds
        if (_pointList.length > 1) {
          setSeatBounds(L.latLngBounds(_pointList));
        } else {
          setSeatBounds(L.latLngBounds([-90.0, -90.0], [90.0, 90.0]));
        }
      }
    }
  }, [image, seats]);

  useEffect(() => {
    if (map) {
      L.easyButton(
        "fa-crosshairs fa-lg",
        function () {
          map.fitBounds(seatBounds);
        },
        "Zoom Fit"
      ).addTo(map);
    }
  }, [map]);

  const eventHandlers = useMemo(
    (e) => ({
      click: (e) => {
        let marker = e.target;
        if (marker.options.data) {
          setAutoSave(false);
          setSelectedSeat(marker.options.data);
        }
      },
      dragend: (e) => {
        if (editMode) {
          let marker = e.target;
          let latlng = marker.getLatLng();
          let pos = mapImageProjection.inverse(latlng);
          console.log(pos);
          if (marker.options.data) {
            let index = marker.options.data.index;
            seats[index].coordinate[
              floorMap.floorMapLocation.locationType
            ].point = [pos.x, pos.y];
            setAutoSave(true);
            setSelectedSeat(marker.options.data);
          }
        }
      }
    }),
    []
  );

  return (
    <React.Fragment>
      {floorMap.floorMapLocation && (
        <Grid container>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h3">
              {`${t("Locations.FloorMap_")} ${
                floorMap.floorMapLocation.displayName
              } ${floorMap.url ? "" : t("Locations.NotFound")}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {image && (
              <MapContainer
                key={stateKey}
                className={classes.mapContainer}
                center={[0.0, 0.0]}
                zoom={1}
                scrollWheelZoom={false}
                whenCreated={setMap}
                crs={L.CRS.EPSG4326}
              >
                <ImageOverlay
                  url={floorMap.url}
                  bounds={mapImageProjection.bounds()}
                />
                {seats &&
                  floorMap.floorMapLocation &&
                  seats.map((item, index) => {
                    if (
                      item.coordinate[floorMap.floorMapLocation.locationType] &&
                      item.coordinate[floorMap.floorMapLocation.locationType]
                        .point &&
                      item.coordinate[floorMap.floorMapLocation.locationType]
                        .point.length === 2
                    )
                      return (
                        <Marker
                          key={item.locationId}
                          data={{ seat: item, index: index, editMode: false }}
                          position={mapImageProjection.transform(
                            item.coordinate[
                              floorMap.floorMapLocation.locationType
                            ].point[0],
                            item.coordinate[
                              floorMap.floorMapLocation.locationType
                            ].point[1]
                          )}
                          draggable={editMode}
                          eventHandlers={eventHandlers}
                          icon={
                            selectedSeat.seat &&
                            item.locationId === selectedSeat.seat.locationId
                              ? selectedIcon
                              : assignedIcon
                          }
                        >
                          <Tooltip
                            direction="top"
                            offset={[0, -30]}
                            opacity={0.8}
                          >
                            {item.displayName}
                          </Tooltip>
                        </Marker>
                      );
                    else if (
                      selectedSeat.seat &&
                      item.locationId === selectedSeat.seat.locationId
                    )
                      return (
                        <Marker
                          key={item.locationId}
                          data={{ seat: item, index: index, editMode: false }}
                          position={map.getCenter()}
                          draggable={editMode}
                          eventHandlers={eventHandlers}
                          icon={unassignedIcon}
                        >
                          <Tooltip
                            direction="top"
                            offset={[0, -30]}
                            opacity={0.8}
                          >
                            {item.displayName}
                          </Tooltip>
                        </Marker>
                      );
                    return "";
                  })}
              </MapContainer>
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
