import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Container, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import EventsFilters from "./EventsFilters";
import EventCard from "./EventCard";
import { useCompanyService } from "../../services/useCompanyService";
import { isEmpty } from "lodash";
import { useLocalStore } from "../../hooks/useLocalStore";
import { addDays } from "date-fns";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../actions";


const useStyles = makeStyles(() => ({
  container: {
    height: "100%"
  }
}));

export default function Events() {
  const [t] = useTranslation();
  const [init, setInit] = useState(false);
  const localStore = useLocalStore();
  const companyService = useCompanyService();
  const classes = useStyles();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [events, setEvents] = useState([]);

  const [startingDate, setStartingDate] = useState(new Date());
  const [endingDate, setEndingDate] = useState(addDays(new Date(), 7));

  const [locationsList, setLocationsList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const company = localStore.getCompany();

  const locationController = {
    value: selectedLocation,
    setter: setSelectedLocation
  };

  useEffect(() => {
    if (!init) {
      loadData();
      setInit(true);
    }
  }, []);

  const loadData = useCallback(async () => {
    try {
      await findEvents();

      if (isEmpty(locationsList)) {
        const _locationsList =
        await companyService.getLocationsFromDropdownListCollection();
        setLocationsList(_locationsList);
      }
    } catch(error) {
      console.log("Error fetching events/locations: ", error);
      dispatch(setErrorMessage(t("Errors.General")));
    } finally {
      setLoading(false);
    }
  }, [locationsList, events]);

  const onStartingDateChange = (date) => setStartingDate(date);
  const onEndingDateChange = (date) => setEndingDate(date);

  const findEvents = async () => {
    try {
      setLoading(true);
      const _eventsList = await companyService.getEvents(
        startingDate,
        endingDate,
        selectedLocation
      );
      setEvents(_eventsList);
    } catch (error) {
      console.log("Error fetching events: ", error);
      dispatch(setErrorMessage(t("Errors.General")));
    } finally{
      setLoading(false);
    }
  };

  const removeEvent = (index) => {
    const _events = [...events];
    _events.splice(index, 1);

    setEvents(_events);
  };
 
  const renderEvents = () => events.map((event, index) => <EventCard event={event} index={index} company={company} onCancelEvent={removeEvent} key={event.meetingId}/>);

  if (init) {
    return (
      <div className="content">
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h3">{t("Events.EventsPageTitle")}</Typography>
          <EventsFilters
            loadingView={loading}
            startingDate={startingDate}
            endingDate={endingDate}
            setStartingDate={onStartingDateChange}
            setEndingDate={onEndingDateChange}
            locationsList={locationsList}
            locationController={locationController}
            company={company}
            onSearchEvents={findEvents}
          />
          {renderEvents()}
        </Container>
      </div>
    );
  } else return <LinearProgress />;
}
