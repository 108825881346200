import { auth, database, firebase } from "../firebase";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useLocalStore } from "../hooks/useLocalStore";
import { useTranslation } from 'react-i18next';
import { ROLES, USER_STATUS } from "../constants";

export const useAuthService = () => {
  const history = useHistory();
  const localStorage = useLocalStorage();
  const localStore = useLocalStore();
  const { t } = useTranslation();

  const signInOverride = async (companyId) => {
    localStorage.setCompanyID(companyId);
    // Assume the ROOT user has been successfully login before calling this signin-override
    // localStorage.setUserID(user.uid);
    // localStorage.setUserRoles(user.roles);
    // localStorage.setEmailVerified(true);

    // acquire the override company apiKey to perform backend operations
    const company = await getCompanyData(companyId);
    if (company.preferences.hiveApiService) {
      localStorage.setHiveApiServiceKey(company.preferences.hiveApiService.apiKey);
    }
    else {
      localStorage.setHiveApiServiceKey(null);
    }
    const region = await getCompanyRegion(companyId);
    localStorage.setHiveRegion(region);
    localStore.setCompany(company);
    return true;
  };

  const signInUserCompanySettings = async (userCompany, user) => {
    if (user.state !== USER_STATUS.ACTIVE) {
      localStorage.clear();
      throw new TypeError(t('Errors.UserNotActive'));
    }

    if (![ROLES.ADMIN, ROLES.IT_ADMIN, ROLES.LOCATION_ADMIN, ROLES.USER_GROUP_ADMIN, ROLES.VISITOR_ADMIN, ROLES.CASE_ADMIN, ROLES.APPOINTMENT_ADMIN, ROLES.HR_ADMIN, ROLES.EVENT_ADMIN, ROLES.EVENT_TEAM].some(role => user.roles.includes(role))) {
      localStorage.clear();
      throw new TypeError(t('Errors.UserDoesNotHaveRole'));
    }
    localStorage.setUserRoles(user.roles);

    const company = await getCompanyData(userCompany.companyId);
    localStore.setCompany(company);
    if (company.preferences.hiveApiService) {
      localStorage.setHiveApiServiceKey(company.preferences.hiveApiService.apiKey);
      if (company.preferences.maximumOccupancyEnabled) {
        localStorage.setMaximumOccupancyEnabled(company.preferences.maximumOccupancyEnabled);
      }
      if (company.preferences.templateMatchingEnabled) {
        localStorage.setTemplateMatchingEnabled(company.preferences.templateMatchingEnabled);
      }
    }
    if (company.preferences.hiveIdentityService) {
      localStorage.setHiveIamServiceKey(company.preferences.hiveIdentityService.apiKey);
    }
    if (company.preferences.hiveSyncService) {
      localStorage.setHiveSyncServiceKey(company.preferences.hiveSyncService.apiKey);
    }
  };

  const signIn = async (email, password) => {
    await auth.signInWithEmailAndPassword(email, password);

    const userCompany = await getUserCompany();

    localStorage.setUserID(auth.currentUser.uid);
    localStorage.setUserEmail(auth.currentUser.email);

    if (auth.currentUser.emailVerified) {
      localStorage.setCompanyID(userCompany.companyId);

      if (userCompany.companyName) { // Indicates that app is in creation flow.
        localStorage.setCreateCompanyName(userCompany.companyName);
        localStorage.setUserRoles([ROLES.ADMIN]);
      } else {
        const user = await getUser(userCompany.companyId);
        localStore.setCurrentUser(prepareUserForLocalStore(auth, user));
        await signInUserCompanySettings(userCompany, user);
      }

      const region = await getCompanyRegion(userCompany.companyId);
      localStorage.setHiveRegion(region);
      localStorage.setEmailVerified(true);
      return true;
    } else {
      return false;
    }
  };

  const signInWithCustomToken = async (companyId, token) => {
    await auth.signInWithCustomToken(token);

    const userCompany = await getUserCompany();

    // double check the user's companyId matched what token claimed
    if (userCompany.companyId === companyId) {
      localStorage.setUserID(auth.currentUser.uid);
      localStorage.setCompanyID(companyId);

      const user = await getUser(companyId);
      localStore.setCurrentUser(prepareUserForLocalStore(auth, user));
      await signInUserCompanySettings(userCompany, user);

      const region = await getCompanyRegion(companyId);
      localStorage.setHiveRegion(region);
      localStorage.setEmailVerified(true);
      return true;
    }

    return false;
  };

  const prepareUserForLocalStore = (auth, user) => {
    return {
      userId: auth.currentUser.uid,
      displayName: `${user.firstName} ${user.lastName}`,
      email: user.email,
      roles: user.roles
    };
  };

  const getUserCompany = () => {
    return database
      .collection("UserCompany")
      .doc(auth.currentUser.uid)
      .get()
      .then(doc => doc.data());
  };

  const getCompanyRegion = async (companyId) => {
    const companyIdDoc = await database
      .collection("CompanyIDs")
      .where("companyId", "==", companyId)
      .get();

    return companyIdDoc?.docs[0]?.data()?.hiveRegion ?? "";
  };

  const getCompanyData = (companyId) => {
    return database
      .collection("Companies")
      .doc(companyId)
      .get()
      .then(doc => doc.data());
  };

  const getUser = (companyId) => {
    return database
      .collection("Companies")
      .doc(companyId)
      .collection("Users")
      .doc(auth.currentUser.uid)
      .get()
      .then(doc => doc.data());
  };

  const getUserId = () => {
    return auth.currentUser.uid;
  };

  const logout = async () => {
    localStorage.clear();

    await auth.signOut()
      .catch(e => {
        console.log(e);
      });

    history.push("/signin");
  };

  const createUser = async (email, password, companyId, companyName) => {

    const data = {
      companyId: companyId,
      companyName: companyName,
      email: email
    };

    await auth.createUserWithEmailAndPassword(email, password);
    await firebase.functions().httpsCallable("addUserCompanyEntry")(data);
    await auth.currentUser.sendEmailVerification();
  };

  const sendVerifyEmail = () => {
    return auth.currentUser.sendEmailVerification();
  };

  const sendResetPasswordEmail = async (email) => {
    await auth.sendPasswordResetEmail(email);
  };

  return {
    signInOverride: signInOverride,
    signIn: signIn,
    signInWithCustomToken: signInWithCustomToken,
    getUserId: getUserId,
    logout: logout,
    createUser: createUser,
    sendVerifyEmail: sendVerifyEmail,
    sendResetPasswordEmail: sendResetPasswordEmail
  };
};
