import React, { useEffect } from "react";
import PropType from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {
  Tab,
  Tabs,
  Grid,
  Paper,
  Switch,
  AppBar,
  Button,
  Container,
  ButtonGroup,
  Typography,
  Tooltip
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  navigatorContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "0px",
    height: "65vh",
    width: "100%"
  },
  tabIndexBar: {
    alignSelf: "flex-start",
    backgroundColor: theme.palette.background.default,
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.primary.main}`
    }
  },
  tabWrapper: {
    maxWidth: "40px"
  },
  tabButton: {
    border: `1px solid ${theme.palette.divider}`
  },
  tableSection: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  tableWrapper: {
    alignSelf: "flex-start",
    width: "100%",
    height: "100%"
  },
  paginationController: {
    display: "flex",
    padding: "8px",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-end",
    maxHeight: "40px",
    width: "100%",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default
  },
  pagCtrlLeftSection: {},
  pagCtrlMiddleSection: {},
  ".MuiTab-root": {
    minWidth: "40px"
  }
}));

const TableNavigator = ({
  children,
  tabIndex,
  page,
  hideTabIndex,
  disableNavigator = false,
  disableIndex = false
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const INCREMENT = "inc";
  const DECREMENT = "dec";

  const indexes = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const tabList = React.useMemo(
    () =>
      indexes.split("").map((opt) => {
        return (
          <Tab
            label={opt}
            value={opt}
            key={`index#${opt}`}
            disabled={disableIndex}
            className={`${classes.tabButton} ${classes[".MuiTab-root"]}`}
          />
        );
      }),
    []
  );

  useEffect(() => {}, []);

  const handlePageNumberChange = (action) => {
    if (action === DECREMENT) {
      let newPageNumber = page.value === 1 ? 1 : page.value - 1;
      page.set(newPageNumber);
      return;
    }
    action === INCREMENT && page.hasMore && page.set(page.value + 1);
  };

  return (
    <Container className={classes.navigatorContainer}>
      {tabIndex && !hideTabIndex.value && (
        <AppBar position="static" className={classes.tabWrapper}>
          <Tabs
            variant="scrollable"
            orientation="vertical"
            value={tabIndex.value}
            className={classes.tabIndexBar}
            indicatorColor="primary"
            onChange={(event, newValue) => tabIndex.set(newValue)}
          >
            {tabList}
          </Tabs>
        </AppBar>
      )}
      <Paper elevation={4} className={classes.tableSection}>
        <div className={classes.tableWrapper}>{children}</div>
        <div className={classes.paginationController}>
          <div className={classes.pagCtrlLeftSection}>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Off</Grid>
                <Grid item>
                  <Tooltip title={t("Users.SearchByIndex")}>
                    <Switch
                      disabled={disableNavigator}
                      checked={!hideTabIndex.value}
                      onChange={() => hideTabIndex.set(!hideTabIndex.value)}
                      name="checkedC"
                      color="primary"
                    />
                  </Tooltip>
                </Grid>
                <Grid item>On</Grid>
              </Grid>
            </Typography>
          </div>
          <div className={classes.pagCtrlMiddleSection}>
            <ButtonGroup variant="text">
              <Button
                disabled={page.value === 1 || disableNavigator}
                onClick={() => handlePageNumberChange(DECREMENT)}
                color="primary"
              >
                <ArrowLeftIcon />
              </Button>
              <Button disabled>
                <Typography variant="button">{page.value}</Typography>
              </Button>
              <Button
                disabled={!page.hasMore || disableNavigator}
                onClick={() => handlePageNumberChange(INCREMENT)}
                color="primary"
              >
                <ArrowRightIcon />
              </Button>
            </ButtonGroup>
          </div>
          <div></div>
        </div>
      </Paper>
    </Container>
  );
};

TableNavigator.propType = {
  children: PropType.node,
  tabIndex: PropType.object,
  page: PropType.object.isRequired,
  disableIndex: PropType.bool
};

export default TableNavigator;
