import React from "react";
import {
  Typography
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import Alert from "@material-ui/lab/Alert";

export const ErrorAlert = ({ errorMessages, onCloseAlert, styles }) => {
  const { t } = useTranslation();
  return (
    <Alert severity="error" onClose={() => onCloseAlert([])} className={styles}>
      <Typography variant="h6" gutterBottom>{t("Commons.FoundErrors")}</Typography>
      <ul>{errorMessages.map(msg => (<li key={`errorMessage: ${msg}`}><Typography variant="body1">{msg}</Typography></li>))}</ul>
    </Alert>
  );
};