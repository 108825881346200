import React, { useState } from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  heading: {
    fontWeight: "bold"
  }
});

export default function MessagesHeading({
  heading,
  visitorMessages,
  copyFromRegularMessages
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openConfirm, setOpenConfirm] = useState(false);

  return (
    <div className={classes.wrapper}>
      <Typography
        component={"span"}
        variant="body1"
        gutterBottom
        className={classes.heading}
      >
        {heading}
      </Typography>
      {visitorMessages && (
        <Button onClick={() => setOpenConfirm(true)} variant="outlined">
          {t("Questions.CopyFromRegularMessages")}
        </Button>
      )}
      {openConfirm && (
        <ConfirmDialog
          onConfirm={() => {
            copyFromRegularMessages();
            setOpenConfirm(false);
          }}
          onCancel={() => setOpenConfirm(false)}
          title={t("Questions.ConfirmCopyMessages")}
          heading={t("Questions.CopyMessagesHeading")}
          message={t("Questions.CopyMessagesMessage")}
        />
      )}
    </div>
  );
}
