import React, { useEffect, useState } from "react";
import { useCompanyService } from "../../services/useCompanyService";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  LinearProgress
} from "@material-ui/core";
import { EditNotification } from "./EditNotification";
import Add from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import { DeleteNotification } from "./DeleteNotification";
import { useDispatch } from "react-redux";
import { setErrorMessage, setSuccessMessage } from "../../actions/index";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  card: {
    maxWidth: "960px",
    height: "70vh",
    marginTop: theme.spacing(3)
  },
  panelHeader: {
    backgroundColor: "#fafafa"
  },
  panelContent: {
    height: "calc(70vh - 49px)",
    overflow: "auto"
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export const Notifications = () => {
  const classes = useStyles();
  const companyService = useCompanyService();
  // eslint-disable-next-line no-unused-vars
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState(null);
  const [editScheduledNotificationInfo, setEditScheduledNotificationInfo] =
    useState(null);
  const [deleteScheduledNotificationInfo, setDeleteScheduledNotificationInfo] =
    useState(null);
  const [users, setUsers] = useState(null);
  const [userGroups, setUserGroups] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    companyService.getScheduledNotifications().then((notifications) => {
      notifications.forEach((notification) => {
        notification.startDate = notification.startDate.toDate();
        notification.endDate =
          notification.endDate == null ? null : notification.endDate.toDate();
      });
      setNotifications(notifications);
    });
    loadUsers();
    loadUserGroups();
    setIsLoading(false);
  }, []);

  const loadUsers = async () => {
    const users = await companyService.getUsersFromDropdownListCollection();
    setUsers(users);
  };

  const loadUserGroups = async () => {
    const groups = await companyService.getUserGroups();
    setUserGroups(groups);
  };

  const add = () => {
    setEditScheduledNotificationInfo({
      createMode: true,
      notification: {}
    });
  };

  const edit = (notification, index) => {
    setEditScheduledNotificationInfo({
      createMode: false,
      notification: notification,
      index: index
    });
  };

  const remove = (notification, index) => {
    setDeleteScheduledNotificationInfo({
      notification: notification,
      index: index
    });
  };

  const closeEditNotification = (notification) => {
    if (notification) {
      let _notifications = [...notifications];

      if (editScheduledNotificationInfo.createMode) {
        _notifications.push(notification);
      } else {
        _notifications[editScheduledNotificationInfo.index] = notification;
      }

      setNotifications(_notifications);
    }
    setEditScheduledNotificationInfo(null);
  };

  const confirmDeleteNotification = async () => {
    const response = await companyService.deleteScheduledNotification(
      deleteScheduledNotificationInfo.notification.scheduledNotificationId
    );

    if (response.data.success) {
      let _notifications = [...notifications];
      _notifications.splice(deleteScheduledNotificationInfo.index, 1);
      setNotifications(_notifications);
      dispatch(setSuccessMessage(t("Success.DeleteScheduledNotification")));
    } else {
      let details = i18next.exists("Errors.Cloud." + response.data.message)
        ? t("Errors.Cloud." + response.data.message)
        : "";
      dispatch(
        setErrorMessage(t("Errors.DeleteScheduledNotification") + " " + details)
      );
    }
    setDeleteScheduledNotificationInfo(null);
  };

  if (!isLoading) {
    return (
      <div className="content">
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h2">
            {t("ScheduledNotifications.ScheduledNotifications")}
          </Typography>
          {notifications && (
            <Card className={classes.card}>
              <Grid
                container
                justify="flex-end"
                className={classes.panelHeader}
              >
                <IconButton onClick={add}>
                  <Add />
                </IconButton>
              </Grid>
              <Divider />
              <div className={classes.panelContent}>
                <List className={classes.list}>
                  {notifications.map((notification, index) => (
                    <React.Fragment key={index}>
                      <ListItem button onClick={() => {}}>
                        <ListItemText
                          primary={notification.notification.title}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => {
                              edit(notification, index);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              remove(notification, index);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      {index !== notifications.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </div>
            </Card>
          )}
        </Container>
        {editScheduledNotificationInfo && (
          <EditNotification
            createMode={editScheduledNotificationInfo.createMode}
            scheduledNotification={editScheduledNotificationInfo.notification}
            users={users}
            groups={userGroups}
            close={closeEditNotification}
          />
        )}
        {deleteScheduledNotificationInfo && (
          <DeleteNotification
            notification={deleteScheduledNotificationInfo.notification}
            onConfirm={confirmDeleteNotification}
            onCancel={() => {
              setDeleteScheduledNotificationInfo(null);
            }}
          />
        )}
      </div>
    );
  } else {
    return <LinearProgress />;
  }
};