import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { setSuccessMessage } from "../../actions/index";
import { useCompanyService } from "../../services/useCompanyService";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useLocalStore } from "../../hooks/useLocalStore";
import { isTrue, allMatching } from "../../common";
import { ROLES } from "../../constants";
import { KeyboardDatePicker } from "@material-ui/pickers";
import classnames from "classnames";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { HEALTH_SCREENING_STATES } from "../../constants";
import { grey } from "@material-ui/core/colors";
import addDays from 'date-fns/addDays';


const useStyles = makeStyles(() => ({
  container: {
    height: "100%"
  },
  wrapper: {
    margin: "20px 0"
  },
  card: {
    maxHeight: "70vh",
    display: "grid",
    "grid-template-rows": "1fr 95px"
  },
  panelContent: {
    maxHeight: "70vh",
    overflow: "auto"
  },
  table: {
    paddingTop: 0,
    paddingBottom: 0
  },
  requestParams: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1.5fr",
    columnGap: "20px",
    rowGap: "20px",
    alignItems: "center"
  },
  exportParams: {
    position: "sticky",
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gridColumnGap: "20px",
    alignItems: "center",
    padding: "20px"
  },
  "@media print": {
    requestParams: {
      display: "none"
    },
    exportParams: {
      display: "none"
    }
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  selectButtonContainer: {
    maxWidth: "960px",
    margin: "20px 0",
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr",
    gridGap: "20px",
    "&>div": {
      display: "flex",
      "&>button": {
        marginRight: "13px"
      }
    },
    "&>div:last-of-type": {
      justifyContent: "flex-end",
      "&>button:last-of-type": {
        marginRight: "0"
      }
    }
  },
  selected: {
    backgroundColor: "#efe8d0"
  },
  nowrap: {
    whiteSpace: "nowrap"
  }
}));

export default function HealthScreening() {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const classes = useStyles();
  const localStore = useLocalStore();
  const localStorage = useLocalStorage();
  const companyService = useCompanyService();

  const [startingDate, setStartingDate] = useState(new Date());
  const [endingDate, setEndingDate] = useState(addDays(new Date(), 7));
  const [email, setEmail] = useState("");
  const [healthScreeningRecords, setHealthScreeningRecords] = useState([]);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("ALL");

  const [loadingView, setLoadingView] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const company = localStore.getCompany();
  const coordinates = company.coordinateSystem;
  const currentUser = localStore.getCurrentUser();
  const selectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    const getUsers = async () => {
      const users = await companyService.getUsers();
      const isGroupAdminOnly = isTrue(
        localStorage
          .getUserRoles()
          .some((role) => role === ROLES.USER_GROUP_ADMIN) &&
          localStorage.getUserRoles().length === 1
      );
      if (isGroupAdminOnly) {
        const userGroups = await companyService.getUserGroups();
        const managedGroups = userGroups
          .filter((g) => g.admins.includes(currentUser.email))
          .map((g) => g.userGroupId);
        const filteredUserList = allMatching(users, managedGroups, "groups");
        setUsers(filteredUserList);
      } else {
        setUsers(users);
      }
    };
    getUsers();
  }, []);

  const getHealthScreeningRecords = async (
    startingDate,
    endingDate,
    userId,
    status
  ) => {
    setLoadingView(true);
    let records = await companyService.getHealthScreeningRecords(
      startingDate,
      endingDate,
      userId,
      status
    );

    records = records.map((record) => {
      let _record = record;
      let recordUser = users.find((user) => user.userId === record.userId);
      _record.username = recordUser
        ? recordUser.firstName + " " + recordUser.lastName
        : "";

      return _record;
    });
    setLoadingView(false);
    setHealthScreeningRecords(records);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  return (
    <>
      <Typography variant="h3">
        {t("Reports.HealthScreeningReports")}
      </Typography>
      <div
        className={classnames({
          [classes.requestParams]: true,
          [classes.wrapper]: true
        })}
      >
        <KeyboardDatePicker
          disableToolbar
          inputVariant="outlined"
          format="MM/dd/yyyy"
          label={t("Commons.StartDate")}
          name="startingDate"
          maxDate={endingDate}
          value={startingDate}
          onChange={(date) => {
            setStartingDate(date);
          }}
        />
        <KeyboardDatePicker
          disableToolbar
          inputVariant="outlined"
          format="MM/dd/yyyy"
          label={t("Commons.EndDate")}
          name="endingDate"
          minDate={startingDate}
          value={endingDate}
          onChange={(date) => {
            setEndingDate(date);
          }}
        />
        <div></div>
        <Autocomplete
          id="users"
          defaultValue={selectedUser}
          options={users}
          value={selectedUser}
          getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
          onChange={(event, value) => {
            setSelectedUser(value);
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Commons.User")}
              variant="outlined"
            />
          )}
        />
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="status-label">{t("Commons.Status")}</InputLabel>
          <Select
            labelId="status-label"
            value={selectedStatus}
            onChange={handleStatusChange}
            label={t("Commons.Status")}
            className={classes.filter}
            autoWidth={true}
          >
            <MenuItem value="ALL">{t("Commons.AllStatus")}</MenuItem>
            <MenuItem value={HEALTH_SCREENING_STATES.PASSED}>
              {t("Commons.Passed")}
            </MenuItem>
            <MenuItem value={HEALTH_SCREENING_STATES.FAILED}>
              {t("Commons.Failed")}
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          disabled={loadingView}
          onClick={() => {
            getHealthScreeningRecords(
              startingDate,
              endingDate,
              selectedUser ? selectedUser.userId : null,
              selectedStatus
            );
          }}
        >
          {t("Reports.ViewReport")}
          {loadingView && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </div>

      {coordinates && healthScreeningRecords && (
        <Card className={classes.card}>
          <div className={classes.panelContent}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label={t("Reports.HealthScreeningReports")}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Commons.Name")}</TableCell>
                    <TableCell>{t("Commons.Visitor")}</TableCell>
                    <TableCell>
                      {t("Commons.Date") + " (" + selectedTimeZone + ")"}
                    </TableCell>
                    <TableCell>{t("Commons.Email")}</TableCell>
                    <TableCell>{t("Commons.Status")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {healthScreeningRecords.length > 0 ? (
                    healthScreeningRecords.map((record, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.nowrap}>
                          {" "}
                          {record.username}{" "}
                        </TableCell>
                        <TableCell className={classes.nowrap}>
                          {" "}
                          {record.visitor &&
                            record.visitor.firstName +
                              " " +
                              record.visitor.lastName}
                        </TableCell>
                        <TableCell>
                          {record.time
                            .toDate()
                            .toLocaleString("en-US", {
                              timeZone: selectedTimeZone
                            })}
                        </TableCell>
                        <TableCell>{record.email}</TableCell>
                        <TableCell>
                          {record.pass
                            ? t("Commons.Passed")
                            : t("Commons.Failed")}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align={"center"} colSpan={5}>
                        {t("Reports.NoHealthScreeningsFound")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {healthScreeningRecords.length > 0 && (
            <div className={classes.exportParams}>
              <TextField
                className={classes.textField}
                variant="outlined"
                label={t("Commons.Email")}
                name="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!email || loadingExport}
                onClick={() => {
                  setLoadingExport(true);
                  companyService
                    .exportOccupantReport(
                      startingDate,
                      endingDate,
                      email,
                      coordinates,
                      selectedUser ? selectedUser.userId : null,
                      selectedStatus
                    )
                    .then(() => {
                      dispatch(
                        setSuccessMessage(
                          t("Success.EmailReport", { email: email })
                        )
                      );
                      setLoadingExport(false);
                      setEmail("");
                    });
                }}
              >
                {t("Reports.ExportReport")}
                {loadingExport && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          )}
        </Card>
      )}
    </>
  );
}
