import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import { TopBar } from "./TopBar";
import LeftNav from "./LeftNav";
import { isNullOrUndefined } from "../common";
import { useLocalStorage } from "../hooks/useLocalStorage";

const useStyles = makeStyles(() => ({
  contentWrapper: {
    position: "relative",
    flexGrow: 1,
    minHeight: "100vh",
    padding: "64px 0 20px 0"
  }
}));

export const AppLayout = ({ children }) => {
  const classes = useStyles();
  const storage = useLocalStorage();
  const authenticated = storage.getEmailVerified();

  return (
    <div className="app">
      <TopBar open={true} />
      {authenticated && isNullOrUndefined(storage.getCreateCompanyName()) ? (
        <LeftNav/>
      ) : null}

      <div className={classes.contentWrapper}>
        <Suspense fallback={<LinearProgress />}>{children}</Suspense>
      </div>
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.object
};
