import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";
import UserSelector from "../../components/Selectors/UserSelector";
import LocationSelector from "../../components/Selectors/LocationSelector";
import SimpleSelector from "../../components/SimpleSelector/SimpleSelector";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { ErrorAlert } from "../common/ErrorAlert";
import { grey } from "@material-ui/core/colors";
import { CASE_STATUS, ROLES, USER_STATUS } from "../../constants";
import {
  Chip,
  Grid,
  Button,
  Tooltip,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(3)
  },
  content: {
    paddingBottom: theme.spacing(2)
  },
  fields: {
    marginBottom: theme.spacing(2)
  },
  titleRightAlign: {
    textAlign: "right"
  },
  titleAndStateWrapper: {
    display: "inline-flex",
    flexWrap: "wrap",
    "& div": {
      marginLeft: "6px"
    }
  },
  chipIsInLockedState: {
    backgroundColor: theme.palette.error.light,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      cursor: "pointer"
    },
    "&:focus": {
      backgroundColor: theme.palette.error.main,
      cursor: "pointer"
    }
  },
  chipIsInActiveState: {
    backgroundColor: theme.palette.icon.green,
    "&:hover": {
      backgroundColor: theme.palette.icon.green
    }
  },
  chipIsInWaitingState: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer"
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  actions: {
    marginBottom: theme.spacing(1)
  },
  notesLabel: {
    marginBottom: theme.spacing(2)
  },
  note: {
    marginBottom: theme.spacing(3)
  },
  addButton: {
    padding: 5,
    minWidth: 0
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  errorMsg: {
    margin: "20px"
  }
}));

export const EditCase = ({
  createMode,
  openForm,
  _case,
  usersList,
  locationsList,
  company,
  cancelFunc,
  saveFunc,
  currentUser,
  spinnerOn
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [newNote, setNewNote] = useState(null);
  const [allStatus, setAllStatus] = useState([]);
  const [reportedCase, setReportedCase] = useState(_case);
  const [errorMessages, setErrorMessages] = useState([]);
  const [activateUser, setActivateUser] = useState(false);

  const adminRoles = [ROLES.ADMIN, ROLES.CASE_ADMIN, ROLES.USER_GROUP_ADMIN];
  const today = new Date();

  useEffect(() => {
    const _allStatus = Object.values(CASE_STATUS).map((s) => ({
      id: s,
      displayKey: `CaseManagement.${s}`
    }));
    setAllStatus(_allStatus);
  }, [activateUser]);

  function formateDate(date) {
    const dateFormat = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", dateFormat);
  }

  const addNote = () => {
    setNewNote({
      author: currentUser,
      date: today.toDateString(),
      comment: ""
    });
  };

  const updateComment = (e) => {
    const _newNote = { ...newNote };
    _newNote.comment = e.target.value;
    setNewNote(_newNote);
  };

  const onLocationChange = (newValue) => {
    onFieldChange("location", newValue);
  };

  const onAdminUsersChange = (newValue) => {
    onFieldChange("admins", newValue);
  };

  const onUserChange = (newValue) => {
    onFieldChange("user", newValue);
  };

  const transformUser = {
    fromFireStore: (user, list) => {
      return list.find((u) => u.userId === user.id);
    },
    toFireStore: (user) => {
      return { displayName: user.displayName, id: user.userId };
    }
  };

  const transformLocation = {
    fromFireStore: (loc, list) => {
      return list.find((l) => l.locationId === loc.id);
    },
    toFireStore: (loc) => {
      return {
        coordinate: loc.coordinate,
        displayName: loc.displayName,
        id: loc.locationId
      };
    }
  };

  const onFieldChange = (field, value) => {
    let _reportedCase = { ...reportedCase };
    _reportedCase[field] = value;
    setReportedCase(_reportedCase);
  };

  const save = () => {
    const _errorMessages = runFormValidadtion();
    if (_errorMessages.length === 0) {
      if (newNote) {
        reportedCase.notes.push({ ...newNote });
        setNewNote(null);
      }
      reportedCase.changeUserState = activateUser;
      return saveFunc(reportedCase);
    }
    setErrorMessages(_errorMessages);
  };

  const runFormValidadtion = () => {
    const _errorMessages = [];

    if (!caseHasUser()) {
      _errorMessages.push(t("CaseManagement.CaseMustHaveUser"));
    }

    if (!caseHasAdmin()) {
      _errorMessages.push(t("CaseManagement.CaseMustHaveAdmin"));
    }

    if (!caseHasLocation()) {
      _errorMessages.push(t("CaseManagement.CaseMustHaveLocation"));
    }

    return _errorMessages;
  };

  const caseHasAdmin = () => {
    return reportedCase.admins && reportedCase.admins.length > 0;
  };

  const caseHasUser = () => {
    return !!reportedCase.user;
  };

  const caseHasLocation = () => {
    return !!reportedCase.location;
  };

  const getUserState = (userId) => {
    const user = usersList.find((user) => user.userId === userId);
    return user.state || "";
  };

  const handleChangeUserState = () => {
    if (!activateUser) {
      setNewNote({
        author: currentUser,
        date: today.toDateString(),
        comment: t("CaseManagement.UnlockingUserComment")
      });
    } else {
      setNewNote(null);
    }

    setActivateUser(!activateUser);
  };

  const renderStateButton = () => {
    const actualUserState = (_case.user && getUserState(_case.user.id)) || "";
    if (actualUserState !== "") {
      const { chipProps, toolTipContentKey } =
        buildActivateButtonProps(actualUserState);

      return (
        <Tooltip title={t(toolTipContentKey)}>
          <Chip {...chipProps} />
        </Tooltip>
      );
    }
    return null;
  };

  const buildActivateButtonProps = (userState) => {
    let toolTipContentKey = "";
    let chipProps = { size: "small" };
    if (userState === USER_STATUS.ACTIVE) {
      toolTipContentKey = "CaseManagement.ActiveChipTooltip";
      chipProps.label = t("CaseManagement.UserIsActive");
      chipProps.className = classes.chipIsInActiveState;
    } else if (userState === USER_STATUS.LOCKED) {
      if (activateUser) {
        toolTipContentKey = "CaseManagement.ActivateButtonWaitingTooltip";
        chipProps.label = t("CaseManagement.UserWillBeActivated");
        chipProps.className = classes.chipIsInWaitingState;
        chipProps.onClick = handleChangeUserState;
      } else {
        toolTipContentKey = "CaseManagement.ActivateButtonTooltip";
        chipProps.label = t("CaseManagement.ActivateUser");
        chipProps.className = classes.chipIsInLockedState;
        chipProps.onClick = handleChangeUserState;
      }
    }

    return { chipProps, toolTipContentKey };
  };

  const renderTitleSection = () => {
    if (createMode) {
      return (
        <>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography variant="h4">
                {t("CaseManagement.CreateNewCase")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              justify="center"
              className={classes.titleRightAlign}
            >
              {`${t("CaseManagement.LastChangedIn")} ${formateDate(
                createMode ? today : reportedCase.lastChange.toDate()
              )}`}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{formateDate(today)}</Typography>
            </Grid>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <div className={classes.titleAndStateWrapper}>
              <Typography variant="h4">
                {reportedCase.user.displayName}
              </Typography>
              {renderStateButton()}
            </div>
          </Grid>
          <Grid
            item
            xs={7}
            justify="center"
            className={classes.titleRightAlign}
          >
            {`${t("CaseManagement.LastChangedIn")} ${formateDate(
              createMode ? today : reportedCase.lastChange.toDate()
            )}`}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{`${formateDate(
              reportedCase.openingDate.toDate()
            )} by ${reportedCase.openedBy.displayName}`}</Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Dialog
      open={openForm}
      fullWidth={true}
      maxWidth="md"
      className={classes.root}
    >
      <DialogTitle>{renderTitleSection()}</DialogTitle>
      {errorMessages.length > 0 && (
        <ErrorAlert
          errorMessages={errorMessages}
          onCloseAlert={setErrorMessages}
          styles={classes.errorMsg}
        />
      )}
      <DialogContent className={classes.content}>
        <Grid container spacing={3} className={classes.fields}>
          <Grid item xs={3}>
            <UserSelector
              contentList={usersList}
              labelKey={"Commons.User"}
              transform={transformUser}
              disabled={!createMode}
              controller={{
                value: reportedCase.user,
                setter: onUserChange
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <UserSelector
              contentList={usersList}
              labelKey={"CaseManagement.CaseAdmin"}
              transform={transformUser}
              filterRoles={adminRoles}
              controller={{
                value: reportedCase.admins,
                setter: onAdminUsersChange
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled
              fullWidth
              label={t("CaseManagement.CaseType")}
              defaultValue={t(reportedCase.type.displayKey)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <SimpleSelector
              disabled={false}
              value={reportedCase.status}
              onChangeFunc={onFieldChange}
              options={allStatus}
              label={t("Commons.Status")}
              fieldId="status"
            />
          </Grid>
          <Grid item xs={6}>
            <LocationSelector
              contentList={locationsList}
              company={company}
              labelKey={"Commons.Location"}
              transform={transformLocation}
              disabled={!createMode}
              controller={{
                value: reportedCase.location,
                setter: onLocationChange
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={1}
          alignItems="center"
          justify="space-between"
          className={classes.notesLabel}
        >
          <Grid container item alignItems="center" sm={11} spacing={1}>
            <Grid item xs={1}>
              <Typography variant="h6" gutterBottom>
                {t("CaseManagement.Notes")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Tooltip
                arrow
                placement="top"
                title={t("CaseManagement.AddComments")}
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            className={classes.addButton}
            onClick={addNote}
          >
            <AddIcon />
          </Button>
        </Grid>
        {reportedCase.notes.map((note, index) => (
          <Grid item key={`self-${index}`}>
            <TextField
              variant="outlined"
              className={classes.note}
              disabled={true}
              label={`${note.author.displayName} - ${formateDate(
                new Date(note.date)
              )}`}
              value={note.comment}
              multiline={true}
              fullWidth
            />
          </Grid>
        ))}
        <Grid item>
          {!!newNote && (
            <TextField
              variant="outlined"
              className={classes.note}
              label={`${newNote.author.displayName} - ${formateDate(
                new Date(newNote.date)
              )}`}
              value={newNote.comment}
              onChange={updateComment}
              multiline={true}
              fullWidth
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={cancelFunc}>{t("Commons.Cancel")}</Button>
        <Button onClick={save} color="primary" variant="contained">
          {t("Commons.Save")}
        </Button>
        {spinnerOn && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </DialogActions>
    </Dialog>
  );
};
