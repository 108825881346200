import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers/rootReducer";

export default function configureStore(preloadState = {}) {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middlewares));
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);
  const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2
  };
  
  const pReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(pReducer, preloadState, composedEnhancers);
  const persistor = persistStore(store);
  return {store, persistor};
}
