import React from "react";
import PropTypes from "prop-types";
import { useCompanyService } from "../../services/useCompanyService";
import { useTranslation } from "react-i18next";
import BaseSelector from "./BaseSelector";

const AppointmentTypeSelector = ({ controller, labelKey }) => {
  /* eslint-disable no-unused-vars */
  const [t, i18n] = useTranslation();
  const companyService = useCompanyService();
  const optionLabelFormater = (type) => {
    return type.displayName && type.displayName[i18n.language];
  };

  return (
    <BaseSelector
      _controller={controller}
      _labelKey={labelKey}
      _ajaxMethod={companyService.getAppointmentTypes}
      _optionLabelFormater={optionLabelFormater}
      _idFieldName={"appointmentTypeId"}
    />
  );
};

AppointmentTypeSelector.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default AppointmentTypeSelector;
