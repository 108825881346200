import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  LinearProgress,
  makeStyles,
  Typography,
  Tabs,
  Tab,
  Grid,
  Switch
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setErrorMessage, setSuccessMessage } from "../../actions";
import { useCompanyService } from "../../services/useCompanyService";
import { useLocalStore } from "../../hooks/useLocalStore";
import { format, formatISO, set } from "date-fns";
import { ErrorAlert } from "../common/ErrorAlert";
import validateEvent from "./EventDetailsValidator";
import EventDetailsSummaryTab from "./EventDetailsSummaryTab";
import EventDetailsAttendeesTab from "./EventDetailsAttendeesTab";
import { ACTIONS_ROLES } from "../../constants";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    padding: "1rem"
  },
  tabIndexBar: {
    padding: "1rem 0",
    alignSelf: "flex-start"
  },
  eventHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  errorMsg: {
    margin: "1rem 0"
  }
}));

export const EVENT_DETAILS_TABS = [
  { id: "EVENT_DETAILS", titleKey: "Events.EventDetails" },
  { id: "EVENT_ATTENDEES", titleKey: "Events.AttendeesList" }
];

const currentDateWithTime = (startOfDay = true) => {
  const date = new Date();
  if (startOfDay) {
    date.setHours(0);
    date.setMinutes(0);
  } else {
    date.setHours(23);
    date.setMinutes(59);
  }
  return date;
};

const EMPTY_EVENT = {
  hiveEvent: true,
  meetingId: null,
  locationId: null,
  displayName: "",
  details: "",
  date: new Date(),
  occupancy: null,
  organizer: null,
  owner: null,
  startTime: currentDateWithTime(true),
  endTime: currentDateWithTime(false),
  changeLog: [],
  attendees: [],
  notifications: [],
  coordinate: null,
  reservationStartTime: currentDateWithTime(true),
  reservationEndTime: currentDateWithTime(false),
  status: null
};
export default function EventDetails({ createMode = false }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const localStore = useLocalStore();
  const { id: eventId } = useParams();
  const history = useHistory();

  const company = localStore.getCompany();
  const companyService = useCompanyService();
  const userRolesUtil = localStore.getUserRolesUtil();

  const [event, setEvent] = useState();
  const [selectedTab, setSelectedTab] = useState(EVENT_DETAILS_TABS[0].id);
  const [editMode, setEditMode] = useState(false);
  const [attendees, setAttendees] = useState([]);

  const [locationsList, setLocationsList] = useState([]);

  const [usersList, setUsersList] = useState([]);

  const [errors, setErrors] = useState([]);

  const [init, setInit] = useState(false);

  const loadEvent = async () => {
    try {
      const event = await companyService.getEvent(eventId);
      if (event) {
        setEvent(event);
        setInit(true);
        setAttendees([...event.attendees]);
      } else {
        console.log("Event not found.");
        dispatch(setErrorMessage(t("Errors.General")));
        history.replace("/events");
      }
    } catch (error) {
      console.log("Error fetching events: ", error);
      dispatch(setErrorMessage(t("Errors.General")));
      history.replace("/events");
    }
  };

  const loadLocationsList = async () => {
    try {
      const _locationsList =
        await companyService.getLocationsFromDropdownListCollection();
      setLocationsList(_locationsList);
    } catch (error) {
      console.log("Error fetching locations: ", error);
      dispatch(setErrorMessage(t("Events.EventNotFound")));
      history.replace("/events");
    }
  };

  const loadUsersList = async () => {
    try {
      const _usersList =
        await companyService.getUsersFromDropdownListCollection();
      setUsersList(_usersList);
    } catch (error) {
      console.log("Error fetching users: ", error);
      dispatch(setErrorMessage(t("Errors.General")));
      history.replace("/events");
    }
  };

  useEffect(() => {
    if (!init) {
      if (createMode) {
        setEvent(EMPTY_EVENT);
        setInit(true);
      } else {
        loadEvent();
      }

      loadLocationsList();
      loadUsersList();
    }
  }, []);

  const isUserAllowedToEdit = () =>
    userRolesUtil.hasRoles(ACTIONS_ROLES.EDIT_EVENT);

  const onDateChange = (date) =>
    setEvent({
      ...event,
      date: formatISO(date),
      startTime: formatISO(
        set(date, {
          hours: format(new Date(event.startTime), "HH"),
          minutes: format(new Date(event.startTime), "mm")
        })
      ),
      endTime: formatISO(
        set(date, {
          hours: format(new Date(event.endTime), "HH"),
          minutes: format(new Date(event.endTime), "mm")
        })
      ),
      reservationStartTime: formatISO(
        set(date, {
          hours: format(new Date(event.reservationStartTime), "HH"),
          minutes: format(new Date(event.reservationStartTime), "mm")
        })
      ),
      reservationEndTime: formatISO(
        set(date, {
          hours: format(new Date(event.reservationEndTime), "HH"),
          minutes: format(new Date(event.reservationEndTime), "mm")
        })
      )
    });

  const onSave = async () => {
    console.log(event);
    const _errorMessages = validateEvent(event);
    setErrors(_errorMessages);

    if (_errorMessages.length === 0) {
      setErrors([]);
      if (createMode) {
        const eventData = {...event, attendees: attendees};

        const result = await companyService.createEvent(eventData);

        result.data.success ? dispatch(setSuccessMessage(t("Success.CreateEvent"))) : 
          dispatch(setErrorMessage(t("Errors.CreateEvent")));
      } else {
        const addedAttendees = attendees.filter((attendee) => !event.attendees.includes(attendee));
        const removedAttendees = event.attendees.filter((attendee) => !attendees.includes(attendee));

        const eventData = {...event, attendees: attendees, addedAttendees: addedAttendees, removedAttendees: removedAttendees};

        const result = await companyService.updateEvent(eventData);

        result.data.success ? dispatch(setSuccessMessage(t("Success.UpdateEvent"))) : 
          dispatch(setErrorMessage(t("Errors.UpdateEvent")));
      }
    }
  };

  const onAddAttendee = async (selectedUser) => {
    const _attendees = [...attendees, selectedUser];
    setAttendees(_attendees);
    const eventData = {...event, attendees: _attendees, addedAttendees: [selectedUser], removedAttendees: []};
    await companyService.updateEvent(eventData);
  };

  const onRemoveAttendee = async (attendeeIndex) => {
    const removedUser = attendees[attendeeIndex];
    const _attendees = [...attendees];
    _attendees.splice(attendeeIndex, 1);

    setAttendees(_attendees);
    const eventData = {...event, attendees: _attendees, addedAttendees: [], removedAttendees: [removedUser]};
    await companyService.updateEvent(eventData);
  };

  const mapUsers = () => {
    return usersList
      .filter(
        (user) =>
          !event.attendees.find((attendee) => attendee.userId === user.userId)
      )
      .map((user) => {
        const splittedName = user.displayName.split(" ");

        const firstName = splittedName
          .slice(0, splittedName.length - 1)
          .join(" ");
        const lastName = splittedName[splittedName.length - 1];

        return {
          ...user,
          firstName,
          lastName
        };
      });
  };

  const renderSelectedTabContent = () => {
    switch (selectedTab) {
      case "EVENT_DETAILS":
        return (
          <EventDetailsSummaryTab
            event={event}
            setEvent={setEvent}
            editMode={createMode || editMode}
            createMode={createMode}
            allowedToEdit={isUserAllowedToEdit()}
            company={company}
            locationsList={locationsList}
            usersList={usersList}
            onDateChange={onDateChange}
            onSave={onSave}
          />
        );
      case "EVENT_ATTENDEES":
        return (
          <EventDetailsAttendeesTab
            eventTitle={event.displayName}
            usersList={mapUsers()}
            attendees={attendees}
            canAddRemove={isUserAllowedToEdit()}
            onAddAttendee={onAddAttendee}
            onRemoveAttendee={onRemoveAttendee}
          />
        );
      default:
        return;
    }
  };

  if (init) {
    return (
      <div className="content">
        <Container className={classes.container}>
          <Typography variant="h3">{t(selectedTab.titleKey)}</Typography>

          <Tabs
            value={selectedTab}
            variant="fullWidth"
            className={classes.tabIndexBar}
            indicatorColor="primary"
            onChange={(event, value) => setSelectedTab(value)}
          >
            {EVENT_DETAILS_TABS.map((tab) => (
              <Tab key={tab.id} value={tab.id} label={t(tab.titleKey)} disabled={createMode && tab.id === "EVENT_ATTENDEES"} />
            ))}
          </Tabs>

          {errors.length > 0 && (
            <ErrorAlert
              errorMessages={errors}
              onCloseAlert={() => setErrors([])}
              styles={classes.errorMsg}
            />
          )}
          <div className={classes.eventHeader}>
            {isUserAllowedToEdit() && selectedTab === "EVENT_DETAILS" && !createMode && (
              <Typography component="div">
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>{t("Commons.Edit")}:</Grid>
                  <Grid item>Off</Grid>
                  <Grid item>
                    <Switch
                      value={editMode}
                      color="primary"
                      onChange={(event, value) => setEditMode(value)}
                    />
                  </Grid>
                  <Grid item>On</Grid>
                </Grid>
              </Typography>
            )}
          </div>
          {renderSelectedTabContent()}
        </Container>
      </div>
    );
  } else {
    return <LinearProgress />;
  }
}

EventDetails.propTypes = {
  createMode: PropTypes.bool
};
