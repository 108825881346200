import React, {useState} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  CircularProgress,
  ListItem
} from "@material-ui/core";
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import Alert from "@material-ui/lab/Alert";
import typography from "../../theme/typography";
import { grey } from "@material-ui/core/colors";
import { isEmpty } from "../../common";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  typography: {
    marginBottom: "1rem"
  },
  typographyTop: {
    marginTop: "1rem"
  },
  description: {
    fontWeight: "bold",
    marginTop: theme.spacing(1)
  },
  title: {
    marginBottom: theme.spacing(1),
    "& h2": {
      ...typography.h3
    }
  },
  actions: {
    padding: theme.spacing(1, 3),
    margin: theme.spacing(2, 0)
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  list: {
    marginTop: theme.spacing(1)
  }
}));

export const DeleteLocation = ({
  locationName,
  locationType,
  assignedUsers,
  preferredUsers,
  confirm,
  cancel
}) => {
  const classes = useStyles();
  const [isDeleting, setIsDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  async function deleteLocation() {
    setIsDeleting(true);
    await confirm(setErrorMessage);
    setIsDeleting(false);
  }

  function renderRow({ index, style, data }) {
    return (
      <ListItem style={style} key={index}>
        <ListItemText>{data.users[index]}</ListItemText>
      </ListItem>
    );
  }

  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm">
      <DialogTitle className={classes.title}>
        {t("Locations.DeleteLocation")}
      </DialogTitle>
      {errorMessage && (
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </Alert>
      )}
      <DialogContent>
        <Typography className={classes.typography} variant="h5">
          {locationType + ": " + locationName}
        </Typography>
        <Typography className={classes.typography}>
          {t("Locations.ConfirmDeleteLocation")}
        </Typography>
        {!isEmpty(assignedUsers) && (
          <>
            <Typography className={classes.description}>
              {t("Locations.CurrentlyAssignedTo")}
            </Typography>
            <FixedSizeList
              height={50}
              width={400}
              itemSize={25}
              className={classes.list}
              itemCount={assignedUsers.length}
              itemData={{ users: assignedUsers }}
            >
              {renderRow}
            </FixedSizeList>
          </>
        )}
        {!isEmpty(preferredUsers) && (
          <>
            <Typography className={classes.description}>
              {t("Locations.CurrentlyPreferredBy")}
            </Typography>
            <FixedSizeList
              height={75}
              width={400}
              itemSize={25}
              className={classes.list}
              itemCount={preferredUsers.length}
              itemData={{ users: preferredUsers }}
            >
              {renderRow}
            </FixedSizeList>
          </>
        )}
        {(!isEmpty(preferredUsers) || !isEmpty(assignedUsers)) && (
          <Typography className={classes.typographyTop}>
            {t("Locations.ConfirmRemoveLocation")}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={cancel}>{t("Commons.Cancel")}</Button>
        <Button
          onClick={deleteLocation}
          color="primary"
          variant="contained"
          disabled={isDeleting}
        >
          {t("Commons.Yes")}{" "}
          {isDeleting && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteLocation.defaultProps = {
  loading: false
};
