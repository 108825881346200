import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import LocationSelector from "../../components/Selectors/LocationSelector";
import { grey } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: "20px 0"
  },
  requestParams: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "1rem"
  },
  filterRow: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    gap: "1rem"
  },
  filterItem: {
    flex: "1",
    alignSelf: "center"
  },
  searchBarContainer: {
    width: "calc(33% - 0.5rem)"
  },
  bottomRow: {
    display: "flex",
    gap: "1rem"
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function EventsFilters({
  loadingView = false,
  startingDate,
  endingDate,
  setStartingDate,
  setEndingDate,
  locationsList,
  locationController,
  company,
  onSearchEvents
}) {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <div
      className={classnames({
        [classes.requestParams]: true,
        [classes.wrapper]: true
      })}
    >
      <div className={classes.filterRow}>
        <KeyboardDatePicker
          className={classes.filterItem}
          disableToolbar
          inputVariant="outlined"
          format="MM/dd/yyyy"
          label={t("Commons.StartDate")}
          name="startingEventDate"
          maxDate={endingDate}
          value={startingDate}
          onChange={(date) => {
            setStartingDate(date);
          }}
        />
        <KeyboardDatePicker
          className={classes.filterItem}
          disableToolbar
          inputVariant="outlined"
          format="MM/dd/yyyy"
          label={t("Commons.EndDate")}
          name="endingEventDate"
          minDate={startingDate}
          value={endingDate}
          onChange={(date) => {
            setEndingDate(date);
          }}
        />

        <div className={classes.filterItem}>
          <LocationSelector
            contentList={locationsList}
            company={company}
            labelKey={"Commons.Location"}
            controller={locationController}
          />
        </div>
      </div>

      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          disabled={loadingView}
          onClick={onSearchEvents}
        >
          {t("Events.ShowEvents")}
          {loadingView && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loadingView}
          onClick={() => history.push("/events/new")}
        >
          {t("Events.NewEvent")}
          {loadingView && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </div>
    </div>
  );
}

EventsFilters.propTypes = {
  loadingView: PropTypes.bool,
  startingDate: PropTypes.instanceOf(Date).isRequired,
  endingDate: PropTypes.instanceOf(Date).isRequired,
  setStartingDate: PropTypes.func.isRequired,
  setEndingDate: PropTypes.func.isRequired,
  locationsList: PropTypes.array.isRequired,
  company: PropTypes.object.isRequired,
  locationController: PropTypes.object.isRequired,
  onSearchEvents: PropTypes.func.isRequired
};
