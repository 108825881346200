import React from "react";
import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    display: "flex",
    gap: "8px",
    color: theme.palette.text.secondary
  },
  labelColorDot: (props) => ({
    height: "1rem",
    width: "1rem",
    borderRadius: "50%",
    background: props.color,
    border: `1px solid ${theme.palette.secondary.light}`
  })
}));

function LocationTag({ label, color, style = {} }) {
  const classes = useStyles({ color });

  const ChipLabel = (
    <div className={classes.labelContainer}>
      <div className={classes.labelColorDot} />
      <span>{label}</span>
    </div>
  );

  return <Chip label={ChipLabel} variant="outlined" style={style} />;
}

export default LocationTag;
