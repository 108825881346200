import React from "react";

const ColorSelectorRadioLabel = ({ color, selected }) => {
  const borderStyle = {
    padding: "2px",
    height: "2.5rem",
    width: "2.5rem",
    borderRadius: "50%",
    border: `${selected ? `2px solid ${color}` : "2px solid white"}`,
    transition: "border 0.2s linear"
  };

  const backgroundStyle = {
    borderRadius: "50%",
    background: color,
    height: "100%",
    width: "100%"
  };

  return (
    <div style={borderStyle}>
      <div style={backgroundStyle} />
    </div>
  );
};

export default ColorSelectorRadioLabel;
