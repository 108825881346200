import { storage, database } from "../firebase";
import { isTrue, isEmpty } from "../common";
import { useLocalStorage } from "../hooks/useLocalStorage";
import admin from "firebase";
import FirebaseUtil from "../utils/FirebaseUtil";

export const useUserService = () => {
  const storageRef = storage.ref();
  const localStorage = useLocalStorage();
  const companyId = localStorage.getCompanyID();

  const getFloorMapUrl = (path) => {
    return storageRef.child(path).getDownloadURL();
  };

  const getUserEligibleLocations = (user, locations, coordinates) => {
    let _eligibleSeats = [];

    //Get eligible root locations for user
    let _rootLocations = locations.filter(
      (location) =>
        location.locationParent === "" &&
        isLocationEligibleForUserGroups(user.groups, location)
    );
    _rootLocations.forEach((_rootLocation) => {
      let _locationSeats = fetchBookableLocations(
        _rootLocation,
        user,
        locations,
        coordinates
      );
      _eligibleSeats = _eligibleSeats.concat(_locationSeats);
    });
    return _eligibleSeats;
  };

  /**
   * Get eligible NoneBookable Locations for userGroups
   * @param userGroups
   * @param locations
   * @param coordinates
   * @returns {[]}
   */
  const getEligibleNoneBookableLocations = (
    userGroups,
    locations,
    coordinates
  ) => {
    let _eligibleLocations = [];

    //Get eligible root locations for user
    let _rootLocations = locations.filter(
      (location) =>
        location.locationParent === "" &&
        isLocationEligibleForUserGroups(userGroups, location)
    );
    _rootLocations.forEach((_rootLocation) => {
      _eligibleLocations.push(_rootLocation);
      let _locations = fetchNoneBookableLocations(
        _rootLocation,
        userGroups,
        locations,
        coordinates
      );
      _eligibleLocations = _eligibleLocations.concat(_locations);
    });
    return _eligibleLocations;
  };

  const fetchNoneBookableLocations = (
    rootLocation,
    userGroups,
    locations,
    coordinates
  ) => {
    let _locations = [];
    let _childLocations = locations.filter(
      (location) => location.locationParent === rootLocation.locationId
    );
    for (let i = 0; i < _childLocations.length; i++) {
      const _childLocation = _childLocations[i];
      let _isBottomLevel =
        _childLocation.locationType === coordinates[coordinates.length - 1];
      if (!_isBottomLevel) {
        let _isEligible = isLocationEligibleForUserGroups(
          userGroups,
          _childLocation
        );
        if (_isEligible) {
          _locations.push(_childLocation);
          let _subLocation = fetchNoneBookableLocations(
            _childLocation,
            userGroups,
            locations,
            coordinates
          );
          _locations = _locations.concat(_subLocation);
        }
      } else {
        break;
      }
    }

    return _locations;
  };

  /**
   * Check if location is Eligible for userGroups, which the location with no group (for everybody)
   * or location userGroups intercept with userGroups
   * Notes: if userGroups is null or undefined, it won't check the userGroups.
   * empty userGroups is one of the use case which user can have no userGroups.
   * @param userGroups
   * @param location
   * @returns {boolean}
   */
  const isLocationEligibleForUserGroups = (userGroups, location) => {
    if (userGroups) {
      let _locationUserGroups = location.groups;
      return (
        !_locationUserGroups ||
        _locationUserGroups.length === 0 ||
        (userGroups.length > 0 &&
          _locationUserGroups.some((group) => userGroups.includes(group)))
      );
    } else return true;
  };

  const fetchBookableLocations = (
    rootLocation,
    user,
    locations,
    coordinates
  ) => {
    let _seats = [];
    // children
    let _childLocations = locations.filter(
      (location) => location.locationParent === rootLocation.locationId
    );
    _childLocations.forEach((location) => {
      let _bookable = isTrue(location.bookable);
      if (!_bookable) {
        let _isEligible = isLocationEligibleForUserGroups(
          user.groups,
          location
        );
        if (_isEligible) {
          let _locationSeats = fetchBookableLocations(
            location,
            user,
            locations,
            coordinates
          );
          _seats = _seats.concat(_locationSeats);
        }
      } else if (
        isTrue(location.atBottomLevel) ||
        isLocationEligibleForUserGroups(user.groups, location)
      ) {
        _seats.push(location);
      }
    });
    // root level
    if (
      rootLocation.bookable &&
      (isTrue(rootLocation.atBottomLevel) ||
        isLocationEligibleForUserGroups(user.groups, rootLocation))
    ) {
      _seats.push(rootLocation);
    }
    return _seats;
  };

  const getUsersRef = () => {
    return database.collection("Companies").doc(companyId).collection("Users");
  };

  const getUsersByAttribute = async (
    attribute,
    values,
    attributeIsArray = true
  ) => {
    let users = [];
    await getUsersRef()
      .where(attribute, attributeIsArray ? "array-contains-any" : "in", values)
      .get()
      .then((snapshots) =>
        snapshots.forEach((doc) => {
          let _userId = { userId: doc.id };
          let _userData = doc.data();
          let _userObj = { ..._userId, ..._userData };
          users.push(_userObj);
        })
      );

    sortUsersByDisplaName(users);

    return users;
  };

  const sortUsersByDisplaName = (users) => {
    users.sort((a, b) => {
      const displayNameOfA = `${a.firstName} ${a.lastName}`;
      const displayNameOfB = `${b.firstName} ${b.lastName}`;

      // prettier-ignore
      return (displayNameOfA > displayNameOfB) ? 1 :
        (displayNameOfA === displayNameOfB) ? ((a.email > b.email) ? 1 : -1) : -1;
    });
  };

  const getPaginatedUsers = async (
    index = null,
    previous = null,
    next = null,
    batchSize = 15
  ) => {
    let hasNextPage = false;
    let results = [];
    let ref = getUsersRef()
      .orderBy("firstName")
      .orderBy("lastName")
      .orderBy("email");

    if (index != null) {
      ref = ref
        .where("firstName", ">=", index)
        .where("firstName", "<", String.fromCharCode(index.charCodeAt(0) + 1));
    }

    if (next != null) {
      ref = ref.startAfter(next.firstName, next.lastName, next.email);
    }

    if (previous != null) {
      hasNextPage = true;
      ref = ref
        .endBefore(previous.firstName, previous.lastName, previous.email)
        .limitToLast(batchSize);
    } else {
      ref = ref.limit(batchSize + 1);
    }

    const snapshots = await ref.get();
    snapshots.forEach((doc) => {
      let _userId = { userId: doc.id };
      let _userData = doc.data();
      let _userObj = { ..._userId, ..._userData };
      results.push(_userObj);
    });

    results.forEach((u) => (u.state = isEmpty(u.state) ? "ACTIVE" : u.state));

    if (results.length > batchSize) {
      hasNextPage = true;
      results.pop();
    }

    return { results, hasNextPage };
  };

  const getUsersByIds = async (listOfIds) => {
    const fetchUsers = async (userIds) => {
      let users = [];
      await getUsersRef()
        .where(admin.firestore.FieldPath.documentId(), "in", userIds)
        .get()
        .then((snapshots) =>
          snapshots.forEach((doc) => {
            let _userId = { userId: doc.id };
            let _userData = doc.data();
            _userData.state = isEmpty(_userData.state)
              ? "ACTIVE"
              : _userData.state;
            let _userObj = { ..._userId, ..._userData };
            users.push(_userObj);
          })
        );

      return users;
    };

    const results = await FirebaseUtil.makeBatchCall(listOfIds, fetchUsers);

    sortUsersByDisplaName(results);

    return results;
  };

  return {
    getUserEligibleLocations,
    getEligibleNoneBookableLocations,
    getFloorMapUrl,
    getUsersByAttribute,
    getPaginatedUsers,
    getUsersByIds,
    isLocationEligibleForUserGroups
  };
};
