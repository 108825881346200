import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Typography,
  TextField,
  Button,
  Card
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useAuthService } from "../../services/useAuthService";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../actions/index";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  signInFormWrapper: {
    width: "100%",
    maxWidth: "900px",
    margin: "0 auto",
    textAlign: "center"
  },
  signInFormCard: {
    marginBottom: theme.spacing(5)
  },
  cardContentWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  cardImageWrapper: {
    width: "46%",
    backgroundImage: "url('../signin.jpg')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  cardFormWrapper: {
    width: "54%",
    padding: theme.spacing(5)
  },
  title: {
    textAlign: "center"
  },
  errorMessage: {
    minHeight: "30px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  form: {
    maxWidth: "400px",
    margin: "auto",
    marginBottom: "1.5em"
  },
  textField: {
    marginBottom: theme.spacing(3)
  },
  signUpLink: {
    color: "#1264a3"
  },
  button: {
    marginRight: theme.spacing(1)
  }
}));

export const ForgotPassword = () => {
  const classes = useStyles();
  const authService = useAuthService();

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const sendPasswordEmail = async () => {
    try {
      await authService.sendResetPasswordEmail(email.trim());
      dispatch(
        setSuccessMessage(t("Success.EmailResetPassword", { email: email }))
      );
    } catch (error) {
      console.log(error);
      setErrorMessage(t("Errors.General") + error.message);
      return;
    }
  };
  return (
    <div className="content">
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.signInFormWrapper}>
          <Card className={classes.signInFormCard}>
            <div className={classes.cardContentWrapper}>
              <div className={classes.cardImageWrapper}></div>
              <div className={classes.cardFormWrapper}>
                <img
                  alt={t("SignIn.SignUpLink")}
                  src="../logo.jpg"
                  style={{ width: "300px", marginBottom: "24px" }}
                />
                <Typography variant="h2" className={classes.title}>
                  {t("SignIn.ResetPassword")}
                </Typography>
                <form noValidate className={classes.form}>
                  <div className={classes.errorMessage}>
                    {errorMessage !== "" && (
                      <Alert
                        severity="error"
                        onClose={() => {
                          setErrorMessage("");
                        }}
                      >
                        {errorMessage}
                      </Alert>
                    )}
                  </div>
                  <TextField
                    className={classes.textField}
                    required
                    fullWidth
                    variant="outlined"
                    label={t("Commons.Email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      sendPasswordEmail();
                    }}
                  >
                    {t("ResetPassword.SendEmail")}
                  </Button>
                </form>
              </div>
            </div>
          </Card>
        </div>
      </Container>
    </div>
  );
};
