import React from 'react';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import QrPopup from "qrcode.react";

const QRCodePopup = (props) => {
  const { onClose, selectedValue, open, title } = props;
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <div style={{ fontSize: "24px" }}>{title}</div>
      </DialogTitle>

      <DialogContent>
        <div style={{ paddingBottom: "10px" }}>
          <QrPopup
            value={selectedValue ? selectedValue : ""}
            size={512}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={false}
            renderAs={"canvas"}
            imageSettings={{
              src: "./hive-logo.png",
              x: null,
              y: null,
              height: 64,
              width: 64,
              excavate: true
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};


export default QRCodePopup;
