import React, { useState } from 'react';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import PropType from "prop-types";

const LoadingButton = ({ icon, text = "", onClick, loadingSize = 40, loadingStyle }) => {

  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  const renderButton = () => {
    return icon ? (
      <IconButton onClick={handleClick}>
        {loading ? <CircularProgress size={loadingSize} sx={loadingStyle}/> : icon}
      </IconButton> 
    ) : (
      <Button onClick={handleClick}>
        {loading ? <CircularProgress size={loadingSize} sx={loadingStyle}/> : text}
      </Button>
    );
  };

  return (
    renderButton()
  );
};

LoadingButton.propType = {
  icon: PropType.object,
  onClick: PropType.func.isRequired,
  text: PropType.string,
  loadingSize: PropType.number,
  loadingStyle: PropType.object
};

export default LoadingButton;