import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCurrentUser, setCompany } from "../actions";
import { CompanyPreferencesUtil } from "../utils/CompanyPreferencesUtil";
import { UserRolesUtil } from "../utils/UserRolesUtil";

export const useLocalStore = () => {
  const dispatch = useDispatch();
  const localStore = useSelector((state) => state);

  return {
    getCurrentUser: () => {
      return localStore.currentUser;
    },

    setCurrentUser: (user) => {
      dispatch(setCurrentUser(user));
    },

    getCompany: () => {
      return localStore.company;
    },

    setCompany: (companyInfo) => {
      dispatch(setCompany(companyInfo));
    },

    getCompanyPreferencesUtil: () => {
      return CompanyPreferencesUtil(localStore.company.preferences);
    },

    getUserRolesUtil: () => {
      return UserRolesUtil(localStore.currentUser);
    }
  };
};
