import { useState, useCallback } from 'react';
import useLocationTagsValidation from './useLocationTagsValidation';
import { useCompanyService } from '../../services/useCompanyService';
import { useDispatch } from 'react-redux';
import { setSuccessMessage, setErrorMessage } from '../../actions';
import { useTranslation } from 'react-i18next';

export default function useSubmitLocationTagsForm(
  tags,
  loadLocationTags,
  closeDialog
) {
  const { formErrors } = useLocationTagsValidation(tags);
  const [validationErrors, setValidationErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const companyService = useCompanyService();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (formErrors.length > 0) {
        setValidationErrors(formErrors);
        return;
      }

      setLoading(true);

      const response = await companyService.updateLocationTags(tags);

      if (!response?.data?.success) {
        dispatch(setErrorMessage(t('Locations.UpdateTagsFail')));
        setLoading(false);
        return;
      }

      loadLocationTags();
      dispatch(setSuccessMessage(t('Locations.UpdateTagsSuccess')));
      setValidationErrors([]);
      setLoading(false);
      closeDialog();
    },
    [
      closeDialog,
      companyService,
      dispatch,
      formErrors,
      loadLocationTags,
      t,
      tags
    ]
  );

  return { loading, onSubmit, validationErrors, setValidationErrors };
}
