import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { blueGrey } from "@material-ui/core/colors";
import { Radio } from "@material-ui/core";

const StyledRadio = withStyles((theme) => ({
  root: {
    color: blueGrey[400],
    "&$checked": {
      color: theme.palette.primary.main
    }
  },
  checked: {}
}))((props) => <Radio required color="default" {...props} />);

StyledRadio.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default StyledRadio;