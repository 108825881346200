import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Button,
  Typography,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";
import BasicInfo from "./BasicInfo";
import VisualCustomization, { colourDefaults } from "./VisualCustomization";
import ContactInfo from "./ContactInfo";
import GeneralInfo from "./GeneralInfo";
import Preferences from "./Preferences";
import CompanyLogo from "./CompanyLogo";
import HealthScreening from "./HealthScreening";
import TermsAndConditions from "./TermsAndConditions";
import Integrations from "./Integrations";
import TimeZone from "./TimeZone";
import { useTranslation } from "react-i18next";
import { useCompanyService } from "../../services/useCompanyService";
import { useEffect } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useLocalStore } from "../../hooks/useLocalStore";
import { Form, Formik } from "formik";
import { isURLFormat } from "../../common";
import { useDispatch } from "react-redux";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";
import { grey } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import { findTimeZone, getUTCOffset } from "timezone-support";
import { isEmpty } from "../../common";
import { ROLES } from "../../constants";
import CustomBookingShifts from "./CustomBookingShifts";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  basicInfo: {
    width: "100%",
    marginTop: theme.spacing(5)
  },
  visualCustomization: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  contactInfo: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  emailInfo: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  generalInfo: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  updateButton: {
    margin: theme.spacing(3, 0)
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function CompanyInfo() {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const localStorage = useLocalStorage();
  const localStore = useLocalStore();
  const companyService = useCompanyService();
  const [createMode, setCreateMode] = useState(false);
  const [doLocationsExist, setDoLocationsExist] = useState(false);
  const [company, setCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [usersDropdownList, setUsersDropdownList] = useState([]);
  const [preferencesUtil, setPrefencesUtil] = useState(null);

  useEffect(() => {
    const loadUserList = async () => {
      const _userList =
        await companyService.getUsersFromDropdownListCollection();
      setUsersDropdownList(_userList);
    };
    isEmpty(usersDropdownList) && loadUserList();
  }, []);

  useEffect(() => {
    loadCompany();
  }, []);

  const loadCompany = async () => {
    setIsLoading(true);
    let company;

    if (localStorage.getCreateCompanyName()) {
      setCreateMode(true);
      company = {
        companyName: localStorage.getCreateCompanyName(),
        companyId: localStorage.getCompanyID(),
        contactName: "",
        businessAddress: "",
        coordinateSystem: [],
        emailDomains: [],
        emails: [],
        healthScreeningMailingList: [],
        generalInfoUrl: "",
        themeData: {
          ...colourDefaults
        },
        preferences: {
          enableDropIn: false,
          showOccupancy: false,
          hiveAccessPolicy: {
            showAccessPolicyField: false,
            accessLevel: "allowAll",
            message: {
              en: "",
              fr: ""
            },
            sendAccessNotification: false
          },
          hiveSyncService: {
            enableCalendarSync: false,
            providers: {}
          }
        },
        locationMaps: {},
        timeZone: null
      };
    } else {
      // ONLY run these for company which is NOT in create mode
      company = await companyService.getCompany();
      setDoLocationsExist((await companyService.getLocations("")).length > 0);

      const requireApprovalGroup =
        await companyService.getRequireApprovalGroups();
      const otherUserGroups = await companyService.getUserGroups();

      isEmpty(userGroups) &&
        setUserGroups([...requireApprovalGroup, ...otherUserGroups]);

    }

    setCompany(company);
    setPrefencesUtil(localStore.getCompanyPreferencesUtil());
    setIsLoading(false);
  };

  const isRootOrAdmin = () => {
    return (
      localStorage.getUserRoles().includes(ROLES.ROOT) ||
      localStorage.getUserRoles().includes(ROLES.IT_ADMIN)
    );
  };

  const validate = async () => {
    const errors = {};

    // Validate info URL
    if (company.generalInfoUrl && !isURLFormat(company.generalInfoUrl)) {
      errors.generalInfoUrl = t("Errors.InvalidURL");
    }

    if (isEmpty(company.timeZone)) {
      errors.timezone = t("Errors.MissingTimezone");
    }

    return errors;
  };

  const setCompanyField = (field, value) => {
    const _company = { ...company };
    _company[field] = value;
    setCompany(_company);
  };

  const save = async () => {
    setButtonLoading(true);
    if (createMode) {
      await companyService.createCompany(company);
      setCreateMode(false);
      localStorage.removeCompanyName();
      history.push("/");
    } else {
      const result = await companyService.updateCompany(company);

      if (result.data.success) {
        dispatch(setSuccessMessage(t("Success.UpdateCompany")));

        // create a requireApproval group only if these preferences are true
        if (preferencesUtil.hasAccessLevel("requireApproval")) {
          // create and add new group if does not exist
          let result = await companyService.getRequireApprovalGroups();
          if (result.length === 0) {
            const newUserGroup = {
              name: "REQUIRE APPROVAL",
              admins: [],
              isAccessPolicyGroup: true
            };
            const response = await companyService.createUserGroup(newUserGroup);
            newUserGroup.userGroupId = response.data.userGroupId;
            await companyService.populateRequireApprovalGroup();
          }
        } else {
          // remove the requireApproval group when access preferences are not correct

          let result = await companyService.getRequireApprovalGroups();
          if (result.length > 0) {
            await companyService.removeUserGroup(result[0].userGroupId);
          }
        }
      } else {
        const path = "Errors.Cloud." + result.data.message;
        const error = i18n.exists(path) ? t(path) : t("Errors.UpdateCompany");
        dispatch(setErrorMessage(error));
      }
    }
    setButtonLoading(false);
  };

  function setTimeZoneOffSet(event, value) {
    if (isEmpty(value)) {
      return;
    }

    const dateObject = getUTCOffset(new Date(), findTimeZone(value));
    dateObject.region = value;
    setCompanyField("timeZone", dateObject);
  }

  if (!isLoading) {
    return (
      <div className="content">
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h3">
            {createMode
              ? t("CompanyInfo.CompanyRegistration")
              : t("CompanyInfo.CompanyInformation")}
          </Typography>
          {company && (
            <Formik
              initialValues={company}
              validate={validate}
              onSubmit={(values, actions) => {
                save().finally(() => {
                  actions.setSubmitting(false);
                });
              }}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {(formProps) => (
                <Form>
                  <BasicInfo
                    className={classes.basicInfo}
                    company={company}
                    setCompanyField={setCompanyField}
                    createMode={createMode}
                    doLocationsExist={doLocationsExist}
                  />
                  {!createMode && (
                    <CompanyLogo
                      company={company}
                      className={classes.visualCustomization}
                    />
                  )}
                  <VisualCustomization
                    company={company}
                    className={classes.visualCustomization}
                  />
                  <ContactInfo
                    className={classes.contactInfo}
                    company={company}
                    setCompanyField={setCompanyField}
                  />
                  {!createMode && <HealthScreening
                    className={classes.generalInfo}
                    company={company}
                    setCompanyField={setCompanyField}
                    companyService={companyService}
                    userGroups={userGroups}
                    usersList={usersDropdownList}
                  />}
                  <GeneralInfo
                    className={classes.generalInfo}
                    company={company}
                    setCompanyField={setCompanyField}
                    formikProps={formProps}
                  />
                  <TermsAndConditions
                    className={classes.generalInfo}
                    company={company}
                    setCompanyField={setCompanyField}
                  />
                  {isRootOrAdmin() && (
                    <Integrations
                      className={classes.generalInfo}
                      company={company}
                      usersList={usersDropdownList}
                      setCompanyField={setCompanyField}
                    />
                  )}
                  <Preferences
                    className={classes.generalInfo}
                    company={company}
                    setCompanyField={setCompanyField}
                  />
                  <CustomBookingShifts
                    className={classes.generalInfo}
                    company={company}
                    setCompanyField={setCompanyField}
                  />
                  <TimeZone
                    className={classes.generalInfo}
                    company={company}
                    setTimeZoneField={setTimeZoneOffSet}
                    formikProps={formProps}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.updateButton}
                    disabled={buttonLoading}
                  >
                    {createMode ? t("Commons.Save") : t("Commons.Update")}
                    {buttonLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </Container>
      </div>
    );
  } else {
    return <LinearProgress />;
  }
}
