import { stringToBoolean, isEmpty } from "../common";

export const useLocalStorage = () => {
  return {
    setUserID: (userId) => {
      sessionStorage.setItem("user_id", userId);
    },

    getUserID: () => {
      return sessionStorage.getItem("user_id");
    },

    setUserEmail: (userEmail) => {
      sessionStorage.setItem("user_email", userEmail);
    },

    getUserEmail: () => {
      return sessionStorage.getItem("user_email");
    },

    setCompanyID: (companyId) => {
      sessionStorage.setItem("company_id", companyId);
    },

    getCompanyID: () => {
      return sessionStorage.getItem("company_id");
    },

    setHiveRegion: (hiveRegion) => {
      sessionStorage.setItem("hive_region", hiveRegion);
    },

    getHiveRegion: () => {
      return sessionStorage.getItem("hive_region");
    },

    setEmailVerified: (verified) => {
      sessionStorage.setItem("email_verified", verified);
    },

    getEmailVerified: () => {
      return stringToBoolean(sessionStorage.getItem("email_verified"));
    },

    setCreateCompanyName: (createCompanyName) => {
      sessionStorage.setItem("create_company_name", createCompanyName);
    },

    getCreateCompanyName: () => {
      return sessionStorage.getItem("create_company_name");
    },

    setUserRoles: (userRoles) => {
      sessionStorage.setItem("user_roles", userRoles);
    },

    getUserRoles: () => {
      const roles = sessionStorage.getItem("user_roles");
      return roles ? roles.split(",") : [];
    },

    removeCompanyName: () => {
      sessionStorage.removeItem("create_company_name");
    },

    clear: () => {
      sessionStorage.clear();
    },

    setHiveApiServiceKey: (hiveApiServiceKey) => {
      sessionStorage.setItem("hive_api_service_key", hiveApiServiceKey);
    },

    getHiveApiServiceKey: () => {
      return sessionStorage.getItem("hive_api_service_key");
    },

    setMaximumOccupancyEnabled: (maximumOccupancyEnabled) => {
      sessionStorage.setItem(
        "maximum_occupancy_enabled",
        maximumOccupancyEnabled
      );
    },

    getMaximumOccupancyEnabled: () => {
      if (!isEmpty(sessionStorage.getItem("maximum_occupancy_enabled"))) {
        return (
          sessionStorage.getItem("maximum_occupancy_enabled").toLowerCase() ===
          "true"
        );
      } else {
        return false;
      }
    },

    setTemplateMatchingEnabled: (templateMatchingEnabled) => {
      sessionStorage.setItem(
        "template_matching_enabled",
        templateMatchingEnabled
      );
    },

    getTemplateMatchingEnabled: () => {
      if (!isEmpty(sessionStorage.getItem("template_matching_enabled"))) {
        return (
          sessionStorage.getItem("template_matching_enabled").toLowerCase() ===
          "true"
        );
      } else {
        return false;
      }
    },

    isHiveApiEnabled: () => {
      return sessionStorage.getItem("hive_api_service_key") !== null;
    },

    setHiveIamServiceKey: (hiveIamServiceKey) => {
      sessionStorage.setItem("hive_iam_service_key", hiveIamServiceKey);
    },

    getHiveIamServiceKey: () => {
      return sessionStorage.getItem("hive_iam_service_key");
    },

    setHiveSyncServiceKey: (hiveSyncServiceKey) => {
      sessionStorage.setItem("hive_sync_service_key", hiveSyncServiceKey);
    },

    getHiveSyncServiceKey: () => {
      return sessionStorage.getItem("hive_sync_service_key");
    }
  };
};
