const gaxios = require('gaxios');

export class useHttpService {
  constructor(baseUrl, headers) {
    gaxios.instance.defaults = {
      baseURL: baseUrl,
      headers: headers,
      timeout: 600000
    };
  }

  async doGet(url) {
    try {
      const results = await gaxios.request({ method: "get", url: url });
      return results.data;
    } catch (error) {
      throw new Error(`External Service Error: ${error.message}`);
    }
  }

  async doPost(url, body) {
    try {
      const results = await gaxios.request({ method: "post", url: url, data: body });
      return results.data;
    } catch (error) {
      throw new Error(`External Service Error: ${error.message}`);
    }
  }
}