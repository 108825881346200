import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

import { KeyboardDatePicker } from "@material-ui/pickers";
import LocationSelector from "../../components/Selectors/LocationSelector";
import UserSelector from "../../components/Selectors/UserSelector";
import UserGroupSelector from "../../components/Selectors/UserGroupSelector";
import StatusSelector from "../../components/Selectors/StatusSelector";

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: "20px 0"
  },
  requestParams: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1rem"
  },
  filterItem: {
    flex: "1",
    alignSelf: "center"
  },
  filterItemHeavy: {
    flex: "2",
    alignSelf: "center"
  },
  bottomRow: {
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between"
  }
}));

const ReportFilters = ({
  enableDatePicker = false,
  startingDate,
  endingDate,
  setStartingDate,
  setEndingDate,
  enableLocationFilter = false,
  locationsList,
  company,
  locationController,
  enableUserFilter = false,
  usersList,
  userController,
  enableUserGroupFilter = false,
  groupController,
  enableStatusFilter = false,
  statusController,
  states,
  removeStates
}) => {
  const classes = useStyles();
  const [ t ] = useTranslation();

  return (
    <>
      <div
        className={classnames({
          [classes.requestParams]: true,
          [classes.wrapper]: true
        })}
      >
        {enableDatePicker && (
          <KeyboardDatePicker
            className={classes.filterItem}
            disableToolbar
            inputVariant="outlined"
            format="MM/dd/yyyy"
            label={t("Commons.StartDate")}
            name="startingBookingDate"
            maxDate={endingDate}
            value={startingDate}
            onChange={(date) => {
              setStartingDate(date);
            }}
          />
        )}
        {enableDatePicker && (
          <KeyboardDatePicker
            className={classes.filterItem}
            disableToolbar
            inputVariant="outlined"
            format="MM/dd/yyyy"
            label={t("Commons.EndDate")}
            name="endingBookingDate"
            minDate={startingDate}
            value={endingDate}
            onChange={(date) => {
              setEndingDate(date);
            }}
          />
        )}
        {enableLocationFilter && (
          <div className={classes.filterItemHeavy}>
            <LocationSelector
              contentList={locationsList}
              company={company}
              labelKey={"Commons.Location"}
              controller={locationController}
            />
          </div>
        )}
      </div>
      <div className={classes.bottomRow}>
        {enableUserFilter && (
          <div className={classes.filterItem}>
            <UserSelector
              contentList={usersList}
              labelKey={"Commons.User"}
              controller={userController}
            />
          </div>
        )}
        {enableUserGroupFilter && (
          <div className={classes.filterItem}>
            <UserGroupSelector
              labelKey={t("UserGroups.UserGroup")}
              controller={groupController}
              includeAccessPolicyGroup={true}
            />
          </div>
        )}
        {enableStatusFilter && (
          <div className={classes.filterItemHeavy}>
            <StatusSelector
              style={{
                maxHeight: "53px"
              }}
              controller={statusController}
              statusObj={states}
              remove={removeStates}
            />
          </div>
        )}
      </div>
    </>
  );
};

const checkRequired = (isRequired, isCorrectType, isUndefined, errorMessage) =>
  isRequired && (!isCorrectType || isUndefined) && new Error(errorMessage);

ReportFilters.propTypes = {
  enableDatePicker: PropTypes.bool,
  startingDate: (props, propName) => {
    return checkRequired(
      props["enableDatePicker"],
      props[propName] instanceof Date,
      props[propName] === undefined || null,
      "startingDate prop requires a Date object when enableDatePicker is true"
    );
  },
  endingDate: (props, propName) => {
    return checkRequired(
      props["enableDatePicker"],
      props[propName] instanceof Date,
      props[propName] == undefined || null,
      "endingDate prop requires a Date object when enableDatePicker is true"
    );
  },
  setStartingDate: (props, propName) => {
    return checkRequired(
      props["enableDatePicker"],
      typeof props[propName] === "function",
      props[propName] === undefined || null,
      "A setter for startingDate is required when enableDatePicker is true"
    );
  },
  setEndingDate: (props, propName) => {
    return checkRequired(
      props["enableDatePicker"],
      typeof props[propName] === "function",
      props[propName] === undefined || null,
      "A setter for endingDate is required when enableDatePicker is true"
    );
  },
  enableLocationFilter: PropTypes.bool,
  locationsList: (props, propName) => {
    return checkRequired(
      props["enableLocationFilter"],
      Array.isArray(props[propName]),
      props[propName] === undefined || null,
      "locationsList requires an array when enableLocationFilter is true"
    );
  },
  company: (props, propName) => {
    return checkRequired(
      props["enableLocationFilter"],
      true,
      props[propName] === undefined || null,
      "company requires a Company object when enableLocationFilter is true"
    );
  },
  locationController: (props, propName) => {
    return checkRequired(
      props["enableLocationFilter"],
      true,
      props[propName] === undefined || null,
      "locationController requires an object in the form of { value, setter } when enableLocationFilter is true"
    );
  },
  enableUserFilter: PropTypes.bool,
  usersList: (props, propName) => {
    return checkRequired(
      props["enableUserFilter"],
      Array.isArray(props[propName]),
      props[propName] === undefined || null,
      "usersList requires an array when enableUserFilter is true"
    );
  },
  userController: (props, propName) => {
    return checkRequired(
      props["enableUserFilter"],
      true,
      props[propName] === undefined || null,
      "userController requires an object in the form of { value, setter } when enableUserFilter is true"
    );
  },
  enableUserGroupFilter: PropTypes.bool,
  groupController: (props, propName) => {
    return checkRequired(
      props["enableUserGroupFilter"],
      true,
      props[propName] === undefined || null,
      "groupController requires an object in the form of { value, setter } when enableUserGroupFilter is true"
    );
  },
  enableStatusFilter: PropTypes.bool,
  statusController: (props, propName) => {
    return checkRequired(
      props["enableStatusFilter"],
      true,
      props[propName] === undefined || null,
      "statusController requires an object in the form of { value, setter } when enableStatusFilter is true"
    );
  },
  states: (props, propName) => {
    return checkRequired(
      props["enableStatusFilter"],
      typeof props[propName] === "object",
      props[propName] === undefined || null,
      "usersList requires an array when enableUserFilter is true"
    );
  },
  removeStates: (props, propName) => {
    return checkRequired(
      props["enableStatusFilter"],
      Array.isArray(props[propName]),
      props[propName] === undefined || null,
      "removeStates requires an array when enableStatusFilter is true"
    );
  }
};

export default ReportFilters;
