import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useLocalStore } from "../../hooks/useLocalStore";
import {
  Container,
  Typography,
  IconButton,
  Divider,
  Card,
  Link,
  Breadcrumbs,
  colors,
  Chip,
  LinearProgress,
  Tooltip,
  Button
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import InfoIcon from "@material-ui/icons/Info";
import CopyIcon from "@material-ui/icons/FileCopyRounded";
import PersonIcon from "@material-ui/icons/Person";
import ArrowBack from "@material-ui/icons/ArrowBack";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HomeIcon from "@material-ui/icons/Home";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import {
  isTrue,
  isNullOrUndefined,
  getMultiLanguageContent,
  isEmpty
} from "../../common";
import { EditLocation } from "./EditLocation";
import { DeleteLocation } from "./DeleteLocation";
import { useCompanyService } from "../../services/useCompanyService";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../actions";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { LocationMapEditor } from "../LocationMapPlus/LocationMapEditor";
import { ROLES } from "../../constants";
import LocationTagsEditor from "./LocationTagsEditor";
import LocationTag from "./LocationTag";
import { EditLocationUser } from "./EditLocationUser";
import { EditReservation } from "./EditReservation";
import { Schedule } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  card: {
    height: "70vh",
    marginTop: theme.spacing(5),
    padding: "16px"
  },
  breadcrumbNav: {
    backgroundColor: colors.grey[50],
    padding: theme.spacing(2)
  },
  breadcrumbLink: {
    display: "flex",
    alignItems: "center"
  },
  breadcrumbIcon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  infoIcon: {
    fontSize: "inherit",
    marginRight: theme.spacing(0.5),
    verticalAlign: "text-bottom"
  },
  panelHeader: {
    backgroundColor: colors.grey[50]
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0
  },
  chipsWrapper: {
    listStyle: "none",
    display: "flex"
  },
  chipListItem: {
    marginRight: "5px"
  },
  scrollContainer: {
    overflowY: "auto",
    height: "calc(100% - 6.4em)"
  },
  cursor: {
    cursor: "pointer"
  },
  nonBookableMark: {
    marginLeft: "5px"
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tableRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& .rowCell:nth-of-type(1)": {
      width: "5%"
    },
    "& .rowCell:nth-of-type(2), .rowCell:nth-of-type(5), .rowCell:nth-of-type(6)":
      {
        width: "10%"
      },
    "& .rowCell:nth-of-type(3), .rowCell:nth-of-type(4)": {
      width: "25%"
    },
    "& .rowCell:nth-of-type(7)": {
      width: "15%"
    }
  },
  mouseOver: {
    borderRadius: "3px",
    paddingLeft: "3px",
    paddingRight: "3px",
    "&:hover": {
      background: theme.palette.primary.main
    }
  }
}));

export const Locations = () => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyService = useCompanyService();
  const localStorage = useLocalStorage();
  const localStore = useLocalStore();

  const [locations, setLocations] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationTrail, setLocationTrail] = useState([]);
  const [fullLocationTrail, setFullLocationTrail] = useState(["root"]);
  const [coordinateIndex, setCoordinateIndex] = useState(0);
  const [editLocation, setEditLocation] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [deleteLocationInfo, setDeleteLocationInfo] = useState(null);
  const [users, setUsers] = useState(null);
  const [userGroups, setUserGroups] = useState(null);
  const [reservationMap, setReservationMap] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [locationTags, setLocationTags] = useState(null);
  const [editReservation, setEditReservation] = useState(null);

  const company = localStore.getCompany();
  const currentUser = localStore.getCurrentUser();
  const coordinates = company.coordinateSystem;

  useEffect(() => {
    setIsLoading(true);
    loadLocations("");
    loadUsers();
    loadUserGroups();
    loadLocationTags();
    loadReservations();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (currentLocation) {
      setFullLocationTrail(["root", ...locationTrail, currentLocation]);
    } else {
      setFullLocationTrail(["root"]);
    }
  }, [locationTrail, currentLocation]);

  const loadUsers = async () => {
    const users = await companyService.getUsers();
    setUsers(users);
  };

  const isRootOrAdmin = () => {
    return (
      localStorage.getUserRoles().includes(ROLES.ROOT) ||
      localStorage.getUserRoles().includes(ROLES.ADMIN)
    );
  };

  const loadUserGroups = async () => {
    const userGroups = await companyService.getUserGroups();
    setUserGroups(userGroups);
  };

  const loadLocationTags = async () => {
    const locationTags = await companyService.getAllLocationTags();
    setLocationTags(locationTags);
  };

  const loadLocations = async (parentLocationId) => {
    const _locations = await companyService.getLocations(parentLocationId);
    setLocations(_locations);
  };

  const loadReservations = async () => {
    const _reservationMap = await companyService.getAllReservationsByItemType("Locations");
    setReservationMap(_reservationMap);
  };

  const selectLocation = (location) => {
    if (isTrue(location.atBottomLevel)) {
      return;
    }

    setLocations(null);
    loadLocations(location.locationId);

    if (currentLocation) {
      setLocationTrail([...locationTrail, currentLocation]);
    }

    setCurrentLocation(location);
    setCoordinateIndex(coordinateIndex + 1);
  };

  const jump = (index) => {
    setLocations(null);
    setCoordinateIndex(index);

    if (index === 0) {
      setLocationTrail([]);
      setCurrentLocation(null);
      loadLocations("");
      return;
    }

    const location = fullLocationTrail[index];
    const _locationTrail = locationTrail.slice(0, index - 1);
    setLocationTrail(_locationTrail);

    setCurrentLocation(location);
    loadLocations(location.locationId);
  };

  const back = () => {
    setLocations(null);
    setCoordinateIndex(coordinateIndex - 1);

    if (locationTrail.length === 0) {
      setCurrentLocation(null);
      loadLocations("");
      return;
    }

    let _locationTrail = [...locationTrail];
    const location = _locationTrail.pop();
    setLocationTrail(_locationTrail);

    setCurrentLocation(location);
    loadLocations(location.locationId);
  };

  const add = () => {
    setEditLocation({
      createMode: true,
      location: {
        locationParent: currentLocation,
        locationType: coordinates[coordinateIndex],
        locationTrail: locationTrail,
        bookable: true,
        atBottomLevel: coordinateIndex === coordinates.length - 1,
        timeBookable: false,
        tags: []
      }
    });
  };

  const edit = (location, index) => {
    const isMeetingRoom = location.isMeetingRoom;
    if (isNullOrUndefined(isMeetingRoom)) {
      location.isMeetingRoom = false;
    }

    setEditLocation({
      createMode: false,
      location: location,
      index: index
    });
  };

  const closeEditLocation = async (location) => {
    if (location) {
      let _locations = [...locations];

      if (editLocation.createMode) {
        let newLocation = await companyService.getLocation(location.locationId);
        _locations.push(newLocation);
      } else {
        _locations[editLocation.index] = location;
      }
      setLocations(_locations);
    }

    setEditLocation(null);
  };

  const closeEditLocationUser = (location) => {
    if (location) {
      let _locations = [...locations];

      _locations[editUser.index] = location;
      setLocations(_locations);
    }
    setEditUser(null);
  };

  const remove = async (location, index) => {
    const assignedUsers = 
      await companyService.getUsersWithIds(location.assignedLocationFor);
    const preferredUsers = 
      await companyService.getUsersWithIds(location.preferredLocationFor);

    setDeleteLocationInfo({
      location: location,
      index: index,
      assignedUsers: assignedUsers,
      preferredUsers: preferredUsers
    });
  };

  const openEditReservation = (location, group, reservation) => {
    setEditReservation({
      location: location,
      group: group,
      reservation: reservation
    });
  };

  const closeEditReservation = (mode, reservation) => {
    if (mode && reservation) {
      const _reservationMap = {...reservationMap};
      const resIndex = _reservationMap[reservation.itemToBeReserved.id]?.findIndex((res) => res.owner.id === reservation.owner.id);

      switch(mode) {
        case 0: {
          _reservationMap[reservation.itemToBeReserved.id][resIndex] = reservation;
          break;
        }
        case 1: {
          _reservationMap[reservation.itemToBeReserved.id].splice(resIndex, 1);
          break;
        }
        case 2: {
          if (_reservationMap[reservation.itemToBeReserved.id]) {
            _reservationMap[reservation.itemToBeReserved.id].push(reservation);
          } else {
            _reservationMap[reservation.itemToBeReserved.id] = [reservation];
          }
        }
      }
    
      setReservationMap(_reservationMap);
    }
    
    setEditReservation(null);
  };

  const updateLocationsDropdownList = () => {
    return companyService.updateLocationsDropdownList();
  };

  const confirmRemove = async (setErrorMessage) => {
    const response = await companyService.removeLocation(
      deleteLocationInfo.location
    );

    if (!response.data.success) {
      const path = "Errors.Cloud." + response.data.message;
      const error = i18n.exists(path) ? t(path) : t("Errors.DeleteLocation");
      setErrorMessage(error);
      setDeleteLocationInfo(null);
    } else {
      setDeleteLocationInfo(null);
      let _locations = [...locations];
      _locations.splice(deleteLocationInfo.index, 1);
      setLocations(_locations);
      updateLocationsDropdownList();
    }
  };

  const findReservationForGroup = (locationId, groupId) => {
    return reservationMap[locationId]?.find((res) => res.owner.id === groupId);
  };  

  function getUserGroupsByLocation(location, userGroups) {
    if (location.groups) {
      return location.groups.map((group, index) => {
        const groupId = userGroups.find(
          (uGroup) => uGroup.userGroupId === group
        );
        const reservation = findReservationForGroup(location.locationId, group);
        return (
          <Chip
            color={reservation ? "primary" : ""}
            icon={reservation ? <Schedule /> : null}
            key={`${groupId.name}_${index}`}
            label={groupId.name}
            className={classes.chip}
            style={{ margin: "0.2rem" }}
            onClick={() => openEditReservation(location, groupId, reservation ? reservation : null)}
          />
        );
      });
    }
  }

  function getTagsByLocation(location, locationTags) {
    if (typeof location.tags === "undefined") {
      location.tags = [];
    }
    if (!isEmpty(location.tags)) {
      return location.tags.map((tag, index) => {
        const tagId = locationTags.find((lTag) => lTag.id === tag);
        return (
          <LocationTag
            key={`tag${index}`}
            label={getMultiLanguageContent(tagId.displayName)}
            color={tagId.color}
            style={{ margin: "0.2rem" }}
          />
        );
      });
    }
  }

  const isLocationActionDisabled = (location) => {
    if (localStorage.getUserRoles().includes(ROLES.ADMIN)) {
      return false;
    }

    return currentUser && currentUser.email ? 
      !(location.admins && location.admins.includes(currentUser.email)) &&
      !isAncestorLocationAdmin() : true;
  };

  const canAddLocation = () => {
    return (
      localStorage.getUserRoles().includes(ROLES.ADMIN) ||
      isAncestorLocationAdmin()
    );
  };

  const isAncestorLocationAdmin = () => {
    return currentUser && currentUser.email ? 
      fullLocationTrail.some((ancestorLocation) =>
        ancestorLocation.admins && ancestorLocation.admins.includes(currentUser.email)
      ) : false;
  };

  const [openTagsEditor, setOpenTagsEditor] = useState(false);

  const RowCell = ({ children, style }) => (
    <div className="rowCell" style={style}>
      {children && children}
    </div>
  );

  const RowCellWithAction = ({ children, action }) => (
    <div className={`rowCell ${classes.mouseOver}`} onClick={action}>
      {children && children}
    </div>
  );

  const chipContainerStyle = {
    display: "flex",
    gap: "0.5rem",
    overflowY: "auto"
  };

  const tableButtonsStyle = { display: "flex", flexWrap: "wrap" };

  if (!isLoading) {
    return (
      <div className="content">
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.headingContainer}>
            <Typography variant="h3">{t("Locations.Locations")}</Typography>
            <Button color="primary" onClick={() => setOpenTagsEditor(true)}>
              {t("Locations.EditLocationTags")}
            </Button>
          </div>
          {locations && coordinates && (
            <>
              <Card className={classes.card}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  className={classes.breadcrumbNav}
                >
                  {fullLocationTrail.map((item, index) => {
                    if (index === 0) {
                      return (
                        <Link
                          component="button"
                          variant="body2"
                          color="textSecondary"
                          className={classes.breadcrumbLink}
                          onClick={() => {
                            jump(index);
                          }}
                          key={`link${index}`}
                        >
                          <HomeIcon className={classes.breadcrumbIcon} />
                          {t("Locations.Company")}
                        </Link>
                      );
                    } else {
                      return (
                        <Link
                          component="button"
                          variant="body2"
                          color="textSecondary"
                          className={classes.breadcrumbLink}
                          key={`link${index}`}
                          onClick={() => {
                            jump(index);
                          }}
                        >
                          {item.displayName}
                        </Link>
                      );
                    }
                  })}
                </Breadcrumbs>
                <Divider />
                <div
                  className={classes.tableRow}
                  style={{ backgroundColor: colors.grey[50] }}
                >
                  <RowCell>
                    <IconButton
                      onClick={back}
                      disabled={isNullOrUndefined(currentLocation)}
                    >
                      <ArrowBack />
                    </IconButton>
                  </RowCell>
                  <RowCell>
                    <Typography>{coordinates[coordinateIndex]}</Typography>
                  </RowCell>
                  <RowCell>
                    {fullLocationTrail.length < 3 && (
                      <Typography>{t("Locations.UserGroups")}</Typography>
                    )}
                  </RowCell>
                  <RowCell>
                    {fullLocationTrail.length < 3 && (
                      <Typography>{t("Locations.Tags")}</Typography>
                    )}
                  </RowCell>
                  <RowCell>
                    <Tooltip
                      title={t("Locations.CapacityTooltip")}
                      placement="top-start"
                    >
                      <div>
                        <InfoIcon className={classes.infoIcon} />
                        <Typography display="inline">
                          {t("Locations.Capacity")}
                        </Typography>
                      </div>
                    </Tooltip>
                  </RowCell>
                  <RowCell>
                    <Tooltip
                      title={t("Locations.ChildrenBookableLocation")}
                      placement="top-start"
                    >
                      <div>
                        <InfoIcon className={classes.infoIcon} />
                        <Typography display="inline">
                          {t("Locations.Bookable")}
                        </Typography>
                      </div>
                    </Tooltip>
                  </RowCell>
                  <RowCell>
                    {canAddLocation() && (
                      <Button onClick={add} variant="text">
                        <Add />{" "}
                        <Typography component="span" variant="body2">
                          {t("Commons.Add")} {coordinates[coordinateIndex]}
                        </Typography>
                      </Button>
                    )}
                  </RowCell>
                </div>
                <Divider />
                <div
                  style={{ height: "calc(70vh - 7.5rem)", overflow: "auto" }}
                >
                  {locations.map((location, index) => (
                    <div key={`loc${index}`}>
                      <div
                        className={classes.tableRow}
                        style={{ cursor: "pointer" }}
                      >
                        <RowCell />
                        <RowCellWithAction
                          action={() => selectLocation(location)}
                        >
                          <Typography>{location.displayName}</Typography>
                        </RowCellWithAction>
                        <RowCell style={chipContainerStyle}>
                          {userGroups && (
                            <div>
                              {getUserGroupsByLocation(location, userGroups)}
                            </div>
                          )}
                        </RowCell>
                        <RowCell style={chipContainerStyle}>
                          {locationTags && (
                            <div>
                              {getTagsByLocation(location, locationTags)}
                            </div>
                          )}
                        </RowCell>
                        <RowCell>
                          {coordinateIndex !== coordinates.length - 1 && (
                            <Typography>
                              {location.safetyCapacity
                                ? location.safetyCapacity
                                : "---"}{" "}
                              /{" "}
                              {location.maxBookingCount
                                ? location.maxBookingCount
                                : "---"}
                            </Typography>
                          )}
                        </RowCell>
                        <RowCell>
                          {isTrue(location.bookable) ? (
                            <CheckIcon />
                          ) : (
                            <Typography className={classes.nonBookableMark}>
                              ---
                            </Typography>
                          )}
                        </RowCell>
                        <RowCell style={tableButtonsStyle}>
                          <IconButton
                            disabled={isLocationActionDisabled(location)}
                            onClick={() => edit(location, index)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            disabled={isLocationActionDisabled(location)}
                            onClick={() => remove(location, index)}
                          >
                            <Delete />
                          </IconButton>
                          <IconButton
                            disabled={isLocationActionDisabled(location) || !location.bookable}
                            onClick={() => setEditUser({ location: location, index: index })}
                          >
                            <PersonIcon />
                          </IconButton>
                          {localStorage.getUserRoles().includes(ROLES.ROOT) && (
                            <IconButton>
                              <CopyToClipboard
                                onCopy={() => {
                                  dispatch(
                                    setSuccessMessage(t("Success.Clipboard"))
                                  );
                                }}
                                text={location.locationId}
                              >
                                <CopyIcon />
                              </CopyToClipboard>
                            </IconButton>
                          )}
                        </RowCell>
                        <hr className="MuiDivider-root" />
                      </div>
                      <hr className="MuiDivider-root" />
                    </div>
                  ))}
                </div>
              </Card>
              {isRootOrAdmin() && (
                <LocationMapEditor
                  targetLocation={currentLocation}
                  locationTrail={locationTrail}
                  seats={locations}
                  setSeats={setLocations}
                  reloadLocations={loadLocations}
                  users={users}
                />
              )}
            </>
          )}
        </Container>
        {editLocation && (
          <EditLocation
            createMode={editLocation.createMode}
            location={editLocation.location}
            users={users}
            userGroups={userGroups}
            close={closeEditLocation}
            company={company}
            parentLocation={currentLocation}
            reloadLocations={loadLocations}
          />
        )}
        {deleteLocationInfo && (
          <DeleteLocation
            locationName={deleteLocationInfo.location.displayName}
            locationType={deleteLocationInfo.location.locationType}
            assignedUsers={deleteLocationInfo.assignedUsers}
            preferredUsers={deleteLocationInfo.preferredUsers}
            confirm={confirmRemove}
            cancel={() => setDeleteLocationInfo(null)}
          />
        )}
        {editUser && (
          <EditLocationUser
            location={editUser.location}
            locationTrail={[...locationTrail, currentLocation]}
            users={users}
            close={closeEditLocationUser}
          />
        )}
        {openTagsEditor && (
          <LocationTagsEditor
            closeDialog={() => setOpenTagsEditor(false)}
            locationTags={locationTags}
            loadLocationTags={loadLocationTags}
          />
        )}
        {editReservation && (
          <EditReservation
            location={editReservation.location}
            userGroup={editReservation.group}
            existingReservation={editReservation.reservation}
            close={closeEditReservation}
          />
        )}
      </div>
    );
  } else {
    return <LinearProgress />;
  }
};
