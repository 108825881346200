import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocalStore } from "../../hooks/useLocalStore";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import { useCompanyService } from "../../services/useCompanyService";
import {
  setSuccessMessage,
  setErrorMessage,
  setWarningMessage
} from "../../actions/index";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { BOOKING_STATES } from "../../constants";
import { grey } from "@material-ui/core/colors";
import { isEmpty } from "../../common";
import { ExpandMore } from "@material-ui/icons";
import { CollectEmail } from "../Appointments/CollectEmail";
import {
  Button,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel
} from "@material-ui/core";
import classnames from "classnames";
import addDays from "date-fns/addDays";
import differenceInDays from "date-fns/differenceInDays";
import subDays from "date-fns/subDays";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import HealthScreeningModal from "../common/HealthScreeningModal";
import CompanyUtil from "../../utils/CompanyUtil";
import BookingsTableController from "./BookingsTableController";
import UserSelector from "../../components/Selectors/UserSelector";
import LocationSelector from "../../components/Selectors/LocationSelector";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%"
  },
  wrapper: {
    margin: "20px 0"
  },
  card: {
    marginBottom: "20px"
  },
  panelContent: {
    maxHeight: "60vh",
    overflow: "auto"
  },
  table: {
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiTableRow-root": {
      cursor: "pointer"
    }
  },
  requestParams: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1.5fr",
    columnGap: "20px",
    rowGap: "20px"
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  selected: {
    backgroundColor: "#efe8d0"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  bookingFormLabel: {
    flexGrow: 1,
    "&>span:nth-child(2)": {
      display: "inline-flex",
      flexGrow: 1
    }
  },
  bookingLabel: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1
  },
  name: {
    flex: 1
  },
  state: {
    flex: 1,
    textAlign: "end"
  },
  location: {
    flex: 1,
    textAlign: "center"
  },
  comment: {
    fontStyle: "italic"
  },
  details: {
    padding: "8px 3em 16px",
    "&>div": {
      flex: 1,
      "&:nth-of-type(2)": {
        textAlign: "center"
      },
      "&:last-of-type": {
        textAlign: "end"
      }
    }
  }
}));

export default function CurrentBookings({ company, usersList, locationsList }) {
  const classes = useStyles();
  const companyService = useCompanyService();
  const localStore = useLocalStore();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const [coordinates, setCoordinates] = useState(null);
  const [startingBookingDate, setStartingBookingDate] = useState(new Date());
  const [bookingLocation, setBookingLocation] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [bookingMap, setBookingMap] = useState({});
  const [meetingMap, setMeetingMap] = useState({});
  const [numMeetingBookings, setNumMeetingBookings] = useState(0);
  const [showQuestions, setShowQuestions] = useState(false);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [questionSetId, setQuestionSetId] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [loadingView, setLoadingView] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [checkingIn, setCheckingIn] = useState(false);
  const [selectedBookingIds, setSelectedBookingIds] = useState([]);
  const [verifyDelete, setVerifyDelete] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [endingBookingDate, setEndingBookingDate] = useState(
    addDays(new Date(), 7)
  );

  const tableEmpty = bookings.length === 0;

  const bookingsByDate = useMemo(() => {
    const dates = {};
    bookings.forEach((booking) => {
      const d = booking.date.toDate().toDateString();
      if (dates[d]) {
        dates[d].push(booking);
      } else {
        dates[d] = [booking];
      }
    });
    return dates;
  }, [bookings]);

  useEffect(() => {
    company && loadLocationData();
  }, [company]);

  useEffect(() => {
    setCurrentUser(localStore.getCurrentUser());
  }, []);

  const loadLocationData = async () => {
    const _coordinates = company.coordinateSystem;
    setCoordinates(_coordinates);
  };

  const getBookings = async (
    startingBookingDate,
    endingBookingDate,
    location,
    userId,
    status
  ) => {
    setLoadingView(true);
    const _bookings = await companyService.getBookings(
      startingBookingDate,
      endingBookingDate,
      location,
      userId,
      status
    );

    const meetings = await companyService.getMeetingsForAssociatedBookings(_bookings);
    const _meetingMap = {};
    for (const m of meetings) {
      _meetingMap[m.meetingId] = m;
    }
    setMeetingMap(_meetingMap);
    setBookings(_bookings);
    setLoadingView(false);

    const _bookingMap = {};
    for (const b of _bookings) {
      _bookingMap[b.bookingId] = b;
    }
    setBookingMap(_bookingMap);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  function filterBookingItem(filterBool) {
    return (itemsList, bookingId) => {
      return itemsList.filter((item) => {
        let flag = filterBool;
        if (item === bookingId) {
          flag = !flag;
        }
        return flag;
      });
    };
  }

  const filterForBookingItem = filterBookingItem(false);
  const filterOutBookingItem = filterBookingItem(true);

  function isBookingIdSelected(bookingId) {
    const list = filterForBookingItem(selectedBookingIds, bookingId);
    return selectedBookingIds.length !== 0 ? list.length > 0 : false;
  }

  function toggleBooking(e, bookingId) {
    e.stopPropagation();
    e.preventDefault();
    if (isBookingIdSelected(bookingId)) {
      bookingMap[bookingId].meetingId && setNumMeetingBookings(numMeetingBookings - 1);
      const _filteredBookingIds = filterOutBookingItem(
        selectedBookingIds,
        bookingId
      );
      setSelectedBookingIds([..._filteredBookingIds]);
    } else {
      bookingMap[bookingId].meetingId && setNumMeetingBookings(numMeetingBookings + 1);
      setSelectedBookingIds([...selectedBookingIds, bookingId]);
    }
  }

  function selectAllBookings() {
    setSelectedBookingIds(bookings.map((booking) => booking.bookingId));
  }

  function deSelectAllBookings() {
    setSelectedBookingIds([]);
  }

  const allBookingsSelected = selectedBookingIds.length === bookings.length;
  function toggleAllSelected() {
    if (allBookingsSelected) {
      deSelectAllBookings();
    } else {
      selectAllBookings();
    }
  }

  function doRefresh() {
    deSelectAllBookings();
    getBookings(
      startingBookingDate,
      endingBookingDate,
      bookingLocation ? bookingLocation : null,
      selectedUser ? selectedUser : null,
      selectedStatus
    );
  }

  function callOnSelectedBookings(ajaxMethod, messages) {
    return () => {
      const cancels = selectedBookingIds.map((bookingId) =>
        ajaxMethod(bookingId)
      );

      setVerifyDelete(false);

      Promise.all(cancels)
        .then((response) => {
          if (response[0].data.success) {
            dispatch(setSuccessMessage(messages.success));
            doRefresh();
          } else {
            dispatch(setErrorMessage(messages.error));
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          dispatch(setErrorMessage(messages.error));
        });
    };
  }

  function updateStartDate(date) {
    setStartingBookingDate(date);
    const difference = differenceInDays(endingBookingDate, date);
    if (difference > 62) {
      const baseDate = new Date(date.getTime());
      setEndingBookingDate(addDays(baseDate, 62));
    }
  }

  function updateEndDate(date) {
    setEndingBookingDate(date);
    const difference = differenceInDays(date, startingBookingDate);
    if (difference > 62) {
      const baseDate = new Date(date.getTime());
      setStartingBookingDate(subDays(baseDate, 62));
    }
  }

  const cancelBooking = () => {
    const promises = [];
    selectedBookingIds.forEach((bookingId) => {
      const associatedMeetingId = bookingMap[bookingId].meetingId;
      const bookingUserId = associatedMeetingId ? bookingMap[bookingId].userId : null;

      if(associatedMeetingId && meetingMap[associatedMeetingId].organizer.userId === bookingUserId) {
        promises.push(companyService.cancelMeeting(associatedMeetingId, bookingMap[bookingId].meetingExternalId));
      } else {
        promises.push(companyService.deleteBooking(bookingId));
      }
    });

    setVerifyDelete(false);

    Promise.all(promises)
      .then((response) => {
        if (response[0].data.success) {
          dispatch(setSuccessMessage(t("Success.DeleteBooking")));
          doRefresh();
        } else {
          dispatch(setErrorMessage(t("Errors.DeleteBooking")));
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        dispatch(setErrorMessage(t("Errors.DeleteBooking")));
      });
  };

  const getMeetingOrganizerName = (meeting) => {
    return (meeting ? meeting.organizer.firstName + " " + meeting.organizer.lastName : null);
  };

  const handleQuestionSubmit = async (answers, questionSetId) => {
    const booking = bookingMap[selectedBookingIds[0]];

    const isAppointment = false;
    const healthScreeningResponse = await companyService.submitHealthScreening(
      booking,
      questionSetId,
      answers,
      isAppointment
    );

    if (!healthScreeningResponse.data.pass) {
      const response = await companyService.sendHealthNotificationAdmin(
        bookingMap[selectedBookingIds[0]],
        company,
        questionSetId
      );
      handleQuestionnaireFailureResponse(response);
    } else {
      const response = await companyService.checkIntoBooking(
        selectedBookingIds[0],
        currentUser.email,
        questionSetId,
        true
      );
      handleResponse(response);
    }
    setShowQuestions(false);
  };

  const handleQuestionnaireFailureResponse = (response) => {
    setCheckingIn(false);
    if (response.data.success) {
      dispatch(setWarningMessage(t("Questionnaire.Notified")));
      doRefresh();
    } else {
      const path = "Errors.Cloud." + response.data.message;
      const error = i18n.exists(path)
        ? t(path)
        : t("Errors.SubmittingQuestionnaire");
      dispatch(setErrorMessage(error));
      console.log("error", response.data.message);
    }
  };

  const handleResponse = (response) => {
    setCheckingIn(false);
    if (response.data.success) {
      const path = "Success.Cloud." + response.data.message;
      const success = i18n.exists(path) ? t(path) : t("Success.General");
      dispatch(setSuccessMessage(success));
      doRefresh();
    } else {
      const path = "Errors.Cloud." + response.data.message;
      const error = i18n.exists(path) ? t(path) : t("Errors.General");
      dispatch(setErrorMessage(error));
      console.log("error", response.data.message);
    }
  };

  const CheckboxLabel = useCallback(
    (props) => {
      const booking = props.booking;
      return (
        <>
          <span className={classes.bookingLabel}>
            <Typography variant="h4" component="span" className={classes.name}>
              {booking.username}
              {booking.visitor && (
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.visitorName}
                >
                  &nbsp;(Visitor: {booking.visitor.firstName}&nbsp;
                  {booking.visitor.lastName})
                </Typography>
              )}
              {booking.comment && (
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.comment}
                >
                  {booking.comment}
                </Typography>
              )}
            </Typography>
            <Typography component="span" className={classes.location}>
              {CompanyUtil.getStringForCoords(company, booking.coordinate)}
            </Typography>
            <Typography variant="h6" component="span" className={classes.state}>
              {booking.state}
            </Typography>
          </span>
        </>
      );
    },
    [coordinates, classes]
  );

  const triggerExport = (email) => {
    setLoadingExport(true);
    companyService
      .exportOccupantReport(
        startingBookingDate,
        endingBookingDate,
        email,
        coordinates,
        bookingLocation ? bookingLocation : null,
        selectedUser ? selectedUser : null,
        selectedStatus
      )
      .then(() => {
        dispatch(setSuccessMessage(t("Success.EmailReport", { email: email })));
        setLoadingExport(false);
      });
  };

  const handleCancel = () => {
    setShowQuestions(false);
    setCheckingIn(false);
  };

  return (
    <div className="content">
      <div>
        <div
          className={classnames({
            [classes.requestParams]: true,
            [classes.wrapper]: true
          })}
        >
          <KeyboardDatePicker
            disableToolbar
            inputVariant="outlined"
            format="MM/dd/yyyy"
            label={t("Commons.StartDate")}
            name="startingBookingDate"
            maxDate={endingBookingDate}
            value={startingBookingDate}
            onChange={updateStartDate}
          />
          <KeyboardDatePicker
            disableToolbar
            inputVariant="outlined"
            format="MM/dd/yyyy"
            label={t("Commons.EndDate")}
            name="endingBookingDate"
            minDate={startingBookingDate}
            value={endingBookingDate}
            onChange={updateEndDate}
          />
          <LocationSelector
            contentList={locationsList}
            company={company}
            labelKey={"Commons.Location"}
            controller={{
              value: bookingLocation,
              setter: setBookingLocation
            }}
          />
          <UserSelector
            contentList={usersList}
            labelKey={"Commons.User"}
            controller={{
              value: selectedUser,
              setter: setSelectedUser
            }}
          />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="status-label">{t("Commons.Status")}</InputLabel>
            <Select
              labelId="status-label"
              value={selectedStatus}
              onChange={handleStatusChange}
              label="status"
              className={classes.filter}
              autoWidth={true}
            >
              <MenuItem value="ALL">{t("Commons.AllStatus")}</MenuItem>
              <MenuItem value={BOOKING_STATES.CONFIRMED}>
                {t("Commons.Confirmed")}
              </MenuItem>
              <MenuItem value={BOOKING_STATES.CANCELED}>
                {t("Commons.Canceled")}
              </MenuItem>
              <MenuItem value={BOOKING_STATES.CHECKED_IN}>
                {t("Commons.CheckedIn")}
              </MenuItem>
              <MenuItem value={BOOKING_STATES.CHECKED_OUT}>
                {t("Commons.CheckedOut")}
              </MenuItem>
              <MenuItem value={BOOKING_STATES.INVITED}>
                {t("Commons.Invited")}
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            disabled={loadingView}
            onClick={() => {
              getBookings(
                startingBookingDate,
                endingBookingDate,
                bookingLocation || null,
                selectedUser || null,
                selectedStatus
              );
            }}
          >
            {t("Booking.ViewBookings")}
            {loadingView && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </div>
        <BookingsTableController
          toggleAllSelected={toggleAllSelected}
          tableEmpty={tableEmpty}
          allBookingsSelected={allBookingsSelected}
          setVerifyDelete={setVerifyDelete}
          selectedBookingIds={selectedBookingIds}
          checkingIn={checkingIn}
          callOnSelectedBookings={callOnSelectedBookings}
          bookingMap={bookingMap}
          setCheckingIn={setCheckingIn}
          setQuestionnaire={setQuestionnaire}
          setQuestionSetId={setQuestionSetId}
          handleResponse={handleResponse}
          setShowQuestions={setShowQuestions}
          currentUser={currentUser}
          loadingExport={loadingExport}
          setOpenEmailModal={setOpenEmailModal}
        />
        {company &&
          Object.entries(bookingsByDate).map((booking) => (
            <Fragment key={booking[0]}>
              <Typography component="h4" variant="h3">
                <span>{booking[0]}</span>
              </Typography>
              <div className="content">
                {booking[1].map((b) => (
                  <Accordion
                    key={b.bookingId}
                    className={classnames(
                      classes.card,
                      isBookingIdSelected(b.bookingId) ? classes.selected : ""
                    )}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <FormControlLabel
                        onClick={(e) => toggleBooking(e, b.bookingId)}
                        checked={isBookingIdSelected(b.bookingId)}
                        control={<Checkbox color="primary" />}
                        className={classes.bookingFormLabel}
                        label={<CheckboxLabel booking={{ ...b }} />}
                      />
                    </AccordionSummary>
                    {
                      <AccordionDetails className={classes.details}>
                        <div>
                          <Typography>
                            {t("Booking.BookingDate")}
                            {b.date.toDate().toDateString()}
                          </Typography>
                          <Typography>
                            {t("Commons.Name_") + b.username}
                          </Typography>
                          <Typography>
                            {t("Commons.Email_") + b.email}
                          </Typography>
                          {b.visitor && (
                            <Typography>
                              {t("Commons.Visitor_") + b.visitor.firstName}
                              &nbsp;{b.visitor.lastName}
                            </Typography>
                          )}
                          {b.visitor &&  !isEmpty(b.visitor.phone) && (
                            <Typography>
                              {t("Commons.Phone_") + b.visitor.phone}
                            </Typography>
                          )}
                          {b.comment && (
                            <Typography variant="body1">
                              {t("Commons.Comment_") + b.comment}
                            </Typography>
                          )}
                        </div>
                        <div>
                          <Typography>
                            {t("Commons.Location_") +
                              CompanyUtil.getStringForCoords(
                                company,
                                b.coordinate
                              )}
                          </Typography>
                        </div>
                        <div>
                          <Typography>
                            {t("Commons.Status_") + b.state}
                          </Typography>
                          {b.meetingId &&
                          <Typography>
                            {t("Booking.Meeting")}
                          </Typography>}
                          {b.meetingId && 
                          <Typography>
                            {t("Booking.MeetingOrganizer_")}{ getMeetingOrganizerName(meetingMap[b.meetingId]) }
                          </Typography>}
                        </div>
                      </AccordionDetails>
                    }
                  </Accordion>
                ))}
              </div>
            </Fragment>
          ))}
        {(!bookings || bookings.length === 0) && (
          <Typography component="h4" variant="h3">
            <span>{t("Booking.NoBookingsFound")}</span>
          </Typography>
        )}
      </div>

      {verifyDelete === true && (
        <ConfirmDialog
          title={t("Booking.ConfirmRemoveBookings")}
          heading={`${selectedBookingIds.length} items`}
          message={t("Booking.MessageRemoveBookings")}
          warning={numMeetingBookings > 0 && t("Booking.Warning")}
          warningInfo={numMeetingBookings > 0 && t("Booking.WarningInfo")}
          onConfirm={cancelBooking}
          onCancel={() => {
            setVerifyDelete(false);
          }}
        />
      )}
      {showQuestions && !isEmpty(questionnaire) && (
        <HealthScreeningModal
          title={t("Booking.HealthScreening")}
          questionnaire={questionnaire}
          questionSetId={questionSetId}
          handleCancel={handleCancel}
          handleSubmit={handleQuestionSubmit}
        />
      )}
      <CollectEmail
        display={openEmailModal}
        close={setOpenEmailModal}
        save={triggerExport}
        submitButtonText={t("Commons.Submit")}
      />
    </div>
  );
}

CurrentBookings.propTypes = {
  company: PropTypes.object.isRequired,
  usersList: PropTypes.array.isRequired,
  locationsList: PropTypes.array.isRequired
};
