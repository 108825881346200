import React from "react";
import { Box } from "@material-ui/core";
import AppointmentType from "./AppointmentType";
import Resource from "./Resource";

export default function ResourceManagement() {
  return (
    <Box>
      <Resource />
      <AppointmentType />
    </Box>
  );
}
