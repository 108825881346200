import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function useExportATtendeesTable() {
  const [t] = useTranslation();
  const [exportCsvHeaders, setExportCsvHeaders] = useState(null);

  useEffect(() => {
    initExportCsvHeaders();
  }, []);

  const initExportCsvHeaders = () => {
    const headers = [
      { label: t("Commons.FirstName"), key: "firstName" },
      { label: t("Commons.LastName"), key: "lastName" },
      { label: t("Commons.Email"), key: "email" },
      { label: t("Commons.Status"), key: "status" },
      { label: t("Events.ExternalGuest"), key: "isExternalGuest" }
    ];

    setExportCsvHeaders(headers);
  };

  const convertToExportable = (attendees) => {
    return attendees.map((item) => {
      const exportable = {
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        status: item.status,
        isExternalGuest: item.userId ? "NO" : "YES"
      };

      return exportable;
    });
  };

  return { exportCsvHeaders, convertToExportable };
}
