import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyService } from "../../../services/useCompanyService";
import { isEmpty } from "../../../common";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddEditDialog from "../../../components/AddEditDialog/AddEditDialog";
import UserSelector from "../../../components/Selectors/UserSelector";
import LocationSelector from "../../../components/Selectors/LocationSelector";
import CheckInReasons from "./CheckInReasons";
import {
  CHECK_IN_REASONS_ACTIONS,
  EMPTY_CHECK_IN_REASON
} from "../../../constants";
import OptionalFields from "./OptionalFields";

const defaultOptionalFields = {
  email: {
    display: true,
    required: true
  },
  phone: {
    display: false,
    required: false
  },
  company: {
    display: false,
    required: false
  }
};

export const EditProfile = ({
  profile,
  groupMap,
  close,
  createMode,
  company,
  usersList,
  locationsList,
  setProfileMap
}) => {
  const companyService = useCompanyService();
  const [t, i18n] = useTranslation();

  const UPDATE_PROFILE_ERROR = t("Errors.UpdateProfile");
  const CREATE_PROFILE_ERROR = t("Errors.CreateProfile");

  const [loading, setLoading] = useState(false);
  const [editProfile, setEditProfile] = useState();
  const [errorMessages, setErrorMessages] = useState([]);

  const changeReasonIdReducer = (reasons, action) =>
    reasons.map((reason, index) => {
      const { index: payloadIndex, value } = action.payload;
      return index === payloadIndex
        ? {
          ...reason,
          id: value
        }
        : reason;
    });

  const changeDisplayNameReducer = (reasons, action) =>
    reasons.map((reason, index) => {
      const { value, index: payloadIndex, language } = action.payload;
      return index === payloadIndex
        ? {
          ...reason,
          displayName: {
            ...reasons[index].displayName,
            [language]: value
          }
        }
        : reason;
    });

  const changeVisiteeGroupsReducer = (reasons, action) => 
    reasons.map((reason, index) => {
      const { index: payloadIndex, value} = action.payload;
      return index === payloadIndex
        ? {
          ...reason,
          visiteeGroups: value
        }
        : reason;
    });

  const checkInReasonsReducer = (reasons, action) => {
    switch (action.type) {
      case CHECK_IN_REASONS_ACTIONS.ADD_REASON:
        return [...reasons, EMPTY_CHECK_IN_REASON];
      case CHECK_IN_REASONS_ACTIONS.CHANGE_REASON_ID:
        return changeReasonIdReducer(reasons, action);
      case CHECK_IN_REASONS_ACTIONS.CHANGE_DISPLAY_NAME:
        return changeDisplayNameReducer(reasons, action);
      case CHECK_IN_REASONS_ACTIONS.CHANGE_VISITEE_GROUPS:
        return changeVisiteeGroupsReducer(reasons, action);
      default:
        return reasons;
    }
  };

  const [checkInReasons, checkInReasonsDispatcher] = useReducer(
    checkInReasonsReducer,
    profile.checkInReasons || []
  );

  const checkInReasonsHaveKeys = () => checkInReasons.filter(r => r.key === '').length === 0;
  const checkInReasonsHaveEnNames = () => checkInReasons.filter(r => r.displayName.en === '').length === 0;

  const runFormValidations = () => {
    const _errorMessages = [];
    const required = ["displayName", "preferences"];

    for (let field of required) {
      if (
        (typeof editProfile[field] === "string" &&
          isEmpty(editProfile[field].trim())) ||
        isEmpty(editProfile[field])
      ) {
        _errorMessages.push(t("Errors.RequiredFields"));
      }
    }

    if (!checkInReasonsHaveKeys()) _errorMessages.push(t("Gate.CheckInReasonsMustHaveKeys"));
    if (!checkInReasonsHaveEnNames()) _errorMessages.push(t("Gate.ReasonsMustHaveEnglishVersion"));

    return _errorMessages;
  };

  useEffect(() => {
    if (createMode) {
      const _profile = {
        displayName: "",
        profileOwner: "",
        preferences: {
          instantCheckIn: false,
          sendEmail: false
        },
        visiteeGroups: [],
        visitorCheckInLocations: [],
        optionalFields: defaultOptionalFields
      };
      setEditProfile(_profile);
    } else {
      setEditProfile(profile);
    }
  }, []);

  const save = async () => {
    const _errorMessages = runFormValidations();
    if (_errorMessages.length === 0) {
      setLoading(true);
      let result;
      const profileData = {
        displayName: editProfile["displayName"],
        profileOwner: editProfile["profileOwner"],
        preferences: editProfile["preferences"],
        visitorCheckInLocations: editProfile["visitorCheckInLocations"],
        checkInReasons,
        visiteeGroups: editProfile["visiteeGroups"],
        optionalFields: editProfile["optionalFields"]
      };
      if (createMode) {
        result = await companyService.createProfile(profileData);
      } else {
        result = await companyService.updateProfile(
          editProfile.profileId,
          profileData
        );
      }

      if (result.data.success) {
        if (createMode) {
          editProfile.profileId = result.data.message;
        }
        close(
          editProfile,
          createMode
        );
      } else {
        const path = "Errors.Cloud." + result.data.message;
        const error = i18n.exists(path) ? t(path) : "";

        _errorMessages.push(
          (createMode ? CREATE_PROFILE_ERROR : UPDATE_PROFILE_ERROR) +
            (error ? " " + error : "")
        );
      }
    }
    setErrorMessages(_errorMessages);
    setLoading(false);
  };

  const onFieldChange = (field, value) => {
    let _editProfile = { ...editProfile };
    _editProfile[field] = value;
    setEditProfile(_editProfile);
  };

  const onProfileOwnerChange = (email) => {
    onFieldChange("profileOwner", email);
  };

  const onLocationChange = (location) => {
    onFieldChange("visitorCheckInLocations", location);
  };

  const filterLocations = (list) => {
    return list.filter((l) => {
      return l.bookable && l.maxBookingCount > 1;
    });
  };

  const transformUser = {
    fromFireStore: (email, list) => {
      return list.find((u) => u.email === email);
    },
    toFireStore: (user) => {
      return user.email;
    }
  };

  const transformLocation = {
    fromFireStore: (loc, list) => {
      return list.find((l) => l.locationId === loc.locationId);
    },
    toFireStore: (loc) => {
      return {
        displayName: loc.displayName,
        locationId: loc.locationId
      };
    }
  };
  
  return (
    <AddEditDialog
      createMode={createMode}
      title={t("Gate.Profile")}
      onSave={async () => {
        await save();
        const profileMap = await companyService.getHiveGateProfiles();
        setProfileMap(profileMap);
      }}
      onCancel={close}
      loading={loading}
      errorMessages={errorMessages}
      setErrorMessages={setErrorMessages}
    >
      {!isEmpty(editProfile) ? (
        <>
          <Grid item sm={12}>
            <TextField
              variant="outlined"
              label={t("Gate.ProfileName")}
              value={editProfile.displayName}
              fullWidth
              required
              onChange={(event) =>
                onFieldChange("displayName", event.target.value)
              }
            />
          </Grid>
          <Grid item sm={12}>
            <UserSelector
              contentList={usersList}
              labelKey={"Gate.TypeProfileOwner"}
              transform={transformUser}
              controller={{
                value: editProfile.profileOwner,
                setter: onProfileOwnerChange
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <LocationSelector
              contentList={locationsList}
              customFilter={filterLocations}
              company={company}
              labelKey={"Gate.TypeCheckLocation"}
              transform={transformLocation}
              controller={{
                value: editProfile.visitorCheckInLocations,
                setter: onLocationChange
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <Tooltip title={t("Gate.V1Feature")} placement="top" arrow>
              <Autocomplete
                multiple
                filterSelectedOptions
                defaultValue={() =>
                  editProfile.visiteeGroups ? editProfile.visiteeGroups : []
                }
                options={Object.keys(groupMap)}
                getOptionLabel={(option) => groupMap[option].name}
                onChange={(event, newUserGroups) => {
                  onFieldChange("visiteeGroups", newUserGroups);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Gate.VisiteeGroups")}
                    variant="outlined"
                  />
                )}
              />
            </Tooltip>
          </Grid>
          <OptionalFields
            optionalFields={editProfile.optionalFields ? editProfile.optionalFields : defaultOptionalFields}
            onFieldChange={onFieldChange}
          />
          <CheckInReasons
            checkInReasons={checkInReasons}
            dispatch={checkInReasonsDispatcher}
            groupMap={groupMap}
          />
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            style={{ padding: 12 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    editProfile ? editProfile.preferences.instantCheckIn : false
                  }
                  onChange={(event) => {
                    const _preferences = { ...editProfile.preferences };
                    _preferences["instantCheckIn"] = event.target.checked;
                    onFieldChange("preferences", _preferences);
                  }}
                  color="primary"
                />
              }
              label={t("Gate.CheckInVisitor")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    editProfile ? editProfile.preferences.sendEmail : false
                  }
                  onChange={(event) => {
                    const _preferences = { ...editProfile.preferences };
                    _preferences["sendEmail"] = event.target.checked;
                    onFieldChange("preferences", _preferences);
                  }}
                  color="primary"
                />
              }
              label={t("Gate.SendVerificationEmail")}
            />
          </Grid>
        </>
      ) : (
        <></>
      )}
    </AddEditDialog>
  );
};
