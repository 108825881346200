import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import typography from "../../theme/typography";
import { debounce } from "../../common";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    "& h2": {
      ...typography.h3
    }
  },
  actions: {
    padding: theme.spacing(1, 3),
    margin: theme.spacing(2, 0)
  },
  content: {
    paddingBottom: theme.spacing(2)
  }
}));

export const CollectEmail = ({ 
  display, 
  save, 
  close, 
  submitButtonText
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [newRecipient, setNewRecipient] = useState("");
  const [allowSave, setAllowSave] = useState(false);

  const handleCancel = () => {
    setNewRecipient("");
    close(!display);
  };

  const handleSave = () => {
    save(newRecipient);
    setNewRecipient("");
    close(!display);
  };

  const handleEmailChange = (value) => {
    setNewRecipient(value);
    checkSaveConditions(value);
  };

  const checkSaveConditions = React.useCallback(
    debounce((value) => {
      setAllowSave(isValidEmail(value));
    }, 400),
    []
  );

  const isValidEmail = (email) => {
    return email !== "" && EMAIL_REGEX.test(String(email).toLowerCase());
  };

  return (
    <Dialog
      open={display}
      fullWidth={true}
      maxWidth="sm"
      className={classes.root}
    >
      <DialogTitle className={classes.title}>
        {t("Appointments.EmailAddress")}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>
          {t("Appointments.EmailAddressMessage")}
        </DialogContentText>
        <TextField
          variant="outlined"
          label={t("Commons.Email")}
          type={"email"}
          value={newRecipient}
          fullWidth
          required
          onChange={(e) => handleEmailChange(e.target.value)}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleCancel}>
          {t("Commons.Cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!allowSave}
        >
          {submitButtonText || t("Commons.Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
