import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useHiveSyncService } from "../../services/useHiveSyncService";
import { useCompanyService } from "../../services/useCompanyService";
import { useLocalStore } from "../../hooks/useLocalStore";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";
import LockOpen from "@material-ui/icons/LockOpen";
import {
  Button,
  Card,
  Tooltip,
  CardContent,
  CardHeader,
  Typography,
  TextField
} from "@material-ui/core";
import { isEmpty } from "../../common";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textField: {
    width: "50%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  button: {
    marginTop: theme.spacing(2)
  },
  selectFileButtonOrigin: {
    cursor: "pointer",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    opacity: 0
  },
  fileSelector: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  selectFileButtonWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    padding: "2px"
  },
  doneIcon: {
    color: theme.palette.icon.green,
    fontSize: "30px"
  }
}));

const Integrations = React.memo(function Integrations({
  className,
  company,
  setCompanyField,
  ...rest
}) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const classes = useStyles();
  const hiveSyncService = useHiveSyncService();
  const companyService = useCompanyService();
  const localStore = useLocalStore();
  const [hasGoogleServiceKey, setHasGoogleServiceKey] = useState(false);
  const [microsoftTenantId, setMicrosoftTenantId] = useState("");
  const [googleProviderEmail, setGoogleProviderEmail] = useState("");

  const preferencesUtil = localStore.getCompanyPreferencesUtil();
  const GOOGLE_SERVICE_KEY_FILE = "service_account_key.json";

  useEffect(() => {
    const checkForGoogleServiceKey = async () => {
      companyService
        .getProviderConfigFile({ fileName: GOOGLE_SERVICE_KEY_FILE })
        .then((response) => {
          setHasGoogleServiceKey(!isEmpty(response.data.message));
        })
        .catch((error) => {
          console.log("Couldn't retrieve service_account_key file. ", error);
          setHasGoogleServiceKey(false);
        });
    };
    preferencesUtil.getHiveSyncGoogleProvider() && checkForGoogleServiceKey();
    isEmpty(microsoftTenantId) && setMicrosoftTenantId(preferencesUtil.getMicrosoftTenantId());
    isEmpty(googleProviderEmail) && setGoogleProviderEmail(preferencesUtil.getGoogleProviderEmail());
  }, []);

  const openAdminConsentLink = async () => {
    const _errorMessages = runFormValidations();
    if (_errorMessages.length === 0) {
      try {
        const requestBody = {
          data: {
            tenantId: preferencesUtil.getMicrosoftTenantId()
          }
        };
        const response = await hiveSyncService.getAdminConsentLink(requestBody);
        if (!isEmpty(response.data.adminConsentLink)) {
          window.open(
            response.data.adminConsentLink,
            "_blank",
            "noopener,noreferrer"
          );
          dispatch(setSuccessMessage(t("Success.UpdateCompany")));
        } else {
          dispatch(
            setErrorMessage(t("Errors.ErrorRetrievingAdminConsentLink"))
          );
        }
      } catch (e) {
        dispatch(setErrorMessage(e.message));
        return;
      }
    } else {
      dispatch(setErrorMessage(_errorMessages));
    }
  };

  const runFormValidations = () => {
    const _errorMessages = [];
    if (isEmpty(preferencesUtil.getMicrosoftTenantId())) {
      _errorMessages.push(t("Errors.MissingMicrosoftTenantId"));
    }

    return _errorMessages;
  };

  const onUploadGoogleFile = useCallback((event) => {
    const file = event.target.files[0];
    if (file.type === "application/json") {
      const reader = new FileReader();
      // eslint-disable-next-line
      reader.onload = function (e) {
        const data = {
          name: GOOGLE_SERVICE_KEY_FILE,
          type: file.type,
          companyId: company.companyId,
          blob: reader.result
        };

        companyService
          .uploadProviderConfigFile(data)
          .then(() => {
            dispatch(setSuccessMessage(t("Success.UpdateCompany")));
            setHasGoogleServiceKey(true);
          })
          .catch((error) => {
            console.log("Error uploading the service account key file.", error);
            dispatch(setErrorMessage(t("Errors.General")));
          });
      };
      reader.readAsText(file);
    } else {
      dispatch(setErrorMessage(t("Errors.FileHasWrongFormat")));
    }
  }, []);

  const onMicrosoftTenantIdChange = (newTenantId) => {
    updateProvider("microsoft", newTenantId);
    setMicrosoftTenantId(newTenantId);
  };

  const onGoogleWorkspaceEmailChange = (newValue) => {
    updateProvider("google", newValue);
    setGoogleProviderEmail(newValue);
  };

  const updateProvider = (provider, newValue) => {
    let newPrefs = { ...company.preferences };

    const providers = preferencesUtil.getHiveSyncProviders();
    if (!providers) {
      newPrefs.hiveSyncService = {
        enableCalendarSync: false,
        providers: {}
      };
    }

    if (isEmpty(newValue)) {
      delete newPrefs.hiveSyncService.providers[provider];
    } else {
      const obj = (provider === "microsoft" && { tenantId: newValue }) || {
        email: newValue
      };
      newPrefs.hiveSyncService.providers[provider] = obj;
    }

    setCompanyField("preferences", newPrefs);
  };

  return (
    <Card className={className} {...rest}>
      <CardHeader title="Integrations" />
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {t("CompanyInfo.MicrosoftAzureActiveDirectory")}
        </Typography>
        <TextField
          className={classes.textField}
          fullWidth
          variant="outlined"
          label={t("CompanyInfo.TenantId")}
          name="tenantId"
          value={microsoftTenantId}
          onChange={(e) => onMicrosoftTenantIdChange(e.target.value)}
          helperText={t("CompanyInfo.MicrosoftAzureActiveDirectoryHelperText")}
        />
        <Button onClick={openAdminConsentLink} className={classes.button}>
          <LockOpen fontSize="large" />
        </Button>
        <Typography variant="h6" gutterBottom>
          {t("CompanyInfo.GoogleWorkspace")}
        </Typography>
        <div className={classes.fileSelector}>
          <Tooltip title={t("CompanyInfo.ServiceKeyButtonTooltip")}>
            <div className={classes.selectFileButtonWrapper}>
              <Button variant="contained" color="primary">
                {(hasGoogleServiceKey && t("CompanyInfo.ServiceKeyPresent")) ||
                  t("CompanyInfo.ServiceKeyNotPresent")}
              </Button>
              <input
                type="file"
                className={classes.selectFileButtonOrigin}
                onChange={(e) => {
                  onUploadGoogleFile(e);
                }}
                accept=".json"
              />
            </div>
          </Tooltip>
          {hasGoogleServiceKey && <DoneIcon className={classes.doneIcon} />}
        </div>
        <TextField
          className={classes.textField}
          fullWidth
          variant="outlined"
          label={t("CompanyInfo.ActAs")}
          name="googleEmail"
          value={googleProviderEmail}
          onChange={(e) => onGoogleWorkspaceEmailChange(e.target.value)}
          helperText={t("CompanyInfo.GoogleIntegrationHelperText")}
        />
      </CardContent>
    </Card>
  );
});

Integrations.propTypes = {
  className: PropTypes.string
};

export default Integrations;
