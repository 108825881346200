import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

// Project Components
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { useCompanyService } from "../../../services/useCompanyService";


export const DeleteDevice = ({ deleteDevice: device, close }) => {
  const companyService = useCompanyService();
  const [t, i18n] = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");

  const confirmDelete = async () => {
    // delete device
    const result = await companyService.deleteDevice(device.deviceId);

    if (result.data.success) {
      close(device);
    } else {
      const path = "Errors.Cloud." + result.data.message;
      const error = i18n.exists(path) ? t(path) : t("Errors.DeleteDevice");
      setErrorMessage(error);
    }
  };

  return (
    <ConfirmDialog
      title={t("Gate.DeleteDevice")}
      heading={device.name}
      message={t("Gate.ConfirmDeleteDevice")}
      onConfirm={confirmDelete}
      onCancel={close}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />
  );
};