import React from "react";
import PropTypes from "prop-types";
import CompanyUtil from "../../utils/CompanyUtil";
import BaseSelector from "./BaseSelector";

const LocationSelector = ({
  controller,
  labelKey,
  transform,
  disabled,
  contentList,
  company,
  customFilter,
  sortLocationList = false
}) => {
  const getLocationsList = () => {
    const locationList = customFilter ? customFilter(contentList) : contentList; 
    return sortLocationList ? sortByLabel(locationList) : locationList;
  };

  const sortByLabel = (locationList) => {
    return locationList.sort((a, b) => (optionLabelFormater(a) > optionLabelFormater(b)) ? 1 : -1);
  };

  const optionLabelFormater = (location) => {
    if (location.coordinate) {
      return CompanyUtil.getStringForCoords(company, location.coordinate);
    }
    return "";
  };

  return (
    <>
      {company && (
        <BaseSelector
          _controller={controller}
          _disabled={disabled}
          _labelKey={labelKey}
          _ajaxMethod={getLocationsList}
          _optionLabelFormater={optionLabelFormater}
          _idFieldName={"locationId"}
          _transform={transform}
        />
      )}
    </>
  );
};

LocationSelector.propTypes = {
  disabled: PropTypes.bool,
  company: PropTypes.object.isRequired,
  controller: PropTypes.object.isRequired,
  labelKey: PropTypes.string,
  contentList: PropTypes.array.isRequired,
  transform: PropTypes.func,
  customFilter: PropTypes.func
};

export default LocationSelector;
