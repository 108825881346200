import React, { useEffect, useState } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useCompanyService } from "../../services/useCompanyService";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import General from "./general";
import Cleaned from "./cleaned";
import ContactTracing from "./contactTracing";
import Location from "./location";
import HealthScreening from "./healthScreening";
import ActiveUsers from "./activeUsers";
import { useLocalStore } from "../../hooks/useLocalStore";
import { ROLES } from "../../constants";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%"
  },
  wrapper: {
    margin: "20px 0"
  },
  requestParams: {
    display: "grid",
    gridTemplateColumns: "2fr 1.5fr",
    columnGap: "0px",
    rowGap: "20px",
    alignItems: "center"
  }
}));

export default function Reports() {
  const { t } = useTranslation();
  const classes = useStyles();
  const companyService = useCompanyService();
  const localStore = useLocalStore();
  const [locationOptions, setLocationOptions] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const company = localStore.getCompany();
  const userRolesUtil = localStore.getUserRolesUtil();

  const INDEX = Object.freeze({
    GENERAL: 0,
    CLEANING: 1,
    CONTACT: 2,
    ACTIVE_USERS: 3,
    LOCATION: 4,
    HEALTH_SCREENING: 5
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const coordinates = company.coordinateSystem;
    if (coordinates) {
      const notBottomLevelLocations = await companyService.getLocationOptions(company);
      setLocationOptions(notBottomLevelLocations);
    }
  };

  const buildMenuItems = () => {
    const items = [];

    if(userRolesUtil.hasRoles([ROLES.ADMIN, ROLES.HR_ADMIN])){
      items.push(<MenuItem value={INDEX.GENERAL}>{t("Reports.GeneralReports")}</MenuItem>);
      items.push(<MenuItem value={INDEX.CLEANING}>{t("Reports.DailyCleaningReport")}</MenuItem>);
    }

    if(userRolesUtil.hasRole(ROLES.HR_ADMIN)){
      items.push(<MenuItem value={INDEX.HEALTH_SCREENING}>{t("Reports.HealthScreeningReports")}</MenuItem>);
      items.push(<MenuItem value={INDEX.CONTACT}>{t("Reports.ContactTracing")}</MenuItem>);
    }

    userRolesUtil.isRootUser() && 
      items.push(<MenuItem value={INDEX.ACTIVE_USERS}>{t("Reports.ActiveUsersReport")}</MenuItem>);
    userRolesUtil.hasRoles([ROLES.ADMIN, ROLES.LOCATION_ADMIN]) && 
      items.push(<MenuItem value={INDEX.LOCATION}>{t("Reports.LocationReport")}</MenuItem>);
    return items;
  };

  const getCurrentTab = () => {
    switch (currentTab) {
      case INDEX.GENERAL:
        return <General />;
      case INDEX.CLEANING:
        return <Cleaned locationOptions={locationOptions} company={company} />;
      case INDEX.CONTACT:
        return <ContactTracing locationOptions={locationOptions} company={company} />;
      case INDEX.ACTIVE_USERS:
        return <ActiveUsers locationOptions={locationOptions} company={company} />;
      case INDEX.LOCATION:
        return <Location company={company} />;
      case INDEX.HEALTH_SCREENING:
        return <HealthScreening />;
      default:
        return "";
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h3">{t("Reports.Reports")}</Typography>
      <div
        className={classnames({
          [classes.requestParams]: true,
          [classes.wrapper]: true
        })}
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="report-label">{t("Reports.Reports")}</InputLabel>
          <Select
            labelId="report-label"
            value={currentTab}
            onChange={(e) => setCurrentTab(e.target.value)}
            label={t("Commons.Status")}
            className={classes.filter}
            autoWidth={true}
          >
            {buildMenuItems()}
          </Select>
        </FormControl>
      </div>
      {getCurrentTab()}
    </Container>
  );
}
