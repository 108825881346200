import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { isTrue, isEmpty, isNullOrUndefined } from "../../common";
import { useCompanyService } from "../../services/useCompanyService";
import { useLocalStore } from "../../hooks/useLocalStore";
import UserSelector from "../../components/Selectors/UserSelector";
import Autocomplete from "@material-ui/lab/Autocomplete";
import matchSorter from "match-sorter";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import InfoIcon from "@material-ui/icons/Info";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ROLES } from "../../constants";
import { useTranslation } from "react-i18next";
import { listTimeZones, getUTCOffset, findTimeZone } from "timezone-support";
import { useDispatch } from "react-redux";
import TagSelector from "../../components/Selectors/TagSelector";
import {
  setSuccessMessage,
  setErrorMessage as dispatchError
} from "../../actions/index";
import {
  Checkbox,
  TextField,
  Grid,
  Typography,
  Tooltip,
  Card
} from "@material-ui/core";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    fontSize: "inherit",
    verticalAlign: "text-bottom",
    display: "inline",
    marginLeft: theme.spacing(0.5)
  },
  checkBoxDescription: {
    display: "inline"
  },
  outOfServiceText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export const EditLocation = ({
  createMode,
  location,
  users,
  userGroups,
  close,
  company,
  parentLocation,
  reloadLocations
}) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const localStore = useLocalStore();
  const localStorage = useLocalStorage();
  const companyService = useCompanyService();
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [adjacentSeats, setAdjacentSeats] = useState([]);
  const [selectedAdjacentSeats, setSelectedAdjacentSeats] = useState([]);
  const [cleaners, setCleaners] = useState([]);
  const [timeZone, setTimeZone] = useState(setTimeBaseZone());
  const [enableBookingApproval, setEnableBookingApproval] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCleaners, setSelectedCleaners] = useState([]);

  const [editLocation, setEditLocation] = useState(() => {
    if (createMode) {
      return {
        displayName: "",
        maxBookingCount: null,
        bookingApprovalsRequired: null,
        isMeetingRoom: false,
        tags: [],
        serviceStatus: {
          isOutOfService: false,
          reason: ""
        }
      };
    } else {
      return location;
    }
  });

  const preferencesUtil = localStore.getCompanyPreferencesUtil();
  const coordinates = company.coordinateSystem;
  const tzList = listTimeZones();
  const TIME_BOOKING_DESCRIPTION = t("Locations.TimeBookingsEnabled");

  useEffect(() => {
    const loadData = async () => {
      const _adminUsers = getExistingAdminUsers();
      setSelectedUsers(_adminUsers);
      const _cleaners = getExistingCleanerUsers();
      setSelectedCleaners(_cleaners);
      isEmpty(usersList) &&
        setUsersList(companyService.getUsersFromDropdownListCollection());
    };
    loadData();

    if (location.atBottomLevel) {
      loadAdjacentSeats();
    }
  }, []);

  useEffect(() => {
    if (location.atBottomLevel) {
      let _selectedAdjacentSeats = [];
      if (location.adjacentLocation && adjacentSeats.length > 0) {
        location.adjacentLocation.forEach((seatId) => {
          _selectedAdjacentSeats.push(
            adjacentSeats.find((location) => location.locationId === seatId)
          );
        });
      }
      _selectedAdjacentSeats = _selectedAdjacentSeats.filter(
        (seat) => seat !== undefined
      );
      setSelectedAdjacentSeats(_selectedAdjacentSeats);
    }
  }, [adjacentSeats]);

  useEffect(() => {
    if (!isEmpty(users)) {
      let _cleaners = users.filter((user) =>
        user.roles.includes(ROLES.CLEANER)
      );
      setCleaners(_cleaners);
    }
  }, [users]);

  useEffect(() => {
    companyService
      .isBookingApprovalEnabled()
      .then((isBookingApprovalEnabled) => {
        setEnableBookingApproval(isBookingApprovalEnabled);
      });
  }, [companyService]);

  const isRootOrITAdmin = () => {
    return (
      localStorage.getUserRoles().includes(ROLES.ROOT) ||
      localStorage.getUserRoles().includes(ROLES.IT_ADMIN)
    );
  };

  const loadAdjacentSeats = async () => {
    if (location.locationParent) {
      const locations = await companyService.getAllLocations();
      const _adjacentSeats = locations.filter(
        (seat) => seat.locationId !== location.locationId && seat.atBottomLevel
      );
      setAdjacentSeats(_adjacentSeats);
    }
  };

  const originalDisplayName = createMode ? "" : location.displayName;

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, {
      keys: ["firstName", "lastName", "email"]
    });

  const getExistingAdminUsers = () => {
    let admins = [];
    if (editLocation.admins) {
      editLocation.admins.forEach((adminEmail) => {
        users.forEach((user) => {
          if (user.email.toLowerCase() === adminEmail.toLowerCase()) {
            admins.push(user);
            return;
          }
        });
      });
    }
    return admins;
  };

  const getExistingCleanerUsers = () => {
    let cleaners = [];
    if (editLocation.cleaners) {
      editLocation.cleaners.forEach((cleanerEmail) => {
        users.forEach((user) => {
          if (user.email.toLowerCase() === cleanerEmail.toLowerCase()) {
            cleaners.push(user);
            return;
          }
        });
      });
    }
    return cleaners;
  };

  const getUserOptionLabel = (option) => {
    let fullName =
      option.firstName + " " + option.lastName + " (" + option.email + ")";
    return fullName.length > 3 ? fullName : option.email;
  };

  const getExistingUserGroups = () => {
    let adminUserGroups = [];
    if (editLocation.groups) {
      editLocation.groups.forEach((group) => {
        userGroups.forEach((userGroup) => {
          if (userGroup.userGroupId === group) {
            adminUserGroups.push(userGroup);
            return;
          }
        });
      });
    }
    return adminUserGroups;
  };

  const getSeatOptionName = (location) => {
    let _name = "";
    coordinates.forEach((level, index) => {
      if (!isNullOrUndefined(location.coordinate[level])) {
        _name = _name.concat(
          location.coordinate[level].displayName +
            (index !== coordinates.length - 1 &&
            !isNullOrUndefined(location.coordinate[coordinates[index + 1]])
              ? " | "
              : "")
        );
      }
    });
    return _name;
  };

  const onTagChange = (newTagValue) => {
    let currLocation = { ...editLocation };
    currLocation.tags = newTagValue;
    setEditLocation(currLocation);    
  };

  const [selectedUserGroups, setSelectedUserGroups] = useState(() =>
    getExistingUserGroups()
  );

  const onChange = (field, e) => {
    let _editLocation = { ...editLocation };
    _editLocation[field] = e.target.value;
    setEditLocation(_editLocation);
  };

  const onChangeIntegration = (integrationName, field, e) => {
    let _editLocation = { ...editLocation };
    if (!Object.prototype.hasOwnProperty.call(_editLocation,"integrations") || isEmpty(_editLocation["integrations"])) {
      _editLocation["integrations"] = {};
      _editLocation["integrations"][integrationName] = {};
    } else if (!Object.prototype.hasOwnProperty.call(_editLocation["integrations"],integrationName)) {
      _editLocation["integrations"][integrationName] = {};
    }
    _editLocation["integrations"][integrationName][field] = e.target.value;
    setEditLocation(_editLocation);
  };

  const setFieldValue = (field, value) => {
    let _editLocation = { ...editLocation };
    _editLocation[field] = value;
    setEditLocation(_editLocation);
  };

  const transformAdminUser = {
    fromFireStore: (user, list) => {
      return list.find((u) => u.userId === user.userId);
    },
    toFireStore: (user) => {
      return user;
    }
  };

  function setTimeBaseZone() {
    let tz = location.timeZone;

    if (!tz) {
      if (createMode) {
        if (!isEmpty(parentLocation)) {
          tz = isEmpty(parentLocation.timeZone)
            ? company.timeZone
            : parentLocation.timeZone;
        } else {
          tz = company.timeZone;
        }
      } else {
        tz = company.timeZone;
      }
    }

    return tz;
  }

  const runFormValidadtion = () => {
    const _errorMessages = [];

    if (!locationHasName()) {
      _errorMessages.push(t("Locations.LocationsMustHaveAName"));
    }

    return _errorMessages;
  };

  const locationHasName = () => {
    return !isEmpty(editLocation.displayName);
  };

  const save = async () => {
    const _errorMessages = runFormValidadtion();
    if (_errorMessages.length === 0) {
      setLoading(true);
      if (createMode) {
        const newLocation = {
          displayName: editLocation.displayName,
          locationParent: location.locationParent
            ? location.locationParent.locationId
            : "",
          locationType: location.locationType,
          admins: selectedUsers ? selectedUsers.map((user) => user.email) : [],
          cleaners: selectedCleaners
            ? selectedCleaners.map((user) => user.email)
            : [],
          assignedLocationFor: [],
          preferredLocationFor: [],
          groups: selectedUserGroups
            ? selectedUserGroups.map((userGroup) => userGroup.userGroupId)
            : [],
          adjacentLocation: selectedAdjacentSeats
            ? selectedAdjacentSeats.map((seat) => seat.locationId)
            : [],
          atBottomLevel: isTrue(location.atBottomLevel),
          bookable: isTrue(location.bookable),
          isMeetingRoom: isTrue(editLocation.isMeetingRoom),
          timeZone: timeZone,
          integrations: editLocation.integrations,
          tags: !isEmpty(editLocation.tags) ? editLocation.tags : [],
          serviceStatus: editLocation.serviceStatus
        };

        let maxBookingCount = parseInt(editLocation.maxBookingCount);
        newLocation.maxBookingCount = maxBookingCount ? maxBookingCount : null;

        let bookingApprovalsRequired = parseInt(
          editLocation.bookingApprovalsRequired
        );
        newLocation.bookingApprovalsRequired =
          bookingApprovalsRequired && enableBookingApproval
            ? bookingApprovalsRequired
            : null;

        const response = await companyService.createLocation(newLocation);

        if (response.data.success) {
          setSuccessMessage(t("Success.CreateLocation"));
          setLoading(false);
          newLocation.locationId = response.data.locationId;
          close(newLocation);
          reloadLocations(newLocation.locationParent);
          updateLocationsDropdownList();
        } else {
          const path = "Errors.Cloud." + response.data.message;
          const error = i18n.exists(path) ? t(path) : t("Errors.CreateLocation");
          _errorMessages.push(error);
          setLoading(false);
        }
      } else {
        if (selectedUsers) {
          editLocation.admins = selectedUsers.map((user) => user.email);
        }

        if (selectedCleaners) {
          editLocation.cleaners = selectedCleaners.map((user) => user.email);
        }

        if (selectedUserGroups) {
          editLocation.groups = selectedUserGroups.map(
            (userGroup) => userGroup.userGroupId
          );
        }

        if (selectedAdjacentSeats) {
          editLocation.adjacentLocation = selectedAdjacentSeats.map(
            (seat) => seat.locationId
          );
        }

        if (timeZone) {
          editLocation.timeZone = timeZone;
        }

        let maxBookingCount = parseInt(editLocation.maxBookingCount);
        editLocation.maxBookingCount = maxBookingCount ? maxBookingCount : null;

        let bookingApprovalsRequired = parseInt(
          editLocation.bookingApprovalsRequired
        );
        editLocation.bookingApprovalsRequired =
          bookingApprovalsRequired && enableBookingApproval
            ? bookingApprovalsRequired
            : null;

        editLocation.coordinate[editLocation.locationType].displayName =
          editLocation.displayName;

        await companyService
          .updateLocation(editLocation)
          .then((response) => {
            if (response.data.success === false) {
              const details = i18next.exists(
                "Errors.Cloud." + response.data.message
              )
                ? t("Errors.Cloud." + response.data.message)
                : "";
              dispatch(dispatchError(t("Errors.UpdateLocation") + details));
            } else {
              dispatch(setSuccessMessage(t("Success.UpdateLocation")));
              updateLocationsDropdownList();
            }
          })
          .catch((error) => {
            dispatch(dispatchError(t("Locations.ProblemUpdatingLocation")));
            console.log("error occurred trying to update this location:", error);
          })
          .finally(() => {
            setLoading(false);
            close(editLocation);
            reloadLocations(location.locationParent);
          });

        if (editLocation.displayName !== originalDisplayName) {
          await companyService.updateLocationTreeCoordinateDisplayName(
            editLocation
          );
        }
      }
    }
    setErrorMessages(_errorMessages);
  };

  function getTimeZoneOffSet(event, value) {
    if (isEmpty(value)) {
      return;
    }

    const dateObject = getUTCOffset(new Date(), findTimeZone(value));
    dateObject.region = value;
    setTimeZone(dateObject);
  }

  const updateLocationsDropdownList = () => {
    return companyService.updateLocationsDropdownList();
  };

  return (
    <AddEditDialog
      createMode={createMode}
      title={location.locationType}
      onSave={save}
      onCancel={close}
      loading={loading}
      errorMessages={errorMessages}
      setErrorMessages={setErrorMessages}
    >
      <Grid item>
        <TextField
          variant="outlined"
          label={t("Commons.Name")}
          value={editLocation.displayName}
          fullWidth
          onChange={(e) => onChange("displayName", e)}
        />
      </Grid>
      {!isTrue(location.atBottomLevel) && (
        <Grid item>
          <TextField
            variant="outlined"
            label={t("Locations.MaxBookingCount")}
            type="number"
            value={editLocation.maxBookingCount}
            fullWidth
            onChange={(e) => onChange("maxBookingCount", e)}
          />
        </Grid>
      )}
      {!isTrue(location.atBottomLevel) && users && (
        <Grid item>
          <UserSelector
            contentList={usersList}
            labelKey={"Locations.AddAdmin"}
            controller={{
              value: selectedUsers,
              setter: setSelectedUsers
            }}
            transform={transformAdminUser}
          />
        </Grid>
      )}
      {company &&
        preferencesUtil.isCleaningEnabled() &&
        (!isTrue(location.bookable) || location.maxBookingCount > 1) &&
        users && (
        <Grid item>
          <Autocomplete
            multiple
            id="selected_cleaners"
            defaultValue={selectedCleaners}
            options={cleaners}
            value={selectedCleaners}
            getOptionLabel={(option) => getUserOptionLabel(option)}
            onChange={(event, newValue) => {
              setSelectedCleaners(newValue);
            }}
            filterOptions={filterOptions}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Locations.AddCleaner")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      )}
      {!isTrue(location.atBottomLevel) && userGroups && (
        <Grid item>
          <Autocomplete
            multiple
            id="selected_userGroups"
            defaultValue={selectedUserGroups}
            options={userGroups}
            value={selectedUserGroups}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setSelectedUserGroups(newValue);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Locations.AddUserGroup")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      )}
      {isTrue(location.bookable) && (
        <Grid item>
          <TagSelector 
            labelKey={"Locations.Tags"}
            controller={{
              value: editLocation.tags, 
              setter: onTagChange
            }} 
          />
        </Grid>
      )}
      {isTrue(location.atBottomLevel && adjacentSeats.length > 0) && (
        <Grid item>
          <Autocomplete
            multiple
            id="adjacent_seats"
            defaultValue={selectedAdjacentSeats}
            options={adjacentSeats}
            value={selectedAdjacentSeats}
            getOptionLabel={(option) => getSeatOptionName(option)}
            onChange={(event, newValue) => {
              setSelectedAdjacentSeats(newValue);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Locations.AddAdjacentSeat")}
                margin="normal"
              />
            )}
          />
        </Grid>
      )}
      <Grid item>
        <TextField
          variant="outlined"
          label={t("Locations.BookingApprovalsRequired")}
          type="number"
          value={editLocation.bookingApprovalsRequired}
          fullWidth
          disabled={!enableBookingApproval}
          helperText={
            <p>
              <InfoIcon style={{ fontSize: 16 }} />{" "}
              <b>
                {enableBookingApproval ? "" : t("Features.DisabledContent")}
              </b>{" "}
              {t("Locations.ApprovalsRequiredDescription")}
            </p>
          }
          onChange={(e) => onChange("bookingApprovalsRequired", e)}
        />
      </Grid>
      {isEmpty(timeZone) ? (
        <Typography>{t("Locations.MissingCompanyTimezone")}</Typography>
      ) : (
        !location.atBottomLevel && (
          <Grid item>
            <Autocomplete
              id="time_zone_offset"
              defaultValue={timeZone.region}
              options={tzList}
              value={timeZone.region}
              getOptionLabel={(option) => option}
              onChange={getTimeZoneOffSet}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Locations.ChooseTimeZone")}
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )
      )}
      {isTrue(location.bookable) && !location.atBottomLevel && (
        <Grid item>
          <Checkbox
            checked={editLocation.isMeetingRoom}
            onChange={() =>
              setFieldValue("isMeetingRoom", !editLocation.isMeetingRoom)
            }
            color="primary"
          />
          <Tooltip title={TIME_BOOKING_DESCRIPTION}>
            <div className={classes.checkBoxDescription}>
              <Typography className={classes.checkBoxDescription}>
                {t("Locations.TimedBookings")}
              </Typography>
              <InfoIcon className={classes.infoIcon} />
            </div>
          </Tooltip>
        </Grid>
      )}
      {editLocation.isMeetingRoom &&
        preferencesUtil.isCalendarSyncEnabled() &&
        preferencesUtil.getHiveSyncMicrosoftProvider() &&
        isRootOrITAdmin() && (
        <Grid item>
          <TextField
            variant="outlined"
            label={t("Locations.ExchangeEmail")}
            value={editLocation.integrations?.exchange?.externalId ?? ""}
            fullWidth
            onChange={(e) => onChangeIntegration("exchange", "externalId", e)}
          />
        </Grid>
      )}
      {editLocation.isMeetingRoom &&
        preferencesUtil.isCalendarSyncEnabled() &&
        preferencesUtil.getHiveSyncGoogleProvider() &&
        isRootOrITAdmin() && (
        <Grid item>
          <TextField
            variant="outlined"
            label={t("Locations.GoogleEmail")}
            value={editLocation.integrations?.google?.externalId ?? ""}
            fullWidth
            onChange={(e) => onChangeIntegration("google", "externalId", e)}
          />
        </Grid>
      )}
      <Grid item>
        <Card className={classes.outOfServiceCard}>
          <Checkbox
            checked={!isEmpty(editLocation.serviceStatus) ? editLocation.serviceStatus.isOutOfService : false}
            onChange={(event) => {
              const checked = event.target.checked;
              const newServiceStatus = !isEmpty(editLocation.serviceStatus) ? 
                { ...editLocation.serviceStatus, isOutOfService: checked } : { isOutOfService: checked, reason: "" };
              setFieldValue("serviceStatus", newServiceStatus);
            }}
            color="primary"
          />
          <Tooltip title={t("Locations.OutOfServiceToolTip")}>
            <div className={classes.checkBoxDescription}>
              <Typography className={classes.checkBoxDescription}>
                {t("Locations.OutOfService")}
              </Typography>
              <InfoIcon className={classes.infoIcon} />
            </div>
          </Tooltip>
          {editLocation.serviceStatus && editLocation.serviceStatus.isOutOfService && (
            <div className={classes.outOfServiceText}>
              <TextField
                variant="outlined"
                label={t("Locations.Reason")}
                value={editLocation.serviceStatus.reason}
                fullWidth
                helperText={
                  <p>
                    <InfoIcon style={{ fontSize: 16 }} />{" "}
                    {t("Locations.ReasonToolTip")}
                  </p>
                }
                onChange={(event) => setFieldValue("serviceStatus", {...editLocation.serviceStatus, reason: event.target.value})}
              />
            </div>
          )}
        </Card>
      </Grid>
    </AddEditDialog>
  );
};
