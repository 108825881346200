import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, Card, CardHeader, CardContent } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const ContactInfo = React.memo(
  ({ className, company, setCompanyField, ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
      <Card className={className} {...rest}>
        <CardHeader title={t("CompanyInfo.ContactInformation")} />
        <CardContent>
          <TextField
            className={classes.textField}
            required
            variant="outlined"
            label={t("CompanyInfo.ContactName")}
            fullWidth
            value={company.contactName}
            onChange={(e) => setCompanyField("contactName", e.target.value)}
          />
          <TextField
            className={classes.textField}
            required
            fullWidth
            variant="outlined"
            label={t("CompanyInfo.BusinessAddress")}
            value={company.businessAddress}
            onChange={(e) => setCompanyField("businessAddress", e.target.value)}
          />
        </CardContent>
      </Card>
    );
  }
);

ContactInfo.propTypes = {
  className: PropTypes.string
};

export default ContactInfo;
