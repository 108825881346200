import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  makeStyles,
  Typography
} from "@material-ui/core";
import { format as formatDate } from "date-fns";
import CompanyUtil from "../../utils/CompanyUtil";
import { ExpandMore, NotificationsActive } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { ATTENDEE_STATES } from "../../constants";
import { useHistory } from "react-router-dom";
import { useCompanyService } from "../../services/useCompanyService";
import { useDispatch } from "react-redux";
import { setErrorMessage, setSuccessMessage } from "../../actions";

const useStyles = makeStyles((theme) => ({
  wrapper: { margin: "1rem 0" },
  eventStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  eventHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1
  },
  eventSummary: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  eventUpdate: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    gap: "0.2rem"
  },
  eventNotificationMessage: {
    color: "primary"
  },
  eventDetail: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem"
  },
  detailsButton: {
    alignSelf: "flex-start"
  },
  eventInformation: {
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr"
  },
  infoLine: {
    display: "flex",
    flexDirection: "column",
    gap: "0.2rem",
    marginBottom: "1rem"
  },
  left: {
    flex: 1
  },
  right: {
    alignSelf: "flex-start"
  },
  alertTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
    gap: "0.2rem"
  },
  alertIcon: {
    color: theme.palette.primary.main
  },
  alerts: {
    display: "flex",
    flexDirection: "column"
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row"
  },
  cancelButton: {
    alignSelf: "flex-start",
    marginLeft: theme.spacing(2)
  }
}));

export default function EventCard({ event, index, company, onCancelEvent }) {
  const [t] = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const companyService = useCompanyService();
  const dispatch = useDispatch();

  const [init, setInit] = useState(false);
  const [locationName, setLocationName] = useState("");

  useEffect(() => {
    const location = CompanyUtil.getStringForCoords(company, event.coordinate);
    setLocationName(location);

    setInit(true);
  }, []);

  const eventHasNotifications = () => event.notifications.length > 0;

  const renderOwnerName = () =>
    `${event.owner.firstName} ${event.owner.lastName}`;

  const renderAdminName = () =>
    `${event.organizer.firstName} ${event.organizer.lastName}`;

  const renderAttendees = () => {
    let totalAccepted,
      totalDeclined = 0;

    totalAccepted = event.attendees.filter(
      (item) => item.status === ATTENDEE_STATES.CONFIRMED
    ).length;
    totalDeclined = event.attendees.filter(
      (item) => item.status === ATTENDEE_STATES.DECLINED
    ).length;

    return (
      <div className={classes.eventAttendees}>
        <Typography>{`${t(
          "Events.AttendiesAccepted"
        )}: ${totalAccepted}`}</Typography>
        <Typography>{`${t(
          "Events.AttendiesDeclined"
        )}: ${totalDeclined}`}</Typography>
      </div>
    );
  };

  const renderNotifications = () => {
    return event.notifications.map((notification, index) => (
      <Typography key={index}>{notification.message}</Typography>
    ));
  };

  const handleEventDetailsClick = () =>
    history.push(`/events/${event.meetingId}`);

  const handleEventCancelClick = async () => {
    const result = await companyService.cancelEvent(event.meetingId);

    if (result.data.success) {
      dispatch(setSuccessMessage(t("Success.CancelEvent")));
      onCancelEvent(index);
    } else {
      dispatch(setErrorMessage(t("Errors.CancelEvent")));
    }
  };

  if (init) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.eventStatus}>
          <Typography variant="h5">
            {formatDate(event.date, "P")}
          </Typography>
          {eventHasNotifications() && (
            <div className={classes.eventUpdate}>
              <NotificationsActive />
              <Typography
                variant="h5"
                className={classes.eventNotificationMessage}
              >
                {t("Events.EventNotificationsMessage")}
              </Typography>
            </div>
          )}
        </div>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <div className={classes.eventHeader}>
              <div className={classes.eventSummary}>
                <Typography variant="h3" className={classes.eventHeader}>
                  {event.displayName}
                </Typography>
                {locationName && (
                  <Typography variant="h5">
                    {t("Commons.Location")}: {locationName}
                  </Typography>
                )}
              </div>
              <Typography variant="h5">
                {formatDate(event.startTime, "p")}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.eventDetail}>
            <div className={classes.buttonGroup}>
              <Button
                variant="contained"
                color="primary"
                className={classes.detailsButton}
                onClick={handleEventDetailsClick}
              >
                {t("Events.EventDetails")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.cancelButton}
                onClick={handleEventCancelClick}
              >
                {t("Events.CancelEvent")}
              </Button>
            </div>
            <div className={classes.eventInformation}>
              <div className={classes.left}>
                <div className={classes.infoLine}>
                  <Typography variant="h6">{t("Events.EventOwner")}</Typography>
                  <Typography>{renderOwnerName()}</Typography>
                </div>
                <div className={classes.infoLine}>
                  <Typography variant="h6">
                    {t("Events.EventOrganizer")}
                  </Typography>
                  <Typography>{renderAdminName()}</Typography>
                </div>
                <div className={classes.infoLine}>
                  <Typography variant="h6">{t("Events.Attendies")}</Typography>
                  {renderAttendees()}
                </div>
              </div>
              {eventHasNotifications() && (
                <div className={classes.right}>
                  <div className={classes.alertTitle}>
                    <NotificationsActive className={classes.alertIcon} />
                    <Typography variant="h6">
                      {t("Events.QuickAlerts")}
                    </Typography>
                  </div>
                  <div className={classes.alerts}>{renderNotifications()}</div>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  } else {
    return <Skeleton height="5rem" animation="wave" />;
  }
}

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired
};
