import React from "react";
import { Typography } from "@material-ui/core";

const QuestionLabel = ({ text, heading }) => {
  const labelStyle = {
    fontFamily: "inherit",
    wordBreak: "break-word",
    whiteSpace: "pre-line"
  };

  const headingStyle = heading
    ? {
      fontWeight: "bold",
      marginLeft: "0.5rem"
    }
    : {};

  return (
    <Typography component={"span"} variant="body1" gutterBottom>
      <p
        style={{
          ...labelStyle,
          ...headingStyle
        }}
      >
        {text}
      </p>
    </Typography>
  );
};

export default QuestionLabel;
