import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ExportReactCSV } from "../common/ExportReactCSV";
import { useCompanyService } from "../../services/useCompanyService";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { isTrue, allMatching, isEmpty } from "../../common";
import CompanyUtil from "../../utils/CompanyUtil";
import DateTimeUtil from "../../utils/DateTimeUtil";
import { ROLES, BOOKING_STATES } from "../../constants";
import classnames from "classnames";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { grey } from "@material-ui/core/colors";
import addDays from 'date-fns/addDays';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  wrapper: {
    margin: "20px 0"
  },
  card: {
    maxHeight: "70vh",
    display: "grid",
    "grid-template-rows": "1fr 95px"
  },
  panelContent: {
    maxHeight: "70vh",
    overflow: "auto"
  },
  table: {
    paddingTop: 0,
    paddingBottom: 0
  },
  requestParams: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1.5fr",
    columnGap: "20px",
    rowGap: "20px",
    alignItems: "center"
  },
  requestParamsUser: {
    display: "grid",
    gridTemplateColumns: "1fr 0.5fr 0.5fr 1fr",
    columnGap: "20px",
    rowGap: "20px",
    alignItems: "center"
  },
  exportParams: {
    position: "sticky",
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gridColumnGap: "20px",
    alignItems: "center",
    padding: "20px"
  },
  "@media print": {
    requestParams: {
      display: "none"
    },
    exportParams: {
      display: "none"
    }
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  exportButtonContainer: {
    display: "grid",
    gridTemplateColumns: "8fr 8fr 3fr",
    margin: "5px 0px"
  },
  selected: {
    backgroundColor: "#efe8d0"
  },
  nowrap: {
    whiteSpace: "nowrap"
  }
}));

export default function ContactTracing({ locationOptions, company }) {
  const classes = useStyles();
  const localStorage = useLocalStorage();
  const companyService = useCompanyService();
  const { t } = useTranslation();

  const [coordinates, setCoordinates] = useState(null);
  const [coordinateOptions, setCoordinateOptions] = useState([]);
  const [startingBookingDate, setStartingBookingDate] = useState(new Date());
  const [endingBookingDate, setEndingBookingDate] = useState(
    addDays(new Date(), 7)
  );
  const [bookingLocation, setBookingLocation] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [traces, setTraces] = useState([]);
  const [traceLevel, setTraceLevel] = useState("");
  const [traceContact, setTraceContact] = useState(null);
  const [traceLocationName, setTraceLocationName] = useState("");
  const [traceConsolidated, setTraceConsolidated] = useState(false);

  const [visitorFirstName, setVisitorFirstName] = useState(null);
  const [visitorLastName, setVisitorLastName] = useState(null);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("ALL");

  const [loadingView, setLoadingView] = useState(false);

  const tracingHeaders = [
    { label: t("Reports.TraceLocation"), key: "coordinate" },
    { label: t("Commons.CheckInDate"), key: "date" },
    { label: t("Commons.Name"), key: "username" },
    { label: t("Commons.Email"), key: "email" },
    { label: `${t("Commons.Visitor")}?`, key: "isVisitor" }
  ];

  const tracingFilename = `Tracing Report (${DateTimeUtil.formatDateAndTime(
    new Date()
  )}).csv`;

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      const users = await companyService.getUsers();
      const currentUserId = localStorage.getUserID();
      const isGroupAdminOnly = isTrue(
        localStorage
          .getUserRoles()
          .some((role) => role === ROLES.USER_GROUP_ADMIN) &&
          localStorage.getUserRoles().length === 1
      );
      const currentUser = currentUserId
        ? users.find((user) => user.userId === currentUserId)
        : null;
      if (isGroupAdminOnly) {
        const userGroups = await companyService.getUserGroups();
        const managedGroups = userGroups
          .filter((g) => g.admins.includes(currentUser.email))
          .map((g) => g.userGroupId);
        const filteredUserList = allMatching(users, managedGroups, "groups");
        setUsers(filteredUserList);
      } else {
        setUsers(users);
      }
    };
    getUsers();
  }, []);

  const loadData = async () => {
    const coordinates = company.coordinateSystem;
    setCoordinates(coordinates);

    if (coordinates) {
      var coordinateOptions = [];
      for (var dim in coordinates) {
        var coordinateOption = {
          value: coordinates[dim],
          displayLabel: coordinates[dim]
        };
        coordinateOptions.push(coordinateOption);
      }
      setCoordinateOptions(coordinateOptions);

      if (coordinateOptions.length > 1) {
        setTraceLevel(coordinateOptions[coordinateOptions.length - 2]);
      } else {
        setTraceLevel(coordinateOptions[coordinateOptions.length - 1]);
      }
    }
  };

  const getContacts = async (
    startingBookingDate,
    endingBookingDate,
    location,
    userId,
    visitorFirstName,
    visitorLastName,
    status
  ) => {
    setLoadingView(true);
    const contacts = await companyService.getUserBookingContacts(
      startingBookingDate,
      endingBookingDate,
      location,
      userId,
      visitorFirstName,
      visitorLastName,
      status
    );

    for (var c in contacts) {
      contacts[c].traceLevel = traceLevel;
    }

    setLoadingView(false);
    setContacts(contacts);
    setTraces([]);
    setTraceContact(null);
    setTraceLocationName("");
    setTraceConsolidated(false);
  };

  const getContactTracesToBeDisplayed = async (
    startingBookingDate,
    endingBookingDate,
    locationId,
    userEmail,
    states
  ) => {
    const tracesData = await companyService.getUserBookingContactTraces(
      startingBookingDate,
      endingBookingDate,
      locationId,
      userEmail,
      states
    );

    return tracesData.map((trace) => {
      return {
        ...trace,
        coordinate: CompanyUtil.getStringForCoords(company, trace.coordinate),
        isVisitor: trace.isVisitor ? t("Commons.Yes") : ""
      };
    });
  };

  const getContactTraces = async (
    startingBookingDate,
    endingBookingDate,
    locationId,
    userEmail,
    states
  ) => {
    setLoadingView(true);
    const traces = await getContactTracesToBeDisplayed(
      startingBookingDate,
      endingBookingDate,
      locationId,
      userEmail,
      states
    );
    setLoadingView(false);
    setTraces(traces);
  };

  const getConsolidatedContactTraces = async (
    startingBookingDate,
    endingBookingDate,
    locationIds,
    userEmail,
    states
  ) => {
    setLoadingView(true);

    var emailList = [];
    var allTraces = [];
    for (var i in locationIds) {
      var locationId = locationIds[i];
      var traces = await getContactTracesToBeDisplayed(
        startingBookingDate,
        endingBookingDate,
        locationId,
        userEmail,
        states
      );

      // consolidate the trace records to be unique emails
      for (var t in traces) {
        var trace = traces[t];
        if (!emailList.includes(trace.email)) {
          allTraces.push(trace);
          emailList.push(trace.email);
        }
      }
    }

    setLoadingView(false);
    setTraces(allTraces);
  };

  const doContactTracing = async (contact) => {
    var traceLocationId = null;
    var traceLocationName = "";
    if (contact.coordinate[contact.traceLevel.value]) {
      traceLocationId = contact.coordinate[contact.traceLevel.value].locationId;
      traceLocationName =
        contact.coordinate[contact.traceLevel.value].displayName;
    }

    setTraceContact(contact);
    setTraceLocationName(traceLocationName);

    await getContactTraces(
      contact.startingBookingDate,
      contact.endingBookingDate,
      traceLocationId,
      contact.email,
      [BOOKING_STATES.CHECKED_IN, BOOKING_STATES.CHECKED_OUT]
    );
  };

  const doConsolidatedContactTracing = async (contacts) => {
    var traceLocationIds = [];
    var traceLocationName = traceLevel.displayLabel;

    // find all location Ids at the required trace level
    for (var c in contacts) {
      var contact = contacts[c];
      if (contact.coordinate[traceLevel.value]) {
        var traceLocationId = contact.coordinate[traceLevel.value].locationId;
        if (!traceLocationIds.includes(traceLocationId)) {
          traceLocationIds.push(traceLocationId);
        }
      }
    }

    setTraceContact(contacts[0]);
    setTraceLocationName(traceLocationName);
    setTraceConsolidated(true);

    await getConsolidatedContactTraces(
      contact.startingBookingDate,
      contact.endingBookingDate,
      traceLocationIds,
      contact.email,
      [BOOKING_STATES.CHECKED_IN, BOOKING_STATES.CHECKED_OUT]
    );
  };

  const isContactLocationsButtonDisabled = () => {
    return (
      loadingView ||
      (isEmpty(selectedUser) &&
        isEmpty(visitorFirstName) &&
        isEmpty(visitorLastName))
    );
  };

  return (
    <>
      <Typography variant="h3">{t("Reports.ContactTracingReports")}</Typography>
      <div
        className={classnames({
          [classes.requestParams]: true,
          [classes.wrapper]: true
        })}
      >
        <KeyboardDatePicker
          disableToolbar
          inputVariant="outlined"
          format="MM/dd/yyyy"
          label={t("Commons.StartDate")}
          name="startingBookingDate"
          maxDate={endingBookingDate}
          value={startingBookingDate}
          onChange={(date) => {
            setStartingBookingDate(date);
          }}
        />
        <KeyboardDatePicker
          disableToolbar
          inputVariant="outlined"
          format="MM/dd/yyyy"
          label={t("Commons.EndDate")}
          name="endingBookingDate"
          minDate={startingBookingDate}
          value={endingBookingDate}
          onChange={(date) => {
            setEndingBookingDate(date);
          }}
        />
        <Autocomplete
          id="bookingLocation"
          defaultValue={bookingLocation}
          options={locationOptions}
          value={bookingLocation}
          getOptionLabel={(option) => option.displayLabel}
          onChange={(event, newValue) => {
            setBookingLocation(newValue);
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Commons.Location")}
              variant="outlined"
            />
          )}
        />
      </div>
      <Typography>{t("Reports.ContactUserFieldsOption")}:</Typography>
      <div
        className={classnames({
          [classes.requestParamsUser]: true,
          [classes.wrapper]: true
        })}
      >
        <Autocomplete
          id="users"
          defaultValue={selectedUser}
          options={users}
          value={selectedUser}
          getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
          onChange={(event, value) => {
            setSelectedUser(value);
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Commons.User")}
              variant="outlined"
            />
          )}
        />
        <TextField
          className={classes.textField}
          variant="outlined"
          label={t("Visitors.VisitorFirstName")}
          name="firstName"
          value={visitorFirstName}
          onChange={(e) => setVisitorFirstName(e.target.value)}
        />
        <TextField
          className={classes.textField}
          variant="outlined"
          label={t("Visitors.VisitorLastName")}
          name="lastName"
          value={visitorLastName}
          onChange={(e) => setVisitorLastName(e.target.value)}
        />

        <Button
          variant="contained"
          color="primary"
          disabled={isContactLocationsButtonDisabled()}
          onClick={() => {
            getContacts(
              startingBookingDate,
              endingBookingDate,
              bookingLocation ? bookingLocation.locationId : null,
              selectedUser ? selectedUser.userId : null,
              visitorFirstName,
              visitorLastName,
              selectedStatus
            );
          }}
        >
          {t("Reports.ContactLocations")}
          {loadingView && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </div>

      <Typography variant="h4">{t("Reports.Step1")}</Typography>
      {coordinates && contacts && (
        <Card className={classes.card}>
          <div className={classes.panelContent}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label={t("Reports.Contacts")}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Commons.Name")}</TableCell>
                    <TableCell>{t("Commons.Location")}</TableCell>
                    <TableCell>{t("Reports.TraceLevel")}</TableCell>
                    <TableCell>{t("Reports.Tracing")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts.length > 0 ? (
                    contacts.map((contact, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.nowrap}>
                          {contact.username}
                          {contact.visitor && (
                            <Typography
                              variant="body1"
                              component="span"
                              className={classes.visitorName}
                            >
                              <br />({t("Commons.Visitor")}:{" "}
                              {contact.visitor.firstName}&nbsp;
                              {contact.visitor.lastName})
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {CompanyUtil.getStringForCoords(
                            company,
                            contact.coordinate
                          )}
                        </TableCell>

                        <TableCell>
                          <Autocomplete
                            id="traceLevel"
                            defaultValue={contact.traceLevel}
                            options={coordinateOptions}
                            value={contact.traceLevel}
                            getOptionLabel={(option) => option.displayLabel}
                            onChange={(event, newValue) => {
                              contact.traceLevel = newValue;
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Reports.TraceLevel")}
                                variant="outlined"
                              />
                            )}
                          />
                        </TableCell>

                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={loadingView}
                            onClick={() => {
                              doContactTracing(contact);
                            }}
                          >
                            {t("Reports.TraceThis")}
                            {loadingView && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align={"center"} colSpan={5}>
                        {t("Reports.NoContactFound")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Card>
      )}

      <div
        className={classnames({
          [classes.requestParams]: true,
          [classes.wrapper]: true
        })}
      >
        <div></div>
        <Autocomplete
          id="traceLevel"
          disabled={loadingView || 0 === contacts.length}
          defaultValue={traceLevel}
          options={coordinateOptions}
          value={traceLevel}
          getOptionLabel={(option) => option.displayLabel}
          onChange={(event, newValue) => {
            setTraceLevel(newValue);
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Reports.ConsolidatedTraceLevel")}
              variant="outlined"
            />
          )}
        />

        <Button
          variant="contained"
          color="primary"
          disabled={loadingView || 0 === contacts.length}
          onClick={() => {
            doConsolidatedContactTracing(contacts);
          }}
        >
          {t("Reports.ConsolidatedContactsTracing")}
          {loadingView && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </div>

      <br />
      <Typography variant="h4">{t("Reports.Step2")}</Typography>

      {traceContact && traceConsolidated ? (
        <Typography variant="h6">
          <i>{t("Reports.For")}</i> {traceContact.username}{" "}
          <i>{t("Reports.Between")}</i> [
          {traceContact.startingBookingDate.toDateString()}{" "}
          <i>{t("Reports.To")}</i>{" "}
          {traceContact.endingBookingDate.toDateString()}]
          <i>{t("Reports.ConsolidatedWithAll")}</i> {traceLevel.displayLabel}
        </Typography>
      ) : (
        <div />
      )}

      {traceContact && !traceConsolidated ? (
        <Typography variant="h6">
          <i>{t("Reports.For")}</i> {traceContact.username}{" "}
          <i>{t("Reports.Between")}</i> [
          {traceContact.startingBookingDate.toDateString()}{" "}
          <i>{t("Reports.To")}</i>{" "}
          {traceContact.endingBookingDate.toDateString()}]
          <i>{t("Reports.At")}</i> {traceContact.traceLevel.displayLabel}:{" "}
          {traceLocationName}
        </Typography>
      ) : (
        <div />
      )}

      <div className={classes.exportButtonContainer}>
        <div></div>
        <div></div>
        <ExportReactCSV
          csvData={traces}
          fileName={tracingFilename}
          headers={tracingHeaders}
          disabled={loadingView || 0 === traces.length}
        />
      </div>
      {coordinates && contacts && (
        <Card className={classes.card}>
          <div className={classes.panelContent}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label={t("Reports.Contacts")}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Reports.TraceLocation")}</TableCell>
                    <TableCell>{t("Commons.CheckInDate")}</TableCell>
                    <TableCell>{t("Commons.Name")}</TableCell>
                    <TableCell>{t("Commons.Email")}</TableCell>
                    <TableCell>{t("Commons.Visitor")}?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {traces.length > 0 ? (
                    traces.map((trace, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.nowrap}>
                          {" "}
                          {trace.coordinate}{" "}
                        </TableCell>
                        <TableCell className={classes.nowrap}>
                          {" "}
                          {trace.date}{" "}
                        </TableCell>
                        <TableCell className={classes.nowrap}>
                          {" "}
                          {trace.username}{" "}
                        </TableCell>
                        <TableCell className={classes.nowrap}>
                          {" "}
                          {trace.email}{" "}
                        </TableCell>
                        <TableCell className={classes.nowrap}>
                          {" "}
                          {trace.isVisitor}{" "}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align={"center"} colSpan={5}>
                        {t("Reports.NoContactTraceFound")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Card>
      )}
    </>
  );
}
