import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { listTimeZones, getUTCOffset, findTimeZone } from "timezone-support";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";

const TimeZone = React.memo(
  ({
    className,
    company,
    formikProps,
    setTimeZoneField,
    ...rest
  }) => {
    const tzList = listTimeZones();
    const { t } = useTranslation();
    let timeZone = company.timeZone;

    if (!timeZone) {
      const toronto = findTimeZone("America/Toronto");
      const date = new Date();
      const defaultTimeZone = getUTCOffset(date, toronto);
      timeZone = defaultTimeZone;
    }

    return (
      <Card className={className} {...rest}>
        <CardHeader title={t("CompanyInfo.TimeZone")} />
        <CardContent>
          <Grid item>
            <Autocomplete
              id="time_zone_offset"
              defaultValue={timeZone.region}
              options={tzList}
              value={timeZone.region}
              getOptionLabel={(option) => option}
              onChange={setTimeZoneField}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={formikProps.errors.timezone}
                  label={t("CompanyInfo.ChooseTimeZone")}
                  margin="normal"
                  variant="outlined"
                  helperText={formikProps.errors.timezone}
                />
              )}
            />
          </Grid>
          <Typography>{t("CompanyInfo.DefaultLocations")}</Typography>
        </CardContent>
      </Card>
    );
  }
);

TimeZone.propTypes = {
  className: PropTypes.string
};

export default TimeZone;
