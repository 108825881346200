import { useHttpService } from './useHttpService';
import { useLocalStorage } from "../hooks/useLocalStorage";
import { hiveIamUrl } from "../firebase";

export const useHiveIamService = () => {
  const BASE_URL = hiveIamUrl;
  const SET_NEW_PASSWORD_URL = "/accessmanagement/run/user/password";

  const localStorage = useLocalStorage();
  const companyId = localStorage.getCompanyID();
  const hiveIamServiceKey = localStorage.getHiveIamServiceKey();
  const httpService = hiveIamServiceKey ? new useHttpService(BASE_URL, { Authorization: `Bearer ${hiveIamServiceKey}` }) : null;

  const saveNewUserPassword = async (requestBody) => {
    let response = { success: false, message: "" };
    if (httpService) {
      try {
        const results = await httpService.doPost(`${SET_NEW_PASSWORD_URL}?company=${companyId}`, requestBody);
        response.success = true;
        response.data = results.data;
      } catch (error) {
        response.message = `saveNewUserPassword has failed. ${error}`;
      }
      return response;

    }
    response.message = "Company has no access to Hive API Service.";
    return response;
  };

  return {
    saveNewUserPassword: saveNewUserPassword
  };
};
