import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import { isEmpty, isValidEmail } from "../../common";
import { TextField, Tooltip } from "@material-ui/core";

const UserSelectorPlusEmailInput = ({
  controller,
  labelKey,
  transform,
  disabled = false,
  contentList,
  labelFormater,
  wrapEmail = true
}) => {
  const [t] = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    const _selectedItems = [];
    controller.value &&
      controller.value.map((u) =>
        _selectedItems.push(transform.fromFireStore(u, contentList))
      );
    setSelectedItems(_selectedItems);
  }, [contentList, controller]);

  const optionLabelFormater = (user) => {
    return labelFormater && labelFormater(user) || user.displayName || user.email;
  };

  const inputFieldFormater = (user) => {
    if (!isEmpty(user.displayName)) {
      return (
        <Tooltip title={user.email}>
          <span>{user.displayName}</span>
        </Tooltip>
      );
    }
    return user.email;
  };

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        label={t(labelKey)}
        variant="outlined"
        onKeyDown={handleOnSelectValue}
      />
    );
  };

  const checkKeyCode = (keyCode) => {
    const keyCodes = [
      13, // enter
      9, // tab
      32, // space
      188 // comma
    ];
    return keyCodes.includes(keyCode);
  };

  const handleOnSelectValue = (event, newValue) => {
    if (checkKeyCode(event.keyCode) && event.target.value) {
      let value = event.target.value.toLowerCase();
      let preSelection = contentList.filter(
        (user) =>
          user.email.toLowerCase().includes(value) ||
          `${user.firstName} ${user.lastName}`.toLowerCase().includes(value)
      );
      if (isEmpty(preSelection) && isValidEmail(value)) {
        (wrapEmail && preSelection.push({ email: value })) ||
          preSelection.push(value);
      }
      setSelectedItems([...selectedItems, ...preSelection]);
      controller.set(
        transform.toFireStore([...selectedItems, ...preSelection])
      );
    } else if (newValue) {
      setSelectedItems(newValue);
      controller.set(transform.toFireStore(newValue));
    }
  };

  return (
    <Tooltip title={t("CompanyInfo.EmailInputTooltip")} arrow>
      <Autocomplete
        freeSolo
        fullWidth
        disabled={disabled}
        multiple
        id={`select${t(labelKey)}Widget`}
        defaultValue={selectedItems}
        options={contentList}
        value={selectedItems}
        getOptionLabel={optionLabelFormater}
        onChange={handleOnSelectValue}
        filterSelectedOptions
        renderInput={renderInput}
        renderOption={inputFieldFormater}
      />
    </Tooltip>
  );
};

UserSelectorPlusEmailInput.propTypes = {
  controller: PropTypes.object.isRequired,
  labelKey: PropTypes.string.isRequired,
  transform: PropTypes.object.isRequired,
  contentList: PropTypes.array.isRequired,
  disabled: PropTypes.bool
};

export default UserSelectorPlusEmailInput;
