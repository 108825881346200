import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    background: "white"
  }
}));

const SimpleSelector = ({
  disable,
  value,
  onChangeFunc,
  label,
  options,
  fieldId,
  fullWidth = true,
  className = {}
}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <>
      <FormControl fullWidth={fullWidth} variant="outlined" className={className}>
        <InputLabel disabled={disable}>{label}</InputLabel>
        <Select
          variant="outlined"
          disabled={disable}
          className={classes.filter}
          value={!!value && value.id ? value.id : ""}
          onChange={(e) =>
            onChangeFunc(
              fieldId,
              options.filter((opt) => opt.id === e.target.value)[0]
            )
          }
          label={label}
        >
          {options.map((opt) => (
            <MenuItem key={opt.id} value={opt.id}>
              {t(opt.displayKey)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

SimpleSelector.propTypes = {
  disable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChangeFunc: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  value: PropTypes.object,
  className: PropTypes.object
};

export default SimpleSelector;