import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Tooltip
} from "@material-ui/core";
import classnames from "classnames";
import CompanyUtil from "../../utils/CompanyUtil";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { setErrorMessage, setSuccessMessage } from "../../actions/index";
import { useCompanyService } from "../../services/useCompanyService";
import { useLocalStore } from "../../hooks/useLocalStore";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { BOOKING_STATES } from "../../constants";
import { grey } from "@material-ui/core/colors";
import addDays from 'date-fns/addDays';
import UserSelector from "../../components/Selectors/UserSelector";
import UserGroupSelector from "../../components/Selectors/UserGroupSelector";
import LocationSelector from "../../components/Selectors/LocationSelector";
import StatusSelector from "../../components/Selectors/StatusSelector";
import AlertPopup from "../../components/AlertPopup/AlertPopup";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%"
  },
  wrapper: {
    margin: "20px 0"
  },
  card: {
    maxHeight: "70vh"
  },
  exportCard: {
    padding: "1rem 1rem 0 1rem",
    marginTop: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1rem"
  },
  cardChildVertical: {
    display: "flex",
    flexDirection: "column",
    flex: "1"
  },
  cardChild: {
    flex: "2",
    alignSelf: "flex-start"
  },
  panelContent: {
    maxHeight: "70vh",
    overflow: "auto"
  },
  table: {
    paddingTop: 0,
    paddingBottom: 0
  },
  requestParams: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1rem"
  },
  filterItem: {
    flex: "1",
    alignSelf: "center"
  },
  filterItemHeavy: {
    flex: "2",
    alignSelf: "center"
  },
  exportParams: {
    position: "sticky",
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gridColumnGap: "20px",
    alignItems: "center",
    padding: "20px"
  },
  exportButtonSection: {
    position: "sticky",
    display: "grid",
    alignItems: "center"
  },
  "@media print": {
    requestParams: {
      display: "none"
    },
    exportParams: {
      display: "none"
    }
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  selectButtonContainer: {
    maxWidth: "960px",
    margin: "20px 0",
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr",
    gridGap: "20px",
    "&>div": {
      display: "flex",
      "&>button": {
        marginRight: "13px"
      }
    },
    "&>div:last-of-type": {
      justifyContent: "flex-end",
      "&>button:last-of-type": {
        marginRight: "0"
      }
    }
  },
  selected: {
    backgroundColor: "#efe8d0"
  },
  nowrap: {
    whiteSpace: "nowrap"
  }
}));

export default function General() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyService = useCompanyService();
  const localStore = useLocalStore();

  const [startingBookingDate, setStartingBookingDate] = useState(new Date());
  const [endingBookingDate, setEndingBookingDate] = useState(
    addDays(new Date(), 7)
  );
  const [bookingLocation, setBookingLocation] = useState(null);
  const [email, setEmail] = useState("");
  const [bookings, setBookings] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [loadingView, setLoadingView] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [includeAuditInExport, setIncludeAuditInExport] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [init, setInit] = useState(false);

  useEffect(() => {
    !init && loadData();
    selectedUsers.length > 1 && setSelectedStatuses([]);
  }, [selectedUsers]);

  const [usersList, setUsersList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);

  const company = localStore.getCompany();
  const coordinates = company.coordinateSystem;

  const loadData = useCallback(async () => {
    const _users = await companyService.getUsersFromDropdownListCollection();
    const _locations = await companyService.getLocationsFromDropdownListCollection();
    setUsersList(_users);
    setLocationsList(_locations);
    setInit(true);
  }, [companyService]);

  const shouldEnableStatusFilter = () => {
    return selectedUsers.length < 2;
  };

  const getBookings = async (
    startingBookingDate,
    endingBookingDate,
    location,
    userId,
    groupId,
    statuses
  ) => {
    setLoadingView(true);
    let bookings = await companyService.getBookings(
      startingBookingDate,
      endingBookingDate,
      location,
      userId,
      (statuses && statuses.length > 0) ? statuses : "ALL"
    );

    if (groupId) {
      bookings = companyService.getBookingsForGroup(bookings, groupId);
    }
    setBookings(bookings);
    setLoadingView(false);
  };

  const onToggleAuditCheck = () => {
    setIncludeAuditInExport(!includeAuditInExport);
  };

  const onUpdateSelectedUsers = (newValues) => {
    if (newValues.length > 10) {
      setOpenWarning(true);
      return;
    }
    setSelectedUsers(newValues);
  };

  const getDateWithMonthOffset = (date, add) => {
    let offsetDate = new Date(date.getTime());

    if (add) {
      offsetDate.setDate(date.getDate() + 31);
    } else {
      offsetDate.setDate(date.getDate() - 31);
    }

    return offsetDate;
  };

  const locationController = {
    value: bookingLocation,
    setter: setBookingLocation
  };

  const userController = {
    value: selectedUsers,
    setter: onUpdateSelectedUsers
  };

  const groupController = {
    value: selectedGroup,
    setter: setSelectedGroup
  };

  const getSelectedUsers = () => {
    return selectedUsers.length > 1 ? selectedUsers :
      selectedUsers.length === 1 ? selectedUsers[0] :
        null;
  };

  return (
    <>
      <Typography variant="h3">{t("Reports.OccupationReports")}</Typography>
      <div
        className={classnames({
          [classes.requestParams]: true,
          [classes.wrapper]: true
        })}
      >
        <Tooltip title={t("Reports.GeneralReportDatePickerTooltip")} placement="bottom">
          <KeyboardDatePicker
            className={classes.filterItem}
            disableToolbar
            inputVariant="outlined"
            format="MM/dd/yyyy"
            label={t("Commons.StartDate")}
            name="startingBookingDate"
            maxDate={endingBookingDate}
            minDate={getDateWithMonthOffset(endingBookingDate, false)}
            value={startingBookingDate}
            onChange={(date) => {
              setStartingBookingDate(date);
            }}
          />
        </Tooltip>
        <Tooltip title={t("Reports.GeneralReportDatePickerTooltip")} placement="bottom">
          <KeyboardDatePicker
            className={classes.filterItem}
            disableToolbar
            inputVariant="outlined"
            format="MM/dd/yyyy"
            label={t("Commons.EndDate")}
            name="endingBookingDate"
            minDate={startingBookingDate}
            maxDate={getDateWithMonthOffset(startingBookingDate, true)}
            value={endingBookingDate}
            onChange={(date) => {
              setEndingBookingDate(date);
            }}
          />
        </Tooltip>
        <div className={classes.filterItemHeavy}>
          <LocationSelector
            contentList={locationsList}
            company={company}
            labelKey={"Commons.Location"}
            controller={locationController}
            sortLocationList={true}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          marginBottom: "1rem"
        }}
      >
        <div className={classes.filterItem}>
          <UserSelector
            contentList={usersList}
            labelKey={"Commons.User"}
            controller={userController}
          />
        </div>
        <div className={classes.filterItem}>
          <UserGroupSelector
            labelKey={t("UserGroups.UserGroup")}
            controller={groupController}
            includeAccessPolicyGroup={true}
          />
        </div>
        <div className={classes.filterItemHeavy}>
          <StatusSelector
            enable={shouldEnableStatusFilter()}
            style={{
              maxHeight: "53px"
            }}
            controller={{
              value: selectedStatuses,
              setter: setSelectedStatuses
            }}
            statusObj={BOOKING_STATES}
            remove={["PENDING_APPROVAL", "DENIED_APPROVAL"]}
          />
        </div>
      </div>
      <Button
        variant="contained"
        style={{ marginBottom: "1rem" }}
        color="primary"
        disabled={loadingView}
        onClick={() => {
          getBookings(
            startingBookingDate,
            endingBookingDate,
            bookingLocation ? bookingLocation : null,
            getSelectedUsers(),
            selectedGroup ? selectedGroup : null,
            selectedStatuses
          );
        }}
      >
        {t("Reports.ViewReport")}
        {loadingView && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Button>

      {coordinates && bookings && (
        <Card className={classes.card}>
          <div className={classes.panelContent}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label={t("Reports.Bookings")}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Commons.Name")}</TableCell>
                    <TableCell>{t("Commons.Visitor")}</TableCell>
                    <TableCell>{t("Commons.Date")}</TableCell>
                    <TableCell>{t("Commons.Email")}</TableCell>
                    <TableCell>{t("Commons.Location")}</TableCell>
                    <TableCell>{t("Commons.Status")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookings.length > 0 ? (
                    bookings.map((booking, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.nowrap}>
                          {" "}
                          {booking.username}{" "}
                        </TableCell>
                        <TableCell className={classes.nowrap}>
                          {" "}
                          {booking.visitor &&
                            booking.visitor.firstName +
                              " " +
                              booking.visitor.lastName}
                        </TableCell>
                        <TableCell>
                          {booking.date.toDate().toLocaleDateString("en-US", {
                            timeZone: booking.timeZone ? booking.timeZone.region : company.timeZone.region,
                            weekday: "short",
                            month: "short",
                            day: "numeric",
                            year: "numeric"
                          })}
                        </TableCell>
                        <TableCell>{booking.email}</TableCell>
                        <TableCell>
                          {CompanyUtil.getStringForBookingCoords(
                            coordinates,
                            booking.coordinate
                          )}
                        </TableCell>
                        <TableCell>{booking.state}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align={"center"} colSpan={5}>
                        {t("Reports.NoBookingsFound")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Card>
      )}
      {bookings.length > 0 && (
        <Card className={classes.exportCard}>
          <div className={classes.cardChild}>
            <TextField
              fullWidth
              variant="outlined"
              label={t("Commons.Email")}
              name="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
          <div className={classes.cardChildVertical}>
            <Button
              variant="contained"
              color="primary"
              disabled={!email || loadingExport}
              onClick={() => {
                setLoadingExport(true);
                companyService
                  .exportOccupantReport(
                    startingBookingDate,
                    endingBookingDate,
                    email,
                    coordinates,
                    bookingLocation ? bookingLocation : null,
                    getSelectedUsers(),
                    selectedStatuses,
                    selectedGroup,
                    includeAuditInExport
                  )
                  .then(() => {
                    dispatch(
                      setSuccessMessage(
                        t("Success.EmailReport", { email: email })
                      )
                    );
                    setLoadingExport(false);
                    setEmail("");
                  }).catch((error) => {
                    dispatch(
                      setErrorMessage(
                        t("Errors.EmailReport")
                      )
                    );
                    console.log(t("Errors.EmailReport"), error);
                    setLoadingExport(false);
                    setEmail("");
                  });
              }}
            >
              {t("Reports.ExportReport")}
              {loadingExport && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <FormControlLabel
              style={{ marginTop: "-0.25rem" }}
              control={
                <Checkbox
                  checked={includeAuditInExport}
                  color="primary"
                  onChange={() => onToggleAuditCheck()}
                />
              }
              label="Include Audit Information"
            />
          </div>
        </Card>
      )}
      {
        openWarning && (
          <AlertPopup
            title={t("Reports.Warning")}
            warning={t("Reports.MaxAmount")}
            warningInfo={t("Reports.MaxAmountMsg")}
            onConfirm={() => setOpenWarning(false)}
          />
        )
      }

    </>
  );
}
