import { SET_USER_INFO } from "../actions/index";

const userInfoReducer = (
  state = { userId: "", displayName: "", email: "", roles: [] },
  action
) => {
  switch (action.type) {
    case SET_USER_INFO: {
      return {...state, ...action.payload};
    }
    default: {
      return state;
    }
  }
};

export default userInfoReducer;
