import React, { useState } from "react";
import {
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import AddEditDialog from "../../components/AddEditDialog/AddEditDialog";
import { useCompanyService } from "../../services/useCompanyService";
import { isEmpty } from "../../common";
import { ToggleScheduleDays } from "../UserGroups/ToggleScheduleDays";
import { ErrorAlert } from "../common/ErrorAlert";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import {
  setSuccessMessage,
  setErrorMessage
} from "../../actions/index";

const useStyles = makeStyles((theme) => ({
  schedule: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center"
  },
  weekLabel: {
    marginRight: theme.spacing(2)
  },
  checkBoxContainer: {
    marginBottom: theme.spacing(1)
  },
  textGrey: {
    color: "LightGrey"
  },
  text: {
    color: "Black"
  }
}));

export const EditReservation = ({
  location,
  userGroup,
  existingReservation,
  close
}) => {
  const [t] = useTranslation();
  const companyService = useCompanyService();
  const classes = useStyles();
  const dispatch = useDispatch();

  const SCHEDULE_WEEKS = 4;
  const SCHEDULE_DEFAULT_WEEKLY_BITMAP = "1111111";
  const DAYS = [
    {
      key: "sunday",
      label: t("UserGroups.CommonSundayAbbrev")
    },
    {
      key: "monday",
      label: t("UserGroups.CommonMondayAbbrev")
    },
    {
      key: "tuesday",
      label: t("UserGroups.CommonTuesdayAbbrev")
    },
    {
      key: "wednesday",
      label: t("UserGroups.CommonWednesdayAbbrev")
    },
    {
      key: "thursday",
      label: t("UserGroups.CommonThursdayAbbrev")
    },
    {
      key: "friday",
      label: t("UserGroups.CommonFridayAbbrev")
    },
    {
      key: "saturday",
      label: t("UserGroups.CommonSaturdayAbbrev")
    }
  ];

  const [reservation, setReservation] = useState(!isEmpty(existingReservation) ? existingReservation : {
    name: "",
    itemToBeReserved: {
      collection: "Locations",
      id: location.locationId
    },
    owner: {
      collection: "Groups",
      id: userGroup.userGroupId
    },
    reservationBitmaps: [
      "1111111"
    ]
  });

  const reservationExists = !isEmpty(existingReservation);
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enable, setEnable] = useState(reservationExists);

  const onChange = (field, value) => {
    let _reservation = {...reservation};
    _reservation[field] = value;
    setReservation(_reservation);
  };

  const onScheduleBitmapChange = (index, scheduleBitmapStr) => {
    let _reservation = { ...reservation };
    _reservation["reservationBitmaps"][index] = scheduleBitmapStr;
    setReservation(_reservation);
  };

  const onAddScheduleBitmap = () => {
    let _reservation = { ...reservation };
    _reservation.reservationBitmaps.push(SCHEDULE_DEFAULT_WEEKLY_BITMAP);
    setReservation(_reservation);
  };

  const onDeleteScheduleBitmap = (index) => {
    let _reservation = { ...reservation };
    _reservation.reservationBitmaps.splice(index, 1);
    setReservation(_reservation);
  };

  const runFormValidadtion = () => {
    const _errorMessages = [];

    if (isEmpty(reservation.name) && enable) {
      _errorMessages.push(t("Reservation.ReservationMustHaveAName"));
    }

    return _errorMessages;
  };

  const save = async () => {
    const _errorMessages = runFormValidadtion();
    if (_errorMessages.length === 0) {
      setLoading(true);

      if (reservationExists || enable) {
        const reservationFuncs = [companyService.updateReservation, companyService.deleteReservation, companyService.createReservation];
        const mode = reservationExists ? (enable ? 0 : 1) : 2;

        await reservationFuncs[mode](reservation).then((response) => {
          if (response.data.success === false) {
            const details = i18next.exists(
              "Errors.Cloud." + response.data.message
            )
              ? t("Errors.Cloud." + response.data.message)
              : "";
            dispatch(setErrorMessage(t("Errors.UpdateReservation") + details));
          } else {
            if (mode == 2) {
              reservation.reservationId = response.data.reservationId;
            }
            dispatch(setSuccessMessage(t("Success.UpdateReservation")));
          }
        }).catch((error) => {
          dispatch(setErrorMessage(error));
          console.log("error occurred trying to update this location:", error);
        }).finally(() => {
          setLoading(false);
          close(mode, reservation);
        });
      } else {
        close();
      } 
    }
    setErrorMessages(_errorMessages);
  };

  return (
    <AddEditDialog
      createMode={false}
      title={t("Locations.ReservationFor", { locationName: location.displayName })}
      onSave={save}
      onCancel={close}
      loading={loading}
    >
      {errorMessages.length > 0 && (
        <ErrorAlert
          errorMessages={errorMessages}
          onCloseAlert={setErrorMessages}
        />
      )}
      <Grid item sm={12}>
        <Typography variant="h4">
          {userGroup.name}
        </Typography>
      </Grid>
      <Grid item sm={12} className={classes.checkBoxContainer}>
        <FormControlLabel 
          control={
            <Checkbox 
              color="primary" 
              className={classes.checkBox} 
              checked={enable} 
              onChange={(event) => setEnable(event.target.checked)}
            />} 
          label={t("Locations.EnableScheduledReservation")}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          disabled={!enable}
          variant="outlined"
          label={t("Commons.Name")}
          value={reservation.name}
          fullWidth
          onChange={(e) => onChange("name", e.target.value)}
        />
      </Grid>
      <Grid item sm={true}>
        <div className={classes.buttonGroups}>
          {reservation.reservationBitmaps.map((bitmap, index) => (
            <div key={`schedule${index}`} className={classes.schedule}>
              <div className={classes.weekLabel}>
                <Typography variant="h6" className={!enable ? classes.textGrey : classes.text}>
                  {t("Locations.Week", { weekNum: index + 1 })}
                </Typography>
              </div>
              <div>
                <ToggleScheduleDays
                  disable={!enable}
                  customColor="primary"
                  customDays={DAYS}
                  scheduleBitmapLength={reservation.reservationBitmaps.length}
                  scheduleBitmapIndex={index}
                  scheduleBitmap={bitmap}
                  scheduleWeeks={SCHEDULE_WEEKS}
                  onScheduleBitmapChange={onScheduleBitmapChange}
                  onAddScheduleBitmap={onAddScheduleBitmap}
                  onDeleteScheduleBitmap={onDeleteScheduleBitmap}
                />
              </div>
            </div>
          ))}
        </div>
      </Grid>
    </AddEditDialog>
  );
};