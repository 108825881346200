import { SET_COMPANY_INFO } from "../actions/index";

const companyInfoReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case SET_COMPANY_INFO: {
      return {...state, ...action.payload};
    }
    default: {
      return state;
    }
  }
};

export default companyInfoReducer;
