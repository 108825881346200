import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import { 
  Dialog, 
  DialogContent, 
  DialogTitle,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Button,
  DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import typography from "../../theme/typography";
import { useTranslation } from "react-i18next";
import { ErrorAlert } from "../../views/common/ErrorAlert";
import LoadingButton from "../LoadingButton/LoadingButton";
import SearchBar from "../SearchBar/SearchBar";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  title: {
    "& h2": {
      display: 'flex',
      justifyContent: 'space-between',
      ...typography.h3
    }
  },
  paper: {
    width: '100%',
    overflow: 'hidden'
  },
  tableContainer: {
    maxHeight: 400,
    minHeight: 400
  },
  table: {
    minWidth: 500
  },
  searchBar: {
    marginBottom: theme.spacing(2)
  },
  actions: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(.7)
  },
  error: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const QuickAddDialog = ({
  title = "",
  errorMessages = [],
  setErrorMessages,
  optionsList,
  onAdd,
  onRemove,
  innerText,
  optionsFilter,
  isOptionAdd,
  columns,
  onClose
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  const [filteredOptions, setFilteredOptions] = useState(optionsList ? optionsList : []);
  const [searchWord, setSearchWord] = useState("");

  useEffect(() => {
    filterSearchOptions(searchWord);
  }, [optionsList]);

  const filterSearchOptions = (search) => {
    setSearchWord(search);
    let _filteredOptions = optionsList.filter((options) => optionsFilter(options, search));
    setFilteredOptions(_filteredOptions);
  };

  const renderAction = (option) => {
    return isOptionAdd(option) ? (
      <LoadingButton
        icon={<AddIcon />}
        onClick={ async () => await onAdd(option)}
        loadingSize={24}
      />
    ) : (
      <LoadingButton
        icon={<RemoveIcon />}
        onClick={ async () => await onRemove(option)}
        loadingSize={24}
      />
    );
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm" className={classes.root}>
      <DialogTitle className={classes.title}>
        {t("Commons.QuickAddRemove") + " " + title}
      </DialogTitle>
      <DialogContent>
        <SearchBar onSearch={filterSearchOptions} innerText={innerText} className={classes.searchBar}/>
        {errorMessages.length > 0 && setErrorMessages && (
          <div className={classes.error}>
            <ErrorAlert errorMessages={errorMessages} onCloseAlert={() => setErrorMessages([])} />
          </div>
        )}
        <Paper className={classes.paper}>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader className={classes.table} aria-label="quick add table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell align="right">{t("Commons.AddRemove")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOptions.map((option, index) => (
                  <TableRow
                    key={`rowNum${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {columns.map((column) => (
                      <TableCell key={column.id} component="th" scope="row">{column.getter(option)}</TableCell>
                    ))}
                    <TableCell key={"action"} align="center">{renderAction(option)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose}>{t("Commons.Close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

QuickAddDialog.proptype = {
  optionsList: PropType.array.isRequired,
  columns: PropType.array.isRequired,
  isOptionAdd: PropType.func.isRequired,
  getOptionName: PropType.func.isRequired,
  optionsFilter: PropType.func.isRequired,
  onAdd: PropType.func.isRequired,
  onRemove: PropType.func.isRequired,
  onClose: PropType.func.isRequired
};

export default QuickAddDialog;