import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useUserService } from "../../services/useUserService";
import { isEmpty } from "../../common";
import DeleteIcon from "@material-ui/icons/Delete";
import InsertIconOn from "@material-ui/icons/AddLocation";
import InsertIconOff from "@material-ui/icons/AddLocationOutlined";
import { CircularProgress, Button, TextField } from "@material-ui/core";
import { Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  buttonProgress: {
    color: "primary",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  locationEditGrid: {
    paddingTop: "2px",
    marginBottom: "2px"
  },
  locationEditItem: {
    margin: "2px"
  }
}));

export const GeoMarkerEditPopup = ({
  company,
  item,
  itemPoint,
  saveEditedLocation,
  removeEditedLocation,
  selectedMode,
  handleSelectedChange
}) => {
  const userService = useUserService();

  const [displayName, setDisplayName] = useState(item.displayName);
  const [isWorking, setIsWorking] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (item.locationImages) {
      let _imagePrefix = "Companies/" + company.companyId + "/";
      let _imageUrl = _imagePrefix + item.locationImages[0].imagePath.path;
      userService.getFloorMapUrl(_imageUrl).then((url) => {
        setImageUrl(url);
      });
    }
  });

  // const displayLocationAmenities = (location) => {
  //   let _amenities = [];
  //   if (!isEmpty(location.properties)) {
  //     if (!isEmpty(location.properties.amenities)) {
  //       let amenities = location.properties.amenities;
  //       for (var x in amenities) {
  //         let amenity = amenities[x];
  //         _amenities.push(amenity.name);
  //       }
  //     }
  //   }
  //   return _amenities.toString();
  // };

  // const displayLocationOccupancy = (location) => {
  //   if (!isEmpty(location.properties)) {
  //     if (!isEmpty(location.properties.occupancy)) {
  //       return location.properties.occupancy.person;
  //     }
  //   }
  //   return 0;
  // };

  const handleLocationMarkerEditDelete = (seat) => {
    removeEditedLocation(seat);
  };

  if (itemPoint.length === 2) {
    return (
      <React.Fragment>
        <Popup
          minWidth="200"
          onClose={() => {
            if (item.displayName !== displayName) {
              if (!isEmpty(item.coordinate)) {
                // if this is a real location document, we have to update the corrdinate displayName
                item.coordinate[item.locationType].displayName = displayName;
              }
              item.displayName = displayName;
              saveEditedLocation(item);
            }
          }}
        >
          <div>
            <Grid container className={classes.locationEditGrid}>
              <Grid item xs={12}>
                <TextField
                  value={displayName}
                  onChange={(event) => setDisplayName(event.target.value)}
                  variant="outlined"
                  size="small"
                  placeholder={t("Locations.LocationDisplayName")}
                  label={t("Locations.LocationDisplayName")}
                  className={classes.locationEditItem}
                />
              </Grid>

              <Grid item xs={12}>
                {t("Locations.LocationPosition") +
                  ":" +
                  `${itemPoint[0].toFixed(4)}` +
                  "," +
                  `${itemPoint[1].toFixed(4)}`}
              </Grid>

              <Grid item xs={12}>
                <img src={imageUrl} width={200} />
              </Grid>

              <Grid item xs={12}>
                <Button
                  aria-label="delete"
                  disabled={isWorking}
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.locationEditItem}
                  startIcon={<DeleteIcon size="small" />}
                  onClick={() => {
                    setIsWorking(true);
                    handleLocationMarkerEditDelete(item);
                    setIsWorking(false);
                  }}
                >
                  {t("Locations.LocationDelete")}
                  {isWorking && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    >
                      {" "}
                    </CircularProgress>
                  )}
                </Button>

                {!isEmpty(selectedMode) && (
                  <Button
                    aria-label="selected"
                    onClick={() => {
                      handleSelectedChange(item, selectedMode);
                    }}
                    variant="contained"
                    color={selectedMode ? "secondary" : "primary"}
                    size="small"
                    startIcon={
                      selectedMode ? (
                        <InsertIconOn color="error" size="small" />
                      ) : (
                        <InsertIconOff size="small" />
                      )
                    }
                    className={classes.locationEditItem}
                  >
                    {selectedMode
                      ? t("Locations.LocationSelectedOn")
                      : t("Locations.LocationSelectedOff")}
                    {isWorking && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      >
                        {" "}
                      </CircularProgress>
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Popup>
      </React.Fragment>
    );
  } else {
    return <div />;
  }
};
