import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Grid, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import { red, orange, blue, green } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  seatToolTip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  selected: {
    color: orange[600]
  },
  unassigned: {
    color: blue[500]
  },
  assigned: {
    color: green[500]
  },
  inserted: {
    color: red[500]
  }
}));

export const LocationMapLegend = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Grid container spacing={2} justify="center" direction="row">
      <Grid item xs={1} className={classes.seatToolTip}>
        <FiberManualRecordIcon
          fontSize="small"
          className={classes.unassigned}
        />
        <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
          {t("Locations.Unassigned")}
        </Typography>
      </Grid>
      <Grid item xs={1} className={classes.seatToolTip}>
        <FiberManualRecordIcon fontSize="small" className={classes.assigned} />
        <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
          {t("Locations.Assigned")}
        </Typography>
      </Grid>
      <Grid item xs={1} className={classes.seatToolTip}>
        <FiberManualRecordIcon fontSize="small" className={classes.selected} />
        <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
          {t("Locations.Selected")}
        </Typography>
      </Grid>
      <Grid item xs={1} className={classes.seatToolTip}>
        <FiberManualRecordIcon fontSize="small" className={classes.inserted} />
        <Typography variant="subtitle2" style={{ marginRight: "8px" }}>
          {t("Locations.Inserted")}
        </Typography>
      </Grid>
    </Grid>
  );
};
