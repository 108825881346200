import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() => ({
  root: {
    background: "white"
  }
}));

const MultipleSelector = ({
  className,
  options,
  onChange,
  multi = true,
  label,
  value = undefined,
  placeHolder
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      className={className}
      multiple={multi}
      options={options}
      value={value}
      getOptionLabel={(option) => option.name}
      filterSelectedOptions
      size="small"
      onChange={(e, value) => {
        if (onChange) {
          onChange(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          classes={{ root: classes.root }}
          variant="outlined"
          label={label || "User Groups"}
          placeholder={placeHolder || "Groups"}
        />
      )}
    />
  );
};

MultipleSelector.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default MultipleSelector;
