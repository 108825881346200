import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useTranslation } from 'react-i18next';
import { useLocalStore } from "../../hooks/useLocalStore";
import { QuestionnaireEditor } from "./QuestionnaireEditor";
import { useCompanyService } from "../../services/useCompanyService";
import CompanyUtil from "../../utils/CompanyUtil";
import { EditQuestionLocations } from "./EditQuestionLocations";
import { setErrorMessage, setSuccessMessage } from "../../actions";
import { hasFrenchContent, isEmpty } from "../../common";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  button: {
    margin: theme.spacing(3, 0)
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  details: {
    flexDirection: "column"
  },
  question: {
    fontFamily: "inherit",
    wordBreak: "break-word",
    whiteSpace: "pre-line"
  },
  typographyContainer: {
    display: "flex",
    alignItems: "center"
  }
}));

export const ScreeningQuestions = () => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyService = useCompanyService();
  const localStore = useLocalStore();

  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editQuestion, setEditQuestion] = useState(null);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const company = localStore.getCompany();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const questions = await companyService.getCheckInQuestions();
    setQuestionnaires(questions);
    const locations = await companyService.getAllLocations();
    setAllLocations(locations);
  };

  const editQuestions = (q) => {
    setEditQuestion(q);
    setIsEditing(true);
  };

  const closeAddQuestions = () => {
    setIsAdding(false);
    setIsEditing(false);
    setEditQuestion(null);
  };

  const editLocations = (q) => {
    setIsEditingLocation(true);
    setEditQuestion(q);
  };

  const closeEditLocations = () => {
    setIsEditingLocation(false);
    setEditQuestion(null);
  };

  const toggleQuestion = (e, qId) => {
    e.stopPropagation();
    e.preventDefault();
    const selected = [...selectedQuestions];
    if (isQuestionChecked(qId)) {
      const index = selected.indexOf(qId);
      selected.splice(index, 1);
    } else {
      selected.push(qId);
    }
    setSelectedQuestions(selected);
  };

  const isQuestionChecked = (qId) => {
    return selectedQuestions.includes(qId);
  };

  const filteredLocations = useCallback(
    (locationIds) => {
      return allLocations.filter((l) => locationIds.includes(l.locationId));
    },
    [allLocations]
  );

  const deleteQuestions = async () => {
    setIsDeleting(true);
    const response = await companyService.deleteCheckinQuestions(
      selectedQuestions
    );
    setIsDeleting(false);
    if (response.data.success) {
      const path = "Success.Cloud." + response.data.message;
      const success = i18n.exists(path)
        ? t(path)
        : t("Success.DeleteQuestionSet");
      dispatch(setSuccessMessage(success));
      loadData();
    } else {
      const path = "Errors.Cloud." + response.data.message;
      const error = i18n.exists(path) ? t(path) : t("Errors.DeleteQuestionSet");
      dispatch(setErrorMessage(error));
    }
  };

  const renderCheckingQuestionsPreview = (q) => {
    let lang = defineLang(q);
    return questionListIsEmpty(q, lang) ? (
      q.questionnaire.map((q, index) => (
        <Typography component={"span"} key={`self-${index}`}>
          <pre className={classes.question}>
            {index + 1}. {q.question[lang]}
          </pre>
        </Typography>
      ))
    ) : (
      <Typography>{t("Questions.NoSelfCheckInQuestions")}</Typography>
    );
  };

  const questionListIsEmpty = (q, lang) => {
    return (
      isEmpty(q.questionnaire) || !!q.questionnaire[0].question[lang].length > 0
    );
  };

  const defineLang = (q) => {
    return i18n.language === "fr" &&
      !isEmpty(q.questionnaire) &&
      hasFrenchContent(q.questionnaire[0].question)
      ? "fr"
      : "en";
  };

  return (
    <div className="content">
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h3">
          {t("Questions.ScreeningQuestions")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => setIsAdding(true)}
            >
              {t("Questions.AddQuestionnaire")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => deleteQuestions()}
              disabled={isDeleting}
            >
              {t("Questions.DeleteSelected")}
              {isDeleting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </Grid>
        </Grid>
        {questionnaires.map((q, index) => (
          <Fragment key={q.id}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <FormControlLabel
                  onClick={(e) => toggleQuestion(e, q.id)}
                  checked={isQuestionChecked(q.id)}
                  control={<Checkbox color="primary" />}
                />
                <div className={classes.typographyContainer}>
                  <Typography variant="h4" component="span">
                    {t("Questions.QuestionSet")} {q.name || index + 1}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => editQuestions(q)}
                    >
                      {t("Questions.EditQuestions")}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="span">
                      {t("Questions.SelfCheckInQuestions")}
                    </Typography>
                    {renderCheckingQuestionsPreview(q)}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => editLocations(q)}
                    >
                      {t("Questions.ManageLocations")}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="span">
                      {t("Questions.Locations")}
                    </Typography>
                    {q.locationIds.length > 0 ? (
                      filteredLocations(q.locationIds).map((l) => (
                        <Typography key={l.locationId}>
                          {CompanyUtil.getStringForCoords(
                            company,
                            l.coordinate
                          )}
                        </Typography>
                      ))
                    ) : (
                      <Typography>
                        {t("Questions.NoAssociatedLocations")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Fragment>
        ))}
      </Container>
      {(isAdding || isEditing) && (
        <QuestionnaireEditor
          createMode={isAdding}
          onClose={closeAddQuestions}
          checkInQuestion={editQuestion}
          onSaveCallback={loadData}
          controller={{ data: editQuestion, set: setEditQuestion }}
        />
      )}
      {isEditingLocation && (
        <EditQuestionLocations
          onClose={closeEditLocations}
          onSaveCallback={loadData}
          allLocations={allLocations}
          checkInQuestion={editQuestion}
          company={company}
        />
      )}
    </div>
  );
};