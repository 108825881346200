import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router-dom";
import {
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
  
const useStyles = makeStyles((theme) => ({
  menuItem: {
    padding: "14px 24px"
  },
  menuText: {
    marginTop: 0,
    marginBottom: 0,
    "& span": {
      fontWeight: "bold"
    }
  },
  highLight: {
    textDecoration: "underline",
    textDecorationColor: theme.palette.primary.main,
    textDecorationThickness: "5px"
  }
}));

const LeftNavMenuItem = ({item, index}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const Icon = item.icon;

  return (
    <RouterLink to={item.link} key={index}>
      <ListItem button key={index} className={classes.menuItem}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText
          primary={t(item.textKey)}
          className={`${classes.menuText} ${item.active ? classes.highLight : ""}`}
        />
      </ListItem>
    </RouterLink>
  );
};

LeftNavMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default LeftNavMenuItem;