import React from "react";
import PropTypes from "prop-types";
import { useCompanyService } from "../../services/useCompanyService";
import BaseSelector from "./BaseSelector";
import { getMultiLanguageContent } from "../../common";

const TagSelector = ({ controller, labelKey }) => {
  const companyService = useCompanyService();

  const optionLabelFormater = (tag) => {
    return getMultiLanguageContent(tag.displayName);
  };

  const getAllTags = async () => {
    const tags = await companyService.getAllLocationTags();
    return tags;
  };

  return (
    <BaseSelector
      _controller={controller}
      _labelKey={labelKey}
      _ajaxMethod={getAllTags}
      _optionLabelFormater={optionLabelFormater}
      _transform={{
        fromFireStore: (tag, list) => {
          return list.find((t) => t.id === tag);
        },

        toFireStore: (tag) => {
          return tag.id;
        }
      }}
      _idFieldName={"id"}
    />
  );
};

TagSelector.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default TagSelector;