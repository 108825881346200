import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Typography,
  LinearProgress,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import { colourDefaults } from "../CompanyInfo/VisualCustomization";
import { useTranslation } from "react-i18next";
import { useCompanyService } from "../../services/useCompanyService";
import { useEffect } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { grey } from "@material-ui/core/colors";
import {
  XAxis,
  ResponsiveContainer
} from "recharts";
import { isEmpty } from "../../common";
import { BOOKING_STATES, STYLE, TIME } from "../../constants";
import { endOfDay, startOfDay } from "date-fns";
import TimeRangeNumberDisplay from "./TimeRangeNumberDisplay";
import DonutChartDisplay from "./DonutChartDisplay";
import BarChartDisplay from "./BarChartDisplay";
import { useAppService } from "../../services/useAppService";

const cardContentWidth = 300;
const cardContentHeight = 250;
const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  basicInfo: {
    width: "100%",
    marginTop: theme.spacing(5)
  },
  visualCustomization: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  contactInfo: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  emailInfo: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  generalInfo: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  updateButton: {
    margin: theme.spacing(3, 0)
  },
  buttonProgress: {
    color: grey[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  card: {
    width: "100%",
    margin: "16px"
  },
  cardHeader: {
    paddingBottom: 4
  },
  timeRangeButton: {
    width: "100%",
    margin: 2
  },
  releaseNoteItem: {
    marginBottom: "0.75rem"
  },
  accordionSummary: {
    width: "100%"
  },
  scrollableCard: {
    height: cardContentHeight,
    overflowY: "auto"
  },
  chartToolTip: {
    margin: "0px",
    padding: "10px",
    backgroundColor: "rgb(255, 255, 255)",
    border: "1px solid rgb(204, 204, 204)",
    whiteSpace: "nowrap"
  },
  chartTooltipItem: {
    display: "block",
    paddingTop: "4px",
    paddingBottom: "0px"
  },
  barChartXAxisText: {
    fill: "#4A4A4A",
    fontFamily: STYLE.fontFamily,
    fontSize: "0.9rem"
  },
  locationOccupancySummary: {
    textAlign: "right"
  }
}));

const timeDifferenceInDays = (today, pastDay) => {
  // Inputs are in milliseconds
  return Math.floor((today - pastDay) / TIME.millisecondsPerDay);
};

const healthScreeningRecordsCountByDay = (records, timeRange, t) => {
  const endOfToday = endOfDay(new Date());
  let recordsByDay = [];

  for (let daysAgo = 0; daysAgo < timeRange; daysAgo++) {
    recordsByDay[daysAgo] = {
      daysAgo: daysAgo,
      passed: 0,
      failed: 0
    };
  }

  records.forEach((record) => {
    // Filter is done in forEach loop. Can be done ahead of time
    if (
      endOfToday >= record.time.toMillis() &&
      record.time.toMillis() > endOfToday - timeRange * TIME.millisecondsPerDay
    ) {
      let daysElapsed = timeDifferenceInDays(
        endOfToday,
        record.time.toMillis()
      );
      record.pass
        ? recordsByDay[daysElapsed]["passed"]++
        : recordsByDay[daysElapsed]["failed"]++;
    }
  });

  // Place oldest screenings on the left
  recordsByDay = recordsByDay.reverse();

  return recordsByDay;
};

const bookingsCountByDay = (bookings, timeRange, states) => {
  // This is similar to healthScreeningRecordsCountByDay. Get function to access time from bookings or health screening records for refactor
  if (isEmpty(timeRange)) {
    return [];
  }

  const endOfToday = endOfDay(new Date());
  // Positive numbers are for days in the past and negative numbers are for days in the future
  const startTimeRange = timeRange[0] || timeRange;
  const endTimeRange = -timeRange[1] || 0;
  let bookingsByDay = [];

  for (let daysAgo = endTimeRange; daysAgo < startTimeRange; daysAgo++) {
    // Only positive indexes are used by recharts. endTimeRange is maximum number of days into the future as a negative number
    bookingsByDay[daysAgo - endTimeRange] = {
      daysAgo: daysAgo
    };
  }

  bookings.forEach((booking) => {
    const bookingTimeInMilliseconds = booking.date.seconds * 1000;
    // startTimeRange is 0 or negative. Subtract to calculate time for future days
    if (
      endOfToday - endTimeRange * TIME.millisecondsPerDay >=
        bookingTimeInMilliseconds &&
      bookingTimeInMilliseconds >
        endOfToday - startTimeRange * TIME.millisecondsPerDay
    ) {
      const daysElapsed = timeDifferenceInDays(
        endOfToday,
        bookingTimeInMilliseconds
      );
      states.forEach((state) => {
        if (state.includes(booking.state)) {
          // If the property doesn't exist then initialize it to 1 to count the current match
          bookingsByDay[daysElapsed - endTimeRange][state[0]] = bookingsByDay[
            daysElapsed - endTimeRange
          ][state[0]]
            ? bookingsByDay[daysElapsed - endTimeRange][state[0]] + 1
            : 1;
        }
      });
    }
  });

  // Place oldest bookings on the left
  bookingsByDay = bookingsByDay.reverse();
  console.log(bookingsByDay);
  return bookingsByDay;
};

const isBookingValid = (booking) => {
  return (
    booking.date.seconds * 1000 >= startOfDay(new Date()) &&
    booking.date.seconds * 1000 <= endOfDay(new Date()) &&
    booking.state === BOOKING_STATES.CHECKED_IN
  );
};

const generateLocationComponents = (company, bookings, locations, classes) => {
  const locationComponents = [];
  const validBookings = bookings.filter((booking) => isBookingValid(booking));
  const validLocations = locations.filter((location) =>
    isEmpty(location.locationParent)
  );

  const locationBookingCounts = countBookingsByLocation(
    validBookings,
    validLocations
  );

  validLocations.forEach((location, index) => {
    locationComponents.push(
      <Accordion key={`locationOccupancyAccordion-${index}`}>
        <AccordionSummary>
          <table className={classes.accordionSummary}>
            <tbody>
              <tr>
                <td>
                  <Typography>{location.displayName}:</Typography>
                </td>
                <td>
                  {/* It is difficult to line up the '/' vertically. Using another table inside is difficult. Padding maxBookingCount with spaces to the right ahead of time is ignored in HTML. */}
                  <Typography className={classes.locationOccupancySummary}>
                    {locationBookingCounts[location.locationId] || 0}
                    {location.maxBookingCount
                      ? ` / ${location.maxBookingCount}`
                      : ""}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </AccordionSummary>
        <AccordionDetails>
          {/* TODO: Recursively generate Accordions to display sublocations
                  Or use company coordinateSystem to know what keys to use to find sublocations and how nested locations are
                  Ex: coordinateSystem = ["Floor", "Zone", "Seat"] tells us that the parent locations are Floors. 
                  Then we loop through the rest of the array to generate components for Zone locations then Seat locations */}
        </AccordionDetails>
      </Accordion>
    );
  });

  /*
    If I use the coordinateSystem in the outer loop then I will generate the component for each location with type coordinateSystem[0]
    Then we add location with type coordinateSystem[1]. But I need to find the component for the parent location for each of these locations.
    The parent location is stored by its ID but I will need to add the location ID to the component to add components for sublocations.

    If I use the locations in the outer loop then I will filter by empty location parents or coordinateSystem[0] to find top level locations
    Then I need to filter for locations with type coordinateSystem[1] then coordinateSystem[2]. 
    I would need to nest the same number of for loops as the number of entries in coordinateSystem. This is not feasible if the size of coordinateSystem can change

    Recursion can solve this. Each location added calls a recursive function to find the document for its children. 
    But locations don't store their children. Locations store a coordinate which stores which locations are the parents.

    Bottom level locations have "atBottomLevel" as true and have locationType as the last element of coordinateSystem
  */

  return locationComponents;
};

const countBookingsByLocation = (bookings, locations) => {
  // Count number of bookings under each location. Returns object with locationId storing the booking count
  // To find bookings under top level locations, give only top level locations
  const locationBookingCounts = {};

  bookings.forEach((booking) => {
    locations.forEach((location) => {
      // Location is or contains booking location
      if (booking.ancestors.indexOf(location.locationId) > -1) {
        locationBookingCounts[location.locationId] = locationBookingCounts[
          location.locationId
        ]
          ? locationBookingCounts[location.locationId] + 1
          : 1;
      }
    });
  });

  return locationBookingCounts;
};

const createBookingsBarChartTooltip = (classes, t) => {
  // Tooltip expects a functional component but it doesn't have access to the styles in Dashboard
  // This passes the styles to the custom tooltip that is passed to Tooltip
  // eslint-disable-next-line no-unused-vars
  return ({ active, payload, label, ...rest }) => {
    // label is XAxis label. It corresponds to the number of days ago
    if (active && payload && payload.length) {
      return (
        <div className={classes.chartToolTip}>
          <p className="recharts-tooltip-label">
            {getDateStringFromDaysAgo(label, t)}
          </p>
          {payload.map((bar, index) => (
            <div key={`bookingBarChartTooltipDesc-${index}`}>
              <p className={classes.chartTooltipItem}>
                <span style={{ color: bar.color }}>{bar.name}</span>:{" "}
                {bar.value}
              </p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };
};

const getDateStringFromDaysAgo = (daysAgo, t) => {
  // Returns date string for the date daysAgo from today
  const bookingDate = new Date(
    new Date().getTime() - daysAgo * TIME.millisecondsPerDay
  );
  const bookingDayOfMonth = bookingDate.getDate();
  const bookingMonth = bookingDate.getMonth() + 1; // Get values in range 1 to 12
  return t("Dashboard.Month" + bookingMonth) + " " + bookingDayOfMonth;
};

const createBookingBarChartXAxis = (classes, t, maxDay) => {
  // Return a function that renders the x axis to use correct locale
  return (tickProps) => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;
    const tickInterval = 2;

    if (value % tickInterval !== 0) {
      // Only render every other value centered on today
      return null;
    }

    const dateString = getDateStringFromDaysAgo(value, t);
    const newX = x + 8;
    const newY = y + 6;

    return (
      <text
        x={newX}
        y={newY}
        transform={`rotate(-35 ${newX} ${newY + 4})`}
        textAnchor="end"
        className={classes.barChartXAxisText}
        style={{ fontWeight: value === 0 ? "bold" : "" }}
      >
        {dateString}
      </text>
    );
  };
};

export default function Dashboard() {
  const maxTimeRange = 90;
  const bookingDaysAhead = 7;
  const bookingBarChartRange = [30, bookingDaysAhead];
  const bookingBarChartXAxisHeight = 50;
  const defaultColors = ["#8884D0", "#84D088", "#9F9F90"];
  const bookingStates = [
    [BOOKING_STATES.CHECKED_IN, BOOKING_STATES.CHECKED_OUT],
    [BOOKING_STATES.CANCELED],
    [BOOKING_STATES.CONFIRMED]
  ];
  const classes = useStyles();
  const localStorage = useLocalStorage();
  const companyService = useCompanyService();
  const appService = useAppService();
  const [createMode, setCreateMode] = useState(false);
  const [company, setCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [t, i18n] = useTranslation();
  const [healthScreeningRecords, setHealthScreeningRecords] = useState([]);
  const [locations, setLocations] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [colors, setColors] = useState(defaultColors);
  const [releaseNotes, setReleaseNotes] = useState([]);

  useEffect(() => {
    loadCompany();
  }, []);

  const loadCompany = async () => {
    setIsLoading(true);
    let company;

    if (!localStorage.getCreateCompanyName()) {
      const endTime = endOfDay(new Date());
      const bookingEndTime = new Date(
        endTime.getTime() + bookingDaysAhead * TIME.millisecondsPerDay
      );
      const startTime = new Date(
        endTime.getTime() - maxTimeRange * TIME.millisecondsPerDay
      );
      company = await companyService.getCompany();

      // Get only failed health screening records. Other records are not needed for now
      setHealthScreeningRecords(
        await companyService.getHealthScreeningRecords(
          startTime,
          endTime,
          null,
          null
        )
      );
      setLocations(await companyService.getAllLocations());
      setBookings(
        await companyService.getBookings(
          startTime,
          bookingEndTime,
          null,
          null,
          null
        )
      );
      setColors([
        colourDefaults.primary ? colourDefaults.primary : defaultColors[0],
        colourDefaults.secondary ? colourDefaults.secondary : defaultColors[1],
        defaultColors[2]
      ]);
      setReleaseNotes(await appService.getReleaseNotes());
    }

    setCompany(company);
    setIsLoading(false);
  };

  // These depend on state variables but don't change after data is loaded
  const healthScreeningCount = healthScreeningRecordsCountByDay(
    healthScreeningRecords,
    1,
    t
  );
  const oneDayHealthScreeningCount = healthScreeningCount.filter(
    (count) => count.daysAgo == 0
  );
  const oneDayHealthScreeningSuccesses = [
    {
      name: "Passed",
      amount: oneDayHealthScreeningCount[0]
        ? oneDayHealthScreeningCount[0].passed || 0
        : 0
    },
    {
      name: "Failed",
      amount: oneDayHealthScreeningCount[0]
        ? oneDayHealthScreeningCount[0].failed || 0
        : 0
    }
  ];
  const oneDayHealthScreeningSuccessesLength =
    oneDayHealthScreeningSuccesses.filter((data) => data.amount > 0).length;
  const bookingCount = bookingsCountByDay(
    bookings,
    bookingBarChartRange,
    bookingStates
  );

  const failedScreeningRangeButtons = [1, 7, 30, 90];
  const bookingCheckinRangeButtons = [1, 7, 30, 90];
  const bookingNoShowRangeButtons = [1, 7, 30, 90];

  let totalHealthScreenings = 0;
  oneDayHealthScreeningSuccesses.forEach((entry) => {
    totalHealthScreenings += entry.amount;
  });
  const healthScreeningDonutChartCenterText =
    oneDayHealthScreeningSuccesses[0].amount + "/" + totalHealthScreenings;

  const bookingEndRangeTitleString = bookingBarChartRange[1]
    ? t("Dashboard.BookingsBarChartCardTitleEnding").replace(
      "{range}",
      bookingBarChartRange[1]
    )
    : "";
  const cardData = [
    {
      title: t("Dashboard.FailedHealthScreeningCardTitle"),
      body: (
        <Container>
          <TimeRangeNumberDisplay
            classes={classes}
            dataArray={healthScreeningRecords.filter(
              (record) => record.pass === false
            )}
            rangeOptions={failedScreeningRangeButtons}
            timeFilter={(record, timeRange) =>
              record.time.seconds >
              (endOfDay(Date.now()) - timeRange * 1000 * 60 * 60 * 24) / 1000
            }
            keyTemplate={"failedScreeningRangeButton"}
            t={t}
          />
        </Container>
      ),
      lgWidth: 3,
      mdWidth: 6,
      xsWidth: 6
    },
    {
      title: t("Dashboard.BookingCheckinsCardTitle"),
      body: (
        <Container>
          <TimeRangeNumberDisplay
            classes={classes}
            dataArray={bookings.filter(
              (booking) =>
                booking.state == BOOKING_STATES.CHECKED_IN ||
                booking.state == BOOKING_STATES.CHECKED_OUT
            )}
            rangeOptions={bookingCheckinRangeButtons}
            timeFilter={(booking, timeRange) =>
              booking.date.seconds >
              (endOfDay(Date.now()) - timeRange * 1000 * 60 * 60 * 24) / 1000
            }
            keyTemplate={"bookingCheckinRangeButton"}
            t={t}
          />
        </Container>
      ),
      lgWidth: 3,
      mdWidth: 6,
      xsWidth: 6
    },
    {
      title: t("Dashboard.BookingNoShowCardTitle"),
      body: (
        <Container>
          <TimeRangeNumberDisplay
            classes={classes}
            dataArray={bookings.filter(
              (booking) =>
                booking.state == BOOKING_STATES.CONFIRMED ||
                booking.state == BOOKING_STATES.INVITED
            )}
            rangeOptions={bookingNoShowRangeButtons}
            timeFilter={(booking, timeRange) =>
              endOfDay(Date.now()).getTime() / 1000 >= booking.date.seconds &&
              booking.date.seconds >
                (endOfDay(Date.now()).getTime() -
                  timeRange * 1000 * 60 * 60 * 24) /
                  1000
            }
            keyTemplate={"bookingNoShowRangeButton"}
            t={t}
          />
        </Container>
      ),
      lgWidth: 3,
      mdWidth: 6,
      xsWidth: 6
    },
    {
      title: t("Dashboard.HealthScreeningDonutChartCardTitle"),
      body: (
        <ResponsiveContainer height={cardContentHeight}>
          <DonutChartDisplay
            classes={classes}
            data={oneDayHealthScreeningSuccesses}
            dataKey="amount"
            centerText={healthScreeningDonutChartCenterText}
            colors={colors}
            translationContext="Dashboard."
            emptyDataMessage={t("Dashboard.NoHealthScreeningsMessage")}
            width={cardContentWidth}
            height={cardContentHeight}
            cellKeyTemplate="oneDayHealthScreeningSuccessesCell-"
          />
        </ResponsiveContainer>
      ),
      lgWidth: 3,
      mdWidth: 6,
      xsWidth: 6
    },
    {
      title:
        t("Dashboard.BookingsBarChartCardTitle").replace(
          "{range}",
          bookingBarChartRange[0]
        ) +
        " " +
        bookingEndRangeTitleString,
      body: (
        <ResponsiveContainer height={cardContentHeight}>
          <BarChartDisplay
            data={bookingCount}
            dataKey="daysAgo"
            customXAxis={
              <XAxis
                dataKey="daysAgo"
                interval={0}
                scale="band"
                tick={createBookingBarChartXAxis(
                  classes,
                  t,
                  bookingBarChartRange[1]
                )}
                height={bookingBarChartXAxisHeight}
              />
            }
            YAxisLabel={t("Dashboard.CountLabel")}
            customTooltip={createBookingsBarChartTooltip(classes, t)}
            states={bookingStates}
            colors={colors}
            width={cardContentWidth}
            height={cardContentHeight}
            translationContext="Dashboard."
            keyTemplate="BookingBarChartBar-"
            classes={classes}
            t={t}
          />
        </ResponsiveContainer>
      ),
      lgWidth: 12,
      mdWidth: 12,
      xsWidth: 12
    },
    {
      title: t("Dashboard.LocationOccupancyCardTitle"),
      body: (
        <div className={classes.scrollableCard}>
          <Container>
            {generateLocationComponents(company, bookings, locations, classes)}
          </Container>
        </div>
      ),
      lgWidth: 6,
      mdWidth: 6,
      xsWidth: 12
    },
    {
      title: t("Dashboard.ReleaseNotesCardTitle"),
      body: (
        <div className={classes.scrollableCard}>
          <Container>
            {releaseNotes.map((notes, index) => (
              <div
                key={`ReleaseNotesItem-${index}`}
                className={classes.releaseNoteItem}
              >
                <Typography variant="h5">
                  {notes.title[i18n.language]}
                </Typography>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: notes.changelog[i18n.language]
                  }}
                ></Typography>
              </div>
            ))}
          </Container>
        </div>
      )
    }
  ];

  if (!isLoading) {
    if (!createMode) {
      return (
        <div className="content">
          <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h3">{t("Dashboard.Dashboard")}</Typography>
            <Grid container spacing={0}>
              {cardData.map((card, index) => (
                <Grid
                  container
                  item
                  xs={card.xsWidth || 12}
                  md={card.mdWidth || 6}
                  lg={card.lgWidth || 6}
                  spacing={0}
                  key={`CardGridContainer-${index}`}
                >
                  <Card className={classes.card}>
                    <CardHeader
                      title={<Typography variant="h4">{card.title}</Typography>}
                      className={classes.cardHeader}
                    />
                    <CardContent>{card.body}</CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      );
    } else {
      return (
        <div className="content">
          <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h3">
              {t("Dashboard.DoCompanyRegistration")}
            </Typography>
          </Container>
        </div>
      );
    }
  } else {
    return <LinearProgress />;
  }
}