import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useCompanyService } from "../../services/useCompanyService";
import { setSuccessMessage, setErrorMessage } from "../../actions/index";
import { useDispatch } from "react-redux";
import { isEmpty } from "../../common";

const useStyles = makeStyles((theme) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  fileSelector: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr 1fr",
    marginTop: theme.spacing(2),
    "&>div": {
      whiteSpace: "no-break",
      "align-self": "center",
      "justify-self": "center"
    }
  },
  selectFileButtonWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    padding: "2px"
  },
  selectFileButtonOrigin: {
    width: "100%",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0
  },
  fileName: {
    marginLeft: theme.spacing(1)
  }
}));

const CompanyLogo = React.memo(function CompanyLogo({
  className,
  company,
  ...rest
}) {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const [fileName, setFileName] = useState("");
  const [uploadAllowed, setUploadAllowed] = useState(false);
  const companyService = useCompanyService();
  const preview = useRef();
  const dispatch = useDispatch();

  function handleFileInput(e, company) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = function (fileData) {
      // make sure the image data is encoded in base64 by btoa()
      const data = {
        name: file.name,
        type: file.type,
        companyId: company.companyId,
        blob: btoa(reader.result)
      };

      companyService
        .uploadCompanyLogo(data)
        .then((data) => {
          company.companyLogo = file.name;
          companyService
            .updateCompany(company)
            .then((response) => {
              if (response.data.success) {
                setFileName(file.name);
                dispatch(setSuccessMessage(t("Success.UpdateLogo")));
              } else {
                const path = "Errors.Cloud." + response.data.message;
                const error = i18n.exists(path)
                  ? t(path)
                  : t("Errors.UpdateLogo");
                dispatch(setErrorMessage(error));
              }
            })
            .catch((error) => {
              console.log(error);
              dispatch(setErrorMessage(t("Errors.General")));
            });
        })
        .catch((error) => {
          console.log(error);
          dispatch(setErrorMessage(t("Errors.General")));
        });
    };
    reader.readAsBinaryString(file);
  }

  const getLogo = useCallback(() => {
    companyService
      .getCompanyLogo({ fileName: company.companyLogo })
      .then((response) => {
        if (!isEmpty(preview.current)) {
          preview.current.src =
            "data:image/xxx; base64," + response.data.message;
        }
        setFileName(company.companyLogo);
      });
  }, [company.companyLogo, companyService]);

  useEffect(() => {
    if (
      isEmpty(company.companyName) ||
      isEmpty(company.contactName) ||
      isEmpty(company.businessAddress) ||
      isEmpty(company.coordinateSystem)
    ) {
      setUploadAllowed(false);
    } else {
      setUploadAllowed(true);
    }
  }, [company]);

  useEffect(() => {
    if (company.companyLogo) {
      getLogo();
    }
  }, [company.companyLogo, getLogo]);

  return (
    <Card className={className} {...rest}>
      <CardHeader
        title={t("CompanyInfo.CompanyLogo")}
        subheader={t("CompanyInfo.ChooseImageType")}
      />
      <CardContent>
        <div>
          <div className={classes.fileSelector}>
            <div className={classes.selectFileButtonWrapper}>
              <Button
                variant="contained"
                color="primary"
                disabled={!uploadAllowed}
              >
                <Typography variant="h6">{t("Commons.SelectFile")}</Typography>
              </Button>
              <input
                type="file"
                className={classes.selectFileButtonOrigin}
                onChange={(e) => {
                  handleFileInput(e, company);
                }}
                accept="image/png, image/jpeg"
                disabled={!uploadAllowed}
              ></input>
            </div>
            <div>
              <Typography variant="h3" className={classes.fileName}>
                {fileName}
              </Typography>
            </div>
            <div>
              <img ref={preview} />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
});

CompanyLogo.propTypes = {
  className: PropTypes.string
};

export default CompanyLogo;
