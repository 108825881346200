import React from "react";
import { useTranslation } from "react-i18next";
import { MultiLanguageInputField } from "../../components/MultiLanguage/MultiLanguageInputField";
import {
  Checkbox,
  RadioGroup,
  makeStyles,
  FormControlLabel
} from "@material-ui/core";
import QuestionLabel from "./QuestionLabel";
import StyledRadio from "../../components/StyledRadio/StyledRadio";

const useStyles = makeStyles({
  inputContainer: {
    margin: "1rem 0"
  },
  checkbox: {
    marginTop: "-16px",
    paddingBottom: "16px"
  }
});

const RenderAdditionalField = ({
  index,
  language,
  questions,
  checkboxTextOnChange,
  expectedAnswerOnChange,
  expectedCheckboxAnswerOnChange
}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  if (questions[index].questionType === "") return null;

  return questions[index].questionType === "CHECKBOX" ? (
    <div className={classes.inputContainer}>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checked={questions[index].expectedAnswer === "CHECKED"}
            onChange={(event) => expectedCheckboxAnswerOnChange(event, index)}
            color="primary"
          />
        }
        label={t("Questions.ExpectedAnswerIsChecked")}
      />
      <MultiLanguageInputField
        label={t("Questions.CheckboxLabel")}
        labelSize={99}
        controller={{
          value: questions[index].checkboxText,
          set: checkboxTextOnChange,
          lang: language
        }}
        index={index}
      />
    </div>
  ) : (
    <div className={classes.inputContainer}>
      <QuestionLabel text={t("Questions.ExpectedAnswer")} />
      <RadioGroup
        value={questions[index].expectedAnswer}
        onChange={(event) => expectedAnswerOnChange(event, index)}
        row
      >
        <FormControlLabel
          value="YES"
          control={<StyledRadio />}
          label={t("Questions.Yes")}
        />
        <FormControlLabel
          value="NO"
          control={<StyledRadio />}
          label={t("Questions.No")}
        />
      </RadioGroup>
    </div>
  );
};

export default RenderAdditionalField;
