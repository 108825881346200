import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Typography, 
  MenuItem, 
  ButtonGroup, 
  Popper, 
  Grow, 
  Paper, 
  ClickAwayListener, 
  MenuList 
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { isNullOrUndefined, getAvailableLanguages } from "../common";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { useAuthService } from "../services/useAuthService";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useCompanyService } from "../services/useCompanyService";
import {ROLES} from "../constants";
import { useTranslation } from 'react-i18next';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  topBarDefault: {
    width: "100%"
  },
  topBar: {
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  "@media print": {
    topBar: {
      display: "none"
    }
  },
  topBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  companyNameWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(4)
  },
  companyNameText: {
    marginLeft: theme.spacing(1)
  },
  flexGrow: {
    flexGrow: 1
  },
  button: {
    margin: "0 16px"
  },
  buttonSelectLanguage: {
    border: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginLeft: "16px",
    textTransform: "none"
  }
}));

function ElevationScroll({ children }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });
  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired
};

const menuLinks = [
  {
    textKey: "NavBar.ChangeCompany",
    link: "/signin-override",
    allowedRoles: [ROLES.ROOT]
  }
];

export const TopBar = ({ className, open, ...rest }) => {
  const classes = useStyles();
  const authService = useAuthService();
  const storage = useLocalStorage();
  const authenticated = storage.getEmailVerified();
  const { getCompany } = useCompanyService();
  const [companyName, setCompanyName] = useState("");
  const { t, i18n } = useTranslation();
  const availableLanguages = getAvailableLanguages();
  const [selectLangOpen, setSelectLangOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleSignOut = () => {
    authService.logout();
  };

  useEffect(() => {
    if (authenticated) {
      getCompany().then((company) => {
        setCompanyName(company.companyName);
      });
    } else {
      setCompanyName("");
    }
  }, [authenticated, getCompany]);

  const renderLanguageMenuItems = () => {
    return availableLanguages.map((lang, index) => {
      return (
        <MenuItem
          value={lang.value}
          key={index}
          onClick={() => handleMenuItemClick(index)}
        >
          {lang.label}
        </MenuItem>
      );
    });
  };

  const handleMenuItemClick = (index) => {
    changeLanguage(availableLanguages[index]);
    setSelectLangOpen(false);
  };

  const changeLanguage = (lang) => {
    if (!lang.value) {
      lang = getSuggestedLanguage();
    }
    i18n.changeLanguage(lang.value);
  };

  const closeSelectLanguage = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }
    setSelectLangOpen(false);
  };

  const handleToggle = () => {
    setSelectLangOpen((prevOpen) => !prevOpen);
  };

  const getSuggestedLanguage = () => {
    if (i18n.language === "en") {
      return availableLanguages[1];
    }
    return availableLanguages[0];
  };

  return (
    <>
      <ElevationScroll>
        <AppBar
          {...rest}
          className={classnames(className, {
            [classes.topBarDefault]: !authenticated,
            [classes.topBar]:
              authenticated &&
              isNullOrUndefined(storage.getCreateCompanyName()),
            [classes.topBarShift]:
              authenticated &&
              isNullOrUndefined(storage.getCreateCompanyName()) &&
              open
          })}
          color="secondary"
          aria-label="menu"
          position="fixed"
        >
          <Toolbar>
            {authenticated && (
              <>
                <div className={classes.flexGrow}>
                  <div className={classes.companyNameWrapper}>
                    <Typography
                      variant="h5"
                      component="span"
                      className={classes.companyNameText}
                    >
                      {companyName}
                    </Typography>
                  </div>
                </div>

                {menuLinks
                  .filter((menuLink) =>
                    storage
                      .getUserRoles()
                      .some((role) => menuLink.allowedRoles.includes(role))
                  )
                  .map((menuLink, index) => {
                    return (
                      <RouterLink to={menuLink.link} key={index}>
                        <Typography variant="h5">
                          {t(menuLink.textKey)}
                        </Typography>
                      </RouterLink>
                    );
                  })}

                <ButtonGroup ref={anchorRef}>
                  <Button
                    onClick={changeLanguage}
                    className={classes.buttonSelectLanguage}
                  >
                    <Typography variant="h5">
                      {getSuggestedLanguage().label}
                    </Typography>
                  </Button>
                  <Button
                    className={classes.buttonSelectLanguage}
                    aria-controls={
                      selectLangOpen ? "split-button-menu" : undefined
                    }
                    aria-expanded={selectLangOpen ? "true" : undefined}
                    onClick={handleToggle}
                    size="small"
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={selectLangOpen}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: "center bottom"
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={closeSelectLanguage}>
                          <MenuList id="split-button-menu">
                            <MenuItem disabled>
                              {t("Commons.Language")}
                            </MenuItem>
                            {renderLanguageMenuItems()}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

                <Button className={classes.button} onClick={handleSignOut}>
                  {t("NavBar.SignOut")}
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

TopBar.propTypes = {
  open: PropTypes.bool.isRequired
};
