import React from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Typography
} from "@material-ui/core";
import ColorSelectorRadioLabel from "./ColorSelectorRadioLabel";
import { useTranslation } from "react-i18next";
import { TAG_COLORS } from "../../constants";

const useStyles = makeStyles({
  radio: { display: "none" },
  radioContainer: { margin: "1rem" },
  radioHeading: { marginTop: "1rem" }
});

const ColorSelector = ({ value, onChange, index }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography component="span" variant="overline">
        <p className={classes.radioHeading}>{t("Locations.ChooseTagColor")}</p>
      </Typography>
      <div className={classes.radioContainer}>
        <RadioGroup
          value={value}
          onChange={(e) => onChange(index, e.target.value)}
          row
        >
          {TAG_COLORS.map((color) => (
            <FormControlLabel
              key={`${index}${color}`}
              value={color}
              control={<Radio className={classes.radio} />}
              label={
                <ColorSelectorRadioLabel
                  color={color}
                  selected={value === color}
                />
              }
            />
          ))}
        </RadioGroup>
      </div>
    </>
  );
};

export default ColorSelector;
